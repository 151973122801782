/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlignmentTypes {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum AppRouter {
  ACCOUNTING_PERIOD = "ACCOUNTING_PERIOD",
  APPROVALS = "APPROVALS",
  APPROVAL_HISTORY = "APPROVAL_HISTORY",
  APPROVAL_SETUP = "APPROVAL_SETUP",
  BANK_STATEMENT_DOCUMENT_PACKAGE = "BANK_STATEMENT_DOCUMENT_PACKAGE",
  BATCH_EDIT = "BATCH_EDIT",
  BATCH_EDIT_VERIFICATION = "BATCH_EDIT_VERIFICATION",
  CHANNELS = "CHANNELS",
  COMMUNICATION_GROUPS = "COMMUNICATION_GROUPS",
  COMPANY_BUSINESS_UNITS = "COMPANY_BUSINESS_UNITS",
  COMPANY_INFO = "COMPANY_INFO",
  CORPORATE_WORKGROUPS = "CORPORATE_WORKGROUPS",
  DEPARTMENT_BUDGET_SETUP = "DEPARTMENT_BUDGET_SETUP",
  DEPARTMENT_GROUP_SETUP = "DEPARTMENT_GROUP_SETUP",
  DEPARTMENT_OCC_TEMPLATE = "DEPARTMENT_OCC_TEMPLATE",
  DEPARTMENT_SETUP = "DEPARTMENT_SETUP",
  DOCUMENT_EXPORT = "DOCUMENT_EXPORT",
  DOCUMENT_POOL = "DOCUMENT_POOL",
  DOCUMENT_POOL_FILE_LOADER = "DOCUMENT_POOL_FILE_LOADER",
  EMPLOYEE = "EMPLOYEE",
  FILE_CABINET = "FILE_CABINET",
  GOODS_RECEIPT = "GOODS_RECEIPT",
  INVOICE_RECEIPT = "INVOICE_RECEIPT",
  LOOKUP_ACCOUNTS = "LOOKUP_ACCOUNTS",
  LOOKUP_CORPORATE_ACCOUNTS = "LOOKUP_CORPORATE_ACCOUNTS",
  OCCUPATION_SETUP = "OCCUPATION_SETUP",
  PAYMENT_DOCUMENT_PACKAGE = "PAYMENT_DOCUMENT_PACKAGE",
  PAYMENT_TRACKING = "PAYMENT_TRACKING",
  PAY_CYCLE = "PAY_CYCLE",
  PCARD_ACCOUNT = "PCARD_ACCOUNT",
  PCARD_HOLDER = "PCARD_HOLDER",
  POSTED_TRANSACTIONS = "POSTED_TRANSACTIONS",
  PO_DOCUMENT_PACKAGE = "PO_DOCUMENT_PACKAGE",
  PRIVATE_POOL = "PRIVATE_POOL",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PURCHASE_ORDER_ACCOUNTING_EXPORT = "PURCHASE_ORDER_ACCOUNTING_EXPORT",
  SUPPLIER = "SUPPLIER",
  TAGS = "TAGS",
  TAG_CATEGORIES = "TAG_CATEGORIES",
  TAG_GROUPS = "TAG_GROUPS",
  TA_DOCUMENT_PACKAGE = "TA_DOCUMENT_PACKAGE",
  TRANSACTION_DOCUMENT_PACKAGE = "TRANSACTION_DOCUMENT_PACKAGE",
  TRANSACTION_SIGNING = "TRANSACTION_SIGNING",
  TRAVELER = "TRAVELER",
  TRAVEL_AUTHORIZATION = "TRAVEL_AUTHORIZATION",
  TRAVEL_POLICIES = "TRAVEL_POLICIES",
  TRIP_BREAKDOWN = "TRIP_BREAKDOWN",
  USER_SETUP = "USER_SETUP",
  VENDOR = "VENDOR",
}

/**
 * Methods to use when ordering `AppliedEntityDocument`.
 */
export enum AppliedEntityDocumentsOrderBy {
  ACCOUNTING_STAMP_DATE_ASC = "ACCOUNTING_STAMP_DATE_ASC",
  ACCOUNTING_STAMP_DATE_DESC = "ACCOUNTING_STAMP_DATE_DESC",
  ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  APPLIED_AMOUNT_ASC = "APPLIED_AMOUNT_ASC",
  APPLIED_AMOUNT_DESC = "APPLIED_AMOUNT_DESC",
  CONTROL_TOTAL_AMOUNT_ASC = "CONTROL_TOTAL_AMOUNT_ASC",
  CONTROL_TOTAL_AMOUNT_DESC = "CONTROL_TOTAL_AMOUNT_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DIFFERENCE_AMOUNT_ASC = "DIFFERENCE_AMOUNT_ASC",
  DIFFERENCE_AMOUNT_DESC = "DIFFERENCE_AMOUNT_DESC",
  DISTRIBUTED_AMOUNT_ASC = "DISTRIBUTED_AMOUNT_ASC",
  DISTRIBUTED_AMOUNT_DESC = "DISTRIBUTED_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__APP_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__APP_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__APP_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__APP_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__BUSINESS_UNIT_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__BUSINESS_UNIT_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__BUSINESS_UNIT_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__BUSINESS_UNIT_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_ACCOUNT_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_ACCOUNT_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_ACCOUNT_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_ACCOUNT_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_HOLDER_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_HOLDER_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_HOLDER_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__CARD_HOLDER_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMMENT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMMENT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMMENT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMMENT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMPANY_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMPANY_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMPANY_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COMPANY_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DEPARTMENT_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DEPARTMENT_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DEPARTMENT_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DEPARTMENT_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_APPLIED_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_APPLIED_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_APPLIED_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_APPLIED_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_OVERAGE_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_OVERAGE_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_OVERAGE_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_OVERAGE_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_POSITION_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_POSITION_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_POSITION_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_POSITION_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_RETIRED_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_RETIRED_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_RETIRED_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_RETIRED_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_EXPLANATION_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_EXPLANATION_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_EXPLANATION_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_EXPLANATION_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_STATUS_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__DOCUMENT_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_DOCUMENT_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_DOCUMENT_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_DOCUMENT_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_DOCUMENT_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ENTITY_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_INDEX_INFORMATION_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_INDEX_INFORMATION_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_INDEX_INFORMATION_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_INDEX_INFORMATION_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_REFERENCE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_REFERENCE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_REFERENCE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__FILE_REFERENCE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ICON_TYPE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ICON_TYPE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ICON_TYPE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ICON_TYPE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NUMBER_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NUMBER_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NUMBER_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ACCOUNT_NUMBER_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_CONFIRMATION_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_CONFIRMATION_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_CONFIRMATION_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_CONFIRMATION_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_IBAN_CODE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_IBAN_CODE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_IBAN_CODE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_IBAN_CODE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ROUTING_NUMBER_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ROUTING_NUMBER_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ROUTING_NUMBER_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_ROUTING_NUMBER_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SORT_CODE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SORT_CODE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SORT_CODE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SORT_CODE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SWIFT_CODE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SWIFT_CODE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SWIFT_CODE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_BANK_SWIFT_CODE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_CURRENCY_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_CURRENCY_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_CURRENCY_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_CURRENCY_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_DESCRIPTION_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_DESCRIPTION_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_DESCRIPTION_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_DESCRIPTION_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_REFERENCE_NUMBER_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_REFERENCE_NUMBER_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_REFERENCE_NUMBER_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_REFERENCE_NUMBER_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_1_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_1_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_1_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_1_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_2_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_2_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_2_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_2_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_3_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_3_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_3_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_3_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_4_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_4_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_4_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_ACCOUNT_4_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_1_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_1_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_1_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_1_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_2_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_2_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_2_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_2_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_3_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_3_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_3_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_3_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_4_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_4_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_4_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_AMOUNT_4_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_1_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_1_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_1_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_1_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_2_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_2_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_2_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_2_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_3_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_3_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_3_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_3_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_4_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_4_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_4_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TAX_CODE_4_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TRANSACTION_DATE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TRANSACTION_DATE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TRANSACTION_DATE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__INDEX_TRANSACTION_DATE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_OVERAGE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_OVERAGE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_OVERAGE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_OVERAGE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_RETIRED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_RETIRED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_RETIRED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_APPLIED_AMOUNT_RETIRED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_SHAREABLE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_SHAREABLE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_SHAREABLE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__IS_SHAREABLE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ROW_SECURITY_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ROW_SECURITY_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ROW_SECURITY_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__ROW_SECURITY_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__VERIFIED_USER_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__VERIFIED_USER_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__VERIFIED_USER_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__VERIFIED_USER_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CDN_THUMBNAIL_GUID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CDN_THUMBNAIL_GUID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CDN_THUMBNAIL_GUID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CDN_THUMBNAIL_GUID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USERID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USERID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USERID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USERID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USER_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USER_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USER_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_BY_USER_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_DATE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_DATE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_DATE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___CREATED_DATE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DIRECTORY_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DIRECTORY_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DIRECTORY_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DIRECTORY_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_CONTENTS_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_CONTENTS_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_CONTENTS_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_CONTENTS_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_FILE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_FILE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_FILE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_FILE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_POOL_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_TYPE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_TYPE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_TYPE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___DOCUMENT_TYPE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_DOCUMENT_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_DOCUMENT_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_DOCUMENT_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_DOCUMENT_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_ROUTE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_ROUTE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_ROUTE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_ROUTE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EMAIL_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORTED_BY_USER_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORTED_BY_USER_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORTED_BY_USER_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORTED_BY_USER_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_DATE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_DATE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_DATE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_DATE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_PROCESS_HISTORY_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_PROCESS_HISTORY_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_PROCESS_HISTORY_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___EXPORT_PROCESS_HISTORY_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_SIZE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_SIZE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_SIZE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_SIZE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_VIEWER_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_VIEWER_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_VIEWER_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FILE_VIEWER_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FULLY_QUALIFIED_DOCUMENT_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FULLY_QUALIFIED_DOCUMENT_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FULLY_QUALIFIED_DOCUMENT_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___FULLY_QUALIFIED_DOCUMENT_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACCOUNTING_DOCUMENT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACCOUNTING_DOCUMENT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACCOUNTING_DOCUMENT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACCOUNTING_DOCUMENT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACTIVE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACTIVE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACTIVE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_ACTIVE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DELETABLE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DELETABLE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DELETABLE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DELETABLE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DEPRECATED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DEPRECATED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DEPRECATED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DEPRECATED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_APPLIED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_APPLIED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_APPLIED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_APPLIED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_SHARE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_SHARE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_SHARE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_SHARE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_TYPE_UPDATABLE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_TYPE_UPDATABLE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_TYPE_UPDATABLE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_DOCUMENT_TYPE_UPDATABLE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_INDEX_EXTRACTED_INFORMATION_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_INDEX_EXTRACTED_INFORMATION_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_INDEX_EXTRACTED_INFORMATION_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_INDEX_EXTRACTED_INFORMATION_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOADING_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOADING_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOADING_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOADING_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOAD_FAILED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOAD_FAILED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOAD_FAILED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_LOAD_FAILED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_MODIFIED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_MODIFIED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_MODIFIED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_MODIFIED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_ORIGIN_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_ORIGIN_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_ORIGIN_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_ORIGIN_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_RECIPIENT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_RECIPIENT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_RECIPIENT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PERSONAL_POOL_RECIPIENT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_REPORTING_DOCUMENT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_REPORTING_DOCUMENT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_REPORTING_DOCUMENT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_REPORTING_DOCUMENT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_SELECTED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_SELECTED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_SELECTED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_SELECTED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_THUMBNAIL_CREATED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_THUMBNAIL_CREATED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_THUMBNAIL_CREATED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_THUMBNAIL_CREATED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_UPDATABLE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_UPDATABLE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_UPDATABLE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_UPDATABLE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_VERIFIED_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_VERIFIED_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_VERIFIED_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___IS_VERIFIED_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___LOOKUP_NAME_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___LOOKUP_NAME_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___LOOKUP_NAME_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___LOOKUP_NAME_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OUTPUT_FILE_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OUTPUT_FILE_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OUTPUT_FILE_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OUTPUT_FILE_NAME_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OVERRIDING_LANGUAGE_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OVERRIDING_LANGUAGE_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OVERRIDING_LANGUAGE_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___OVERRIDING_LANGUAGE_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___POOL_ENTITY_DOCUMENT_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___POOL_ENTITY_DOCUMENT_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___POOL_ENTITY_DOCUMENT_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___POOL_ENTITY_DOCUMENT_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___RECIPIENT_USER_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___RECIPIENT_USER_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___RECIPIENT_USER_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___RECIPIENT_USER_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___ROW_TIMESTAMP_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___ROW_TIMESTAMP_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___ROW_TIMESTAMP_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___ROW_TIMESTAMP_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SEARCH_DOCUMENT_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SEARCH_DOCUMENT_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SEARCH_DOCUMENT_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SEARCH_DOCUMENT_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_ID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_ID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_ID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SHARED_BY_USER_ID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SIGNED_EXECUTED_DATE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SIGNED_EXECUTED_DATE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SIGNED_EXECUTED_DATE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SIGNED_EXECUTED_DATE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SORT_EXPORT_DATE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SORT_EXPORT_DATE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SORT_EXPORT_DATE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___SORT_EXPORT_DATE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USERID_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USERID_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USERID_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USERID_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USER_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USER_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USER_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOADED_BY_USER_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOAD_DATE_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOAD_DATE_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOAD_DATE_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___UPLOAD_DATE_DESC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___VERIFIED_USER_NAME_ASC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___VERIFIED_USER_NAME_ASC",
  ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___VERIFIED_USER_NAME_DESC = "ENTITY_DOCUMENT_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID___VERIFIED_USER_NAME_DESC",
  ENTITY_ID_ASC = "ENTITY_ID_ASC",
  ENTITY_ID_DESC = "ENTITY_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_ASC = "INVOICE_BY_ENTITY_ID__BATCH_ID_ASC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_DESC = "INVOICE_BY_ENTITY_ID__BATCH_ID_DESC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC",
  INVOICE_BY_ENTITY_ID__ID_ASC = "INVOICE_BY_ENTITY_ID__ID_ASC",
  INVOICE_BY_ENTITY_ID__ID_DESC = "INVOICE_BY_ENTITY_ID__ID_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  INVOICE_DATE_ASC = "INVOICE_DATE_ASC",
  INVOICE_DATE_DESC = "INVOICE_DATE_DESC",
  INVOICE_NUMBER_ASC = "INVOICE_NUMBER_ASC",
  INVOICE_NUMBER_DESC = "INVOICE_NUMBER_DESC",
  ISO_CODE_ASC = "ISO_CODE_ASC",
  ISO_CODE_DESC = "ISO_CODE_DESC",
  NATURAL = "NATURAL",
  OPENING_BALANCE_ASC = "OPENING_BALANCE_ASC",
  OPENING_BALANCE_DESC = "OPENING_BALANCE_DESC",
  OVERAGE_AMOUNT_ASC = "OVERAGE_AMOUNT_ASC",
  OVERAGE_AMOUNT_DESC = "OVERAGE_AMOUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REMAINING_BALANCE_ASC = "REMAINING_BALANCE_ASC",
  REMAINING_BALANCE_DESC = "REMAINING_BALANCE_DESC",
  RETIRED_AMOUNT_ASC = "RETIRED_AMOUNT_ASC",
  RETIRED_AMOUNT_DESC = "RETIRED_AMOUNT_DESC",
  TRANSACTION_TYPE_ASC = "TRANSACTION_TYPE_ASC",
  TRANSACTION_TYPE_DESC = "TRANSACTION_TYPE_DESC",
  VENDOR_REFERENCE_ASC = "VENDOR_REFERENCE_ASC",
  VENDOR_REFERENCE_DESC = "VENDOR_REFERENCE_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _DOCUMENT_FILE_ID_ASC = "_DOCUMENT_FILE_ID_ASC",
  _DOCUMENT_FILE_ID_DESC = "_DOCUMENT_FILE_ID_DESC",
  _DOCUMENT_POOL_ENTITY_TYPE_ID_ASC = "_DOCUMENT_POOL_ENTITY_TYPE_ID_ASC",
  _DOCUMENT_POOL_ENTITY_TYPE_ID_DESC = "_DOCUMENT_POOL_ENTITY_TYPE_ID_DESC",
  _IS_RETIRE_AVAILABLE_ASC = "_IS_RETIRE_AVAILABLE_ASC",
  _IS_RETIRE_AVAILABLE_DESC = "_IS_RETIRE_AVAILABLE_DESC",
}

/**
 * Methods to use when ordering `ApprovalHistory`.
 */
export enum ApprovalHistoriesOrderBy {
  ADDITIONAL_APPROVAL_INFORMATION_ASC = "ADDITIONAL_APPROVAL_INFORMATION_ASC",
  ADDITIONAL_APPROVAL_INFORMATION_DESC = "ADDITIONAL_APPROVAL_INFORMATION_DESC",
  AMOUNT_1_ASC = "AMOUNT_1_ASC",
  AMOUNT_1_DESC = "AMOUNT_1_DESC",
  AMOUNT_2_ASC = "AMOUNT_2_ASC",
  AMOUNT_2_DESC = "AMOUNT_2_DESC",
  AMOUNT_3_ASC = "AMOUNT_3_ASC",
  AMOUNT_3_DESC = "AMOUNT_3_DESC",
  AMOUNT_4_ASC = "AMOUNT_4_ASC",
  AMOUNT_4_DESC = "AMOUNT_4_DESC",
  AMOUNT_5_ASC = "AMOUNT_5_ASC",
  AMOUNT_5_DESC = "AMOUNT_5_DESC",
  APPROVAL_BRANCH_ID_ASC = "APPROVAL_BRANCH_ID_ASC",
  APPROVAL_BRANCH_ID_DESC = "APPROVAL_BRANCH_ID_DESC",
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC",
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC",
  APPROVAL_BY_ENTITY_ID__ID_ASC = "APPROVAL_BY_ENTITY_ID__ID_ASC",
  APPROVAL_BY_ENTITY_ID__ID_DESC = "APPROVAL_BY_ENTITY_ID__ID_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC",
  APPROVAL_BY_ENTITY_ID__NAME_ASC = "APPROVAL_BY_ENTITY_ID__NAME_ASC",
  APPROVAL_BY_ENTITY_ID__NAME_DESC = "APPROVAL_BY_ENTITY_ID__NAME_DESC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  APPROVAL_GROUP_ASC = "APPROVAL_GROUP_ASC",
  APPROVAL_GROUP_DESC = "APPROVAL_GROUP_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ID_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ID_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_ASC = "APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_DESC = "APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_PERSONA_ASC = "APPROVAL_PERSONA_ASC",
  APPROVAL_PERSONA_DESC = "APPROVAL_PERSONA_DESC",
  APPROVAL_PHASE_BY_ID__ID_ASC = "APPROVAL_PHASE_BY_ID__ID_ASC",
  APPROVAL_PHASE_BY_ID__ID_DESC = "APPROVAL_PHASE_BY_ID__ID_DESC",
  APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC = "APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC",
  APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC = "APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC",
  APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_TOPIC_ASC = "APPROVAL_TOPIC_ASC",
  APPROVAL_TOPIC_DESC = "APPROVAL_TOPIC_DESC",
  APPROVAL_TREE_ID_ASC = "APPROVAL_TREE_ID_ASC",
  APPROVAL_TREE_ID_DESC = "APPROVAL_TREE_ID_DESC",
  APPROVAL_TREE_LEVEL_ASC = "APPROVAL_TREE_LEVEL_ASC",
  APPROVAL_TREE_LEVEL_DESC = "APPROVAL_TREE_LEVEL_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_DESC",
  APPROVAL_TYPE_ID_ASC = "APPROVAL_TYPE_ID_ASC",
  APPROVAL_TYPE_ID_DESC = "APPROVAL_TYPE_ID_DESC",
  APPROVED_DATE_ASC = "APPROVED_DATE_ASC",
  APPROVED_DATE_DESC = "APPROVED_DATE_DESC",
  APPROVER_USER_PROFILE_ID_ASC = "APPROVER_USER_PROFILE_ID_ASC",
  APPROVER_USER_PROFILE_ID_DESC = "APPROVER_USER_PROFILE_ID_DESC",
  APP_ID_ASC = "APP_ID_ASC",
  APP_ID_DESC = "APP_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  BRANCH_APPROVED_DATE_ASC = "BRANCH_APPROVED_DATE_ASC",
  BRANCH_APPROVED_DATE_DESC = "BRANCH_APPROVED_DATE_DESC",
  BRANCH_DIGITAL_SIGNATURE_ASC = "BRANCH_DIGITAL_SIGNATURE_ASC",
  BRANCH_DIGITAL_SIGNATURE_DESC = "BRANCH_DIGITAL_SIGNATURE_DESC",
  BRANCH_REJECTED_DATE_ASC = "BRANCH_REJECTED_DATE_ASC",
  BRANCH_REJECTED_DATE_DESC = "BRANCH_REJECTED_DATE_DESC",
  BRANCH_STATUS_TYPE_ID_ASC = "BRANCH_STATUS_TYPE_ID_ASC",
  BRANCH_STATUS_TYPE_ID_DESC = "BRANCH_STATUS_TYPE_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  CURRENCY_ISO_CODE_ASC = "CURRENCY_ISO_CODE_ASC",
  CURRENCY_ISO_CODE_DESC = "CURRENCY_ISO_CODE_DESC",
  DATE_1_ASC = "DATE_1_ASC",
  DATE_1_DESC = "DATE_1_DESC",
  DATE_2_ASC = "DATE_2_ASC",
  DATE_2_DESC = "DATE_2_DESC",
  DATE_3_ASC = "DATE_3_ASC",
  DATE_3_DESC = "DATE_3_DESC",
  DATE_4_ASC = "DATE_4_ASC",
  DATE_4_DESC = "DATE_4_DESC",
  DATE_5_ASC = "DATE_5_ASC",
  DATE_5_DESC = "DATE_5_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DESCRIPTION_10_ASC = "DESCRIPTION_10_ASC",
  DESCRIPTION_10_DESC = "DESCRIPTION_10_DESC",
  DESCRIPTION_1_ASC = "DESCRIPTION_1_ASC",
  DESCRIPTION_1_DESC = "DESCRIPTION_1_DESC",
  DESCRIPTION_2_ASC = "DESCRIPTION_2_ASC",
  DESCRIPTION_2_DESC = "DESCRIPTION_2_DESC",
  DESCRIPTION_3_ASC = "DESCRIPTION_3_ASC",
  DESCRIPTION_3_DESC = "DESCRIPTION_3_DESC",
  DESCRIPTION_4_ASC = "DESCRIPTION_4_ASC",
  DESCRIPTION_4_DESC = "DESCRIPTION_4_DESC",
  DESCRIPTION_5_ASC = "DESCRIPTION_5_ASC",
  DESCRIPTION_5_DESC = "DESCRIPTION_5_DESC",
  DESCRIPTION_6_ASC = "DESCRIPTION_6_ASC",
  DESCRIPTION_6_DESC = "DESCRIPTION_6_DESC",
  DESCRIPTION_7_ASC = "DESCRIPTION_7_ASC",
  DESCRIPTION_7_DESC = "DESCRIPTION_7_DESC",
  DESCRIPTION_8_ASC = "DESCRIPTION_8_ASC",
  DESCRIPTION_8_DESC = "DESCRIPTION_8_DESC",
  DESCRIPTION_9_ASC = "DESCRIPTION_9_ASC",
  DESCRIPTION_9_DESC = "DESCRIPTION_9_DESC",
  DIGITAL_SIGNATURE_ASC = "DIGITAL_SIGNATURE_ASC",
  DIGITAL_SIGNATURE_DESC = "DIGITAL_SIGNATURE_DESC",
  DISPLAY_ENTITY_APPROVAL_ID_ASC = "DISPLAY_ENTITY_APPROVAL_ID_ASC",
  DISPLAY_ENTITY_APPROVAL_ID_DESC = "DISPLAY_ENTITY_APPROVAL_ID_DESC",
  ENTITY_APPROVAL_BRANCH_ID_ASC = "ENTITY_APPROVAL_BRANCH_ID_ASC",
  ENTITY_APPROVAL_BRANCH_ID_DESC = "ENTITY_APPROVAL_BRANCH_ID_DESC",
  ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC = "ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC",
  ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC = "ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC",
  ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC = "ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC",
  ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC = "ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC",
  ENTITY_APPROVAL_COMPANY_ID_ASC = "ENTITY_APPROVAL_COMPANY_ID_ASC",
  ENTITY_APPROVAL_COMPANY_ID_DESC = "ENTITY_APPROVAL_COMPANY_ID_DESC",
  ENTITY_APPROVAL_TREE_ID_ASC = "ENTITY_APPROVAL_TREE_ID_ASC",
  ENTITY_APPROVAL_TREE_ID_DESC = "ENTITY_APPROVAL_TREE_ID_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_ID_ASC = "ENTITY_ID_ASC",
  ENTITY_ID_DESC = "ENTITY_ID_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_TYPE_ID_ASC = "ENTITY_TYPE_ID_ASC",
  ENTITY_TYPE_ID_DESC = "ENTITY_TYPE_ID_DESC",
  FINALIZED_DATE_ASC = "FINALIZED_DATE_ASC",
  FINALIZED_DATE_DESC = "FINALIZED_DATE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__ID_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__ID_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__ID_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__ID_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_DESC",
  ICON_TYPE_ID_ASC = "ICON_TYPE_ID_ASC",
  ICON_TYPE_ID_DESC = "ICON_TYPE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_ASC = "INVOICE_BY_ENTITY_ID__BATCH_ID_ASC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_DESC = "INVOICE_BY_ENTITY_ID__BATCH_ID_DESC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC",
  INVOICE_BY_ENTITY_ID__ID_ASC = "INVOICE_BY_ENTITY_ID__ID_ASC",
  INVOICE_BY_ENTITY_ID__ID_DESC = "INVOICE_BY_ENTITY_ID__ID_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_ASC = "INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_DESC = "INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_ASC = "INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_DESC = "INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_DESC",
  IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC = "IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC",
  IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC = "IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC",
  IS_APPROVED_ASC = "IS_APPROVED_ASC",
  IS_APPROVED_DESC = "IS_APPROVED_DESC",
  IS_APPROVER_ADDING_ALLOWED_ASC = "IS_APPROVER_ADDING_ALLOWED_ASC",
  IS_APPROVER_ADDING_ALLOWED_DESC = "IS_APPROVER_ADDING_ALLOWED_DESC",
  IS_APPROVER_ASC = "IS_APPROVER_ASC",
  IS_APPROVER_DESC = "IS_APPROVER_DESC",
  IS_AUTO_APPROVAL_ASC = "IS_AUTO_APPROVAL_ASC",
  IS_AUTO_APPROVAL_DESC = "IS_AUTO_APPROVAL_DESC",
  IS_HISTORY_ASC = "IS_HISTORY_ASC",
  IS_HISTORY_DESC = "IS_HISTORY_DESC",
  IS_LEVEL_BYPASSING_ALLOWED_ASC = "IS_LEVEL_BYPASSING_ALLOWED_ASC",
  IS_LEVEL_BYPASSING_ALLOWED_DESC = "IS_LEVEL_BYPASSING_ALLOWED_DESC",
  IS_OBSERVER_ASC = "IS_OBSERVER_ASC",
  IS_OBSERVER_DESC = "IS_OBSERVER_DESC",
  IS_PENDING_ASC = "IS_PENDING_ASC",
  IS_PENDING_DESC = "IS_PENDING_DESC",
  IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC = "IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC",
  IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC = "IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC",
  IS_REJECTED_ASC = "IS_REJECTED_ASC",
  IS_REJECTED_DESC = "IS_REJECTED_DESC",
  IS_REQUESTER_ASC = "IS_REQUESTER_ASC",
  IS_REQUESTER_DESC = "IS_REQUESTER_DESC",
  IS_REVOKED_ASC = "IS_REVOKED_ASC",
  IS_REVOKED_DESC = "IS_REVOKED_DESC",
  IS_URGENCY_UPDATE_ALLOWED_ASC = "IS_URGENCY_UPDATE_ALLOWED_ASC",
  IS_URGENCY_UPDATE_ALLOWED_DESC = "IS_URGENCY_UPDATE_ALLOWED_DESC",
  LAYOUT_TYPE_ASC = "LAYOUT_TYPE_ASC",
  LAYOUT_TYPE_DESC = "LAYOUT_TYPE_DESC",
  LINKED_ENTITY_ID_ASC = "LINKED_ENTITY_ID_ASC",
  LINKED_ENTITY_ID_DESC = "LINKED_ENTITY_ID_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_ASC = "PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_DESC = "PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_DESC",
  PAY_CYCLE_BY_ENTITY_ID__ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_ASC = "PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_ASC",
  PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_DESC = "PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_DESC",
  PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_ASC = "PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_ASC",
  PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_DESC = "PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_ASC = "PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_ASC",
  PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_DESC = "PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REASON_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REASON_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REASON_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REASON_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  RATE_1_ASC = "RATE_1_ASC",
  RATE_1_DESC = "RATE_1_DESC",
  RATE_2_ASC = "RATE_2_ASC",
  RATE_2_DESC = "RATE_2_DESC",
  RATE_3_ASC = "RATE_3_ASC",
  RATE_3_DESC = "RATE_3_DESC",
  RATE_4_ASC = "RATE_4_ASC",
  RATE_4_DESC = "RATE_4_DESC",
  RATE_5_ASC = "RATE_5_ASC",
  RATE_5_DESC = "RATE_5_DESC",
  REJECTED_DATE_ASC = "REJECTED_DATE_ASC",
  REJECTED_DATE_DESC = "REJECTED_DATE_DESC",
  REQUESTED_DATE_ASC = "REQUESTED_DATE_ASC",
  REQUESTED_DATE_DESC = "REQUESTED_DATE_DESC",
  REQUESTER_NAME_ASC = "REQUESTER_NAME_ASC",
  REQUESTER_NAME_DESC = "REQUESTER_NAME_DESC",
  REQUESTER_USER_PROFILE_ID_ASC = "REQUESTER_USER_PROFILE_ID_ASC",
  REQUESTER_USER_PROFILE_ID_DESC = "REQUESTER_USER_PROFILE_ID_DESC",
  REQUIRED_DATE_ASC = "REQUIRED_DATE_ASC",
  REQUIRED_DATE_DESC = "REQUIRED_DATE_DESC",
  REVOKED_BY_USER_NAME_ASC = "REVOKED_BY_USER_NAME_ASC",
  REVOKED_BY_USER_NAME_DESC = "REVOKED_BY_USER_NAME_DESC",
  REVOKED_BY_USER_PROFILE_ID_ASC = "REVOKED_BY_USER_PROFILE_ID_ASC",
  REVOKED_BY_USER_PROFILE_ID_DESC = "REVOKED_BY_USER_PROFILE_ID_DESC",
  REVOKED_DATE_ASC = "REVOKED_DATE_ASC",
  REVOKED_DATE_DESC = "REVOKED_DATE_DESC",
  SORT_ORDER_GROUP_ASC = "SORT_ORDER_GROUP_ASC",
  SORT_ORDER_GROUP_DESC = "SORT_ORDER_GROUP_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_ID_ASC = "STATUS_TYPE_ID_ASC",
  STATUS_TYPE_ID_DESC = "STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__ID_ASC = "SUPPLIER_BY_ENTITY_ID__ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ID_DESC = "SUPPLIER_BY_ENTITY_ID__ID_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID__ID_ASC = "TRAVELER_BY_ENTITY_ID__ID_ASC",
  TRAVELER_BY_ENTITY_ID__ID_DESC = "TRAVELER_BY_ENTITY_ID__ID_DESC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_DESC",
  TREE_APPROVED_DATE_ASC = "TREE_APPROVED_DATE_ASC",
  TREE_APPROVED_DATE_DESC = "TREE_APPROVED_DATE_DESC",
  TREE_REJECTED_DATE_ASC = "TREE_REJECTED_DATE_ASC",
  TREE_REJECTED_DATE_DESC = "TREE_REJECTED_DATE_DESC",
  TREE_STATUS_TYPE_ID_ASC = "TREE_STATUS_TYPE_ID_ASC",
  TREE_STATUS_TYPE_ID_DESC = "TREE_STATUS_TYPE_ID_DESC",
  UNIT_1_ASC = "UNIT_1_ASC",
  UNIT_1_DESC = "UNIT_1_DESC",
  UNIT_2_ASC = "UNIT_2_ASC",
  UNIT_2_DESC = "UNIT_2_DESC",
  UNIT_3_ASC = "UNIT_3_ASC",
  UNIT_3_DESC = "UNIT_3_DESC",
  UNIT_4_ASC = "UNIT_4_ASC",
  UNIT_4_DESC = "UNIT_4_DESC",
  UNIT_5_ASC = "UNIT_5_ASC",
  UNIT_5_DESC = "UNIT_5_DESC",
  URGENCY_LEVEL_ASC = "URGENCY_LEVEL_ASC",
  URGENCY_LEVEL_DESC = "URGENCY_LEVEL_DESC",
  USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_ASC = "USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_DESC = "USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__APP_BUSINESS_RULES_ASC = "USER_APP_BY_APP_ID__APP_BUSINESS_RULES_ASC",
  USER_APP_BY_APP_ID__APP_BUSINESS_RULES_DESC = "USER_APP_BY_APP_ID__APP_BUSINESS_RULES_DESC",
  USER_APP_BY_APP_ID__APP_CATEGORY_ID_ASC = "USER_APP_BY_APP_ID__APP_CATEGORY_ID_ASC",
  USER_APP_BY_APP_ID__APP_CATEGORY_ID_DESC = "USER_APP_BY_APP_ID__APP_CATEGORY_ID_DESC",
  USER_APP_BY_APP_ID__APP_CATEGORY_NAME_ASC = "USER_APP_BY_APP_ID__APP_CATEGORY_NAME_ASC",
  USER_APP_BY_APP_ID__APP_CATEGORY_NAME_DESC = "USER_APP_BY_APP_ID__APP_CATEGORY_NAME_DESC",
  USER_APP_BY_APP_ID__APP_ID_ASC = "USER_APP_BY_APP_ID__APP_ID_ASC",
  USER_APP_BY_APP_ID__APP_ID_DESC = "USER_APP_BY_APP_ID__APP_ID_DESC",
  USER_APP_BY_APP_ID__APP_NAME_ASC = "USER_APP_BY_APP_ID__APP_NAME_ASC",
  USER_APP_BY_APP_ID__APP_NAME_DESC = "USER_APP_BY_APP_ID__APP_NAME_DESC",
  USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_ASC = "USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_ASC",
  USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_DESC = "USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_DESC",
  USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_ASC = "USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_ASC",
  USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_DESC = "USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_DESC",
  USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_APP_ENTRY_ASC = "USER_APP_BY_APP_ID__IS_APP_ENTRY_ASC",
  USER_APP_BY_APP_ID__IS_APP_ENTRY_DESC = "USER_APP_BY_APP_ID__IS_APP_ENTRY_DESC",
  USER_APP_BY_APP_ID__IS_APP_REPORTING_ASC = "USER_APP_BY_APP_ID__IS_APP_REPORTING_ASC",
  USER_APP_BY_APP_ID__IS_APP_REPORTING_DESC = "USER_APP_BY_APP_ID__IS_APP_REPORTING_DESC",
  USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_ASC = "USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_ASC",
  USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_DESC = "USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_DESC",
  USER_APP_BY_APP_ID__IS_APP_SERVICE_ASC = "USER_APP_BY_APP_ID__IS_APP_SERVICE_ASC",
  USER_APP_BY_APP_ID__IS_APP_SERVICE_DESC = "USER_APP_BY_APP_ID__IS_APP_SERVICE_DESC",
  USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_ASC = "USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_ASC",
  USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_DESC = "USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_DESC",
  USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_ASC = "USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_ASC",
  USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_DESC = "USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_DESC",
  USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_MOBILE_LINK_ASC = "USER_APP_BY_APP_ID__IS_MOBILE_LINK_ASC",
  USER_APP_BY_APP_ID__IS_MOBILE_LINK_DESC = "USER_APP_BY_APP_ID__IS_MOBILE_LINK_DESC",
  USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_READ_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_READ_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_READ_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_READ_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_ASC = "USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_ASC",
  USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_DESC = "USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_DESC",
  USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_WEB_LINK_ASC = "USER_APP_BY_APP_ID__IS_WEB_LINK_ASC",
  USER_APP_BY_APP_ID__IS_WEB_LINK_DESC = "USER_APP_BY_APP_ID__IS_WEB_LINK_DESC",
  USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_ASC = "USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_DESC = "USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__LINK_HINT_ASC = "USER_APP_BY_APP_ID__LINK_HINT_ASC",
  USER_APP_BY_APP_ID__LINK_HINT_DESC = "USER_APP_BY_APP_ID__LINK_HINT_DESC",
  USER_APP_BY_APP_ID__LINK_TITLE_ASC = "USER_APP_BY_APP_ID__LINK_TITLE_ASC",
  USER_APP_BY_APP_ID__LINK_TITLE_DESC = "USER_APP_BY_APP_ID__LINK_TITLE_DESC",
  USER_APP_BY_APP_ID__LINK_TYPE_ASC = "USER_APP_BY_APP_ID__LINK_TYPE_ASC",
  USER_APP_BY_APP_ID__LINK_TYPE_DESC = "USER_APP_BY_APP_ID__LINK_TYPE_DESC",
  USER_APP_BY_APP_ID__LINK_TYPE_ID_ASC = "USER_APP_BY_APP_ID__LINK_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__LINK_TYPE_ID_DESC = "USER_APP_BY_APP_ID__LINK_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_ASC = "USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_DESC = "USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC",
  USER_PROFILE_BY_ENTITY_ID__ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_ASC = "USER_PROFILE_BY_ENTITY_ID__INVITED_ASC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_DESC = "USER_PROFILE_BY_ENTITY_ID__INVITED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  USER_PROFILE_BY_ENTITY_ID__NAME_ASC = "USER_PROFILE_BY_ENTITY_ID__NAME_ASC",
  USER_PROFILE_BY_ENTITY_ID__NAME_DESC = "USER_PROFILE_BY_ENTITY_ID__NAME_DESC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__ID_ASC = "VENDOR_BY_ENTITY_ID__ID_ASC",
  VENDOR_BY_ENTITY_ID__ID_DESC = "VENDOR_BY_ENTITY_ID__ID_DESC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_ASC = "VENDOR_BY_ENTITY_ID___FULL_NAME_ASC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_DESC = "VENDOR_BY_ENTITY_ID___FULL_NAME_DESC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_ASC = "VENDOR_BY_ENTITY_ID___SORT_NAME_ASC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_DESC = "VENDOR_BY_ENTITY_ID___SORT_NAME_DESC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_ENVIRONMENTALS_EXIST_ASC = "_IS_ENVIRONMENTALS_EXIST_ASC",
  _IS_ENVIRONMENTALS_EXIST_DESC = "_IS_ENVIRONMENTALS_EXIST_DESC",
  _IS_LOOKUP_NAME_APPLIED_ASC = "_IS_LOOKUP_NAME_APPLIED_ASC",
  _IS_LOOKUP_NAME_APPLIED_DESC = "_IS_LOOKUP_NAME_APPLIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_REVOCABLE_ASC = "_IS_REVOCABLE_ASC",
  _IS_REVOCABLE_DESC = "_IS_REVOCABLE_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_URGENT_APPROVAL_ASC = "_IS_URGENT_APPROVAL_ASC",
  _IS_URGENT_APPROVAL_DESC = "_IS_URGENT_APPROVAL_DESC",
  _IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC = "_IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC",
  _IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC = "_IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC",
  _IS_VALIDATING_ASC = "_IS_VALIDATING_ASC",
  _IS_VALIDATING_DESC = "_IS_VALIDATING_DESC",
  _LOOKUP_NAME_ID_ASC = "_LOOKUP_NAME_ID_ASC",
  _LOOKUP_NAME_ID_DESC = "_LOOKUP_NAME_ID_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _PERIOD_END_DATE_ASC = "_PERIOD_END_DATE_ASC",
  _PERIOD_END_DATE_DESC = "_PERIOD_END_DATE_DESC",
  _PERIOD_ID_ASC = "_PERIOD_ID_ASC",
  _PERIOD_ID_DESC = "_PERIOD_ID_DESC",
  _PERIOD_START_DATE_ASC = "_PERIOD_START_DATE_ASC",
  _PERIOD_START_DATE_DESC = "_PERIOD_START_DATE_DESC",
  _PERIOD_YEAR_ASC = "_PERIOD_YEAR_ASC",
  _PERIOD_YEAR_DESC = "_PERIOD_YEAR_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _VERIFICATION_DATE_ASC = "_VERIFICATION_DATE_ASC",
  _VERIFICATION_DATE_DESC = "_VERIFICATION_DATE_DESC",
  _VERIFICATION_LINK_TYPE_ID_ASC = "_VERIFICATION_LINK_TYPE_ID_ASC",
  _VERIFICATION_LINK_TYPE_ID_DESC = "_VERIFICATION_LINK_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `ApprovalHistoryItem`.
 */
export enum ApprovalHistoryItemsOrderBy {
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_DESC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_DESC",
  DISPLAY_ENTITY_APPROVAL_ID_ASC = "DISPLAY_ENTITY_APPROVAL_ID_ASC",
  DISPLAY_ENTITY_APPROVAL_ID_DESC = "DISPLAY_ENTITY_APPROVAL_ID_DESC",
  ENTITY_ITEM_ID_ASC = "ENTITY_ITEM_ID_ASC",
  ENTITY_ITEM_ID_DESC = "ENTITY_ITEM_ID_DESC",
  ID_1_ASC = "ID_1_ASC",
  ID_1_DESC = "ID_1_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__APPLIED_AMOUNT_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__APPLIED_AMOUNT_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__APPLIED_AMOUNT_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__APPLIED_AMOUNT_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__BATCH_TRANSACTION_ID_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__BATCH_TRANSACTION_ID_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__BATCH_TRANSACTION_ID_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__BATCH_TRANSACTION_ID_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__COMMENT_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__COMMENT_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__COMMENT_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__COMMENT_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__ID_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__ID_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__ID_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__ID_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__INVOICE_ID_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__INVOICE_ID_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__INVOICE_ID_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__INVOICE_ID_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__IS_VERIFIED_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__IS_VERIFIED_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__IS_VERIFIED_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__IS_VERIFIED_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___CREATED_DATE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___CREATED_DATE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___CREATED_DATE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___CREATED_DATE_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_ACTIVE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_ACTIVE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_ACTIVE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_ACTIVE_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DELETABLE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DELETABLE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DELETABLE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DELETABLE_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DEPRECATED_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DEPRECATED_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DEPRECATED_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_DEPRECATED_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_MODIFIED_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_MODIFIED_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_MODIFIED_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_MODIFIED_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_UPDATABLE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_UPDATABLE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_UPDATABLE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_UPDATABLE_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_VERIFIABLE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_VERIFIABLE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_VERIFIABLE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___IS_VERIFIABLE_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___SORT_ACCOUNTING_REFERENCE_ASC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___SORT_ACCOUNTING_REFERENCE_ASC",
  INVOICE_BATCH_BY_ENTITY_ITEM_ID___SORT_ACCOUNTING_REFERENCE_DESC = "INVOICE_BATCH_BY_ENTITY_ITEM_ID___SORT_ACCOUNTING_REFERENCE_DESC",
  ITEM_AMOUNT_1_ASC = "ITEM_AMOUNT_1_ASC",
  ITEM_AMOUNT_1_DESC = "ITEM_AMOUNT_1_DESC",
  ITEM_AMOUNT_2_ASC = "ITEM_AMOUNT_2_ASC",
  ITEM_AMOUNT_2_DESC = "ITEM_AMOUNT_2_DESC",
  ITEM_AMOUNT_3_ASC = "ITEM_AMOUNT_3_ASC",
  ITEM_AMOUNT_3_DESC = "ITEM_AMOUNT_3_DESC",
  ITEM_AMOUNT_4_ASC = "ITEM_AMOUNT_4_ASC",
  ITEM_AMOUNT_4_DESC = "ITEM_AMOUNT_4_DESC",
  ITEM_AMOUNT_5_ASC = "ITEM_AMOUNT_5_ASC",
  ITEM_AMOUNT_5_DESC = "ITEM_AMOUNT_5_DESC",
  ITEM_DATE_1_ASC = "ITEM_DATE_1_ASC",
  ITEM_DATE_1_DESC = "ITEM_DATE_1_DESC",
  ITEM_DATE_2_ASC = "ITEM_DATE_2_ASC",
  ITEM_DATE_2_DESC = "ITEM_DATE_2_DESC",
  ITEM_DATE_3_ASC = "ITEM_DATE_3_ASC",
  ITEM_DATE_3_DESC = "ITEM_DATE_3_DESC",
  ITEM_DATE_4_ASC = "ITEM_DATE_4_ASC",
  ITEM_DATE_4_DESC = "ITEM_DATE_4_DESC",
  ITEM_DATE_5_ASC = "ITEM_DATE_5_ASC",
  ITEM_DATE_5_DESC = "ITEM_DATE_5_DESC",
  ITEM_DESCRIPTION_1_ASC = "ITEM_DESCRIPTION_1_ASC",
  ITEM_DESCRIPTION_1_DESC = "ITEM_DESCRIPTION_1_DESC",
  ITEM_DESCRIPTION_2_ASC = "ITEM_DESCRIPTION_2_ASC",
  ITEM_DESCRIPTION_2_DESC = "ITEM_DESCRIPTION_2_DESC",
  ITEM_DESCRIPTION_3_ASC = "ITEM_DESCRIPTION_3_ASC",
  ITEM_DESCRIPTION_3_DESC = "ITEM_DESCRIPTION_3_DESC",
  ITEM_DESCRIPTION_4_ASC = "ITEM_DESCRIPTION_4_ASC",
  ITEM_DESCRIPTION_4_DESC = "ITEM_DESCRIPTION_4_DESC",
  ITEM_DESCRIPTION_5_ASC = "ITEM_DESCRIPTION_5_ASC",
  ITEM_DESCRIPTION_5_DESC = "ITEM_DESCRIPTION_5_DESC",
  ITEM_RATE_1_ASC = "ITEM_RATE_1_ASC",
  ITEM_RATE_1_DESC = "ITEM_RATE_1_DESC",
  ITEM_RATE_2_ASC = "ITEM_RATE_2_ASC",
  ITEM_RATE_2_DESC = "ITEM_RATE_2_DESC",
  ITEM_RATE_3_ASC = "ITEM_RATE_3_ASC",
  ITEM_RATE_3_DESC = "ITEM_RATE_3_DESC",
  ITEM_RATE_4_ASC = "ITEM_RATE_4_ASC",
  ITEM_RATE_4_DESC = "ITEM_RATE_4_DESC",
  ITEM_RATE_5_ASC = "ITEM_RATE_5_ASC",
  ITEM_RATE_5_DESC = "ITEM_RATE_5_DESC",
  ITEM_UNIT_1_ASC = "ITEM_UNIT_1_ASC",
  ITEM_UNIT_1_DESC = "ITEM_UNIT_1_DESC",
  ITEM_UNIT_2_ASC = "ITEM_UNIT_2_ASC",
  ITEM_UNIT_2_DESC = "ITEM_UNIT_2_DESC",
  ITEM_UNIT_3_ASC = "ITEM_UNIT_3_ASC",
  ITEM_UNIT_3_DESC = "ITEM_UNIT_3_DESC",
  ITEM_UNIT_4_ASC = "ITEM_UNIT_4_ASC",
  ITEM_UNIT_4_DESC = "ITEM_UNIT_4_DESC",
  ITEM_UNIT_5_ASC = "ITEM_UNIT_5_ASC",
  ITEM_UNIT_5_DESC = "ITEM_UNIT_5_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_BUSINESS_UNIT_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_BUSINESS_UNIT_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_BUSINESS_UNIT_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_BUSINESS_UNIT_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_DEPARTMENT_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_DEPARTMENT_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_DEPARTMENT_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ADDITIONAL_DEPARTMENT_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__CALCULATION_TYPE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__CALCULATION_TYPE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__CALCULATION_TYPE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__CALCULATION_TYPE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_1_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_1_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_1_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_1_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_2_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_2_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_2_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ADDRESS_LINE_2_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ALTERNATE_CONTACT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ALTERNATE_CONTACT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ALTERNATE_CONTACT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ALTERNATE_CONTACT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ATTENTION_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ATTENTION_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ATTENTION_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_ATTENTION_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_CITY_NAME_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_CITY_NAME_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_CITY_NAME_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_CITY_NAME_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_COUNTRY_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_COUNTRY_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_COUNTRY_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_COUNTRY_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_EMAIL_ADDRESS_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_EMAIL_ADDRESS_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_EMAIL_ADDRESS_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_EMAIL_ADDRESS_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MAIN_CONTACT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MAIN_CONTACT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MAIN_CONTACT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MAIN_CONTACT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MOBILE_NUMBER_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MOBILE_NUMBER_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MOBILE_NUMBER_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_MOBILE_NUMBER_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_NOTES_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_NOTES_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_NOTES_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_NOTES_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_POSTAL_CODE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_POSTAL_CODE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_POSTAL_CODE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_POSTAL_CODE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_STATE_REGION_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_STATE_REGION_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_STATE_REGION_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_STATE_REGION_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_TELEPHONE_NUMBER_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_TELEPHONE_NUMBER_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_TELEPHONE_NUMBER_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DELIVERY_TELEPHONE_NUMBER_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DESCRIPTION_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DESCRIPTION_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DESCRIPTION_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DESCRIPTION_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_ACCOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_ACCOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_ACCOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_ACCOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_DESCRIPTION_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_DESCRIPTION_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_DESCRIPTION_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_DESCRIPTION_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_QUANTITY_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_QUANTITY_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_QUANTITY_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_QUANTITY_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_RENTAL_TERM_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_RENTAL_TERM_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_RENTAL_TERM_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_RENTAL_TERM_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TOTAL_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TOTAL_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TOTAL_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TOTAL_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TYPE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TYPE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TYPE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_TYPE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_UNIT_PRICE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_UNIT_PRICE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_UNIT_PRICE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__DISPLAY_PO_ITEM_UNIT_PRICE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__EXPENDITURE_TYPE_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__EXPENDITURE_TYPE_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__EXPENDITURE_TYPE_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__EXPENDITURE_TYPE_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_TRACKED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_TRACKED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_TRACKED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DELIVERY_TRACKED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DISCOUNTED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DISCOUNTED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DISCOUNTED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_DISCOUNTED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_TAXABLE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_TAXABLE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_TAXABLE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__IS_TAXABLE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAYS_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAYS_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAYS_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAYS_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_AMOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_AMOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_AMOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_AMOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_RATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_RATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_RATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_DAY_RATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_EXTENDED_AMOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_EXTENDED_AMOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_EXTENDED_AMOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_EXTENDED_AMOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_FROM_DATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_FROM_DATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_FROM_DATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_FROM_DATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOURS_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOURS_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOURS_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOURS_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_AMOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_AMOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_AMOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_AMOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_RATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_RATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_RATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_HOUR_RATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTHS_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTHS_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTHS_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTHS_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_AMOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_AMOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_AMOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_AMOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_RATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_RATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_RATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_MONTH_RATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_NUMBER_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_NUMBER_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_NUMBER_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_NUMBER_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_QUANTITY_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_QUANTITY_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_QUANTITY_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_QUANTITY_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_RENTAL_DATES_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_RENTAL_DATES_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_RENTAL_DATES_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_RENTAL_DATES_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_TO_DATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_TO_DATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_TO_DATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_TO_DATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_UNIT_PRICE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_UNIT_PRICE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_UNIT_PRICE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_UNIT_PRICE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEKS_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEKS_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEKS_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEKS_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_AMOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_AMOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_AMOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_AMOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_RATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_RATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_RATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_WEEK_RATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEARS_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEARS_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEARS_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEARS_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_AMOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_AMOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_AMOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_AMOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_RATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_RATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_RATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__ITEM_YEAR_RATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ITEM_TYPE_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ITEM_TYPE_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ITEM_TYPE_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__PURCHASE_ORDER_ITEM_TYPE_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SCHEDULED_DELIVERY_DATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SCHEDULED_DELIVERY_DATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SCHEDULED_DELIVERY_DATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SCHEDULED_DELIVERY_DATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SUBJECT_TO_AMOUNT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SUBJECT_TO_AMOUNT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SUBJECT_TO_AMOUNT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__SUBJECT_TO_AMOUNT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__TAX_TYPE_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__TAX_TYPE_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__TAX_TYPE_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__TAX_TYPE_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__UNIT_OF_MEASURE_ITEM_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__UNIT_OF_MEASURE_ITEM_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__UNIT_OF_MEASURE_ITEM_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID__UNIT_OF_MEASURE_ITEM_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___CREATED_DATE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___CREATED_DATE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___CREATED_DATE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___CREATED_DATE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___EXTENDED_ITEM_TOTAL_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___EXTENDED_ITEM_TOTAL_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___EXTENDED_ITEM_TOTAL_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___EXTENDED_ITEM_TOTAL_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_ACTIVE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_ACTIVE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_ACTIVE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_ACTIVE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELETABLE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELETABLE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELETABLE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELETABLE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELIVERY_COMPLETE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELIVERY_COMPLETE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELIVERY_COMPLETE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DELIVERY_COMPLETE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DEPRECATED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DEPRECATED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DEPRECATED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_DEPRECATED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_MODIFIED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_MODIFIED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_MODIFIED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_MODIFIED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PARTIAL_RETURN_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PARTIAL_RETURN_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PARTIAL_RETURN_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PARTIAL_RETURN_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_RETURNED_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_RETURNED_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_RETURNED_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_RETURNED_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_UPDATABLE_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_UPDATABLE_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_UPDATABLE_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___IS_UPDATABLE_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___PURCHASE_ORDER_DELIVERY_ID_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___PURCHASE_ORDER_DELIVERY_ID_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___PURCHASE_ORDER_DELIVERY_ID_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___PURCHASE_ORDER_DELIVERY_ID_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_DESC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SORT_ITEM_NUMBER_ASC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SORT_ITEM_NUMBER_ASC",
  PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SORT_ITEM_NUMBER_DESC = "PURCHASE_ORDER_ITEM_BY_ENTITY_ITEM_ID___SORT_ITEM_NUMBER_DESC",
  REFERENCE_CODE_1_ASC = "REFERENCE_CODE_1_ASC",
  REFERENCE_CODE_1_DESC = "REFERENCE_CODE_1_DESC",
  REFERENCE_CODE_2_ASC = "REFERENCE_CODE_2_ASC",
  REFERENCE_CODE_2_DESC = "REFERENCE_CODE_2_DESC",
  REFERENCE_CODE_3_ASC = "REFERENCE_CODE_3_ASC",
  REFERENCE_CODE_3_DESC = "REFERENCE_CODE_3_DESC",
  REFERENCE_CODE_4_ASC = "REFERENCE_CODE_4_ASC",
  REFERENCE_CODE_4_DESC = "REFERENCE_CODE_4_DESC",
  REFERENCE_CODE_5_ASC = "REFERENCE_CODE_5_ASC",
  REFERENCE_CODE_5_DESC = "REFERENCE_CODE_5_DESC",
  REFERENCE_CODE_6_ASC = "REFERENCE_CODE_6_ASC",
  REFERENCE_CODE_6_DESC = "REFERENCE_CODE_6_DESC",
  REFERENCE_CODE_7_ASC = "REFERENCE_CODE_7_ASC",
  REFERENCE_CODE_7_DESC = "REFERENCE_CODE_7_DESC",
  SET_REFERENCE_ASC = "SET_REFERENCE_ASC",
  SET_REFERENCE_DESC = "SET_REFERENCE_DESC",
  TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__ACCOMMODATION_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__ACCOUNT_REFERENCE_ASC = "TRIP_BY_ENTITY_ITEM_ID__ACCOUNT_REFERENCE_ASC",
  TRIP_BY_ENTITY_ITEM_ID__ACCOUNT_REFERENCE_DESC = "TRIP_BY_ENTITY_ITEM_ID__ACCOUNT_REFERENCE_DESC",
  TRIP_BY_ENTITY_ITEM_ID__AGENCY_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__AGENCY_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__AGENCY_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__AGENCY_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__AGENCY_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__AGENCY_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__AGENCY_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__AGENCY_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__AIRFARE_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__AIRFARE_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__AIRFARE_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__AIRFARE_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__AIRFARE_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__AIRFARE_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__AIRFARE_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__AIRFARE_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__BOOKING_CREATED_ASC = "TRIP_BY_ENTITY_ITEM_ID__BOOKING_CREATED_ASC",
  TRIP_BY_ENTITY_ITEM_ID__BOOKING_CREATED_DESC = "TRIP_BY_ENTITY_ITEM_ID__BOOKING_CREATED_DESC",
  TRIP_BY_ENTITY_ITEM_ID__BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__COMMENTS_ASC = "TRIP_BY_ENTITY_ITEM_ID__COMMENTS_ASC",
  TRIP_BY_ENTITY_ITEM_ID__COMMENTS_DESC = "TRIP_BY_ENTITY_ITEM_ID__COMMENTS_DESC",
  TRIP_BY_ENTITY_ITEM_ID__CONFIRMATION_CODE_ASC = "TRIP_BY_ENTITY_ITEM_ID__CONFIRMATION_CODE_ASC",
  TRIP_BY_ENTITY_ITEM_ID__CONFIRMATION_CODE_DESC = "TRIP_BY_ENTITY_ITEM_ID__CONFIRMATION_CODE_DESC",
  TRIP_BY_ENTITY_ITEM_ID__CONTROL_TOTAL_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__CONTROL_TOTAL_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__CONTROL_TOTAL_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__CONTROL_TOTAL_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__ID_ASC = "TRIP_BY_ENTITY_ITEM_ID__ID_ASC",
  TRIP_BY_ENTITY_ITEM_ID__ID_DESC = "TRIP_BY_ENTITY_ITEM_ID__ID_DESC",
  TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__INCIDENTAL_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__LINKED_ENTITY_ID_ASC = "TRIP_BY_ENTITY_ITEM_ID__LINKED_ENTITY_ID_ASC",
  TRIP_BY_ENTITY_ITEM_ID__LINKED_ENTITY_ID_DESC = "TRIP_BY_ENTITY_ITEM_ID__LINKED_ENTITY_ID_DESC",
  TRIP_BY_ENTITY_ITEM_ID__OTHER_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__OTHER_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__OTHER_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__OTHER_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__OTHER_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__OTHER_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__OTHER_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__OTHER_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__REFERENCE_ITEM_TYPES_ASC = "TRIP_BY_ENTITY_ITEM_ID__REFERENCE_ITEM_TYPES_ASC",
  TRIP_BY_ENTITY_ITEM_ID__REFERENCE_ITEM_TYPES_DESC = "TRIP_BY_ENTITY_ITEM_ID__REFERENCE_ITEM_TYPES_DESC",
  TRIP_BY_ENTITY_ITEM_ID__ROW_SECURITY_ID_ASC = "TRIP_BY_ENTITY_ITEM_ID__ROW_SECURITY_ID_ASC",
  TRIP_BY_ENTITY_ITEM_ID__ROW_SECURITY_ID_DESC = "TRIP_BY_ENTITY_ITEM_ID__ROW_SECURITY_ID_DESC",
  TRIP_BY_ENTITY_ITEM_ID__SORT_TRAVELER_NAME_ASC = "TRIP_BY_ENTITY_ITEM_ID__SORT_TRAVELER_NAME_ASC",
  TRIP_BY_ENTITY_ITEM_ID__SORT_TRAVELER_NAME_DESC = "TRIP_BY_ENTITY_ITEM_ID__SORT_TRAVELER_NAME_DESC",
  TRIP_BY_ENTITY_ITEM_ID__SPECIAL_INSTRUCTIONS_ASC = "TRIP_BY_ENTITY_ITEM_ID__SPECIAL_INSTRUCTIONS_ASC",
  TRIP_BY_ENTITY_ITEM_ID__SPECIAL_INSTRUCTIONS_DESC = "TRIP_BY_ENTITY_ITEM_ID__SPECIAL_INSTRUCTIONS_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRAIN_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRAIN_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRAIN_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRAIN_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRAIN_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRAIN_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRAIN_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRAIN_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRANSPORTATION_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRAVELER_ID_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRAVELER_ID_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRAVELER_ID_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRAVELER_ID_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRAVELER_REFERENCE_NAME_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRAVELER_REFERENCE_NAME_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRAVELER_REFERENCE_NAME_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRAVELER_REFERENCE_NAME_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRAVEL_AUTHORIZATION_ID_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRAVEL_AUTHORIZATION_ID_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRAVEL_AUTHORIZATION_ID_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRAVEL_AUTHORIZATION_ID_DESC",
  TRIP_BY_ENTITY_ITEM_ID__TRIP_LOCATOR_ASC = "TRIP_BY_ENTITY_ITEM_ID__TRIP_LOCATOR_ASC",
  TRIP_BY_ENTITY_ITEM_ID__TRIP_LOCATOR_DESC = "TRIP_BY_ENTITY_ITEM_ID__TRIP_LOCATOR_DESC",
  TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ITEM_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_1_ASC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_1_ASC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_1_DESC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_1_DESC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_2_ASC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_2_ASC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_2_DESC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_2_DESC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_3_ASC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_3_ASC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_3_DESC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_INFO_3_DESC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_1_ASC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_1_ASC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_1_DESC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_1_DESC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_2_ASC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_2_ASC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_2_DESC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_2_DESC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_3_ASC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_3_ASC",
  TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_3_DESC = "TRIP_BY_ENTITY_ITEM_ID__WARNING_VALUE_3_DESC",
  TRIP_BY_ENTITY_ITEM_ID___COMPANY_ID_ASC = "TRIP_BY_ENTITY_ITEM_ID___COMPANY_ID_ASC",
  TRIP_BY_ENTITY_ITEM_ID___COMPANY_ID_DESC = "TRIP_BY_ENTITY_ITEM_ID___COMPANY_ID_DESC",
  TRIP_BY_ENTITY_ITEM_ID___CREATED_DATE_ASC = "TRIP_BY_ENTITY_ITEM_ID___CREATED_DATE_ASC",
  TRIP_BY_ENTITY_ITEM_ID___CREATED_DATE_DESC = "TRIP_BY_ENTITY_ITEM_ID___CREATED_DATE_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_ACTIVE_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_ACTIVE_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_ACTIVE_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_ACTIVE_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_DELETABLE_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_DELETABLE_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_DELETABLE_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_DELETABLE_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_DEPRECATED_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_DEPRECATED_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_DEPRECATED_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_DEPRECATED_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_ENVIRONMENTALS_EXIST_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_ENVIRONMENTALS_EXIST_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_MODIFIED_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_MODIFIED_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_MODIFIED_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_MODIFIED_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_OVER_BUDGET_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_OVER_BUDGET_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_OVER_BUDGET_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_OVER_BUDGET_DESC",
  TRIP_BY_ENTITY_ITEM_ID___IS_UPDATABLE_ASC = "TRIP_BY_ENTITY_ITEM_ID___IS_UPDATABLE_ASC",
  TRIP_BY_ENTITY_ITEM_ID___IS_UPDATABLE_DESC = "TRIP_BY_ENTITY_ITEM_ID___IS_UPDATABLE_DESC",
  TRIP_BY_ENTITY_ITEM_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRIP_BY_ENTITY_ITEM_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRIP_BY_ENTITY_ITEM_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRIP_BY_ENTITY_ITEM_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRIP_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_ASC = "TRIP_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_ASC",
  TRIP_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_DESC = "TRIP_BY_ENTITY_ITEM_ID___ROW_TIMESTAMP_DESC",
  TRIP_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_ASC = "TRIP_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_ASC",
  TRIP_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_DESC = "TRIP_BY_ENTITY_ITEM_ID___SEARCH_DOCUMENT_DESC",
  TRIP_BY_ENTITY_ITEM_ID___STATUS_TYPE_ID_ASC = "TRIP_BY_ENTITY_ITEM_ID___STATUS_TYPE_ID_ASC",
  TRIP_BY_ENTITY_ITEM_ID___STATUS_TYPE_ID_DESC = "TRIP_BY_ENTITY_ITEM_ID___STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_1_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_2_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_3_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_4_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__AMOUNT_5_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_BRANCH_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_GROUP_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PERSONA_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TOPIC_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TREE_LEVEL_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVAL_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APPROVER_USER_PROFILE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__APP_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_APPROVED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_DIGITAL_SIGNATURE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_REJECTED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BRANCH_STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__BUSINESS_UNIT_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CORPORATE_PERIOD_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__CURRENCY_ISO_CODE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_1_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_2_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_3_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_4_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DATE_5_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DEPARTMENT_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_10_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_1_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_2_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_3_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_4_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_5_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_6_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_7_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_8_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DESCRIPTION_9_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DIGITAL_SIGNATURE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_COMPANY_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_APPROVAL_TREE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ENTITY_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__FINALIZED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ICON_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ADDING_ALLOWED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_APPROVER_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_AUTO_APPROVAL_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_HISTORY_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_OBSERVER_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PENDING_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REJECTED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REQUESTER_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_REVOKED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__IS_URGENCY_UPDATE_ALLOWED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LAYOUT_TYPE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__LINKED_ENTITY_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__PROJECT_PERIOD_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_1_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_2_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_3_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_4_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__RATE_5_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REJECTED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_NAME_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUESTER_USER_PROFILE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REQUIRED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_NAME_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_BY_USER_PROFILE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__REVOKED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__SORT_ORDER_GROUP_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_APPROVED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_REJECTED_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__TREE_STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_1_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_2_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_3_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_4_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__UNIT_5_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID__URGENCY_LEVEL_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___COMPANY_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_DOCUMENTS_EXIST_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_LOOKUP_NAME_APPLIED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_NOTES_EXIST_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_REVOCABLE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TAGS_EXIST_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_TRANSACTION_CANCELLED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_URGENT_APPROVAL_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___IS_VALIDATING_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___LOOKUP_NAME_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PENDING_APPROVERS_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_END_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_ID_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_START_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___PERIOD_YEAR_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___SEARCH_DOCUMENT_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_DATE_DESC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_ASC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_ASC",
  USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_DESC = "USER_APPROVAL_BY_DISPLAY_ENTITY_APPROVAL_ID___VERIFICATION_LINK_TYPE_ID_DESC",
  USER_BY_VERIFICATION_USER_ID__DIGITAL_SIGNATURE_ASC = "USER_BY_VERIFICATION_USER_ID__DIGITAL_SIGNATURE_ASC",
  USER_BY_VERIFICATION_USER_ID__DIGITAL_SIGNATURE_DESC = "USER_BY_VERIFICATION_USER_ID__DIGITAL_SIGNATURE_DESC",
  USER_BY_VERIFICATION_USER_ID__EMAIL_ACCOUNT_ASC = "USER_BY_VERIFICATION_USER_ID__EMAIL_ACCOUNT_ASC",
  USER_BY_VERIFICATION_USER_ID__EMAIL_ACCOUNT_DESC = "USER_BY_VERIFICATION_USER_ID__EMAIL_ACCOUNT_DESC",
  USER_BY_VERIFICATION_USER_ID__ID_ASC = "USER_BY_VERIFICATION_USER_ID__ID_ASC",
  USER_BY_VERIFICATION_USER_ID__ID_DESC = "USER_BY_VERIFICATION_USER_ID__ID_DESC",
  USER_BY_VERIFICATION_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC = "USER_BY_VERIFICATION_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC",
  USER_BY_VERIFICATION_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC = "USER_BY_VERIFICATION_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC",
  USER_BY_VERIFICATION_USER_ID__IS_DRAFT_ASC = "USER_BY_VERIFICATION_USER_ID__IS_DRAFT_ASC",
  USER_BY_VERIFICATION_USER_ID__IS_DRAFT_DESC = "USER_BY_VERIFICATION_USER_ID__IS_DRAFT_DESC",
  USER_BY_VERIFICATION_USER_ID__IS_RESTRICTED_USER_ASC = "USER_BY_VERIFICATION_USER_ID__IS_RESTRICTED_USER_ASC",
  USER_BY_VERIFICATION_USER_ID__IS_RESTRICTED_USER_DESC = "USER_BY_VERIFICATION_USER_ID__IS_RESTRICTED_USER_DESC",
  USER_BY_VERIFICATION_USER_ID__IS_SIGNIN_ALLOWED_ASC = "USER_BY_VERIFICATION_USER_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_BY_VERIFICATION_USER_ID__IS_SIGNIN_ALLOWED_DESC = "USER_BY_VERIFICATION_USER_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_BY_VERIFICATION_USER_ID__LANGUAGE_ID_ASC = "USER_BY_VERIFICATION_USER_ID__LANGUAGE_ID_ASC",
  USER_BY_VERIFICATION_USER_ID__LANGUAGE_ID_DESC = "USER_BY_VERIFICATION_USER_ID__LANGUAGE_ID_DESC",
  USER_BY_VERIFICATION_USER_ID__NAME_ASC = "USER_BY_VERIFICATION_USER_ID__NAME_ASC",
  USER_BY_VERIFICATION_USER_ID__NAME_DESC = "USER_BY_VERIFICATION_USER_ID__NAME_DESC",
  USER_BY_VERIFICATION_USER_ID__TENANT_ID_ASC = "USER_BY_VERIFICATION_USER_ID__TENANT_ID_ASC",
  USER_BY_VERIFICATION_USER_ID__TENANT_ID_DESC = "USER_BY_VERIFICATION_USER_ID__TENANT_ID_DESC",
  USER_BY_VERIFICATION_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_BY_VERIFICATION_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_BY_VERIFICATION_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_BY_VERIFICATION_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  USER_BY_VERIFICATION_USER_ID___CREATED_BY_USERID_ASC = "USER_BY_VERIFICATION_USER_ID___CREATED_BY_USERID_ASC",
  USER_BY_VERIFICATION_USER_ID___CREATED_BY_USERID_DESC = "USER_BY_VERIFICATION_USER_ID___CREATED_BY_USERID_DESC",
  USER_BY_VERIFICATION_USER_ID___CREATED_DATE_ASC = "USER_BY_VERIFICATION_USER_ID___CREATED_DATE_ASC",
  USER_BY_VERIFICATION_USER_ID___CREATED_DATE_DESC = "USER_BY_VERIFICATION_USER_ID___CREATED_DATE_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_ASC = "USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_DESC = "USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_TENANT_USER_ASC = "USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_TENANT_USER_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_TENANT_USER_DESC = "USER_BY_VERIFICATION_USER_ID___IS_ACTIVE_TENANT_USER_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_BY_VERIFICATION_USER_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_BY_VERIFICATION_USER_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_APPROVED_ASC = "USER_BY_VERIFICATION_USER_ID___IS_APPROVED_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_APPROVED_DESC = "USER_BY_VERIFICATION_USER_ID___IS_APPROVED_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC = "USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC = "USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_USER_ASC = "USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_USER_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_USER_DESC = "USER_BY_VERIFICATION_USER_ID___IS_AUTHORIZED_USER_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_DELETABLE_ASC = "USER_BY_VERIFICATION_USER_ID___IS_DELETABLE_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_DELETABLE_DESC = "USER_BY_VERIFICATION_USER_ID___IS_DELETABLE_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_DEPRECATED_ASC = "USER_BY_VERIFICATION_USER_ID___IS_DEPRECATED_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_DEPRECATED_DESC = "USER_BY_VERIFICATION_USER_ID___IS_DEPRECATED_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_EMAIL_EXISTS_ASC = "USER_BY_VERIFICATION_USER_ID___IS_EMAIL_EXISTS_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_EMAIL_EXISTS_DESC = "USER_BY_VERIFICATION_USER_ID___IS_EMAIL_EXISTS_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_MODIFIED_ASC = "USER_BY_VERIFICATION_USER_ID___IS_MODIFIED_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_MODIFIED_DESC = "USER_BY_VERIFICATION_USER_ID___IS_MODIFIED_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_BY_VERIFICATION_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_BY_VERIFICATION_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_TENANT_USER_EXISTS_ASC = "USER_BY_VERIFICATION_USER_ID___IS_TENANT_USER_EXISTS_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_TENANT_USER_EXISTS_DESC = "USER_BY_VERIFICATION_USER_ID___IS_TENANT_USER_EXISTS_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_UPDATABLE_ASC = "USER_BY_VERIFICATION_USER_ID___IS_UPDATABLE_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_UPDATABLE_DESC = "USER_BY_VERIFICATION_USER_ID___IS_UPDATABLE_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVER_ASC = "USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVER_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVER_DESC = "USER_BY_VERIFICATION_USER_ID___IS_USER_APPROVER_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_OBSERVER_ASC = "USER_BY_VERIFICATION_USER_ID___IS_USER_OBSERVER_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_OBSERVER_DESC = "USER_BY_VERIFICATION_USER_ID___IS_USER_OBSERVER_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_BY_VERIFICATION_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_REQUESTER_ASC = "USER_BY_VERIFICATION_USER_ID___IS_USER_REQUESTER_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_REQUESTER_DESC = "USER_BY_VERIFICATION_USER_ID___IS_USER_REQUESTER_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_REVOKER_ASC = "USER_BY_VERIFICATION_USER_ID___IS_USER_REVOKER_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_USER_REVOKER_DESC = "USER_BY_VERIFICATION_USER_ID___IS_USER_REVOKER_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_VALIDATED_ASC = "USER_BY_VERIFICATION_USER_ID___IS_VALIDATED_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_VALIDATED_DESC = "USER_BY_VERIFICATION_USER_ID___IS_VALIDATED_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_WAITING_APPROVAL_ASC = "USER_BY_VERIFICATION_USER_ID___IS_WAITING_APPROVAL_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_WAITING_APPROVAL_DESC = "USER_BY_VERIFICATION_USER_ID___IS_WAITING_APPROVAL_DESC",
  USER_BY_VERIFICATION_USER_ID___IS_WAITING_VALIDATION_ASC = "USER_BY_VERIFICATION_USER_ID___IS_WAITING_VALIDATION_ASC",
  USER_BY_VERIFICATION_USER_ID___IS_WAITING_VALIDATION_DESC = "USER_BY_VERIFICATION_USER_ID___IS_WAITING_VALIDATION_DESC",
  USER_BY_VERIFICATION_USER_ID___ROW_TIMESTAMP_ASC = "USER_BY_VERIFICATION_USER_ID___ROW_TIMESTAMP_ASC",
  USER_BY_VERIFICATION_USER_ID___ROW_TIMESTAMP_DESC = "USER_BY_VERIFICATION_USER_ID___ROW_TIMESTAMP_DESC",
  USER_BY_VERIFICATION_USER_ID___STATUS_TYPE_ID_ASC = "USER_BY_VERIFICATION_USER_ID___STATUS_TYPE_ID_ASC",
  USER_BY_VERIFICATION_USER_ID___STATUS_TYPE_ID_DESC = "USER_BY_VERIFICATION_USER_ID___STATUS_TYPE_ID_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ASC = "_ACCOUNTING_STAMP_USER_ASC",
  _ACCOUNTING_STAMP_USER_DESC = "_ACCOUNTING_STAMP_USER_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _BATCH_COMMENT_ASC = "_BATCH_COMMENT_ASC",
  _BATCH_COMMENT_DESC = "_BATCH_COMMENT_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_VERIFIED_ASC = "_IS_VERIFIED_ASC",
  _IS_VERIFIED_DESC = "_IS_VERIFIED_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _VERIFICATION_COMMENT_ASC = "_VERIFICATION_COMMENT_ASC",
  _VERIFICATION_COMMENT_DESC = "_VERIFICATION_COMMENT_DESC",
  _VERIFICATION_DATE_ASC = "_VERIFICATION_DATE_ASC",
  _VERIFICATION_DATE_DESC = "_VERIFICATION_DATE_DESC",
  _VERIFICATION_USER_ID_ASC = "_VERIFICATION_USER_ID_ASC",
  _VERIFICATION_USER_ID_DESC = "_VERIFICATION_USER_ID_DESC",
}

/**
 * Methods to use when ordering `ApprovalMapType`.
 */
export enum ApprovalMapTypesOrderBy {
  APPROVAL_TYPE_ASC = "APPROVAL_TYPE_ASC",
  APPROVAL_TYPE_DESC = "APPROVAL_TYPE_DESC",
  APP_ID_ASC = "APP_ID_ASC",
  APP_ID_DESC = "APP_ID_DESC",
  DIGITAL_SIGNATURE_ASC = "DIGITAL_SIGNATURE_ASC",
  DIGITAL_SIGNATURE_DESC = "DIGITAL_SIGNATURE_DESC",
  DISPLAY_ENTITY_APPROVAL_ID_ASC = "DISPLAY_ENTITY_APPROVAL_ID_ASC",
  DISPLAY_ENTITY_APPROVAL_ID_DESC = "DISPLAY_ENTITY_APPROVAL_ID_DESC",
  ENTITY_APPROVAL_ID_ASC = "ENTITY_APPROVAL_ID_ASC",
  ENTITY_APPROVAL_ID_DESC = "ENTITY_APPROVAL_ID_DESC",
  ENTITY_ID_ASC = "ENTITY_ID_ASC",
  ENTITY_ID_DESC = "ENTITY_ID_DESC",
  ENTITY_TYPE_ID_ASC = "ENTITY_TYPE_ID_ASC",
  ENTITY_TYPE_ID_DESC = "ENTITY_TYPE_ID_DESC",
  ERROR_MESSAGE_ASC = "ERROR_MESSAGE_ASC",
  ERROR_MESSAGE_DESC = "ERROR_MESSAGE_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_ADDED_APPROVER_ASC = "IS_ADDED_APPROVER_ASC",
  IS_ADDED_APPROVER_DESC = "IS_ADDED_APPROVER_DESC",
  IS_APPROVAL_ASC = "IS_APPROVAL_ASC",
  IS_APPROVAL_DESC = "IS_APPROVAL_DESC",
  IS_APPROVED_ASC = "IS_APPROVED_ASC",
  IS_APPROVED_DESC = "IS_APPROVED_DESC",
  IS_BRANCH_ASC = "IS_BRANCH_ASC",
  IS_BRANCH_DESC = "IS_BRANCH_DESC",
  IS_ERROR_FOUND_ASC = "IS_ERROR_FOUND_ASC",
  IS_ERROR_FOUND_DESC = "IS_ERROR_FOUND_DESC",
  IS_PENDING_ASC = "IS_PENDING_ASC",
  IS_PENDING_DESC = "IS_PENDING_DESC",
  IS_REJECTED_ASC = "IS_REJECTED_ASC",
  IS_REJECTED_DESC = "IS_REJECTED_DESC",
  IS_REVOKED_ASC = "IS_REVOKED_ASC",
  IS_REVOKED_DESC = "IS_REVOKED_DESC",
  IS_TREE_ASC = "IS_TREE_ASC",
  IS_TREE_DESC = "IS_TREE_DESC",
  LEVEL_ASC = "LEVEL_ASC",
  LEVEL_DESC = "LEVEL_DESC",
  MESSAGE_DISPLAY_APPROVAL_ENTITY_ID_ASC = "MESSAGE_DISPLAY_APPROVAL_ENTITY_ID_ASC",
  MESSAGE_DISPLAY_APPROVAL_ENTITY_ID_DESC = "MESSAGE_DISPLAY_APPROVAL_ENTITY_ID_DESC",
  MESSAGE_USER_ID_ASC = "MESSAGE_USER_ID_ASC",
  MESSAGE_USER_ID_DESC = "MESSAGE_USER_ID_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PARENT_ID_ASC = "PARENT_ID_ASC",
  PARENT_ID_DESC = "PARENT_ID_DESC",
  REQUESTED_DATE_ASC = "REQUESTED_DATE_ASC",
  REQUESTED_DATE_DESC = "REQUESTED_DATE_DESC",
  SEQUENCE_ASC = "SEQUENCE_ASC",
  SEQUENCE_DESC = "SEQUENCE_DESC",
  SORT_ORDER_ASC = "SORT_ORDER_ASC",
  SORT_ORDER_DESC = "SORT_ORDER_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DATE_ASC = "STATUS_DATE_ASC",
  STATUS_DATE_DESC = "STATUS_DATE_DESC",
  STATUS_DESC = "STATUS_DESC",
  SUB_TOPIC_ASC = "SUB_TOPIC_ASC",
  SUB_TOPIC_DESC = "SUB_TOPIC_DESC",
  TOPIC_ASC = "TOPIC_ASC",
  TOPIC_DESC = "TOPIC_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
}

export enum ApprovalPersonas {
  APPROVER = "APPROVER",
  APPROVER_DELEGATE = "APPROVER_DELEGATE",
  OBSERVER = "OBSERVER",
  OBSERVER_DELEGATION = "OBSERVER_DELEGATION",
  REQUESTER = "REQUESTER",
  REQUESTER_APPROVER = "REQUESTER_APPROVER",
}

export enum ApprovalState {
  AMENDED = "AMENDED",
  APPROVED = "APPROVED",
  APPROVED_NOT_REQUIRED = "APPROVED_NOT_REQUIRED",
  CLOSED = "CLOSED",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  VOIDED = "VOIDED",
}

/**
 * Methods to use when ordering `Approval`.
 */
export enum ApprovalsOrderBy {
  ABBREVIATION_ASC = "ABBREVIATION_ASC",
  ABBREVIATION_DESC = "ABBREVIATION_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_PHASE_BY_ID__ID_ASC = "APPROVAL_PHASE_BY_ID__ID_ASC",
  APPROVAL_PHASE_BY_ID__ID_DESC = "APPROVAL_PHASE_BY_ID__ID_DESC",
  APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC = "APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC",
  APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC = "APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC",
  APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_SELECTION_CONDITIONS_BY_APPROVAL_ID__COUNT_ASC = "APPROVAL_SELECTION_CONDITIONS_BY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_SELECTION_CONDITIONS_BY_APPROVAL_ID__COUNT_DESC = "APPROVAL_SELECTION_CONDITIONS_BY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_SETUP_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SETUP_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SETUP_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SETUP_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_TREES_BY_APPROVAL_ID__COUNT_ASC = "APPROVAL_TREES_BY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_TREES_BY_APPROVAL_ID__COUNT_DESC = "APPROVAL_TREES_BY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_DESC",
  APPROVAL_TYPE_ID_ASC = "APPROVAL_TYPE_ID_ASC",
  APPROVAL_TYPE_ID_DESC = "APPROVAL_TYPE_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC = "IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC",
  IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC = "IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC",
  IS_ADD_APPROVERS_ELIGIBLE_ASC = "IS_ADD_APPROVERS_ELIGIBLE_ASC",
  IS_ADD_APPROVERS_ELIGIBLE_DESC = "IS_ADD_APPROVERS_ELIGIBLE_DESC",
  IS_DELAYED_APPROVER_ALLOWED_ASC = "IS_DELAYED_APPROVER_ALLOWED_ASC",
  IS_DELAYED_APPROVER_ALLOWED_DESC = "IS_DELAYED_APPROVER_ALLOWED_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PRIORITY_NUMBER_ASC = "PRIORITY_NUMBER_ASC",
  PRIORITY_NUMBER_DESC = "PRIORITY_NUMBER_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_APPROVAL_PHASE_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `BatchTransaction`.
 */
export enum BatchTransactionsOrderBy {
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  BATCH_TRANSACTION_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "BATCH_TRANSACTION_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  BATCH_TRANSACTION_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "BATCH_TRANSACTION_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  CONTROL_TOTAL_AMOUNT_ASC = "CONTROL_TOTAL_AMOUNT_ASC",
  CONTROL_TOTAL_AMOUNT_DESC = "CONTROL_TOTAL_AMOUNT_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BATCHES_BY_BATCH_TRANSACTION_ID__COUNT_ASC = "INVOICE_BATCHES_BY_BATCH_TRANSACTION_ID__COUNT_ASC",
  INVOICE_BATCHES_BY_BATCH_TRANSACTION_ID__COUNT_DESC = "INVOICE_BATCHES_BY_BATCH_TRANSACTION_ID__COUNT_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  POSTING_DATE_ASC = "POSTING_DATE_ASC",
  POSTING_DATE_DESC = "POSTING_DATE_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  REFERENCE_NUMBER_ASC = "REFERENCE_NUMBER_ASC",
  REFERENCE_NUMBER_DESC = "REFERENCE_NUMBER_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC",
  WEEK_NUMBER_ASC = "WEEK_NUMBER_ASC",
  WEEK_NUMBER_DESC = "WEEK_NUMBER_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _BATCH_TRANSACTION_COUNT_ASC = "_BATCH_TRANSACTION_COUNT_ASC",
  _BATCH_TRANSACTION_COUNT_DESC = "_BATCH_TRANSACTION_COUNT_DESC",
  _BATCH_TRANSACTION_TOTAL_ASC = "_BATCH_TRANSACTION_TOTAL_ASC",
  _BATCH_TRANSACTION_TOTAL_DESC = "_BATCH_TRANSACTION_TOTAL_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_BY_USER_NAME_ASC = "_CREATED_BY_USER_NAME_ASC",
  _CREATED_BY_USER_NAME_DESC = "_CREATED_BY_USER_NAME_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACCOUNTING_ENTRY_ASC = "_IS_ACCOUNTING_ENTRY_ASC",
  _IS_ACCOUNTING_ENTRY_DESC = "_IS_ACCOUNTING_ENTRY_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_BATCH_SELECTABLE_ASC = "_IS_BATCH_SELECTABLE_ASC",
  _IS_BATCH_SELECTABLE_DESC = "_IS_BATCH_SELECTABLE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_TRANSACTION_ENTRY_ASC = "_IS_TRANSACTION_ENTRY_ASC",
  _IS_TRANSACTION_ENTRY_DESC = "_IS_TRANSACTION_ENTRY_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_VERIFIABLE_ASC = "_IS_VERIFIABLE_ASC",
  _IS_VERIFIABLE_DESC = "_IS_VERIFIABLE_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _USER_GROUP_ID_ASC = "_USER_GROUP_ID_ASC",
  _USER_GROUP_ID_DESC = "_USER_GROUP_ID_DESC",
}

/**
 * Methods to use when ordering `CardAccount`.
 */
export enum CardAccountsOrderBy {
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  CARD_ACCOUNT_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "CARD_ACCOUNT_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  CARD_ACCOUNT_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "CARD_ACCOUNT_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  CARD_ACCOUNT_TRANSACTIONS_BY_CARD_ACCOUNT_ID__COUNT_ASC = "CARD_ACCOUNT_TRANSACTIONS_BY_CARD_ACCOUNT_ID__COUNT_ASC",
  CARD_ACCOUNT_TRANSACTIONS_BY_CARD_ACCOUNT_ID__COUNT_DESC = "CARD_ACCOUNT_TRANSACTIONS_BY_CARD_ACCOUNT_ID__COUNT_DESC",
  CARD_HOLDERS_BY_CARD_ACCOUNT_ID__COUNT_ASC = "CARD_HOLDERS_BY_CARD_ACCOUNT_ID__COUNT_ASC",
  CARD_HOLDERS_BY_CARD_ACCOUNT_ID__COUNT_DESC = "CARD_HOLDERS_BY_CARD_ACCOUNT_ID__COUNT_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__API_KEY_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__API_KEY_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__API_KEY_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__API_KEY_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__CARD_COMPANY_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__CARD_COMPANY_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__CARD_COMPANY_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__CARD_COMPANY_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID__ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_DOCUMENT_TYPE_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_DOCUMENT_TYPE_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_DOCUMENT_TYPE_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_DOCUMENT_TYPE_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_EXTRACTION_TYPE_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_EXTRACTION_TYPE_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_EXTRACTION_TYPE_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ACCOUNT_EXTRACTION_TYPE_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_DOCUMENT_TYPE_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_DOCUMENT_TYPE_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_DOCUMENT_TYPE_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_DOCUMENT_TYPE_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_EXTRACTION_TYPE_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_EXTRACTION_TYPE_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_EXTRACTION_TYPE_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARDHOLDER_EXTRACTION_TYPE_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_COMPANY_NAME_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_COMPANY_NAME_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_COMPANY_NAME_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_COMPANY_NAME_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_NUMBER_SEARCH_PREFIX_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_NUMBER_SEARCH_PREFIX_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_NUMBER_SEARCH_PREFIX_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CARD_NUMBER_SEARCH_PREFIX_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___COMPANY_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___COMPANY_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___COMPANY_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___COMPANY_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CREATED_DATE_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CREATED_DATE_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CREATED_DATE_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___CREATED_DATE_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_ACTIVE_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_ACTIVE_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_ACTIVE_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_ACTIVE_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CREDIT_CARD_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CREDIT_CARD_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CREDIT_CARD_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_CREDIT_CARD_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEBIT_CARD_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEBIT_CARD_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEBIT_CARD_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEBIT_CARD_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DELETABLE_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DELETABLE_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DELETABLE_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DELETABLE_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEPRECATED_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEPRECATED_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEPRECATED_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_DEPRECATED_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MANUAL_PROCESS_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MANUAL_PROCESS_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MANUAL_PROCESS_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MANUAL_PROCESS_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MODIFIED_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MODIFIED_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MODIFIED_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_MODIFIED_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_PAY_CARD_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_PAY_CARD_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_PAY_CARD_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_PAY_CARD_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_UPDATABLE_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_UPDATABLE_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_UPDATABLE_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___IS_UPDATABLE_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PAYMENT_CARD_TYPE_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PAYMENT_CARD_TYPE_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PAYMENT_CARD_TYPE_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PAYMENT_CARD_TYPE_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PROCESS_TYPE_ID_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PROCESS_TYPE_ID_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PROCESS_TYPE_ID_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___PROCESS_TYPE_ID_DESC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ROW_TIMESTAMP_ASC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ROW_TIMESTAMP_DESC = "COMPANY_CARD_COMPANY_BY_COMPANY_CARD_COMPANY_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CARD_COMPANY_ID_ASC = "COMPANY_CARD_COMPANY_ID_ASC",
  COMPANY_CARD_COMPANY_ID_DESC = "COMPANY_CARD_COMPANY_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_DESC",
  DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_ID_DESC",
  DOCUMENT_TYPE_ID_ASC = "DOCUMENT_TYPE_ID_ASC",
  DOCUMENT_TYPE_ID_DESC = "DOCUMENT_TYPE_ID_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PAYMENT_ACCOUNT_ID_ASC = "PAYMENT_ACCOUNT_ID_ASC",
  PAYMENT_ACCOUNT_ID_DESC = "PAYMENT_ACCOUNT_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `CardHolder`.
 */
export enum CardHoldersOrderBy {
  AVAILABLE_CARD_HOLDER_USER_BY_ID__DEPARTMENT_ID_ASC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__DEPARTMENT_ID_ASC",
  AVAILABLE_CARD_HOLDER_USER_BY_ID__DEPARTMENT_ID_DESC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__DEPARTMENT_ID_DESC",
  AVAILABLE_CARD_HOLDER_USER_BY_ID__ID_ASC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__ID_ASC",
  AVAILABLE_CARD_HOLDER_USER_BY_ID__ID_DESC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__ID_DESC",
  AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_GROUP_ID_ASC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_GROUP_ID_ASC",
  AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_GROUP_ID_DESC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_GROUP_ID_DESC",
  AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_NAME_ASC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_NAME_ASC",
  AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_NAME_DESC = "AVAILABLE_CARD_HOLDER_USER_BY_ID__USER_NAME_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__BUSINESS_UNIT_ID_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__BUSINESS_UNIT_ID_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__BUSINESS_UNIT_ID_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__BUSINESS_UNIT_ID_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__COMPANY_CARD_COMPANY_ID_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__COMPANY_CARD_COMPANY_ID_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__COMPANY_CARD_COMPANY_ID_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__COMPANY_CARD_COMPANY_ID_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DESCRIPTION_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DESCRIPTION_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DESCRIPTION_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DESCRIPTION_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_POOL_ID_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_POOL_ID_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_POOL_ID_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_POOL_ID_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_TYPE_ID_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_TYPE_ID_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_TYPE_ID_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__DOCUMENT_TYPE_ID_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__ID_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__ID_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__ID_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__ID_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__IS_DRAFT_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__IS_DRAFT_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__IS_DRAFT_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__IS_DRAFT_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__NAME_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__NAME_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__NAME_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__NAME_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__PAYMENT_ACCOUNT_ID_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__PAYMENT_ACCOUNT_ID_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__PAYMENT_ACCOUNT_ID_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID__PAYMENT_ACCOUNT_ID_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___CREATED_DATE_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___CREATED_DATE_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___CREATED_DATE_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___CREATED_DATE_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_ACTIVE_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_ACTIVE_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_ACTIVE_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_ACTIVE_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_REVOCABLE_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_REVOCABLE_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_REVOCABLE_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVAL_REVOCABLE_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVED_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVED_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVED_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_APPROVED_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DELETABLE_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DELETABLE_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DELETABLE_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DELETABLE_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DEPRECATED_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DEPRECATED_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DEPRECATED_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_DEPRECATED_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_MODIFIED_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_MODIFIED_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_MODIFIED_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_MODIFIED_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_NOTES_EXIST_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_NOTES_EXIST_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_NOTES_EXIST_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_NOTES_EXIST_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_TAGS_EXIST_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_TAGS_EXIST_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_TAGS_EXIST_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_TAGS_EXIST_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_UPDATABLE_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_UPDATABLE_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_UPDATABLE_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_UPDATABLE_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVER_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVER_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVER_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_APPROVER_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_OBSERVER_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_OBSERVER_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_OBSERVER_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_OBSERVER_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REQUESTER_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REQUESTER_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REQUESTER_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REQUESTER_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REVOKER_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REVOKER_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REVOKER_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_USER_REVOKER_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_VALIDATED_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_VALIDATED_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_VALIDATED_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_VALIDATED_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___PENDING_APPROVERS_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___PENDING_APPROVERS_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___PENDING_APPROVERS_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___PENDING_APPROVERS_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___ROW_TIMESTAMP_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___ROW_TIMESTAMP_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___ROW_TIMESTAMP_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___ROW_TIMESTAMP_DESC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___STATUS_TYPE_ID_ASC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___STATUS_TYPE_ID_ASC",
  CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___STATUS_TYPE_ID_DESC = "CARD_ACCOUNT_BY_CARD_ACCOUNT_ID___STATUS_TYPE_ID_DESC",
  CARD_ACCOUNT_ID_ASC = "CARD_ACCOUNT_ID_ASC",
  CARD_ACCOUNT_ID_DESC = "CARD_ACCOUNT_ID_DESC",
  CARD_DEPARTMENT_ASC = "CARD_DEPARTMENT_ASC",
  CARD_DEPARTMENT_DESC = "CARD_DEPARTMENT_DESC",
  CARD_EXPIRY_ASC = "CARD_EXPIRY_ASC",
  CARD_EXPIRY_DESC = "CARD_EXPIRY_DESC",
  CARD_FIRST_NAME_ASC = "CARD_FIRST_NAME_ASC",
  CARD_FIRST_NAME_DESC = "CARD_FIRST_NAME_DESC",
  CARD_FULL_NAME_ASC = "CARD_FULL_NAME_ASC",
  CARD_FULL_NAME_DESC = "CARD_FULL_NAME_DESC",
  CARD_HOLDER_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "CARD_HOLDER_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  CARD_HOLDER_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "CARD_HOLDER_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  CARD_HOLDER_TRANSACTIONS_BY_CARD_HOLDER_ID__COUNT_ASC = "CARD_HOLDER_TRANSACTIONS_BY_CARD_HOLDER_ID__COUNT_ASC",
  CARD_HOLDER_TRANSACTIONS_BY_CARD_HOLDER_ID__COUNT_DESC = "CARD_HOLDER_TRANSACTIONS_BY_CARD_HOLDER_ID__COUNT_DESC",
  CARD_LAST_NAME_ASC = "CARD_LAST_NAME_ASC",
  CARD_LAST_NAME_DESC = "CARD_LAST_NAME_DESC",
  CARD_NUMBER_ASC = "CARD_NUMBER_ASC",
  CARD_NUMBER_DESC = "CARD_NUMBER_DESC",
  CARD_STATUS_ASC = "CARD_STATUS_ASC",
  CARD_STATUS_DESC = "CARD_STATUS_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DEPARTMENT_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DEFAULT_ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__DESCRIPTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__EXTRACTION_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_COMPANY_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DEPARTMENT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_ENTITY_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_PRIVATE_POOL_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_RETAINED_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__IS_SHAREABLE_DEFAULT_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__NAME_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__PARENT_DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID__USER_GROUP_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___COMPANY_OR_POOLING_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_BY_USERID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___CREATED_DATE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_ACTIVE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DELETABLE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DEPRECATED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_DOCUMENTS_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MESSAGES_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_MODIFIED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_NOTES_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_PROTECTED_VIEW_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_TAGS_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___IS_UPDATABLE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___ROW_TIMESTAMP_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID___SEARCH_DOCUMENT_DESC",
  DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_ID_DESC",
  DOCUMENT_TYPE_ID_ASC = "DOCUMENT_TYPE_ID_ASC",
  DOCUMENT_TYPE_ID_DESC = "DOCUMENT_TYPE_ID_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_MY_DOCUMENTS_USED_ASC = "IS_MY_DOCUMENTS_USED_ASC",
  IS_MY_DOCUMENTS_USED_DESC = "IS_MY_DOCUMENTS_USED_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REFERENCE_CODE_ASC = "REFERENCE_CODE_ASC",
  REFERENCE_CODE_DESC = "REFERENCE_CODE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  TENANT_USER_ID_ASC = "TENANT_USER_ID_ASC",
  TENANT_USER_ID_DESC = "TENANT_USER_ID_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ID_ASC = "USER_GROUP_BY_USER_GROUP_ID__ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ID_DESC = "USER_GROUP_BY_USER_GROUP_ID__ID_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_ASC = "USER_GROUP_BY_USER_GROUP_ID__NAME_ASC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_DESC = "USER_GROUP_BY_USER_GROUP_ID__NAME_DESC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

export enum ChartLayoutType {
  KPI_AGED_PAYABLES = "KPI_AGED_PAYABLES",
  KPI_APPROVAL_TYPES = "KPI_APPROVAL_TYPES",
  KPI_BUSINESS_UNITS = "KPI_BUSINESS_UNITS",
  KPI_COMPANIES = "KPI_COMPANIES",
  KPI_DEPARTMENTS = "KPI_DEPARTMENTS",
  KPI_DOCUMENT_BREAK_DOWN = "KPI_DOCUMENT_BREAK_DOWN",
  KPI_MY_PO_STATUS = "KPI_MY_PO_STATUS",
  KPI_MY_TA_STATUS = "KPI_MY_TA_STATUS",
  KPI_MY_TRANSACTION_STATUS = "KPI_MY_TRANSACTION_STATUS",
  KPI_PO_STATUS = "KPI_PO_STATUS",
  KPI_PO_TAGS = "KPI_PO_TAGS",
  KPI_SUPPLIERS = "KPI_SUPPLIERS",
  KPI_TAGS = "KPI_TAGS",
  KPI_TA_STATUS = "KPI_TA_STATUS",
  KPI_TRANSACTION_STATUS = "KPI_TRANSACTION_STATUS",
  KPI_TRANSACTION_TYPES = "KPI_TRANSACTION_TYPES",
  KPI_TRAVEL_AUTHORIZATION_POLICY_BREAKDOWN = "KPI_TRAVEL_AUTHORIZATION_POLICY_BREAKDOWN",
}

export enum CitizenType {
  AUTHORIZED_ALIEN = "AUTHORIZED_ALIEN",
  CITIZEN = "CITIZEN",
  LAWFUL_PERMANENT_RESIDENT = "LAWFUL_PERMANENT_RESIDENT",
  NON_CITIZEN_NATIONAL = "NON_CITIZEN_NATIONAL",
}

export enum Colors {
  BLUE = "BLUE",
  CYAN = "CYAN",
  GREEN = "GREEN",
  MAGENTA = "MAGENTA",
  RED = "RED",
  YELLOW = "YELLOW",
}

/**
 * Methods to use when ordering `CommunicationChannel`.
 */
export enum CommunicationChannelsOrderBy {
  COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_CHANNEL_ID__COUNT_ASC = "COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_CHANNEL_ID__COUNT_ASC",
  COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_CHANNEL_ID__COUNT_DESC = "COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_CHANNEL_ID__COUNT_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_MESSAGES_ASC = "IS_MESSAGES_ASC",
  IS_MESSAGES_DESC = "IS_MESSAGES_DESC",
  IS_REPORTS_ASC = "IS_REPORTS_ASC",
  IS_REPORTS_DESC = "IS_REPORTS_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  NOTIFICATION_TYPE_ID_ASC = "NOTIFICATION_TYPE_ID_ASC",
  NOTIFICATION_TYPE_ID_DESC = "NOTIFICATION_TYPE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_ALERTS_ASC = "_IS_ALERTS_ASC",
  _IS_ALERTS_DESC = "_IS_ALERTS_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_VALID_COMPLETE_ASC = "_IS_VALID_COMPLETE_ASC",
  _IS_VALID_COMPLETE_DESC = "_IS_VALID_COMPLETE_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _NOTIFICATION_TYPE_ASC = "_NOTIFICATION_TYPE_ASC",
  _NOTIFICATION_TYPE_DESC = "_NOTIFICATION_TYPE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `CommunicationGroupItem`.
 */
export enum CommunicationGroupItemsOrderBy {
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__DESCRIPTION_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__DESCRIPTION_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__DESCRIPTION_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__DESCRIPTION_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__ID_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__ID_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__ID_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__ID_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_DRAFT_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_DRAFT_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_DRAFT_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_DRAFT_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_MESSAGES_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_MESSAGES_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_MESSAGES_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_MESSAGES_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_REPORTS_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_REPORTS_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_REPORTS_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__IS_REPORTS_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NAME_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NOTIFICATION_TYPE_ID_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NOTIFICATION_TYPE_ID_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NOTIFICATION_TYPE_ID_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID__NOTIFICATION_TYPE_ID_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___CREATED_DATE_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___CREATED_DATE_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___CREATED_DATE_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___CREATED_DATE_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ACTIVE_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ACTIVE_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ACTIVE_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ACTIVE_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ALERTS_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ALERTS_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ALERTS_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_ALERTS_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_APPROVED_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_APPROVED_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_APPROVED_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_APPROVED_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DELETABLE_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DELETABLE_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DELETABLE_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DELETABLE_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DEPRECATED_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DEPRECATED_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DEPRECATED_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_DEPRECATED_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_MODIFIED_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_MODIFIED_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_MODIFIED_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_MODIFIED_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_UPDATABLE_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_UPDATABLE_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_UPDATABLE_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_UPDATABLE_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALIDATED_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALIDATED_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALIDATED_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALIDATED_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALID_COMPLETE_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALID_COMPLETE_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALID_COMPLETE_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_VALID_COMPLETE_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_APPROVAL_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_APPROVAL_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_APPROVAL_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_APPROVAL_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_VALIDATION_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_VALIDATION_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_VALIDATION_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___IS_WAITING_VALIDATION_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___NOTIFICATION_TYPE_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___NOTIFICATION_TYPE_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___NOTIFICATION_TYPE_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___NOTIFICATION_TYPE_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___ROW_TIMESTAMP_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___ROW_TIMESTAMP_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___ROW_TIMESTAMP_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___ROW_TIMESTAMP_DESC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___STATUS_TYPE_ID_ASC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___STATUS_TYPE_ID_ASC",
  COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___STATUS_TYPE_ID_DESC = "COMMUNICATION_CHANNEL_BY_COMMUNICATION_CHANNEL_ID___STATUS_TYPE_ID_DESC",
  COMMUNICATION_CHANNEL_ID_ASC = "COMMUNICATION_CHANNEL_ID_ASC",
  COMMUNICATION_CHANNEL_ID_DESC = "COMMUNICATION_CHANNEL_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_RECIPIENT_ONLY_ASC = "IS_RECIPIENT_ONLY_ASC",
  IS_RECIPIENT_ONLY_DESC = "IS_RECIPIENT_ONLY_DESC",
  IS_SENDER_ONLY_ASC = "IS_SENDER_ONLY_ASC",
  IS_SENDER_ONLY_DESC = "IS_SENDER_ONLY_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  _COMMUNICATION_CHANNEL_NAME_ASC = "_COMMUNICATION_CHANNEL_NAME_ASC",
  _COMMUNICATION_CHANNEL_NAME_DESC = "_COMMUNICATION_CHANNEL_NAME_DESC",
  _COMMUNICATION_GROUP_ID_ASC = "_COMMUNICATION_GROUP_ID_ASC",
  _COMMUNICATION_GROUP_ID_DESC = "_COMMUNICATION_GROUP_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `CommunicationGroup`.
 */
export enum CommunicationGroupsOrderBy {
  ABBREVIATION_ASC = "ABBREVIATION_ASC",
  ABBREVIATION_DESC = "ABBREVIATION_DESC",
  COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_GROUP_ID__COUNT_ASC = "COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_GROUP_ID__COUNT_ASC",
  COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_GROUP_ID__COUNT_DESC = "COMMUNICATION_GROUP_ITEMS_BY_COMMUNICATION_GROUP_ID__COUNT_DESC",
  COMPANY_COMMUNICATION_GROUPS_BY_COMMUNICATION_GROUP_ID__COUNT_ASC = "COMPANY_COMMUNICATION_GROUPS_BY_COMMUNICATION_GROUP_ID__COUNT_ASC",
  COMPANY_COMMUNICATION_GROUPS_BY_COMMUNICATION_GROUP_ID__COUNT_DESC = "COMPANY_COMMUNICATION_GROUPS_BY_COMMUNICATION_GROUP_ID__COUNT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_COMMUNICATION_GROUP_ID__COUNT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_COMMUNICATION_GROUP_ID__COUNT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_COMMUNICATION_GROUP_ID__COUNT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_COMMUNICATION_GROUP_ID__COUNT_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  SORT_ORDER_ASC = "SORT_ORDER_ASC",
  SORT_ORDER_DESC = "SORT_ORDER_DESC",
  STATUS_TYPE_ID_ASC = "STATUS_TYPE_ID_ASC",
  STATUS_TYPE_ID_DESC = "STATUS_TYPE_ID_DESC",
  USER_PROFILES_BY_COMMUNICATION_GROUP_ID__COUNT_ASC = "USER_PROFILES_BY_COMMUNICATION_GROUP_ID__COUNT_ASC",
  USER_PROFILES_BY_COMMUNICATION_GROUP_ID__COUNT_DESC = "USER_PROFILES_BY_COMMUNICATION_GROUP_ID__COUNT_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_VALID_COMPLETE_ASC = "_IS_VALID_COMPLETE_ASC",
  _IS_VALID_COMPLETE_DESC = "_IS_VALID_COMPLETE_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `CompanyBusinessUnit`.
 */
export enum CompanyBusinessUnitsOrderBy {
  BATCH_TRANSACTIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "BATCH_TRANSACTIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  BATCH_TRANSACTIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "BATCH_TRANSACTIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  BUSINESS_AREA_ASC = "BUSINESS_AREA_ASC",
  BUSINESS_AREA_DESC = "BUSINESS_AREA_DESC",
  BUSINESS_UNIT_BY_ID__ABBREVIATION_ASC = "BUSINESS_UNIT_BY_ID__ABBREVIATION_ASC",
  BUSINESS_UNIT_BY_ID__ABBREVIATION_DESC = "BUSINESS_UNIT_BY_ID__ABBREVIATION_DESC",
  BUSINESS_UNIT_BY_ID__DESCRIPTION_ASC = "BUSINESS_UNIT_BY_ID__DESCRIPTION_ASC",
  BUSINESS_UNIT_BY_ID__DESCRIPTION_DESC = "BUSINESS_UNIT_BY_ID__DESCRIPTION_DESC",
  BUSINESS_UNIT_BY_ID__ID_ASC = "BUSINESS_UNIT_BY_ID__ID_ASC",
  BUSINESS_UNIT_BY_ID__ID_DESC = "BUSINESS_UNIT_BY_ID__ID_DESC",
  BUSINESS_UNIT_BY_ID__IS_DRAFT_ASC = "BUSINESS_UNIT_BY_ID__IS_DRAFT_ASC",
  BUSINESS_UNIT_BY_ID__IS_DRAFT_DESC = "BUSINESS_UNIT_BY_ID__IS_DRAFT_DESC",
  BUSINESS_UNIT_BY_ID__IS_EXTERNAL_TRADING_PARTNER_ASC = "BUSINESS_UNIT_BY_ID__IS_EXTERNAL_TRADING_PARTNER_ASC",
  BUSINESS_UNIT_BY_ID__IS_EXTERNAL_TRADING_PARTNER_DESC = "BUSINESS_UNIT_BY_ID__IS_EXTERNAL_TRADING_PARTNER_DESC",
  BUSINESS_UNIT_BY_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_ID__LINKED_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_ID__LINKED_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_ID__LINKED_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_ID__LINKED_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_ID__NAME_ASC = "BUSINESS_UNIT_BY_ID__NAME_ASC",
  BUSINESS_UNIT_BY_ID__NAME_DESC = "BUSINESS_UNIT_BY_ID__NAME_DESC",
  BUSINESS_UNIT_BY_ID__ROLLUP_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_ID__ROLLUP_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_ID__ROLLUP_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_ID__ROLLUP_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_ID__SORT_ORDER_ASC = "BUSINESS_UNIT_BY_ID__SORT_ORDER_ASC",
  BUSINESS_UNIT_BY_ID__SORT_ORDER_DESC = "BUSINESS_UNIT_BY_ID__SORT_ORDER_DESC",
  BUSINESS_UNIT_BY_ID___CREATED_DATE_ASC = "BUSINESS_UNIT_BY_ID___CREATED_DATE_ASC",
  BUSINESS_UNIT_BY_ID___CREATED_DATE_DESC = "BUSINESS_UNIT_BY_ID___CREATED_DATE_DESC",
  BUSINESS_UNIT_BY_ID___IS_ACTIVE_ASC = "BUSINESS_UNIT_BY_ID___IS_ACTIVE_ASC",
  BUSINESS_UNIT_BY_ID___IS_ACTIVE_DESC = "BUSINESS_UNIT_BY_ID___IS_ACTIVE_DESC",
  BUSINESS_UNIT_BY_ID___IS_APPROVALS_EXIST_ASC = "BUSINESS_UNIT_BY_ID___IS_APPROVALS_EXIST_ASC",
  BUSINESS_UNIT_BY_ID___IS_APPROVALS_EXIST_DESC = "BUSINESS_UNIT_BY_ID___IS_APPROVALS_EXIST_DESC",
  BUSINESS_UNIT_BY_ID___IS_APPROVED_ASC = "BUSINESS_UNIT_BY_ID___IS_APPROVED_ASC",
  BUSINESS_UNIT_BY_ID___IS_APPROVED_DESC = "BUSINESS_UNIT_BY_ID___IS_APPROVED_DESC",
  BUSINESS_UNIT_BY_ID___IS_DELETABLE_ASC = "BUSINESS_UNIT_BY_ID___IS_DELETABLE_ASC",
  BUSINESS_UNIT_BY_ID___IS_DELETABLE_DESC = "BUSINESS_UNIT_BY_ID___IS_DELETABLE_DESC",
  BUSINESS_UNIT_BY_ID___IS_DEPRECATED_ASC = "BUSINESS_UNIT_BY_ID___IS_DEPRECATED_ASC",
  BUSINESS_UNIT_BY_ID___IS_DEPRECATED_DESC = "BUSINESS_UNIT_BY_ID___IS_DEPRECATED_DESC",
  BUSINESS_UNIT_BY_ID___IS_DOCUMENTS_EXIST_ASC = "BUSINESS_UNIT_BY_ID___IS_DOCUMENTS_EXIST_ASC",
  BUSINESS_UNIT_BY_ID___IS_DOCUMENTS_EXIST_DESC = "BUSINESS_UNIT_BY_ID___IS_DOCUMENTS_EXIST_DESC",
  BUSINESS_UNIT_BY_ID___IS_MESSAGES_EXIST_ASC = "BUSINESS_UNIT_BY_ID___IS_MESSAGES_EXIST_ASC",
  BUSINESS_UNIT_BY_ID___IS_MESSAGES_EXIST_DESC = "BUSINESS_UNIT_BY_ID___IS_MESSAGES_EXIST_DESC",
  BUSINESS_UNIT_BY_ID___IS_MODIFIED_ASC = "BUSINESS_UNIT_BY_ID___IS_MODIFIED_ASC",
  BUSINESS_UNIT_BY_ID___IS_MODIFIED_DESC = "BUSINESS_UNIT_BY_ID___IS_MODIFIED_DESC",
  BUSINESS_UNIT_BY_ID___IS_NOTES_EXIST_ASC = "BUSINESS_UNIT_BY_ID___IS_NOTES_EXIST_ASC",
  BUSINESS_UNIT_BY_ID___IS_NOTES_EXIST_DESC = "BUSINESS_UNIT_BY_ID___IS_NOTES_EXIST_DESC",
  BUSINESS_UNIT_BY_ID___IS_TAGS_EXIST_ASC = "BUSINESS_UNIT_BY_ID___IS_TAGS_EXIST_ASC",
  BUSINESS_UNIT_BY_ID___IS_TAGS_EXIST_DESC = "BUSINESS_UNIT_BY_ID___IS_TAGS_EXIST_DESC",
  BUSINESS_UNIT_BY_ID___IS_UPDATABLE_ASC = "BUSINESS_UNIT_BY_ID___IS_UPDATABLE_ASC",
  BUSINESS_UNIT_BY_ID___IS_UPDATABLE_DESC = "BUSINESS_UNIT_BY_ID___IS_UPDATABLE_DESC",
  BUSINESS_UNIT_BY_ID___IS_VALIDATED_ASC = "BUSINESS_UNIT_BY_ID___IS_VALIDATED_ASC",
  BUSINESS_UNIT_BY_ID___IS_VALIDATED_DESC = "BUSINESS_UNIT_BY_ID___IS_VALIDATED_DESC",
  BUSINESS_UNIT_BY_ID___IS_VALID_COMPLETE_ASC = "BUSINESS_UNIT_BY_ID___IS_VALID_COMPLETE_ASC",
  BUSINESS_UNIT_BY_ID___IS_VALID_COMPLETE_DESC = "BUSINESS_UNIT_BY_ID___IS_VALID_COMPLETE_DESC",
  BUSINESS_UNIT_BY_ID___IS_WAITING_APPROVAL_ASC = "BUSINESS_UNIT_BY_ID___IS_WAITING_APPROVAL_ASC",
  BUSINESS_UNIT_BY_ID___IS_WAITING_APPROVAL_DESC = "BUSINESS_UNIT_BY_ID___IS_WAITING_APPROVAL_DESC",
  BUSINESS_UNIT_BY_ID___IS_WAITING_VALIDATION_ASC = "BUSINESS_UNIT_BY_ID___IS_WAITING_VALIDATION_ASC",
  BUSINESS_UNIT_BY_ID___IS_WAITING_VALIDATION_DESC = "BUSINESS_UNIT_BY_ID___IS_WAITING_VALIDATION_DESC",
  BUSINESS_UNIT_BY_ID___ROW_TIMESTAMP_ASC = "BUSINESS_UNIT_BY_ID___ROW_TIMESTAMP_ASC",
  BUSINESS_UNIT_BY_ID___ROW_TIMESTAMP_DESC = "BUSINESS_UNIT_BY_ID___ROW_TIMESTAMP_DESC",
  BUSINESS_UNIT_BY_ID___STATUS_TYPE_ID_ASC = "BUSINESS_UNIT_BY_ID___STATUS_TYPE_ID_ASC",
  BUSINESS_UNIT_BY_ID___STATUS_TYPE_ID_DESC = "BUSINESS_UNIT_BY_ID___STATUS_TYPE_ID_DESC",
  CHART_OF_ACCOUNT_ID_ASC = "CHART_OF_ACCOUNT_ID_ASC",
  CHART_OF_ACCOUNT_ID_DESC = "CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_ADDRESSES_BY_COMPANY_ID_AND_ENTITY_ID__COUNT_ASC = "COMPANY_ADDRESSES_BY_COMPANY_ID_AND_ENTITY_ID__COUNT_ASC",
  COMPANY_ADDRESSES_BY_COMPANY_ID_AND_ENTITY_ID__COUNT_DESC = "COMPANY_ADDRESSES_BY_COMPANY_ID_AND_ENTITY_ID__COUNT_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_ALIAS_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_ALIAS_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_ALIAS_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_ALIAS_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_1_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_1_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_1_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_1_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_2_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_2_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_2_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_LINE_2_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_OVERRIDE_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_OVERRIDE_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_OVERRIDE_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_OVERRIDE_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_TYPE_ID_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_TYPE_ID_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_TYPE_ID_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ADDRESS_TYPE_ID_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ALTERNATE_CONTACT_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ALTERNATE_CONTACT_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ALTERNATE_CONTACT_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ALTERNATE_CONTACT_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ATTENTION_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ATTENTION_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ATTENTION_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ATTENTION_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__CITY_NAME_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__CITY_NAME_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__CITY_NAME_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__CITY_NAME_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__COUNTRY_ID_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__COUNTRY_ID_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__COUNTRY_ID_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__COUNTRY_ID_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__EMAIL_ADDRESS_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__EMAIL_ADDRESS_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__EMAIL_ADDRESS_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__EMAIL_ADDRESS_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ENTITY_ID_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ENTITY_ID_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ENTITY_ID_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ENTITY_ID_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__GEO_LOCATION_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__GEO_LOCATION_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__GEO_LOCATION_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__GEO_LOCATION_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ID_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ID_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ID_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__ID_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_ADDRESS_OVERRIDDEN_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_ADDRESS_OVERRIDDEN_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_ADDRESS_OVERRIDDEN_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_ADDRESS_OVERRIDDEN_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_DELIVERY_ADDRESS_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_DELIVERY_ADDRESS_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_DELIVERY_ADDRESS_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_DELIVERY_ADDRESS_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_PRIMARY_ADDRESS_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_PRIMARY_ADDRESS_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_PRIMARY_ADDRESS_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__IS_PRIMARY_ADDRESS_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MAIN_CONTACT_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MAIN_CONTACT_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MAIN_CONTACT_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MAIN_CONTACT_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MOBILE_NUMBER_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MOBILE_NUMBER_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MOBILE_NUMBER_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__MOBILE_NUMBER_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__POSTAL_CODE_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__POSTAL_CODE_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__POSTAL_CODE_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__POSTAL_CODE_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__STATE_REGION_ID_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__STATE_REGION_ID_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__STATE_REGION_ID_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__STATE_REGION_ID_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__TELEPHONE_NUMBER_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__TELEPHONE_NUMBER_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__TELEPHONE_NUMBER_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID__TELEPHONE_NUMBER_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___COMPANY_ID_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___COMPANY_ID_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___COMPANY_ID_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___COMPANY_ID_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___CREATED_DATE_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___CREATED_DATE_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___CREATED_DATE_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___CREATED_DATE_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ENTITY_TYPE_ID_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ENTITY_TYPE_ID_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ENTITY_TYPE_ID_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ENTITY_TYPE_ID_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_ACTIVE_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_ACTIVE_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_ACTIVE_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_ACTIVE_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DELETABLE_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DELETABLE_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DELETABLE_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DELETABLE_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DEPRECATED_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DEPRECATED_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DEPRECATED_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_DEPRECATED_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_MODIFIED_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_MODIFIED_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_MODIFIED_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_MODIFIED_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_UPDATABLE_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_UPDATABLE_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_UPDATABLE_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___IS_UPDATABLE_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ROW_TIMESTAMP_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ROW_TIMESTAMP_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ROW_TIMESTAMP_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___ROW_TIMESTAMP_DESC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___SEARCH_DOCUMENT_ASC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___SEARCH_DOCUMENT_ASC",
  COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___SEARCH_DOCUMENT_DESC = "COMPANY_ADDRESS_BY_COMPANY_ADDRESS_ID___SEARCH_DOCUMENT_DESC",
  COMPANY_ADDRESS_ID_ASC = "COMPANY_ADDRESS_ID_ASC",
  COMPANY_ADDRESS_ID_DESC = "COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_CURRENCIES_BY_COMPANY_BUSINESS_UNIT_ID__COUNT_ASC = "COMPANY_BUSINESS_UNIT_CURRENCIES_BY_COMPANY_BUSINESS_UNIT_ID__COUNT_ASC",
  COMPANY_BUSINESS_UNIT_CURRENCIES_BY_COMPANY_BUSINESS_UNIT_ID__COUNT_DESC = "COMPANY_BUSINESS_UNIT_CURRENCIES_BY_COMPANY_BUSINESS_UNIT_ID__COUNT_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_ASC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_ASC",
  COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_DESC = "COMPANY_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_DESC",
  COMPANY_CODE_ASC = "COMPANY_CODE_ASC",
  COMPANY_CODE_DESC = "COMPANY_CODE_DESC",
  COST_CENTER_ASC = "COST_CENTER_ASC",
  COST_CENTER_DESC = "COST_CENTER_DESC",
  DEPARTMENT_BUDGETS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "DEPARTMENT_BUDGETS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  DEPARTMENT_BUDGETS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "DEPARTMENT_BUDGETS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  EMAIL_FIXED_CC_ASC = "EMAIL_FIXED_CC_ASC",
  EMAIL_FIXED_CC_DESC = "EMAIL_FIXED_CC_DESC",
  EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  EMAIL_REPLY_NAME_ASC = "EMAIL_REPLY_NAME_ASC",
  EMAIL_REPLY_NAME_DESC = "EMAIL_REPLY_NAME_DESC",
  EMAIL_REPLY_TO_ASC = "EMAIL_REPLY_TO_ASC",
  EMAIL_REPLY_TO_DESC = "EMAIL_REPLY_TO_DESC",
  GL_ACCOUNT_ASC = "GL_ACCOUNT_ASC",
  GL_ACCOUNT_DESC = "GL_ACCOUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICES_BY_BUSINESS_UNIT_ID__COUNT_ASC = "INVOICES_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  INVOICES_BY_BUSINESS_UNIT_ID__COUNT_DESC = "INVOICES_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  INVOICE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "INVOICE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  INVOICE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "INVOICE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PAYMENTS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "PAYMENTS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  PAYMENTS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "PAYMENTS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  PAY_CYCLES_BY_BUSINESS_UNIT_ID__COUNT_ASC = "PAY_CYCLES_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  PAY_CYCLES_BY_BUSINESS_UNIT_ID__COUNT_DESC = "PAY_CYCLES_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PURCHASE_ORDERS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "PURCHASE_ORDERS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  PURCHASE_ORDERS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "PURCHASE_ORDERS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  TRAVEL_AUTHORIZATIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "TRAVEL_AUTHORIZATIONS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  TRAVEL_AUTHORIZATIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "TRAVEL_AUTHORIZATIONS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  TRIP_BREAKDOWNS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "TRIP_BREAKDOWNS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  TRIP_BREAKDOWNS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "TRIP_BREAKDOWNS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  USER_DEFAULTS_BY_BUSINESS_UNIT_ID__COUNT_ASC = "USER_DEFAULTS_BY_BUSINESS_UNIT_ID__COUNT_ASC",
  USER_DEFAULTS_BY_BUSINESS_UNIT_ID__COUNT_DESC = "USER_DEFAULTS_BY_BUSINESS_UNIT_ID__COUNT_DESC",
  _COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "_COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  _COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "_COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  _COMPANY_BUSINESS_UNIT_ID_ASC = "_COMPANY_BUSINESS_UNIT_ID_ASC",
  _COMPANY_BUSINESS_UNIT_ID_DESC = "_COMPANY_BUSINESS_UNIT_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "_IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  _IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "_IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_PRODUCTION_BUSINESS_UNIT_ASC = "_IS_PRODUCTION_BUSINESS_UNIT_ASC",
  _IS_PRODUCTION_BUSINESS_UNIT_DESC = "_IS_PRODUCTION_BUSINESS_UNIT_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `CompanyCardCompany`.
 */
export enum CompanyCardCompaniesOrderBy {
  API_KEY_ASC = "API_KEY_ASC",
  API_KEY_DESC = "API_KEY_DESC",
  CARD_ACCOUNTS_BY_COMPANY_CARD_COMPANY_ID__COUNT_ASC = "CARD_ACCOUNTS_BY_COMPANY_CARD_COMPANY_ID__COUNT_ASC",
  CARD_ACCOUNTS_BY_COMPANY_CARD_COMPANY_ID__COUNT_DESC = "CARD_ACCOUNTS_BY_COMPANY_CARD_COMPANY_ID__COUNT_DESC",
  CARD_COMPANY_ID_ASC = "CARD_COMPANY_ID_ASC",
  CARD_COMPANY_ID_DESC = "CARD_COMPANY_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  _ACCOUNT_DOCUMENT_TYPE_ID_ASC = "_ACCOUNT_DOCUMENT_TYPE_ID_ASC",
  _ACCOUNT_DOCUMENT_TYPE_ID_DESC = "_ACCOUNT_DOCUMENT_TYPE_ID_DESC",
  _ACCOUNT_EXTRACTION_TYPE_ID_ASC = "_ACCOUNT_EXTRACTION_TYPE_ID_ASC",
  _ACCOUNT_EXTRACTION_TYPE_ID_DESC = "_ACCOUNT_EXTRACTION_TYPE_ID_DESC",
  _CARDHOLDER_DOCUMENT_TYPE_ID_ASC = "_CARDHOLDER_DOCUMENT_TYPE_ID_ASC",
  _CARDHOLDER_DOCUMENT_TYPE_ID_DESC = "_CARDHOLDER_DOCUMENT_TYPE_ID_DESC",
  _CARDHOLDER_EXTRACTION_TYPE_ID_ASC = "_CARDHOLDER_EXTRACTION_TYPE_ID_ASC",
  _CARDHOLDER_EXTRACTION_TYPE_ID_DESC = "_CARDHOLDER_EXTRACTION_TYPE_ID_DESC",
  _CARD_COMPANY_NAME_ASC = "_CARD_COMPANY_NAME_ASC",
  _CARD_COMPANY_NAME_DESC = "_CARD_COMPANY_NAME_DESC",
  _CARD_NUMBER_SEARCH_PREFIX_ASC = "_CARD_NUMBER_SEARCH_PREFIX_ASC",
  _CARD_NUMBER_SEARCH_PREFIX_DESC = "_CARD_NUMBER_SEARCH_PREFIX_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_ASC = "_IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_ASC",
  _IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_DESC = "_IS_CONTROLLING_CARD_ACCOUNT_REQUIRED_DESC",
  _IS_CREDIT_CARD_ASC = "_IS_CREDIT_CARD_ASC",
  _IS_CREDIT_CARD_DESC = "_IS_CREDIT_CARD_DESC",
  _IS_DEBIT_CARD_ASC = "_IS_DEBIT_CARD_ASC",
  _IS_DEBIT_CARD_DESC = "_IS_DEBIT_CARD_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MANUAL_PROCESS_ASC = "_IS_MANUAL_PROCESS_ASC",
  _IS_MANUAL_PROCESS_DESC = "_IS_MANUAL_PROCESS_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_PAY_CARD_ASC = "_IS_PAY_CARD_ASC",
  _IS_PAY_CARD_DESC = "_IS_PAY_CARD_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _PAYMENT_CARD_TYPE_ID_ASC = "_PAYMENT_CARD_TYPE_ID_ASC",
  _PAYMENT_CARD_TYPE_ID_DESC = "_PAYMENT_CARD_TYPE_ID_DESC",
  _PROCESS_TYPE_ID_ASC = "_PROCESS_TYPE_ID_ASC",
  _PROCESS_TYPE_ID_DESC = "_PROCESS_TYPE_ID_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `CompanyCorporatePeriod`.
 */
export enum CompanyCorporatePeriodsOrderBy {
  APPROVAL_HISTORIES_BY_CORPORATE_PERIOD_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_CORPORATE_PERIOD_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_CORPORATE_PERIOD_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_CORPORATE_PERIOD_ID__COUNT_DESC",
  BATCH_TRANSACTIONS_BY_CORPORATE_PERIOD_ID__COUNT_ASC = "BATCH_TRANSACTIONS_BY_CORPORATE_PERIOD_ID__COUNT_ASC",
  BATCH_TRANSACTIONS_BY_CORPORATE_PERIOD_ID__COUNT_DESC = "BATCH_TRANSACTIONS_BY_CORPORATE_PERIOD_ID__COUNT_DESC",
  CLOSED_BY_USER_ID_ASC = "CLOSED_BY_USER_ID_ASC",
  CLOSED_BY_USER_ID_DESC = "CLOSED_BY_USER_ID_DESC",
  CLOSED_DATE_ASC = "CLOSED_DATE_ASC",
  CLOSED_DATE_DESC = "CLOSED_DATE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_BY_USER_ID_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_BY_USER_ID_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_DATE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_DATE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_DATE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__CLOSED_DATE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__END_DATE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__END_DATE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__END_DATE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__END_DATE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__FISCAL_YEAR_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__FISCAL_YEAR_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__FISCAL_YEAR_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__FISCAL_YEAR_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__ID_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__ID_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__ID_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__ID_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_ENTRY_ALLOWED_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_ENTRY_ALLOWED_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_ENTRY_ALLOWED_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_ENTRY_ALLOWED_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_END_ADJUSTMENTS_ALLOWED_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_END_ADJUSTMENTS_ALLOWED_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_END_ADJUSTMENTS_ALLOWED_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_END_ADJUSTMENTS_ALLOWED_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_OPEN_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_OPEN_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_OPEN_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_OPEN_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_PRE_CLOSE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_PRE_CLOSE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_PRE_CLOSE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__IS_YEAR_PRE_CLOSE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_BY_USER_ID_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_BY_USER_ID_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_DATE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_DATE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_DATE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_DATE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_REASON_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_REASON_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_REASON_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__REOPENED_REASON_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__START_DATE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__START_DATE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__START_DATE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__START_DATE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__YEAR_STATUS_TYPE_ID_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__YEAR_STATUS_TYPE_ID_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__YEAR_STATUS_TYPE_ID_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID__YEAR_STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___COMPANY_ID_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___COMPANY_ID_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___COMPANY_ID_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___COMPANY_ID_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___CREATED_DATE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___CREATED_DATE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___CREATED_DATE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___CREATED_DATE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_ACTIVE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_ACTIVE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_ACTIVE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DELETABLE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DELETABLE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DELETABLE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DELETABLE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DEPRECATED_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DEPRECATED_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DEPRECATED_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_DEPRECATED_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_MODIFIED_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_MODIFIED_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_MODIFIED_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_MODIFIED_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_UPDATABLE_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_UPDATABLE_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_UPDATABLE_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___IS_UPDATABLE_DESC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___ROW_TIMESTAMP_ASC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___ROW_TIMESTAMP_DESC = "COMPANY_CORPORATE_YEAR_BY_FISCAL_YEAR_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_BUDGETS_BY_END_CORPORATE_PERIOD_ID__COUNT_ASC = "DEPARTMENT_BUDGETS_BY_END_CORPORATE_PERIOD_ID__COUNT_ASC",
  DEPARTMENT_BUDGETS_BY_END_CORPORATE_PERIOD_ID__COUNT_DESC = "DEPARTMENT_BUDGETS_BY_END_CORPORATE_PERIOD_ID__COUNT_DESC",
  DEPARTMENT_BUDGETS_BY_START_CORPORATE_PERIOD_ID__COUNT_ASC = "DEPARTMENT_BUDGETS_BY_START_CORPORATE_PERIOD_ID__COUNT_ASC",
  DEPARTMENT_BUDGETS_BY_START_CORPORATE_PERIOD_ID__COUNT_DESC = "DEPARTMENT_BUDGETS_BY_START_CORPORATE_PERIOD_ID__COUNT_DESC",
  END_DATE_ASC = "END_DATE_ASC",
  END_DATE_DESC = "END_DATE_DESC",
  FISCAL_PERIOD_ASC = "FISCAL_PERIOD_ASC",
  FISCAL_PERIOD_DESC = "FISCAL_PERIOD_DESC",
  FISCAL_YEAR_ID_ASC = "FISCAL_YEAR_ID_ASC",
  FISCAL_YEAR_ID_DESC = "FISCAL_YEAR_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICES_BY_CORPORATE_PERIOD_ID__COUNT_ASC = "INVOICES_BY_CORPORATE_PERIOD_ID__COUNT_ASC",
  INVOICES_BY_CORPORATE_PERIOD_ID__COUNT_DESC = "INVOICES_BY_CORPORATE_PERIOD_ID__COUNT_DESC",
  IS_ENTRY_ALLOWED_ASC = "IS_ENTRY_ALLOWED_ASC",
  IS_ENTRY_ALLOWED_DESC = "IS_ENTRY_ALLOWED_DESC",
  IS_PERIOD_ADJUSTMENT_ALLOWED_ASC = "IS_PERIOD_ADJUSTMENT_ALLOWED_ASC",
  IS_PERIOD_ADJUSTMENT_ALLOWED_DESC = "IS_PERIOD_ADJUSTMENT_ALLOWED_DESC",
  IS_PERIOD_OPEN_ASC = "IS_PERIOD_OPEN_ASC",
  IS_PERIOD_OPEN_DESC = "IS_PERIOD_OPEN_DESC",
  IS_PERIOD_PRE_CLOSE_ASC = "IS_PERIOD_PRE_CLOSE_ASC",
  IS_PERIOD_PRE_CLOSE_DESC = "IS_PERIOD_PRE_CLOSE_DESC",
  NATURAL = "NATURAL",
  PAYMENTS_BY_CORPORATE_PERIOD_ID__COUNT_ASC = "PAYMENTS_BY_CORPORATE_PERIOD_ID__COUNT_ASC",
  PAYMENTS_BY_CORPORATE_PERIOD_ID__COUNT_DESC = "PAYMENTS_BY_CORPORATE_PERIOD_ID__COUNT_DESC",
  PAY_CYCLES_BY_CORPORATE_PERIOD_ID__COUNT_ASC = "PAY_CYCLES_BY_CORPORATE_PERIOD_ID__COUNT_ASC",
  PAY_CYCLES_BY_CORPORATE_PERIOD_ID__COUNT_DESC = "PAY_CYCLES_BY_CORPORATE_PERIOD_ID__COUNT_DESC",
  PERIOD_STATUS_TYPE_ID_ASC = "PERIOD_STATUS_TYPE_ID_ASC",
  PERIOD_STATUS_TYPE_ID_DESC = "PERIOD_STATUS_TYPE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REOPENED_BY_USER_ID_ASC = "REOPENED_BY_USER_ID_ASC",
  REOPENED_BY_USER_ID_DESC = "REOPENED_BY_USER_ID_DESC",
  REOPENED_DATE_ASC = "REOPENED_DATE_ASC",
  REOPENED_DATE_DESC = "REOPENED_DATE_DESC",
  REOPENED_REASON_ASC = "REOPENED_REASON_ASC",
  REOPENED_REASON_DESC = "REOPENED_REASON_DESC",
  START_DATE_ASC = "START_DATE_ASC",
  START_DATE_DESC = "START_DATE_DESC",
  TRANSACTION_POSTING_CUTOFF_DATE_ASC = "TRANSACTION_POSTING_CUTOFF_DATE_ASC",
  TRANSACTION_POSTING_CUTOFF_DATE_DESC = "TRANSACTION_POSTING_CUTOFF_DATE_DESC",
  USER_APPROVALS_BY_CORPORATE_PERIOD_ID__COUNT_ASC = "USER_APPROVALS_BY_CORPORATE_PERIOD_ID__COUNT_ASC",
  USER_APPROVALS_BY_CORPORATE_PERIOD_ID__COUNT_DESC = "USER_APPROVALS_BY_CORPORATE_PERIOD_ID__COUNT_DESC",
  USER_DEFAULTS_BY_CORPORATE_PERIOD_ID__COUNT_ASC = "USER_DEFAULTS_BY_CORPORATE_PERIOD_ID__COUNT_ASC",
  USER_DEFAULTS_BY_CORPORATE_PERIOD_ID__COUNT_DESC = "USER_DEFAULTS_BY_CORPORATE_PERIOD_ID__COUNT_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_ENTRY_SELECTABLE_ASC = "_IS_ENTRY_SELECTABLE_ASC",
  _IS_ENTRY_SELECTABLE_DESC = "_IS_ENTRY_SELECTABLE_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _PERIOD_YEAR_ASC = "_PERIOD_YEAR_ASC",
  _PERIOD_YEAR_DESC = "_PERIOD_YEAR_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `CompanyCorporateYear`.
 */
export enum CompanyCorporateYearsOrderBy {
  CLOSED_BY_USER_ID_ASC = "CLOSED_BY_USER_ID_ASC",
  CLOSED_BY_USER_ID_DESC = "CLOSED_BY_USER_ID_DESC",
  CLOSED_DATE_ASC = "CLOSED_DATE_ASC",
  CLOSED_DATE_DESC = "CLOSED_DATE_DESC",
  COMPANY_CORPORATE_PERIODS_BY_FISCAL_YEAR_ID__COUNT_ASC = "COMPANY_CORPORATE_PERIODS_BY_FISCAL_YEAR_ID__COUNT_ASC",
  COMPANY_CORPORATE_PERIODS_BY_FISCAL_YEAR_ID__COUNT_DESC = "COMPANY_CORPORATE_PERIODS_BY_FISCAL_YEAR_ID__COUNT_DESC",
  END_DATE_ASC = "END_DATE_ASC",
  END_DATE_DESC = "END_DATE_DESC",
  FISCAL_YEAR_ASC = "FISCAL_YEAR_ASC",
  FISCAL_YEAR_DESC = "FISCAL_YEAR_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_ENTRY_ALLOWED_ASC = "IS_ENTRY_ALLOWED_ASC",
  IS_ENTRY_ALLOWED_DESC = "IS_ENTRY_ALLOWED_DESC",
  IS_YEAR_END_ADJUSTMENTS_ALLOWED_ASC = "IS_YEAR_END_ADJUSTMENTS_ALLOWED_ASC",
  IS_YEAR_END_ADJUSTMENTS_ALLOWED_DESC = "IS_YEAR_END_ADJUSTMENTS_ALLOWED_DESC",
  IS_YEAR_OPEN_ASC = "IS_YEAR_OPEN_ASC",
  IS_YEAR_OPEN_DESC = "IS_YEAR_OPEN_DESC",
  IS_YEAR_PRE_CLOSE_ASC = "IS_YEAR_PRE_CLOSE_ASC",
  IS_YEAR_PRE_CLOSE_DESC = "IS_YEAR_PRE_CLOSE_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REOPENED_BY_USER_ID_ASC = "REOPENED_BY_USER_ID_ASC",
  REOPENED_BY_USER_ID_DESC = "REOPENED_BY_USER_ID_DESC",
  REOPENED_DATE_ASC = "REOPENED_DATE_ASC",
  REOPENED_DATE_DESC = "REOPENED_DATE_DESC",
  REOPENED_REASON_ASC = "REOPENED_REASON_ASC",
  REOPENED_REASON_DESC = "REOPENED_REASON_DESC",
  START_DATE_ASC = "START_DATE_ASC",
  START_DATE_DESC = "START_DATE_DESC",
  YEAR_STATUS_TYPE_ID_ASC = "YEAR_STATUS_TYPE_ID_ASC",
  YEAR_STATUS_TYPE_ID_DESC = "YEAR_STATUS_TYPE_ID_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `CorporateWorkgroup`.
 */
export enum CorporateWorkgroupsOrderBy {
  ABBREVIATION_ASC = "ABBREVIATION_ASC",
  ABBREVIATION_DESC = "ABBREVIATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  CHART_OF_ACCOUNTS_ID_ASC = "CHART_OF_ACCOUNTS_ID_ASC",
  CHART_OF_ACCOUNTS_ID_DESC = "CHART_OF_ACCOUNTS_ID_DESC",
  COMPANIES_BY_CORPORATE_WORKGROUP_ID__COUNT_ASC = "COMPANIES_BY_CORPORATE_WORKGROUP_ID__COUNT_ASC",
  COMPANIES_BY_CORPORATE_WORKGROUP_ID__COUNT_DESC = "COMPANIES_BY_CORPORATE_WORKGROUP_ID__COUNT_DESC",
  CONTROLLING_WORKGROUP_ID_ASC = "CONTROLLING_WORKGROUP_ID_ASC",
  CONTROLLING_WORKGROUP_ID_DESC = "CONTROLLING_WORKGROUP_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ABBREVIATION_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ABBREVIATION_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ABBREVIATION_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ABBREVIATION_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__BUSINESS_AREA_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__BUSINESS_AREA_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__BUSINESS_AREA_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__BUSINESS_AREA_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COMPANY_CODE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COMPANY_CODE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COMPANY_CODE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COMPANY_CODE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COST_CENTER_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COST_CENTER_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COST_CENTER_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__COST_CENTER_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__DESCRIPTION_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__DESCRIPTION_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__DESCRIPTION_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__DESCRIPTION_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__GL_ACCOUNT_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__GL_ACCOUNT_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__GL_ACCOUNT_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__GL_ACCOUNT_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_DRAFT_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_DRAFT_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_DRAFT_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_DRAFT_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_NEW_PROJECT_SELECTABLE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_NEW_PROJECT_SELECTABLE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_NEW_PROJECT_SELECTABLE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_NEW_PROJECT_SELECTABLE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__NAME_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__NAME_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__NAME_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__NAME_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRODUCTION_STATUS_TYPE_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRODUCTION_STATUS_TYPE_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRODUCTION_STATUS_TYPE_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID__PRODUCTION_STATUS_TYPE_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___COMPANY_OR_POOLING_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___COMPANY_OR_POOLING_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___COMPANY_OR_POOLING_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___COMPANY_OR_POOLING_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___CREATED_DATE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___CREATED_DATE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___CREATED_DATE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___CREATED_DATE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_ACTIVE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_ACTIVE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_ACTIVE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_ACTIVE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVALS_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVALS_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVALS_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVALS_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_APPROVED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_CORPORATE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_CORPORATE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_CORPORATE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_CORPORATE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DELETABLE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DELETABLE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DELETABLE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DELETABLE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DEPRECATED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DEPRECATED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DEPRECATED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DEPRECATED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DOCUMENTS_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DOCUMENTS_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DOCUMENTS_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_DOCUMENTS_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MESSAGES_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MESSAGES_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MESSAGES_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MESSAGES_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MODIFIED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MODIFIED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MODIFIED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_MODIFIED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_NOTES_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_NOTES_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_NOTES_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_NOTES_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_TAGS_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_TAGS_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_TAGS_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_TAGS_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_UPDATABLE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_UPDATABLE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_UPDATABLE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_UPDATABLE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALIDATED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALIDATED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALIDATED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALIDATED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALID_COMPLETE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALID_COMPLETE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALID_COMPLETE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_VALID_COMPLETE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_APPROVAL_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_APPROVAL_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_APPROVAL_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_APPROVAL_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_VALIDATION_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_VALIDATION_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_VALIDATION_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___IS_WAITING_VALIDATION_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___ROW_TIMESTAMP_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___ROW_TIMESTAMP_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___ROW_TIMESTAMP_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___ROW_TIMESTAMP_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___STATUS_TYPE_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___STATUS_TYPE_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___STATUS_TYPE_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNTS_ID___STATUS_TYPE_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_NEW_WORKGROUP_SELECTABLE_ASC = "IS_NEW_WORKGROUP_SELECTABLE_ASC",
  IS_NEW_WORKGROUP_SELECTABLE_DESC = "IS_NEW_WORKGROUP_SELECTABLE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PREFIX_CODE_ASC = "PREFIX_CODE_ASC",
  PREFIX_CODE_DESC = "PREFIX_CODE_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__ID_ASC = "PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__ID_ASC",
  PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__ID_DESC = "PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__ID_DESC",
  PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_ASC = "PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_ASC",
  PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_DESC = "PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_DESC",
  PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_SMALLINT_VALUE_ASC = "PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_SMALLINT_VALUE_ASC",
  PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_SMALLINT_VALUE_DESC = "PRODUCTION_PHASE_BY_PRODUCTION_PHASE_ID__STATUS_TYPE_SMALLINT_VALUE_DESC",
  PRODUCTION_PHASE_ID_ASC = "PRODUCTION_PHASE_ID_ASC",
  PRODUCTION_PHASE_ID_DESC = "PRODUCTION_PHASE_ID_DESC",
  PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__ID_ASC = "PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__ID_ASC",
  PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__ID_DESC = "PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__ID_DESC",
  PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_ASC = "PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_DESC = "PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_SMALLINT_VALUE_ASC = "PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_SMALLINT_VALUE_ASC",
  PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_SMALLINT_VALUE_DESC = "PRODUCTION_STATUS_BY_PRODUCTION_STATUS_TYPE_ID__STATUS_TYPE_SMALLINT_VALUE_DESC",
  PRODUCTION_STATUS_TYPE_ID_ASC = "PRODUCTION_STATUS_TYPE_ID_ASC",
  PRODUCTION_STATUS_TYPE_ID_DESC = "PRODUCTION_STATUS_TYPE_ID_DESC",
  SORTED_CODE_ASC = "SORTED_CODE_ASC",
  SORTED_CODE_DESC = "SORTED_CODE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  TRAVEL_AUTHORIZATIONS_BY_CORPORATE_WORKGROUP_ID__COUNT_ASC = "TRAVEL_AUTHORIZATIONS_BY_CORPORATE_WORKGROUP_ID__COUNT_ASC",
  TRAVEL_AUTHORIZATIONS_BY_CORPORATE_WORKGROUP_ID__COUNT_DESC = "TRAVEL_AUTHORIZATIONS_BY_CORPORATE_WORKGROUP_ID__COUNT_DESC",
  WORKGROUP_NUMBER_ASC = "WORKGROUP_NUMBER_ASC",
  WORKGROUP_NUMBER_DESC = "WORKGROUP_NUMBER_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVALS_EXIST_ASC = "_IS_APPROVALS_EXIST_ASC",
  _IS_APPROVALS_EXIST_DESC = "_IS_APPROVALS_EXIST_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_AFTER_APPROVED_ASC = "_IS_UPDATABLE_AFTER_APPROVED_ASC",
  _IS_UPDATABLE_AFTER_APPROVED_DESC = "_IS_UPDATABLE_AFTER_APPROVED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

export enum CrudOperations {
  CREATE = "CREATE",
  DELETE = "DELETE",
  NONE = "NONE",
  UPDATE = "UPDATE",
}

/**
 * Methods to use when ordering `DepartmentGroup`.
 */
export enum DepartmentGroupsOrderBy {
  ABBREVIATION_ASC = "ABBREVIATION_ASC",
  ABBREVIATION_DESC = "ABBREVIATION_DESC",
  APPROVAL_BRANCHES_BY_TRANSACTION_DEPARTMENT_GROUP_ID__COUNT_ASC = "APPROVAL_BRANCHES_BY_TRANSACTION_DEPARTMENT_GROUP_ID__COUNT_ASC",
  APPROVAL_BRANCHES_BY_TRANSACTION_DEPARTMENT_GROUP_ID__COUNT_DESC = "APPROVAL_BRANCHES_BY_TRANSACTION_DEPARTMENT_GROUP_ID__COUNT_DESC",
  APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_DEPARTMENT_GROUP_ID__COUNT_ASC = "APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_DEPARTMENT_GROUP_ID__COUNT_ASC",
  APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_DEPARTMENT_GROUP_ID__COUNT_DESC = "APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_DEPARTMENT_GROUP_ID__COUNT_DESC",
  COMPANY_DEPARTMENT_GROUPS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC = "COMPANY_DEPARTMENT_GROUPS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC",
  COMPANY_DEPARTMENT_GROUPS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC = "COMPANY_DEPARTMENT_GROUPS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC",
  DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC = "DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC",
  DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC = "DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_GROUP_ID__COUNT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_GROUP_ID__COUNT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_GROUP_ID__COUNT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_GROUP_ID__COUNT_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_PRE_APPROVED_ASC = "IS_PRE_APPROVED_ASC",
  IS_PRE_APPROVED_DESC = "IS_PRE_APPROVED_DESC",
  IS_TRAVEL_DEPARTMENT_GROUP_ASC = "IS_TRAVEL_DEPARTMENT_GROUP_ASC",
  IS_TRAVEL_DEPARTMENT_GROUP_DESC = "IS_TRAVEL_DEPARTMENT_GROUP_DESC",
  LOOKUP_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC = "LOOKUP_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC",
  LOOKUP_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC = "LOOKUP_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC = "LOOKUP_CORPORATE_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC = "LOOKUP_CORPORATE_ACCOUNTS_BY_DEPARTMENT_GROUP_ID__COUNT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  SORT_ORDER_ASC = "SORT_ORDER_ASC",
  SORT_ORDER_DESC = "SORT_ORDER_DESC",
  USER_PROFILES_BY_DEPARTMENT_GROUP_ID__COUNT_ASC = "USER_PROFILES_BY_DEPARTMENT_GROUP_ID__COUNT_ASC",
  USER_PROFILES_BY_DEPARTMENT_GROUP_ID__COUNT_DESC = "USER_PROFILES_BY_DEPARTMENT_GROUP_ID__COUNT_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `DepartmentOccupationTemplate`.
 */
export enum DepartmentOccupationTemplatesOrderBy {
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_DESC",
  ACCESS_GROUP_ID_ASC = "ACCESS_GROUP_ID_ASC",
  ACCESS_GROUP_ID_DESC = "ACCESS_GROUP_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_DESC",
  COMMUNICATION_GROUP_ID_ASC = "COMMUNICATION_GROUP_ID_ASC",
  COMMUNICATION_GROUP_ID_DESC = "COMMUNICATION_GROUP_ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC = "COMPANY_DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC",
  COMPANY_DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC = "COMPANY_DEPARTMENT_OCCUPATION_TEMPLATES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_DESC",
  DATA_ACCESS_POLICY_ID_ASC = "DATA_ACCESS_POLICY_ID_ASC",
  DATA_ACCESS_POLICY_ID_DESC = "DATA_ACCESS_POLICY_ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_GROUP_ID_ASC = "DEPARTMENT_GROUP_ID_ASC",
  DEPARTMENT_GROUP_ID_DESC = "DEPARTMENT_GROUP_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DEPARTMENT_OCCUPATION_APPROVALS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC = "DEPARTMENT_OCCUPATION_APPROVALS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC",
  DEPARTMENT_OCCUPATION_APPROVALS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC = "DEPARTMENT_OCCUPATION_APPROVALS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC",
  DEPARTMENT_OCCUPATION_CHARTS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC = "DEPARTMENT_OCCUPATION_CHARTS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC",
  DEPARTMENT_OCCUPATION_CHARTS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC = "DEPARTMENT_OCCUPATION_CHARTS_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATES_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATES_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATES_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATES_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DEPARTMENT_USER_GROUP_REQUIRED_ASC = "IS_DEPARTMENT_USER_GROUP_REQUIRED_ASC",
  IS_DEPARTMENT_USER_GROUP_REQUIRED_DESC = "IS_DEPARTMENT_USER_GROUP_REQUIRED_DESC",
  IS_OCCUPATION_PLACED_IN_DESCRIPTION_ASC = "IS_OCCUPATION_PLACED_IN_DESCRIPTION_ASC",
  IS_OCCUPATION_PLACED_IN_DESCRIPTION_DESC = "IS_OCCUPATION_PLACED_IN_DESCRIPTION_DESC",
  IS_TEMPLATE_CONTROLLED_ASC = "IS_TEMPLATE_CONTROLLED_ASC",
  IS_TEMPLATE_CONTROLLED_DESC = "IS_TEMPLATE_CONTROLLED_DESC",
  IS_USER_DEPARTMENT_DEFAULT_ASC = "IS_USER_DEPARTMENT_DEFAULT_ASC",
  IS_USER_DEPARTMENT_DEFAULT_DESC = "IS_USER_DEPARTMENT_DEFAULT_DESC",
  IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_ASC = "IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_ASC",
  IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_DESC = "IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_DESC",
  IS_USER_PRIMARY_CURRENCY_DEFAULT_ASC = "IS_USER_PRIMARY_CURRENCY_DEFAULT_ASC",
  IS_USER_PRIMARY_CURRENCY_DEFAULT_DESC = "IS_USER_PRIMARY_CURRENCY_DEFAULT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  ROLE_BY_ROLE_ID__ABBREVIATION_ASC = "ROLE_BY_ROLE_ID__ABBREVIATION_ASC",
  ROLE_BY_ROLE_ID__ABBREVIATION_DESC = "ROLE_BY_ROLE_ID__ABBREVIATION_DESC",
  ROLE_BY_ROLE_ID__DESCRIPTION_ASC = "ROLE_BY_ROLE_ID__DESCRIPTION_ASC",
  ROLE_BY_ROLE_ID__DESCRIPTION_DESC = "ROLE_BY_ROLE_ID__DESCRIPTION_DESC",
  ROLE_BY_ROLE_ID__ID_ASC = "ROLE_BY_ROLE_ID__ID_ASC",
  ROLE_BY_ROLE_ID__ID_DESC = "ROLE_BY_ROLE_ID__ID_DESC",
  ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_DRAFT_ASC = "ROLE_BY_ROLE_ID__IS_DRAFT_ASC",
  ROLE_BY_ROLE_ID__IS_DRAFT_DESC = "ROLE_BY_ROLE_ID__IS_DRAFT_DESC",
  ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__NAME_ASC = "ROLE_BY_ROLE_ID__NAME_ASC",
  ROLE_BY_ROLE_ID__NAME_DESC = "ROLE_BY_ROLE_ID__NAME_DESC",
  ROLE_BY_ROLE_ID__ROLE_TYPE_ASC = "ROLE_BY_ROLE_ID__ROLE_TYPE_ASC",
  ROLE_BY_ROLE_ID__ROLE_TYPE_DESC = "ROLE_BY_ROLE_ID__ROLE_TYPE_DESC",
  ROLE_BY_ROLE_ID__SORT_ORDER_ASC = "ROLE_BY_ROLE_ID__SORT_ORDER_ASC",
  ROLE_BY_ROLE_ID__SORT_ORDER_DESC = "ROLE_BY_ROLE_ID__SORT_ORDER_DESC",
  ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_ASC = "ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_ASC",
  ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_DESC = "ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_DESC",
  ROLE_BY_ROLE_ID___CREATED_DATE_ASC = "ROLE_BY_ROLE_ID___CREATED_DATE_ASC",
  ROLE_BY_ROLE_ID___CREATED_DATE_DESC = "ROLE_BY_ROLE_ID___CREATED_DATE_DESC",
  ROLE_BY_ROLE_ID___IS_ACTIVE_ASC = "ROLE_BY_ROLE_ID___IS_ACTIVE_ASC",
  ROLE_BY_ROLE_ID___IS_ACTIVE_DESC = "ROLE_BY_ROLE_ID___IS_ACTIVE_DESC",
  ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_ASC = "ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_ASC",
  ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_DESC = "ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_DESC",
  ROLE_BY_ROLE_ID___IS_APPROVED_ASC = "ROLE_BY_ROLE_ID___IS_APPROVED_ASC",
  ROLE_BY_ROLE_ID___IS_APPROVED_DESC = "ROLE_BY_ROLE_ID___IS_APPROVED_DESC",
  ROLE_BY_ROLE_ID___IS_DELETABLE_ASC = "ROLE_BY_ROLE_ID___IS_DELETABLE_ASC",
  ROLE_BY_ROLE_ID___IS_DELETABLE_DESC = "ROLE_BY_ROLE_ID___IS_DELETABLE_DESC",
  ROLE_BY_ROLE_ID___IS_DEPRECATED_ASC = "ROLE_BY_ROLE_ID___IS_DEPRECATED_ASC",
  ROLE_BY_ROLE_ID___IS_DEPRECATED_DESC = "ROLE_BY_ROLE_ID___IS_DEPRECATED_DESC",
  ROLE_BY_ROLE_ID___IS_MODIFIED_ASC = "ROLE_BY_ROLE_ID___IS_MODIFIED_ASC",
  ROLE_BY_ROLE_ID___IS_MODIFIED_DESC = "ROLE_BY_ROLE_ID___IS_MODIFIED_DESC",
  ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  ROLE_BY_ROLE_ID___IS_UPDATABLE_ASC = "ROLE_BY_ROLE_ID___IS_UPDATABLE_ASC",
  ROLE_BY_ROLE_ID___IS_UPDATABLE_DESC = "ROLE_BY_ROLE_ID___IS_UPDATABLE_DESC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVER_ASC = "ROLE_BY_ROLE_ID___IS_USER_APPROVER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVER_DESC = "ROLE_BY_ROLE_ID___IS_USER_APPROVER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_OBSERVER_ASC = "ROLE_BY_ROLE_ID___IS_USER_OBSERVER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_OBSERVER_DESC = "ROLE_BY_ROLE_ID___IS_USER_OBSERVER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  ROLE_BY_ROLE_ID___IS_USER_REQUESTER_ASC = "ROLE_BY_ROLE_ID___IS_USER_REQUESTER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_REQUESTER_DESC = "ROLE_BY_ROLE_ID___IS_USER_REQUESTER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_REVOKER_ASC = "ROLE_BY_ROLE_ID___IS_USER_REVOKER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_REVOKER_DESC = "ROLE_BY_ROLE_ID___IS_USER_REVOKER_DESC",
  ROLE_BY_ROLE_ID___IS_VALIDATED_ASC = "ROLE_BY_ROLE_ID___IS_VALIDATED_ASC",
  ROLE_BY_ROLE_ID___IS_VALIDATED_DESC = "ROLE_BY_ROLE_ID___IS_VALIDATED_DESC",
  ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_ASC = "ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_ASC",
  ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_DESC = "ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_DESC",
  ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_ASC = "ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_ASC",
  ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_DESC = "ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_DESC",
  ROLE_BY_ROLE_ID___ROW_TIMESTAMP_ASC = "ROLE_BY_ROLE_ID___ROW_TIMESTAMP_ASC",
  ROLE_BY_ROLE_ID___ROW_TIMESTAMP_DESC = "ROLE_BY_ROLE_ID___ROW_TIMESTAMP_DESC",
  ROLE_BY_ROLE_ID___STATUS_TYPE_ID_ASC = "ROLE_BY_ROLE_ID___STATUS_TYPE_ID_ASC",
  ROLE_BY_ROLE_ID___STATUS_TYPE_ID_DESC = "ROLE_BY_ROLE_ID___STATUS_TYPE_ID_DESC",
  ROLE_ID_ASC = "ROLE_ID_ASC",
  ROLE_ID_DESC = "ROLE_ID_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_DESC",
  SECURE_ROW_LEVEL_ID_ASC = "SECURE_ROW_LEVEL_ID_ASC",
  SECURE_ROW_LEVEL_ID_DESC = "SECURE_ROW_LEVEL_ID_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__ID_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__ID_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__ID_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__ID_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__NAME_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__NAME_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__NAME_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__NAME_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_DESC",
  TAG_GROUP_ID_ASC = "TAG_GROUP_ID_ASC",
  TAG_GROUP_ID_DESC = "TAG_GROUP_ID_DESC",
  USER_DEFAULT_COMPANY_ASC = "USER_DEFAULT_COMPANY_ASC",
  USER_DEFAULT_COMPANY_DESC = "USER_DEFAULT_COMPANY_DESC",
  USER_DEFAULT_LOCATION_ASC = "USER_DEFAULT_LOCATION_ASC",
  USER_DEFAULT_LOCATION_DESC = "USER_DEFAULT_LOCATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ID_ASC = "USER_GROUP_BY_USER_GROUP_ID__ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ID_DESC = "USER_GROUP_BY_USER_GROUP_ID__ID_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_ASC = "USER_GROUP_BY_USER_GROUP_ID__NAME_ASC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_DESC = "USER_GROUP_BY_USER_GROUP_ID__NAME_DESC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_DESC",
  USER_OCCUPATION_TITLE_ID_ASC = "USER_OCCUPATION_TITLE_ID_ASC",
  USER_OCCUPATION_TITLE_ID_DESC = "USER_OCCUPATION_TITLE_ID_DESC",
  USER_PROFILES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC = "USER_PROFILES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_ASC",
  USER_PROFILES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC = "USER_PROFILES_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COUNT_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_DESC",
  USER_RANK_TYPE_ID_ASC = "USER_RANK_TYPE_ID_ASC",
  USER_RANK_TYPE_ID_DESC = "USER_RANK_TYPE_ID_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `Department`.
 */
export enum DepartmentsOrderBy {
  ABBREVIATION_ASC = "ABBREVIATION_ASC",
  ABBREVIATION_DESC = "ABBREVIATION_DESC",
  APPROVAL_HISTORIES_BY_DEPARTMENT_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_DEPARTMENT_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_DEPARTMENT_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_DEPARTMENT_ID__COUNT_DESC",
  CARD_HOLDERS_BY_DEPARTMENT_ID__COUNT_ASC = "CARD_HOLDERS_BY_DEPARTMENT_ID__COUNT_ASC",
  CARD_HOLDERS_BY_DEPARTMENT_ID__COUNT_DESC = "CARD_HOLDERS_BY_DEPARTMENT_ID__COUNT_DESC",
  DEPARTMENT_BUDGETS_BY_DEPARTMENT_ID__COUNT_ASC = "DEPARTMENT_BUDGETS_BY_DEPARTMENT_ID__COUNT_ASC",
  DEPARTMENT_BUDGETS_BY_DEPARTMENT_ID__COUNT_DESC = "DEPARTMENT_BUDGETS_BY_DEPARTMENT_ID__COUNT_DESC",
  DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_ID__COUNT_ASC = "DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_ID__COUNT_ASC",
  DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_ID__COUNT_DESC = "DEPARTMENT_GROUP_ITEMS_BY_DEPARTMENT_ID__COUNT_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ABBREVIATION_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ABBREVIATION_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ABBREVIATION_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ABBREVIATION_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__DEPARTMENT_TYPE_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__DEPARTMENT_TYPE_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__DEPARTMENT_TYPE_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__DEPARTMENT_TYPE_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ID_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ID_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ID_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__ID_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ABOVE_THE_LINE_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ABOVE_THE_LINE_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ABOVE_THE_LINE_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ABOVE_THE_LINE_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ACCOUNTING_DEPARTMENT_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ACCOUNTING_DEPARTMENT_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ACCOUNTING_DEPARTMENT_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ACCOUNTING_DEPARTMENT_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ADMINISTRATOR_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ADMINISTRATOR_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ADMINISTRATOR_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_ADMINISTRATOR_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_BELOW_THE_LINE_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_BELOW_THE_LINE_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_BELOW_THE_LINE_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_BELOW_THE_LINE_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_EXTERNAL_DEPARTMENT_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_EXTERNAL_DEPARTMENT_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_EXTERNAL_DEPARTMENT_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_EXTERNAL_DEPARTMENT_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_PAYROLL_DEPARTMENT_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_PAYROLL_DEPARTMENT_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_PAYROLL_DEPARTMENT_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__IS_PAYROLL_DEPARTMENT_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__SORT_ORDER_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__SORT_ORDER_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__SORT_ORDER_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID__SORT_ORDER_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_ACTIVE_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_ACTIVE_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_ACTIVE_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_ACTIVE_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DELETABLE_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DELETABLE_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DELETABLE_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DELETABLE_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DEPRECATED_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DEPRECATED_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_UPDATABLE_ASC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_UPDATABLE_DESC = "DEPARTMENT_TYPE_BY_DEPARTMENT_TYPE_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_TYPE_ID_ASC = "DEPARTMENT_TYPE_ID_ASC",
  DEPARTMENT_TYPE_ID_DESC = "DEPARTMENT_TYPE_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DOCUMENT_POOLS_BY_DEFAULT_DEPARTMENT_ID__COUNT_ASC = "DOCUMENT_POOLS_BY_DEFAULT_DEPARTMENT_ID__COUNT_ASC",
  DOCUMENT_POOLS_BY_DEFAULT_DEPARTMENT_ID__COUNT_DESC = "DOCUMENT_POOLS_BY_DEFAULT_DEPARTMENT_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  SORT_ORDER_ASC = "SORT_ORDER_ASC",
  SORT_ORDER_DESC = "SORT_ORDER_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  USER_APPROVALS_BY_DEPARTMENT_ID__COUNT_ASC = "USER_APPROVALS_BY_DEPARTMENT_ID__COUNT_ASC",
  USER_APPROVALS_BY_DEPARTMENT_ID__COUNT_DESC = "USER_APPROVALS_BY_DEPARTMENT_ID__COUNT_DESC",
  USER_PROFILES_BY_DEPARTMENT_ID__COUNT_ASC = "USER_PROFILES_BY_DEPARTMENT_ID__COUNT_ASC",
  USER_PROFILES_BY_DEPARTMENT_ID__COUNT_DESC = "USER_PROFILES_BY_DEPARTMENT_ID__COUNT_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVALS_EXIST_ASC = "_IS_APPROVALS_EXIST_ASC",
  _IS_APPROVALS_EXIST_DESC = "_IS_APPROVALS_EXIST_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_SYSTEM_INTERNAL_ASC = "_IS_SYSTEM_INTERNAL_ASC",
  _IS_SYSTEM_INTERNAL_DESC = "_IS_SYSTEM_INTERNAL_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_VALID_COMPLETE_ASC = "_IS_VALID_COMPLETE_ASC",
  _IS_VALID_COMPLETE_DESC = "_IS_VALID_COMPLETE_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `Depot`.
 */
export enum DepotsOrderBy {
  CITY_AREA_ASC = "CITY_AREA_ASC",
  CITY_AREA_DESC = "CITY_AREA_DESC",
  CITY_AREA_ID_ASC = "CITY_AREA_ID_ASC",
  CITY_AREA_ID_DESC = "CITY_AREA_ID_DESC",
  COUNTRY_ID_ASC = "COUNTRY_ID_ASC",
  COUNTRY_ID_DESC = "COUNTRY_ID_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID__CITY_AREA_ASC = "DEPOT_CITY_BY_CITY_AREA_ID__CITY_AREA_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID__CITY_AREA_DESC = "DEPOT_CITY_BY_CITY_AREA_ID__CITY_AREA_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID__COUNTRY_ID_ASC = "DEPOT_CITY_BY_CITY_AREA_ID__COUNTRY_ID_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID__COUNTRY_ID_DESC = "DEPOT_CITY_BY_CITY_AREA_ID__COUNTRY_ID_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID__ID_ASC = "DEPOT_CITY_BY_CITY_AREA_ID__ID_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID__ID_DESC = "DEPOT_CITY_BY_CITY_AREA_ID__ID_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID__STATE_REGION_ID_ASC = "DEPOT_CITY_BY_CITY_AREA_ID__STATE_REGION_ID_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID__STATE_REGION_ID_DESC = "DEPOT_CITY_BY_CITY_AREA_ID__STATE_REGION_ID_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___CREATED_DATE_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___CREATED_DATE_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___CREATED_DATE_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___CREATED_DATE_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_ACTIVE_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_ACTIVE_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_ACTIVE_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_ACTIVE_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_DELETABLE_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_DELETABLE_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_DELETABLE_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_DELETABLE_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_DEPRECATED_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_DEPRECATED_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_DEPRECATED_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_DEPRECATED_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_MODIFIED_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_MODIFIED_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_MODIFIED_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_MODIFIED_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_UPDATABLE_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_UPDATABLE_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___IS_UPDATABLE_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___IS_UPDATABLE_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___ROW_TIMESTAMP_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___ROW_TIMESTAMP_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___ROW_TIMESTAMP_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___ROW_TIMESTAMP_DESC",
  DEPOT_CITY_BY_CITY_AREA_ID___SORT_ORDER_ASC = "DEPOT_CITY_BY_CITY_AREA_ID___SORT_ORDER_ASC",
  DEPOT_CITY_BY_CITY_AREA_ID___SORT_ORDER_DESC = "DEPOT_CITY_BY_CITY_AREA_ID___SORT_ORDER_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  IATA_CODE_ASC = "IATA_CODE_ASC",
  IATA_CODE_DESC = "IATA_CODE_DESC",
  ICAO_CODE_ASC = "ICAO_CODE_ASC",
  ICAO_CODE_DESC = "ICAO_CODE_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_AIRPORT_ASC = "IS_AIRPORT_ASC",
  IS_AIRPORT_DESC = "IS_AIRPORT_DESC",
  IS_BUS_DEPOT_ASC = "IS_BUS_DEPOT_ASC",
  IS_BUS_DEPOT_DESC = "IS_BUS_DEPOT_DESC",
  IS_TRAIN_DEPOT_ASC = "IS_TRAIN_DEPOT_ASC",
  IS_TRAIN_DEPOT_DESC = "IS_TRAIN_DEPOT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  STATE_REGION_ASC = "STATE_REGION_ASC",
  STATE_REGION_DESC = "STATE_REGION_DESC",
  STATE_REGION_ID_ASC = "STATE_REGION_ID_ASC",
  STATE_REGION_ID_DESC = "STATE_REGION_ID_DESC",
  TRIP_ITEMS_BY_DEPARTURE_ARRIVAL_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_DEPARTURE_ARRIVAL_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_DEPARTURE_ARRIVAL_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_DEPARTURE_ARRIVAL_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_DEPARTURE_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_DEPARTURE_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_DEPARTURE_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_DEPARTURE_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_DEPART_1_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_DEPART_1_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_DEPART_1_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_DEPART_1_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_DEPART_2_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_DEPART_2_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_DEPART_2_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_DEPART_2_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_DEPART_3_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_DEPART_3_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_DEPART_3_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_DEPART_3_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_DEPART_4_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_DEPART_4_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_DEPART_4_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_DEPART_4_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_1_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_1_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_1_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_1_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_2_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_2_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_2_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_2_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_3_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_3_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_3_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_3_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_4_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_4_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_4_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_4_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_ARRIVAL_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_ARRIVAL_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_ARRIVAL_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_ARRIVAL_DEPOT_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_DEPOT_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_DEPOT_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_DEPOT_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_DEPOT_ID__COUNT_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SORT_ORDER_ASC = "_SORT_ORDER_ASC",
  _SORT_ORDER_DESC = "_SORT_ORDER_DESC",
}

export enum DocumentPackageStatusType {
  FAILURE = "FAILURE",
  REGENERATION_FAILURE = "REGENERATION_FAILURE",
  SUCCESS = "SUCCESS",
}

/**
 * Methods to use when ordering `DocumentPoolRecipient`.
 */
export enum DocumentPoolRecipientsOrderBy {
  DEFAULT_BUSINESS_UNIT_ID_ASC = "DEFAULT_BUSINESS_UNIT_ID_ASC",
  DEFAULT_BUSINESS_UNIT_ID_DESC = "DEFAULT_BUSINESS_UNIT_ID_DESC",
  DEFAULT_DEPARTMENT_ID_ASC = "DEFAULT_DEPARTMENT_ID_ASC",
  DEFAULT_DEPARTMENT_ID_DESC = "DEFAULT_DEPARTMENT_ID_DESC",
  DEFAULT_DIRECTORY_TYPE_ID_ASC = "DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DEFAULT_DIRECTORY_TYPE_ID_DESC = "DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DEFAULT_DOCUMENT_TYPE_ID_ASC = "DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DEFAULT_DOCUMENT_TYPE_ID_DESC = "DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DEFAULT_ENTITY_TYPE_ID_ASC = "DEFAULT_ENTITY_TYPE_ID_ASC",
  DEFAULT_ENTITY_TYPE_ID_DESC = "DEFAULT_ENTITY_TYPE_ID_DESC",
  DEFAULT_ROW_SECURITY_ID_ASC = "DEFAULT_ROW_SECURITY_ID_ASC",
  DEFAULT_ROW_SECURITY_ID_DESC = "DEFAULT_ROW_SECURITY_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC",
  EXTRACTION_TYPE_ID_ASC = "EXTRACTION_TYPE_ID_ASC",
  EXTRACTION_TYPE_ID_DESC = "EXTRACTION_TYPE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_BUSINESS_UNIT_SELECTOR_ASC = "IS_BUSINESS_UNIT_SELECTOR_ASC",
  IS_BUSINESS_UNIT_SELECTOR_DESC = "IS_BUSINESS_UNIT_SELECTOR_DESC",
  IS_COMPANY_SELECTOR_ASC = "IS_COMPANY_SELECTOR_ASC",
  IS_COMPANY_SELECTOR_DESC = "IS_COMPANY_SELECTOR_DESC",
  IS_CREATE_COPY_WITH_SELECTION_ASC = "IS_CREATE_COPY_WITH_SELECTION_ASC",
  IS_CREATE_COPY_WITH_SELECTION_DESC = "IS_CREATE_COPY_WITH_SELECTION_DESC",
  IS_DEPARTMENT_SELECTOR_ASC = "IS_DEPARTMENT_SELECTOR_ASC",
  IS_DEPARTMENT_SELECTOR_DESC = "IS_DEPARTMENT_SELECTOR_DESC",
  IS_DOCUMENT_TYPE_SELECTOR_ASC = "IS_DOCUMENT_TYPE_SELECTOR_ASC",
  IS_DOCUMENT_TYPE_SELECTOR_DESC = "IS_DOCUMENT_TYPE_SELECTOR_DESC",
  IS_ENTITY_TYPE_SELECTOR_ASC = "IS_ENTITY_TYPE_SELECTOR_ASC",
  IS_ENTITY_TYPE_SELECTOR_DESC = "IS_ENTITY_TYPE_SELECTOR_DESC",
  IS_PRIVATE_POOL_ASC = "IS_PRIVATE_POOL_ASC",
  IS_PRIVATE_POOL_DESC = "IS_PRIVATE_POOL_DESC",
  IS_RETAINED_WITH_SELECTION_ASC = "IS_RETAINED_WITH_SELECTION_ASC",
  IS_RETAINED_WITH_SELECTION_DESC = "IS_RETAINED_WITH_SELECTION_DESC",
  IS_SHAREABLE_DEFAULT_ASC = "IS_SHAREABLE_DEFAULT_ASC",
  IS_SHAREABLE_DEFAULT_DESC = "IS_SHAREABLE_DEFAULT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _ENTITY_TYPE_ID_ASC = "_ENTITY_TYPE_ID_ASC",
  _ENTITY_TYPE_ID_DESC = "_ENTITY_TYPE_ID_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
}

/**
 * Methods to use when ordering `DocumentPool`.
 */
export enum DocumentPoolsOrderBy {
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ABBREVIATION_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ABBREVIATION_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ABBREVIATION_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ABBREVIATION_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__DESCRIPTION_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__DESCRIPTION_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__DESCRIPTION_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__DESCRIPTION_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ID_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ID_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ID_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ID_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_DRAFT_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_DRAFT_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_DRAFT_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_DRAFT_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__NAME_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__NAME_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__NAME_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__NAME_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__SORT_ORDER_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__SORT_ORDER_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__SORT_ORDER_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID__SORT_ORDER_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVED_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVED_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVED_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_APPROVED_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALIDATED_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALIDATED_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALIDATED_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALIDATED_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC",
  BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC = "BUSINESS_UNIT_BY_DEFAULT_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC",
  CARD_ACCOUNTS_BY_DOCUMENT_POOL_ID__COUNT_ASC = "CARD_ACCOUNTS_BY_DOCUMENT_POOL_ID__COUNT_ASC",
  CARD_ACCOUNTS_BY_DOCUMENT_POOL_ID__COUNT_DESC = "CARD_ACCOUNTS_BY_DOCUMENT_POOL_ID__COUNT_DESC",
  CARD_HOLDERS_BY_DOCUMENT_POOL_ID__COUNT_ASC = "CARD_HOLDERS_BY_DOCUMENT_POOL_ID__COUNT_ASC",
  CARD_HOLDERS_BY_DOCUMENT_POOL_ID__COUNT_DESC = "CARD_HOLDERS_BY_DOCUMENT_POOL_ID__COUNT_DESC",
  DEFAULT_BUSINESS_UNIT_ID_ASC = "DEFAULT_BUSINESS_UNIT_ID_ASC",
  DEFAULT_BUSINESS_UNIT_ID_DESC = "DEFAULT_BUSINESS_UNIT_ID_DESC",
  DEFAULT_DEPARTMENT_ID_ASC = "DEFAULT_DEPARTMENT_ID_ASC",
  DEFAULT_DEPARTMENT_ID_DESC = "DEFAULT_DEPARTMENT_ID_DESC",
  DEFAULT_DIRECTORY_TYPE_ID_ASC = "DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DEFAULT_DIRECTORY_TYPE_ID_DESC = "DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DEFAULT_DOCUMENT_TYPE_ID_ASC = "DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DEFAULT_DOCUMENT_TYPE_ID_DESC = "DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DEFAULT_ENTITY_TYPE_ID_ASC = "DEFAULT_ENTITY_TYPE_ID_ASC",
  DEFAULT_ENTITY_TYPE_ID_DESC = "DEFAULT_ENTITY_TYPE_ID_DESC",
  DEFAULT_ROW_SECURITY_ID_ASC = "DEFAULT_ROW_SECURITY_ID_ASC",
  DEFAULT_ROW_SECURITY_ID_DESC = "DEFAULT_ROW_SECURITY_ID_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ABBREVIATION_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ABBREVIATION_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ABBREVIATION_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ABBREVIATION_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DESCRIPTION_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DESCRIPTION_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DESCRIPTION_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__DESCRIPTION_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ID_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ID_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ID_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__ID_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__IS_DRAFT_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__IS_DRAFT_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__IS_DRAFT_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__IS_DRAFT_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__NAME_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__NAME_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__NAME_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__NAME_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__SORT_ORDER_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__SORT_ORDER_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__SORT_ORDER_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID__SORT_ORDER_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___CREATED_DATE_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___CREATED_DATE_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___CREATED_DATE_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___CREATED_DATE_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_ACTIVE_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_ACTIVE_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_ACTIVE_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_ACTIVE_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVED_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVED_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVED_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_APPROVED_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DELETABLE_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DELETABLE_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DELETABLE_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DELETABLE_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DEPRECATED_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DEPRECATED_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MODIFIED_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MODIFIED_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MODIFIED_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_MODIFIED_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_NOTES_EXIST_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_NOTES_EXIST_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_NOTES_EXIST_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_NOTES_EXIST_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_TAGS_EXIST_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_TAGS_EXIST_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_TAGS_EXIST_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_TAGS_EXIST_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_UPDATABLE_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_UPDATABLE_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALIDATED_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALIDATED_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALIDATED_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALIDATED_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_BY_DEFAULT_DEPARTMENT_ID___STATUS_TYPE_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DESCRIPTION_ASC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DESCRIPTION_ASC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DESCRIPTION_DESC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DESCRIPTION_DESC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DIRECTORY_ASC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DIRECTORY_ASC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DIRECTORY_DESC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__DIRECTORY_DESC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__ID_ASC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__ID_ASC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__ID_DESC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__ID_DESC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_EXTERNAL_SERVICE_ASC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_EXTERNAL_SERVICE_ASC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_EXTERNAL_SERVICE_DESC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_EXTERNAL_SERVICE_DESC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_POOL_SELECTABLE_ASC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_POOL_SELECTABLE_ASC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_POOL_SELECTABLE_DESC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_POOL_SELECTABLE_DESC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_THUMBNAIL_ASC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_THUMBNAIL_ASC",
  DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_THUMBNAIL_DESC = "DIRECTORY_TYPE_BY_DEFAULT_DIRECTORY_TYPE_ID__IS_THUMBNAIL_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC",
  DOCUMENT_POOL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "DOCUMENT_POOL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  DOCUMENT_POOL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "DOCUMENT_POOL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC",
  ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_DESC",
  EXTRACTION_TYPE_ID_ASC = "EXTRACTION_TYPE_ID_ASC",
  EXTRACTION_TYPE_ID_DESC = "EXTRACTION_TYPE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_BUSINESS_UNIT_SELECTOR_ASC = "IS_BUSINESS_UNIT_SELECTOR_ASC",
  IS_BUSINESS_UNIT_SELECTOR_DESC = "IS_BUSINESS_UNIT_SELECTOR_DESC",
  IS_COMPANY_SELECTOR_ASC = "IS_COMPANY_SELECTOR_ASC",
  IS_COMPANY_SELECTOR_DESC = "IS_COMPANY_SELECTOR_DESC",
  IS_CREATE_COPY_WITH_SELECTION_ASC = "IS_CREATE_COPY_WITH_SELECTION_ASC",
  IS_CREATE_COPY_WITH_SELECTION_DESC = "IS_CREATE_COPY_WITH_SELECTION_DESC",
  IS_DEPARTMENT_SELECTOR_ASC = "IS_DEPARTMENT_SELECTOR_ASC",
  IS_DEPARTMENT_SELECTOR_DESC = "IS_DEPARTMENT_SELECTOR_DESC",
  IS_DOCUMENT_TYPE_SELECTOR_ASC = "IS_DOCUMENT_TYPE_SELECTOR_ASC",
  IS_DOCUMENT_TYPE_SELECTOR_DESC = "IS_DOCUMENT_TYPE_SELECTOR_DESC",
  IS_ENTITY_TYPE_SELECTOR_ASC = "IS_ENTITY_TYPE_SELECTOR_ASC",
  IS_ENTITY_TYPE_SELECTOR_DESC = "IS_ENTITY_TYPE_SELECTOR_DESC",
  IS_PRIVATE_POOL_ASC = "IS_PRIVATE_POOL_ASC",
  IS_PRIVATE_POOL_DESC = "IS_PRIVATE_POOL_DESC",
  IS_RETAINED_WITH_SELECTION_ASC = "IS_RETAINED_WITH_SELECTION_ASC",
  IS_RETAINED_WITH_SELECTION_DESC = "IS_RETAINED_WITH_SELECTION_DESC",
  IS_SHAREABLE_DEFAULT_ASC = "IS_SHAREABLE_DEFAULT_ASC",
  IS_SHAREABLE_DEFAULT_DESC = "IS_SHAREABLE_DEFAULT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PARENT_DOCUMENT_POOL_ID_ASC = "PARENT_DOCUMENT_POOL_ID_ASC",
  PARENT_DOCUMENT_POOL_ID_DESC = "PARENT_DOCUMENT_POOL_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_DEFAULT_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  SUPPLIERS_BY_PURCHASE_DOCUMENTS_POOL_ID__COUNT_ASC = "SUPPLIERS_BY_PURCHASE_DOCUMENTS_POOL_ID__COUNT_ASC",
  SUPPLIERS_BY_PURCHASE_DOCUMENTS_POOL_ID__COUNT_DESC = "SUPPLIERS_BY_PURCHASE_DOCUMENTS_POOL_ID__COUNT_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ID_ASC = "USER_GROUP_BY_USER_GROUP_ID__ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ID_DESC = "USER_GROUP_BY_USER_GROUP_ID__ID_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_ASC = "USER_GROUP_BY_USER_GROUP_ID__NAME_ASC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_DESC = "USER_GROUP_BY_USER_GROUP_ID__NAME_DESC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _ENTITY_TYPE_ID_ASC = "_ENTITY_TYPE_ID_ASC",
  _ENTITY_TYPE_ID_DESC = "_ENTITY_TYPE_ID_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
}

/**
 * Methods to use when ordering `EntityDocument`.
 */
export enum EntityDocumentsOrderBy {
  APPLIED_ENTITY_DOCUMENTS_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COUNT_ASC = "APPLIED_ENTITY_DOCUMENTS_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COUNT_ASC",
  APPLIED_ENTITY_DOCUMENTS_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COUNT_DESC = "APPLIED_ENTITY_DOCUMENTS_BY_DOCUMENT_FILE_ID_AND_DOCUMENT_POOL_ENTITY_TYPE_ID__COUNT_DESC",
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC",
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC",
  APPROVAL_BY_ENTITY_ID__ID_ASC = "APPROVAL_BY_ENTITY_ID__ID_ASC",
  APPROVAL_BY_ENTITY_ID__ID_DESC = "APPROVAL_BY_ENTITY_ID__ID_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC",
  APPROVAL_BY_ENTITY_ID__NAME_ASC = "APPROVAL_BY_ENTITY_ID__NAME_ASC",
  APPROVAL_BY_ENTITY_ID__NAME_DESC = "APPROVAL_BY_ENTITY_ID__NAME_DESC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_1_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_1_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_1_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_1_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_2_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_2_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_2_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_2_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_3_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_3_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_3_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_3_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_4_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_4_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_4_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_4_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_5_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_5_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_5_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__AMOUNT_5_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_BRANCH_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_BRANCH_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_BRANCH_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_BRANCH_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_GROUP_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_GROUP_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_GROUP_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_GROUP_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PERSONA_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PERSONA_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PERSONA_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PERSONA_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TOPIC_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TOPIC_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TOPIC_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TOPIC_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APP_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__APP_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__APP_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__APP_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_APPROVED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_APPROVED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_APPROVED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_APPROVED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_REJECTED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_REJECTED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_REJECTED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_REJECTED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ISO_CODE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ISO_CODE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ISO_CODE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__CURRENCY_ISO_CODE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_1_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_1_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_1_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_1_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_2_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_2_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_2_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_2_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_3_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_3_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_3_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_3_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_4_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_4_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_4_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_4_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_5_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_5_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DATE_5_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DATE_5_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_10_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_10_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_10_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_10_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_1_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_1_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_1_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_1_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_2_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_2_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_2_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_2_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_3_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_3_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_3_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_3_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_4_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_4_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_4_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_4_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_5_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_5_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_5_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_5_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_6_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_6_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_6_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_6_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_7_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_7_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_7_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_7_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_8_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_8_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_8_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_8_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_9_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_9_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_9_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DESCRIPTION_9_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DIGITAL_SIGNATURE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DIGITAL_SIGNATURE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DIGITAL_SIGNATURE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DIGITAL_SIGNATURE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ENTITY_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__FINALIZED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__FINALIZED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__FINALIZED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__FINALIZED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ICON_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ICON_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ICON_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ICON_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_APPROVER_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_AUTO_APPROVAL_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_AUTO_APPROVAL_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_AUTO_APPROVAL_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_AUTO_APPROVAL_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_HISTORY_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_HISTORY_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_HISTORY_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_HISTORY_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_OBSERVER_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_OBSERVER_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_OBSERVER_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_OBSERVER_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_PENDING_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_PENDING_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_PENDING_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_PENDING_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_REJECTED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_REJECTED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_REJECTED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_REJECTED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_REQUESTER_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_REQUESTER_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_REQUESTER_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_REQUESTER_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_REVOKED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_REVOKED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_REVOKED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_REVOKED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__LAYOUT_TYPE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__LAYOUT_TYPE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__LAYOUT_TYPE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__LAYOUT_TYPE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_1_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_1_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_1_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_1_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_2_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_2_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_2_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_2_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_3_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_3_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_3_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_3_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_4_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_4_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_4_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_4_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_5_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_5_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__RATE_5_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__RATE_5_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REJECTED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REJECTED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REJECTED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REJECTED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_NAME_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_NAME_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_NAME_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUIRED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REQUIRED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_NAME_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_NAME_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_NAME_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_NAME_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__REVOKED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__SORT_ORDER_GROUP_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__SORT_ORDER_GROUP_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__SORT_ORDER_GROUP_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__SORT_ORDER_GROUP_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__STATUS_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__STATUS_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__STATUS_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__STATUS_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__TREE_APPROVED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__TREE_APPROVED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__TREE_APPROVED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__TREE_APPROVED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__TREE_REJECTED_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__TREE_REJECTED_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__TREE_REJECTED_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__TREE_REJECTED_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_1_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_1_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_1_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_1_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_2_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_2_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_2_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_2_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_3_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_3_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_3_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_3_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_4_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_4_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_4_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_4_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_5_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_5_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_5_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__UNIT_5_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID__URGENCY_LEVEL_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID__URGENCY_LEVEL_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID__URGENCY_LEVEL_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID__URGENCY_LEVEL_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___COMPANY_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___COMPANY_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___COMPANY_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___COMPANY_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_REVOCABLE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_REVOCABLE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_REVOCABLE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_REVOCABLE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_URGENT_APPROVAL_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_URGENT_APPROVAL_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_URGENT_APPROVAL_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_URGENT_APPROVAL_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_VALIDATING_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_VALIDATING_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___IS_VALIDATING_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___IS_VALIDATING_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_END_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_END_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_END_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_END_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_ID_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_START_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_START_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_START_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_START_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_YEAR_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_YEAR_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_YEAR_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___PERIOD_YEAR_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_DATE_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_DATE_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_DATE_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_DATE_DESC",
  APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_ASC = "APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_ASC",
  APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_DESC = "APPROVAL_HISTORY_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_DOCUMENT_ID__COUNT_DESC",
  APP_ID_ASC = "APP_ID_ASC",
  APP_ID_DESC = "APP_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  CARD_ACCOUNT_ID_ASC = "CARD_ACCOUNT_ID_ASC",
  CARD_ACCOUNT_ID_DESC = "CARD_ACCOUNT_ID_DESC",
  CARD_HOLDER_ID_ASC = "CARD_HOLDER_ID_ASC",
  CARD_HOLDER_ID_DESC = "CARD_HOLDER_ID_DESC",
  COMMENT_ASC = "COMMENT_ASC",
  COMMENT_DESC = "COMMENT_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_DESC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_INDEX_CURRENCY_ID__NAME_DESC",
  COMPANY_ID_ASC = "COMPANY_ID_ASC",
  COMPANY_ID_DESC = "COMPANY_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DOCUMENT_APPLIED_AMOUNT_ASC = "DOCUMENT_APPLIED_AMOUNT_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__APPLIED_TOTAL_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__APPLIED_TOTAL_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__APPLIED_TOTAL_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__APPLIED_TOTAL_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__DOCUMENT_TOTAL_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__DOCUMENT_TOTAL_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__DOCUMENT_TOTAL_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__DOCUMENT_TOTAL_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__ID_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__ID_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__ID_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__ID_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__OVERAGE_TOTAL_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__OVERAGE_TOTAL_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__OVERAGE_TOTAL_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__OVERAGE_TOTAL_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__REMAINING_TOTAL_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__REMAINING_TOTAL_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__REMAINING_TOTAL_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__REMAINING_TOTAL_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__RETIRED_TOTAL_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__RETIRED_TOTAL_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__RETIRED_TOTAL_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__RETIRED_TOTAL_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__USED_TOTAL_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__USED_TOTAL_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__USED_TOTAL_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID__USED_TOTAL_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_REMAINING_AMOUNT_AVAILABLE_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_REMAINING_AMOUNT_AVAILABLE_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_REMAINING_AMOUNT_AVAILABLE_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_REMAINING_AMOUNT_AVAILABLE_DESC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_RETIRING_BALANCE_ALLOWED_ASC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_RETIRING_BALANCE_ALLOWED_ASC",
  DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_RETIRING_BALANCE_ALLOWED_DESC = "DOCUMENT_APPLIED_AMOUNT_BY_DOCUMENT_FILE_ID___IS_RETIRING_BALANCE_ALLOWED_DESC",
  DOCUMENT_APPLIED_AMOUNT_DESC = "DOCUMENT_APPLIED_AMOUNT_DESC",
  DOCUMENT_FILE_DISTRIBUTIONS_BY_DOCUMENT_FILE_ID__COUNT_ASC = "DOCUMENT_FILE_DISTRIBUTIONS_BY_DOCUMENT_FILE_ID__COUNT_ASC",
  DOCUMENT_FILE_DISTRIBUTIONS_BY_DOCUMENT_FILE_ID__COUNT_DESC = "DOCUMENT_FILE_DISTRIBUTIONS_BY_DOCUMENT_FILE_ID__COUNT_DESC",
  DOCUMENT_OVERAGE_AMOUNT_ASC = "DOCUMENT_OVERAGE_AMOUNT_ASC",
  DOCUMENT_OVERAGE_AMOUNT_DESC = "DOCUMENT_OVERAGE_AMOUNT_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__PARENT_DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__PARENT_DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__PARENT_DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__PARENT_DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_DESC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_ASC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_ASC",
  DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_DESC = "DOCUMENT_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_DESC",
  DOCUMENT_POSITION_ASC = "DOCUMENT_POSITION_ASC",
  DOCUMENT_POSITION_DESC = "DOCUMENT_POSITION_DESC",
  DOCUMENT_RETIRED_AMOUNT_ASC = "DOCUMENT_RETIRED_AMOUNT_ASC",
  DOCUMENT_RETIRED_AMOUNT_DESC = "DOCUMENT_RETIRED_AMOUNT_DESC",
  DOCUMENT_STATUS_EXPLANATION_ASC = "DOCUMENT_STATUS_EXPLANATION_ASC",
  DOCUMENT_STATUS_EXPLANATION_DESC = "DOCUMENT_STATUS_EXPLANATION_DESC",
  DOCUMENT_STATUS_TYPE_ID_ASC = "DOCUMENT_STATUS_TYPE_ID_ASC",
  DOCUMENT_STATUS_TYPE_ID_DESC = "DOCUMENT_STATUS_TYPE_ID_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__ACCOUNT_RESOURCE_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__ACCOUNT_RESOURCE_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__ACCOUNT_RESOURCE_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__ACCOUNT_RESOURCE_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_RESOURCE_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_RESOURCE_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_RESOURCE_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_RESOURCE_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_SERVICE_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_SERVICE_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_SERVICE_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__BLOB_SERVICE_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__CONTAINER_NAME_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__CONTAINER_NAME_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__CONTAINER_NAME_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__CONTAINER_NAME_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__DATA_LAKE_SERVICE_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__DATA_LAKE_SERVICE_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__DATA_LAKE_SERVICE_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__DATA_LAKE_SERVICE_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_NAME_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_NAME_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_NAME_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_NAME_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_SERVICE_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_SERVICE_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_SERVICE_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__FILE_SERVICE_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__ID_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__ID_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__ID_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__ID_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__QUEUE_SERVICE_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__QUEUE_SERVICE_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__QUEUE_SERVICE_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__QUEUE_SERVICE_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__STORAGE_ACCOUNT_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__STORAGE_ACCOUNT_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__STORAGE_ACCOUNT_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__STORAGE_ACCOUNT_DESC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__TABLE_SERVICE_ASC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__TABLE_SERVICE_ASC",
  DOCUMENT_STORAGE_ACCOUNT_BY_ID__TABLE_SERVICE_DESC = "DOCUMENT_STORAGE_ACCOUNT_BY_ID__TABLE_SERVICE_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__ID_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__ID_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__ID_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__ID_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC",
  DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC = "DOCUMENT_TYPE_BY_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC",
  DOCUMENT_TYPE_ID_ASC = "DOCUMENT_TYPE_ID_ASC",
  DOCUMENT_TYPE_ID_DESC = "DOCUMENT_TYPE_ID_DESC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  EMPLOYEE_BY_ENTITY_ID__ADMISSION_NUMBER_ASC = "EMPLOYEE_BY_ENTITY_ID__ADMISSION_NUMBER_ASC",
  EMPLOYEE_BY_ENTITY_ID__ADMISSION_NUMBER_DESC = "EMPLOYEE_BY_ENTITY_ID__ADMISSION_NUMBER_DESC",
  EMPLOYEE_BY_ENTITY_ID__ALIEN_EXPIRATION_DATE_ASC = "EMPLOYEE_BY_ENTITY_ID__ALIEN_EXPIRATION_DATE_ASC",
  EMPLOYEE_BY_ENTITY_ID__ALIEN_EXPIRATION_DATE_DESC = "EMPLOYEE_BY_ENTITY_ID__ALIEN_EXPIRATION_DATE_DESC",
  EMPLOYEE_BY_ENTITY_ID__ALIEN_REGISTRATION_USCIS_NUMBER_ASC = "EMPLOYEE_BY_ENTITY_ID__ALIEN_REGISTRATION_USCIS_NUMBER_ASC",
  EMPLOYEE_BY_ENTITY_ID__ALIEN_REGISTRATION_USCIS_NUMBER_DESC = "EMPLOYEE_BY_ENTITY_ID__ALIEN_REGISTRATION_USCIS_NUMBER_DESC",
  EMPLOYEE_BY_ENTITY_ID__BIRTH_DATE_ASC = "EMPLOYEE_BY_ENTITY_ID__BIRTH_DATE_ASC",
  EMPLOYEE_BY_ENTITY_ID__BIRTH_DATE_DESC = "EMPLOYEE_BY_ENTITY_ID__BIRTH_DATE_DESC",
  EMPLOYEE_BY_ENTITY_ID__CITIZEN_TYPE_ASC = "EMPLOYEE_BY_ENTITY_ID__CITIZEN_TYPE_ASC",
  EMPLOYEE_BY_ENTITY_ID__CITIZEN_TYPE_DESC = "EMPLOYEE_BY_ENTITY_ID__CITIZEN_TYPE_DESC",
  EMPLOYEE_BY_ENTITY_ID__CITY_AREA_EXEMPTIONS_ASC = "EMPLOYEE_BY_ENTITY_ID__CITY_AREA_EXEMPTIONS_ASC",
  EMPLOYEE_BY_ENTITY_ID__CITY_AREA_EXEMPTIONS_DESC = "EMPLOYEE_BY_ENTITY_ID__CITY_AREA_EXEMPTIONS_DESC",
  EMPLOYEE_BY_ENTITY_ID__COUNTRY_EXEMPTIONS_ASC = "EMPLOYEE_BY_ENTITY_ID__COUNTRY_EXEMPTIONS_ASC",
  EMPLOYEE_BY_ENTITY_ID__COUNTRY_EXEMPTIONS_DESC = "EMPLOYEE_BY_ENTITY_ID__COUNTRY_EXEMPTIONS_DESC",
  EMPLOYEE_BY_ENTITY_ID__FIRST_NAME_ASC = "EMPLOYEE_BY_ENTITY_ID__FIRST_NAME_ASC",
  EMPLOYEE_BY_ENTITY_ID__FIRST_NAME_DESC = "EMPLOYEE_BY_ENTITY_ID__FIRST_NAME_DESC",
  EMPLOYEE_BY_ENTITY_ID__GENDER_ASC = "EMPLOYEE_BY_ENTITY_ID__GENDER_ASC",
  EMPLOYEE_BY_ENTITY_ID__GENDER_DESC = "EMPLOYEE_BY_ENTITY_ID__GENDER_DESC",
  EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_FIRST_NAME_ASC = "EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_FIRST_NAME_ASC",
  EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_FIRST_NAME_DESC = "EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_FIRST_NAME_DESC",
  EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_LAST_NAME_ASC = "EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_LAST_NAME_ASC",
  EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_LAST_NAME_DESC = "EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_LAST_NAME_DESC",
  EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_TAX_REFERENCE_ASC = "EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_TAX_REFERENCE_ASC",
  EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_TAX_REFERENCE_DESC = "EMPLOYEE_BY_ENTITY_ID__GUILD_REPORTING_TAX_REFERENCE_DESC",
  EMPLOYEE_BY_ENTITY_ID__ID_ASC = "EMPLOYEE_BY_ENTITY_ID__ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__ID_DESC = "EMPLOYEE_BY_ENTITY_ID__ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__IS_ALIEN_EXPIRATION_NOT_APPLICABLE_ASC = "EMPLOYEE_BY_ENTITY_ID__IS_ALIEN_EXPIRATION_NOT_APPLICABLE_ASC",
  EMPLOYEE_BY_ENTITY_ID__IS_ALIEN_EXPIRATION_NOT_APPLICABLE_DESC = "EMPLOYEE_BY_ENTITY_ID__IS_ALIEN_EXPIRATION_NOT_APPLICABLE_DESC",
  EMPLOYEE_BY_ENTITY_ID__IS_DIRECT_DEPOSIT_ENABLED_ASC = "EMPLOYEE_BY_ENTITY_ID__IS_DIRECT_DEPOSIT_ENABLED_ASC",
  EMPLOYEE_BY_ENTITY_ID__IS_DIRECT_DEPOSIT_ENABLED_DESC = "EMPLOYEE_BY_ENTITY_ID__IS_DIRECT_DEPOSIT_ENABLED_DESC",
  EMPLOYEE_BY_ENTITY_ID__IS_DRAFT_ASC = "EMPLOYEE_BY_ENTITY_ID__IS_DRAFT_ASC",
  EMPLOYEE_BY_ENTITY_ID__IS_DRAFT_DESC = "EMPLOYEE_BY_ENTITY_ID__IS_DRAFT_DESC",
  EMPLOYEE_BY_ENTITY_ID__IS_LIEN_ENABLED_ASC = "EMPLOYEE_BY_ENTITY_ID__IS_LIEN_ENABLED_ASC",
  EMPLOYEE_BY_ENTITY_ID__IS_LIEN_ENABLED_DESC = "EMPLOYEE_BY_ENTITY_ID__IS_LIEN_ENABLED_DESC",
  EMPLOYEE_BY_ENTITY_ID__IS_NEW_HIRE_ASC = "EMPLOYEE_BY_ENTITY_ID__IS_NEW_HIRE_ASC",
  EMPLOYEE_BY_ENTITY_ID__IS_NEW_HIRE_DESC = "EMPLOYEE_BY_ENTITY_ID__IS_NEW_HIRE_DESC",
  EMPLOYEE_BY_ENTITY_ID__IS_PAY_CARD_ENABLED_ASC = "EMPLOYEE_BY_ENTITY_ID__IS_PAY_CARD_ENABLED_ASC",
  EMPLOYEE_BY_ENTITY_ID__IS_PAY_CARD_ENABLED_DESC = "EMPLOYEE_BY_ENTITY_ID__IS_PAY_CARD_ENABLED_DESC",
  EMPLOYEE_BY_ENTITY_ID__IS_VERIFIED_ASC = "EMPLOYEE_BY_ENTITY_ID__IS_VERIFIED_ASC",
  EMPLOYEE_BY_ENTITY_ID__IS_VERIFIED_DESC = "EMPLOYEE_BY_ENTITY_ID__IS_VERIFIED_DESC",
  EMPLOYEE_BY_ENTITY_ID__LAST_NAME_ASC = "EMPLOYEE_BY_ENTITY_ID__LAST_NAME_ASC",
  EMPLOYEE_BY_ENTITY_ID__LAST_NAME_DESC = "EMPLOYEE_BY_ENTITY_ID__LAST_NAME_DESC",
  EMPLOYEE_BY_ENTITY_ID__MARITAL_STATUS_TYPE_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__MARITAL_STATUS_TYPE_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__MARITAL_STATUS_TYPE_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__MARITAL_STATUS_TYPE_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__PASSPORT_COUNTRY_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__PASSPORT_COUNTRY_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__PASSPORT_COUNTRY_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__PASSPORT_COUNTRY_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__PASSPORT_NUMBER_ASC = "EMPLOYEE_BY_ENTITY_ID__PASSPORT_NUMBER_ASC",
  EMPLOYEE_BY_ENTITY_ID__PASSPORT_NUMBER_DESC = "EMPLOYEE_BY_ENTITY_ID__PASSPORT_NUMBER_DESC",
  EMPLOYEE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__STATE_REGION_EXEMPTIONS_ASC = "EMPLOYEE_BY_ENTITY_ID__STATE_REGION_EXEMPTIONS_ASC",
  EMPLOYEE_BY_ENTITY_ID__STATE_REGION_EXEMPTIONS_DESC = "EMPLOYEE_BY_ENTITY_ID__STATE_REGION_EXEMPTIONS_DESC",
  EMPLOYEE_BY_ENTITY_ID__STATUS_TYPE_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__STATUS_TYPE_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__STATUS_TYPE_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__STATUS_TYPE_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__TAX_CITY_AREA_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__TAX_CITY_AREA_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__TAX_CITY_AREA_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__TAX_CITY_AREA_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__TAX_COUNTRY_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__TAX_COUNTRY_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__TAX_COUNTRY_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__TAX_COUNTRY_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__TAX_PAYER_TYPE_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__TAX_PAYER_TYPE_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__TAX_PAYER_TYPE_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__TAX_PAYER_TYPE_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID__TAX_REFERENCE_NUMBER_ASC = "EMPLOYEE_BY_ENTITY_ID__TAX_REFERENCE_NUMBER_ASC",
  EMPLOYEE_BY_ENTITY_ID__TAX_REFERENCE_NUMBER_DESC = "EMPLOYEE_BY_ENTITY_ID__TAX_REFERENCE_NUMBER_DESC",
  EMPLOYEE_BY_ENTITY_ID__TAX_STATE_REGION_ID_ASC = "EMPLOYEE_BY_ENTITY_ID__TAX_STATE_REGION_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID__TAX_STATE_REGION_ID_DESC = "EMPLOYEE_BY_ENTITY_ID__TAX_STATE_REGION_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID___BUSINESS_UNIT_ID_ASC = "EMPLOYEE_BY_ENTITY_ID___BUSINESS_UNIT_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID___BUSINESS_UNIT_ID_DESC = "EMPLOYEE_BY_ENTITY_ID___BUSINESS_UNIT_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID___COMPANY_ID_ASC = "EMPLOYEE_BY_ENTITY_ID___COMPANY_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID___COMPANY_ID_DESC = "EMPLOYEE_BY_ENTITY_ID___COMPANY_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "EMPLOYEE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  EMPLOYEE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "EMPLOYEE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  EMPLOYEE_BY_ENTITY_ID___CREATED_DATE_ASC = "EMPLOYEE_BY_ENTITY_ID___CREATED_DATE_ASC",
  EMPLOYEE_BY_ENTITY_ID___CREATED_DATE_DESC = "EMPLOYEE_BY_ENTITY_ID___CREATED_DATE_DESC",
  EMPLOYEE_BY_ENTITY_ID___DUMMY_STATUS_TYPE_ID_ASC = "EMPLOYEE_BY_ENTITY_ID___DUMMY_STATUS_TYPE_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID___DUMMY_STATUS_TYPE_ID_DESC = "EMPLOYEE_BY_ENTITY_ID___DUMMY_STATUS_TYPE_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_ACTIVE_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_ACTIVE_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_APPROVALS_EXIST_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_APPROVALS_EXIST_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_APPROVALS_EXIST_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_APPROVALS_EXIST_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_APPROVED_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_APPROVED_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_APPROVED_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_APPROVED_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_DELETABLE_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_DELETABLE_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_MODIFIED_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_MODIFIED_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_VALIDATED_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_VALIDATED_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_VALID_COMPLETE_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_VALID_COMPLETE_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_VALID_COMPLETE_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_VALID_COMPLETE_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  EMPLOYEE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "EMPLOYEE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  EMPLOYEE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "EMPLOYEE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  EMPLOYEE_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC = "EMPLOYEE_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC = "EMPLOYEE_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC",
  EMPLOYEE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "EMPLOYEE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  EMPLOYEE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "EMPLOYEE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  EMPLOYEE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "EMPLOYEE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  EMPLOYEE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "EMPLOYEE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  EMPLOYEE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "EMPLOYEE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  EMPLOYEE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "EMPLOYEE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  ENTITY_DOCUMENT_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENT_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENT_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENT_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENT_TYPE_ID_ASC = "ENTITY_DOCUMENT_TYPE_ID_ASC",
  ENTITY_DOCUMENT_TYPE_ID_DESC = "ENTITY_DOCUMENT_TYPE_ID_DESC",
  ENTITY_ID_ASC = "ENTITY_ID_ASC",
  ENTITY_ID_DESC = "ENTITY_ID_DESC",
  ENTITY_TYPE_ID_ASC = "ENTITY_TYPE_ID_ASC",
  ENTITY_TYPE_ID_DESC = "ENTITY_TYPE_ID_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ABBREVIATION_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__CURRENCY_ID_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DESCRIPTION_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__DOCUMENT_TYPE_ID_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXCEL_VERSION_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_LAYOUT_TYPE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__EXTRACTION_TYPE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__ID_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_CSV_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DISTRIBUTION_EXTRACTED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_DOCUMENT_EXTRACTION_MODEL_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXCEL_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXPORT_DATA_MODEL_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_EXTRACT_PROTECTED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_HTML_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_IMAGE_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_JSON_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_NON_PO_DISTRIBUTION_EXTRACTED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_PDF_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_RAW_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_SUPPLIER_CODE_USED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__IS_TAB_TEXT_DOCUMENT_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID__SORT_ORDER_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_ACTIVE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DELETABLE_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_DEPRECATED_DESC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_ASC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_ASC",
  EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_DESC = "EXTRACTION_TYPE_BY_EXTRACTION_TYPE_ID___IS_UPDATABLE_DESC",
  EXTRACTION_TYPE_ID_ASC = "EXTRACTION_TYPE_ID_ASC",
  EXTRACTION_TYPE_ID_DESC = "EXTRACTION_TYPE_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_BUSINESS_UNIT_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DEPARTMENT_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ENTITY_TYPE_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DEFAULT_ROW_SECURITY_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__DESCRIPTION_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__EXTRACTION_TYPE_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_BUSINESS_UNIT_SELECTOR_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_COMPANY_SELECTOR_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_CREATE_COPY_WITH_SELECTION_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DEPARTMENT_SELECTOR_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_ENTITY_TYPE_SELECTOR_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_PRIVATE_POOL_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_RETAINED_WITH_SELECTION_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__IS_SHAREABLE_DEFAULT_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__NAME_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__ROW_SECURITY_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__USER_GROUP_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___COMPANY_OR_POOLING_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_BY_USERID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___CREATED_DATE_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ENTITY_TYPE_ID_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_ACTIVE_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DELETABLE_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DEPRECATED_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_DOCUMENTS_EXIST_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MESSAGES_EXIST_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_MODIFIED_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_NOTES_EXIST_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_PROTECTED_VIEW_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_TAGS_EXIST_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___IS_UPDATABLE_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___ROW_TIMESTAMP_DESC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_ASC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_ASC",
  FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_DESC = "FILE_CABINET_POOL_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID___SEARCH_DOCUMENT_DESC",
  FILE_INDEX_INFORMATION_ASC = "FILE_INDEX_INFORMATION_ASC",
  FILE_INDEX_INFORMATION_DESC = "FILE_INDEX_INFORMATION_DESC",
  FILE_REFERENCE_ASC = "FILE_REFERENCE_ASC",
  FILE_REFERENCE_DESC = "FILE_REFERENCE_DESC",
  ICON_TYPE_ASC = "ICON_TYPE_ASC",
  ICON_TYPE_DESC = "ICON_TYPE_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INDEX_AMOUNT_ASC = "INDEX_AMOUNT_ASC",
  INDEX_AMOUNT_DESC = "INDEX_AMOUNT_DESC",
  INDEX_BANK_ACCOUNT_NAME_ASC = "INDEX_BANK_ACCOUNT_NAME_ASC",
  INDEX_BANK_ACCOUNT_NAME_DESC = "INDEX_BANK_ACCOUNT_NAME_DESC",
  INDEX_BANK_ACCOUNT_NUMBER_ASC = "INDEX_BANK_ACCOUNT_NUMBER_ASC",
  INDEX_BANK_ACCOUNT_NUMBER_DESC = "INDEX_BANK_ACCOUNT_NUMBER_DESC",
  INDEX_BANK_CONFIRMATION_ASC = "INDEX_BANK_CONFIRMATION_ASC",
  INDEX_BANK_CONFIRMATION_DESC = "INDEX_BANK_CONFIRMATION_DESC",
  INDEX_BANK_IBAN_CODE_ASC = "INDEX_BANK_IBAN_CODE_ASC",
  INDEX_BANK_IBAN_CODE_DESC = "INDEX_BANK_IBAN_CODE_DESC",
  INDEX_BANK_NAME_ASC = "INDEX_BANK_NAME_ASC",
  INDEX_BANK_NAME_DESC = "INDEX_BANK_NAME_DESC",
  INDEX_BANK_ROUTING_NUMBER_ASC = "INDEX_BANK_ROUTING_NUMBER_ASC",
  INDEX_BANK_ROUTING_NUMBER_DESC = "INDEX_BANK_ROUTING_NUMBER_DESC",
  INDEX_BANK_SORT_CODE_ASC = "INDEX_BANK_SORT_CODE_ASC",
  INDEX_BANK_SORT_CODE_DESC = "INDEX_BANK_SORT_CODE_DESC",
  INDEX_BANK_SWIFT_CODE_ASC = "INDEX_BANK_SWIFT_CODE_ASC",
  INDEX_BANK_SWIFT_CODE_DESC = "INDEX_BANK_SWIFT_CODE_DESC",
  INDEX_CURRENCY_ID_ASC = "INDEX_CURRENCY_ID_ASC",
  INDEX_CURRENCY_ID_DESC = "INDEX_CURRENCY_ID_DESC",
  INDEX_DESCRIPTION_ASC = "INDEX_DESCRIPTION_ASC",
  INDEX_DESCRIPTION_DESC = "INDEX_DESCRIPTION_DESC",
  INDEX_NAME_ASC = "INDEX_NAME_ASC",
  INDEX_NAME_DESC = "INDEX_NAME_DESC",
  INDEX_REFERENCE_NUMBER_ASC = "INDEX_REFERENCE_NUMBER_ASC",
  INDEX_REFERENCE_NUMBER_DESC = "INDEX_REFERENCE_NUMBER_DESC",
  INDEX_TAX_ACCOUNT_1_ASC = "INDEX_TAX_ACCOUNT_1_ASC",
  INDEX_TAX_ACCOUNT_1_DESC = "INDEX_TAX_ACCOUNT_1_DESC",
  INDEX_TAX_ACCOUNT_2_ASC = "INDEX_TAX_ACCOUNT_2_ASC",
  INDEX_TAX_ACCOUNT_2_DESC = "INDEX_TAX_ACCOUNT_2_DESC",
  INDEX_TAX_ACCOUNT_3_ASC = "INDEX_TAX_ACCOUNT_3_ASC",
  INDEX_TAX_ACCOUNT_3_DESC = "INDEX_TAX_ACCOUNT_3_DESC",
  INDEX_TAX_ACCOUNT_4_ASC = "INDEX_TAX_ACCOUNT_4_ASC",
  INDEX_TAX_ACCOUNT_4_DESC = "INDEX_TAX_ACCOUNT_4_DESC",
  INDEX_TAX_AMOUNT_1_ASC = "INDEX_TAX_AMOUNT_1_ASC",
  INDEX_TAX_AMOUNT_1_DESC = "INDEX_TAX_AMOUNT_1_DESC",
  INDEX_TAX_AMOUNT_2_ASC = "INDEX_TAX_AMOUNT_2_ASC",
  INDEX_TAX_AMOUNT_2_DESC = "INDEX_TAX_AMOUNT_2_DESC",
  INDEX_TAX_AMOUNT_3_ASC = "INDEX_TAX_AMOUNT_3_ASC",
  INDEX_TAX_AMOUNT_3_DESC = "INDEX_TAX_AMOUNT_3_DESC",
  INDEX_TAX_AMOUNT_4_ASC = "INDEX_TAX_AMOUNT_4_ASC",
  INDEX_TAX_AMOUNT_4_DESC = "INDEX_TAX_AMOUNT_4_DESC",
  INDEX_TAX_CODE_1_ASC = "INDEX_TAX_CODE_1_ASC",
  INDEX_TAX_CODE_1_DESC = "INDEX_TAX_CODE_1_DESC",
  INDEX_TAX_CODE_2_ASC = "INDEX_TAX_CODE_2_ASC",
  INDEX_TAX_CODE_2_DESC = "INDEX_TAX_CODE_2_DESC",
  INDEX_TAX_CODE_3_ASC = "INDEX_TAX_CODE_3_ASC",
  INDEX_TAX_CODE_3_DESC = "INDEX_TAX_CODE_3_DESC",
  INDEX_TAX_CODE_4_ASC = "INDEX_TAX_CODE_4_ASC",
  INDEX_TAX_CODE_4_DESC = "INDEX_TAX_CODE_4_DESC",
  INDEX_TRANSACTION_DATE_ASC = "INDEX_TRANSACTION_DATE_ASC",
  INDEX_TRANSACTION_DATE_DESC = "INDEX_TRANSACTION_DATE_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_ASC = "INVOICE_BY_ENTITY_ID__BATCH_ID_ASC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_DESC = "INVOICE_BY_ENTITY_ID__BATCH_ID_DESC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC",
  INVOICE_BY_ENTITY_ID__ID_ASC = "INVOICE_BY_ENTITY_ID__ID_ASC",
  INVOICE_BY_ENTITY_ID__ID_DESC = "INVOICE_BY_ENTITY_ID__ID_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__ID_ASC = "INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__ID_ASC",
  INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__ID_DESC = "INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__ID_DESC",
  INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__IS_EXPLANATION_REQUIRED_ASC = "INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__IS_EXPLANATION_REQUIRED_ASC",
  INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__IS_EXPLANATION_REQUIRED_DESC = "INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__IS_EXPLANATION_REQUIRED_DESC",
  INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__STATUS_TYPE_ASC = "INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__STATUS_TYPE_DESC = "INVOICE_DISTRIBUTION_STATUS_TYPE_BY_DOCUMENT_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  IS_APPLIED_AMOUNT_OVERAGE_ASC = "IS_APPLIED_AMOUNT_OVERAGE_ASC",
  IS_APPLIED_AMOUNT_OVERAGE_DESC = "IS_APPLIED_AMOUNT_OVERAGE_DESC",
  IS_APPLIED_AMOUNT_RETIRED_ASC = "IS_APPLIED_AMOUNT_RETIRED_ASC",
  IS_APPLIED_AMOUNT_RETIRED_DESC = "IS_APPLIED_AMOUNT_RETIRED_DESC",
  IS_SHAREABLE_ASC = "IS_SHAREABLE_ASC",
  IS_SHAREABLE_DESC = "IS_SHAREABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC",
  NATURAL = "NATURAL",
  PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PAYMENT_BY_ENTITY_ID__CURRENCY_ID_ASC = "PAYMENT_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PAYMENT_BY_ENTITY_ID__CURRENCY_ID_DESC = "PAYMENT_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PAYMENT_BY_ENTITY_ID__ID_ASC = "PAYMENT_BY_ENTITY_ID__ID_ASC",
  PAYMENT_BY_ENTITY_ID__ID_DESC = "PAYMENT_BY_ENTITY_ID__ID_DESC",
  PAYMENT_BY_ENTITY_ID__MEMO_ASC = "PAYMENT_BY_ENTITY_ID__MEMO_ASC",
  PAYMENT_BY_ENTITY_ID__MEMO_DESC = "PAYMENT_BY_ENTITY_ID__MEMO_DESC",
  PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_ASC = "PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_ASC",
  PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_DESC = "PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_DESC",
  PAYMENT_BY_ENTITY_ID__PAID_DATE_ASC = "PAYMENT_BY_ENTITY_ID__PAID_DATE_ASC",
  PAYMENT_BY_ENTITY_ID__PAID_DATE_DESC = "PAYMENT_BY_ENTITY_ID__PAID_DATE_DESC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_ASC = "PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_ASC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_DESC = "PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_DESC",
  PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_ASC = "PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_ASC",
  PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_DESC = "PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_DESC",
  PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  PAYMENT_BY_ENTITY_ID__VENDOR_ID_ASC = "PAYMENT_BY_ENTITY_ID__VENDOR_ID_ASC",
  PAYMENT_BY_ENTITY_ID__VENDOR_ID_DESC = "PAYMENT_BY_ENTITY_ID__VENDOR_ID_DESC",
  PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_ASC = "PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_ASC",
  PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_DESC = "PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  PAYMENT_BY_ENTITY_ID___COMPANY_ID_ASC = "PAYMENT_BY_ENTITY_ID___COMPANY_ID_ASC",
  PAYMENT_BY_ENTITY_ID___COMPANY_ID_DESC = "PAYMENT_BY_ENTITY_ID___COMPANY_ID_DESC",
  PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PAYMENT_BY_ENTITY_ID___CREATED_DATE_ASC = "PAYMENT_BY_ENTITY_ID___CREATED_DATE_ASC",
  PAYMENT_BY_ENTITY_ID___CREATED_DATE_DESC = "PAYMENT_BY_ENTITY_ID___CREATED_DATE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ACTIVE_ASC = "PAYMENT_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ACTIVE_DESC = "PAYMENT_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_DELETABLE_ASC = "PAYMENT_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_DELETABLE_DESC = "PAYMENT_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_HISTORY_ASC = "PAYMENT_BY_ENTITY_ID___IS_HISTORY_ASC",
  PAYMENT_BY_ENTITY_ID___IS_HISTORY_DESC = "PAYMENT_BY_ENTITY_ID___IS_HISTORY_DESC",
  PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_ASC = "PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_DESC = "PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_MODIFIED_ASC = "PAYMENT_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_MODIFIED_DESC = "PAYMENT_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_ASC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_DESC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_ASC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_DESC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_DESC",
  PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_ASC = "PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_ASC",
  PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_DESC = "PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_DESC",
  PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_ASC = "PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_DESC = "PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_DESC",
  PAY_CYCLE_BY_ENTITY_ID__ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_ASC = "PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_ASC",
  PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_DESC = "PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_DESC",
  PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_ASC = "PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_ASC",
  PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_DESC = "PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_ASC = "PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_ASC",
  PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_DESC = "PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REASON_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REASON_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REASON_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REASON_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__ID_ASC = "SUPPLIER_BY_ENTITY_ID__ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ID_DESC = "SUPPLIER_BY_ENTITY_ID__ID_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID__ID_ASC = "TRAVELER_BY_ENTITY_ID__ID_ASC",
  TRAVELER_BY_ENTITY_ID__ID_DESC = "TRAVELER_BY_ENTITY_ID__ID_DESC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__ACCOMMODATION_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__ACCOMMODATION_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__ACCOMMODATION_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__ACCOMMODATION_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__ACCOMMODATION_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__ACCOMMODATION_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__ACCOMMODATION_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__ACCOMMODATION_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__ACCOUNT_REFERENCE_ASC = "TRIP_BY_ENTITY_ID__ACCOUNT_REFERENCE_ASC",
  TRIP_BY_ENTITY_ID__ACCOUNT_REFERENCE_DESC = "TRIP_BY_ENTITY_ID__ACCOUNT_REFERENCE_DESC",
  TRIP_BY_ENTITY_ID__AGENCY_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__AGENCY_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__AGENCY_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__AGENCY_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__AGENCY_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__AGENCY_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__AGENCY_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__AGENCY_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__AIRFARE_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__AIRFARE_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__AIRFARE_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__AIRFARE_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__AIRFARE_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__AIRFARE_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__AIRFARE_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__AIRFARE_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__BOOKING_CREATED_ASC = "TRIP_BY_ENTITY_ID__BOOKING_CREATED_ASC",
  TRIP_BY_ENTITY_ID__BOOKING_CREATED_DESC = "TRIP_BY_ENTITY_ID__BOOKING_CREATED_DESC",
  TRIP_BY_ENTITY_ID__BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__COMMENTS_ASC = "TRIP_BY_ENTITY_ID__COMMENTS_ASC",
  TRIP_BY_ENTITY_ID__COMMENTS_DESC = "TRIP_BY_ENTITY_ID__COMMENTS_DESC",
  TRIP_BY_ENTITY_ID__CONFIRMATION_CODE_ASC = "TRIP_BY_ENTITY_ID__CONFIRMATION_CODE_ASC",
  TRIP_BY_ENTITY_ID__CONFIRMATION_CODE_DESC = "TRIP_BY_ENTITY_ID__CONFIRMATION_CODE_DESC",
  TRIP_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__ID_ASC = "TRIP_BY_ENTITY_ID__ID_ASC",
  TRIP_BY_ENTITY_ID__ID_DESC = "TRIP_BY_ENTITY_ID__ID_DESC",
  TRIP_BY_ENTITY_ID__INCIDENTAL_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__INCIDENTAL_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__INCIDENTAL_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__INCIDENTAL_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__INCIDENTAL_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__INCIDENTAL_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__INCIDENTAL_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__INCIDENTAL_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC = "TRIP_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC",
  TRIP_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC = "TRIP_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC",
  TRIP_BY_ENTITY_ID__OTHER_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__OTHER_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__OTHER_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__OTHER_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__OTHER_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__OTHER_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__OTHER_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__OTHER_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC = "TRIP_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC",
  TRIP_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC = "TRIP_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC",
  TRIP_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRIP_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRIP_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRIP_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRIP_BY_ENTITY_ID__SORT_TRAVELER_NAME_ASC = "TRIP_BY_ENTITY_ID__SORT_TRAVELER_NAME_ASC",
  TRIP_BY_ENTITY_ID__SORT_TRAVELER_NAME_DESC = "TRIP_BY_ENTITY_ID__SORT_TRAVELER_NAME_DESC",
  TRIP_BY_ENTITY_ID__SPECIAL_INSTRUCTIONS_ASC = "TRIP_BY_ENTITY_ID__SPECIAL_INSTRUCTIONS_ASC",
  TRIP_BY_ENTITY_ID__SPECIAL_INSTRUCTIONS_DESC = "TRIP_BY_ENTITY_ID__SPECIAL_INSTRUCTIONS_DESC",
  TRIP_BY_ENTITY_ID__TRAIN_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__TRAIN_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__TRAIN_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__TRAIN_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__TRAIN_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__TRAIN_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__TRAIN_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__TRAIN_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__TRANSPORTATION_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__TRANSPORTATION_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__TRANSPORTATION_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__TRANSPORTATION_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__TRANSPORTATION_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__TRANSPORTATION_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__TRANSPORTATION_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__TRANSPORTATION_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__TRAVELER_ID_ASC = "TRIP_BY_ENTITY_ID__TRAVELER_ID_ASC",
  TRIP_BY_ENTITY_ID__TRAVELER_ID_DESC = "TRIP_BY_ENTITY_ID__TRAVELER_ID_DESC",
  TRIP_BY_ENTITY_ID__TRAVELER_REFERENCE_NAME_ASC = "TRIP_BY_ENTITY_ID__TRAVELER_REFERENCE_NAME_ASC",
  TRIP_BY_ENTITY_ID__TRAVELER_REFERENCE_NAME_DESC = "TRIP_BY_ENTITY_ID__TRAVELER_REFERENCE_NAME_DESC",
  TRIP_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC = "TRIP_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC",
  TRIP_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC = "TRIP_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC",
  TRIP_BY_ENTITY_ID__TRIP_LOCATOR_ASC = "TRIP_BY_ENTITY_ID__TRIP_LOCATOR_ASC",
  TRIP_BY_ENTITY_ID__TRIP_LOCATOR_DESC = "TRIP_BY_ENTITY_ID__TRIP_LOCATOR_DESC",
  TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_ASC = "TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_ASC",
  TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_DESC = "TRIP_BY_ENTITY_ID__VEHICLE_RENTAL_BUDGET_AMOUNT_DESC",
  TRIP_BY_ENTITY_ID__WARNING_INFO_1_ASC = "TRIP_BY_ENTITY_ID__WARNING_INFO_1_ASC",
  TRIP_BY_ENTITY_ID__WARNING_INFO_1_DESC = "TRIP_BY_ENTITY_ID__WARNING_INFO_1_DESC",
  TRIP_BY_ENTITY_ID__WARNING_INFO_2_ASC = "TRIP_BY_ENTITY_ID__WARNING_INFO_2_ASC",
  TRIP_BY_ENTITY_ID__WARNING_INFO_2_DESC = "TRIP_BY_ENTITY_ID__WARNING_INFO_2_DESC",
  TRIP_BY_ENTITY_ID__WARNING_INFO_3_ASC = "TRIP_BY_ENTITY_ID__WARNING_INFO_3_ASC",
  TRIP_BY_ENTITY_ID__WARNING_INFO_3_DESC = "TRIP_BY_ENTITY_ID__WARNING_INFO_3_DESC",
  TRIP_BY_ENTITY_ID__WARNING_VALUE_1_ASC = "TRIP_BY_ENTITY_ID__WARNING_VALUE_1_ASC",
  TRIP_BY_ENTITY_ID__WARNING_VALUE_1_DESC = "TRIP_BY_ENTITY_ID__WARNING_VALUE_1_DESC",
  TRIP_BY_ENTITY_ID__WARNING_VALUE_2_ASC = "TRIP_BY_ENTITY_ID__WARNING_VALUE_2_ASC",
  TRIP_BY_ENTITY_ID__WARNING_VALUE_2_DESC = "TRIP_BY_ENTITY_ID__WARNING_VALUE_2_DESC",
  TRIP_BY_ENTITY_ID__WARNING_VALUE_3_ASC = "TRIP_BY_ENTITY_ID__WARNING_VALUE_3_ASC",
  TRIP_BY_ENTITY_ID__WARNING_VALUE_3_DESC = "TRIP_BY_ENTITY_ID__WARNING_VALUE_3_DESC",
  TRIP_BY_ENTITY_ID___COMPANY_ID_ASC = "TRIP_BY_ENTITY_ID___COMPANY_ID_ASC",
  TRIP_BY_ENTITY_ID___COMPANY_ID_DESC = "TRIP_BY_ENTITY_ID___COMPANY_ID_DESC",
  TRIP_BY_ENTITY_ID___CREATED_DATE_ASC = "TRIP_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRIP_BY_ENTITY_ID___CREATED_DATE_DESC = "TRIP_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRIP_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRIP_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRIP_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRIP_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRIP_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRIP_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRIP_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRIP_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRIP_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRIP_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRIP_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRIP_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRIP_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRIP_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRIP_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRIP_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRIP_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "TRIP_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  TRIP_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "TRIP_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  TRIP_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRIP_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRIP_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRIP_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRIP_BY_ENTITY_ID___IS_OVER_BUDGET_ASC = "TRIP_BY_ENTITY_ID___IS_OVER_BUDGET_ASC",
  TRIP_BY_ENTITY_ID___IS_OVER_BUDGET_DESC = "TRIP_BY_ENTITY_ID___IS_OVER_BUDGET_DESC",
  TRIP_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRIP_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRIP_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRIP_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRIP_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRIP_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRIP_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRIP_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRIP_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRIP_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRIP_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRIP_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRIP_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRIP_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRIP_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRIP_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRIP_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRIP_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRIP_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRIP_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC = "USER_APPROVAL_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC = "USER_APPROVAL_BY_ENTITY_ID__ADDITIONAL_APPROVAL_INFORMATION_DESC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_1_ASC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_1_ASC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_1_DESC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_1_DESC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_2_ASC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_2_ASC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_2_DESC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_2_DESC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_3_ASC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_3_ASC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_3_DESC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_3_DESC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_4_ASC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_4_ASC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_4_DESC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_4_DESC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_5_ASC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_5_ASC",
  USER_APPROVAL_BY_ENTITY_ID__AMOUNT_5_DESC = "USER_APPROVAL_BY_ENTITY_ID__AMOUNT_5_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_BRANCH_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_BRANCH_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_BRANCH_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_BRANCH_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_GROUP_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_GROUP_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_GROUP_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_GROUP_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PERSONA_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PERSONA_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PERSONA_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PERSONA_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TOPIC_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TOPIC_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TOPIC_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TOPIC_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TREE_LEVEL_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__APPROVER_USER_PROFILE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__APP_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__APP_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__APP_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__APP_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_APPROVED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_APPROVED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_APPROVED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_APPROVED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_ASC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_DESC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_DIGITAL_SIGNATURE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_REJECTED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_REJECTED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_REJECTED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_REJECTED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__BRANCH_STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ISO_CODE_ASC = "USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ISO_CODE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ISO_CODE_DESC = "USER_APPROVAL_BY_ENTITY_ID__CURRENCY_ISO_CODE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_1_ASC = "USER_APPROVAL_BY_ENTITY_ID__DATE_1_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_1_DESC = "USER_APPROVAL_BY_ENTITY_ID__DATE_1_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_2_ASC = "USER_APPROVAL_BY_ENTITY_ID__DATE_2_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_2_DESC = "USER_APPROVAL_BY_ENTITY_ID__DATE_2_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_3_ASC = "USER_APPROVAL_BY_ENTITY_ID__DATE_3_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_3_DESC = "USER_APPROVAL_BY_ENTITY_ID__DATE_3_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_4_ASC = "USER_APPROVAL_BY_ENTITY_ID__DATE_4_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_4_DESC = "USER_APPROVAL_BY_ENTITY_ID__DATE_4_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_5_ASC = "USER_APPROVAL_BY_ENTITY_ID__DATE_5_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DATE_5_DESC = "USER_APPROVAL_BY_ENTITY_ID__DATE_5_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_10_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_10_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_10_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_10_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_1_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_1_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_1_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_1_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_2_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_2_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_2_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_2_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_3_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_3_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_3_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_3_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_4_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_4_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_4_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_4_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_5_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_5_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_5_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_5_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_6_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_6_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_6_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_6_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_7_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_7_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_7_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_7_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_8_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_8_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_8_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_8_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_9_ASC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_9_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_9_DESC = "USER_APPROVAL_BY_ENTITY_ID__DESCRIPTION_9_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DIGITAL_SIGNATURE_ASC = "USER_APPROVAL_BY_ENTITY_ID__DIGITAL_SIGNATURE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DIGITAL_SIGNATURE_DESC = "USER_APPROVAL_BY_ENTITY_ID__DIGITAL_SIGNATURE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__DISPLAY_ENTITY_APPROVAL_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_COMPANY_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_APPROVAL_TREE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ENTITY_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ENTITY_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__FINALIZED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__FINALIZED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__FINALIZED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__FINALIZED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ICON_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ICON_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ICON_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ICON_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_ADDING_ALLOWED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_APPROVER_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_AUTO_APPROVAL_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_AUTO_APPROVAL_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_AUTO_APPROVAL_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_AUTO_APPROVAL_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_HISTORY_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_HISTORY_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_HISTORY_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_HISTORY_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_LEVEL_BYPASSING_ALLOWED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_OBSERVER_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_OBSERVER_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_OBSERVER_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_OBSERVER_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_PENDING_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_PENDING_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_PENDING_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_PENDING_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_REJECTED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_REJECTED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_REJECTED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_REJECTED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_REQUESTER_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_REQUESTER_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_REQUESTER_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_REQUESTER_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_REVOKED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_REVOKED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_REVOKED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_REVOKED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_ASC = "USER_APPROVAL_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_ASC",
  USER_APPROVAL_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_DESC = "USER_APPROVAL_BY_ENTITY_ID__IS_URGENCY_UPDATE_ALLOWED_DESC",
  USER_APPROVAL_BY_ENTITY_ID__LAYOUT_TYPE_ASC = "USER_APPROVAL_BY_ENTITY_ID__LAYOUT_TYPE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__LAYOUT_TYPE_DESC = "USER_APPROVAL_BY_ENTITY_ID__LAYOUT_TYPE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_1_ASC = "USER_APPROVAL_BY_ENTITY_ID__RATE_1_ASC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_1_DESC = "USER_APPROVAL_BY_ENTITY_ID__RATE_1_DESC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_2_ASC = "USER_APPROVAL_BY_ENTITY_ID__RATE_2_ASC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_2_DESC = "USER_APPROVAL_BY_ENTITY_ID__RATE_2_DESC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_3_ASC = "USER_APPROVAL_BY_ENTITY_ID__RATE_3_ASC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_3_DESC = "USER_APPROVAL_BY_ENTITY_ID__RATE_3_DESC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_4_ASC = "USER_APPROVAL_BY_ENTITY_ID__RATE_4_ASC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_4_DESC = "USER_APPROVAL_BY_ENTITY_ID__RATE_4_DESC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_5_ASC = "USER_APPROVAL_BY_ENTITY_ID__RATE_5_ASC",
  USER_APPROVAL_BY_ENTITY_ID__RATE_5_DESC = "USER_APPROVAL_BY_ENTITY_ID__RATE_5_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REJECTED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__REJECTED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REJECTED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__REJECTED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REQUESTED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__REQUESTED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REQUESTED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__REQUESTED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REQUESTER_NAME_ASC = "USER_APPROVAL_BY_ENTITY_ID__REQUESTER_NAME_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REQUESTER_NAME_DESC = "USER_APPROVAL_BY_ENTITY_ID__REQUESTER_NAME_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__REQUESTER_USER_PROFILE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REQUIRED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REQUIRED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_NAME_ASC = "USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_NAME_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_NAME_DESC = "USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_NAME_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__REVOKED_BY_USER_PROFILE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__REVOKED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__REVOKED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__REVOKED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__REVOKED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__SORT_ORDER_GROUP_ASC = "USER_APPROVAL_BY_ENTITY_ID__SORT_ORDER_GROUP_ASC",
  USER_APPROVAL_BY_ENTITY_ID__SORT_ORDER_GROUP_DESC = "USER_APPROVAL_BY_ENTITY_ID__SORT_ORDER_GROUP_DESC",
  USER_APPROVAL_BY_ENTITY_ID__STATUS_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__STATUS_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__STATUS_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__TREE_APPROVED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__TREE_APPROVED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__TREE_APPROVED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__TREE_APPROVED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__TREE_REJECTED_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID__TREE_REJECTED_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID__TREE_REJECTED_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID__TREE_REJECTED_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID__TREE_STATUS_TYPE_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_1_ASC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_1_ASC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_1_DESC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_1_DESC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_2_ASC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_2_ASC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_2_DESC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_2_DESC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_3_ASC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_3_ASC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_3_DESC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_3_DESC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_4_ASC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_4_ASC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_4_DESC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_4_DESC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_5_ASC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_5_ASC",
  USER_APPROVAL_BY_ENTITY_ID__UNIT_5_DESC = "USER_APPROVAL_BY_ENTITY_ID__UNIT_5_DESC",
  USER_APPROVAL_BY_ENTITY_ID__URGENCY_LEVEL_ASC = "USER_APPROVAL_BY_ENTITY_ID__URGENCY_LEVEL_ASC",
  USER_APPROVAL_BY_ENTITY_ID__URGENCY_LEVEL_DESC = "USER_APPROVAL_BY_ENTITY_ID__URGENCY_LEVEL_DESC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "USER_APPROVAL_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  USER_APPROVAL_BY_ENTITY_ID___COMPANY_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID___COMPANY_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID___COMPANY_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID___COMPANY_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_LOOKUP_NAME_APPLIED_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_REVOCABLE_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_REVOCABLE_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_REVOCABLE_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_REVOCABLE_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_URGENT_APPROVAL_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_URGENT_APPROVAL_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_URGENT_APPROVAL_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_URGENT_APPROVAL_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC",
  USER_APPROVAL_BY_ENTITY_ID___IS_VALIDATING_ASC = "USER_APPROVAL_BY_ENTITY_ID___IS_VALIDATING_ASC",
  USER_APPROVAL_BY_ENTITY_ID___IS_VALIDATING_DESC = "USER_APPROVAL_BY_ENTITY_ID___IS_VALIDATING_DESC",
  USER_APPROVAL_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "USER_APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  USER_APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "USER_APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_END_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_END_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_END_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_END_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_ID_DESC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_START_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_START_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_START_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_START_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_YEAR_ASC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_YEAR_ASC",
  USER_APPROVAL_BY_ENTITY_ID___PERIOD_YEAR_DESC = "USER_APPROVAL_BY_ENTITY_ID___PERIOD_YEAR_DESC",
  USER_APPROVAL_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "USER_APPROVAL_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  USER_APPROVAL_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "USER_APPROVAL_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_DATE_ASC = "USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_DATE_ASC",
  USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_DATE_DESC = "USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_DATE_DESC",
  USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_ASC = "USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_ASC",
  USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_DESC = "USER_APPROVAL_BY_ENTITY_ID___VERIFICATION_LINK_TYPE_ID_DESC",
  USER_BY_ID__DIGITAL_SIGNATURE_ASC = "USER_BY_ID__DIGITAL_SIGNATURE_ASC",
  USER_BY_ID__DIGITAL_SIGNATURE_DESC = "USER_BY_ID__DIGITAL_SIGNATURE_DESC",
  USER_BY_ID__EMAIL_ACCOUNT_ASC = "USER_BY_ID__EMAIL_ACCOUNT_ASC",
  USER_BY_ID__EMAIL_ACCOUNT_DESC = "USER_BY_ID__EMAIL_ACCOUNT_DESC",
  USER_BY_ID__ID_ASC = "USER_BY_ID__ID_ASC",
  USER_BY_ID__ID_DESC = "USER_BY_ID__ID_DESC",
  USER_BY_ID__IS_ACCESS_ALL_COMPANIES_ASC = "USER_BY_ID__IS_ACCESS_ALL_COMPANIES_ASC",
  USER_BY_ID__IS_ACCESS_ALL_COMPANIES_DESC = "USER_BY_ID__IS_ACCESS_ALL_COMPANIES_DESC",
  USER_BY_ID__IS_DRAFT_ASC = "USER_BY_ID__IS_DRAFT_ASC",
  USER_BY_ID__IS_DRAFT_DESC = "USER_BY_ID__IS_DRAFT_DESC",
  USER_BY_ID__IS_RESTRICTED_USER_ASC = "USER_BY_ID__IS_RESTRICTED_USER_ASC",
  USER_BY_ID__IS_RESTRICTED_USER_DESC = "USER_BY_ID__IS_RESTRICTED_USER_DESC",
  USER_BY_ID__IS_SIGNIN_ALLOWED_ASC = "USER_BY_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_BY_ID__IS_SIGNIN_ALLOWED_DESC = "USER_BY_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_BY_ID__LANGUAGE_ID_ASC = "USER_BY_ID__LANGUAGE_ID_ASC",
  USER_BY_ID__LANGUAGE_ID_DESC = "USER_BY_ID__LANGUAGE_ID_DESC",
  USER_BY_ID__NAME_ASC = "USER_BY_ID__NAME_ASC",
  USER_BY_ID__NAME_DESC = "USER_BY_ID__NAME_DESC",
  USER_BY_ID__TENANT_ID_ASC = "USER_BY_ID__TENANT_ID_ASC",
  USER_BY_ID__TENANT_ID_DESC = "USER_BY_ID__TENANT_ID_DESC",
  USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  USER_BY_ID___CREATED_BY_USERID_ASC = "USER_BY_ID___CREATED_BY_USERID_ASC",
  USER_BY_ID___CREATED_BY_USERID_DESC = "USER_BY_ID___CREATED_BY_USERID_DESC",
  USER_BY_ID___CREATED_DATE_ASC = "USER_BY_ID___CREATED_DATE_ASC",
  USER_BY_ID___CREATED_DATE_DESC = "USER_BY_ID___CREATED_DATE_DESC",
  USER_BY_ID___IS_ACTIVE_ASC = "USER_BY_ID___IS_ACTIVE_ASC",
  USER_BY_ID___IS_ACTIVE_DESC = "USER_BY_ID___IS_ACTIVE_DESC",
  USER_BY_ID___IS_ACTIVE_TENANT_USER_ASC = "USER_BY_ID___IS_ACTIVE_TENANT_USER_ASC",
  USER_BY_ID___IS_ACTIVE_TENANT_USER_DESC = "USER_BY_ID___IS_ACTIVE_TENANT_USER_DESC",
  USER_BY_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_BY_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_BY_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_BY_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_BY_ID___IS_APPROVED_ASC = "USER_BY_ID___IS_APPROVED_ASC",
  USER_BY_ID___IS_APPROVED_DESC = "USER_BY_ID___IS_APPROVED_DESC",
  USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC = "USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC",
  USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC = "USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC",
  USER_BY_ID___IS_AUTHORIZED_USER_ASC = "USER_BY_ID___IS_AUTHORIZED_USER_ASC",
  USER_BY_ID___IS_AUTHORIZED_USER_DESC = "USER_BY_ID___IS_AUTHORIZED_USER_DESC",
  USER_BY_ID___IS_DELETABLE_ASC = "USER_BY_ID___IS_DELETABLE_ASC",
  USER_BY_ID___IS_DELETABLE_DESC = "USER_BY_ID___IS_DELETABLE_DESC",
  USER_BY_ID___IS_DEPRECATED_ASC = "USER_BY_ID___IS_DEPRECATED_ASC",
  USER_BY_ID___IS_DEPRECATED_DESC = "USER_BY_ID___IS_DEPRECATED_DESC",
  USER_BY_ID___IS_EMAIL_EXISTS_ASC = "USER_BY_ID___IS_EMAIL_EXISTS_ASC",
  USER_BY_ID___IS_EMAIL_EXISTS_DESC = "USER_BY_ID___IS_EMAIL_EXISTS_DESC",
  USER_BY_ID___IS_MODIFIED_ASC = "USER_BY_ID___IS_MODIFIED_ASC",
  USER_BY_ID___IS_MODIFIED_DESC = "USER_BY_ID___IS_MODIFIED_DESC",
  USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_BY_ID___IS_TENANT_USER_EXISTS_ASC = "USER_BY_ID___IS_TENANT_USER_EXISTS_ASC",
  USER_BY_ID___IS_TENANT_USER_EXISTS_DESC = "USER_BY_ID___IS_TENANT_USER_EXISTS_DESC",
  USER_BY_ID___IS_UPDATABLE_ASC = "USER_BY_ID___IS_UPDATABLE_ASC",
  USER_BY_ID___IS_UPDATABLE_DESC = "USER_BY_ID___IS_UPDATABLE_DESC",
  USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_BY_ID___IS_USER_APPROVER_ASC = "USER_BY_ID___IS_USER_APPROVER_ASC",
  USER_BY_ID___IS_USER_APPROVER_DESC = "USER_BY_ID___IS_USER_APPROVER_DESC",
  USER_BY_ID___IS_USER_OBSERVER_ASC = "USER_BY_ID___IS_USER_OBSERVER_ASC",
  USER_BY_ID___IS_USER_OBSERVER_DESC = "USER_BY_ID___IS_USER_OBSERVER_DESC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_BY_ID___IS_USER_REQUESTER_ASC = "USER_BY_ID___IS_USER_REQUESTER_ASC",
  USER_BY_ID___IS_USER_REQUESTER_DESC = "USER_BY_ID___IS_USER_REQUESTER_DESC",
  USER_BY_ID___IS_USER_REVOKER_ASC = "USER_BY_ID___IS_USER_REVOKER_ASC",
  USER_BY_ID___IS_USER_REVOKER_DESC = "USER_BY_ID___IS_USER_REVOKER_DESC",
  USER_BY_ID___IS_VALIDATED_ASC = "USER_BY_ID___IS_VALIDATED_ASC",
  USER_BY_ID___IS_VALIDATED_DESC = "USER_BY_ID___IS_VALIDATED_DESC",
  USER_BY_ID___IS_WAITING_APPROVAL_ASC = "USER_BY_ID___IS_WAITING_APPROVAL_ASC",
  USER_BY_ID___IS_WAITING_APPROVAL_DESC = "USER_BY_ID___IS_WAITING_APPROVAL_DESC",
  USER_BY_ID___IS_WAITING_VALIDATION_ASC = "USER_BY_ID___IS_WAITING_VALIDATION_ASC",
  USER_BY_ID___IS_WAITING_VALIDATION_DESC = "USER_BY_ID___IS_WAITING_VALIDATION_DESC",
  USER_BY_ID___ROW_TIMESTAMP_ASC = "USER_BY_ID___ROW_TIMESTAMP_ASC",
  USER_BY_ID___ROW_TIMESTAMP_DESC = "USER_BY_ID___ROW_TIMESTAMP_DESC",
  USER_BY_ID___STATUS_TYPE_ID_ASC = "USER_BY_ID___STATUS_TYPE_ID_ASC",
  USER_BY_ID___STATUS_TYPE_ID_DESC = "USER_BY_ID___STATUS_TYPE_ID_DESC",
  USER_PROFILES_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COUNT_ASC = "USER_PROFILES_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COUNT_ASC",
  USER_PROFILES_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COUNT_DESC = "USER_PROFILES_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COUNT_DESC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC",
  USER_PROFILE_BY_ENTITY_ID__ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_ASC = "USER_PROFILE_BY_ENTITY_ID__INVITED_ASC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_DESC = "USER_PROFILE_BY_ENTITY_ID__INVITED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  USER_PROFILE_BY_ENTITY_ID__NAME_ASC = "USER_PROFILE_BY_ENTITY_ID__NAME_ASC",
  USER_PROFILE_BY_ENTITY_ID__NAME_DESC = "USER_PROFILE_BY_ENTITY_ID__NAME_DESC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__ID_ASC = "VENDOR_BY_ENTITY_ID__ID_ASC",
  VENDOR_BY_ENTITY_ID__ID_DESC = "VENDOR_BY_ENTITY_ID__ID_DESC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_ASC = "VENDOR_BY_ENTITY_ID___FULL_NAME_ASC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_DESC = "VENDOR_BY_ENTITY_ID___FULL_NAME_DESC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_ASC = "VENDOR_BY_ENTITY_ID___SORT_NAME_ASC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_DESC = "VENDOR_BY_ENTITY_ID___SORT_NAME_DESC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  VERIFIED_USER_ID_ASC = "VERIFIED_USER_ID_ASC",
  VERIFIED_USER_ID_DESC = "VERIFIED_USER_ID_DESC",
  _CDN_THUMBNAIL_GUID_ASC = "_CDN_THUMBNAIL_GUID_ASC",
  _CDN_THUMBNAIL_GUID_DESC = "_CDN_THUMBNAIL_GUID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_BY_USER_NAME_ASC = "_CREATED_BY_USER_NAME_ASC",
  _CREATED_BY_USER_NAME_DESC = "_CREATED_BY_USER_NAME_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _DIRECTORY_TYPE_ID_ASC = "_DIRECTORY_TYPE_ID_ASC",
  _DIRECTORY_TYPE_ID_DESC = "_DIRECTORY_TYPE_ID_DESC",
  _DOCUMENT_CONTENTS_ASC = "_DOCUMENT_CONTENTS_ASC",
  _DOCUMENT_CONTENTS_DESC = "_DOCUMENT_CONTENTS_DESC",
  _DOCUMENT_FILE_ID_ASC = "_DOCUMENT_FILE_ID_ASC",
  _DOCUMENT_FILE_ID_DESC = "_DOCUMENT_FILE_ID_DESC",
  _DOCUMENT_POOL_ID_ASC = "_DOCUMENT_POOL_ID_ASC",
  _DOCUMENT_POOL_ID_DESC = "_DOCUMENT_POOL_ID_DESC",
  _DOCUMENT_POOL_NAME_ASC = "_DOCUMENT_POOL_NAME_ASC",
  _DOCUMENT_POOL_NAME_DESC = "_DOCUMENT_POOL_NAME_DESC",
  _DOCUMENT_TYPE_ASC = "_DOCUMENT_TYPE_ASC",
  _DOCUMENT_TYPE_DESC = "_DOCUMENT_TYPE_DESC",
  _EMAIL_DOCUMENT_ID_ASC = "_EMAIL_DOCUMENT_ID_ASC",
  _EMAIL_DOCUMENT_ID_DESC = "_EMAIL_DOCUMENT_ID_DESC",
  _EMAIL_ROUTE_ID_ASC = "_EMAIL_ROUTE_ID_ASC",
  _EMAIL_ROUTE_ID_DESC = "_EMAIL_ROUTE_ID_DESC",
  _EMAIL_TYPE_ASC = "_EMAIL_TYPE_ASC",
  _EMAIL_TYPE_DESC = "_EMAIL_TYPE_DESC",
  _EMAIL_TYPE_ID_ASC = "_EMAIL_TYPE_ID_ASC",
  _EMAIL_TYPE_ID_DESC = "_EMAIL_TYPE_ID_DESC",
  _EXPORTED_BY_USER_ASC = "_EXPORTED_BY_USER_ASC",
  _EXPORTED_BY_USER_DESC = "_EXPORTED_BY_USER_DESC",
  _EXPORT_DATE_ASC = "_EXPORT_DATE_ASC",
  _EXPORT_DATE_DESC = "_EXPORT_DATE_DESC",
  _EXPORT_PROCESS_HISTORY_ID_ASC = "_EXPORT_PROCESS_HISTORY_ID_ASC",
  _EXPORT_PROCESS_HISTORY_ID_DESC = "_EXPORT_PROCESS_HISTORY_ID_DESC",
  _FILE_NAME_ASC = "_FILE_NAME_ASC",
  _FILE_NAME_DESC = "_FILE_NAME_DESC",
  _FILE_SIZE_ASC = "_FILE_SIZE_ASC",
  _FILE_SIZE_DESC = "_FILE_SIZE_DESC",
  _FILE_TYPE_ASC = "_FILE_TYPE_ASC",
  _FILE_TYPE_DESC = "_FILE_TYPE_DESC",
  _FILE_TYPE_ID_ASC = "_FILE_TYPE_ID_ASC",
  _FILE_TYPE_ID_DESC = "_FILE_TYPE_ID_DESC",
  _FILE_VIEWER_ASC = "_FILE_VIEWER_ASC",
  _FILE_VIEWER_DESC = "_FILE_VIEWER_DESC",
  _FULLY_QUALIFIED_DOCUMENT_NAME_ASC = "_FULLY_QUALIFIED_DOCUMENT_NAME_ASC",
  _FULLY_QUALIFIED_DOCUMENT_NAME_DESC = "_FULLY_QUALIFIED_DOCUMENT_NAME_DESC",
  _IS_ACCOUNTING_DOCUMENT_ASC = "_IS_ACCOUNTING_DOCUMENT_ASC",
  _IS_ACCOUNTING_DOCUMENT_DESC = "_IS_ACCOUNTING_DOCUMENT_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENT_AMOUNT_ACCUMULATED_ASC = "_IS_DOCUMENT_AMOUNT_ACCUMULATED_ASC",
  _IS_DOCUMENT_AMOUNT_ACCUMULATED_DESC = "_IS_DOCUMENT_AMOUNT_ACCUMULATED_DESC",
  _IS_DOCUMENT_AMOUNT_APPLIED_ASC = "_IS_DOCUMENT_AMOUNT_APPLIED_ASC",
  _IS_DOCUMENT_AMOUNT_APPLIED_DESC = "_IS_DOCUMENT_AMOUNT_APPLIED_DESC",
  _IS_DOCUMENT_AMOUNT_AVAILABLE_ASC = "_IS_DOCUMENT_AMOUNT_AVAILABLE_ASC",
  _IS_DOCUMENT_AMOUNT_AVAILABLE_DESC = "_IS_DOCUMENT_AMOUNT_AVAILABLE_DESC",
  _IS_DOCUMENT_RECEIPT_ACCUMULATED_ASC = "_IS_DOCUMENT_RECEIPT_ACCUMULATED_ASC",
  _IS_DOCUMENT_RECEIPT_ACCUMULATED_DESC = "_IS_DOCUMENT_RECEIPT_ACCUMULATED_DESC",
  _IS_DOCUMENT_SHARE_ASC = "_IS_DOCUMENT_SHARE_ASC",
  _IS_DOCUMENT_SHARE_DESC = "_IS_DOCUMENT_SHARE_DESC",
  _IS_DOCUMENT_TYPE_UPDATABLE_ASC = "_IS_DOCUMENT_TYPE_UPDATABLE_ASC",
  _IS_DOCUMENT_TYPE_UPDATABLE_DESC = "_IS_DOCUMENT_TYPE_UPDATABLE_DESC",
  _IS_FOLDER_STATUS_ACTIVE_PAYMENTS_ASC = "_IS_FOLDER_STATUS_ACTIVE_PAYMENTS_ASC",
  _IS_FOLDER_STATUS_ACTIVE_PAYMENTS_DESC = "_IS_FOLDER_STATUS_ACTIVE_PAYMENTS_DESC",
  _IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_ASC = "_IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_ASC",
  _IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_DESC = "_IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_DESC",
  _IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_ASC = "_IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_ASC",
  _IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_DESC = "_IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_DESC",
  _IS_FOLDER_STATUS_ENTERED_PAYMENTS_ASC = "_IS_FOLDER_STATUS_ENTERED_PAYMENTS_ASC",
  _IS_FOLDER_STATUS_ENTERED_PAYMENTS_DESC = "_IS_FOLDER_STATUS_ENTERED_PAYMENTS_DESC",
  _IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_ASC = "_IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_ASC",
  _IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_DESC = "_IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_DESC",
  _IS_INDEX_EXTRACTED_INFORMATION_ASC = "_IS_INDEX_EXTRACTED_INFORMATION_ASC",
  _IS_INDEX_EXTRACTED_INFORMATION_DESC = "_IS_INDEX_EXTRACTED_INFORMATION_DESC",
  _IS_LOADING_ASC = "_IS_LOADING_ASC",
  _IS_LOADING_DESC = "_IS_LOADING_DESC",
  _IS_LOAD_FAILED_ASC = "_IS_LOAD_FAILED_ASC",
  _IS_LOAD_FAILED_DESC = "_IS_LOAD_FAILED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_PERSONAL_POOL_ORIGIN_ASC = "_IS_PERSONAL_POOL_ORIGIN_ASC",
  _IS_PERSONAL_POOL_ORIGIN_DESC = "_IS_PERSONAL_POOL_ORIGIN_DESC",
  _IS_PERSONAL_POOL_RECIPIENT_ASC = "_IS_PERSONAL_POOL_RECIPIENT_ASC",
  _IS_PERSONAL_POOL_RECIPIENT_DESC = "_IS_PERSONAL_POOL_RECIPIENT_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_REPORTING_DOCUMENT_ASC = "_IS_REPORTING_DOCUMENT_ASC",
  _IS_REPORTING_DOCUMENT_DESC = "_IS_REPORTING_DOCUMENT_DESC",
  _IS_SELECTED_ASC = "_IS_SELECTED_ASC",
  _IS_SELECTED_DESC = "_IS_SELECTED_DESC",
  _IS_THUMBNAIL_CREATED_ASC = "_IS_THUMBNAIL_CREATED_ASC",
  _IS_THUMBNAIL_CREATED_DESC = "_IS_THUMBNAIL_CREATED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_VERIFIED_ASC = "_IS_VERIFIED_ASC",
  _IS_VERIFIED_DESC = "_IS_VERIFIED_DESC",
  _LOOKUP_NAME_ID_ASC = "_LOOKUP_NAME_ID_ASC",
  _LOOKUP_NAME_ID_DESC = "_LOOKUP_NAME_ID_DESC",
  _OUTPUT_FILE_NAME_ASC = "_OUTPUT_FILE_NAME_ASC",
  _OUTPUT_FILE_NAME_DESC = "_OUTPUT_FILE_NAME_DESC",
  _OVERRIDING_LANGUAGE_ID_ASC = "_OVERRIDING_LANGUAGE_ID_ASC",
  _OVERRIDING_LANGUAGE_ID_DESC = "_OVERRIDING_LANGUAGE_ID_DESC",
  _POOL_ENTITY_DOCUMENT_ID_ASC = "_POOL_ENTITY_DOCUMENT_ID_ASC",
  _POOL_ENTITY_DOCUMENT_ID_DESC = "_POOL_ENTITY_DOCUMENT_ID_DESC",
  _RECIPIENT_USER_ID_ASC = "_RECIPIENT_USER_ID_ASC",
  _RECIPIENT_USER_ID_DESC = "_RECIPIENT_USER_ID_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _SHARED_BY_USER_ASC = "_SHARED_BY_USER_ASC",
  _SHARED_BY_USER_DESC = "_SHARED_BY_USER_DESC",
  _SHARED_BY_USER_ID_ASC = "_SHARED_BY_USER_ID_ASC",
  _SHARED_BY_USER_ID_DESC = "_SHARED_BY_USER_ID_DESC",
  _SIGNED_EXECUTED_DATE_ASC = "_SIGNED_EXECUTED_DATE_ASC",
  _SIGNED_EXECUTED_DATE_DESC = "_SIGNED_EXECUTED_DATE_DESC",
  _SORT_EXPORT_DATE_ASC = "_SORT_EXPORT_DATE_ASC",
  _SORT_EXPORT_DATE_DESC = "_SORT_EXPORT_DATE_DESC",
  _UPLOADED_BY_USERID_ASC = "_UPLOADED_BY_USERID_ASC",
  _UPLOADED_BY_USERID_DESC = "_UPLOADED_BY_USERID_DESC",
  _UPLOADED_BY_USER_ASC = "_UPLOADED_BY_USER_ASC",
  _UPLOADED_BY_USER_DESC = "_UPLOADED_BY_USER_DESC",
  _UPLOAD_DATE_ASC = "_UPLOAD_DATE_ASC",
  _UPLOAD_DATE_DESC = "_UPLOAD_DATE_DESC",
  _VERIFIED_USER_NAME_ASC = "_VERIFIED_USER_NAME_ASC",
  _VERIFIED_USER_NAME_DESC = "_VERIFIED_USER_NAME_DESC",
}

export enum ExportDocumentsStatusType {
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export enum ExtractionLayout {
  AVATAR_WIRE_REQUEST = "AVATAR_WIRE_REQUEST",
  AVATAR_WIRE_REQUEST_N_INVOICES = "AVATAR_WIRE_REQUEST_N_INVOICES",
  AVATAR_WIRE_REQUEST_N_RECEIPTS = "AVATAR_WIRE_REQUEST_N_RECEIPTS",
  AZURE_1_INVOICE_N_INVOICES_PDF = "AZURE_1_INVOICE_N_INVOICES_PDF",
  AZURE_1_INVOICE_N_RECEIPTS_PDF = "AZURE_1_INVOICE_N_RECEIPTS_PDF",
  AZURE_1_INVOICE_PDF = "AZURE_1_INVOICE_PDF",
  AZURE_INVOICE_PDF = "AZURE_INVOICE_PDF",
  AZURE_JOURNAL_PDF = "AZURE_JOURNAL_PDF",
  AZURE_RECEIPT = "AZURE_RECEIPT",
  AZURE_RECEIPT_PDF = "AZURE_RECEIPT_PDF",
  AZURE_TRANSMITTAL_N_INVOICES_PDF = "AZURE_TRANSMITTAL_N_INVOICES_PDF",
  AZURE_TRANSMITTAL_N_RECEIPTS_PDF = "AZURE_TRANSMITTAL_N_RECEIPTS_PDF",
  AZURE_TRANSMITTAL_PDF = "AZURE_TRANSMITTAL_PDF",
  CC_CREW_TIME_CARD = "CC_CREW_TIME_CARD",
  CENTTRIP = "CENTTRIP",
  CENTTRIP_CARDHOLDER = "CENTTRIP_CARDHOLDER",
  CHECK_LAYOUT_1_PDF = "CHECK_LAYOUT_1_PDF",
  CSV_ACCOUNTS_STANDARD = "CSV_ACCOUNTS_STANDARD",
  CSV_EXPORT_STANDARD = "CSV_EXPORT_STANDARD",
  CSV_IMPORT_STANDARD = "CSV_IMPORT_STANDARD",
  CSV_PO_STANDARD = "CSV_PO_STANDARD",
  CSV_PO_TOPSHEET_STANDARD = "CSV_PO_TOPSHEET_STANDARD",
  CSV_SUPPLIER = "CSV_SUPPLIER",
  CSV_TA_STANDARD = "CSV_TA_STANDARD",
  CSV_TA_TOPSHEET_STANDARD = "CSV_TA_TOPSHEET_STANDARD",
  CSV_TOPSHEET_STANDARD = "CSV_TOPSHEET_STANDARD",
  CSV_TRANSACTION_STANDARD = "CSV_TRANSACTION_STANDARD",
  CSV_TRAVELER = "CSV_TRAVELER",
  CSV_TRIP_BREAKDOWN_STANDARD = "CSV_TRIP_BREAKDOWN_STANDARD",
  CSV_VENDORS_STANDARD = "CSV_VENDORS_STANDARD",
  DPO_PDF = "DPO_PDF",
  EPOL_CHECK_PAYMENT = "EPOL_CHECK_PAYMENT",
  EPOL_CREW_TIME_CARD = "EPOL_CREW_TIME_CARD",
  EPOL_PAYMENT_REGISTER = "EPOL_PAYMENT_REGISTER",
  EP_CREW_TIME_CARD = "EP_CREW_TIME_CARD",
  EP_SAG_TIME_CARD = "EP_SAG_TIME_CARD",
  HOD_PAYMENT_REQUEST = "HOD_PAYMENT_REQUEST",
  PSL_CHECK_PAYMENT = "PSL_CHECK_PAYMENT",
  PSL_CHECK_PAYMENT2 = "PSL_CHECK_PAYMENT2",
  PSL_CHECK_PAYMENT_DISNEY = "PSL_CHECK_PAYMENT_DISNEY",
  PSL_INVOICE_CSV = "PSL_INVOICE_CSV",
  PSL_JOURNAL_CSV = "PSL_JOURNAL_CSV",
  PSL_PAYMENT_REGISTER = "PSL_PAYMENT_REGISTER",
  PSL_PCARD_CSV = "PSL_PCARD_CSV",
  PSL_PDF = "PSL_PDF",
  PSL_PETTY_CASH_CSV = "PSL_PETTY_CASH_CSV",
  SMARTACCT_PAYMENT_POSTING = "SMARTACCT_PAYMENT_POSTING",
  SMARTACCT_PAYMENT_REGISTER = "SMARTACCT_PAYMENT_REGISTER",
  SMARTACCT_PAYMENT_REMITTANCE = "SMARTACCT_PAYMENT_REMITTANCE",
  SMARTACCT_PAYMENT_REMITTANCE_BATCH = "SMARTACCT_PAYMENT_REMITTANCE_BATCH",
  SMARTACCT_PAYMENT_REQUEST = "SMARTACCT_PAYMENT_REQUEST",
  SMARTACCT_PO_PDF = "SMARTACCT_PO_PDF",
  UNICORN_AMEX_STATEMENT = "UNICORN_AMEX_STATEMENT",
  UNICORN_CASHET_STATEMENT = "UNICORN_CASHET_STATEMENT",
  UNICORN_PAYMENT_REQUEST = "UNICORN_PAYMENT_REQUEST",
  UNICORN_PAYMENT_REQUEST_N_INVOICES = "UNICORN_PAYMENT_REQUEST_N_INVOICES",
  UNICORN_PAYMENT_REQUEST_N_RECEIPTS = "UNICORN_PAYMENT_REQUEST_N_RECEIPTS",
  UNICORN_PETTY_CASH = "UNICORN_PETTY_CASH",
  WIRE_LAYOUT_1_PDF = "WIRE_LAYOUT_1_PDF",
}

/**
 * Methods to use when ordering `FileCabinetPoolRecipient`.
 */
export enum FileCabinetPoolRecipientsOrderBy {
  DEFAULT_BUSINESS_UNIT_ID_ASC = "DEFAULT_BUSINESS_UNIT_ID_ASC",
  DEFAULT_BUSINESS_UNIT_ID_DESC = "DEFAULT_BUSINESS_UNIT_ID_DESC",
  DEFAULT_DEPARTMENT_ID_ASC = "DEFAULT_DEPARTMENT_ID_ASC",
  DEFAULT_DEPARTMENT_ID_DESC = "DEFAULT_DEPARTMENT_ID_DESC",
  DEFAULT_DIRECTORY_TYPE_ID_ASC = "DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DEFAULT_DIRECTORY_TYPE_ID_DESC = "DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DEFAULT_DOCUMENT_TYPE_ID_ASC = "DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DEFAULT_DOCUMENT_TYPE_ID_DESC = "DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DEFAULT_ENTITY_TYPE_ID_ASC = "DEFAULT_ENTITY_TYPE_ID_ASC",
  DEFAULT_ENTITY_TYPE_ID_DESC = "DEFAULT_ENTITY_TYPE_ID_DESC",
  DEFAULT_ROW_SECURITY_ID_ASC = "DEFAULT_ROW_SECURITY_ID_ASC",
  DEFAULT_ROW_SECURITY_ID_DESC = "DEFAULT_ROW_SECURITY_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__ID_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_ACCOUNTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_REPORTING_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__IS_SIGNING_REQUIRED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID__TRANSACTION_TYPE_ID_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_ACTIVE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_APPLIABLE_DOCUMENT_TYPE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_AUTO_VIEW_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEFAULT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DELETABLE_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DEPRECATED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_DOCUMENT_DISTRIBUTION_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_MULTI_DOCUMENT_BUNDLE_ALLOWED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_PO_SYSTEM_DOCUMENT_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_TAX_FIELD_TRACKED_DESC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_ASC",
  DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC = "DOCUMENT_TYPE_BY_DEFAULT_DOCUMENT_TYPE_ID___IS_UPDATABLE_DESC",
  EXTRACTION_TYPE_ID_ASC = "EXTRACTION_TYPE_ID_ASC",
  EXTRACTION_TYPE_ID_DESC = "EXTRACTION_TYPE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_BUSINESS_UNIT_SELECTOR_ASC = "IS_BUSINESS_UNIT_SELECTOR_ASC",
  IS_BUSINESS_UNIT_SELECTOR_DESC = "IS_BUSINESS_UNIT_SELECTOR_DESC",
  IS_COMPANY_SELECTOR_ASC = "IS_COMPANY_SELECTOR_ASC",
  IS_COMPANY_SELECTOR_DESC = "IS_COMPANY_SELECTOR_DESC",
  IS_CREATE_COPY_WITH_SELECTION_ASC = "IS_CREATE_COPY_WITH_SELECTION_ASC",
  IS_CREATE_COPY_WITH_SELECTION_DESC = "IS_CREATE_COPY_WITH_SELECTION_DESC",
  IS_DEPARTMENT_SELECTOR_ASC = "IS_DEPARTMENT_SELECTOR_ASC",
  IS_DEPARTMENT_SELECTOR_DESC = "IS_DEPARTMENT_SELECTOR_DESC",
  IS_DOCUMENT_TYPE_SELECTOR_ASC = "IS_DOCUMENT_TYPE_SELECTOR_ASC",
  IS_DOCUMENT_TYPE_SELECTOR_DESC = "IS_DOCUMENT_TYPE_SELECTOR_DESC",
  IS_ENTITY_TYPE_SELECTOR_ASC = "IS_ENTITY_TYPE_SELECTOR_ASC",
  IS_ENTITY_TYPE_SELECTOR_DESC = "IS_ENTITY_TYPE_SELECTOR_DESC",
  IS_PRIVATE_POOL_ASC = "IS_PRIVATE_POOL_ASC",
  IS_PRIVATE_POOL_DESC = "IS_PRIVATE_POOL_DESC",
  IS_RETAINED_WITH_SELECTION_ASC = "IS_RETAINED_WITH_SELECTION_ASC",
  IS_RETAINED_WITH_SELECTION_DESC = "IS_RETAINED_WITH_SELECTION_DESC",
  IS_SHAREABLE_DEFAULT_ASC = "IS_SHAREABLE_DEFAULT_ASC",
  IS_SHAREABLE_DEFAULT_DESC = "IS_SHAREABLE_DEFAULT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _ENTITY_TYPE_ID_ASC = "_ENTITY_TYPE_ID_ASC",
  _ENTITY_TYPE_ID_DESC = "_ENTITY_TYPE_ID_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
}

/**
 * Methods to use when ordering `FileCabinetPool`.
 */
export enum FileCabinetPoolsOrderBy {
  DEFAULT_BUSINESS_UNIT_ID_ASC = "DEFAULT_BUSINESS_UNIT_ID_ASC",
  DEFAULT_BUSINESS_UNIT_ID_DESC = "DEFAULT_BUSINESS_UNIT_ID_DESC",
  DEFAULT_DEPARTMENT_ID_ASC = "DEFAULT_DEPARTMENT_ID_ASC",
  DEFAULT_DEPARTMENT_ID_DESC = "DEFAULT_DEPARTMENT_ID_DESC",
  DEFAULT_DIRECTORY_TYPE_ID_ASC = "DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DEFAULT_DIRECTORY_TYPE_ID_DESC = "DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DEFAULT_DOCUMENT_TYPE_ID_ASC = "DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DEFAULT_DOCUMENT_TYPE_ID_DESC = "DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DEFAULT_ENTITY_TYPE_ID_ASC = "DEFAULT_ENTITY_TYPE_ID_ASC",
  DEFAULT_ENTITY_TYPE_ID_DESC = "DEFAULT_ENTITY_TYPE_ID_DESC",
  DEFAULT_ROW_SECURITY_ID_ASC = "DEFAULT_ROW_SECURITY_ID_ASC",
  DEFAULT_ROW_SECURITY_ID_DESC = "DEFAULT_ROW_SECURITY_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__AVAILABLE_DOCUMENTS_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_ASC",
  DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC = "DOCUMENT_POOL_DOCUMENT_TOTAL_BY_ID__SELECTED_DOCUMENTS_DESC",
  ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_DOCUMENT_POOL_ID_AND_ENTITY_TYPE_ID__COUNT_DESC",
  EXTRACTION_TYPE_ID_ASC = "EXTRACTION_TYPE_ID_ASC",
  EXTRACTION_TYPE_ID_DESC = "EXTRACTION_TYPE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_BUSINESS_UNIT_SELECTOR_ASC = "IS_BUSINESS_UNIT_SELECTOR_ASC",
  IS_BUSINESS_UNIT_SELECTOR_DESC = "IS_BUSINESS_UNIT_SELECTOR_DESC",
  IS_COMPANY_SELECTOR_ASC = "IS_COMPANY_SELECTOR_ASC",
  IS_COMPANY_SELECTOR_DESC = "IS_COMPANY_SELECTOR_DESC",
  IS_CREATE_COPY_WITH_SELECTION_ASC = "IS_CREATE_COPY_WITH_SELECTION_ASC",
  IS_CREATE_COPY_WITH_SELECTION_DESC = "IS_CREATE_COPY_WITH_SELECTION_DESC",
  IS_DEPARTMENT_SELECTOR_ASC = "IS_DEPARTMENT_SELECTOR_ASC",
  IS_DEPARTMENT_SELECTOR_DESC = "IS_DEPARTMENT_SELECTOR_DESC",
  IS_DOCUMENT_TYPE_SELECTOR_ASC = "IS_DOCUMENT_TYPE_SELECTOR_ASC",
  IS_DOCUMENT_TYPE_SELECTOR_DESC = "IS_DOCUMENT_TYPE_SELECTOR_DESC",
  IS_ENTITY_TYPE_SELECTOR_ASC = "IS_ENTITY_TYPE_SELECTOR_ASC",
  IS_ENTITY_TYPE_SELECTOR_DESC = "IS_ENTITY_TYPE_SELECTOR_DESC",
  IS_PRIVATE_POOL_ASC = "IS_PRIVATE_POOL_ASC",
  IS_PRIVATE_POOL_DESC = "IS_PRIVATE_POOL_DESC",
  IS_RETAINED_WITH_SELECTION_ASC = "IS_RETAINED_WITH_SELECTION_ASC",
  IS_RETAINED_WITH_SELECTION_DESC = "IS_RETAINED_WITH_SELECTION_DESC",
  IS_SHAREABLE_DEFAULT_ASC = "IS_SHAREABLE_DEFAULT_ASC",
  IS_SHAREABLE_DEFAULT_DESC = "IS_SHAREABLE_DEFAULT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _ENTITY_TYPE_ID_ASC = "_ENTITY_TYPE_ID_ASC",
  _ENTITY_TYPE_ID_DESC = "_ENTITY_TYPE_ID_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
}

export enum FileIconType {
  IMAGE = "IMAGE",
  MSEXCEL = "MSEXCEL",
  MSPOWERPOINT = "MSPOWERPOINT",
  MSWORD = "MSWORD",
  OTHER = "OTHER",
  PDF = "PDF",
  SOUND = "SOUND",
  TEXT = "TEXT",
  THUMBNAIL = "THUMBNAIL",
  VIDEO = "VIDEO",
}

export enum GenerateIntegrationStatusType {
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export enum IconState {
  ACTION_APPROVED = "ACTION_APPROVED",
  ACTION_PENDING = "ACTION_PENDING",
  ACTION_QUEUED = "ACTION_QUEUED",
  ACTION_REJECTED = "ACTION_REJECTED",
  ACTION_VALIDATING = "ACTION_VALIDATING",
  AMENDED = "AMENDED",
  APPROVED = "APPROVED",
  APPROVED_PENDING = "APPROVED_PENDING",
  CANCELED = "CANCELED",
  DRAFT = "DRAFT",
  ENTERED = "ENTERED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  REJECTED_PENDING = "REJECTED_PENDING",
  REQUESTED = "REQUESTED",
  SENT = "SENT",
  VALIDATING = "VALIDATING",
  VOIDED = "VOIDED",
}

/**
 * Methods to use when ordering `InvoiceBatch`.
 */
export enum InvoiceBatchesOrderBy {
  APPLIED_AMOUNT_ASC = "APPLIED_AMOUNT_ASC",
  APPLIED_AMOUNT_DESC = "APPLIED_AMOUNT_DESC",
  APPROVAL_HISTORY_ITEMS_BY_ENTITY_ITEM_ID__COUNT_ASC = "APPROVAL_HISTORY_ITEMS_BY_ENTITY_ITEM_ID__COUNT_ASC",
  APPROVAL_HISTORY_ITEMS_BY_ENTITY_ITEM_ID__COUNT_DESC = "APPROVAL_HISTORY_ITEMS_BY_ENTITY_ITEM_ID__COUNT_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__BUSINESS_UNIT_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__BUSINESS_UNIT_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__BUSINESS_UNIT_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__BUSINESS_UNIT_ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CONTROL_TOTAL_AMOUNT_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CONTROL_TOTAL_AMOUNT_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CONTROL_TOTAL_AMOUNT_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CONTROL_TOTAL_AMOUNT_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CORPORATE_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CORPORATE_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CORPORATE_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CORPORATE_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CURRENCY_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CURRENCY_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CURRENCY_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__CURRENCY_ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__DESCRIPTION_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__DESCRIPTION_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__DESCRIPTION_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__DESCRIPTION_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__IS_DRAFT_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__IS_DRAFT_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__IS_DRAFT_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__IS_DRAFT_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__POSTING_DATE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__POSTING_DATE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__POSTING_DATE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__POSTING_DATE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__PROJECT_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__PROJECT_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__PROJECT_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__PROJECT_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__REFERENCE_NUMBER_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__REFERENCE_NUMBER_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__REFERENCE_NUMBER_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__REFERENCE_NUMBER_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__WEEK_NUMBER_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__WEEK_NUMBER_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__WEEK_NUMBER_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID__WEEK_NUMBER_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_DATE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_DATE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_DATE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_DATE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_COUNT_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_COUNT_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_COUNT_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_COUNT_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_TOTAL_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_TOTAL_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_TOTAL_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___BATCH_TRANSACTION_TOTAL_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___COMPANY_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___COMPANY_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___COMPANY_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___COMPANY_ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USERID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USERID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USERID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USERID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USER_NAME_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USER_NAME_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_BY_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_DATE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_DATE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_DATE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___CREATED_DATE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACTIVE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACTIVE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACTIVE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_ACTIVE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_REVOCABLE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_REVOCABLE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_REVOCABLE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVAL_REVOCABLE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_APPROVED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_BATCH_SELECTABLE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_BATCH_SELECTABLE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_BATCH_SELECTABLE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_BATCH_SELECTABLE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DELETABLE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DELETABLE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DELETABLE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DELETABLE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DEPRECATED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DEPRECATED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DEPRECATED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DEPRECATED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DOCUMENTS_EXIST_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DOCUMENTS_EXIST_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DOCUMENTS_EXIST_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_DOCUMENTS_EXIST_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_HISTORY_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_HISTORY_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_HISTORY_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_HISTORY_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MESSAGES_EXIST_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MESSAGES_EXIST_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MESSAGES_EXIST_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MESSAGES_EXIST_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MODIFIED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MODIFIED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MODIFIED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_MODIFIED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_NOTES_EXIST_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_NOTES_EXIST_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_NOTES_EXIST_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_NOTES_EXIST_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_CANCELLED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_CANCELLED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_CANCELLED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_CANCELLED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_ENTRY_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_ENTRY_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_ENTRY_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_TRANSACTION_ENTRY_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_UPDATABLE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_UPDATABLE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_UPDATABLE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_UPDATABLE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVER_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVER_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVER_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_APPROVER_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_OBSERVER_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_OBSERVER_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_OBSERVER_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_OBSERVER_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REQUESTER_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REQUESTER_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REQUESTER_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REQUESTER_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REVOKER_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REVOKER_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REVOKER_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_USER_REVOKER_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VALIDATED_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VALIDATED_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VALIDATED_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VALIDATED_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VERIFIABLE_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VERIFIABLE_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VERIFIABLE_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_VERIFIABLE_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_APPROVAL_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_APPROVAL_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_APPROVAL_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_APPROVAL_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_VALIDATION_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_VALIDATION_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_VALIDATION_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___IS_WAITING_VALIDATION_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___PENDING_APPROVERS_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___PENDING_APPROVERS_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___PENDING_APPROVERS_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___PENDING_APPROVERS_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ROW_TIMESTAMP_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ROW_TIMESTAMP_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ROW_TIMESTAMP_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___ROW_TIMESTAMP_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___SEARCH_DOCUMENT_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___SEARCH_DOCUMENT_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___SEARCH_DOCUMENT_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___SEARCH_DOCUMENT_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___STATUS_TYPE_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___STATUS_TYPE_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___STATUS_TYPE_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___STATUS_TYPE_ID_DESC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___USER_GROUP_ID_ASC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___USER_GROUP_ID_ASC",
  BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___USER_GROUP_ID_DESC = "BATCH_TRANSACTION_BY_BATCH_TRANSACTION_ID___USER_GROUP_ID_DESC",
  BATCH_TRANSACTION_ID_ASC = "BATCH_TRANSACTION_ID_ASC",
  BATCH_TRANSACTION_ID_DESC = "BATCH_TRANSACTION_ID_DESC",
  COMMENT_ASC = "COMMENT_ASC",
  COMMENT_DESC = "COMMENT_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BATCH_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "INVOICE_BATCH_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  INVOICE_BATCH_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "INVOICE_BATCH_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_INVOICE_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_INVOICE_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_INVOICE_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_INVOICE_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_INVOICE_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_INVOICE_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_INVOICE_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_INVOICE_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_INVOICE_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_INVOICE_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_INVOICE_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_INVOICE_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_INVOICE_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_INVOICE_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_INVOICE_ID__BANK_NAME_ASC = "INVOICE_BY_INVOICE_ID__BANK_NAME_ASC",
  INVOICE_BY_INVOICE_ID__BANK_NAME_DESC = "INVOICE_BY_INVOICE_ID__BANK_NAME_DESC",
  INVOICE_BY_INVOICE_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_INVOICE_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_INVOICE_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_INVOICE_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_INVOICE_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_INVOICE_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_INVOICE_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_INVOICE_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_INVOICE_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_INVOICE_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_INVOICE_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_INVOICE_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_INVOICE_ID__BATCH_ID_ASC = "INVOICE_BY_INVOICE_ID__BATCH_ID_ASC",
  INVOICE_BY_INVOICE_ID__BATCH_ID_DESC = "INVOICE_BY_INVOICE_ID__BATCH_ID_DESC",
  INVOICE_BY_INVOICE_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_INVOICE_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_INVOICE_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_INVOICE_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_INVOICE_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_INVOICE_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_INVOICE_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_INVOICE_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_INVOICE_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_INVOICE_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_INVOICE_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_INVOICE_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_INVOICE_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_INVOICE_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_INVOICE_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_INVOICE_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_INVOICE_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_INVOICE_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_INVOICE_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_INVOICE_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_INVOICE_ID__CURRENCY_ID_ASC = "INVOICE_BY_INVOICE_ID__CURRENCY_ID_ASC",
  INVOICE_BY_INVOICE_ID__CURRENCY_ID_DESC = "INVOICE_BY_INVOICE_ID__CURRENCY_ID_DESC",
  INVOICE_BY_INVOICE_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_INVOICE_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_INVOICE_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_INVOICE_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_INVOICE_ID__DESCRIPTION_ASC = "INVOICE_BY_INVOICE_ID__DESCRIPTION_ASC",
  INVOICE_BY_INVOICE_ID__DESCRIPTION_DESC = "INVOICE_BY_INVOICE_ID__DESCRIPTION_DESC",
  INVOICE_BY_INVOICE_ID__ID_ASC = "INVOICE_BY_INVOICE_ID__ID_ASC",
  INVOICE_BY_INVOICE_ID__ID_DESC = "INVOICE_BY_INVOICE_ID__ID_DESC",
  INVOICE_BY_INVOICE_ID__INVOICE_DATE_ASC = "INVOICE_BY_INVOICE_ID__INVOICE_DATE_ASC",
  INVOICE_BY_INVOICE_ID__INVOICE_DATE_DESC = "INVOICE_BY_INVOICE_ID__INVOICE_DATE_DESC",
  INVOICE_BY_INVOICE_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_INVOICE_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_INVOICE_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_INVOICE_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_INVOICE_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_INVOICE_ID__IS_DRAFT_ASC = "INVOICE_BY_INVOICE_ID__IS_DRAFT_ASC",
  INVOICE_BY_INVOICE_ID__IS_DRAFT_DESC = "INVOICE_BY_INVOICE_ID__IS_DRAFT_DESC",
  INVOICE_BY_INVOICE_ID__JOURNAL_DATE_ASC = "INVOICE_BY_INVOICE_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_INVOICE_ID__JOURNAL_DATE_DESC = "INVOICE_BY_INVOICE_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_INVOICE_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_INVOICE_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_INVOICE_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_INVOICE_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_INVOICE_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_INVOICE_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_INVOICE_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_INVOICE_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_INVOICE_ID__PAYMENT_ID_ASC = "INVOICE_BY_INVOICE_ID__PAYMENT_ID_ASC",
  INVOICE_BY_INVOICE_ID__PAYMENT_ID_DESC = "INVOICE_BY_INVOICE_ID__PAYMENT_ID_DESC",
  INVOICE_BY_INVOICE_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_INVOICE_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_INVOICE_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_INVOICE_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_INVOICE_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_INVOICE_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_INVOICE_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_INVOICE_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_INVOICE_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_INVOICE_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_INVOICE_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_INVOICE_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_INVOICE_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_INVOICE_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_INVOICE_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_INVOICE_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_INVOICE_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_INVOICE_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_INVOICE_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_INVOICE_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_INVOICE_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_INVOICE_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_INVOICE_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_INVOICE_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_INVOICE_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_INVOICE_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_INVOICE_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_INVOICE_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_INVOICE_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_INVOICE_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_INVOICE_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_INVOICE_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_INVOICE_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_INVOICE_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_INVOICE_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_INVOICE_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_INVOICE_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_INVOICE_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_INVOICE_ID__VENDOR_ID_ASC = "INVOICE_BY_INVOICE_ID__VENDOR_ID_ASC",
  INVOICE_BY_INVOICE_ID__VENDOR_ID_DESC = "INVOICE_BY_INVOICE_ID__VENDOR_ID_DESC",
  INVOICE_BY_INVOICE_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_INVOICE_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_INVOICE_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_INVOICE_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_INVOICE_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_INVOICE_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_INVOICE_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_INVOICE_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_INVOICE_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_INVOICE_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_INVOICE_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_INVOICE_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_INVOICE_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_INVOICE_ID___COMPANY_ID_ASC = "INVOICE_BY_INVOICE_ID___COMPANY_ID_ASC",
  INVOICE_BY_INVOICE_ID___COMPANY_ID_DESC = "INVOICE_BY_INVOICE_ID___COMPANY_ID_DESC",
  INVOICE_BY_INVOICE_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_INVOICE_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_INVOICE_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_INVOICE_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_INVOICE_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_INVOICE_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_INVOICE_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_INVOICE_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_INVOICE_ID___CREATED_DATE_ASC = "INVOICE_BY_INVOICE_ID___CREATED_DATE_ASC",
  INVOICE_BY_INVOICE_ID___CREATED_DATE_DESC = "INVOICE_BY_INVOICE_ID___CREATED_DATE_DESC",
  INVOICE_BY_INVOICE_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_INVOICE_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_INVOICE_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_INVOICE_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_INVOICE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_INVOICE_ID___IS_ACTIVE_ASC = "INVOICE_BY_INVOICE_ID___IS_ACTIVE_ASC",
  INVOICE_BY_INVOICE_ID___IS_ACTIVE_DESC = "INVOICE_BY_INVOICE_ID___IS_ACTIVE_DESC",
  INVOICE_BY_INVOICE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_INVOICE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_INVOICE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_INVOICE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_INVOICE_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_INVOICE_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_INVOICE_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_INVOICE_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_INVOICE_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_INVOICE_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_INVOICE_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_INVOICE_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_INVOICE_ID___IS_APPROVED_ASC = "INVOICE_BY_INVOICE_ID___IS_APPROVED_ASC",
  INVOICE_BY_INVOICE_ID___IS_APPROVED_DESC = "INVOICE_BY_INVOICE_ID___IS_APPROVED_DESC",
  INVOICE_BY_INVOICE_ID___IS_DELETABLE_ASC = "INVOICE_BY_INVOICE_ID___IS_DELETABLE_ASC",
  INVOICE_BY_INVOICE_ID___IS_DELETABLE_DESC = "INVOICE_BY_INVOICE_ID___IS_DELETABLE_DESC",
  INVOICE_BY_INVOICE_ID___IS_DEPRECATED_ASC = "INVOICE_BY_INVOICE_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_INVOICE_ID___IS_DEPRECATED_DESC = "INVOICE_BY_INVOICE_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_INVOICE_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_INVOICE_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_INVOICE_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_INVOICE_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_INVOICE_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_INVOICE_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_INVOICE_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_INVOICE_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_INVOICE_ID___IS_HISTORY_ASC = "INVOICE_BY_INVOICE_ID___IS_HISTORY_ASC",
  INVOICE_BY_INVOICE_ID___IS_HISTORY_DESC = "INVOICE_BY_INVOICE_ID___IS_HISTORY_DESC",
  INVOICE_BY_INVOICE_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_INVOICE_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_INVOICE_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_INVOICE_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_INVOICE_ID___IS_MODIFIED_ASC = "INVOICE_BY_INVOICE_ID___IS_MODIFIED_ASC",
  INVOICE_BY_INVOICE_ID___IS_MODIFIED_DESC = "INVOICE_BY_INVOICE_ID___IS_MODIFIED_DESC",
  INVOICE_BY_INVOICE_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_INVOICE_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_INVOICE_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_INVOICE_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_INVOICE_ID___IS_PROTECTED_ASC = "INVOICE_BY_INVOICE_ID___IS_PROTECTED_ASC",
  INVOICE_BY_INVOICE_ID___IS_PROTECTED_DESC = "INVOICE_BY_INVOICE_ID___IS_PROTECTED_DESC",
  INVOICE_BY_INVOICE_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_INVOICE_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_INVOICE_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_INVOICE_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_INVOICE_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_INVOICE_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_INVOICE_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_INVOICE_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_INVOICE_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_INVOICE_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_INVOICE_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_INVOICE_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_INVOICE_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_INVOICE_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_INVOICE_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_INVOICE_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_INVOICE_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_INVOICE_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_INVOICE_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_INVOICE_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_INVOICE_ID___IS_UPDATABLE_ASC = "INVOICE_BY_INVOICE_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_INVOICE_ID___IS_UPDATABLE_DESC = "INVOICE_BY_INVOICE_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_INVOICE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_INVOICE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_INVOICE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_INVOICE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_INVOICE_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_INVOICE_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_INVOICE_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_INVOICE_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_INVOICE_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_INVOICE_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_INVOICE_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_INVOICE_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_INVOICE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_INVOICE_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_INVOICE_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_INVOICE_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_INVOICE_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_INVOICE_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_INVOICE_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_INVOICE_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_INVOICE_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_INVOICE_ID___IS_VALIDATED_ASC = "INVOICE_BY_INVOICE_ID___IS_VALIDATED_ASC",
  INVOICE_BY_INVOICE_ID___IS_VALIDATED_DESC = "INVOICE_BY_INVOICE_ID___IS_VALIDATED_DESC",
  INVOICE_BY_INVOICE_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_INVOICE_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_INVOICE_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_INVOICE_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_INVOICE_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_INVOICE_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_INVOICE_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_INVOICE_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_INVOICE_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_INVOICE_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_INVOICE_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_INVOICE_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_INVOICE_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_INVOICE_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_INVOICE_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_INVOICE_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_INVOICE_ID___PAYMENT_DATE_ASC = "INVOICE_BY_INVOICE_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_INVOICE_ID___PAYMENT_DATE_DESC = "INVOICE_BY_INVOICE_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_INVOICE_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_INVOICE_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_INVOICE_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_INVOICE_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_INVOICE_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_INVOICE_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_INVOICE_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_INVOICE_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_INVOICE_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_INVOICE_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_INVOICE_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_INVOICE_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_INVOICE_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_INVOICE_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_INVOICE_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_INVOICE_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_INVOICE_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_INVOICE_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_INVOICE_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_INVOICE_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_INVOICE_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_INVOICE_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_INVOICE_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_INVOICE_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_INVOICE_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_INVOICE_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_INVOICE_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_INVOICE_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_INVOICE_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_INVOICE_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_INVOICE_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_INVOICE_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_INVOICE_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_INVOICE_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_INVOICE_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_INVOICE_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_INVOICE_ID___USER_GROUP_ID_ASC = "INVOICE_BY_INVOICE_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_INVOICE_ID___USER_GROUP_ID_DESC = "INVOICE_BY_INVOICE_ID___USER_GROUP_ID_DESC",
  INVOICE_ID_ASC = "INVOICE_ID_ASC",
  INVOICE_ID_DESC = "INVOICE_ID_DESC",
  IS_VERIFIED_ASC = "IS_VERIFIED_ASC",
  IS_VERIFIED_DESC = "IS_VERIFIED_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  TRANSACTION_TYPE_ID_ASC = "TRANSACTION_TYPE_ID_ASC",
  TRANSACTION_TYPE_ID_DESC = "TRANSACTION_TYPE_ID_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ASC = "_ACCOUNTING_STAMP_USER_ASC",
  _ACCOUNTING_STAMP_USER_DESC = "_ACCOUNTING_STAMP_USER_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_VERIFIABLE_ASC = "_IS_VERIFIABLE_ASC",
  _IS_VERIFIABLE_DESC = "_IS_VERIFIABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SORT_ACCOUNTING_REFERENCE_ASC = "_SORT_ACCOUNTING_REFERENCE_ASC",
  _SORT_ACCOUNTING_REFERENCE_DESC = "_SORT_ACCOUNTING_REFERENCE_DESC",
}

/**
 * Methods to use when ordering `Invoice`.
 */
export enum InvoicesOrderBy {
  ADDITIONAL_INFORMATION_STATUS_ASC = "ADDITIONAL_INFORMATION_STATUS_ASC",
  ADDITIONAL_INFORMATION_STATUS_DESC = "ADDITIONAL_INFORMATION_STATUS_DESC",
  ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  APPLIED_ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "APPLIED_ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  APPLIED_ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "APPLIED_ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORIES_BY_LINKED_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_LINKED_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_LINKED_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_LINKED_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_TRIGGER_DATE_ASC = "APPROVAL_TRIGGER_DATE_ASC",
  APPROVAL_TRIGGER_DATE_DESC = "APPROVAL_TRIGGER_DATE_DESC",
  BANK_ACCOUNT_NAME_ASC = "BANK_ACCOUNT_NAME_ASC",
  BANK_ACCOUNT_NAME_DESC = "BANK_ACCOUNT_NAME_DESC",
  BANK_ACCOUNT_NUMBER_ASC = "BANK_ACCOUNT_NUMBER_ASC",
  BANK_ACCOUNT_NUMBER_DESC = "BANK_ACCOUNT_NUMBER_DESC",
  BANK_CONFIRMATION_ASC = "BANK_CONFIRMATION_ASC",
  BANK_CONFIRMATION_DESC = "BANK_CONFIRMATION_DESC",
  BANK_IBAN_CODE_ASC = "BANK_IBAN_CODE_ASC",
  BANK_IBAN_CODE_DESC = "BANK_IBAN_CODE_DESC",
  BANK_NAME_ASC = "BANK_NAME_ASC",
  BANK_NAME_DESC = "BANK_NAME_DESC",
  BANK_ROUTING_NUMBER_ASC = "BANK_ROUTING_NUMBER_ASC",
  BANK_ROUTING_NUMBER_DESC = "BANK_ROUTING_NUMBER_DESC",
  BANK_SORT_CODE_ASC = "BANK_SORT_CODE_ASC",
  BANK_SORT_CODE_DESC = "BANK_SORT_CODE_DESC",
  BANK_SWIFT_CODE_ASC = "BANK_SWIFT_CODE_ASC",
  BANK_SWIFT_CODE_DESC = "BANK_SWIFT_CODE_DESC",
  BATCH_ID_ASC = "BATCH_ID_ASC",
  BATCH_ID_DESC = "BATCH_ID_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  CARD_ACCOUNT_ID_ASC = "CARD_ACCOUNT_ID_ASC",
  CARD_ACCOUNT_ID_DESC = "CARD_ACCOUNT_ID_DESC",
  CARD_HOLDER_ID_ASC = "CARD_HOLDER_ID_ASC",
  CARD_HOLDER_ID_DESC = "CARD_HOLDER_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC",
  COMPANY_BY_COMPANY_ID__ID_ASC = "COMPANY_BY_COMPANY_ID__ID_ASC",
  COMPANY_BY_COMPANY_ID__ID_DESC = "COMPANY_BY_COMPANY_ID__ID_DESC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC",
  COMPANY_BY_COMPANY_ID__NAME_ASC = "COMPANY_BY_COMPANY_ID__NAME_ASC",
  COMPANY_BY_COMPANY_ID__NAME_DESC = "COMPANY_BY_COMPANY_ID__NAME_DESC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_ASC = "COMPANY_BY_COMPANY_ID__TENANT_ID_ASC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_DESC = "COMPANY_BY_COMPANY_ID__TENANT_ID_DESC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_ACCOUNT_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_DEPARTMENT_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_EXPIRY_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FIRST_NAME_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_FULL_NAME_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_LAST_NAME_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_NUMBER_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__CARD_STATUS_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DEPARTMENT_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_POOL_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__DOCUMENT_TYPE_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__IS_MY_DOCUMENTS_USED_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__TENANT_USER_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID__USER_GROUP_ID_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___CREATED_DATE_DESC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_ASC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_ASC",
  COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_DESC = "COMPANY_CARD_HOLDER_BY_CARD_HOLDER_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__EXPORT_LAYOUT_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ID_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___COMPANY_ID_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___CREATED_DATE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_ACTIVE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DELETABLE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_DEPRECATED_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_MODIFIED_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___IS_UPDATABLE_DESC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_ASC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_ASC",
  COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_DESC = "COMPANY_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID___ROW_TIMESTAMP_DESC",
  CONTROL_TOTAL_AMOUNT_ASC = "CONTROL_TOTAL_AMOUNT_ASC",
  CONTROL_TOTAL_AMOUNT_DESC = "CONTROL_TOTAL_AMOUNT_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BATCHES_BY_INVOICE_ID__COUNT_ASC = "INVOICE_BATCHES_BY_INVOICE_ID__COUNT_ASC",
  INVOICE_BATCHES_BY_INVOICE_ID__COUNT_DESC = "INVOICE_BATCHES_BY_INVOICE_ID__COUNT_DESC",
  INVOICE_DATE_ASC = "INVOICE_DATE_ASC",
  INVOICE_DATE_DESC = "INVOICE_DATE_DESC",
  INVOICE_DISTRIBUTIONS_BY_INVOICE_ID__COUNT_ASC = "INVOICE_DISTRIBUTIONS_BY_INVOICE_ID__COUNT_ASC",
  INVOICE_DISTRIBUTIONS_BY_INVOICE_ID__COUNT_DESC = "INVOICE_DISTRIBUTIONS_BY_INVOICE_ID__COUNT_DESC",
  INVOICE_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "INVOICE_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  INVOICE_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "INVOICE_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  INVOICE_NUMBER_ASC = "INVOICE_NUMBER_ASC",
  INVOICE_NUMBER_DESC = "INVOICE_NUMBER_DESC",
  INVOICE_PAYMENTS_BY_INVOICE_ID__COUNT_ASC = "INVOICE_PAYMENTS_BY_INVOICE_ID__COUNT_ASC",
  INVOICE_PAYMENTS_BY_INVOICE_ID__COUNT_DESC = "INVOICE_PAYMENTS_BY_INVOICE_ID__COUNT_DESC",
  INVOICE_RECEIVED_DATE_ASC = "INVOICE_RECEIVED_DATE_ASC",
  INVOICE_RECEIVED_DATE_DESC = "INVOICE_RECEIVED_DATE_DESC",
  INVOICE_RECEIVED_TIME_ASC = "INVOICE_RECEIVED_TIME_ASC",
  INVOICE_RECEIVED_TIME_DESC = "INVOICE_RECEIVED_TIME_DESC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__APPLIED_TOTAL_ASC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__APPLIED_TOTAL_ASC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__APPLIED_TOTAL_DESC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__APPLIED_TOTAL_DESC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__ID_ASC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__ID_ASC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__ID_DESC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__ID_DESC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__OVERAGE_TOTAL_ASC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__OVERAGE_TOTAL_ASC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__OVERAGE_TOTAL_DESC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__OVERAGE_TOTAL_DESC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__RETIRED_TOTAL_ASC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__RETIRED_TOTAL_ASC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__RETIRED_TOTAL_DESC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__RETIRED_TOTAL_DESC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__USED_TOTAL_ASC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__USED_TOTAL_ASC",
  INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__USED_TOTAL_DESC = "INVOICE_SIGNING_APPLIED_AMOUNT_BY_ID__USED_TOTAL_DESC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__IS_DOCUMENT_SIGNING_PAYMENT_ASC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__IS_DOCUMENT_SIGNING_PAYMENT_ASC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__IS_DOCUMENT_SIGNING_PAYMENT_DESC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__IS_DOCUMENT_SIGNING_PAYMENT_DESC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC",
  INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC = "INVOICE_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  JOURNAL_DATE_ASC = "JOURNAL_DATE_ASC",
  JOURNAL_DATE_DESC = "JOURNAL_DATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PAYMENT_DUE_DATE_ASC = "PAYMENT_DUE_DATE_ASC",
  PAYMENT_DUE_DATE_DESC = "PAYMENT_DUE_DATE_DESC",
  PAYMENT_GROUP_TYPE_ID_ASC = "PAYMENT_GROUP_TYPE_ID_ASC",
  PAYMENT_GROUP_TYPE_ID_DESC = "PAYMENT_GROUP_TYPE_ID_DESC",
  PAYMENT_ID_ASC = "PAYMENT_ID_ASC",
  PAYMENT_ID_DESC = "PAYMENT_ID_DESC",
  PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__ID_ASC = "PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__ID_ASC",
  PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__ID_DESC = "PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__ID_DESC",
  PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__STATUS_TYPE_ASC = "PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__STATUS_TYPE_DESC = "PAYMENT_STATUS_TYPE_BY_PAYMENT_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  PAYMENT_TERM_TYPE_ID_ASC = "PAYMENT_TERM_TYPE_ID_ASC",
  PAYMENT_TERM_TYPE_ID_DESC = "PAYMENT_TERM_TYPE_ID_DESC",
  PETTY_CASH_ID_ASC = "PETTY_CASH_ID_ASC",
  PETTY_CASH_ID_DESC = "PETTY_CASH_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  PURCHASE_ORDER_INVOICE_SCHEDULES_BY_INVOICE_ID__COUNT_ASC = "PURCHASE_ORDER_INVOICE_SCHEDULES_BY_INVOICE_ID__COUNT_ASC",
  PURCHASE_ORDER_INVOICE_SCHEDULES_BY_INVOICE_ID__COUNT_DESC = "PURCHASE_ORDER_INVOICE_SCHEDULES_BY_INVOICE_ID__COUNT_DESC",
  PURCHASE_ORDER_REFERENCE_ASC = "PURCHASE_ORDER_REFERENCE_ASC",
  PURCHASE_ORDER_REFERENCE_DESC = "PURCHASE_ORDER_REFERENCE_DESC",
  REIMBURSE_ACCOUNT_ID_ASC = "REIMBURSE_ACCOUNT_ID_ASC",
  REIMBURSE_ACCOUNT_ID_DESC = "REIMBURSE_ACCOUNT_ID_DESC",
  REIMBURSE_ACCOUNT_REFERENCE_ASC = "REIMBURSE_ACCOUNT_REFERENCE_ASC",
  REIMBURSE_ACCOUNT_REFERENCE_DESC = "REIMBURSE_ACCOUNT_REFERENCE_DESC",
  REIMBURSE_AMOUNT_ASC = "REIMBURSE_AMOUNT_ASC",
  REIMBURSE_AMOUNT_DESC = "REIMBURSE_AMOUNT_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  STATUS_HINT_OVERRIDE_ASC = "STATUS_HINT_OVERRIDE_ASC",
  STATUS_HINT_OVERRIDE_DESC = "STATUS_HINT_OVERRIDE_DESC",
  STATUS_HINT_OVERRIDE_ID_ASC = "STATUS_HINT_OVERRIDE_ID_ASC",
  STATUS_HINT_OVERRIDE_ID_DESC = "STATUS_HINT_OVERRIDE_ID_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_ADDITIONAL_INFORMATION_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  TRANSACTION_NUMBER_REFERENCE_ASC = "TRANSACTION_NUMBER_REFERENCE_ASC",
  TRANSACTION_NUMBER_REFERENCE_DESC = "TRANSACTION_NUMBER_REFERENCE_DESC",
  TRANSACTION_TYPE_ID_ASC = "TRANSACTION_TYPE_ID_ASC",
  TRANSACTION_TYPE_ID_DESC = "TRANSACTION_TYPE_ID_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_APPROVALS_BY_LINKED_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_LINKED_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_LINKED_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_LINKED_ENTITY_ID__COUNT_DESC",
  USER_BY_ID__DIGITAL_SIGNATURE_ASC = "USER_BY_ID__DIGITAL_SIGNATURE_ASC",
  USER_BY_ID__DIGITAL_SIGNATURE_DESC = "USER_BY_ID__DIGITAL_SIGNATURE_DESC",
  USER_BY_ID__EMAIL_ACCOUNT_ASC = "USER_BY_ID__EMAIL_ACCOUNT_ASC",
  USER_BY_ID__EMAIL_ACCOUNT_DESC = "USER_BY_ID__EMAIL_ACCOUNT_DESC",
  USER_BY_ID__ID_ASC = "USER_BY_ID__ID_ASC",
  USER_BY_ID__ID_DESC = "USER_BY_ID__ID_DESC",
  USER_BY_ID__IS_ACCESS_ALL_COMPANIES_ASC = "USER_BY_ID__IS_ACCESS_ALL_COMPANIES_ASC",
  USER_BY_ID__IS_ACCESS_ALL_COMPANIES_DESC = "USER_BY_ID__IS_ACCESS_ALL_COMPANIES_DESC",
  USER_BY_ID__IS_DRAFT_ASC = "USER_BY_ID__IS_DRAFT_ASC",
  USER_BY_ID__IS_DRAFT_DESC = "USER_BY_ID__IS_DRAFT_DESC",
  USER_BY_ID__IS_RESTRICTED_USER_ASC = "USER_BY_ID__IS_RESTRICTED_USER_ASC",
  USER_BY_ID__IS_RESTRICTED_USER_DESC = "USER_BY_ID__IS_RESTRICTED_USER_DESC",
  USER_BY_ID__IS_SIGNIN_ALLOWED_ASC = "USER_BY_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_BY_ID__IS_SIGNIN_ALLOWED_DESC = "USER_BY_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_BY_ID__LANGUAGE_ID_ASC = "USER_BY_ID__LANGUAGE_ID_ASC",
  USER_BY_ID__LANGUAGE_ID_DESC = "USER_BY_ID__LANGUAGE_ID_DESC",
  USER_BY_ID__NAME_ASC = "USER_BY_ID__NAME_ASC",
  USER_BY_ID__NAME_DESC = "USER_BY_ID__NAME_DESC",
  USER_BY_ID__TENANT_ID_ASC = "USER_BY_ID__TENANT_ID_ASC",
  USER_BY_ID__TENANT_ID_DESC = "USER_BY_ID__TENANT_ID_DESC",
  USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_BY_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  USER_BY_ID___CREATED_BY_USERID_ASC = "USER_BY_ID___CREATED_BY_USERID_ASC",
  USER_BY_ID___CREATED_BY_USERID_DESC = "USER_BY_ID___CREATED_BY_USERID_DESC",
  USER_BY_ID___CREATED_DATE_ASC = "USER_BY_ID___CREATED_DATE_ASC",
  USER_BY_ID___CREATED_DATE_DESC = "USER_BY_ID___CREATED_DATE_DESC",
  USER_BY_ID___IS_ACTIVE_ASC = "USER_BY_ID___IS_ACTIVE_ASC",
  USER_BY_ID___IS_ACTIVE_DESC = "USER_BY_ID___IS_ACTIVE_DESC",
  USER_BY_ID___IS_ACTIVE_TENANT_USER_ASC = "USER_BY_ID___IS_ACTIVE_TENANT_USER_ASC",
  USER_BY_ID___IS_ACTIVE_TENANT_USER_DESC = "USER_BY_ID___IS_ACTIVE_TENANT_USER_DESC",
  USER_BY_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_BY_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_BY_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_BY_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_BY_ID___IS_APPROVED_ASC = "USER_BY_ID___IS_APPROVED_ASC",
  USER_BY_ID___IS_APPROVED_DESC = "USER_BY_ID___IS_APPROVED_DESC",
  USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC = "USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC",
  USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC = "USER_BY_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC",
  USER_BY_ID___IS_AUTHORIZED_USER_ASC = "USER_BY_ID___IS_AUTHORIZED_USER_ASC",
  USER_BY_ID___IS_AUTHORIZED_USER_DESC = "USER_BY_ID___IS_AUTHORIZED_USER_DESC",
  USER_BY_ID___IS_DELETABLE_ASC = "USER_BY_ID___IS_DELETABLE_ASC",
  USER_BY_ID___IS_DELETABLE_DESC = "USER_BY_ID___IS_DELETABLE_DESC",
  USER_BY_ID___IS_DEPRECATED_ASC = "USER_BY_ID___IS_DEPRECATED_ASC",
  USER_BY_ID___IS_DEPRECATED_DESC = "USER_BY_ID___IS_DEPRECATED_DESC",
  USER_BY_ID___IS_EMAIL_EXISTS_ASC = "USER_BY_ID___IS_EMAIL_EXISTS_ASC",
  USER_BY_ID___IS_EMAIL_EXISTS_DESC = "USER_BY_ID___IS_EMAIL_EXISTS_DESC",
  USER_BY_ID___IS_MODIFIED_ASC = "USER_BY_ID___IS_MODIFIED_ASC",
  USER_BY_ID___IS_MODIFIED_DESC = "USER_BY_ID___IS_MODIFIED_DESC",
  USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_BY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_BY_ID___IS_TENANT_USER_EXISTS_ASC = "USER_BY_ID___IS_TENANT_USER_EXISTS_ASC",
  USER_BY_ID___IS_TENANT_USER_EXISTS_DESC = "USER_BY_ID___IS_TENANT_USER_EXISTS_DESC",
  USER_BY_ID___IS_UPDATABLE_ASC = "USER_BY_ID___IS_UPDATABLE_ASC",
  USER_BY_ID___IS_UPDATABLE_DESC = "USER_BY_ID___IS_UPDATABLE_DESC",
  USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_BY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_BY_ID___IS_USER_APPROVER_ASC = "USER_BY_ID___IS_USER_APPROVER_ASC",
  USER_BY_ID___IS_USER_APPROVER_DESC = "USER_BY_ID___IS_USER_APPROVER_DESC",
  USER_BY_ID___IS_USER_OBSERVER_ASC = "USER_BY_ID___IS_USER_OBSERVER_ASC",
  USER_BY_ID___IS_USER_OBSERVER_DESC = "USER_BY_ID___IS_USER_OBSERVER_DESC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_BY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_BY_ID___IS_USER_REQUESTER_ASC = "USER_BY_ID___IS_USER_REQUESTER_ASC",
  USER_BY_ID___IS_USER_REQUESTER_DESC = "USER_BY_ID___IS_USER_REQUESTER_DESC",
  USER_BY_ID___IS_USER_REVOKER_ASC = "USER_BY_ID___IS_USER_REVOKER_ASC",
  USER_BY_ID___IS_USER_REVOKER_DESC = "USER_BY_ID___IS_USER_REVOKER_DESC",
  USER_BY_ID___IS_VALIDATED_ASC = "USER_BY_ID___IS_VALIDATED_ASC",
  USER_BY_ID___IS_VALIDATED_DESC = "USER_BY_ID___IS_VALIDATED_DESC",
  USER_BY_ID___IS_WAITING_APPROVAL_ASC = "USER_BY_ID___IS_WAITING_APPROVAL_ASC",
  USER_BY_ID___IS_WAITING_APPROVAL_DESC = "USER_BY_ID___IS_WAITING_APPROVAL_DESC",
  USER_BY_ID___IS_WAITING_VALIDATION_ASC = "USER_BY_ID___IS_WAITING_VALIDATION_ASC",
  USER_BY_ID___IS_WAITING_VALIDATION_DESC = "USER_BY_ID___IS_WAITING_VALIDATION_DESC",
  USER_BY_ID___ROW_TIMESTAMP_ASC = "USER_BY_ID___ROW_TIMESTAMP_ASC",
  USER_BY_ID___ROW_TIMESTAMP_DESC = "USER_BY_ID___ROW_TIMESTAMP_DESC",
  USER_BY_ID___STATUS_TYPE_ID_ASC = "USER_BY_ID___STATUS_TYPE_ID_ASC",
  USER_BY_ID___STATUS_TYPE_ID_DESC = "USER_BY_ID___STATUS_TYPE_ID_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC",
  VENDOR_ADDRESS_ID_ASC = "VENDOR_ADDRESS_ID_ASC",
  VENDOR_ADDRESS_ID_DESC = "VENDOR_ADDRESS_ID_DESC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_ASC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_ASC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_DESC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_DESC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_ASC = "VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_ASC",
  VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_DESC = "VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_DESC",
  VENDOR_BY_VENDOR_ID__BANK_NAME_ASC = "VENDOR_BY_VENDOR_ID__BANK_NAME_ASC",
  VENDOR_BY_VENDOR_ID__BANK_NAME_DESC = "VENDOR_BY_VENDOR_ID__BANK_NAME_DESC",
  VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_ASC = "VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_ASC",
  VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_DESC = "VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_DESC",
  VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_ASC = "VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_ASC",
  VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_DESC = "VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_DESC",
  VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_ASC = "VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_ASC",
  VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_DESC = "VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_DESC",
  VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_ASC = "VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_ASC",
  VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_DESC = "VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_DESC",
  VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_ASC = "VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_ASC",
  VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_DESC = "VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_DESC",
  VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_ASC = "VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_DESC = "VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_ASC = "VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_ASC",
  VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_DESC = "VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_DESC",
  VENDOR_BY_VENDOR_ID__FIRST_NAME_ASC = "VENDOR_BY_VENDOR_ID__FIRST_NAME_ASC",
  VENDOR_BY_VENDOR_ID__FIRST_NAME_DESC = "VENDOR_BY_VENDOR_ID__FIRST_NAME_DESC",
  VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__ID_ASC = "VENDOR_BY_VENDOR_ID__ID_ASC",
  VENDOR_BY_VENDOR_ID__ID_DESC = "VENDOR_BY_VENDOR_ID__ID_DESC",
  VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_CORPORATION_ASC = "VENDOR_BY_VENDOR_ID__IS_CORPORATION_ASC",
  VENDOR_BY_VENDOR_ID__IS_CORPORATION_DESC = "VENDOR_BY_VENDOR_ID__IS_CORPORATION_DESC",
  VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_ASC = "VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_ASC",
  VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_DESC = "VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_DESC",
  VENDOR_BY_VENDOR_ID__IS_DRAFT_ASC = "VENDOR_BY_VENDOR_ID__IS_DRAFT_ASC",
  VENDOR_BY_VENDOR_ID__IS_DRAFT_DESC = "VENDOR_BY_VENDOR_ID__IS_DRAFT_DESC",
  VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_ASC = "VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_ASC",
  VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_DESC = "VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_DESC",
  VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_ASC = "VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_ASC",
  VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_DESC = "VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_DESC",
  VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC = "VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC",
  VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC = "VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC",
  VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  VENDOR_BY_VENDOR_ID__IS_TRAVELER_ASC = "VENDOR_BY_VENDOR_ID__IS_TRAVELER_ASC",
  VENDOR_BY_VENDOR_ID__IS_TRAVELER_DESC = "VENDOR_BY_VENDOR_ID__IS_TRAVELER_DESC",
  VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_ASC = "VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_ASC",
  VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_DESC = "VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_DESC",
  VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_ASC = "VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_ASC",
  VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_DESC = "VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_DESC",
  VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_ASC = "VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_ASC",
  VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_DESC = "VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_DESC",
  VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_ASC = "VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_ASC",
  VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_DESC = "VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_DESC",
  VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_ASC = "VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_ASC",
  VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_DESC = "VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_DESC",
  VENDOR_BY_VENDOR_ID__VAT_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__VAT_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__VAT_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__VAT_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_ASC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_ASC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_DESC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_DESC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_ASC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_DESC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_ASC = "VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_ASC",
  VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_DESC = "VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_DESC",
  VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_ASC = "VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_ASC",
  VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_DESC = "VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_DESC",
  VENDOR_BY_VENDOR_ID___CREATED_DATE_ASC = "VENDOR_BY_VENDOR_ID___CREATED_DATE_ASC",
  VENDOR_BY_VENDOR_ID___CREATED_DATE_DESC = "VENDOR_BY_VENDOR_ID___CREATED_DATE_DESC",
  VENDOR_BY_VENDOR_ID___FULL_NAME_ASC = "VENDOR_BY_VENDOR_ID___FULL_NAME_ASC",
  VENDOR_BY_VENDOR_ID___FULL_NAME_DESC = "VENDOR_BY_VENDOR_ID___FULL_NAME_DESC",
  VENDOR_BY_VENDOR_ID___IS_ACTIVE_ASC = "VENDOR_BY_VENDOR_ID___IS_ACTIVE_ASC",
  VENDOR_BY_VENDOR_ID___IS_ACTIVE_DESC = "VENDOR_BY_VENDOR_ID___IS_ACTIVE_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVED_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVED_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVED_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVED_DESC",
  VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  VENDOR_BY_VENDOR_ID___IS_DELETABLE_ASC = "VENDOR_BY_VENDOR_ID___IS_DELETABLE_ASC",
  VENDOR_BY_VENDOR_ID___IS_DELETABLE_DESC = "VENDOR_BY_VENDOR_ID___IS_DELETABLE_DESC",
  VENDOR_BY_VENDOR_ID___IS_DEPRECATED_ASC = "VENDOR_BY_VENDOR_ID___IS_DEPRECATED_ASC",
  VENDOR_BY_VENDOR_ID___IS_DEPRECATED_DESC = "VENDOR_BY_VENDOR_ID___IS_DEPRECATED_DESC",
  VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_MODIFIED_ASC = "VENDOR_BY_VENDOR_ID___IS_MODIFIED_ASC",
  VENDOR_BY_VENDOR_ID___IS_MODIFIED_DESC = "VENDOR_BY_VENDOR_ID___IS_MODIFIED_DESC",
  VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_ASC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_ASC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_DESC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_DESC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_ASC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_ASC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_DESC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_DESC",
  VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_UPDATABLE_ASC = "VENDOR_BY_VENDOR_ID___IS_UPDATABLE_ASC",
  VENDOR_BY_VENDOR_ID___IS_UPDATABLE_DESC = "VENDOR_BY_VENDOR_ID___IS_UPDATABLE_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_DESC",
  VENDOR_BY_VENDOR_ID___IS_VALIDATED_ASC = "VENDOR_BY_VENDOR_ID___IS_VALIDATED_ASC",
  VENDOR_BY_VENDOR_ID___IS_VALIDATED_DESC = "VENDOR_BY_VENDOR_ID___IS_VALIDATED_DESC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_ASC = "VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_ASC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_DESC = "VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_DESC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_ASC = "VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_ASC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_DESC = "VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_DESC",
  VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_ASC = "VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_ASC",
  VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_DESC = "VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_DESC",
  VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_ASC = "VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_ASC",
  VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_DESC = "VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_DESC",
  VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_ASC = "VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_ASC",
  VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_DESC = "VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_DESC",
  VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_ASC = "VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_ASC",
  VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_DESC = "VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_DESC",
  VENDOR_BY_VENDOR_ID___SORT_NAME_ASC = "VENDOR_BY_VENDOR_ID___SORT_NAME_ASC",
  VENDOR_BY_VENDOR_ID___SORT_NAME_DESC = "VENDOR_BY_VENDOR_ID___SORT_NAME_DESC",
  VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_ASC = "VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_ASC",
  VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_DESC = "VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_DESC",
  VENDOR_BY_VENDOR_ID___USER_GROUP_ID_ASC = "VENDOR_BY_VENDOR_ID___USER_GROUP_ID_ASC",
  VENDOR_BY_VENDOR_ID___USER_GROUP_ID_DESC = "VENDOR_BY_VENDOR_ID___USER_GROUP_ID_DESC",
  VENDOR_ID_ASC = "VENDOR_ID_ASC",
  VENDOR_ID_DESC = "VENDOR_ID_DESC",
  VENDOR_REFERENCE_ASC = "VENDOR_REFERENCE_ASC",
  VENDOR_REFERENCE_DESC = "VENDOR_REFERENCE_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _BASE_CURRENCY_AMOUNT_ASC = "_BASE_CURRENCY_AMOUNT_ASC",
  _BASE_CURRENCY_AMOUNT_DESC = "_BASE_CURRENCY_AMOUNT_DESC",
  _BASE_CURRENCY_ID_ASC = "_BASE_CURRENCY_ID_ASC",
  _BASE_CURRENCY_ID_DESC = "_BASE_CURRENCY_ID_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_BY_USER_NAME_ASC = "_CREATED_BY_USER_NAME_ASC",
  _CREATED_BY_USER_NAME_DESC = "_CREATED_BY_USER_NAME_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _ELAPSED_TRIGGER_DAYS_ASC = "_ELAPSED_TRIGGER_DAYS_ASC",
  _ELAPSED_TRIGGER_DAYS_DESC = "_ELAPSED_TRIGGER_DAYS_DESC",
  _IS_ACCOUNTING_ENTRY_ASC = "_IS_ACCOUNTING_ENTRY_ASC",
  _IS_ACCOUNTING_ENTRY_DESC = "_IS_ACCOUNTING_ENTRY_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_ENVIRONMENTALS_EXIST_ASC = "_IS_ENVIRONMENTALS_EXIST_ASC",
  _IS_ENVIRONMENTALS_EXIST_DESC = "_IS_ENVIRONMENTALS_EXIST_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_TRANSACTION_ENTRY_ASC = "_IS_TRANSACTION_ENTRY_ASC",
  _IS_TRANSACTION_ENTRY_DESC = "_IS_TRANSACTION_ENTRY_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _LINKED_INVOICE_ID_ASC = "_LINKED_INVOICE_ID_ASC",
  _LINKED_INVOICE_ID_DESC = "_LINKED_INVOICE_ID_DESC",
  _LOOKUP_NAME_ID_ASC = "_LOOKUP_NAME_ID_ASC",
  _LOOKUP_NAME_ID_DESC = "_LOOKUP_NAME_ID_DESC",
  _PAYMENT_DATE_ASC = "_PAYMENT_DATE_ASC",
  _PAYMENT_DATE_DESC = "_PAYMENT_DATE_DESC",
  _PAYMENT_REFERENCE_ASC = "_PAYMENT_REFERENCE_ASC",
  _PAYMENT_REFERENCE_DESC = "_PAYMENT_REFERENCE_DESC",
  _PAYMENT_STATUS_TYPE_ID_ASC = "_PAYMENT_STATUS_TYPE_ID_ASC",
  _PAYMENT_STATUS_TYPE_ID_DESC = "_PAYMENT_STATUS_TYPE_ID_DESC",
  _PAYMENT_TOTAL_ASC = "_PAYMENT_TOTAL_ASC",
  _PAYMENT_TOTAL_DESC = "_PAYMENT_TOTAL_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _SPOT_CURRENCY_AMOUNT_ASC = "_SPOT_CURRENCY_AMOUNT_ASC",
  _SPOT_CURRENCY_AMOUNT_DESC = "_SPOT_CURRENCY_AMOUNT_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _USER_GROUP_ID_ASC = "_USER_GROUP_ID_ASC",
  _USER_GROUP_ID_DESC = "_USER_GROUP_ID_DESC",
}

/**
 * Methods to use when ordering `LookupAccount`.
 */
export enum LookupAccountsOrderBy {
  ACCOUNT_ASC = "ACCOUNT_ASC",
  ACCOUNT_DESC = "ACCOUNT_DESC",
  ACCOUNT_TYPE_ID_ASC = "ACCOUNT_TYPE_ID_ASC",
  ACCOUNT_TYPE_ID_DESC = "ACCOUNT_TYPE_ID_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_DESC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_ASC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_ASC",
  CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_DESC = "CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_DESC",
  CHART_OF_ACCOUNT_ID_ASC = "CHART_OF_ACCOUNT_ID_ASC",
  CHART_OF_ACCOUNT_ID_DESC = "CHART_OF_ACCOUNT_ID_DESC",
  CORPORATE_LOOKUP_ACCOUNT_ID_ASC = "CORPORATE_LOOKUP_ACCOUNT_ID_ASC",
  CORPORATE_LOOKUP_ACCOUNT_ID_DESC = "CORPORATE_LOOKUP_ACCOUNT_ID_DESC",
  DEPARTMENT_BUDGETS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC = "DEPARTMENT_BUDGETS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  DEPARTMENT_BUDGETS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC = "DEPARTMENT_BUDGETS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_GROUP_ID_ASC = "DEPARTMENT_GROUP_ID_ASC",
  DEPARTMENT_GROUP_ID_DESC = "DEPARTMENT_GROUP_ID_DESC",
  DOCUMENT_FILE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC = "DOCUMENT_FILE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  DOCUMENT_FILE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC = "DOCUMENT_FILE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC = "INVOICE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  INVOICE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC = "INVOICE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  IS_APPROVED_ACCOUNT_ASC = "IS_APPROVED_ACCOUNT_ASC",
  IS_APPROVED_ACCOUNT_DESC = "IS_APPROVED_ACCOUNT_DESC",
  IS_OPTION_1_ALLOWED_ASC = "IS_OPTION_1_ALLOWED_ASC",
  IS_OPTION_1_ALLOWED_DESC = "IS_OPTION_1_ALLOWED_DESC",
  IS_OPTION_2_ALLOWED_ASC = "IS_OPTION_2_ALLOWED_ASC",
  IS_OPTION_2_ALLOWED_DESC = "IS_OPTION_2_ALLOWED_DESC",
  IS_OPTION_3_ALLOWED_ASC = "IS_OPTION_3_ALLOWED_ASC",
  IS_OPTION_3_ALLOWED_DESC = "IS_OPTION_3_ALLOWED_DESC",
  IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_ASC = "IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_ASC",
  IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_DESC = "IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_DESC",
  LOOKUP_ACCOUNT_ASC = "LOOKUP_ACCOUNT_ASC",
  LOOKUP_ACCOUNT_DESC = "LOOKUP_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_TYPE_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_TYPE_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_TYPE_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ACCOUNT_TYPE_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CHART_OF_ACCOUNT_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CHART_OF_ACCOUNT_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CHART_OF_ACCOUNT_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CHART_OF_ACCOUNT_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CORPORATE_LOOKUP_ACCOUNT_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CORPORATE_LOOKUP_ACCOUNT_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CORPORATE_LOOKUP_ACCOUNT_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__CORPORATE_LOOKUP_ACCOUNT_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__DEPARTMENT_GROUP_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__DEPARTMENT_GROUP_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__DEPARTMENT_GROUP_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__DEPARTMENT_GROUP_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_APPROVED_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_APPROVED_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_APPROVED_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_APPROVED_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_1_ALLOWED_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_1_ALLOWED_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_1_ALLOWED_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_1_ALLOWED_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_2_ALLOWED_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_2_ALLOWED_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_2_ALLOWED_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_2_ALLOWED_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_3_ALLOWED_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_3_ALLOWED_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_3_ALLOWED_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_OPTION_3_ALLOWED_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_NAME_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_NAME_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_NAME_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__LOOKUP_NAME_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__PROJECT_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__PROJECT_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__PROJECT_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__PROJECT_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SET_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SET_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SET_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SET_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_CORPORATE_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_CORPORATE_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_CORPORATE_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_CORPORATE_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_DEPT_GROUP_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_DEPT_GROUP_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_DEPT_GROUP_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_DEPT_GROUP_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_PROJECT_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_PROJECT_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_PROJECT_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_PROJECT_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_SET_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_SET_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_SET_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_SET_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_TRAVEL_ACCOUNT_TYPE_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_TRAVEL_ACCOUNT_TYPE_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_TRAVEL_ACCOUNT_TYPE_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SORT_TRAVEL_ACCOUNT_TYPE_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_NUMBER_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_NUMBER_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_NUMBER_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__SUB_ACCOUNT_NUMBER_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__TRAVEL_ACCOUNT_TYPE_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__TRAVEL_ACCOUNT_TYPE_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__TRAVEL_ACCOUNT_TYPE_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__TRAVEL_ACCOUNT_TYPE_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__USER_GROUP_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__USER_GROUP_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__USER_GROUP_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID__USER_GROUP_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ACCOUNT_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ACCOUNT_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ACCOUNT_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ACCOUNT_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___CREATED_DATE_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___CREATED_DATE_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___CREATED_DATE_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___CREATED_DATE_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_ACTIVE_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_ACTIVE_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_ACTIVE_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_ACTIVE_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DELETABLE_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DELETABLE_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DELETABLE_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DELETABLE_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DEPRECATED_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DEPRECATED_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DEPRECATED_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_DEPRECATED_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_HISTORY_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_HISTORY_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_HISTORY_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_HISTORY_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_MODIFIED_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_MODIFIED_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_MODIFIED_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_MODIFIED_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_VIEW_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_VIEW_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_VIEW_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_PROTECTED_VIEW_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_UPDATABLE_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_UPDATABLE_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_UPDATABLE_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___IS_UPDATABLE_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ROW_TIMESTAMP_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ROW_TIMESTAMP_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ROW_TIMESTAMP_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___ROW_TIMESTAMP_DESC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___SEARCH_DOCUMENT_ASC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___SEARCH_DOCUMENT_ASC",
  LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___SEARCH_DOCUMENT_DESC = "LOOKUP_CORPORATE_ACCOUNT_BY_CORPORATE_LOOKUP_ACCOUNT_ID___SEARCH_DOCUMENT_DESC",
  LOOKUP_NAME_ASC = "LOOKUP_NAME_ASC",
  LOOKUP_NAME_DESC = "LOOKUP_NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_ACCOUNT_ASC = "PROJECT_ACCOUNT_ASC",
  PROJECT_ACCOUNT_DESC = "PROJECT_ACCOUNT_DESC",
  PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC = "PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC = "PURCHASE_ORDER_INVOICE_SCHEDULE_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  PURCHASE_ORDER_ITEM_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC = "PURCHASE_ORDER_ITEM_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  PURCHASE_ORDER_ITEM_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC = "PURCHASE_ORDER_ITEM_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  SET_ACCOUNT_ASC = "SET_ACCOUNT_ASC",
  SET_ACCOUNT_DESC = "SET_ACCOUNT_DESC",
  SORT_CORPORATE_ACCOUNT_ASC = "SORT_CORPORATE_ACCOUNT_ASC",
  SORT_CORPORATE_ACCOUNT_DESC = "SORT_CORPORATE_ACCOUNT_DESC",
  SORT_DEPT_GROUP_ASC = "SORT_DEPT_GROUP_ASC",
  SORT_DEPT_GROUP_DESC = "SORT_DEPT_GROUP_DESC",
  SORT_PROJECT_ACCOUNT_ASC = "SORT_PROJECT_ACCOUNT_ASC",
  SORT_PROJECT_ACCOUNT_DESC = "SORT_PROJECT_ACCOUNT_DESC",
  SORT_SET_ACCOUNT_ASC = "SORT_SET_ACCOUNT_ASC",
  SORT_SET_ACCOUNT_DESC = "SORT_SET_ACCOUNT_DESC",
  SORT_TRAVEL_ACCOUNT_TYPE_ASC = "SORT_TRAVEL_ACCOUNT_TYPE_ASC",
  SORT_TRAVEL_ACCOUNT_TYPE_DESC = "SORT_TRAVEL_ACCOUNT_TYPE_DESC",
  SUB_ACCOUNT_ASC = "SUB_ACCOUNT_ASC",
  SUB_ACCOUNT_DESC = "SUB_ACCOUNT_DESC",
  SUB_ACCOUNT_NUMBER_ASC = "SUB_ACCOUNT_NUMBER_ASC",
  SUB_ACCOUNT_NUMBER_DESC = "SUB_ACCOUNT_NUMBER_DESC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_ASC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_ASC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_DESC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_DESC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_ASC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_ASC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_DESC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_DESC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_ASC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_ASC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_DESC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_DESC",
  TRAVEL_ACCOUNT_TYPE_ID_ASC = "TRAVEL_ACCOUNT_TYPE_ID_ASC",
  TRAVEL_ACCOUNT_TYPE_ID_DESC = "TRAVEL_ACCOUNT_TYPE_ID_DESC",
  TRIP_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC = "TRIP_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  TRIP_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC = "TRIP_DISTRIBUTIONS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  USER_DEFAULTS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC = "USER_DEFAULTS_BY_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  USER_DEFAULTS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC = "USER_DEFAULTS_BY_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  _ACCOUNT_ID_ASC = "_ACCOUNT_ID_ASC",
  _ACCOUNT_ID_DESC = "_ACCOUNT_ID_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
}

/**
 * Methods to use when ordering `LookupCorporateAccount`.
 */
export enum LookupCorporateAccountsOrderBy {
  ACCOUNT_ASC = "ACCOUNT_ASC",
  ACCOUNT_DESC = "ACCOUNT_DESC",
  ACCOUNT_TYPE_ID_ASC = "ACCOUNT_TYPE_ID_ASC",
  ACCOUNT_TYPE_ID_DESC = "ACCOUNT_TYPE_ID_DESC",
  CHART_OF_ACCOUNT_ID_ASC = "CHART_OF_ACCOUNT_ID_ASC",
  CHART_OF_ACCOUNT_ID_DESC = "CHART_OF_ACCOUNT_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ABBREVIATION_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__BUSINESS_AREA_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CHART_OF_ACCOUNT_TYPE_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COMPANY_CODE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__CORPORATE_CHART_OF_ACCOUNT_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__COST_CENTER_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__DESCRIPTION_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__GL_ACCOUNT_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_DRAFT_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_NEW_PROJECT_SELECTABLE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__IS_SUBSET_CHART_OF_ACCOUNTS_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__NAME_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRIMARY_CHART_OF_ACCOUNT_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID__PRODUCTION_STATUS_TYPE_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___COMPANY_OR_POOLING_ID_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___CREATED_DATE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_ACTIVE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVALS_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_APPROVED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_CORPORATE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DELETABLE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DEPRECATED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_DOCUMENTS_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MESSAGES_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_MODIFIED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_NOTES_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_TAGS_EXIST_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_UPDATABLE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALIDATED_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_VALID_COMPLETE_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_APPROVAL_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___IS_WAITING_VALIDATION_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___ROW_TIMESTAMP_DESC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_ASC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_ASC",
  CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_DESC = "CORPORATE_CHART_OF_ACCOUNT_BY_CHART_OF_ACCOUNT_ID___STATUS_TYPE_ID_DESC",
  CORPORATE_LOOKUP_ACCOUNT_ID_ASC = "CORPORATE_LOOKUP_ACCOUNT_ID_ASC",
  CORPORATE_LOOKUP_ACCOUNT_ID_DESC = "CORPORATE_LOOKUP_ACCOUNT_ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_GROUP_ID_ASC = "DEPARTMENT_GROUP_ID_ASC",
  DEPARTMENT_GROUP_ID_DESC = "DEPARTMENT_GROUP_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_APPROVED_ACCOUNT_ASC = "IS_APPROVED_ACCOUNT_ASC",
  IS_APPROVED_ACCOUNT_DESC = "IS_APPROVED_ACCOUNT_DESC",
  IS_OPTION_1_ALLOWED_ASC = "IS_OPTION_1_ALLOWED_ASC",
  IS_OPTION_1_ALLOWED_DESC = "IS_OPTION_1_ALLOWED_DESC",
  IS_OPTION_2_ALLOWED_ASC = "IS_OPTION_2_ALLOWED_ASC",
  IS_OPTION_2_ALLOWED_DESC = "IS_OPTION_2_ALLOWED_DESC",
  IS_OPTION_3_ALLOWED_ASC = "IS_OPTION_3_ALLOWED_ASC",
  IS_OPTION_3_ALLOWED_DESC = "IS_OPTION_3_ALLOWED_DESC",
  IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_ASC = "IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_ASC",
  IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_DESC = "IS_TRAVEL_ACCOUNT_FOR_ALL_DEPARTMENTS_DESC",
  LOOKUP_ACCOUNTS_BY_CORPORATE_LOOKUP_ACCOUNT_ID__COUNT_ASC = "LOOKUP_ACCOUNTS_BY_CORPORATE_LOOKUP_ACCOUNT_ID__COUNT_ASC",
  LOOKUP_ACCOUNTS_BY_CORPORATE_LOOKUP_ACCOUNT_ID__COUNT_DESC = "LOOKUP_ACCOUNTS_BY_CORPORATE_LOOKUP_ACCOUNT_ID__COUNT_DESC",
  LOOKUP_ACCOUNT_ASC = "LOOKUP_ACCOUNT_ASC",
  LOOKUP_ACCOUNT_DESC = "LOOKUP_ACCOUNT_DESC",
  LOOKUP_NAME_ASC = "LOOKUP_NAME_ASC",
  LOOKUP_NAME_DESC = "LOOKUP_NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_ACCOUNT_ASC = "PROJECT_ACCOUNT_ASC",
  PROJECT_ACCOUNT_DESC = "PROJECT_ACCOUNT_DESC",
  SET_ACCOUNT_ASC = "SET_ACCOUNT_ASC",
  SET_ACCOUNT_DESC = "SET_ACCOUNT_DESC",
  SORT_CORPORATE_ACCOUNT_ASC = "SORT_CORPORATE_ACCOUNT_ASC",
  SORT_CORPORATE_ACCOUNT_DESC = "SORT_CORPORATE_ACCOUNT_DESC",
  SORT_DEPT_GROUP_ASC = "SORT_DEPT_GROUP_ASC",
  SORT_DEPT_GROUP_DESC = "SORT_DEPT_GROUP_DESC",
  SORT_PROJECT_ACCOUNT_ASC = "SORT_PROJECT_ACCOUNT_ASC",
  SORT_PROJECT_ACCOUNT_DESC = "SORT_PROJECT_ACCOUNT_DESC",
  SORT_SET_ACCOUNT_ASC = "SORT_SET_ACCOUNT_ASC",
  SORT_SET_ACCOUNT_DESC = "SORT_SET_ACCOUNT_DESC",
  SORT_TRAVEL_ACCOUNT_TYPE_ASC = "SORT_TRAVEL_ACCOUNT_TYPE_ASC",
  SORT_TRAVEL_ACCOUNT_TYPE_DESC = "SORT_TRAVEL_ACCOUNT_TYPE_DESC",
  SUB_ACCOUNT_ASC = "SUB_ACCOUNT_ASC",
  SUB_ACCOUNT_DESC = "SUB_ACCOUNT_DESC",
  SUB_ACCOUNT_NUMBER_ASC = "SUB_ACCOUNT_NUMBER_ASC",
  SUB_ACCOUNT_NUMBER_DESC = "SUB_ACCOUNT_NUMBER_DESC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_ASC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_ASC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_DESC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ACCOUNT_TYPE_DESC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_ASC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_ASC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_DESC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__ID_DESC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_ASC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_ASC",
  TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_DESC = "TRAVEL_ACCOUNT_TYPE_BY_TRAVEL_ACCOUNT_TYPE_ID__TRIP_SECTION_ID_DESC",
  TRAVEL_ACCOUNT_TYPE_ID_ASC = "TRAVEL_ACCOUNT_TYPE_ID_ASC",
  TRAVEL_ACCOUNT_TYPE_ID_DESC = "TRAVEL_ACCOUNT_TYPE_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  _ACCOUNT_ID_ASC = "_ACCOUNT_ID_ASC",
  _ACCOUNT_ID_DESC = "_ACCOUNT_ID_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
}

/**
 * Methods to use when ordering `LookupName`.
 */
export enum LookupNamesOrderBy {
  ADDRESS_1_ASC = "ADDRESS_1_ASC",
  ADDRESS_1_DESC = "ADDRESS_1_DESC",
  ADDRESS_2_ASC = "ADDRESS_2_ASC",
  ADDRESS_2_DESC = "ADDRESS_2_DESC",
  APPROVAL_HISTORIES_BY_LOOKUP_NAME_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_LOOKUP_NAME_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_LOOKUP_NAME_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_LOOKUP_NAME_ID__COUNT_DESC",
  BANK_ACCOUNT_NAME_ASC = "BANK_ACCOUNT_NAME_ASC",
  BANK_ACCOUNT_NAME_DESC = "BANK_ACCOUNT_NAME_DESC",
  BANK_ACCOUNT_NUMBER_ASC = "BANK_ACCOUNT_NUMBER_ASC",
  BANK_ACCOUNT_NUMBER_DESC = "BANK_ACCOUNT_NUMBER_DESC",
  BANK_IBAN_CODE_ASC = "BANK_IBAN_CODE_ASC",
  BANK_IBAN_CODE_DESC = "BANK_IBAN_CODE_DESC",
  BANK_NAME_ASC = "BANK_NAME_ASC",
  BANK_NAME_DESC = "BANK_NAME_DESC",
  BANK_ROUTING_NUMBER_ASC = "BANK_ROUTING_NUMBER_ASC",
  BANK_ROUTING_NUMBER_DESC = "BANK_ROUTING_NUMBER_DESC",
  BANK_SORT_CODE_ASC = "BANK_SORT_CODE_ASC",
  BANK_SORT_CODE_DESC = "BANK_SORT_CODE_DESC",
  BANK_SWIFT_CODE_ASC = "BANK_SWIFT_CODE_ASC",
  BANK_SWIFT_CODE_DESC = "BANK_SWIFT_CODE_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  CITY_ASC = "CITY_ASC",
  CITY_DESC = "CITY_DESC",
  CONTACT_ASC = "CONTACT_ASC",
  CONTACT_DESC = "CONTACT_DESC",
  COUNTRY_ASC = "COUNTRY_ASC",
  COUNTRY_DESC = "COUNTRY_DESC",
  COUNTRY_ID_ASC = "COUNTRY_ID_ASC",
  COUNTRY_ID_DESC = "COUNTRY_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  EMAIL_ACCOUNT_ASC = "EMAIL_ACCOUNT_ASC",
  EMAIL_ACCOUNT_DESC = "EMAIL_ACCOUNT_DESC",
  ENTITY_DOCUMENTS_BY_LOOKUP_NAME_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_LOOKUP_NAME_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_LOOKUP_NAME_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_LOOKUP_NAME_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICES_BY_LOOKUP_NAME_ID__COUNT_ASC = "INVOICES_BY_LOOKUP_NAME_ID__COUNT_ASC",
  INVOICES_BY_LOOKUP_NAME_ID__COUNT_DESC = "INVOICES_BY_LOOKUP_NAME_ID__COUNT_DESC",
  IS_APPROVED_VENDOR_ASC = "IS_APPROVED_VENDOR_ASC",
  IS_APPROVED_VENDOR_DESC = "IS_APPROVED_VENDOR_DESC",
  IS_TRANSACTION_SIGNING_LOOKUP_ASC = "IS_TRANSACTION_SIGNING_LOOKUP_ASC",
  IS_TRANSACTION_SIGNING_LOOKUP_DESC = "IS_TRANSACTION_SIGNING_LOOKUP_DESC",
  LOOKUP_CODE_ASC = "LOOKUP_CODE_ASC",
  LOOKUP_CODE_DESC = "LOOKUP_CODE_DESC",
  LOOKUP_DESCRIPTION_ASC = "LOOKUP_DESCRIPTION_ASC",
  LOOKUP_DESCRIPTION_DESC = "LOOKUP_DESCRIPTION_DESC",
  LOOKUP_GROUP_SEQUENCE_ASC = "LOOKUP_GROUP_SEQUENCE_ASC",
  LOOKUP_GROUP_SEQUENCE_DESC = "LOOKUP_GROUP_SEQUENCE_DESC",
  LOOKUP_NAME_2_ASC = "LOOKUP_NAME_2_ASC",
  LOOKUP_NAME_2_DESC = "LOOKUP_NAME_2_DESC",
  LOOKUP_NAME_ASC = "LOOKUP_NAME_ASC",
  LOOKUP_NAME_DESC = "LOOKUP_NAME_DESC",
  NATURAL = "NATURAL",
  PAYMENTS_BY_LOOKUP_NAME_ID__COUNT_ASC = "PAYMENTS_BY_LOOKUP_NAME_ID__COUNT_ASC",
  PAYMENTS_BY_LOOKUP_NAME_ID__COUNT_DESC = "PAYMENTS_BY_LOOKUP_NAME_ID__COUNT_DESC",
  PHONE_ASC = "PHONE_ASC",
  PHONE_DESC = "PHONE_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  STATE_ASC = "STATE_ASC",
  STATE_DESC = "STATE_DESC",
  STATE_REGION_ID_ASC = "STATE_REGION_ID_ASC",
  STATE_REGION_ID_DESC = "STATE_REGION_ID_DESC",
  STUDIO_CODE_ASC = "STUDIO_CODE_ASC",
  STUDIO_CODE_DESC = "STUDIO_CODE_DESC",
  USER_APPROVALS_BY_LOOKUP_NAME_ID__COUNT_ASC = "USER_APPROVALS_BY_LOOKUP_NAME_ID__COUNT_ASC",
  USER_APPROVALS_BY_LOOKUP_NAME_ID__COUNT_DESC = "USER_APPROVALS_BY_LOOKUP_NAME_ID__COUNT_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  ZIP_CODE_ASC = "ZIP_CODE_ASC",
  ZIP_CODE_DESC = "ZIP_CODE_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
}

/**
 * Methods to use when ordering `PayCycle`.
 */
export enum PayCyclesOrderBy {
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  CONTROL_TOTAL_AMOUNT_ASC = "CONTROL_TOTAL_AMOUNT_ASC",
  CONTROL_TOTAL_AMOUNT_DESC = "CONTROL_TOTAL_AMOUNT_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  DEFAULT_PAYMENT_DATE_ASC = "DEFAULT_PAYMENT_DATE_ASC",
  DEFAULT_PAYMENT_DATE_DESC = "DEFAULT_PAYMENT_DATE_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_CYCLE_OPEN_ENDED_ASC = "IS_CYCLE_OPEN_ENDED_ASC",
  IS_CYCLE_OPEN_ENDED_DESC = "IS_CYCLE_OPEN_ENDED_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  JOURNAL_DATE_ASC = "JOURNAL_DATE_ASC",
  JOURNAL_DATE_DESC = "JOURNAL_DATE_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PAYMENTS_BY_PAY_CYCLE_ID__COUNT_ASC = "PAYMENTS_BY_PAY_CYCLE_ID__COUNT_ASC",
  PAYMENTS_BY_PAY_CYCLE_ID__COUNT_DESC = "PAYMENTS_BY_PAY_CYCLE_ID__COUNT_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_DESC",
  PAYMENT_ACCOUNT_ID_ASC = "PAYMENT_ACCOUNT_ID_ASC",
  PAYMENT_ACCOUNT_ID_DESC = "PAYMENT_ACCOUNT_ID_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC",
  PAY_CYCLE_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "PAY_CYCLE_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  PAY_CYCLE_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "PAY_CYCLE_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  TRANSACTION_NUMBER_REFERENCE_ASC = "TRANSACTION_NUMBER_REFERENCE_ASC",
  TRANSACTION_NUMBER_REFERENCE_DESC = "TRANSACTION_NUMBER_REFERENCE_DESC",
  TRANSACTION_TYPE_ID_ASC = "TRANSACTION_TYPE_ID_ASC",
  TRANSACTION_TYPE_ID_DESC = "TRANSACTION_TYPE_ID_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACCOUNTING_ENTRY_ASC = "_IS_ACCOUNTING_ENTRY_ASC",
  _IS_ACCOUNTING_ENTRY_DESC = "_IS_ACCOUNTING_ENTRY_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PAYMENT_SELECTABLE_ASC = "_IS_PAYMENT_SELECTABLE_ASC",
  _IS_PAYMENT_SELECTABLE_DESC = "_IS_PAYMENT_SELECTABLE_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_TRANSACTION_ENTRY_ASC = "_IS_TRANSACTION_ENTRY_ASC",
  _IS_TRANSACTION_ENTRY_DESC = "_IS_TRANSACTION_ENTRY_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _PAYMENT_COUNT_ASC = "_PAYMENT_COUNT_ASC",
  _PAYMENT_COUNT_DESC = "_PAYMENT_COUNT_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _TOTAL_PAYMENTS_ASC = "_TOTAL_PAYMENTS_ASC",
  _TOTAL_PAYMENTS_DESC = "_TOTAL_PAYMENTS_DESC",
  _USER_GROUP_ID_ASC = "_USER_GROUP_ID_ASC",
  _USER_GROUP_ID_DESC = "_USER_GROUP_ID_DESC",
}

/**
 * Methods to use when ordering `Payment`.
 */
export enum PaymentsOrderBy {
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC",
  COMPANY_BY_COMPANY_ID__ID_ASC = "COMPANY_BY_COMPANY_ID__ID_ASC",
  COMPANY_BY_COMPANY_ID__ID_DESC = "COMPANY_BY_COMPANY_ID__ID_DESC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC",
  COMPANY_BY_COMPANY_ID__NAME_ASC = "COMPANY_BY_COMPANY_ID__NAME_ASC",
  COMPANY_BY_COMPANY_ID__NAME_DESC = "COMPANY_BY_COMPANY_ID__NAME_DESC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_ASC = "COMPANY_BY_COMPANY_ID__TENANT_ID_ASC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_DESC = "COMPANY_BY_COMPANY_ID__TENANT_ID_DESC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_PAYMENTS_BY_PAYMENT_ID__COUNT_ASC = "INVOICE_PAYMENTS_BY_PAYMENT_ID__COUNT_ASC",
  INVOICE_PAYMENTS_BY_PAYMENT_ID__COUNT_DESC = "INVOICE_PAYMENTS_BY_PAYMENT_ID__COUNT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC",
  MEMO_ASC = "MEMO_ASC",
  MEMO_DESC = "MEMO_DESC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PAID_AMOUNT_ASC = "PAID_AMOUNT_ASC",
  PAID_AMOUNT_DESC = "PAID_AMOUNT_DESC",
  PAID_DATE_ASC = "PAID_DATE_ASC",
  PAID_DATE_DESC = "PAID_DATE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BANK_ACCOUNT_NUMBER_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__BUSINESS_UNIT_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__CURRENCY_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__DESCRIPTION_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__NAME_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID__PAYMENT_TYPE_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___COMPANY_ID_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___CREATED_DATE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_ACTIVE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DELETABLE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_DEPRECATED_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_MODIFIED_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___IS_UPDATABLE_DESC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_ASC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_ASC",
  PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_DESC = "PAYMENT_ACCOUNT_BY_PAYMENT_ACCOUNT_ID___ROW_TIMESTAMP_DESC",
  PAYMENT_ACCOUNT_ID_ASC = "PAYMENT_ACCOUNT_ID_ASC",
  PAYMENT_ACCOUNT_ID_DESC = "PAYMENT_ACCOUNT_ID_DESC",
  PAYMENT_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "PAYMENT_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  PAYMENT_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "PAYMENT_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  PAYMENT_REFERENCE_ASC = "PAYMENT_REFERENCE_ASC",
  PAYMENT_REFERENCE_DESC = "PAYMENT_REFERENCE_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__DESCRIPTION_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__ID_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__LAYOUT_TYPE_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__SORT_ORDER_DESC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_ASC",
  PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC = "PAYMENT_TRANSACTION_TYPE_BY_TRANSACTION_TYPE_ID__TRANSACTION_TYPE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__BUSINESS_UNIT_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__BUSINESS_UNIT_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__BUSINESS_UNIT_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__BUSINESS_UNIT_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__CONTROL_TOTAL_AMOUNT_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__CONTROL_TOTAL_AMOUNT_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__CONTROL_TOTAL_AMOUNT_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__CONTROL_TOTAL_AMOUNT_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__CORPORATE_PERIOD_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__CORPORATE_PERIOD_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__CORPORATE_PERIOD_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__CORPORATE_PERIOD_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__CURRENCY_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__CURRENCY_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__CURRENCY_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__CURRENCY_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__DEFAULT_PAYMENT_DATE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__DEFAULT_PAYMENT_DATE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__DEFAULT_PAYMENT_DATE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__DEFAULT_PAYMENT_DATE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__DEPARTMENT_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__DEPARTMENT_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__DEPARTMENT_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__DEPARTMENT_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__DESCRIPTION_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__DESCRIPTION_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__DESCRIPTION_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__DESCRIPTION_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__IS_CYCLE_OPEN_ENDED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__IS_CYCLE_OPEN_ENDED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__IS_CYCLE_OPEN_ENDED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__IS_CYCLE_OPEN_ENDED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__IS_DRAFT_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__IS_DRAFT_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__IS_DRAFT_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__IS_DRAFT_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__JOURNAL_DATE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__JOURNAL_DATE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__JOURNAL_DATE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__JOURNAL_DATE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__PAYMENT_ACCOUNT_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__PAYMENT_ACCOUNT_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__PAYMENT_ACCOUNT_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__PAYMENT_ACCOUNT_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__PROJECT_PERIOD_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__PROJECT_PERIOD_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__PROJECT_PERIOD_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__PROJECT_PERIOD_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__ROW_SECURITY_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__ROW_SECURITY_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__ROW_SECURITY_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__ROW_SECURITY_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_TYPE_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_TYPE_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_TYPE_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID__TRANSACTION_TYPE_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_DATE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_DATE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_DATE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_DATE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___COMPANY_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___COMPANY_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___COMPANY_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___COMPANY_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_BY_USERID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_BY_USERID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_BY_USERID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_BY_USERID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_DATE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_DATE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_DATE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___CREATED_DATE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACTIVE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACTIVE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACTIVE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_ACTIVE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_REVOCABLE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_REVOCABLE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_REVOCABLE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVAL_REVOCABLE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_APPROVED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DELETABLE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DELETABLE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DELETABLE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DELETABLE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DEPRECATED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DEPRECATED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DEPRECATED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DEPRECATED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DOCUMENTS_EXIST_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DOCUMENTS_EXIST_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DOCUMENTS_EXIST_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_DOCUMENTS_EXIST_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_HISTORY_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_HISTORY_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_HISTORY_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_HISTORY_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MESSAGES_EXIST_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MESSAGES_EXIST_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MESSAGES_EXIST_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MESSAGES_EXIST_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MODIFIED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MODIFIED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MODIFIED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_MODIFIED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_NOTES_EXIST_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_NOTES_EXIST_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_NOTES_EXIST_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_NOTES_EXIST_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PAYMENT_SELECTABLE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PAYMENT_SELECTABLE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PAYMENT_SELECTABLE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PAYMENT_SELECTABLE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_VIEW_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_VIEW_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_VIEW_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_PROTECTED_VIEW_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TAGS_EXIST_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TAGS_EXIST_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TAGS_EXIST_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TAGS_EXIST_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_CANCELLED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_CANCELLED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_CANCELLED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_CANCELLED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_ENTRY_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_ENTRY_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_ENTRY_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_TRANSACTION_ENTRY_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_UPDATABLE_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_UPDATABLE_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_UPDATABLE_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_UPDATABLE_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVER_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVER_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVER_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_APPROVER_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_OBSERVER_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_OBSERVER_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_OBSERVER_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_OBSERVER_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REQUESTER_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REQUESTER_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REQUESTER_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REQUESTER_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REVOKER_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REVOKER_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REVOKER_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_USER_REVOKER_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_VALIDATED_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_VALIDATED_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_VALIDATED_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_VALIDATED_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_APPROVAL_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_APPROVAL_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_APPROVAL_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_APPROVAL_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_VALIDATION_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_VALIDATION_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_VALIDATION_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___IS_WAITING_VALIDATION_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___PAYMENT_COUNT_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___PAYMENT_COUNT_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___PAYMENT_COUNT_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___PAYMENT_COUNT_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___PENDING_APPROVERS_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___PENDING_APPROVERS_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___PENDING_APPROVERS_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___PENDING_APPROVERS_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ROW_TIMESTAMP_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ROW_TIMESTAMP_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___ROW_TIMESTAMP_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___ROW_TIMESTAMP_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___SEARCH_DOCUMENT_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___SEARCH_DOCUMENT_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___SEARCH_DOCUMENT_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___SEARCH_DOCUMENT_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___STATUS_TYPE_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___STATUS_TYPE_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___STATUS_TYPE_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___STATUS_TYPE_ID_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___TOTAL_PAYMENTS_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___TOTAL_PAYMENTS_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___TOTAL_PAYMENTS_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___TOTAL_PAYMENTS_DESC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___USER_GROUP_ID_ASC = "PAY_CYCLE_BY_PAY_CYCLE_ID___USER_GROUP_ID_ASC",
  PAY_CYCLE_BY_PAY_CYCLE_ID___USER_GROUP_ID_DESC = "PAY_CYCLE_BY_PAY_CYCLE_ID___USER_GROUP_ID_DESC",
  PAY_CYCLE_ID_ASC = "PAY_CYCLE_ID_ASC",
  PAY_CYCLE_ID_DESC = "PAY_CYCLE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  TRANSACTION_TYPE_ID_ASC = "TRANSACTION_TYPE_ID_ASC",
  TRANSACTION_TYPE_ID_DESC = "TRANSACTION_TYPE_ID_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_ASC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_ASC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_DESC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NAME_DESC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__BANK_ACCOUNT_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_ASC = "VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_ASC",
  VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_DESC = "VENDOR_BY_VENDOR_ID__BANK_IBAN_CODE_DESC",
  VENDOR_BY_VENDOR_ID__BANK_NAME_ASC = "VENDOR_BY_VENDOR_ID__BANK_NAME_ASC",
  VENDOR_BY_VENDOR_ID__BANK_NAME_DESC = "VENDOR_BY_VENDOR_ID__BANK_NAME_DESC",
  VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__BANK_ROUTING_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_ASC = "VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_ASC",
  VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_DESC = "VENDOR_BY_VENDOR_ID__BANK_SORT_CODE_DESC",
  VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_ASC = "VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_ASC",
  VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_DESC = "VENDOR_BY_VENDOR_ID__BANK_SWIFT_CODE_DESC",
  VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_ASC = "VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_ASC",
  VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_DESC = "VENDOR_BY_VENDOR_ID__COMPANY_OR_LAST_NAME_DESC",
  VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_ASC = "VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_ASC",
  VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_DESC = "VENDOR_BY_VENDOR_ID__CUSTOMER_ACCOUNT_DESC",
  VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_ASC = "VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_ASC",
  VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_DESC = "VENDOR_BY_VENDOR_ID__DATE_OF_BIRTH_DESC",
  VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_ASC = "VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_DESC = "VENDOR_BY_VENDOR_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_ASC = "VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_ASC",
  VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_DESC = "VENDOR_BY_VENDOR_ID__FEDERAL_TAX_REFERENCE_DESC",
  VENDOR_BY_VENDOR_ID__FIRST_NAME_ASC = "VENDOR_BY_VENDOR_ID__FIRST_NAME_ASC",
  VENDOR_BY_VENDOR_ID__FIRST_NAME_DESC = "VENDOR_BY_VENDOR_ID__FIRST_NAME_DESC",
  VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__FREQUENT_FLYER_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__ID_ASC = "VENDOR_BY_VENDOR_ID__ID_ASC",
  VENDOR_BY_VENDOR_ID__ID_DESC = "VENDOR_BY_VENDOR_ID__ID_DESC",
  VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_CORPORATION_ASC = "VENDOR_BY_VENDOR_ID__IS_CORPORATION_ASC",
  VENDOR_BY_VENDOR_ID__IS_CORPORATION_DESC = "VENDOR_BY_VENDOR_ID__IS_CORPORATION_DESC",
  VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_ASC = "VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_ASC",
  VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_DESC = "VENDOR_BY_VENDOR_ID__IS_CREW_MEMBER_DESC",
  VENDOR_BY_VENDOR_ID__IS_DRAFT_ASC = "VENDOR_BY_VENDOR_ID__IS_DRAFT_ASC",
  VENDOR_BY_VENDOR_ID__IS_DRAFT_DESC = "VENDOR_BY_VENDOR_ID__IS_DRAFT_DESC",
  VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "VENDOR_BY_VENDOR_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_ASC = "VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_ASC",
  VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_DESC = "VENDOR_BY_VENDOR_ID__IS_INDIVIDUAL_DESC",
  VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_ASC = "VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_ASC",
  VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_DESC = "VENDOR_BY_VENDOR_ID__IS_PAYROLL_VENDOR_DESC",
  VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC = "VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC",
  VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC = "VENDOR_BY_VENDOR_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC",
  VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "VENDOR_BY_VENDOR_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  VENDOR_BY_VENDOR_ID__IS_TRAVELER_ASC = "VENDOR_BY_VENDOR_ID__IS_TRAVELER_ASC",
  VENDOR_BY_VENDOR_ID__IS_TRAVELER_DESC = "VENDOR_BY_VENDOR_ID__IS_TRAVELER_DESC",
  VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_ASC = "VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_ASC",
  VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_DESC = "VENDOR_BY_VENDOR_ID__IS_VENDOR_PLACEHOLDER_DESC",
  VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "VENDOR_BY_VENDOR_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_ASC = "VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_ASC",
  VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_DESC = "VENDOR_BY_VENDOR_ID__PAYMENT_TERM_TYPE_ID_DESC",
  VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "VENDOR_BY_VENDOR_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_ASC = "VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_ASC",
  VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_DESC = "VENDOR_BY_VENDOR_ID__ROW_SECURITY_ID_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_COMPANION_TICKETS_DESC",
  VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_ASC = "VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_ASC",
  VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_DESC = "VENDOR_BY_VENDOR_ID__TRAVELER_DEPARTMENT_ID_DESC",
  VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_ASC = "VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_ASC",
  VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_DESC = "VENDOR_BY_VENDOR_ID__TRAVEL_POLICY_ID_DESC",
  VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_ASC = "VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_ASC",
  VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_DESC = "VENDOR_BY_VENDOR_ID__USER_OCCUPATION_TITLE_ID_DESC",
  VENDOR_BY_VENDOR_ID__VAT_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__VAT_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__VAT_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__VAT_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_ASC = "VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_ASC",
  VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_DESC = "VENDOR_BY_VENDOR_ID__VENDOR_NUMBER_DESC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_ASC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_ASC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_DESC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_RATE_DESC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_ASC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_DESC = "VENDOR_BY_VENDOR_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_ASC = "VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_ASC",
  VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_DESC = "VENDOR_BY_VENDOR_ID___COMPANY_OR_POOLING_ID_DESC",
  VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_ASC = "VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_ASC",
  VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_DESC = "VENDOR_BY_VENDOR_ID___CREATED_BY_USERID_DESC",
  VENDOR_BY_VENDOR_ID___CREATED_DATE_ASC = "VENDOR_BY_VENDOR_ID___CREATED_DATE_ASC",
  VENDOR_BY_VENDOR_ID___CREATED_DATE_DESC = "VENDOR_BY_VENDOR_ID___CREATED_DATE_DESC",
  VENDOR_BY_VENDOR_ID___FULL_NAME_ASC = "VENDOR_BY_VENDOR_ID___FULL_NAME_ASC",
  VENDOR_BY_VENDOR_ID___FULL_NAME_DESC = "VENDOR_BY_VENDOR_ID___FULL_NAME_DESC",
  VENDOR_BY_VENDOR_ID___IS_ACTIVE_ASC = "VENDOR_BY_VENDOR_ID___IS_ACTIVE_ASC",
  VENDOR_BY_VENDOR_ID___IS_ACTIVE_DESC = "VENDOR_BY_VENDOR_ID___IS_ACTIVE_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVAL_REVOCABLE_DESC",
  VENDOR_BY_VENDOR_ID___IS_APPROVED_ASC = "VENDOR_BY_VENDOR_ID___IS_APPROVED_ASC",
  VENDOR_BY_VENDOR_ID___IS_APPROVED_DESC = "VENDOR_BY_VENDOR_ID___IS_APPROVED_DESC",
  VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "VENDOR_BY_VENDOR_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  VENDOR_BY_VENDOR_ID___IS_DELETABLE_ASC = "VENDOR_BY_VENDOR_ID___IS_DELETABLE_ASC",
  VENDOR_BY_VENDOR_ID___IS_DELETABLE_DESC = "VENDOR_BY_VENDOR_ID___IS_DELETABLE_DESC",
  VENDOR_BY_VENDOR_ID___IS_DEPRECATED_ASC = "VENDOR_BY_VENDOR_ID___IS_DEPRECATED_ASC",
  VENDOR_BY_VENDOR_ID___IS_DEPRECATED_DESC = "VENDOR_BY_VENDOR_ID___IS_DEPRECATED_DESC",
  VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_DOCUMENTS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_MESSAGES_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_MODIFIED_ASC = "VENDOR_BY_VENDOR_ID___IS_MODIFIED_ASC",
  VENDOR_BY_VENDOR_ID___IS_MODIFIED_DESC = "VENDOR_BY_VENDOR_ID___IS_MODIFIED_DESC",
  VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_NOTES_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_ASC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_ASC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_DESC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_DESC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_ASC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_ASC",
  VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_DESC = "VENDOR_BY_VENDOR_ID___IS_PROTECTED_VIEW_DESC",
  VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_RATINGS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "VENDOR_BY_VENDOR_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_ASC = "VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_ASC",
  VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_DESC = "VENDOR_BY_VENDOR_ID___IS_TAGS_EXIST_DESC",
  VENDOR_BY_VENDOR_ID___IS_UPDATABLE_ASC = "VENDOR_BY_VENDOR_ID___IS_UPDATABLE_ASC",
  VENDOR_BY_VENDOR_ID___IS_UPDATABLE_DESC = "VENDOR_BY_VENDOR_ID___IS_UPDATABLE_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_APPROVER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_OBSERVER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_REQUESTER_DESC",
  VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_ASC = "VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_ASC",
  VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_DESC = "VENDOR_BY_VENDOR_ID___IS_USER_REVOKER_DESC",
  VENDOR_BY_VENDOR_ID___IS_VALIDATED_ASC = "VENDOR_BY_VENDOR_ID___IS_VALIDATED_ASC",
  VENDOR_BY_VENDOR_ID___IS_VALIDATED_DESC = "VENDOR_BY_VENDOR_ID___IS_VALIDATED_DESC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_ASC = "VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_ASC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_DESC = "VENDOR_BY_VENDOR_ID___IS_WAITING_APPROVAL_DESC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_ASC = "VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_ASC",
  VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_DESC = "VENDOR_BY_VENDOR_ID___IS_WAITING_VALIDATION_DESC",
  VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_ASC = "VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_ASC",
  VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_DESC = "VENDOR_BY_VENDOR_ID___PENDING_APPROVERS_DESC",
  VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_ASC = "VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_ASC",
  VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_DESC = "VENDOR_BY_VENDOR_ID___RATING_VENDOR_ID_DESC",
  VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "VENDOR_BY_VENDOR_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_ASC = "VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_ASC",
  VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_DESC = "VENDOR_BY_VENDOR_ID___ROW_TIMESTAMP_DESC",
  VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_ASC = "VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_ASC",
  VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_DESC = "VENDOR_BY_VENDOR_ID___SEARCH_DOCUMENT_DESC",
  VENDOR_BY_VENDOR_ID___SORT_NAME_ASC = "VENDOR_BY_VENDOR_ID___SORT_NAME_ASC",
  VENDOR_BY_VENDOR_ID___SORT_NAME_DESC = "VENDOR_BY_VENDOR_ID___SORT_NAME_DESC",
  VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_ASC = "VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_ASC",
  VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_DESC = "VENDOR_BY_VENDOR_ID___STATUS_TYPE_ID_DESC",
  VENDOR_BY_VENDOR_ID___USER_GROUP_ID_ASC = "VENDOR_BY_VENDOR_ID___USER_GROUP_ID_ASC",
  VENDOR_BY_VENDOR_ID___USER_GROUP_ID_DESC = "VENDOR_BY_VENDOR_ID___USER_GROUP_ID_DESC",
  VENDOR_ID_ASC = "VENDOR_ID_ASC",
  VENDOR_ID_DESC = "VENDOR_ID_DESC",
  VENDOR_REFERENCE_ASC = "VENDOR_REFERENCE_ASC",
  VENDOR_REFERENCE_DESC = "VENDOR_REFERENCE_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _BASE_CURRENCY_AMOUNT_ASC = "_BASE_CURRENCY_AMOUNT_ASC",
  _BASE_CURRENCY_AMOUNT_DESC = "_BASE_CURRENCY_AMOUNT_DESC",
  _BASE_CURRENCY_ID_ASC = "_BASE_CURRENCY_ID_ASC",
  _BASE_CURRENCY_ID_DESC = "_BASE_CURRENCY_ID_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACCOUNTING_ENTRY_ASC = "_IS_ACCOUNTING_ENTRY_ASC",
  _IS_ACCOUNTING_ENTRY_DESC = "_IS_ACCOUNTING_ENTRY_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_ENVIRONMENTALS_EXIST_ASC = "_IS_ENVIRONMENTALS_EXIST_ASC",
  _IS_ENVIRONMENTALS_EXIST_DESC = "_IS_ENVIRONMENTALS_EXIST_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_INVOICE_BALANCE_REQUIRED_ASC = "_IS_INVOICE_BALANCE_REQUIRED_ASC",
  _IS_INVOICE_BALANCE_REQUIRED_DESC = "_IS_INVOICE_BALANCE_REQUIRED_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_TRANSACTION_ENTRY_ASC = "_IS_TRANSACTION_ENTRY_ASC",
  _IS_TRANSACTION_ENTRY_DESC = "_IS_TRANSACTION_ENTRY_DESC",
  _IS_TRANSACTION_PAYMENT_ASC = "_IS_TRANSACTION_PAYMENT_ASC",
  _IS_TRANSACTION_PAYMENT_DESC = "_IS_TRANSACTION_PAYMENT_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _LOOKUP_NAME_ID_ASC = "_LOOKUP_NAME_ID_ASC",
  _LOOKUP_NAME_ID_DESC = "_LOOKUP_NAME_ID_DESC",
  _PAY_CYCLE_DESCRIPTION_ASC = "_PAY_CYCLE_DESCRIPTION_ASC",
  _PAY_CYCLE_DESCRIPTION_DESC = "_PAY_CYCLE_DESCRIPTION_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _SPOT_CURRENCY_AMOUNT_ASC = "_SPOT_CURRENCY_AMOUNT_ASC",
  _SPOT_CURRENCY_AMOUNT_DESC = "_SPOT_CURRENCY_AMOUNT_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `PurchaseOrder`.
 */
export enum PurchaseOrdersOrderBy {
  ACCOUNTING_TRANSFER_DATE_ASC = "ACCOUNTING_TRANSFER_DATE_ASC",
  ACCOUNTING_TRANSFER_DATE_DESC = "ACCOUNTING_TRANSFER_DATE_DESC",
  ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC = "ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC",
  ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC = "ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC",
  ACCOUNTING_TRANSFER_USER_ID_ASC = "ACCOUNTING_TRANSFER_USER_ID_ASC",
  ACCOUNTING_TRANSFER_USER_ID_DESC = "ACCOUNTING_TRANSFER_USER_ID_DESC",
  AMENDED_DATE_ASC = "AMENDED_DATE_ASC",
  AMENDED_DATE_DESC = "AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVED_PURCHASE_ORDER_ID_ASC = "APPROVED_PURCHASE_ORDER_ID_ASC",
  APPROVED_PURCHASE_ORDER_ID_DESC = "APPROVED_PURCHASE_ORDER_ID_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMMENT_ASC = "COMMENT_ASC",
  COMMENT_DESC = "COMMENT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC",
  COMPANY_BY_COMPANY_ID__ID_ASC = "COMPANY_BY_COMPANY_ID__ID_ASC",
  COMPANY_BY_COMPANY_ID__ID_DESC = "COMPANY_BY_COMPANY_ID__ID_DESC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC",
  COMPANY_BY_COMPANY_ID__NAME_ASC = "COMPANY_BY_COMPANY_ID__NAME_ASC",
  COMPANY_BY_COMPANY_ID__NAME_DESC = "COMPANY_BY_COMPANY_ID__NAME_DESC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_ASC = "COMPANY_BY_COMPANY_ID__TENANT_ID_ASC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_DESC = "COMPANY_BY_COMPANY_ID__TENANT_ID_DESC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  CONTRACTUAL_PURCHASE_ORDER_ID_ASC = "CONTRACTUAL_PURCHASE_ORDER_ID_ASC",
  CONTRACTUAL_PURCHASE_ORDER_ID_DESC = "CONTRACTUAL_PURCHASE_ORDER_ID_DESC",
  CONTROL_TOTAL_AMOUNT_ASC = "CONTROL_TOTAL_AMOUNT_ASC",
  CONTROL_TOTAL_AMOUNT_DESC = "CONTROL_TOTAL_AMOUNT_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  DELIVERY_ADDRESS_ALIAS_ASC = "DELIVERY_ADDRESS_ALIAS_ASC",
  DELIVERY_ADDRESS_ALIAS_DESC = "DELIVERY_ADDRESS_ALIAS_DESC",
  DELIVERY_ADDRESS_ID_ASC = "DELIVERY_ADDRESS_ID_ASC",
  DELIVERY_ADDRESS_ID_DESC = "DELIVERY_ADDRESS_ID_DESC",
  DELIVERY_ADDRESS_LINE_1_ASC = "DELIVERY_ADDRESS_LINE_1_ASC",
  DELIVERY_ADDRESS_LINE_1_DESC = "DELIVERY_ADDRESS_LINE_1_DESC",
  DELIVERY_ADDRESS_LINE_2_ASC = "DELIVERY_ADDRESS_LINE_2_ASC",
  DELIVERY_ADDRESS_LINE_2_DESC = "DELIVERY_ADDRESS_LINE_2_DESC",
  DELIVERY_ALTERNATE_CONTACT_ASC = "DELIVERY_ALTERNATE_CONTACT_ASC",
  DELIVERY_ALTERNATE_CONTACT_DESC = "DELIVERY_ALTERNATE_CONTACT_DESC",
  DELIVERY_ATTENTION_ASC = "DELIVERY_ATTENTION_ASC",
  DELIVERY_ATTENTION_DESC = "DELIVERY_ATTENTION_DESC",
  DELIVERY_CITY_NAME_ASC = "DELIVERY_CITY_NAME_ASC",
  DELIVERY_CITY_NAME_DESC = "DELIVERY_CITY_NAME_DESC",
  DELIVERY_COUNTRY_ID_ASC = "DELIVERY_COUNTRY_ID_ASC",
  DELIVERY_COUNTRY_ID_DESC = "DELIVERY_COUNTRY_ID_DESC",
  DELIVERY_EMAIL_ADDRESS_ASC = "DELIVERY_EMAIL_ADDRESS_ASC",
  DELIVERY_EMAIL_ADDRESS_DESC = "DELIVERY_EMAIL_ADDRESS_DESC",
  DELIVERY_MAIN_CONTACT_ASC = "DELIVERY_MAIN_CONTACT_ASC",
  DELIVERY_MAIN_CONTACT_DESC = "DELIVERY_MAIN_CONTACT_DESC",
  DELIVERY_MOBILE_NUMBER_ASC = "DELIVERY_MOBILE_NUMBER_ASC",
  DELIVERY_MOBILE_NUMBER_DESC = "DELIVERY_MOBILE_NUMBER_DESC",
  DELIVERY_NOTES_ASC = "DELIVERY_NOTES_ASC",
  DELIVERY_NOTES_DESC = "DELIVERY_NOTES_DESC",
  DELIVERY_POSTAL_CODE_ASC = "DELIVERY_POSTAL_CODE_ASC",
  DELIVERY_POSTAL_CODE_DESC = "DELIVERY_POSTAL_CODE_DESC",
  DELIVERY_STATE_REGION_ID_ASC = "DELIVERY_STATE_REGION_ID_ASC",
  DELIVERY_STATE_REGION_ID_DESC = "DELIVERY_STATE_REGION_ID_DESC",
  DELIVERY_TELEPHONE_NUMBER_ASC = "DELIVERY_TELEPHONE_NUMBER_ASC",
  DELIVERY_TELEPHONE_NUMBER_DESC = "DELIVERY_TELEPHONE_NUMBER_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DOCUMENT_FILE_ID_ASC = "DOCUMENT_FILE_ID_ASC",
  DOCUMENT_FILE_ID_DESC = "DOCUMENT_FILE_ID_DESC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENT_ID_ASC = "ENTITY_DOCUMENT_ID_ASC",
  ENTITY_DOCUMENT_ID_DESC = "ENTITY_DOCUMENT_ID_DESC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DELIVERY_ADDRESS_PROTECTED_ASC = "IS_DELIVERY_ADDRESS_PROTECTED_ASC",
  IS_DELIVERY_ADDRESS_PROTECTED_DESC = "IS_DELIVERY_ADDRESS_PROTECTED_DESC",
  IS_DELIVERY_TRACKED_ASC = "IS_DELIVERY_TRACKED_ASC",
  IS_DELIVERY_TRACKED_DESC = "IS_DELIVERY_TRACKED_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC = "IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC",
  IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC = "IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC",
  IS_SCHEDULED_PICKUP_ASC = "IS_SCHEDULED_PICKUP_ASC",
  IS_SCHEDULED_PICKUP_DESC = "IS_SCHEDULED_PICKUP_DESC",
  IS_SUPPLIER_PO_APPROVED_ASC = "IS_SUPPLIER_PO_APPROVED_ASC",
  IS_SUPPLIER_PO_APPROVED_DESC = "IS_SUPPLIER_PO_APPROVED_DESC",
  IS_TEMPLATE_ASC = "IS_TEMPLATE_ASC",
  IS_TEMPLATE_DESC = "IS_TEMPLATE_DESC",
  JOURNAL_DATE_ASC = "JOURNAL_DATE_ASC",
  JOURNAL_DATE_DESC = "JOURNAL_DATE_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  ORIGINAL_PURCHASE_ORDER_ID_ASC = "ORIGINAL_PURCHASE_ORDER_ID_ASC",
  ORIGINAL_PURCHASE_ORDER_ID_DESC = "ORIGINAL_PURCHASE_ORDER_ID_DESC",
  PAYMENT_ACCOUNT_ID_ASC = "PAYMENT_ACCOUNT_ID_ASC",
  PAYMENT_ACCOUNT_ID_DESC = "PAYMENT_ACCOUNT_ID_DESC",
  PAYMENT_ACCOUNT_REFERENCE_ASC = "PAYMENT_ACCOUNT_REFERENCE_ASC",
  PAYMENT_ACCOUNT_REFERENCE_DESC = "PAYMENT_ACCOUNT_REFERENCE_DESC",
  PAYMENT_DATE_ASC = "PAYMENT_DATE_ASC",
  PAYMENT_DATE_DESC = "PAYMENT_DATE_DESC",
  PAYMENT_NUMBER_ASC = "PAYMENT_NUMBER_ASC",
  PAYMENT_NUMBER_DESC = "PAYMENT_NUMBER_DESC",
  PAYMENT_TYPE_ID_ASC = "PAYMENT_TYPE_ID_ASC",
  PAYMENT_TYPE_ID_DESC = "PAYMENT_TYPE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  PURCHASE_ORDER_DATE_ASC = "PURCHASE_ORDER_DATE_ASC",
  PURCHASE_ORDER_DATE_DESC = "PURCHASE_ORDER_DATE_DESC",
  PURCHASE_ORDER_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "PURCHASE_ORDER_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  PURCHASE_ORDER_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "PURCHASE_ORDER_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  PURCHASE_ORDER_INVOICE_SCHEDULES_BY_PURCHASE_ORDER_ID__COUNT_ASC = "PURCHASE_ORDER_INVOICE_SCHEDULES_BY_PURCHASE_ORDER_ID__COUNT_ASC",
  PURCHASE_ORDER_INVOICE_SCHEDULES_BY_PURCHASE_ORDER_ID__COUNT_DESC = "PURCHASE_ORDER_INVOICE_SCHEDULES_BY_PURCHASE_ORDER_ID__COUNT_DESC",
  PURCHASE_ORDER_ITEMS_BY_PURCHASE_ORDER_ID__COUNT_ASC = "PURCHASE_ORDER_ITEMS_BY_PURCHASE_ORDER_ID__COUNT_ASC",
  PURCHASE_ORDER_ITEMS_BY_PURCHASE_ORDER_ID__COUNT_DESC = "PURCHASE_ORDER_ITEMS_BY_PURCHASE_ORDER_ID__COUNT_DESC",
  PURCHASE_ORDER_NUMBER_ASC = "PURCHASE_ORDER_NUMBER_ASC",
  PURCHASE_ORDER_NUMBER_DESC = "PURCHASE_ORDER_NUMBER_DESC",
  PURCHASE_ORDER_REVISION_HISTORIES_BY_PO_GROUP_ID__COUNT_ASC = "PURCHASE_ORDER_REVISION_HISTORIES_BY_PO_GROUP_ID__COUNT_ASC",
  PURCHASE_ORDER_REVISION_HISTORIES_BY_PO_GROUP_ID__COUNT_DESC = "PURCHASE_ORDER_REVISION_HISTORIES_BY_PO_GROUP_ID__COUNT_DESC",
  PURCHASE_ORDER_TIME_ASC = "PURCHASE_ORDER_TIME_ASC",
  PURCHASE_ORDER_TIME_DESC = "PURCHASE_ORDER_TIME_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__APPROVAL_TYPE_ID_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__APPROVAL_TYPE_ID_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__APPROVAL_TYPE_ID_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__APPROVAL_TYPE_ID_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ATTACHED_DOCUMENT_GROUP_ID_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ATTACHED_DOCUMENT_GROUP_ID_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ATTACHED_DOCUMENT_GROUP_ID_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ATTACHED_DOCUMENT_GROUP_ID_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__DESCRIPTION_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__DESCRIPTION_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__DESCRIPTION_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__DESCRIPTION_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ID_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ID_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ID_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__ID_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_ACCOUNTING_INTEGRATION_REQUIRED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_ACCOUNTING_INTEGRATION_REQUIRED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_ACCOUNTING_INTEGRATION_REQUIRED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_ACCOUNTING_INTEGRATION_REQUIRED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_APPROVAL_REQUIRED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_APPROVAL_REQUIRED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_APPROVAL_REQUIRED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_APPROVAL_REQUIRED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_PURCHASE_ORDER_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_PURCHASE_ORDER_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_PURCHASE_ORDER_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_PURCHASE_ORDER_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_RELEASE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_RELEASE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_RELEASE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_BLANKET_RELEASE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CONTRACTUAL_PURCHASE_ORDER_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CONTRACTUAL_PURCHASE_ORDER_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CONTRACTUAL_PURCHASE_ORDER_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CONTRACTUAL_PURCHASE_ORDER_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CREATE_ENTRY_ALLOWED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CREATE_ENTRY_ALLOWED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CREATE_ENTRY_ALLOWED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_CREATE_ENTRY_ALLOWED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_DEFAULT_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_DEFAULT_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_DEFAULT_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_DEFAULT_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_EXTERNAL_PURCHASE_ORDER_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_EXTERNAL_PURCHASE_ORDER_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_EXTERNAL_PURCHASE_ORDER_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_EXTERNAL_PURCHASE_ORDER_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_INVOICE_MATCHING_REQUIRED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_INVOICE_MATCHING_REQUIRED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_INVOICE_MATCHING_REQUIRED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_INVOICE_MATCHING_REQUIRED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PLANNED_PURCHASE_ORDER_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PLANNED_PURCHASE_ORDER_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PLANNED_PURCHASE_ORDER_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PLANNED_PURCHASE_ORDER_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PROTECTED_FROM_UPDATE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PROTECTED_FROM_UPDATE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PROTECTED_FROM_UPDATE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PROTECTED_FROM_UPDATE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PUNCHOUT_PROCESSING_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PUNCHOUT_PROCESSING_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PUNCHOUT_PROCESSING_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PUNCHOUT_PROCESSING_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PURCHASED_GOODS_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PURCHASED_GOODS_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PURCHASED_GOODS_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_PURCHASED_GOODS_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_RECEIVING_GOODS_REQUIRED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_RECEIVING_GOODS_REQUIRED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_RECEIVING_GOODS_REQUIRED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_RECEIVING_GOODS_REQUIRED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_REQUEST_FOR_QUOTE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_REQUEST_FOR_QUOTE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_REQUEST_FOR_QUOTE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_REQUEST_FOR_QUOTE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SCHEDULED_RELEASE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SCHEDULED_RELEASE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SCHEDULED_RELEASE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SCHEDULED_RELEASE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_STANDARD_PURCHASE_ORDER_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_STANDARD_PURCHASE_ORDER_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_STANDARD_PURCHASE_ORDER_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_STANDARD_PURCHASE_ORDER_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SUPPLIER_SEND_ALLOWED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SUPPLIER_SEND_ALLOWED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SUPPLIER_SEND_ALLOWED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_SUPPLIER_SEND_ALLOWED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_TRAVEL_AUTHORIZATION_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_TRAVEL_AUTHORIZATION_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_TRAVEL_AUTHORIZATION_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_TRAVEL_AUTHORIZATION_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_USER_TEMPLATE_ALLOWED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_USER_TEMPLATE_ALLOWED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_USER_TEMPLATE_ALLOWED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__IS_USER_TEMPLATE_ALLOWED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__LAYOUT_TYPE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__LAYOUT_TYPE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__LAYOUT_TYPE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__LAYOUT_TYPE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__PURCHASE_ORDER_TYPE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__PURCHASE_ORDER_TYPE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__PURCHASE_ORDER_TYPE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__PURCHASE_ORDER_TYPE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__REQUIRED_DOCUMENT_GROUP_ID_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__REQUIRED_DOCUMENT_GROUP_ID_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__REQUIRED_DOCUMENT_GROUP_ID_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__REQUIRED_DOCUMENT_GROUP_ID_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__SORT_ORDER_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__SORT_ORDER_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__SORT_ORDER_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__SORT_ORDER_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__STATEMENT_LAYOUT_TYPE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__STATEMENT_LAYOUT_TYPE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__STATEMENT_LAYOUT_TYPE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID__STATEMENT_LAYOUT_TYPE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_ACTIVE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_ACTIVE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_ACTIVE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_ACTIVE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DELETABLE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DELETABLE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DELETABLE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DELETABLE_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DEPRECATED_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DEPRECATED_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DEPRECATED_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_DEPRECATED_DESC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_UPDATABLE_ASC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_UPDATABLE_ASC",
  PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_UPDATABLE_DESC = "PURCHASE_ORDER_TYPE_BY_PURCHASE_ORDER_TYPE_ID___IS_UPDATABLE_DESC",
  PURCHASE_ORDER_TYPE_ID_ASC = "PURCHASE_ORDER_TYPE_ID_ASC",
  PURCHASE_ORDER_TYPE_ID_DESC = "PURCHASE_ORDER_TYPE_ID_DESC",
  REASON_ASC = "REASON_ASC",
  REASON_DESC = "REASON_DESC",
  REQUIRED_DATE_ASC = "REQUIRED_DATE_ASC",
  REQUIRED_DATE_DESC = "REQUIRED_DATE_DESC",
  REQUIRED_TIME_ASC = "REQUIRED_TIME_ASC",
  REQUIRED_TIME_DESC = "REQUIRED_TIME_DESC",
  RETIRED_DATE_ASC = "RETIRED_DATE_ASC",
  RETIRED_DATE_DESC = "RETIRED_DATE_DESC",
  REVISION_NUMBER_ASC = "REVISION_NUMBER_ASC",
  REVISION_NUMBER_DESC = "REVISION_NUMBER_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SCHEDULED_DELIVERY_DATE_ASC = "SCHEDULED_DELIVERY_DATE_ASC",
  SCHEDULED_DELIVERY_DATE_DESC = "SCHEDULED_DELIVERY_DATE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  SORT_SUPPLIER_NAME_ASC = "SORT_SUPPLIER_NAME_ASC",
  SORT_SUPPLIER_NAME_DESC = "SORT_SUPPLIER_NAME_DESC",
  STAMPED_ENTITY_DOCUMENT_ID_ASC = "STAMPED_ENTITY_DOCUMENT_ID_ASC",
  STAMPED_ENTITY_DOCUMENT_ID_DESC = "STAMPED_ENTITY_DOCUMENT_ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_ASC = "SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_ASC",
  SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_DESC = "SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_DESC",
  SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_ASC = "SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_DESC = "SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_ASC = "SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_ASC",
  SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_DESC = "SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_DESC",
  SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_ASC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_DESC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_ASC = "SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_DESC = "SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_ASC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_DESC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_ASC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_DESC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_DESC",
  SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_ASC = "SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_ASC",
  SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_DESC = "SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_DESC",
  SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_ASC = "SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_ASC",
  SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_DESC = "SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_DESC",
  SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_ASC = "SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_ASC",
  SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_DESC = "SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_DESC",
  SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_DESC",
  SUPPLIER_ID_ASC = "SUPPLIER_ID_ASC",
  SUPPLIER_ID_DESC = "SUPPLIER_ID_DESC",
  SUPPLIER_REFERENCE_NAME_ASC = "SUPPLIER_REFERENCE_NAME_ASC",
  SUPPLIER_REFERENCE_NAME_DESC = "SUPPLIER_REFERENCE_NAME_DESC",
  TEMPLATE_PURCHASE_ORDER_ID_ASC = "TEMPLATE_PURCHASE_ORDER_ID_ASC",
  TEMPLATE_PURCHASE_ORDER_ID_DESC = "TEMPLATE_PURCHASE_ORDER_ID_DESC",
  TRAVEL_AUTHORIZATION_ID_ASC = "TRAVEL_AUTHORIZATION_ID_ASC",
  TRAVEL_AUTHORIZATION_ID_DESC = "TRAVEL_AUTHORIZATION_ID_DESC",
  TRAVEL_SECTION_ID_ASC = "TRAVEL_SECTION_ID_ASC",
  TRAVEL_SECTION_ID_DESC = "TRAVEL_SECTION_ID_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__DIGITAL_SIGNATURE_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__DIGITAL_SIGNATURE_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__DIGITAL_SIGNATURE_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__DIGITAL_SIGNATURE_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__EMAIL_ACCOUNT_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__EMAIL_ACCOUNT_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__EMAIL_ACCOUNT_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__EMAIL_ACCOUNT_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__ID_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__ID_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__ID_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__ID_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_DRAFT_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_DRAFT_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_DRAFT_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_DRAFT_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_RESTRICTED_USER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_RESTRICTED_USER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_RESTRICTED_USER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_RESTRICTED_USER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_SIGNIN_ALLOWED_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_SIGNIN_ALLOWED_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__LANGUAGE_ID_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__LANGUAGE_ID_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__LANGUAGE_ID_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__LANGUAGE_ID_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__NAME_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__NAME_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__NAME_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__NAME_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__TENANT_ID_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__TENANT_ID_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__TENANT_ID_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__TENANT_ID_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_BY_USERID_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_BY_USERID_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_BY_USERID_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_BY_USERID_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_DATE_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_DATE_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_DATE_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___CREATED_DATE_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_TENANT_USER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_TENANT_USER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_TENANT_USER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_ACTIVE_TENANT_USER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVED_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVED_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVED_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_APPROVED_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_USER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_USER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_USER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_AUTHORIZED_USER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DELETABLE_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DELETABLE_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DELETABLE_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DELETABLE_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DEPRECATED_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DEPRECATED_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DEPRECATED_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_DEPRECATED_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_EMAIL_EXISTS_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_EMAIL_EXISTS_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_EMAIL_EXISTS_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_EMAIL_EXISTS_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_MODIFIED_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_MODIFIED_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_MODIFIED_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_MODIFIED_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_TENANT_USER_EXISTS_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_TENANT_USER_EXISTS_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_TENANT_USER_EXISTS_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_TENANT_USER_EXISTS_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_UPDATABLE_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_UPDATABLE_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_UPDATABLE_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_UPDATABLE_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_APPROVER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_OBSERVER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_OBSERVER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_OBSERVER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_OBSERVER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REQUESTER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REQUESTER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REQUESTER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REQUESTER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REVOKER_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REVOKER_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REVOKER_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_USER_REVOKER_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_VALIDATED_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_VALIDATED_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_VALIDATED_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_VALIDATED_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_APPROVAL_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_APPROVAL_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_APPROVAL_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_APPROVAL_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_VALIDATION_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_VALIDATION_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_VALIDATION_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___IS_WAITING_VALIDATION_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___ROW_TIMESTAMP_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___ROW_TIMESTAMP_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___ROW_TIMESTAMP_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___ROW_TIMESTAMP_DESC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___STATUS_TYPE_ID_ASC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___STATUS_TYPE_ID_ASC",
  USER_BY_ACCOUNTING_EXPORT_USER_ID___STATUS_TYPE_ID_DESC = "USER_BY_ACCOUNTING_EXPORT_USER_ID___STATUS_TYPE_ID_DESC",
  USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_ASC = "USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_ASC",
  USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_DESC = "USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_DESC",
  USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_ASC = "USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_ASC",
  USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_DESC = "USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_DESC",
  USER_BY_REVISED_USER_ID__ID_ASC = "USER_BY_REVISED_USER_ID__ID_ASC",
  USER_BY_REVISED_USER_ID__ID_DESC = "USER_BY_REVISED_USER_ID__ID_DESC",
  USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC = "USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC",
  USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC = "USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC",
  USER_BY_REVISED_USER_ID__IS_DRAFT_ASC = "USER_BY_REVISED_USER_ID__IS_DRAFT_ASC",
  USER_BY_REVISED_USER_ID__IS_DRAFT_DESC = "USER_BY_REVISED_USER_ID__IS_DRAFT_DESC",
  USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_ASC = "USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_ASC",
  USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_DESC = "USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_DESC",
  USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_ASC = "USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_DESC = "USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_BY_REVISED_USER_ID__LANGUAGE_ID_ASC = "USER_BY_REVISED_USER_ID__LANGUAGE_ID_ASC",
  USER_BY_REVISED_USER_ID__LANGUAGE_ID_DESC = "USER_BY_REVISED_USER_ID__LANGUAGE_ID_DESC",
  USER_BY_REVISED_USER_ID__NAME_ASC = "USER_BY_REVISED_USER_ID__NAME_ASC",
  USER_BY_REVISED_USER_ID__NAME_DESC = "USER_BY_REVISED_USER_ID__NAME_DESC",
  USER_BY_REVISED_USER_ID__TENANT_ID_ASC = "USER_BY_REVISED_USER_ID__TENANT_ID_ASC",
  USER_BY_REVISED_USER_ID__TENANT_ID_DESC = "USER_BY_REVISED_USER_ID__TENANT_ID_DESC",
  USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  USER_BY_REVISED_USER_ID___CREATED_BY_USERID_ASC = "USER_BY_REVISED_USER_ID___CREATED_BY_USERID_ASC",
  USER_BY_REVISED_USER_ID___CREATED_BY_USERID_DESC = "USER_BY_REVISED_USER_ID___CREATED_BY_USERID_DESC",
  USER_BY_REVISED_USER_ID___CREATED_DATE_ASC = "USER_BY_REVISED_USER_ID___CREATED_DATE_ASC",
  USER_BY_REVISED_USER_ID___CREATED_DATE_DESC = "USER_BY_REVISED_USER_ID___CREATED_DATE_DESC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_ASC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_ASC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_DESC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_DESC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_ASC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_ASC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_DESC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_DESC",
  USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_BY_REVISED_USER_ID___IS_APPROVED_ASC = "USER_BY_REVISED_USER_ID___IS_APPROVED_ASC",
  USER_BY_REVISED_USER_ID___IS_APPROVED_DESC = "USER_BY_REVISED_USER_ID___IS_APPROVED_DESC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_ASC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_ASC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_DESC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_DESC",
  USER_BY_REVISED_USER_ID___IS_DELETABLE_ASC = "USER_BY_REVISED_USER_ID___IS_DELETABLE_ASC",
  USER_BY_REVISED_USER_ID___IS_DELETABLE_DESC = "USER_BY_REVISED_USER_ID___IS_DELETABLE_DESC",
  USER_BY_REVISED_USER_ID___IS_DEPRECATED_ASC = "USER_BY_REVISED_USER_ID___IS_DEPRECATED_ASC",
  USER_BY_REVISED_USER_ID___IS_DEPRECATED_DESC = "USER_BY_REVISED_USER_ID___IS_DEPRECATED_DESC",
  USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_ASC = "USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_ASC",
  USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_DESC = "USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_DESC",
  USER_BY_REVISED_USER_ID___IS_MODIFIED_ASC = "USER_BY_REVISED_USER_ID___IS_MODIFIED_ASC",
  USER_BY_REVISED_USER_ID___IS_MODIFIED_DESC = "USER_BY_REVISED_USER_ID___IS_MODIFIED_DESC",
  USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_ASC = "USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_ASC",
  USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_DESC = "USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_DESC",
  USER_BY_REVISED_USER_ID___IS_UPDATABLE_ASC = "USER_BY_REVISED_USER_ID___IS_UPDATABLE_ASC",
  USER_BY_REVISED_USER_ID___IS_UPDATABLE_DESC = "USER_BY_REVISED_USER_ID___IS_UPDATABLE_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_REVOKER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_REVOKER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_REVOKER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_REVOKER_DESC",
  USER_BY_REVISED_USER_ID___IS_VALIDATED_ASC = "USER_BY_REVISED_USER_ID___IS_VALIDATED_ASC",
  USER_BY_REVISED_USER_ID___IS_VALIDATED_DESC = "USER_BY_REVISED_USER_ID___IS_VALIDATED_DESC",
  USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_ASC = "USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_ASC",
  USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_DESC = "USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_DESC",
  USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_ASC = "USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_ASC",
  USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_DESC = "USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_DESC",
  USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_ASC = "USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_ASC",
  USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_DESC = "USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_DESC",
  USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_ASC = "USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_ASC",
  USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_DESC = "USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  VOIDED_DATE_ASC = "VOIDED_DATE_ASC",
  VOIDED_DATE_DESC = "VOIDED_DATE_DESC",
  _ACCOUNTING_EXPORT_CYCLE_ID_ASC = "_ACCOUNTING_EXPORT_CYCLE_ID_ASC",
  _ACCOUNTING_EXPORT_CYCLE_ID_DESC = "_ACCOUNTING_EXPORT_CYCLE_ID_DESC",
  _ACCOUNTING_EXPORT_DATE_ASC = "_ACCOUNTING_EXPORT_DATE_ASC",
  _ACCOUNTING_EXPORT_DATE_DESC = "_ACCOUNTING_EXPORT_DATE_DESC",
  _ACCOUNTING_EXPORT_USER_ID_ASC = "_ACCOUNTING_EXPORT_USER_ID_ASC",
  _ACCOUNTING_EXPORT_USER_ID_DESC = "_ACCOUNTING_EXPORT_USER_ID_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _BASE_CURRENCY_AMOUNT_ASC = "_BASE_CURRENCY_AMOUNT_ASC",
  _BASE_CURRENCY_AMOUNT_DESC = "_BASE_CURRENCY_AMOUNT_DESC",
  _BASE_CURRENCY_ID_ASC = "_BASE_CURRENCY_ID_ASC",
  _BASE_CURRENCY_ID_DESC = "_BASE_CURRENCY_ID_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_BY_USER_NAME_ASC = "_CREATED_BY_USER_NAME_ASC",
  _CREATED_BY_USER_NAME_DESC = "_CREATED_BY_USER_NAME_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACCOUNTING_ENTRY_ASC = "_IS_ACCOUNTING_ENTRY_ASC",
  _IS_ACCOUNTING_ENTRY_DESC = "_IS_ACCOUNTING_ENTRY_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DELIVERY_COMPLETE_ASC = "_IS_DELIVERY_COMPLETE_ASC",
  _IS_DELIVERY_COMPLETE_DESC = "_IS_DELIVERY_COMPLETE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "_IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  _IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "_IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_ENVIRONMENTALS_EXIST_ASC = "_IS_ENVIRONMENTALS_EXIST_ASC",
  _IS_ENVIRONMENTALS_EXIST_DESC = "_IS_ENVIRONMENTALS_EXIST_DESC",
  _IS_FLAGGED_HISTORY_ASC = "_IS_FLAGGED_HISTORY_ASC",
  _IS_FLAGGED_HISTORY_DESC = "_IS_FLAGGED_HISTORY_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_INVOICE_SCHEDULE_UPDATABLE_ASC = "_IS_INVOICE_SCHEDULE_UPDATABLE_ASC",
  _IS_INVOICE_SCHEDULE_UPDATABLE_DESC = "_IS_INVOICE_SCHEDULE_UPDATABLE_DESC",
  _IS_INVOICE_SCHEDULE_VIEWABLE_ASC = "_IS_INVOICE_SCHEDULE_VIEWABLE_ASC",
  _IS_INVOICE_SCHEDULE_VIEWABLE_DESC = "_IS_INVOICE_SCHEDULE_VIEWABLE_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PARTIAL_RETURN_ASC = "_IS_PARTIAL_RETURN_ASC",
  _IS_PARTIAL_RETURN_DESC = "_IS_PARTIAL_RETURN_DESC",
  _IS_PROMOTE_ADDRESS_ALLOWED_ASC = "_IS_PROMOTE_ADDRESS_ALLOWED_ASC",
  _IS_PROMOTE_ADDRESS_ALLOWED_DESC = "_IS_PROMOTE_ADDRESS_ALLOWED_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_RETIRE_ALLOWED_ASC = "_IS_RETIRE_ALLOWED_ASC",
  _IS_RETIRE_ALLOWED_DESC = "_IS_RETIRE_ALLOWED_DESC",
  _IS_RETURNED_ASC = "_IS_RETURNED_ASC",
  _IS_RETURNED_DESC = "_IS_RETURNED_DESC",
  _IS_REVISABLE_ASC = "_IS_REVISABLE_ASC",
  _IS_REVISABLE_DESC = "_IS_REVISABLE_DESC",
  _IS_REVISED_ASC = "_IS_REVISED_ASC",
  _IS_REVISED_DESC = "_IS_REVISED_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_STAMP_ALLOWED_ASC = "_IS_STAMP_ALLOWED_ASC",
  _IS_STAMP_ALLOWED_DESC = "_IS_STAMP_ALLOWED_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_TRANSACTION_ENTRY_ASC = "_IS_TRANSACTION_ENTRY_ASC",
  _IS_TRANSACTION_ENTRY_DESC = "_IS_TRANSACTION_ENTRY_DESC",
  _IS_UNSTAMP_ALLOWED_ASC = "_IS_UNSTAMP_ALLOWED_ASC",
  _IS_UNSTAMP_ALLOWED_DESC = "_IS_UNSTAMP_ALLOWED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _OVERRIDING_LANGUAGE_ID_ASC = "_OVERRIDING_LANGUAGE_ID_ASC",
  _OVERRIDING_LANGUAGE_ID_DESC = "_OVERRIDING_LANGUAGE_ID_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _PURCHASE_ORDER_DELIVERY_ID_ASC = "_PURCHASE_ORDER_DELIVERY_ID_ASC",
  _PURCHASE_ORDER_DELIVERY_ID_DESC = "_PURCHASE_ORDER_DELIVERY_ID_DESC",
  _PURCHASE_ORDER_TYPE_ASC = "_PURCHASE_ORDER_TYPE_ASC",
  _PURCHASE_ORDER_TYPE_DESC = "_PURCHASE_ORDER_TYPE_DESC",
  _REFERENCE_ITEM_TYPES_ASC = "_REFERENCE_ITEM_TYPES_ASC",
  _REFERENCE_ITEM_TYPES_DESC = "_REFERENCE_ITEM_TYPES_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _REVISED_DATE_ASC = "_REVISED_DATE_ASC",
  _REVISED_DATE_DESC = "_REVISED_DATE_DESC",
  _REVISED_USER_ID_ASC = "_REVISED_USER_ID_ASC",
  _REVISED_USER_ID_DESC = "_REVISED_USER_ID_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _SPOT_CURRENCY_AMOUNT_ASC = "_SPOT_CURRENCY_AMOUNT_ASC",
  _SPOT_CURRENCY_AMOUNT_DESC = "_SPOT_CURRENCY_AMOUNT_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _USER_GROUP_ID_ASC = "_USER_GROUP_ID_ASC",
  _USER_GROUP_ID_DESC = "_USER_GROUP_ID_DESC",
}

export enum ReportStatusType {
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export enum ReportTypes {
  DEFAULT_PO_STATEMENT = "DEFAULT_PO_STATEMENT",
  DEFAULT_PO_TRACKER = "DEFAULT_PO_TRACKER",
  DEFAULT_TA_MOVEMENT = "DEFAULT_TA_MOVEMENT",
  DEFAULT_TA_PLANNER = "DEFAULT_TA_PLANNER",
  DEFAULT_TA_STATEMENT = "DEFAULT_TA_STATEMENT",
  DISNEY_TA_STATEMENT = "DISNEY_TA_STATEMENT",
  TELERIK_PO = "TELERIK_PO",
  TELERIK_PO_TRACKER = "TELERIK_PO_TRACKER",
}

export enum RoleTypes {
  SYSTEM_ADMINISTRATOR = "SYSTEM_ADMINISTRATOR",
  SYSTEM_VENDOR_APPROVAL_CUSTODIAN = "SYSTEM_VENDOR_APPROVAL_CUSTODIAN",
  SYSTEM_VENDOR_BANKING_CUSTODIAN = "SYSTEM_VENDOR_BANKING_CUSTODIAN",
  SYSTEM_VENDOR_CONTRACT_CUSTODIAN = "SYSTEM_VENDOR_CONTRACT_CUSTODIAN",
  SYSTEM_VENDOR_INVOICE_CUSTODIAN = "SYSTEM_VENDOR_INVOICE_CUSTODIAN",
  SYSTEM_VENDOR_PURCHASING_CUSTODIAN = "SYSTEM_VENDOR_PURCHASING_CUSTODIAN",
  SYSTEM_VENDOR_QUOTE_CUSTODIAN = "SYSTEM_VENDOR_QUOTE_CUSTODIAN",
  SYSTEM_VENDOR_RELATED_PARTY_CUSTODIAN = "SYSTEM_VENDOR_RELATED_PARTY_CUSTODIAN",
  SYSTEM_VENDOR_SHIPPING_CUSTODIAN = "SYSTEM_VENDOR_SHIPPING_CUSTODIAN",
  USER = "USER",
}

export enum StandardCalculationType {
  PO_3_DAY_WEEK_7_DAY = "PO_3_DAY_WEEK_7_DAY",
  PO_ANNUAL_360_DAYS = "PO_ANNUAL_360_DAYS",
  PO_BI_WEEK_5_DAY = "PO_BI_WEEK_5_DAY",
  PO_BI_WEEK_7_DAY = "PO_BI_WEEK_7_DAY",
  PO_CONTRACTUAL = "PO_CONTRACTUAL",
  PO_DAILY = "PO_DAILY",
  PO_DAILY_NIGHTLY = "PO_DAILY_NIGHTLY",
  PO_DISCOUNT = "PO_DISCOUNT",
  PO_HOURLY = "PO_HOURLY",
  PO_MONTH_30_DAYS = "PO_MONTH_30_DAYS",
  PO_MULTI_DAY = "PO_MULTI_DAY",
  PO_PURCHASE = "PO_PURCHASE",
  PO_SHIPPING = "PO_SHIPPING",
  PO_USA_TAX = "PO_USA_TAX",
  PO_VAT_TAX = "PO_VAT_TAX",
  PO_WEEK_5_DAY = "PO_WEEK_5_DAY",
  PO_WEEK_7_DAY = "PO_WEEK_7_DAY",
}

/**
 * Methods to use when ordering `Supplier`.
 */
export enum SuppliersOrderBy {
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  BANK_ACCOUNT_NAME_ASC = "BANK_ACCOUNT_NAME_ASC",
  BANK_ACCOUNT_NAME_DESC = "BANK_ACCOUNT_NAME_DESC",
  BANK_ACCOUNT_NUMBER_ASC = "BANK_ACCOUNT_NUMBER_ASC",
  BANK_ACCOUNT_NUMBER_DESC = "BANK_ACCOUNT_NUMBER_DESC",
  BANK_IBAN_CODE_ASC = "BANK_IBAN_CODE_ASC",
  BANK_IBAN_CODE_DESC = "BANK_IBAN_CODE_DESC",
  BANK_NAME_ASC = "BANK_NAME_ASC",
  BANK_NAME_DESC = "BANK_NAME_DESC",
  BANK_ROUTING_NUMBER_ASC = "BANK_ROUTING_NUMBER_ASC",
  BANK_ROUTING_NUMBER_DESC = "BANK_ROUTING_NUMBER_DESC",
  BANK_SORT_CODE_ASC = "BANK_SORT_CODE_ASC",
  BANK_SORT_CODE_DESC = "BANK_SORT_CODE_DESC",
  BANK_SWIFT_CODE_ASC = "BANK_SWIFT_CODE_ASC",
  BANK_SWIFT_CODE_DESC = "BANK_SWIFT_CODE_DESC",
  COMPANY_OR_LAST_NAME_ASC = "COMPANY_OR_LAST_NAME_ASC",
  COMPANY_OR_LAST_NAME_DESC = "COMPANY_OR_LAST_NAME_DESC",
  CUSTOMER_ACCOUNT_ASC = "CUSTOMER_ACCOUNT_ASC",
  CUSTOMER_ACCOUNT_DESC = "CUSTOMER_ACCOUNT_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_BUSINESS_UNIT_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DEPARTMENT_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DEPARTMENT_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DEPARTMENT_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DEPARTMENT_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DIRECTORY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_DOCUMENT_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DEFAULT_ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DESCRIPTION_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DESCRIPTION_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DESCRIPTION_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__DESCRIPTION_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__EXTRACTION_TYPE_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__EXTRACTION_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__EXTRACTION_TYPE_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__EXTRACTION_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_BUSINESS_UNIT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_COMPANY_SELECTOR_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_COMPANY_SELECTOR_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_COMPANY_SELECTOR_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_COMPANY_SELECTOR_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_CREATE_COPY_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DEPARTMENT_SELECTOR_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DEPARTMENT_SELECTOR_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DEPARTMENT_SELECTOR_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DEPARTMENT_SELECTOR_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_DOCUMENT_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_ENTITY_TYPE_SELECTOR_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_ENTITY_TYPE_SELECTOR_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_ENTITY_TYPE_SELECTOR_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_ENTITY_TYPE_SELECTOR_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_PRIVATE_POOL_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_PRIVATE_POOL_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_PRIVATE_POOL_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_PRIVATE_POOL_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_RETAINED_WITH_SELECTION_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_RETAINED_WITH_SELECTION_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_RETAINED_WITH_SELECTION_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_RETAINED_WITH_SELECTION_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_SHAREABLE_DEFAULT_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_SHAREABLE_DEFAULT_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_SHAREABLE_DEFAULT_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__IS_SHAREABLE_DEFAULT_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__NAME_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__NAME_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__NAME_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__NAME_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__PARENT_DOCUMENT_POOL_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__PARENT_DOCUMENT_POOL_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__PARENT_DOCUMENT_POOL_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__PARENT_DOCUMENT_POOL_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ROW_SECURITY_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ROW_SECURITY_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ROW_SECURITY_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__ROW_SECURITY_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__USER_GROUP_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__USER_GROUP_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__USER_GROUP_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID__USER_GROUP_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___COMPANY_OR_POOLING_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___COMPANY_OR_POOLING_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___COMPANY_OR_POOLING_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___COMPANY_OR_POOLING_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_BY_USERID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_BY_USERID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_BY_USERID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_BY_USERID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_DATE_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_DATE_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_DATE_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___CREATED_DATE_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ENTITY_TYPE_ID_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ENTITY_TYPE_ID_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ENTITY_TYPE_ID_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ENTITY_TYPE_ID_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_ACTIVE_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_ACTIVE_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_ACTIVE_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_ACTIVE_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DELETABLE_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DELETABLE_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DELETABLE_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DELETABLE_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DEPRECATED_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DEPRECATED_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DEPRECATED_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DEPRECATED_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DOCUMENTS_EXIST_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DOCUMENTS_EXIST_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DOCUMENTS_EXIST_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_DOCUMENTS_EXIST_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MESSAGES_EXIST_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MESSAGES_EXIST_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MESSAGES_EXIST_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MESSAGES_EXIST_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MODIFIED_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MODIFIED_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MODIFIED_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_MODIFIED_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_NOTES_EXIST_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_NOTES_EXIST_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_NOTES_EXIST_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_NOTES_EXIST_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_VIEW_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_VIEW_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_VIEW_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_PROTECTED_VIEW_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_TAGS_EXIST_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_TAGS_EXIST_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_TAGS_EXIST_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_TAGS_EXIST_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_UPDATABLE_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_UPDATABLE_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_UPDATABLE_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___IS_UPDATABLE_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ROW_TIMESTAMP_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ROW_TIMESTAMP_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ROW_TIMESTAMP_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___ROW_TIMESTAMP_DESC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___SEARCH_DOCUMENT_ASC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___SEARCH_DOCUMENT_ASC",
  DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___SEARCH_DOCUMENT_DESC = "DOCUMENT_POOL_BY_PURCHASE_DOCUMENTS_POOL_ID___SEARCH_DOCUMENT_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__CITY_AREAS_ID_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__CITY_AREAS_ID_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__CITY_AREAS_ID_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__CITY_AREAS_ID_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__COUNTRY_ID_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__COUNTRY_ID_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__COUNTRY_ID_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__COUNTRY_ID_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__DESCRIPTION_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__DESCRIPTION_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__DESCRIPTION_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__DESCRIPTION_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ENVIRONMENTAL_METRIC_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ENVIRONMENTAL_METRIC_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ENVIRONMENTAL_METRIC_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ENVIRONMENTAL_METRIC_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ID_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ID_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ID_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__ID_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__LAYOUT_TYPE_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__LAYOUT_TYPE_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__LAYOUT_TYPE_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__LAYOUT_TYPE_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__STATE_REGION_ID_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__STATE_REGION_ID_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__STATE_REGION_ID_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID__STATE_REGION_ID_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_ACTIVE_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_ACTIVE_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_ACTIVE_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_ACTIVE_DESC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_DEPRECATED_ASC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_DEPRECATED_ASC",
  ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_DEPRECATED_DESC = "ENVIRONMENTAL_METRIC_BY_ENVIRONMENTAL_METRIC_ID___IS_DEPRECATED_DESC",
  ENVIRONMENTAL_METRIC_ID_ASC = "ENVIRONMENTAL_METRIC_ID_ASC",
  ENVIRONMENTAL_METRIC_ID_DESC = "ENVIRONMENTAL_METRIC_ID_DESC",
  FEDERAL_TAX_REFERENCE_ASC = "FEDERAL_TAX_REFERENCE_ASC",
  FEDERAL_TAX_REFERENCE_DESC = "FEDERAL_TAX_REFERENCE_DESC",
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_COMPETITIVE_BIDS_REQUIRED_ASC = "IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  IS_COMPETITIVE_BIDS_REQUIRED_DESC = "IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  IS_CORPORATION_ASC = "IS_CORPORATION_ASC",
  IS_CORPORATION_DESC = "IS_CORPORATION_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  IS_INDIVIDUAL_ASC = "IS_INDIVIDUAL_ASC",
  IS_INDIVIDUAL_DESC = "IS_INDIVIDUAL_DESC",
  IS_RELATED_PARTY_SUPPLIER_ASC = "IS_RELATED_PARTY_SUPPLIER_ASC",
  IS_RELATED_PARTY_SUPPLIER_DESC = "IS_RELATED_PARTY_SUPPLIER_DESC",
  IS_TRAVELER_ASC = "IS_TRAVELER_ASC",
  IS_TRAVELER_DESC = "IS_TRAVELER_DESC",
  IS_VENDOR_PLACEHOLDER_ASC = "IS_VENDOR_PLACEHOLDER_ASC",
  IS_VENDOR_PLACEHOLDER_DESC = "IS_VENDOR_PLACEHOLDER_DESC",
  IS_W9_ON_FILE_ASC = "IS_W9_ON_FILE_ASC",
  IS_W9_ON_FILE_DESC = "IS_W9_ON_FILE_DESC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  NAME_ALIAS_ASC = "NAME_ALIAS_ASC",
  NAME_ALIAS_DESC = "NAME_ALIAS_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DESCRIPTION_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DESCRIPTION_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DESCRIPTION_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DESCRIPTION_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_DAYS_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_DAYS_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_DAYS_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_DAYS_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_PERCENT_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_PERCENT_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_PERCENT_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DISCOUNT_PERCENT_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DUE_DAYS_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DUE_DAYS_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DUE_DAYS_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__DUE_DAYS_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__ID_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__ID_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__ID_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__ID_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__PAYMENT_TERM_TYPE_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__PAYMENT_TERM_TYPE_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__PAYMENT_TERM_TYPE_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__PAYMENT_TERM_TYPE_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__SORT_ORDER_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__SORT_ORDER_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__SORT_ORDER_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID__SORT_ORDER_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_ACTIVE_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_ACTIVE_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_ACTIVE_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_ACTIVE_DESC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_DEPRECATED_ASC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_DEPRECATED_ASC",
  PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_DEPRECATED_DESC = "PAYMENT_TERM_TYPE_BY_PAYMENT_TERM_TYPE_ID___IS_DEPRECATED_DESC",
  PAYMENT_TERM_TYPE_ID_ASC = "PAYMENT_TERM_TYPE_ID_ASC",
  PAYMENT_TERM_TYPE_ID_DESC = "PAYMENT_TERM_TYPE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PURCHASE_DOCUMENTS_POOL_ID_ASC = "PURCHASE_DOCUMENTS_POOL_ID_ASC",
  PURCHASE_DOCUMENTS_POOL_ID_DESC = "PURCHASE_DOCUMENTS_POOL_ID_DESC",
  PURCHASE_ORDERS_BY_SUPPLIER_ID__COUNT_ASC = "PURCHASE_ORDERS_BY_SUPPLIER_ID__COUNT_ASC",
  PURCHASE_ORDERS_BY_SUPPLIER_ID__COUNT_DESC = "PURCHASE_ORDERS_BY_SUPPLIER_ID__COUNT_DESC",
  RATINGS_BY_ENTITY_ID__COUNT_ASC = "RATINGS_BY_ENTITY_ID__COUNT_ASC",
  RATINGS_BY_ENTITY_ID__COUNT_DESC = "RATINGS_BY_ENTITY_ID__COUNT_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__COUNT_TOTAL_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__COUNT_TOTAL_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__COUNT_TOTAL_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__COUNT_TOTAL_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__ID_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__ID_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__ID_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__ID_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_1_PERCENT_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_1_PERCENT_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_1_PERCENT_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_1_PERCENT_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_2_PERCENT_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_2_PERCENT_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_2_PERCENT_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_2_PERCENT_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_3_PERCENT_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_3_PERCENT_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_3_PERCENT_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_3_PERCENT_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_4_PERCENT_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_4_PERCENT_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_4_PERCENT_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_4_PERCENT_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_5_PERCENT_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_5_PERCENT_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_5_PERCENT_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_5_PERCENT_DESC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_VALUE_ASC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_VALUE_ASC",
  RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_VALUE_DESC = "RATING_ENTITY_RESULT_BY_RATING_VENDOR_ID__STAR_VALUE_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  SUPPLIER_ADDRESSES_BY_SUPPLIER_ID__COUNT_ASC = "SUPPLIER_ADDRESSES_BY_SUPPLIER_ID__COUNT_ASC",
  SUPPLIER_ADDRESSES_BY_SUPPLIER_ID__COUNT_DESC = "SUPPLIER_ADDRESSES_BY_SUPPLIER_ID__COUNT_DESC",
  SUPPLIER_CONTACTS_BY_SUPPLIER_ID__COUNT_ASC = "SUPPLIER_CONTACTS_BY_SUPPLIER_ID__COUNT_ASC",
  SUPPLIER_CONTACTS_BY_SUPPLIER_ID__COUNT_DESC = "SUPPLIER_CONTACTS_BY_SUPPLIER_ID__COUNT_DESC",
  SUPPLIER_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "SUPPLIER_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  SUPPLIER_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "SUPPLIER_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  TRAVEL_AUTHORIZATIONS_BY_SUPPLIER_ID__COUNT_ASC = "TRAVEL_AUTHORIZATIONS_BY_SUPPLIER_ID__COUNT_ASC",
  TRAVEL_AUTHORIZATIONS_BY_SUPPLIER_ID__COUNT_DESC = "TRAVEL_AUTHORIZATIONS_BY_SUPPLIER_ID__COUNT_DESC",
  TRIP_ITEMS_BY_PICKUP_DROP_SUPPLIER_ID__COUNT_ASC = "TRIP_ITEMS_BY_PICKUP_DROP_SUPPLIER_ID__COUNT_ASC",
  TRIP_ITEMS_BY_PICKUP_DROP_SUPPLIER_ID__COUNT_DESC = "TRIP_ITEMS_BY_PICKUP_DROP_SUPPLIER_ID__COUNT_DESC",
  TRIP_ITEMS_BY_PICKUP_SUPPLIER_ID__COUNT_ASC = "TRIP_ITEMS_BY_PICKUP_SUPPLIER_ID__COUNT_ASC",
  TRIP_ITEMS_BY_PICKUP_SUPPLIER_ID__COUNT_DESC = "TRIP_ITEMS_BY_PICKUP_SUPPLIER_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_DROP_SUPPLIER_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_DROP_SUPPLIER_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_DROP_SUPPLIER_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_DROP_SUPPLIER_ID__COUNT_DESC",
  TRIP_ITEMS_BY_RETURN_SUPPLIER_ID__COUNT_ASC = "TRIP_ITEMS_BY_RETURN_SUPPLIER_ID__COUNT_ASC",
  TRIP_ITEMS_BY_RETURN_SUPPLIER_ID__COUNT_DESC = "TRIP_ITEMS_BY_RETURN_SUPPLIER_ID__COUNT_DESC",
  TRIP_ITEMS_BY_SUPPLIER_ID__COUNT_ASC = "TRIP_ITEMS_BY_SUPPLIER_ID__COUNT_ASC",
  TRIP_ITEMS_BY_SUPPLIER_ID__COUNT_DESC = "TRIP_ITEMS_BY_SUPPLIER_ID__COUNT_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC",
  VAT_NUMBER_ASC = "VAT_NUMBER_ASC",
  VAT_NUMBER_DESC = "VAT_NUMBER_DESC",
  VENDOR_NUMBER_ASC = "VENDOR_NUMBER_ASC",
  VENDOR_NUMBER_DESC = "VENDOR_NUMBER_DESC",
  VENDOR_SERVICE_RATE_ASC = "VENDOR_SERVICE_RATE_ASC",
  VENDOR_SERVICE_RATE_DESC = "VENDOR_SERVICE_RATE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__DESCRIPTION_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__DESCRIPTION_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__DESCRIPTION_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__DESCRIPTION_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__ID_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__ID_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__ID_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__ID_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_AIRFARE_TYPE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_AIRFARE_TYPE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_AIRFARE_TYPE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_AIRFARE_TYPE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_GROUND_TRANSPORTATION_TYPE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_GROUND_TRANSPORTATION_TYPE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_GROUND_TRANSPORTATION_TYPE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_GROUND_TRANSPORTATION_TYPE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_HOTEL_TYPE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_HOTEL_TYPE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_HOTEL_TYPE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_HOTEL_TYPE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_RATE_REQUIRED_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_RATE_REQUIRED_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_RATE_REQUIRED_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_RATE_REQUIRED_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_SERVICE_RATE_ALLOWED_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_SERVICE_RATE_ALLOWED_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_SERVICE_RATE_ALLOWED_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_SERVICE_RATE_ALLOWED_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAIN_TYPE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAIN_TYPE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAIN_TYPE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAIN_TYPE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAVEL_AGENT_TYPE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAVEL_AGENT_TYPE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAVEL_AGENT_TYPE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_TRAVEL_AGENT_TYPE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_VEHICLE_RENTAL_TYPE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_VEHICLE_RENTAL_TYPE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_VEHICLE_RENTAL_TYPE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__IS_VEHICLE_RENTAL_TYPE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__SORT_ORDER_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__SORT_ORDER_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__SORT_ORDER_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__SORT_ORDER_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__VENDOR_TYPE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__VENDOR_TYPE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__VENDOR_TYPE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID__VENDOR_TYPE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_ACTIVE_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_ACTIVE_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_ACTIVE_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_ACTIVE_DESC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_DEPRECATED_ASC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_DEPRECATED_ASC",
  VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_DEPRECATED_DESC = "VENDOR_SERVICE_TYPE_BY_VENDOR_SERVICE_TYPE_ID___IS_DEPRECATED_DESC",
  VENDOR_SERVICE_TYPE_ID_ASC = "VENDOR_SERVICE_TYPE_ID_ASC",
  VENDOR_SERVICE_TYPE_ID_DESC = "VENDOR_SERVICE_TYPE_ID_DESC",
  W9_EXPIRATION_DATE_ASC = "W9_EXPIRATION_DATE_ASC",
  W9_EXPIRATION_DATE_DESC = "W9_EXPIRATION_DATE_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _FULL_NAME_ASC = "_FULL_NAME_ASC",
  _FULL_NAME_DESC = "_FULL_NAME_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_ALIAS_INVOKED_ASC = "_IS_ALIAS_INVOKED_ASC",
  _IS_ALIAS_INVOKED_DESC = "_IS_ALIAS_INVOKED_DESC",
  _IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "_IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  _IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "_IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_BANK_ACCOUNT_VERIFIED_ASC = "_IS_BANK_ACCOUNT_VERIFIED_ASC",
  _IS_BANK_ACCOUNT_VERIFIED_DESC = "_IS_BANK_ACCOUNT_VERIFIED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_ENVIRONMENTALS_EXIST_ASC = "_IS_ENVIRONMENTALS_EXIST_ASC",
  _IS_ENVIRONMENTALS_EXIST_DESC = "_IS_ENVIRONMENTALS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC = "_IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC",
  _IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC = "_IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC",
  _IS_RATINGS_EXIST_ASC = "_IS_RATINGS_EXIST_ASC",
  _IS_RATINGS_EXIST_DESC = "_IS_RATINGS_EXIST_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_UPDATE_SUPPLIER_OVERRIDE_ASC = "_IS_UPDATE_SUPPLIER_OVERRIDE_ASC",
  _IS_UPDATE_SUPPLIER_OVERRIDE_DESC = "_IS_UPDATE_SUPPLIER_OVERRIDE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _RATING_VENDOR_ID_ASC = "_RATING_VENDOR_ID_ASC",
  _RATING_VENDOR_ID_DESC = "_RATING_VENDOR_ID_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _SORT_NAME_ASC = "_SORT_NAME_ASC",
  _SORT_NAME_DESC = "_SORT_NAME_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _USER_GROUP_ID_ASC = "_USER_GROUP_ID_ASC",
  _USER_GROUP_ID_DESC = "_USER_GROUP_ID_DESC",
}

/**
 * Methods to use when ordering `TagCategory`.
 */
export enum TagCategoriesOrderBy {
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_COST_METRIC_ASC = "IS_COST_METRIC_ASC",
  IS_COST_METRIC_DESC = "IS_COST_METRIC_DESC",
  IS_CUSTOMER_METRIC_ASC = "IS_CUSTOMER_METRIC_ASC",
  IS_CUSTOMER_METRIC_DESC = "IS_CUSTOMER_METRIC_DESC",
  IS_EMPLOYEE_METRIC_ASC = "IS_EMPLOYEE_METRIC_ASC",
  IS_EMPLOYEE_METRIC_DESC = "IS_EMPLOYEE_METRIC_DESC",
  IS_ENVIRONMENTAL_METRIC_ASC = "IS_ENVIRONMENTAL_METRIC_ASC",
  IS_ENVIRONMENTAL_METRIC_DESC = "IS_ENVIRONMENTAL_METRIC_DESC",
  IS_PAYABLES_METRIC_ASC = "IS_PAYABLES_METRIC_ASC",
  IS_PAYABLES_METRIC_DESC = "IS_PAYABLES_METRIC_DESC",
  IS_PAYROLL_METRIC_ASC = "IS_PAYROLL_METRIC_ASC",
  IS_PAYROLL_METRIC_DESC = "IS_PAYROLL_METRIC_DESC",
  IS_PETTY_CASH_METRIC_ASC = "IS_PETTY_CASH_METRIC_ASC",
  IS_PETTY_CASH_METRIC_DESC = "IS_PETTY_CASH_METRIC_DESC",
  IS_PURCHASING_METRIC_ASC = "IS_PURCHASING_METRIC_ASC",
  IS_PURCHASING_METRIC_DESC = "IS_PURCHASING_METRIC_DESC",
  IS_RECEIVABLES_METRIC_ASC = "IS_RECEIVABLES_METRIC_ASC",
  IS_RECEIVABLES_METRIC_DESC = "IS_RECEIVABLES_METRIC_DESC",
  IS_RED_FLAG_ASC = "IS_RED_FLAG_ASC",
  IS_RED_FLAG_DESC = "IS_RED_FLAG_DESC",
  IS_SUPPLIER_METRIC_ASC = "IS_SUPPLIER_METRIC_ASC",
  IS_SUPPLIER_METRIC_DESC = "IS_SUPPLIER_METRIC_DESC",
  IS_TRAVEL_METRIC_ASC = "IS_TRAVEL_METRIC_ASC",
  IS_TRAVEL_METRIC_DESC = "IS_TRAVEL_METRIC_DESC",
  IS_VENDOR_METRIC_ASC = "IS_VENDOR_METRIC_ASC",
  IS_VENDOR_METRIC_DESC = "IS_VENDOR_METRIC_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  TAG_CATEGORY_ITEMS_BY_TAG_CATEGORY_ID__COUNT_ASC = "TAG_CATEGORY_ITEMS_BY_TAG_CATEGORY_ID__COUNT_ASC",
  TAG_CATEGORY_ITEMS_BY_TAG_CATEGORY_ID__COUNT_DESC = "TAG_CATEGORY_ITEMS_BY_TAG_CATEGORY_ID__COUNT_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `TagGroup`.
 */
export enum TagGroupsOrderBy {
  COMPANY_TAG_GROUPS_BY_TAG_GROUP_ID__COUNT_ASC = "COMPANY_TAG_GROUPS_BY_TAG_GROUP_ID__COUNT_ASC",
  COMPANY_TAG_GROUPS_BY_TAG_GROUP_ID__COUNT_DESC = "COMPANY_TAG_GROUPS_BY_TAG_GROUP_ID__COUNT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_TAG_GROUP_ID__COUNT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_TAG_GROUP_ID__COUNT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_TAG_GROUP_ID__COUNT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_TAG_GROUP_ID__COUNT_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_ADDED_TAGS_RECEIVED_ASC = "IS_ADDED_TAGS_RECEIVED_ASC",
  IS_ADDED_TAGS_RECEIVED_DESC = "IS_ADDED_TAGS_RECEIVED_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  TAG_GROUP_ITEMS_BY_TAG_GROUP_ID__COUNT_ASC = "TAG_GROUP_ITEMS_BY_TAG_GROUP_ID__COUNT_ASC",
  TAG_GROUP_ITEMS_BY_TAG_GROUP_ID__COUNT_DESC = "TAG_GROUP_ITEMS_BY_TAG_GROUP_ID__COUNT_DESC",
  USER_PROFILES_BY_TAG_GROUP_ID__COUNT_ASC = "USER_PROFILES_BY_TAG_GROUP_ID__COUNT_ASC",
  USER_PROFILES_BY_TAG_GROUP_ID__COUNT_DESC = "USER_PROFILES_BY_TAG_GROUP_ID__COUNT_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `Tag`.
 */
export enum TagsOrderBy {
  APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_TAG_ID__COUNT_ASC = "APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_TAG_ID__COUNT_ASC",
  APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_TAG_ID__COUNT_DESC = "APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_TAG_ID__COUNT_DESC",
  BADGE_COLOR_ASC = "BADGE_COLOR_ASC",
  BADGE_COLOR_DESC = "BADGE_COLOR_DESC",
  BADGE_NAME_ASC = "BADGE_NAME_ASC",
  BADGE_NAME_DESC = "BADGE_NAME_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_ACCESS_TAG_ASC = "IS_ACCESS_TAG_ASC",
  IS_ACCESS_TAG_DESC = "IS_ACCESS_TAG_DESC",
  IS_MONITORED_ASC = "IS_MONITORED_ASC",
  IS_MONITORED_DESC = "IS_MONITORED_DESC",
  IS_PRIVATE_ASC = "IS_PRIVATE_ASC",
  IS_PRIVATE_DESC = "IS_PRIVATE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  TAG_CATEGORY_ITEMS_BY_TAG_ID__COUNT_ASC = "TAG_CATEGORY_ITEMS_BY_TAG_ID__COUNT_ASC",
  TAG_CATEGORY_ITEMS_BY_TAG_ID__COUNT_DESC = "TAG_CATEGORY_ITEMS_BY_TAG_ID__COUNT_DESC",
  TAG_GROUP_ITEMS_BY_TAG_ID__COUNT_ASC = "TAG_GROUP_ITEMS_BY_TAG_ID__COUNT_ASC",
  TAG_GROUP_ITEMS_BY_TAG_ID__COUNT_DESC = "TAG_GROUP_ITEMS_BY_TAG_ID__COUNT_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_SYSTEM_GENERATED_ASC = "_IS_SYSTEM_GENERATED_ASC",
  _IS_SYSTEM_GENERATED_DESC = "_IS_SYSTEM_GENERATED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

export enum TransactionLayout {
  ACCOUNTING_ENTRY_STANDARD = "ACCOUNTING_ENTRY_STANDARD",
  ACH_PAYMENT = "ACH_PAYMENT",
  AP_INVOICE = "AP_INVOICE",
  BANK_STATEMENT_DOCUMENT_PACKAGE = "BANK_STATEMENT_DOCUMENT_PACKAGE",
  BATCH_TRANSACTION_APPROVAL = "BATCH_TRANSACTION_APPROVAL",
  CHECK_PAYMENT = "CHECK_PAYMENT",
  CREDIT_CARD_INVOICE = "CREDIT_CARD_INVOICE",
  DEFAULT_DISTRIBUTION_LAYOUT = "DEFAULT_DISTRIBUTION_LAYOUT",
  DISNEY_SAP_CORPORATE_ACCOUNT_LAYOUT = "DISNEY_SAP_CORPORATE_ACCOUNT_LAYOUT",
  DOCUMENT_ACCOUNTING_ENTRY = "DOCUMENT_ACCOUNTING_ENTRY",
  DOCUMENT_FILE = "DOCUMENT_FILE",
  DOCUMENT_PAYMENT = "DOCUMENT_PAYMENT",
  DOCUMENT_PAYMENT_REGISTER = "DOCUMENT_PAYMENT_REGISTER",
  DOCUMENT_SIGNING_AMOUNT = "DOCUMENT_SIGNING_AMOUNT",
  DOCUMENT_STANDARD_ENTRY = "DOCUMENT_STANDARD_ENTRY",
  DOCUMENT_TIME_CARD_ACCOUNTING_ENTRY = "DOCUMENT_TIME_CARD_ACCOUNTING_ENTRY",
  EMAIL_STANDARD_LAYOUT = "EMAIL_STANDARD_LAYOUT",
  ENV_FUEL = "ENV_FUEL",
  ENV_KWH = "ENV_KWH",
  ENV_THERMS = "ENV_THERMS",
  ENV_TONNES = "ENV_TONNES",
  ENV_TRAVEL = "ENV_TRAVEL",
  ENV_UNITS = "ENV_UNITS",
  ENV_VEHICLES = "ENV_VEHICLES",
  EPISODIC_DISTRIBUTION_LAYOUT = "EPISODIC_DISTRIBUTION_LAYOUT",
  EXP_AIR_TRAVEL = "EXP_AIR_TRAVEL",
  EXP_BOOKING_FEE = "EXP_BOOKING_FEE",
  EXP_CONSTRUCTION_MATERIAL = "EXP_CONSTRUCTION_MATERIAL",
  EXP_EQUIPMENT_RENTAL = "EXP_EQUIPMENT_RENTAL",
  EXP_FUEL = "EXP_FUEL",
  EXP_GROUND_TRANSPORTATION = "EXP_GROUND_TRANSPORTATION",
  EXP_HOTEL = "EXP_HOTEL",
  EXP_INCIDENTALS = "EXP_INCIDENTALS",
  EXP_INSURANCE = "EXP_INSURANCE",
  EXP_PRODUCT = "EXP_PRODUCT",
  EXP_SERVICE = "EXP_SERVICE",
  EXP_SHIPPING = "EXP_SHIPPING",
  EXP_TAX = "EXP_TAX",
  EXP_TRAIN_TRAVEL = "EXP_TRAIN_TRAVEL",
  EXP_VEHICLE_RENTAL = "EXP_VEHICLE_RENTAL",
  GL_CO_LOC_DISTRIBUTION_LAYOUT = "GL_CO_LOC_DISTRIBUTION_LAYOUT",
  GL_CO_LOC_EPISODE_DISTRIBUTION_LAYOUT = "GL_CO_LOC_EPISODE_DISTRIBUTION_LAYOUT",
  GL_LOC_DISTRIBUTION_LAYOUT = "GL_LOC_DISTRIBUTION_LAYOUT",
  GL_LOC_EPISODE_DISTRIBUTION_LAYOUT = "GL_LOC_EPISODE_DISTRIBUTION_LAYOUT",
  JOURNAL_VOUCHER = "JOURNAL_VOUCHER",
  LISTVIEW_SIZE_LARGE = "LISTVIEW_SIZE_LARGE",
  LISTVIEW_SIZE_MEDIUM = "LISTVIEW_SIZE_MEDIUM",
  LISTVIEW_SIZE_SMALL = "LISTVIEW_SIZE_SMALL",
  LOCATION_DISTRIBUTION_LAYOUT = "LOCATION_DISTRIBUTION_LAYOUT",
  LOCATION_ONLY_DISTRIBUTION_LAYOUT = "LOCATION_ONLY_DISTRIBUTION_LAYOUT",
  LOCATION_ONLY_EPISODE_DISTRIBUTION_LAYOUT = "LOCATION_ONLY_EPISODE_DISTRIBUTION_LAYOUT",
  MISCELLANEOUS_APPROVAL = "MISCELLANEOUS_APPROVAL",
  MOVEMENT_1_AREA = "MOVEMENT_1_AREA",
  MOVEMENT_2_AREA = "MOVEMENT_2_AREA",
  MOVEMENT_3_AREA = "MOVEMENT_3_AREA",
  MOVEMENT_4_AREA = "MOVEMENT_4_AREA",
  MOVEMENT_SECTION_HEADER = "MOVEMENT_SECTION_HEADER",
  NON_COMPANY_DISTRIBUTION_LAYOUT = "NON_COMPANY_DISTRIBUTION_LAYOUT",
  PAYMENT = "PAYMENT",
  PAYMENT_CYCLE = "PAYMENT_CYCLE",
  PAYMENT_REQUEST = "PAYMENT_REQUEST",
  PAY_CARD = "PAY_CARD",
  PAY_REQUEST = "PAY_REQUEST",
  PER_DIEM = "PER_DIEM",
  PETTY_CASH = "PETTY_CASH",
  PETTY_CASH_ELAPSED_DAYS = "PETTY_CASH_ELAPSED_DAYS",
  PETTY_CASH_FUND = "PETTY_CASH_FUND",
  PO_ENTRY_STANDARD = "PO_ENTRY_STANDARD",
  PO_ITEM_CONTRACT = "PO_ITEM_CONTRACT",
  PO_ITEM_DELIVERY = "PO_ITEM_DELIVERY",
  PO_ITEM_DISCOUNT = "PO_ITEM_DISCOUNT",
  PO_ITEM_PURCHASE = "PO_ITEM_PURCHASE",
  PO_ITEM_RENTAL = "PO_ITEM_RENTAL",
  PO_ITEM_TAX = "PO_ITEM_TAX",
  PO_ITEM_TRAVEL = "PO_ITEM_TRAVEL",
  PO_STATEMENT_STANDARD = "PO_STATEMENT_STANDARD",
  PO_SYSTEM_APPROVAL = "PO_SYSTEM_APPROVAL",
  PO_SYSTEM_APPROVAL_ACCOUNTING = "PO_SYSTEM_APPROVAL_ACCOUNTING",
  PR_INVOICE = "PR_INVOICE",
  PSL_ENTRY_EPISODIC = "PSL_ENTRY_EPISODIC",
  PSL_ENTRY_SERIES = "PSL_ENTRY_SERIES",
  PSL_ENTRY_STANDARD = "PSL_ENTRY_STANDARD",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  SERVICE_PAYMENT = "SERVICE_PAYMENT",
  SIGNING_AMOUNT_DOCUMENT = "SIGNING_AMOUNT_DOCUMENT",
  SIGNING_AMOUNT_DOCUMENT_VALUES = "SIGNING_AMOUNT_DOCUMENT_VALUES",
  SIGNING_DOCUMENT = "SIGNING_DOCUMENT",
  SMARTACCT_DISTRIBUTION_LAYOUT = "SMARTACCT_DISTRIBUTION_LAYOUT",
  TA_SYSTEM_APPROVAL = "TA_SYSTEM_APPROVAL",
  TIME_CARD = "TIME_CARD",
  TRAIN_BOOKING_MULTI_TRIP = "TRAIN_BOOKING_MULTI_TRIP",
  TRAIN_BOOKING_ONE_WAY = "TRAIN_BOOKING_ONE_WAY",
  TRAIN_BOOKING_ROUND_TRIP = "TRAIN_BOOKING_ROUND_TRIP",
  TRAIN_PLANNING_MULTI_TRIP = "TRAIN_PLANNING_MULTI_TRIP",
  TRAIN_PLANNING_ONE_WAY = "TRAIN_PLANNING_ONE_WAY",
  TRAIN_PLANNING_ROUND_TRIP = "TRAIN_PLANNING_ROUND_TRIP",
  TRAVEL_AUTHORIZATION = "TRAVEL_AUTHORIZATION",
  TRAVEL_BOOKING_AGENCY = "TRAVEL_BOOKING_AGENCY",
  TRAVEL_BOOKING_AIR_TRAVEL = "TRAVEL_BOOKING_AIR_TRAVEL",
  TRAVEL_BOOKING_GROUND_TRANS = "TRAVEL_BOOKING_GROUND_TRANS",
  TRAVEL_BOOKING_HOTEL = "TRAVEL_BOOKING_HOTEL",
  TRAVEL_BOOKING_HOTEL_MONTHLY = "TRAVEL_BOOKING_HOTEL_MONTHLY",
  TRAVEL_BOOKING_HOTEL_NIGHTLY = "TRAVEL_BOOKING_HOTEL_NIGHTLY",
  TRAVEL_BOOKING_HOTEL_WEEKLY = "TRAVEL_BOOKING_HOTEL_WEEKLY",
  TRAVEL_BOOKING_INCIDENTAL = "TRAVEL_BOOKING_INCIDENTAL",
  TRAVEL_BOOKING_MULTI_TRIP = "TRAVEL_BOOKING_MULTI_TRIP",
  TRAVEL_BOOKING_ONE_WAY = "TRAVEL_BOOKING_ONE_WAY",
  TRAVEL_BOOKING_ROUND_TRIP = "TRAVEL_BOOKING_ROUND_TRIP",
  TRAVEL_BOOKING_TRAIN_TRAVEL = "TRAVEL_BOOKING_TRAIN_TRAVEL",
  TRAVEL_BOOKING_VEHICLE_RENTAL = "TRAVEL_BOOKING_VEHICLE_RENTAL",
  TRAVEL_BOOKING_VEHICLE_RENTAL_DAILY = "TRAVEL_BOOKING_VEHICLE_RENTAL_DAILY",
  TRAVEL_BOOKING_VEHICLE_RENTAL_MONTHLY = "TRAVEL_BOOKING_VEHICLE_RENTAL_MONTHLY",
  TRAVEL_BOOKING_VEHICLE_RENTAL_WEEKLY = "TRAVEL_BOOKING_VEHICLE_RENTAL_WEEKLY",
  TRAVEL_PLANNING_AIR_TRAVEL = "TRAVEL_PLANNING_AIR_TRAVEL",
  TRAVEL_PLANNING_GROUND_TRANS = "TRAVEL_PLANNING_GROUND_TRANS",
  TRAVEL_PLANNING_HOTEL = "TRAVEL_PLANNING_HOTEL",
  TRAVEL_PLANNING_MULTI_TRIP = "TRAVEL_PLANNING_MULTI_TRIP",
  TRAVEL_PLANNING_ONE_WAY = "TRAVEL_PLANNING_ONE_WAY",
  TRAVEL_PLANNING_ROUND_TRIP = "TRAVEL_PLANNING_ROUND_TRIP",
  TRAVEL_PLANNING_TRAIN_TRAVEL = "TRAVEL_PLANNING_TRAIN_TRAVEL",
  TRAVEL_PLANNING_VEHICLE_RENTAL = "TRAVEL_PLANNING_VEHICLE_RENTAL",
  WIRE_PAYMENT = "WIRE_PAYMENT",
  WIRE_TRANSFER = "WIRE_TRANSFER",
  WIRE_TRANSFER_BANK_ACCOUNT = "WIRE_TRANSFER_BANK_ACCOUNT",
}

/**
 * Methods to use when ordering `TravelAuthorization`.
 */
export enum TravelAuthorizationsOrderBy {
  AGENCY_REFERENCE_NAME_ASC = "AGENCY_REFERENCE_NAME_ASC",
  AGENCY_REFERENCE_NAME_DESC = "AGENCY_REFERENCE_NAME_DESC",
  AMENDED_DATE_ASC = "AMENDED_DATE_ASC",
  AMENDED_DATE_DESC = "AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  BOOKING_FEE_ASC = "BOOKING_FEE_ASC",
  BOOKING_FEE_DESC = "BOOKING_FEE_DESC",
  BUDGET_AMOUNT_ASC = "BUDGET_AMOUNT_ASC",
  BUDGET_AMOUNT_DESC = "BUDGET_AMOUNT_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMMENT_ASC = "COMMENT_ASC",
  COMMENT_DESC = "COMMENT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC",
  COMPANY_BY_COMPANY_ID__ID_ASC = "COMPANY_BY_COMPANY_ID__ID_ASC",
  COMPANY_BY_COMPANY_ID__ID_DESC = "COMPANY_BY_COMPANY_ID__ID_DESC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC",
  COMPANY_BY_COMPANY_ID__NAME_ASC = "COMPANY_BY_COMPANY_ID__NAME_ASC",
  COMPANY_BY_COMPANY_ID__NAME_DESC = "COMPANY_BY_COMPANY_ID__NAME_DESC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_ASC = "COMPANY_BY_COMPANY_ID__TENANT_ID_ASC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_DESC = "COMPANY_BY_COMPANY_ID__TENANT_ID_DESC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  CONTROL_TOTAL_AMOUNT_ASC = "CONTROL_TOTAL_AMOUNT_ASC",
  CONTROL_TOTAL_AMOUNT_DESC = "CONTROL_TOTAL_AMOUNT_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ABBREVIATION_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ABBREVIATION_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ABBREVIATION_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ABBREVIATION_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__BUSINESS_UNIT_ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__BUSINESS_UNIT_ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__BUSINESS_UNIT_ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__BUSINESS_UNIT_ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CHART_OF_ACCOUNTS_ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CHART_OF_ACCOUNTS_ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CHART_OF_ACCOUNTS_ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CHART_OF_ACCOUNTS_ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CONTROLLING_WORKGROUP_ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CONTROLLING_WORKGROUP_ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CONTROLLING_WORKGROUP_ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__CONTROLLING_WORKGROUP_ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__DESCRIPTION_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__DESCRIPTION_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__DESCRIPTION_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__DESCRIPTION_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_DRAFT_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_DRAFT_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_DRAFT_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_DRAFT_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_NEW_WORKGROUP_SELECTABLE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_NEW_WORKGROUP_SELECTABLE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_NEW_WORKGROUP_SELECTABLE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__IS_NEW_WORKGROUP_SELECTABLE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__NAME_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__NAME_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__NAME_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__NAME_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PREFIX_CODE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PREFIX_CODE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PREFIX_CODE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PREFIX_CODE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_PHASE_ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_PHASE_ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_PHASE_ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_PHASE_ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_STATUS_TYPE_ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_STATUS_TYPE_ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_STATUS_TYPE_ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__PRODUCTION_STATUS_TYPE_ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__SORTED_CODE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__SORTED_CODE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__SORTED_CODE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__SORTED_CODE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__WORKGROUP_NUMBER_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__WORKGROUP_NUMBER_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__WORKGROUP_NUMBER_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID__WORKGROUP_NUMBER_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___COMPANY_ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___COMPANY_ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___COMPANY_ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___COMPANY_ID_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___CREATED_DATE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___CREATED_DATE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___CREATED_DATE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___CREATED_DATE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_ACTIVE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_ACTIVE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_ACTIVE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_ACTIVE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVALS_EXIST_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVALS_EXIST_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVALS_EXIST_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVALS_EXIST_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVAL_REVOCABLE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVAL_REVOCABLE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVAL_REVOCABLE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVAL_REVOCABLE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVED_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVED_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVED_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_APPROVED_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DELETABLE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DELETABLE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DELETABLE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DELETABLE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DEPRECATED_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DEPRECATED_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DEPRECATED_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DEPRECATED_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DOCUMENTS_EXIST_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DOCUMENTS_EXIST_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DOCUMENTS_EXIST_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_DOCUMENTS_EXIST_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MESSAGES_EXIST_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MESSAGES_EXIST_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MESSAGES_EXIST_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MESSAGES_EXIST_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MODIFIED_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MODIFIED_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MODIFIED_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_MODIFIED_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_NOTES_EXIST_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_NOTES_EXIST_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_NOTES_EXIST_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_NOTES_EXIST_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_TAGS_EXIST_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_TAGS_EXIST_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_TAGS_EXIST_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_TAGS_EXIST_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_AFTER_APPROVED_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_AFTER_APPROVED_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_AFTER_APPROVED_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_AFTER_APPROVED_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_UPDATABLE_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVER_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVER_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVER_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_APPROVER_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_OBSERVER_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_OBSERVER_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_OBSERVER_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_OBSERVER_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REQUESTER_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REQUESTER_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REQUESTER_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REQUESTER_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REVOKER_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REVOKER_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REVOKER_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_USER_REVOKER_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_VALIDATED_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_VALIDATED_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_VALIDATED_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_VALIDATED_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_APPROVAL_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_APPROVAL_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_APPROVAL_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_APPROVAL_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_VALIDATION_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_VALIDATION_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_VALIDATION_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___IS_WAITING_VALIDATION_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___ROW_TIMESTAMP_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___ROW_TIMESTAMP_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___ROW_TIMESTAMP_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___ROW_TIMESTAMP_DESC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___STATUS_TYPE_ID_ASC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___STATUS_TYPE_ID_ASC",
  CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___STATUS_TYPE_ID_DESC = "CORPORATE_WORKGROUP_BY_CORPORATE_WORKGROUP_ID___STATUS_TYPE_ID_DESC",
  CORPORATE_WORKGROUP_ID_ASC = "CORPORATE_WORKGROUP_ID_ASC",
  CORPORATE_WORKGROUP_ID_DESC = "CORPORATE_WORKGROUP_ID_DESC",
  CORPORATE_WORKGROUP_NAME_ASC = "CORPORATE_WORKGROUP_NAME_ASC",
  CORPORATE_WORKGROUP_NAME_DESC = "CORPORATE_WORKGROUP_NAME_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DOCUMENT_FILE_ID_ASC = "DOCUMENT_FILE_ID_ASC",
  DOCUMENT_FILE_ID_DESC = "DOCUMENT_FILE_ID_DESC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "EMAIL_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENT_ID_ASC = "ENTITY_DOCUMENT_ID_ASC",
  ENTITY_DOCUMENT_ID_DESC = "ENTITY_DOCUMENT_ID_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC = "IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC",
  IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC = "IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC",
  LINKED_ENTITY_ID_ASC = "LINKED_ENTITY_ID_ASC",
  LINKED_ENTITY_ID_DESC = "LINKED_ENTITY_ID_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__AMENDED_DATE_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__AMENDED_DATE_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__AMENDED_DATE_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__AMENDED_DATE_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ID_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ID_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ID_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ID_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__IS_GROUP_MOVEMENT_ORDER_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__IS_GROUP_MOVEMENT_ORDER_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__IS_GROUP_MOVEMENT_ORDER_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__IS_GROUP_MOVEMENT_ORDER_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_DATE_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_DATE_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_DATE_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_DATE_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_NUMBER_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_NUMBER_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_NUMBER_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__MOVEMENT_ORDER_NUMBER_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ORIGINAL_MOVEMENT_ORDER_ID_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ORIGINAL_MOVEMENT_ORDER_ID_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ORIGINAL_MOVEMENT_ORDER_ID_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__ORIGINAL_MOVEMENT_ORDER_ID_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_DATE_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_DATE_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_DATE_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_DATE_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_USER_ID_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_USER_ID_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_USER_ID_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISED_USER_ID_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISION_NUMBER_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISION_NUMBER_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISION_NUMBER_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__REVISION_NUMBER_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__TRAVEL_AUTHORIZATION_ID_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__TRAVEL_AUTHORIZATION_ID_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__TRAVEL_AUTHORIZATION_ID_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID__TRAVEL_AUTHORIZATION_ID_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_BY_USERID_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_BY_USERID_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_BY_USERID_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_BY_USERID_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_DATE_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_DATE_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_DATE_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___CREATED_DATE_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_ACTIVE_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_ACTIVE_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_ACTIVE_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_ACTIVE_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DELETABLE_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DELETABLE_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DELETABLE_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DELETABLE_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DEPRECATED_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DEPRECATED_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DEPRECATED_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_DEPRECATED_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_MODIFIED_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_MODIFIED_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_MODIFIED_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_MODIFIED_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_REBUILD_MOVEMENT_ORDERS_ALLOWED_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_REBUILD_MOVEMENT_ORDERS_ALLOWED_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_REBUILD_MOVEMENT_ORDERS_ALLOWED_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_REBUILD_MOVEMENT_ORDERS_ALLOWED_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_UPDATABLE_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_UPDATABLE_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_UPDATABLE_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___IS_UPDATABLE_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___ROW_TIMESTAMP_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___ROW_TIMESTAMP_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___ROW_TIMESTAMP_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___ROW_TIMESTAMP_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___SEARCH_DOCUMENT_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___SEARCH_DOCUMENT_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___SEARCH_DOCUMENT_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___SEARCH_DOCUMENT_DESC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___STATUS_TYPE_ID_ASC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___STATUS_TYPE_ID_ASC",
  MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___STATUS_TYPE_ID_DESC = "MOVEMENT_ORDER_BY_TRIP_MOVEMENT_ID___STATUS_TYPE_ID_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC = "ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC",
  ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC = "ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  REFERENCE_ITEM_TYPES_ASC = "REFERENCE_ITEM_TYPES_ASC",
  REFERENCE_ITEM_TYPES_DESC = "REFERENCE_ITEM_TYPES_DESC",
  REQUIRED_DATE_ASC = "REQUIRED_DATE_ASC",
  REQUIRED_DATE_DESC = "REQUIRED_DATE_DESC",
  REQUIRED_TIME_ASC = "REQUIRED_TIME_ASC",
  REQUIRED_TIME_DESC = "REQUIRED_TIME_DESC",
  REVISION_NUMBER_ASC = "REVISION_NUMBER_ASC",
  REVISION_NUMBER_DESC = "REVISION_NUMBER_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  SORT_AGENCY_NAME_ASC = "SORT_AGENCY_NAME_ASC",
  SORT_AGENCY_NAME_DESC = "SORT_AGENCY_NAME_DESC",
  STAMPED_ENTITY_DOCUMENT_ID_ASC = "STAMPED_ENTITY_DOCUMENT_ID_ASC",
  STAMPED_ENTITY_DOCUMENT_ID_DESC = "STAMPED_ENTITY_DOCUMENT_ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ACCOUNT_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_IBAN_CODE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_ROUTING_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SORT_CODE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_ASC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_DESC = "SUPPLIER_BY_SUPPLIER_ID__BANK_SWIFT_CODE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__COMPANY_OR_LAST_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_ASC = "SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_ASC",
  SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_DESC = "SUPPLIER_BY_SUPPLIER_ID__CUSTOMER_ACCOUNT_DESC",
  SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_ASC = "SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_DESC = "SUPPLIER_BY_SUPPLIER_ID__FEDERAL_TAX_REFERENCE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID__FIRST_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID__ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_CORPORATION_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_DRAFT_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_INDIVIDUAL_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_TRAVELER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_VENDOR_PLACEHOLDER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_ASC = "SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_DESC = "SUPPLIER_BY_SUPPLIER_ID__IS_W9_ON_FILE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_ASC = "SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_ASC",
  SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_DESC = "SUPPLIER_BY_SUPPLIER_ID__NAME_ALIAS_DESC",
  SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__PAYMENT_TERM_TYPE_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__ROW_SECURITY_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__VAT_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_ASC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_DESC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_NUMBER_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_ASC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_DESC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_RATE_DESC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_ASC = "SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_ASC",
  SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_DESC = "SUPPLIER_BY_SUPPLIER_ID__W9_EXPIRATION_DATE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___COMPANY_OR_POOLING_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_ASC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_DESC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_BY_USERID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_ASC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_DESC = "SUPPLIER_BY_SUPPLIER_ID___CREATED_DATE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID___FULL_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ACTIVE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_INVOKED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVAL_REVOCABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_APPROVED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_DELETABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_DEPRECATED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_DOCUMENTS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_MESSAGES_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_MODIFIED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_NOTES_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_PROTECTED_VIEW_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_RATINGS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_TAGS_EXIST_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATABLE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_APPROVER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_OBSERVER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REQUESTER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_USER_REVOKER_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_VALIDATED_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_APPROVAL_DESC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_ASC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_ASC",
  SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_DESC = "SUPPLIER_BY_SUPPLIER_ID___IS_WAITING_VALIDATION_DESC",
  SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_ASC = "SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_ASC",
  SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_DESC = "SUPPLIER_BY_SUPPLIER_ID___PENDING_APPROVERS_DESC",
  SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___RATING_VENDOR_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "SUPPLIER_BY_SUPPLIER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_ASC = "SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_ASC",
  SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_DESC = "SUPPLIER_BY_SUPPLIER_ID___ROW_TIMESTAMP_DESC",
  SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_ASC = "SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_ASC",
  SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_DESC = "SUPPLIER_BY_SUPPLIER_ID___SEARCH_DOCUMENT_DESC",
  SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_ASC = "SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_ASC",
  SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_DESC = "SUPPLIER_BY_SUPPLIER_ID___SORT_NAME_DESC",
  SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_ASC = "SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_ASC",
  SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_DESC = "SUPPLIER_BY_SUPPLIER_ID___USER_GROUP_ID_DESC",
  SUPPLIER_ID_ASC = "SUPPLIER_ID_ASC",
  SUPPLIER_ID_DESC = "SUPPLIER_ID_DESC",
  TA_NUMBER_ASC = "TA_NUMBER_ASC",
  TA_NUMBER_DESC = "TA_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_DATE_ASC = "TRAVEL_AUTHORIZATION_DATE_ASC",
  TRAVEL_AUTHORIZATION_DATE_DESC = "TRAVEL_AUTHORIZATION_DATE_DESC",
  TRAVEL_AUTHORIZATION_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "TRAVEL_AUTHORIZATION_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  TRAVEL_AUTHORIZATION_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "TRAVEL_AUTHORIZATION_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  TRAVEL_AUTHORIZATION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_REVISION_HISTORIES_BY_TA_GROUP_ID__COUNT_ASC = "TRAVEL_AUTHORIZATION_REVISION_HISTORIES_BY_TA_GROUP_ID__COUNT_ASC",
  TRAVEL_AUTHORIZATION_REVISION_HISTORIES_BY_TA_GROUP_ID__COUNT_DESC = "TRAVEL_AUTHORIZATION_REVISION_HISTORIES_BY_TA_GROUP_ID__COUNT_DESC",
  TRAVEL_AUTHORIZATION_TIME_ASC = "TRAVEL_AUTHORIZATION_TIME_ASC",
  TRAVEL_AUTHORIZATION_TIME_DESC = "TRAVEL_AUTHORIZATION_TIME_DESC",
  TRIPS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_ASC = "TRIPS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_ASC",
  TRIPS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_DESC = "TRIPS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_DESC",
  TRIP_BREAKDOWNS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_ASC = "TRIP_BREAKDOWNS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_ASC",
  TRIP_BREAKDOWNS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_DESC = "TRIP_BREAKDOWNS_BY_TRAVEL_AUTHORIZATION_ID__COUNT_DESC",
  TRIP_MOVEMENT_ID_ASC = "TRIP_MOVEMENT_ID_ASC",
  TRIP_MOVEMENT_ID_DESC = "TRIP_MOVEMENT_ID_DESC",
  TRIP_PURPOSE_ASC = "TRIP_PURPOSE_ASC",
  TRIP_PURPOSE_DESC = "TRIP_PURPOSE_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_ASC = "USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_ASC",
  USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_DESC = "USER_BY_REVISED_USER_ID__DIGITAL_SIGNATURE_DESC",
  USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_ASC = "USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_ASC",
  USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_DESC = "USER_BY_REVISED_USER_ID__EMAIL_ACCOUNT_DESC",
  USER_BY_REVISED_USER_ID__ID_ASC = "USER_BY_REVISED_USER_ID__ID_ASC",
  USER_BY_REVISED_USER_ID__ID_DESC = "USER_BY_REVISED_USER_ID__ID_DESC",
  USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC = "USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC",
  USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC = "USER_BY_REVISED_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC",
  USER_BY_REVISED_USER_ID__IS_DRAFT_ASC = "USER_BY_REVISED_USER_ID__IS_DRAFT_ASC",
  USER_BY_REVISED_USER_ID__IS_DRAFT_DESC = "USER_BY_REVISED_USER_ID__IS_DRAFT_DESC",
  USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_ASC = "USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_ASC",
  USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_DESC = "USER_BY_REVISED_USER_ID__IS_RESTRICTED_USER_DESC",
  USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_ASC = "USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_DESC = "USER_BY_REVISED_USER_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_BY_REVISED_USER_ID__LANGUAGE_ID_ASC = "USER_BY_REVISED_USER_ID__LANGUAGE_ID_ASC",
  USER_BY_REVISED_USER_ID__LANGUAGE_ID_DESC = "USER_BY_REVISED_USER_ID__LANGUAGE_ID_DESC",
  USER_BY_REVISED_USER_ID__NAME_ASC = "USER_BY_REVISED_USER_ID__NAME_ASC",
  USER_BY_REVISED_USER_ID__NAME_DESC = "USER_BY_REVISED_USER_ID__NAME_DESC",
  USER_BY_REVISED_USER_ID__TENANT_ID_ASC = "USER_BY_REVISED_USER_ID__TENANT_ID_ASC",
  USER_BY_REVISED_USER_ID__TENANT_ID_DESC = "USER_BY_REVISED_USER_ID__TENANT_ID_DESC",
  USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_BY_REVISED_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  USER_BY_REVISED_USER_ID___CREATED_BY_USERID_ASC = "USER_BY_REVISED_USER_ID___CREATED_BY_USERID_ASC",
  USER_BY_REVISED_USER_ID___CREATED_BY_USERID_DESC = "USER_BY_REVISED_USER_ID___CREATED_BY_USERID_DESC",
  USER_BY_REVISED_USER_ID___CREATED_DATE_ASC = "USER_BY_REVISED_USER_ID___CREATED_DATE_ASC",
  USER_BY_REVISED_USER_ID___CREATED_DATE_DESC = "USER_BY_REVISED_USER_ID___CREATED_DATE_DESC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_ASC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_ASC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_DESC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_DESC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_ASC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_ASC",
  USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_DESC = "USER_BY_REVISED_USER_ID___IS_ACTIVE_TENANT_USER_DESC",
  USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_BY_REVISED_USER_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_BY_REVISED_USER_ID___IS_APPROVED_ASC = "USER_BY_REVISED_USER_ID___IS_APPROVED_ASC",
  USER_BY_REVISED_USER_ID___IS_APPROVED_DESC = "USER_BY_REVISED_USER_ID___IS_APPROVED_DESC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_ASC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_ASC",
  USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_DESC = "USER_BY_REVISED_USER_ID___IS_AUTHORIZED_USER_DESC",
  USER_BY_REVISED_USER_ID___IS_DELETABLE_ASC = "USER_BY_REVISED_USER_ID___IS_DELETABLE_ASC",
  USER_BY_REVISED_USER_ID___IS_DELETABLE_DESC = "USER_BY_REVISED_USER_ID___IS_DELETABLE_DESC",
  USER_BY_REVISED_USER_ID___IS_DEPRECATED_ASC = "USER_BY_REVISED_USER_ID___IS_DEPRECATED_ASC",
  USER_BY_REVISED_USER_ID___IS_DEPRECATED_DESC = "USER_BY_REVISED_USER_ID___IS_DEPRECATED_DESC",
  USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_ASC = "USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_ASC",
  USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_DESC = "USER_BY_REVISED_USER_ID___IS_EMAIL_EXISTS_DESC",
  USER_BY_REVISED_USER_ID___IS_MODIFIED_ASC = "USER_BY_REVISED_USER_ID___IS_MODIFIED_ASC",
  USER_BY_REVISED_USER_ID___IS_MODIFIED_DESC = "USER_BY_REVISED_USER_ID___IS_MODIFIED_DESC",
  USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_BY_REVISED_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_ASC = "USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_ASC",
  USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_DESC = "USER_BY_REVISED_USER_ID___IS_TENANT_USER_EXISTS_DESC",
  USER_BY_REVISED_USER_ID___IS_UPDATABLE_ASC = "USER_BY_REVISED_USER_ID___IS_UPDATABLE_ASC",
  USER_BY_REVISED_USER_ID___IS_UPDATABLE_DESC = "USER_BY_REVISED_USER_ID___IS_UPDATABLE_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_APPROVER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_APPROVER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_OBSERVER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_BY_REVISED_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_REQUESTER_DESC",
  USER_BY_REVISED_USER_ID___IS_USER_REVOKER_ASC = "USER_BY_REVISED_USER_ID___IS_USER_REVOKER_ASC",
  USER_BY_REVISED_USER_ID___IS_USER_REVOKER_DESC = "USER_BY_REVISED_USER_ID___IS_USER_REVOKER_DESC",
  USER_BY_REVISED_USER_ID___IS_VALIDATED_ASC = "USER_BY_REVISED_USER_ID___IS_VALIDATED_ASC",
  USER_BY_REVISED_USER_ID___IS_VALIDATED_DESC = "USER_BY_REVISED_USER_ID___IS_VALIDATED_DESC",
  USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_ASC = "USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_ASC",
  USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_DESC = "USER_BY_REVISED_USER_ID___IS_WAITING_APPROVAL_DESC",
  USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_ASC = "USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_ASC",
  USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_DESC = "USER_BY_REVISED_USER_ID___IS_WAITING_VALIDATION_DESC",
  USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_ASC = "USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_ASC",
  USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_DESC = "USER_BY_REVISED_USER_ID___ROW_TIMESTAMP_DESC",
  USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_ASC = "USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_ASC",
  USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_DESC = "USER_BY_REVISED_USER_ID___STATUS_TYPE_ID_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  VOIDED_DATE_ASC = "VOIDED_DATE_ASC",
  VOIDED_DATE_DESC = "VOIDED_DATE_DESC",
  _ACCOMMODATION_AMOUNT_ASC = "_ACCOMMODATION_AMOUNT_ASC",
  _ACCOMMODATION_AMOUNT_DESC = "_ACCOMMODATION_AMOUNT_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _AIRFARE_AMOUNT_ASC = "_AIRFARE_AMOUNT_ASC",
  _AIRFARE_AMOUNT_DESC = "_AIRFARE_AMOUNT_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_BY_USER_NAME_ASC = "_CREATED_BY_USER_NAME_ASC",
  _CREATED_BY_USER_NAME_DESC = "_CREATED_BY_USER_NAME_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _INCIDENTAL_AMOUNT_ASC = "_INCIDENTAL_AMOUNT_ASC",
  _INCIDENTAL_AMOUNT_DESC = "_INCIDENTAL_AMOUNT_DESC",
  _IS_ACCOMMODATION_EXIST_ASC = "_IS_ACCOMMODATION_EXIST_ASC",
  _IS_ACCOMMODATION_EXIST_DESC = "_IS_ACCOMMODATION_EXIST_DESC",
  _IS_ACCOUNTING_ENTRY_ASC = "_IS_ACCOUNTING_ENTRY_ASC",
  _IS_ACCOUNTING_ENTRY_DESC = "_IS_ACCOUNTING_ENTRY_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_AIRFARE_EXIST_ASC = "_IS_AIRFARE_EXIST_ASC",
  _IS_AIRFARE_EXIST_DESC = "_IS_AIRFARE_EXIST_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_CLONING_ALLOWED_ASC = "_IS_CLONING_ALLOWED_ASC",
  _IS_CLONING_ALLOWED_DESC = "_IS_CLONING_ALLOWED_DESC",
  _IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC = "_IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC",
  _IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC = "_IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC",
  _IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "_IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  _IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "_IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "_IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  _IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "_IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_ENVIRONMENTALS_EXIST_ASC = "_IS_ENVIRONMENTALS_EXIST_ASC",
  _IS_ENVIRONMENTALS_EXIST_DESC = "_IS_ENVIRONMENTALS_EXIST_DESC",
  _IS_FLAGGED_HISTORY_ASC = "_IS_FLAGGED_HISTORY_ASC",
  _IS_FLAGGED_HISTORY_DESC = "_IS_FLAGGED_HISTORY_DESC",
  _IS_HISTORY_ASC = "_IS_HISTORY_ASC",
  _IS_HISTORY_DESC = "_IS_HISTORY_DESC",
  _IS_INCIDENTAL_EXIST_ASC = "_IS_INCIDENTAL_EXIST_ASC",
  _IS_INCIDENTAL_EXIST_DESC = "_IS_INCIDENTAL_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC = "_IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC",
  _IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC = "_IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_OVER_BUDGET_ASC = "_IS_OVER_BUDGET_ASC",
  _IS_OVER_BUDGET_DESC = "_IS_OVER_BUDGET_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_REVISABLE_ASC = "_IS_REVISABLE_ASC",
  _IS_REVISABLE_DESC = "_IS_REVISABLE_DESC",
  _IS_REVISED_ASC = "_IS_REVISED_ASC",
  _IS_REVISED_DESC = "_IS_REVISED_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_STAMP_ALLOWED_ASC = "_IS_STAMP_ALLOWED_ASC",
  _IS_STAMP_ALLOWED_DESC = "_IS_STAMP_ALLOWED_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_TRAIN_EXIST_ASC = "_IS_TRAIN_EXIST_ASC",
  _IS_TRAIN_EXIST_DESC = "_IS_TRAIN_EXIST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_TRANSACTION_ENTRY_ASC = "_IS_TRANSACTION_ENTRY_ASC",
  _IS_TRANSACTION_ENTRY_DESC = "_IS_TRANSACTION_ENTRY_DESC",
  _IS_TRANSPORTATION_EXIST_ASC = "_IS_TRANSPORTATION_EXIST_ASC",
  _IS_TRANSPORTATION_EXIST_DESC = "_IS_TRANSPORTATION_EXIST_DESC",
  _IS_TRIP_MOVEMENT_ALLOWED_ASC = "_IS_TRIP_MOVEMENT_ALLOWED_ASC",
  _IS_TRIP_MOVEMENT_ALLOWED_DESC = "_IS_TRIP_MOVEMENT_ALLOWED_DESC",
  _IS_TRIP_PREFERRED_AREA_ALLOWED_ASC = "_IS_TRIP_PREFERRED_AREA_ALLOWED_ASC",
  _IS_TRIP_PREFERRED_AREA_ALLOWED_DESC = "_IS_TRIP_PREFERRED_AREA_ALLOWED_DESC",
  _IS_UNSTAMP_ALLOWED_ASC = "_IS_UNSTAMP_ALLOWED_ASC",
  _IS_UNSTAMP_ALLOWED_DESC = "_IS_UNSTAMP_ALLOWED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_VEHICLE_RENTAL_EXIST_ASC = "_IS_VEHICLE_RENTAL_EXIST_ASC",
  _IS_VEHICLE_RENTAL_EXIST_DESC = "_IS_VEHICLE_RENTAL_EXIST_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _OVERRIDING_LANGUAGE_ID_ASC = "_OVERRIDING_LANGUAGE_ID_ASC",
  _OVERRIDING_LANGUAGE_ID_DESC = "_OVERRIDING_LANGUAGE_ID_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _PO_STATUS_ASC = "_PO_STATUS_ASC",
  _PO_STATUS_DESC = "_PO_STATUS_DESC",
  _PURCHASE_ORDER_CREATED_ASC = "_PURCHASE_ORDER_CREATED_ASC",
  _PURCHASE_ORDER_CREATED_DESC = "_PURCHASE_ORDER_CREATED_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _REVISED_DATE_ASC = "_REVISED_DATE_ASC",
  _REVISED_DATE_DESC = "_REVISED_DATE_DESC",
  _REVISED_USER_ID_ASC = "_REVISED_USER_ID_ASC",
  _REVISED_USER_ID_DESC = "_REVISED_USER_ID_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _SUPPLIERS_ASC = "_SUPPLIERS_ASC",
  _SUPPLIERS_DESC = "_SUPPLIERS_DESC",
  _TRAIN_AMOUNT_ASC = "_TRAIN_AMOUNT_ASC",
  _TRAIN_AMOUNT_DESC = "_TRAIN_AMOUNT_DESC",
  _TRANSPORTATION_AMOUNT_ASC = "_TRANSPORTATION_AMOUNT_ASC",
  _TRANSPORTATION_AMOUNT_DESC = "_TRANSPORTATION_AMOUNT_DESC",
  _TRAVELER_REFERENCE_ASC = "_TRAVELER_REFERENCE_ASC",
  _TRAVELER_REFERENCE_DESC = "_TRAVELER_REFERENCE_DESC",
  _USER_GROUP_ID_ASC = "_USER_GROUP_ID_ASC",
  _USER_GROUP_ID_DESC = "_USER_GROUP_ID_DESC",
  _VEHICLE_RENTAL_AMOUNT_ASC = "_VEHICLE_RENTAL_AMOUNT_ASC",
  _VEHICLE_RENTAL_AMOUNT_DESC = "_VEHICLE_RENTAL_AMOUNT_DESC",
}

/**
 * Methods to use when ordering `Traveler`.
 */
export enum TravelersOrderBy {
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_TRAVELER_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  COMPANY_OR_LAST_NAME_ASC = "COMPANY_OR_LAST_NAME_ASC",
  COMPANY_OR_LAST_NAME_DESC = "COMPANY_OR_LAST_NAME_DESC",
  CUSTOMER_ACCOUNT_ASC = "CUSTOMER_ACCOUNT_ASC",
  CUSTOMER_ACCOUNT_DESC = "CUSTOMER_ACCOUNT_DESC",
  DATE_OF_BIRTH_ASC = "DATE_OF_BIRTH_ASC",
  DATE_OF_BIRTH_DESC = "DATE_OF_BIRTH_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  FREQUENT_FLYER_NUMBER_ASC = "FREQUENT_FLYER_NUMBER_ASC",
  FREQUENT_FLYER_NUMBER_DESC = "FREQUENT_FLYER_NUMBER_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_BUSINESS_CLASS_PREFERRED_ASC = "IS_BUSINESS_CLASS_PREFERRED_ASC",
  IS_BUSINESS_CLASS_PREFERRED_DESC = "IS_BUSINESS_CLASS_PREFERRED_DESC",
  IS_CHARTER_CLASS_PREFERRED_ASC = "IS_CHARTER_CLASS_PREFERRED_ASC",
  IS_CHARTER_CLASS_PREFERRED_DESC = "IS_CHARTER_CLASS_PREFERRED_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_ECONOMY_CLASS_PREFERRED_ASC = "IS_ECONOMY_CLASS_PREFERRED_ASC",
  IS_ECONOMY_CLASS_PREFERRED_DESC = "IS_ECONOMY_CLASS_PREFERRED_DESC",
  IS_FIRST_CLASS_PREFERRED_ASC = "IS_FIRST_CLASS_PREFERRED_ASC",
  IS_FIRST_CLASS_PREFERRED_DESC = "IS_FIRST_CLASS_PREFERRED_DESC",
  IS_ISLE_SEAT_PREFERRED_ASC = "IS_ISLE_SEAT_PREFERRED_ASC",
  IS_ISLE_SEAT_PREFERRED_DESC = "IS_ISLE_SEAT_PREFERRED_DESC",
  IS_PREMIUM_CLASS_PREFERRED_ASC = "IS_PREMIUM_CLASS_PREFERRED_ASC",
  IS_PREMIUM_CLASS_PREFERRED_DESC = "IS_PREMIUM_CLASS_PREFERRED_DESC",
  IS_WINDOW_SEAT_PREFERRED_ASC = "IS_WINDOW_SEAT_PREFERRED_ASC",
  IS_WINDOW_SEAT_PREFERRED_DESC = "IS_WINDOW_SEAT_PREFERRED_DESC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  MOVEMENT_TRAVELERS_BY_TRAVELER_ID__COUNT_ASC = "MOVEMENT_TRAVELERS_BY_TRAVELER_ID__COUNT_ASC",
  MOVEMENT_TRAVELERS_BY_TRAVELER_ID__COUNT_DESC = "MOVEMENT_TRAVELERS_BY_TRAVELER_ID__COUNT_DESC",
  NAME_ALIAS_ASC = "NAME_ALIAS_ASC",
  NAME_ALIAS_DESC = "NAME_ALIAS_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  TRAVELER_ADDRESSES_BY_TRAVELER_ID__COUNT_ASC = "TRAVELER_ADDRESSES_BY_TRAVELER_ID__COUNT_ASC",
  TRAVELER_ADDRESSES_BY_TRAVELER_ID__COUNT_DESC = "TRAVELER_ADDRESSES_BY_TRAVELER_ID__COUNT_DESC",
  TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__COMPANY_OR_LAST_NAME_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__COMPANY_OR_LAST_NAME_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__COMPANY_OR_LAST_NAME_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__COMPANY_OR_LAST_NAME_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FIRST_NAME_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FIRST_NAME_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FIRST_NAME_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FIRST_NAME_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FREQUENT_FLYER_NUMBER_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FREQUENT_FLYER_NUMBER_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FREQUENT_FLYER_NUMBER_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__FREQUENT_FLYER_NUMBER_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__ID_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__ID_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__ID_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__ID_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_CHARTER_CLASS_PREFERRED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_CHARTER_CLASS_PREFERRED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_CHARTER_CLASS_PREFERRED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_CHARTER_CLASS_PREFERRED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_DRAFT_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_DRAFT_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_DRAFT_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_DRAFT_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_FIRST_CLASS_PREFERRED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_FIRST_CLASS_PREFERRED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ISLE_SEAT_PREFERRED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ISLE_SEAT_PREFERRED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_PREMIUM_CLASS_PREFERRED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_PREMIUM_CLASS_PREFERRED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_PREMIUM_CLASS_PREFERRED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_PREMIUM_CLASS_PREFERRED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVEL_POLICY_ID_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVEL_POLICY_ID_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVEL_POLICY_ID_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__TRAVEL_POLICY_ID_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__USER_OCCUPATION_TITLE_ID_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__USER_OCCUPATION_TITLE_ID_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__USER_OCCUPATION_TITLE_ID_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__USER_OCCUPATION_TITLE_ID_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__VENDOR_NUMBER_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__VENDOR_NUMBER_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__VENDOR_NUMBER_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID__VENDOR_NUMBER_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_BY_USERID_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_BY_USERID_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_BY_USERID_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_BY_USERID_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_DATE_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_DATE_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_DATE_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___CREATED_DATE_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___FULL_NAME_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___FULL_NAME_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___FULL_NAME_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___FULL_NAME_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_ACTIVE_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_ACTIVE_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_ACTIVE_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_ACTIVE_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_APPROVED_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_APPROVED_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_APPROVED_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_APPROVED_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_WAITING_APPROVAL_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_WAITING_APPROVAL_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_WAITING_APPROVAL_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___IS_WAITING_APPROVAL_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___ROW_TIMESTAMP_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___ROW_TIMESTAMP_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___ROW_TIMESTAMP_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___ROW_TIMESTAMP_DESC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___SORT_NAME_ASC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___SORT_NAME_ASC",
  TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___SORT_NAME_DESC = "TRAVELER_COMPANION_MASTER_BY_TRAVELER_COMPANION_MASTER_ID___SORT_NAME_DESC",
  TRAVELER_COMPANION_MASTER_ID_ASC = "TRAVELER_COMPANION_MASTER_ID_ASC",
  TRAVELER_COMPANION_MASTER_ID_DESC = "TRAVELER_COMPANION_MASTER_ID_DESC",
  TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_DIETARY_REQUIREMENTS_BY_TRAVELER_ID__COUNT_ASC = "TRAVELER_DIETARY_REQUIREMENTS_BY_TRAVELER_ID__COUNT_ASC",
  TRAVELER_DIETARY_REQUIREMENTS_BY_TRAVELER_ID__COUNT_DESC = "TRAVELER_DIETARY_REQUIREMENTS_BY_TRAVELER_ID__COUNT_DESC",
  TRAVELER_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "TRAVELER_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  TRAVELER_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "TRAVELER_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  TRAVELER_POLICY_ALLOWANCES_BY_TRAVELER_ID__COUNT_ASC = "TRAVELER_POLICY_ALLOWANCES_BY_TRAVELER_ID__COUNT_ASC",
  TRAVELER_POLICY_ALLOWANCES_BY_TRAVELER_ID__COUNT_DESC = "TRAVELER_POLICY_ALLOWANCES_BY_TRAVELER_ID__COUNT_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_DESC",
  TRAVEL_POLICY_ID_ASC = "TRAVEL_POLICY_ID_ASC",
  TRAVEL_POLICY_ID_DESC = "TRAVEL_POLICY_ID_DESC",
  TRIPS_BY_TRAVELER_ID__COUNT_ASC = "TRIPS_BY_TRAVELER_ID__COUNT_ASC",
  TRIPS_BY_TRAVELER_ID__COUNT_DESC = "TRIPS_BY_TRAVELER_ID__COUNT_DESC",
  TRIP_BREAKDOWNS_BY_PRIMARY_TRAVELER_ID__COUNT_ASC = "TRIP_BREAKDOWNS_BY_PRIMARY_TRAVELER_ID__COUNT_ASC",
  TRIP_BREAKDOWNS_BY_PRIMARY_TRAVELER_ID__COUNT_DESC = "TRIP_BREAKDOWNS_BY_PRIMARY_TRAVELER_ID__COUNT_DESC",
  TRIP_BREAKDOWNS_BY_TRAVELER_ID__COUNT_ASC = "TRIP_BREAKDOWNS_BY_TRAVELER_ID__COUNT_ASC",
  TRIP_BREAKDOWNS_BY_TRAVELER_ID__COUNT_DESC = "TRIP_BREAKDOWNS_BY_TRAVELER_ID__COUNT_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_DESC",
  USER_OCCUPATION_TITLE_ID_ASC = "USER_OCCUPATION_TITLE_ID_ASC",
  USER_OCCUPATION_TITLE_ID_DESC = "USER_OCCUPATION_TITLE_ID_DESC",
  VENDOR_NUMBER_ASC = "VENDOR_NUMBER_ASC",
  VENDOR_NUMBER_DESC = "VENDOR_NUMBER_DESC",
  _COMPANY_OR_POOLING_ID_ASC = "_COMPANY_OR_POOLING_ID_ASC",
  _COMPANY_OR_POOLING_ID_DESC = "_COMPANY_OR_POOLING_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _FULL_NAME_ASC = "_FULL_NAME_ASC",
  _FULL_NAME_DESC = "_FULL_NAME_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_ALIAS_INVOKED_ASC = "_IS_ALIAS_INVOKED_ASC",
  _IS_ALIAS_INVOKED_DESC = "_IS_ALIAS_INVOKED_DESC",
  _IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "_IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  _IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "_IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MESSAGES_EXIST_ASC = "_IS_MESSAGES_EXIST_ASC",
  _IS_MESSAGES_EXIST_DESC = "_IS_MESSAGES_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROTECTED_ASC = "_IS_PROTECTED_ASC",
  _IS_PROTECTED_DESC = "_IS_PROTECTED_DESC",
  _IS_PROTECTED_VIEW_ASC = "_IS_PROTECTED_VIEW_ASC",
  _IS_PROTECTED_VIEW_DESC = "_IS_PROTECTED_VIEW_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_UPDATE_TRAVELER_OVERRIDE_ASC = "_IS_UPDATE_TRAVELER_OVERRIDE_ASC",
  _IS_UPDATE_TRAVELER_OVERRIDE_DESC = "_IS_UPDATE_TRAVELER_OVERRIDE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _SORT_NAME_ASC = "_SORT_NAME_ASC",
  _SORT_NAME_DESC = "_SORT_NAME_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _USER_GROUP_ID_ASC = "_USER_GROUP_ID_ASC",
  _USER_GROUP_ID_DESC = "_USER_GROUP_ID_DESC",
}

/**
 * Methods to use when ordering `TripBreakdown`.
 */
export enum TripBreakdownsOrderBy {
  ACCOMMODATION_AMOUNT_ASC = "ACCOMMODATION_AMOUNT_ASC",
  ACCOMMODATION_AMOUNT_DESC = "ACCOMMODATION_AMOUNT_DESC",
  ACCOMMODATION_BUDGET_AMOUNT_ASC = "ACCOMMODATION_BUDGET_AMOUNT_ASC",
  ACCOMMODATION_BUDGET_AMOUNT_DESC = "ACCOMMODATION_BUDGET_AMOUNT_DESC",
  ACCOUNT_REFERENCE_ASC = "ACCOUNT_REFERENCE_ASC",
  ACCOUNT_REFERENCE_DESC = "ACCOUNT_REFERENCE_DESC",
  AGENCY_AMOUNT_ASC = "AGENCY_AMOUNT_ASC",
  AGENCY_AMOUNT_DESC = "AGENCY_AMOUNT_DESC",
  AGENCY_BUDGET_AMOUNT_ASC = "AGENCY_BUDGET_AMOUNT_ASC",
  AGENCY_BUDGET_AMOUNT_DESC = "AGENCY_BUDGET_AMOUNT_DESC",
  AIRFARE_AMOUNT_ASC = "AIRFARE_AMOUNT_ASC",
  AIRFARE_AMOUNT_DESC = "AIRFARE_AMOUNT_DESC",
  AIRFARE_BUDGET_AMOUNT_ASC = "AIRFARE_BUDGET_AMOUNT_ASC",
  AIRFARE_BUDGET_AMOUNT_DESC = "AIRFARE_BUDGET_AMOUNT_DESC",
  BOOKING_CREATED_ASC = "BOOKING_CREATED_ASC",
  BOOKING_CREATED_DESC = "BOOKING_CREATED_DESC",
  BUDGET_AMOUNT_ASC = "BUDGET_AMOUNT_ASC",
  BUDGET_AMOUNT_DESC = "BUDGET_AMOUNT_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMMENTS_ASC = "COMMENTS_ASC",
  COMMENTS_DESC = "COMMENTS_DESC",
  COMMENT_ASC = "COMMENT_ASC",
  COMMENT_DESC = "COMMENT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__BUSINESS_AREA_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__CHART_OF_ACCOUNT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_ADDRESS_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COMPANY_CODE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__COST_CENTER_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_FIXED_CC_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_PRODUCTION_OFFICE_DESCRIPTION_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__EMAIL_REPLY_TO_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__GL_ACCOUNT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_COMPANY_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___COMPANY_BUSINESS_UNIT_ID_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_HISTORY_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PRODUCTION_BUSINESS_UNIT_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_PROTECTED_VIEW_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC",
  COMPANY_BY_COMPANY_ID__ID_ASC = "COMPANY_BY_COMPANY_ID__ID_ASC",
  COMPANY_BY_COMPANY_ID__ID_DESC = "COMPANY_BY_COMPANY_ID__ID_DESC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC",
  COMPANY_BY_COMPANY_ID__NAME_ASC = "COMPANY_BY_COMPANY_ID__NAME_ASC",
  COMPANY_BY_COMPANY_ID__NAME_DESC = "COMPANY_BY_COMPANY_ID__NAME_DESC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_ASC = "COMPANY_BY_COMPANY_ID__TENANT_ID_ASC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_DESC = "COMPANY_BY_COMPANY_ID__TENANT_ID_DESC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_DOC_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_PO_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TA_SELECTABLE_DESC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_ASC",
  COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC = "COMPANY_DEPARTMENT_BY_DEPARTMENT_ID___IS_TRANS_SELECTABLE_DESC",
  CONFIRMATION_CODE_ASC = "CONFIRMATION_CODE_ASC",
  CONFIRMATION_CODE_DESC = "CONFIRMATION_CODE_DESC",
  CONTROL_TOTAL_AMOUNT_ASC = "CONTROL_TOTAL_AMOUNT_ASC",
  CONTROL_TOTAL_AMOUNT_DESC = "CONTROL_TOTAL_AMOUNT_DESC",
  CORPORATE_WORKGROUP_ID_ASC = "CORPORATE_WORKGROUP_ID_ASC",
  CORPORATE_WORKGROUP_ID_DESC = "CORPORATE_WORKGROUP_ID_DESC",
  CORPORATE_WORKGROUP_NAME_ASC = "CORPORATE_WORKGROUP_NAME_ASC",
  CORPORATE_WORKGROUP_NAME_DESC = "CORPORATE_WORKGROUP_NAME_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INCIDENTAL_AMOUNT_ASC = "INCIDENTAL_AMOUNT_ASC",
  INCIDENTAL_AMOUNT_DESC = "INCIDENTAL_AMOUNT_DESC",
  INCIDENTAL_BUDGET_AMOUNT_ASC = "INCIDENTAL_BUDGET_AMOUNT_ASC",
  INCIDENTAL_BUDGET_AMOUNT_DESC = "INCIDENTAL_BUDGET_AMOUNT_DESC",
  LINKED_ENTITY_ID_ASC = "LINKED_ENTITY_ID_ASC",
  LINKED_ENTITY_ID_DESC = "LINKED_ENTITY_ID_DESC",
  NATURAL = "NATURAL",
  OTHER_AMOUNT_ASC = "OTHER_AMOUNT_ASC",
  OTHER_AMOUNT_DESC = "OTHER_AMOUNT_DESC",
  OTHER_BUDGET_AMOUNT_ASC = "OTHER_BUDGET_AMOUNT_ASC",
  OTHER_BUDGET_AMOUNT_DESC = "OTHER_BUDGET_AMOUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REFERENCE_ITEM_TYPES_ASC = "REFERENCE_ITEM_TYPES_ASC",
  REFERENCE_ITEM_TYPES_DESC = "REFERENCE_ITEM_TYPES_DESC",
  ROW_SECURITY_ID_ASC = "ROW_SECURITY_ID_ASC",
  ROW_SECURITY_ID_DESC = "ROW_SECURITY_ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_ROW_SECURITY_ID___IS_DEPRECATED_DESC",
  SORT_TRAVELER_NAME_ASC = "SORT_TRAVELER_NAME_ASC",
  SORT_TRAVELER_NAME_DESC = "SORT_TRAVELER_NAME_DESC",
  SPECIAL_INSTRUCTIONS_ASC = "SPECIAL_INSTRUCTIONS_ASC",
  SPECIAL_INSTRUCTIONS_DESC = "SPECIAL_INSTRUCTIONS_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_TA_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_TA_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  TRAIN_AMOUNT_ASC = "TRAIN_AMOUNT_ASC",
  TRAIN_AMOUNT_DESC = "TRAIN_AMOUNT_DESC",
  TRAIN_BUDGET_AMOUNT_ASC = "TRAIN_BUDGET_AMOUNT_ASC",
  TRAIN_BUDGET_AMOUNT_DESC = "TRAIN_BUDGET_AMOUNT_DESC",
  TRANSPORTATION_AMOUNT_ASC = "TRANSPORTATION_AMOUNT_ASC",
  TRANSPORTATION_AMOUNT_DESC = "TRANSPORTATION_AMOUNT_DESC",
  TRANSPORTATION_BUDGET_AMOUNT_ASC = "TRANSPORTATION_BUDGET_AMOUNT_ASC",
  TRANSPORTATION_BUDGET_AMOUNT_DESC = "TRANSPORTATION_BUDGET_AMOUNT_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__COMPANY_OR_LAST_NAME_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__COMPANY_OR_LAST_NAME_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__COMPANY_OR_LAST_NAME_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__COMPANY_OR_LAST_NAME_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__CUSTOMER_ACCOUNT_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__CUSTOMER_ACCOUNT_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__CUSTOMER_ACCOUNT_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__CUSTOMER_ACCOUNT_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__DATE_OF_BIRTH_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__DATE_OF_BIRTH_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__DATE_OF_BIRTH_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__DATE_OF_BIRTH_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__FIRST_NAME_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__FIRST_NAME_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__FIRST_NAME_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__FIRST_NAME_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_DRAFT_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_DRAFT_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_DRAFT_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_DRAFT_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__NAME_ALIAS_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__NAME_ALIAS_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__NAME_ALIAS_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__NAME_ALIAS_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__ROW_SECURITY_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__ROW_SECURITY_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__ROW_SECURITY_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__ROW_SECURITY_ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVEL_POLICY_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVEL_POLICY_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVEL_POLICY_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__TRAVEL_POLICY_ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__VENDOR_NUMBER_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__VENDOR_NUMBER_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID__VENDOR_NUMBER_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID__VENDOR_NUMBER_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_BY_USERID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_BY_USERID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_BY_USERID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_BY_USERID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_DATE_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_DATE_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_DATE_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___CREATED_DATE_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___FULL_NAME_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___FULL_NAME_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___FULL_NAME_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___FULL_NAME_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ACTIVE_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ACTIVE_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ACTIVE_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ACTIVE_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_INVOKED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_INVOKED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_INVOKED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_INVOKED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_APPROVED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DELETABLE_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DELETABLE_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DELETABLE_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DELETABLE_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DEPRECATED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DEPRECATED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DEPRECATED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DEPRECATED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MESSAGES_EXIST_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MESSAGES_EXIST_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MESSAGES_EXIST_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MESSAGES_EXIST_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MODIFIED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MODIFIED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MODIFIED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_MODIFIED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_NOTES_EXIST_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_NOTES_EXIST_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_NOTES_EXIST_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_NOTES_EXIST_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_VIEW_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_VIEW_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_VIEW_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_PROTECTED_VIEW_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_TAGS_EXIST_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_TAGS_EXIST_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_TAGS_EXIST_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_TAGS_EXIST_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATABLE_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATABLE_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATABLE_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATABLE_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVER_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVER_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVER_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_APPROVER_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_OBSERVER_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_OBSERVER_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_OBSERVER_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_OBSERVER_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REQUESTER_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REQUESTER_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REQUESTER_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REQUESTER_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REVOKER_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REVOKER_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REVOKER_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_USER_REVOKER_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_VALIDATED_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_VALIDATED_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_VALIDATED_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_VALIDATED_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_APPROVAL_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_APPROVAL_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_APPROVAL_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_APPROVAL_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_VALIDATION_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_VALIDATION_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_VALIDATION_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___IS_WAITING_VALIDATION_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___PENDING_APPROVERS_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___PENDING_APPROVERS_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___PENDING_APPROVERS_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___PENDING_APPROVERS_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___ROW_TIMESTAMP_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___ROW_TIMESTAMP_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___ROW_TIMESTAMP_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___ROW_TIMESTAMP_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___SEARCH_DOCUMENT_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___SEARCH_DOCUMENT_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___SEARCH_DOCUMENT_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___SEARCH_DOCUMENT_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___SORT_NAME_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___SORT_NAME_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___SORT_NAME_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___SORT_NAME_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___STATUS_TYPE_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___STATUS_TYPE_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___STATUS_TYPE_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___STATUS_TYPE_ID_DESC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___USER_GROUP_ID_ASC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___USER_GROUP_ID_ASC",
  TRAVELER_BY_PRIMARY_TRAVELER_ID___USER_GROUP_ID_DESC = "TRAVELER_BY_PRIMARY_TRAVELER_ID___USER_GROUP_ID_DESC",
  TRAVELER_BY_TRAVELER_ID__COMPANY_OR_LAST_NAME_ASC = "TRAVELER_BY_TRAVELER_ID__COMPANY_OR_LAST_NAME_ASC",
  TRAVELER_BY_TRAVELER_ID__COMPANY_OR_LAST_NAME_DESC = "TRAVELER_BY_TRAVELER_ID__COMPANY_OR_LAST_NAME_DESC",
  TRAVELER_BY_TRAVELER_ID__CUSTOMER_ACCOUNT_ASC = "TRAVELER_BY_TRAVELER_ID__CUSTOMER_ACCOUNT_ASC",
  TRAVELER_BY_TRAVELER_ID__CUSTOMER_ACCOUNT_DESC = "TRAVELER_BY_TRAVELER_ID__CUSTOMER_ACCOUNT_DESC",
  TRAVELER_BY_TRAVELER_ID__DATE_OF_BIRTH_ASC = "TRAVELER_BY_TRAVELER_ID__DATE_OF_BIRTH_ASC",
  TRAVELER_BY_TRAVELER_ID__DATE_OF_BIRTH_DESC = "TRAVELER_BY_TRAVELER_ID__DATE_OF_BIRTH_DESC",
  TRAVELER_BY_TRAVELER_ID__FIRST_NAME_ASC = "TRAVELER_BY_TRAVELER_ID__FIRST_NAME_ASC",
  TRAVELER_BY_TRAVELER_ID__FIRST_NAME_DESC = "TRAVELER_BY_TRAVELER_ID__FIRST_NAME_DESC",
  TRAVELER_BY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_ASC = "TRAVELER_BY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_ASC",
  TRAVELER_BY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_DESC = "TRAVELER_BY_TRAVELER_ID__FREQUENT_FLYER_NUMBER_DESC",
  TRAVELER_BY_TRAVELER_ID__ID_ASC = "TRAVELER_BY_TRAVELER_ID__ID_ASC",
  TRAVELER_BY_TRAVELER_ID__ID_DESC = "TRAVELER_BY_TRAVELER_ID__ID_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "TRAVELER_BY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "TRAVELER_BY_TRAVELER_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_ASC = "TRAVELER_BY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_DESC = "TRAVELER_BY_TRAVELER_ID__IS_CHARTER_CLASS_PREFERRED_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_DRAFT_ASC = "TRAVELER_BY_TRAVELER_ID__IS_DRAFT_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_DRAFT_DESC = "TRAVELER_BY_TRAVELER_ID__IS_DRAFT_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "TRAVELER_BY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "TRAVELER_BY_TRAVELER_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_ASC = "TRAVELER_BY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_DESC = "TRAVELER_BY_TRAVELER_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_ASC = "TRAVELER_BY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_DESC = "TRAVELER_BY_TRAVELER_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_ASC = "TRAVELER_BY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_DESC = "TRAVELER_BY_TRAVELER_ID__IS_PREMIUM_CLASS_PREFERRED_DESC",
  TRAVELER_BY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "TRAVELER_BY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  TRAVELER_BY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "TRAVELER_BY_TRAVELER_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  TRAVELER_BY_TRAVELER_ID__NAME_ALIAS_ASC = "TRAVELER_BY_TRAVELER_ID__NAME_ALIAS_ASC",
  TRAVELER_BY_TRAVELER_ID__NAME_ALIAS_DESC = "TRAVELER_BY_TRAVELER_ID__NAME_ALIAS_DESC",
  TRAVELER_BY_TRAVELER_ID__ROW_SECURITY_ID_ASC = "TRAVELER_BY_TRAVELER_ID__ROW_SECURITY_ID_ASC",
  TRAVELER_BY_TRAVELER_ID__ROW_SECURITY_ID_DESC = "TRAVELER_BY_TRAVELER_ID__ROW_SECURITY_ID_DESC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_BY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_BY_TRAVELER_ID__TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_BY_TRAVELER_ID__TRAVEL_POLICY_ID_ASC = "TRAVELER_BY_TRAVELER_ID__TRAVEL_POLICY_ID_ASC",
  TRAVELER_BY_TRAVELER_ID__TRAVEL_POLICY_ID_DESC = "TRAVELER_BY_TRAVELER_ID__TRAVEL_POLICY_ID_DESC",
  TRAVELER_BY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_ASC = "TRAVELER_BY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_ASC",
  TRAVELER_BY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_DESC = "TRAVELER_BY_TRAVELER_ID__USER_OCCUPATION_TITLE_ID_DESC",
  TRAVELER_BY_TRAVELER_ID__VENDOR_NUMBER_ASC = "TRAVELER_BY_TRAVELER_ID__VENDOR_NUMBER_ASC",
  TRAVELER_BY_TRAVELER_ID__VENDOR_NUMBER_DESC = "TRAVELER_BY_TRAVELER_ID__VENDOR_NUMBER_DESC",
  TRAVELER_BY_TRAVELER_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVELER_BY_TRAVELER_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVELER_BY_TRAVELER_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVELER_BY_TRAVELER_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVELER_BY_TRAVELER_ID___CREATED_BY_USERID_ASC = "TRAVELER_BY_TRAVELER_ID___CREATED_BY_USERID_ASC",
  TRAVELER_BY_TRAVELER_ID___CREATED_BY_USERID_DESC = "TRAVELER_BY_TRAVELER_ID___CREATED_BY_USERID_DESC",
  TRAVELER_BY_TRAVELER_ID___CREATED_DATE_ASC = "TRAVELER_BY_TRAVELER_ID___CREATED_DATE_ASC",
  TRAVELER_BY_TRAVELER_ID___CREATED_DATE_DESC = "TRAVELER_BY_TRAVELER_ID___CREATED_DATE_DESC",
  TRAVELER_BY_TRAVELER_ID___FULL_NAME_ASC = "TRAVELER_BY_TRAVELER_ID___FULL_NAME_ASC",
  TRAVELER_BY_TRAVELER_ID___FULL_NAME_DESC = "TRAVELER_BY_TRAVELER_ID___FULL_NAME_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_ACTIVE_ASC = "TRAVELER_BY_TRAVELER_ID___IS_ACTIVE_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_ACTIVE_DESC = "TRAVELER_BY_TRAVELER_ID___IS_ACTIVE_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_ALIAS_INVOKED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_ALIAS_INVOKED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_ALIAS_INVOKED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_ALIAS_INVOKED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "TRAVELER_BY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "TRAVELER_BY_TRAVELER_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_APPROVED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_APPROVED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_DELETABLE_ASC = "TRAVELER_BY_TRAVELER_ID___IS_DELETABLE_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_DELETABLE_DESC = "TRAVELER_BY_TRAVELER_ID___IS_DELETABLE_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_DEPRECATED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_DEPRECATED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_DEPRECATED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_DEPRECATED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVELER_BY_TRAVELER_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVELER_BY_TRAVELER_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_MESSAGES_EXIST_ASC = "TRAVELER_BY_TRAVELER_ID___IS_MESSAGES_EXIST_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_MESSAGES_EXIST_DESC = "TRAVELER_BY_TRAVELER_ID___IS_MESSAGES_EXIST_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_MODIFIED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_MODIFIED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_MODIFIED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_MODIFIED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_NOTES_EXIST_ASC = "TRAVELER_BY_TRAVELER_ID___IS_NOTES_EXIST_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_NOTES_EXIST_DESC = "TRAVELER_BY_TRAVELER_ID___IS_NOTES_EXIST_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_VIEW_ASC = "TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_VIEW_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_VIEW_DESC = "TRAVELER_BY_TRAVELER_ID___IS_PROTECTED_VIEW_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVELER_BY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVELER_BY_TRAVELER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_TAGS_EXIST_ASC = "TRAVELER_BY_TRAVELER_ID___IS_TAGS_EXIST_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_TAGS_EXIST_DESC = "TRAVELER_BY_TRAVELER_ID___IS_TAGS_EXIST_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_UPDATABLE_ASC = "TRAVELER_BY_TRAVELER_ID___IS_UPDATABLE_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_UPDATABLE_DESC = "TRAVELER_BY_TRAVELER_ID___IS_UPDATABLE_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC = "TRAVELER_BY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC = "TRAVELER_BY_TRAVELER_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVER_ASC = "TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVER_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVER_DESC = "TRAVELER_BY_TRAVELER_ID___IS_USER_APPROVER_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_OBSERVER_ASC = "TRAVELER_BY_TRAVELER_ID___IS_USER_OBSERVER_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_OBSERVER_DESC = "TRAVELER_BY_TRAVELER_ID___IS_USER_OBSERVER_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_REQUESTER_ASC = "TRAVELER_BY_TRAVELER_ID___IS_USER_REQUESTER_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_REQUESTER_DESC = "TRAVELER_BY_TRAVELER_ID___IS_USER_REQUESTER_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_REVOKER_ASC = "TRAVELER_BY_TRAVELER_ID___IS_USER_REVOKER_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_USER_REVOKER_DESC = "TRAVELER_BY_TRAVELER_ID___IS_USER_REVOKER_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_VALIDATED_ASC = "TRAVELER_BY_TRAVELER_ID___IS_VALIDATED_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_VALIDATED_DESC = "TRAVELER_BY_TRAVELER_ID___IS_VALIDATED_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_WAITING_APPROVAL_ASC = "TRAVELER_BY_TRAVELER_ID___IS_WAITING_APPROVAL_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_WAITING_APPROVAL_DESC = "TRAVELER_BY_TRAVELER_ID___IS_WAITING_APPROVAL_DESC",
  TRAVELER_BY_TRAVELER_ID___IS_WAITING_VALIDATION_ASC = "TRAVELER_BY_TRAVELER_ID___IS_WAITING_VALIDATION_ASC",
  TRAVELER_BY_TRAVELER_ID___IS_WAITING_VALIDATION_DESC = "TRAVELER_BY_TRAVELER_ID___IS_WAITING_VALIDATION_DESC",
  TRAVELER_BY_TRAVELER_ID___PENDING_APPROVERS_ASC = "TRAVELER_BY_TRAVELER_ID___PENDING_APPROVERS_ASC",
  TRAVELER_BY_TRAVELER_ID___PENDING_APPROVERS_DESC = "TRAVELER_BY_TRAVELER_ID___PENDING_APPROVERS_DESC",
  TRAVELER_BY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVELER_BY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVELER_BY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVELER_BY_TRAVELER_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVELER_BY_TRAVELER_ID___ROW_TIMESTAMP_ASC = "TRAVELER_BY_TRAVELER_ID___ROW_TIMESTAMP_ASC",
  TRAVELER_BY_TRAVELER_ID___ROW_TIMESTAMP_DESC = "TRAVELER_BY_TRAVELER_ID___ROW_TIMESTAMP_DESC",
  TRAVELER_BY_TRAVELER_ID___SEARCH_DOCUMENT_ASC = "TRAVELER_BY_TRAVELER_ID___SEARCH_DOCUMENT_ASC",
  TRAVELER_BY_TRAVELER_ID___SEARCH_DOCUMENT_DESC = "TRAVELER_BY_TRAVELER_ID___SEARCH_DOCUMENT_DESC",
  TRAVELER_BY_TRAVELER_ID___SORT_NAME_ASC = "TRAVELER_BY_TRAVELER_ID___SORT_NAME_ASC",
  TRAVELER_BY_TRAVELER_ID___SORT_NAME_DESC = "TRAVELER_BY_TRAVELER_ID___SORT_NAME_DESC",
  TRAVELER_BY_TRAVELER_ID___STATUS_TYPE_ID_ASC = "TRAVELER_BY_TRAVELER_ID___STATUS_TYPE_ID_ASC",
  TRAVELER_BY_TRAVELER_ID___STATUS_TYPE_ID_DESC = "TRAVELER_BY_TRAVELER_ID___STATUS_TYPE_ID_DESC",
  TRAVELER_BY_TRAVELER_ID___USER_GROUP_ID_ASC = "TRAVELER_BY_TRAVELER_ID___USER_GROUP_ID_ASC",
  TRAVELER_BY_TRAVELER_ID___USER_GROUP_ID_DESC = "TRAVELER_BY_TRAVELER_ID___USER_GROUP_ID_DESC",
  TRAVELER_ID_ASC = "TRAVELER_ID_ASC",
  TRAVELER_ID_DESC = "TRAVELER_ID_DESC",
  TRAVELER_REFERENCE_NAME_ASC = "TRAVELER_REFERENCE_NAME_ASC",
  TRAVELER_REFERENCE_NAME_DESC = "TRAVELER_REFERENCE_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AGENCY_REFERENCE_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AGENCY_REFERENCE_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AGENCY_REFERENCE_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AGENCY_REFERENCE_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AMENDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AMENDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AMENDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__AMENDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BOOKING_FEE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BOOKING_FEE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BOOKING_FEE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BOOKING_FEE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUDGET_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUDGET_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUDGET_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUDGET_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUSINESS_UNIT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUSINESS_UNIT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUSINESS_UNIT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__BUSINESS_UNIT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__COMMENT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__COMMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__COMMENT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__COMMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CONTROL_TOTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CONTROL_TOTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CONTROL_TOTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CONTROL_TOTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CORPORATE_WORKGROUP_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CURRENCY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CURRENCY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CURRENCY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__CURRENCY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DEPARTMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DEPARTMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DEPARTMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DEPARTMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DOCUMENT_FILE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DOCUMENT_FILE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DOCUMENT_FILE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__DOCUMENT_FILE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_DRAFT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_DRAFT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_DRAFT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_DRAFT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__LINKED_ENTITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__LINKED_ENTITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__LINKED_ENTITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__LINKED_ENTITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__PROJECT_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__PROJECT_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__PROJECT_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__PROJECT_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REFERENCE_ITEM_TYPES_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REFERENCE_ITEM_TYPES_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REFERENCE_ITEM_TYPES_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REFERENCE_ITEM_TYPES_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REQUIRED_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REVISION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REVISION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REVISION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__REVISION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ROW_SECURITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ROW_SECURITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ROW_SECURITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__ROW_SECURITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SORT_AGENCY_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SORT_AGENCY_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SORT_AGENCY_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SORT_AGENCY_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SUPPLIER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SUPPLIER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SUPPLIER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__SUPPLIER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TA_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TA_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TA_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TA_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRAVEL_AUTHORIZATION_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_MOVEMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_MOVEMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_MOVEMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_MOVEMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_PURPOSE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_PURPOSE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_PURPOSE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__TRIP_PURPOSE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__VOIDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__VOIDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__VOIDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID__VOIDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOMMODATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOMMODATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOMMODATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOMMODATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___AIRFARE_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___AIRFARE_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___AIRFARE_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___AIRFARE_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___COMPANY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___COMPANY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___COMPANY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___COMPANY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USERID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USERID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USERID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USERID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_BY_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___CREATED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___INCIDENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___INCIDENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___INCIDENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___INCIDENTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOMMODATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOMMODATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOMMODATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOMMODATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACTIVE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACTIVE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACTIVE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ACTIVE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_AIRFARE_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_AIRFARE_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_AIRFARE_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_AIRFARE_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_APPROVED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CLONING_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CLONING_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CLONING_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CLONING_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DELETABLE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DELETABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DELETABLE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DELETABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DEPRECATED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DEPRECATED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DEPRECATED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DEPRECATED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ENVIRONMENTALS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ENVIRONMENTALS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_FLAGGED_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_FLAGGED_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_FLAGGED_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_FLAGGED_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_INCIDENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_INCIDENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_INCIDENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_INCIDENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MESSAGES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MESSAGES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MESSAGES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MESSAGES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MODIFIED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MODIFIED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MODIFIED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_MODIFIED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NOTES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NOTES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NOTES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_NOTES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_OVER_BUDGET_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_OVER_BUDGET_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_OVER_BUDGET_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_OVER_BUDGET_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_VIEW_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_VIEW_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_VIEW_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_PROTECTED_VIEW_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISABLE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISABLE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_REVISED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_STAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TAGS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TAGS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TAGS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TAGS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRAIN_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRAIN_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRAIN_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRAIN_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_CANCELLED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_CANCELLED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_CANCELLED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_CANCELLED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSACTION_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSPORTATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSPORTATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSPORTATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRANSPORTATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UNSTAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UNSTAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UNSTAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UNSTAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UPDATABLE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UPDATABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UPDATABLE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_UPDATABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_APPROVER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_OBSERVER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_OBSERVER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_OBSERVER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_OBSERVER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REQUESTER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REQUESTER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REQUESTER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REQUESTER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REVOKER_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REVOKER_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REVOKER_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_USER_REVOKER_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VALIDATED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VALIDATED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VALIDATED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VALIDATED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VEHICLE_RENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VEHICLE_RENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VEHICLE_RENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_VEHICLE_RENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_VALIDATION_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_VALIDATION_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_VALIDATION_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___IS_WAITING_VALIDATION_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___OVERRIDING_LANGUAGE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___OVERRIDING_LANGUAGE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___OVERRIDING_LANGUAGE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___OVERRIDING_LANGUAGE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PENDING_APPROVERS_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PENDING_APPROVERS_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PENDING_APPROVERS_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PENDING_APPROVERS_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PO_STATUS_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PO_STATUS_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PO_STATUS_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PO_STATUS_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PURCHASE_ORDER_CREATED_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PURCHASE_ORDER_CREATED_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PURCHASE_ORDER_CREATED_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___PURCHASE_ORDER_CREATED_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___REVISED_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ROW_TIMESTAMP_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ROW_TIMESTAMP_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ROW_TIMESTAMP_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___ROW_TIMESTAMP_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SEARCH_DOCUMENT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SEARCH_DOCUMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SEARCH_DOCUMENT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SEARCH_DOCUMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___STATUS_TYPE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___STATUS_TYPE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___STATUS_TYPE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___STATUS_TYPE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SUPPLIERS_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SUPPLIERS_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SUPPLIERS_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___SUPPLIERS_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAIN_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAIN_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAIN_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAIN_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRANSPORTATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRANSPORTATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRANSPORTATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRANSPORTATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAVELER_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAVELER_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAVELER_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___TRAVELER_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___USER_GROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___USER_GROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___USER_GROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___USER_GROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___VEHICLE_RENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___VEHICLE_RENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___VEHICLE_RENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_TRAVEL_AUTHORIZATION_ID___VEHICLE_RENTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_DATE_ASC = "TRAVEL_AUTHORIZATION_DATE_ASC",
  TRAVEL_AUTHORIZATION_DATE_DESC = "TRAVEL_AUTHORIZATION_DATE_DESC",
  TRAVEL_AUTHORIZATION_ID_ASC = "TRAVEL_AUTHORIZATION_ID_ASC",
  TRAVEL_AUTHORIZATION_ID_DESC = "TRAVEL_AUTHORIZATION_ID_DESC",
  TRAVEL_AUTHORIZATION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_NUMBER_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_AMOUNT_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COMPANION_TICKETS_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__COUNTRY_ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__CURRENCY_ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__DESCRIPTION_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_BUSINESS_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CHARTER_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_COMPANION_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_CONTRACTUAL_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_DEFAULT_CUSTOMIZABLE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_ECONOMY_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_FIRST_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__IS_PREMIUM_CLASS_ALLOWED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID__SORT_ORDER_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___CREATED_DATE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_ACTIVE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DELETABLE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_DEPRECATED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_MODIFIED_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___IS_UPDATABLE_DESC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_ASC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_ASC",
  TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_DESC = "TRAVEL_POLICY_BY_TRAVEL_POLICY_ID___ROW_TIMESTAMP_DESC",
  TRIP_LOCATOR_ASC = "TRIP_LOCATOR_ASC",
  TRIP_LOCATOR_DESC = "TRIP_LOCATOR_DESC",
  TRIP_PURPOSE_ASC = "TRIP_PURPOSE_ASC",
  TRIP_PURPOSE_DESC = "TRIP_PURPOSE_DESC",
  VEHICLE_RENTAL_AMOUNT_ASC = "VEHICLE_RENTAL_AMOUNT_ASC",
  VEHICLE_RENTAL_AMOUNT_DESC = "VEHICLE_RENTAL_AMOUNT_DESC",
  VEHICLE_RENTAL_BUDGET_AMOUNT_ASC = "VEHICLE_RENTAL_BUDGET_AMOUNT_ASC",
  VEHICLE_RENTAL_BUDGET_AMOUNT_DESC = "VEHICLE_RENTAL_BUDGET_AMOUNT_DESC",
  WARNING_INFO_1_ASC = "WARNING_INFO_1_ASC",
  WARNING_INFO_1_DESC = "WARNING_INFO_1_DESC",
  WARNING_INFO_2_ASC = "WARNING_INFO_2_ASC",
  WARNING_INFO_2_DESC = "WARNING_INFO_2_DESC",
  WARNING_INFO_3_ASC = "WARNING_INFO_3_ASC",
  WARNING_INFO_3_DESC = "WARNING_INFO_3_DESC",
  WARNING_VALUE_1_ASC = "WARNING_VALUE_1_ASC",
  WARNING_VALUE_1_DESC = "WARNING_VALUE_1_DESC",
  WARNING_VALUE_2_ASC = "WARNING_VALUE_2_ASC",
  WARNING_VALUE_2_DESC = "WARNING_VALUE_2_DESC",
  WARNING_VALUE_3_ASC = "WARNING_VALUE_3_ASC",
  WARNING_VALUE_3_DESC = "WARNING_VALUE_3_DESC",
  _BASE_CURRENCY_AMOUNT_ASC = "_BASE_CURRENCY_AMOUNT_ASC",
  _BASE_CURRENCY_AMOUNT_DESC = "_BASE_CURRENCY_AMOUNT_DESC",
  _BASE_CURRENCY_ID_ASC = "_BASE_CURRENCY_ID_ASC",
  _BASE_CURRENCY_ID_DESC = "_BASE_CURRENCY_ID_DESC",
  _BASE_CURRENCY_SYMBOL_ASC = "_BASE_CURRENCY_SYMBOL_ASC",
  _BASE_CURRENCY_SYMBOL_DESC = "_BASE_CURRENCY_SYMBOL_DESC",
  _BASE_ISO_CODE_ASC = "_BASE_ISO_CODE_ASC",
  _BASE_ISO_CODE_DESC = "_BASE_ISO_CODE_DESC",
  _BUSINESS_UNIT_NAME_ASC = "_BUSINESS_UNIT_NAME_ASC",
  _BUSINESS_UNIT_NAME_DESC = "_BUSINESS_UNIT_NAME_DESC",
  _COMPANION_AMOUNT_USED_ASC = "_COMPANION_AMOUNT_USED_ASC",
  _COMPANION_AMOUNT_USED_DESC = "_COMPANION_AMOUNT_USED_DESC",
  _COMPANION_TICKET_USED_ASC = "_COMPANION_TICKET_USED_ASC",
  _COMPANION_TICKET_USED_DESC = "_COMPANION_TICKET_USED_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USER_ID_ASC = "_CREATED_BY_USER_ID_ASC",
  _CREATED_BY_USER_ID_DESC = "_CREATED_BY_USER_ID_DESC",
  _CREATED_BY_USER_NAME_ASC = "_CREATED_BY_USER_NAME_ASC",
  _CREATED_BY_USER_NAME_DESC = "_CREATED_BY_USER_NAME_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _CURRENCY_SYMBOL_ASC = "_CURRENCY_SYMBOL_ASC",
  _CURRENCY_SYMBOL_DESC = "_CURRENCY_SYMBOL_DESC",
  _DEPARTMENT_NAME_ASC = "_DEPARTMENT_NAME_ASC",
  _DEPARTMENT_NAME_DESC = "_DEPARTMENT_NAME_DESC",
  _ISO_CODE_ASC = "_ISO_CODE_ASC",
  _ISO_CODE_DESC = "_ISO_CODE_DESC",
  _IS_ACCOMMODATION_EXIST_ASC = "_IS_ACCOMMODATION_EXIST_ASC",
  _IS_ACCOMMODATION_EXIST_DESC = "_IS_ACCOMMODATION_EXIST_DESC",
  _IS_ACCOMMODATION_OVER_BUDGET_ASC = "_IS_ACCOMMODATION_OVER_BUDGET_ASC",
  _IS_ACCOMMODATION_OVER_BUDGET_DESC = "_IS_ACCOMMODATION_OVER_BUDGET_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_AIRFARE_EXIST_ASC = "_IS_AIRFARE_EXIST_ASC",
  _IS_AIRFARE_EXIST_DESC = "_IS_AIRFARE_EXIST_DESC",
  _IS_AIRFARE_OVER_BUDGET_ASC = "_IS_AIRFARE_OVER_BUDGET_ASC",
  _IS_AIRFARE_OVER_BUDGET_DESC = "_IS_AIRFARE_OVER_BUDGET_DESC",
  _IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC = "_IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC",
  _IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC = "_IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_INCIDENTAL_EXIST_ASC = "_IS_INCIDENTAL_EXIST_ASC",
  _IS_INCIDENTAL_EXIST_DESC = "_IS_INCIDENTAL_EXIST_DESC",
  _IS_INCIDENTAL_OVER_BUDGET_ASC = "_IS_INCIDENTAL_OVER_BUDGET_ASC",
  _IS_INCIDENTAL_OVER_BUDGET_DESC = "_IS_INCIDENTAL_OVER_BUDGET_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_OVER_BUDGET_ASC = "_IS_OVER_BUDGET_ASC",
  _IS_OVER_BUDGET_DESC = "_IS_OVER_BUDGET_DESC",
  _IS_TRAIN_EXIST_ASC = "_IS_TRAIN_EXIST_ASC",
  _IS_TRAIN_EXIST_DESC = "_IS_TRAIN_EXIST_DESC",
  _IS_TRAIN_OVER_BUDGET_ASC = "_IS_TRAIN_OVER_BUDGET_ASC",
  _IS_TRAIN_OVER_BUDGET_DESC = "_IS_TRAIN_OVER_BUDGET_DESC",
  _IS_TRANSPORTATION_EXIST_ASC = "_IS_TRANSPORTATION_EXIST_ASC",
  _IS_TRANSPORTATION_EXIST_DESC = "_IS_TRANSPORTATION_EXIST_DESC",
  _IS_TRANSPORTATION_OVER_BUDGET_ASC = "_IS_TRANSPORTATION_OVER_BUDGET_ASC",
  _IS_TRANSPORTATION_OVER_BUDGET_DESC = "_IS_TRANSPORTATION_OVER_BUDGET_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_VEHICLE_RENTAL_EXIST_ASC = "_IS_VEHICLE_RENTAL_EXIST_ASC",
  _IS_VEHICLE_RENTAL_EXIST_DESC = "_IS_VEHICLE_RENTAL_EXIST_DESC",
  _IS_VEHICLE_RENTAL_OVER_BUDGET_ASC = "_IS_VEHICLE_RENTAL_OVER_BUDGET_ASC",
  _IS_VEHICLE_RENTAL_OVER_BUDGET_DESC = "_IS_VEHICLE_RENTAL_OVER_BUDGET_DESC",
  _PRIMARY_TRAVELER_ID_ASC = "_PRIMARY_TRAVELER_ID_ASC",
  _PRIMARY_TRAVELER_ID_DESC = "_PRIMARY_TRAVELER_ID_DESC",
  _PRIMARY_TRAVELER_NAME_ASC = "_PRIMARY_TRAVELER_NAME_ASC",
  _PRIMARY_TRAVELER_NAME_DESC = "_PRIMARY_TRAVELER_NAME_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _SPOT_CURRENCY_AMOUNT_ASC = "_SPOT_CURRENCY_AMOUNT_ASC",
  _SPOT_CURRENCY_AMOUNT_DESC = "_SPOT_CURRENCY_AMOUNT_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _TA_STATUS_TYPE_ASC = "_TA_STATUS_TYPE_ASC",
  _TA_STATUS_TYPE_DESC = "_TA_STATUS_TYPE_DESC",
  _TA_STATUS_TYPE_ID_ASC = "_TA_STATUS_TYPE_ID_ASC",
  _TA_STATUS_TYPE_ID_DESC = "_TA_STATUS_TYPE_ID_DESC",
  _TRAVEL_POLICY_DESCRIPTION_ASC = "_TRAVEL_POLICY_DESCRIPTION_ASC",
  _TRAVEL_POLICY_DESCRIPTION_DESC = "_TRAVEL_POLICY_DESCRIPTION_DESC",
  _TRAVEL_POLICY_ID_ASC = "_TRAVEL_POLICY_ID_ASC",
  _TRAVEL_POLICY_ID_DESC = "_TRAVEL_POLICY_ID_DESC",
  _TRAVEL_TAGS_ASC = "_TRAVEL_TAGS_ASC",
  _TRAVEL_TAGS_DESC = "_TRAVEL_TAGS_DESC",
  _TRIP_WARNING_INFO_ASC = "_TRIP_WARNING_INFO_ASC",
  _TRIP_WARNING_INFO_DESC = "_TRIP_WARNING_INFO_DESC",
}

export enum UploadStatusType {
  EXTRACTING = "EXTRACTING",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
  VALIDATING = "VALIDATING",
  WARNING = "WARNING",
}

/**
 * Methods to use when ordering `UserApproval`.
 */
export enum UserApprovalsOrderBy {
  ADDITIONAL_APPROVAL_INFORMATION_ASC = "ADDITIONAL_APPROVAL_INFORMATION_ASC",
  ADDITIONAL_APPROVAL_INFORMATION_DESC = "ADDITIONAL_APPROVAL_INFORMATION_DESC",
  AMOUNT_1_ASC = "AMOUNT_1_ASC",
  AMOUNT_1_DESC = "AMOUNT_1_DESC",
  AMOUNT_2_ASC = "AMOUNT_2_ASC",
  AMOUNT_2_DESC = "AMOUNT_2_DESC",
  AMOUNT_3_ASC = "AMOUNT_3_ASC",
  AMOUNT_3_DESC = "AMOUNT_3_DESC",
  AMOUNT_4_ASC = "AMOUNT_4_ASC",
  AMOUNT_4_DESC = "AMOUNT_4_DESC",
  AMOUNT_5_ASC = "AMOUNT_5_ASC",
  AMOUNT_5_DESC = "AMOUNT_5_DESC",
  APPROVAL_BRANCH_ID_ASC = "APPROVAL_BRANCH_ID_ASC",
  APPROVAL_BRANCH_ID_DESC = "APPROVAL_BRANCH_ID_DESC",
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC",
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC",
  APPROVAL_BY_ENTITY_ID__ID_ASC = "APPROVAL_BY_ENTITY_ID__ID_ASC",
  APPROVAL_BY_ENTITY_ID__ID_DESC = "APPROVAL_BY_ENTITY_ID__ID_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC",
  APPROVAL_BY_ENTITY_ID__NAME_ASC = "APPROVAL_BY_ENTITY_ID__NAME_ASC",
  APPROVAL_BY_ENTITY_ID__NAME_DESC = "APPROVAL_BY_ENTITY_ID__NAME_DESC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  APPROVAL_GROUP_ASC = "APPROVAL_GROUP_ASC",
  APPROVAL_GROUP_DESC = "APPROVAL_GROUP_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__ENTITY_ID_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ID_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__ID_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_HISTORY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_ASC = "APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_DESC = "APPROVAL_HISTORY_ITEMS_BY_DISPLAY_ENTITY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_PERSONA_ASC = "APPROVAL_PERSONA_ASC",
  APPROVAL_PERSONA_DESC = "APPROVAL_PERSONA_DESC",
  APPROVAL_PHASE_BY_ID__ID_ASC = "APPROVAL_PHASE_BY_ID__ID_ASC",
  APPROVAL_PHASE_BY_ID__ID_DESC = "APPROVAL_PHASE_BY_ID__ID_DESC",
  APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC = "APPROVAL_PHASE_BY_ID__PHASE_NAME_ASC",
  APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC = "APPROVAL_PHASE_BY_ID__PHASE_NAME_DESC",
  APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_APPROVAL_ID__COUNT_DESC",
  APPROVAL_TOPIC_ASC = "APPROVAL_TOPIC_ASC",
  APPROVAL_TOPIC_DESC = "APPROVAL_TOPIC_DESC",
  APPROVAL_TREE_ID_ASC = "APPROVAL_TREE_ID_ASC",
  APPROVAL_TREE_ID_DESC = "APPROVAL_TREE_ID_DESC",
  APPROVAL_TREE_LEVEL_ASC = "APPROVAL_TREE_LEVEL_ASC",
  APPROVAL_TREE_LEVEL_DESC = "APPROVAL_TREE_LEVEL_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ABBREVIATION_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__APPROVAL_TYPE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__ID_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID__LAYOUT_TYPE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_ACTIVE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_DEPRECATED_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PAYMENT_TRACKING_STANDALONE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_PURCHASE_ORDER_SYSTEM_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_SIGNING_STANDALONE_DESC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_ASC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_ASC",
  APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_DESC = "APPROVAL_TYPE_BY_APPROVAL_TYPE_ID___IS_TRAVEL_AUTH_SYSTEM_DESC",
  APPROVAL_TYPE_ID_ASC = "APPROVAL_TYPE_ID_ASC",
  APPROVAL_TYPE_ID_DESC = "APPROVAL_TYPE_ID_DESC",
  APPROVED_DATE_ASC = "APPROVED_DATE_ASC",
  APPROVED_DATE_DESC = "APPROVED_DATE_DESC",
  APPROVER_USER_PROFILE_ID_ASC = "APPROVER_USER_PROFILE_ID_ASC",
  APPROVER_USER_PROFILE_ID_DESC = "APPROVER_USER_PROFILE_ID_DESC",
  APP_ID_ASC = "APP_ID_ASC",
  APP_ID_DESC = "APP_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  BRANCH_APPROVED_DATE_ASC = "BRANCH_APPROVED_DATE_ASC",
  BRANCH_APPROVED_DATE_DESC = "BRANCH_APPROVED_DATE_DESC",
  BRANCH_DIGITAL_SIGNATURE_ASC = "BRANCH_DIGITAL_SIGNATURE_ASC",
  BRANCH_DIGITAL_SIGNATURE_DESC = "BRANCH_DIGITAL_SIGNATURE_DESC",
  BRANCH_REJECTED_DATE_ASC = "BRANCH_REJECTED_DATE_ASC",
  BRANCH_REJECTED_DATE_DESC = "BRANCH_REJECTED_DATE_DESC",
  BRANCH_STATUS_TYPE_ID_ASC = "BRANCH_STATUS_TYPE_ID_ASC",
  BRANCH_STATUS_TYPE_ID_DESC = "BRANCH_STATUS_TYPE_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ABBREVIATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__DESCRIPTION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_DRAFT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_EXTERNAL_TRADING_PARTNER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_PRODUCTION_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__IS_SHARED_TENANT_BUSINESS_UNIT_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__LINKED_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__ROLLUP_BUSINESS_UNIT_ID_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__SORT_ORDER_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___CREATED_DATE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_ACTIVE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVALS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_APPROVED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DELETABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DEPRECATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_DOCUMENTS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MESSAGES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_MODIFIED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_NOTES_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_TAGS_EXIST_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_UPDATABLE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALIDATED_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_VALID_COMPLETE_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_APPROVAL_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___IS_WAITING_VALIDATION_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___ROW_TIMESTAMP_DESC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_ASC",
  BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC = "BUSINESS_UNIT_BY_BUSINESS_UNIT_ID___STATUS_TYPE_ID_DESC",
  BUSINESS_UNIT_ID_ASC = "BUSINESS_UNIT_ID_ASC",
  BUSINESS_UNIT_ID_DESC = "BUSINESS_UNIT_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__CLOSED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__END_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_PERIOD_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__FISCAL_YEAR_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_ENTRY_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_ADJUSTMENT_ALLOWED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_OPEN_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__IS_PERIOD_PRE_CLOSE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__PERIOD_STATUS_TYPE_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_BY_USER_ID_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__REOPENED_REASON_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__START_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID__TRANSACTION_POSTING_CUTOFF_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___CREATED_DATE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ACTIVE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DELETABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_DEPRECATED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_ENTRY_SELECTABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_MODIFIED_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___IS_UPDATABLE_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___PERIOD_YEAR_DESC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_ASC",
  COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC = "COMPANY_CORPORATE_PERIOD_BY_CORPORATE_PERIOD_ID___ROW_TIMESTAMP_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_EXCHANGE_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__COMPANY_SPOT_RATE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__CURRENCY_SYMBOL_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ID_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_ASC",
  COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC = "COMPANY_CURRENCY_BY_CURRENCY_ID__NAME_DESC",
  CORPORATE_PERIOD_ID_ASC = "CORPORATE_PERIOD_ID_ASC",
  CORPORATE_PERIOD_ID_DESC = "CORPORATE_PERIOD_ID_DESC",
  CURRENCY_ID_ASC = "CURRENCY_ID_ASC",
  CURRENCY_ID_DESC = "CURRENCY_ID_DESC",
  CURRENCY_ISO_CODE_ASC = "CURRENCY_ISO_CODE_ASC",
  CURRENCY_ISO_CODE_DESC = "CURRENCY_ISO_CODE_DESC",
  DATE_1_ASC = "DATE_1_ASC",
  DATE_1_DESC = "DATE_1_DESC",
  DATE_2_ASC = "DATE_2_ASC",
  DATE_2_DESC = "DATE_2_DESC",
  DATE_3_ASC = "DATE_3_ASC",
  DATE_3_DESC = "DATE_3_DESC",
  DATE_4_ASC = "DATE_4_ASC",
  DATE_4_DESC = "DATE_4_DESC",
  DATE_5_ASC = "DATE_5_ASC",
  DATE_5_DESC = "DATE_5_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DESCRIPTION_10_ASC = "DESCRIPTION_10_ASC",
  DESCRIPTION_10_DESC = "DESCRIPTION_10_DESC",
  DESCRIPTION_1_ASC = "DESCRIPTION_1_ASC",
  DESCRIPTION_1_DESC = "DESCRIPTION_1_DESC",
  DESCRIPTION_2_ASC = "DESCRIPTION_2_ASC",
  DESCRIPTION_2_DESC = "DESCRIPTION_2_DESC",
  DESCRIPTION_3_ASC = "DESCRIPTION_3_ASC",
  DESCRIPTION_3_DESC = "DESCRIPTION_3_DESC",
  DESCRIPTION_4_ASC = "DESCRIPTION_4_ASC",
  DESCRIPTION_4_DESC = "DESCRIPTION_4_DESC",
  DESCRIPTION_5_ASC = "DESCRIPTION_5_ASC",
  DESCRIPTION_5_DESC = "DESCRIPTION_5_DESC",
  DESCRIPTION_6_ASC = "DESCRIPTION_6_ASC",
  DESCRIPTION_6_DESC = "DESCRIPTION_6_DESC",
  DESCRIPTION_7_ASC = "DESCRIPTION_7_ASC",
  DESCRIPTION_7_DESC = "DESCRIPTION_7_DESC",
  DESCRIPTION_8_ASC = "DESCRIPTION_8_ASC",
  DESCRIPTION_8_DESC = "DESCRIPTION_8_DESC",
  DESCRIPTION_9_ASC = "DESCRIPTION_9_ASC",
  DESCRIPTION_9_DESC = "DESCRIPTION_9_DESC",
  DIGITAL_SIGNATURE_ASC = "DIGITAL_SIGNATURE_ASC",
  DIGITAL_SIGNATURE_DESC = "DIGITAL_SIGNATURE_DESC",
  DISPLAY_ENTITY_APPROVAL_ID_ASC = "DISPLAY_ENTITY_APPROVAL_ID_ASC",
  DISPLAY_ENTITY_APPROVAL_ID_DESC = "DISPLAY_ENTITY_APPROVAL_ID_DESC",
  ENTITY_APPROVAL_BRANCH_ID_ASC = "ENTITY_APPROVAL_BRANCH_ID_ASC",
  ENTITY_APPROVAL_BRANCH_ID_DESC = "ENTITY_APPROVAL_BRANCH_ID_DESC",
  ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC = "ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_ASC",
  ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC = "ENTITY_APPROVAL_BRANCH_ROW_TIMESTAMP_DESC",
  ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC = "ENTITY_APPROVAL_BUSINESS_UNIT_ID_ASC",
  ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC = "ENTITY_APPROVAL_BUSINESS_UNIT_ID_DESC",
  ENTITY_APPROVAL_COMPANY_ID_ASC = "ENTITY_APPROVAL_COMPANY_ID_ASC",
  ENTITY_APPROVAL_COMPANY_ID_DESC = "ENTITY_APPROVAL_COMPANY_ID_DESC",
  ENTITY_APPROVAL_TREE_ID_ASC = "ENTITY_APPROVAL_TREE_ID_ASC",
  ENTITY_APPROVAL_TREE_ID_DESC = "ENTITY_APPROVAL_TREE_ID_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_ENVIRONMENTALS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_ID_ASC = "ENTITY_ID_ASC",
  ENTITY_ID_DESC = "ENTITY_ID_DESC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_TAGS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_TYPE_ID_ASC = "ENTITY_TYPE_ID_ASC",
  ENTITY_TYPE_ID_DESC = "ENTITY_TYPE_ID_DESC",
  FINALIZED_DATE_ASC = "FINALIZED_DATE_ASC",
  FINALIZED_DATE_DESC = "FINALIZED_DATE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_STATE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__ICON_TYPE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__ID_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__ID_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__ID_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__ID_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ACTION_AVAILABLE_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_ICON_DESC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_ASC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_ASC",
  ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_DESC = "ICON_TYPE_BY_ICON_TYPE_ID__IS_APPROVAL_REVOKED_DESC",
  ICON_TYPE_ID_ASC = "ICON_TYPE_ID_ASC",
  ICON_TYPE_ID_DESC = "ICON_TYPE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_ASC = "INVOICE_BY_ENTITY_ID__BATCH_ID_ASC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_DESC = "INVOICE_BY_ENTITY_ID__BATCH_ID_DESC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC",
  INVOICE_BY_ENTITY_ID__ID_ASC = "INVOICE_BY_ENTITY_ID__ID_ASC",
  INVOICE_BY_ENTITY_ID__ID_DESC = "INVOICE_BY_ENTITY_ID__ID_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BATCH_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__CURRENCY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_ASC = "INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_DESC = "INVOICE_BY_LINKED_ENTITY_ID__DESCRIPTION_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_LINKED_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_ASC = "INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_DESC = "INVOICE_BY_LINKED_ENTITY_ID__IS_DRAFT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___COMPANY_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___CREATED_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ACTIVE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_APPROVED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DELETABLE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_HISTORY_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_MODIFIED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_VALIDATED_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_LINKED_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_LINKED_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_LINKED_ENTITY_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_LINKED_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_LINKED_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_ASC = "INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_DESC = "INVOICE_BY_LINKED_ENTITY_ID___USER_GROUP_ID_DESC",
  IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC = "IS_APPROVAL_TREE_VIEWER_ALLOWED_ASC",
  IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC = "IS_APPROVAL_TREE_VIEWER_ALLOWED_DESC",
  IS_APPROVED_ASC = "IS_APPROVED_ASC",
  IS_APPROVED_DESC = "IS_APPROVED_DESC",
  IS_APPROVER_ADDING_ALLOWED_ASC = "IS_APPROVER_ADDING_ALLOWED_ASC",
  IS_APPROVER_ADDING_ALLOWED_DESC = "IS_APPROVER_ADDING_ALLOWED_DESC",
  IS_APPROVER_ASC = "IS_APPROVER_ASC",
  IS_APPROVER_DESC = "IS_APPROVER_DESC",
  IS_AUTO_APPROVAL_ASC = "IS_AUTO_APPROVAL_ASC",
  IS_AUTO_APPROVAL_DESC = "IS_AUTO_APPROVAL_DESC",
  IS_HISTORY_ASC = "IS_HISTORY_ASC",
  IS_HISTORY_DESC = "IS_HISTORY_DESC",
  IS_LEVEL_BYPASSING_ALLOWED_ASC = "IS_LEVEL_BYPASSING_ALLOWED_ASC",
  IS_LEVEL_BYPASSING_ALLOWED_DESC = "IS_LEVEL_BYPASSING_ALLOWED_DESC",
  IS_OBSERVER_ASC = "IS_OBSERVER_ASC",
  IS_OBSERVER_DESC = "IS_OBSERVER_DESC",
  IS_PENDING_ASC = "IS_PENDING_ASC",
  IS_PENDING_DESC = "IS_PENDING_DESC",
  IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC = "IS_PERSONAL_APPROVER_ADDING_ALLOWED_ASC",
  IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC = "IS_PERSONAL_APPROVER_ADDING_ALLOWED_DESC",
  IS_REJECTED_ASC = "IS_REJECTED_ASC",
  IS_REJECTED_DESC = "IS_REJECTED_DESC",
  IS_REQUESTER_ASC = "IS_REQUESTER_ASC",
  IS_REQUESTER_DESC = "IS_REQUESTER_DESC",
  IS_REVOKED_ASC = "IS_REVOKED_ASC",
  IS_REVOKED_DESC = "IS_REVOKED_DESC",
  IS_URGENCY_UPDATE_ALLOWED_ASC = "IS_URGENCY_UPDATE_ALLOWED_ASC",
  IS_URGENCY_UPDATE_ALLOWED_DESC = "IS_URGENCY_UPDATE_ALLOWED_DESC",
  LAYOUT_TYPE_ASC = "LAYOUT_TYPE_ASC",
  LAYOUT_TYPE_DESC = "LAYOUT_TYPE_DESC",
  LINKED_ENTITY_ID_ASC = "LINKED_ENTITY_ID_ASC",
  LINKED_ENTITY_ID_DESC = "LINKED_ENTITY_ID_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__DESCRIPTION_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ENTITY_TYPE_ID_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__ID_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_HINT_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_ROUTER_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__LINK_TITLE_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID__SORT_ORDER_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_ACTIVE_DESC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_ASC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_ASC",
  LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_DESC = "LINK_TYPE_BY_VERIFICATION_LINK_TYPE_ID___IS_DEPRECATED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_1_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ADDRESS_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ACCOUNT_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_IBAN_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_ROUTING_NUMBER_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SORT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BANK_SWIFT_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__BUSINESS_UNIT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CITY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__CONTACT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__COUNTRY_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__DEPARTMENT_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__EMAIL_ACCOUNT_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_APPROVED_VENDOR_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__IS_TRANSACTION_SIGNING_LOOKUP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_DESCRIPTION_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_GROUP_SEQUENCE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_2_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__LOOKUP_NAME_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__PHONE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STATE_REGION_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__STUDIO_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__USER_GROUP_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID__ZIP_CODE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___COMPANY_OR_POOLING_ID_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___CREATED_DATE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_ACTIVE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DELETABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_DEPRECATED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_HISTORY_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_MODIFIED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_PROTECTED_VIEW_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___IS_UPDATABLE_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___ROW_TIMESTAMP_DESC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_ASC",
  LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC = "LOOKUP_NAME_BY_LOOKUP_NAME_ID___SEARCH_DOCUMENT_DESC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "MOBILE_USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "PAY_CYCLE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID__DEFAULT_PAYMENT_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_ASC = "PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_ASC",
  PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_DESC = "PAY_CYCLE_BY_ENTITY_ID__DESCRIPTION_DESC",
  PAY_CYCLE_BY_ENTITY_ID__ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_ASC = "PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_ASC",
  PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_DESC = "PAY_CYCLE_BY_ENTITY_ID__IS_CYCLE_OPEN_ENDED_DESC",
  PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_ASC = "PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_ASC",
  PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_DESC = "PAY_CYCLE_BY_ENTITY_ID__IS_DRAFT_DESC",
  PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PAY_CYCLE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___COMPANY_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_ASC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_DESC = "PAY_CYCLE_BY_ENTITY_ID___CREATED_DATE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_APPROVED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_HISTORY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PAYMENT_SELECTABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "PAY_CYCLE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_ASC = "PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_ASC",
  PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_DESC = "PAY_CYCLE_BY_ENTITY_ID___PAYMENT_COUNT_DESC",
  PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "PAY_CYCLE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PAY_CYCLE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PAY_CYCLE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_ASC = "PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_ASC",
  PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_DESC = "PAY_CYCLE_BY_ENTITY_ID___TOTAL_PAYMENTS_DESC",
  PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "PAY_CYCLE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROJECT_PERIOD_ID_ASC = "PROJECT_PERIOD_ID_ASC",
  PROJECT_PERIOD_ID_DESC = "PROJECT_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_REFERENCE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ACCOUNTING_TRANSFER_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__AMENDED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__APPROVED_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__COMMENT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTRACTUAL_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ALIAS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_1_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ADDRESS_LINE_2_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ALTERNATE_CONTACT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_ATTENTION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_CITY_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_COUNTRY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_EMAIL_ADDRESS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MAIN_CONTACT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_MOBILE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_NOTES_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_POSTAL_CODE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_STATE_REGION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DELIVERY_TELEPHONE_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DESCRIPTION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_ADDRESS_PROTECTED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DELIVERY_TRACKED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_DRAFT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_PACKING_SLIP_ATTACHMENT_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SCHEDULED_PICKUP_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_SUPPLIER_PO_APPROVED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__IS_TEMPLATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ORIGINAL_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_ACCOUNT_REFERENCE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PAYMENT_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TIME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__PURCHASE_ORDER_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REASON_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REASON_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REASON_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REASON_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REQUIRED_TIME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__RETIRED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__REVISION_NUMBER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SCHEDULED_DELIVERY_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SORT_SUPPLIER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__SUPPLIER_REFERENCE_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TEMPLATE_PURCHASE_ORDER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__TRAVEL_SECTION_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID__VOIDED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_CYCLE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_EXPORT_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___COMPANY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___CREATED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_APPROVED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DELIVERY_COMPLETE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_HISTORY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_UPDATABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_INVOICE_SCHEDULE_VIEWABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PARTIAL_RETURN_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROMOTE_ADDRESS_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETIRE_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_RETURNED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_REVISED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_DELIVERY_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___PURCHASE_ORDER_TYPE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REFERENCE_ITEM_TYPES_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_DATE_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___REVISED_USER_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "PURCHASE_ORDER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  RATE_1_ASC = "RATE_1_ASC",
  RATE_1_DESC = "RATE_1_DESC",
  RATE_2_ASC = "RATE_2_ASC",
  RATE_2_DESC = "RATE_2_DESC",
  RATE_3_ASC = "RATE_3_ASC",
  RATE_3_DESC = "RATE_3_DESC",
  RATE_4_ASC = "RATE_4_ASC",
  RATE_4_DESC = "RATE_4_DESC",
  RATE_5_ASC = "RATE_5_ASC",
  RATE_5_DESC = "RATE_5_DESC",
  REJECTED_DATE_ASC = "REJECTED_DATE_ASC",
  REJECTED_DATE_DESC = "REJECTED_DATE_DESC",
  REQUESTED_DATE_ASC = "REQUESTED_DATE_ASC",
  REQUESTED_DATE_DESC = "REQUESTED_DATE_DESC",
  REQUESTER_NAME_ASC = "REQUESTER_NAME_ASC",
  REQUESTER_NAME_DESC = "REQUESTER_NAME_DESC",
  REQUESTER_USER_PROFILE_ID_ASC = "REQUESTER_USER_PROFILE_ID_ASC",
  REQUESTER_USER_PROFILE_ID_DESC = "REQUESTER_USER_PROFILE_ID_DESC",
  REQUIRED_DATE_ASC = "REQUIRED_DATE_ASC",
  REQUIRED_DATE_DESC = "REQUIRED_DATE_DESC",
  REVOKED_BY_USER_NAME_ASC = "REVOKED_BY_USER_NAME_ASC",
  REVOKED_BY_USER_NAME_DESC = "REVOKED_BY_USER_NAME_DESC",
  REVOKED_BY_USER_PROFILE_ID_ASC = "REVOKED_BY_USER_PROFILE_ID_ASC",
  REVOKED_BY_USER_PROFILE_ID_DESC = "REVOKED_BY_USER_PROFILE_ID_DESC",
  REVOKED_DATE_ASC = "REVOKED_DATE_ASC",
  REVOKED_DATE_DESC = "REVOKED_DATE_DESC",
  SORT_ORDER_GROUP_ASC = "SORT_ORDER_GROUP_ASC",
  SORT_ORDER_GROUP_DESC = "SORT_ORDER_GROUP_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_BRANCH_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_TREE_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_ID_ASC = "STATUS_TYPE_ID_ASC",
  STATUS_TYPE_ID_DESC = "STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__ID_ASC = "SUPPLIER_BY_ENTITY_ID__ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ID_DESC = "SUPPLIER_BY_ENTITY_ID__ID_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID__ID_ASC = "TRAVELER_BY_ENTITY_ID__ID_ASC",
  TRAVELER_BY_ENTITY_ID__ID_DESC = "TRAVELER_BY_ENTITY_ID__ID_DESC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AGENCY_REFERENCE_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__AMENDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BOOKING_FEE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUDGET_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__COMMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CORPORATE_WORKGROUP_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__CURRENCY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__DOCUMENT_FILE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_DRAFT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__IS_PERSONAL_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__LINKED_ENTITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ORIGINAL_TRAVEL_AUTHORIZATION_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REFERENCE_ITEM_TYPES_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REQUIRED_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__REVISION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SORT_AGENCY_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__STAMPED_ENTITY_DOCUMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__SUPPLIER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TA_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_NUMBER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRAVEL_AUTHORIZATION_TIME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_MOVEMENT_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__TRIP_PURPOSE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID__VOIDED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOMMODATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___AIRFARE_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___COMPANY_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___INCIDENTAL_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOMMODATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_AIRFARE_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_APPROVED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CLONING_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_CORPORATE_WORKGROUP_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DISTRIBUTION_UPDATE_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_FLAGGED_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_HISTORY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_INCIDENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NON_AMOUNT_UPDATABLE_AFTER_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_OVER_BUDGET_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_REVISED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_STAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRAIN_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRANSPORTATION_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_MOVEMENT_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_TRIP_PREFERRED_AREA_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UNSTAMP_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VALIDATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_VEHICLE_RENTAL_EXIST_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___OVERRIDING_LANGUAGE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PO_STATUS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___PURCHASE_ORDER_CREATED_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_DATE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___REVISED_USER_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___SUPPLIERS_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAIN_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRANSPORTATION_AMOUNT_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___TRAVELER_REFERENCE_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_ASC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_ASC",
  TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_DESC = "TRAVEL_AUTHORIZATION_BY_ENTITY_ID___VEHICLE_RENTAL_AMOUNT_DESC",
  TREE_APPROVED_DATE_ASC = "TREE_APPROVED_DATE_ASC",
  TREE_APPROVED_DATE_DESC = "TREE_APPROVED_DATE_DESC",
  TREE_REJECTED_DATE_ASC = "TREE_REJECTED_DATE_ASC",
  TREE_REJECTED_DATE_DESC = "TREE_REJECTED_DATE_DESC",
  TREE_STATUS_TYPE_ID_ASC = "TREE_STATUS_TYPE_ID_ASC",
  TREE_STATUS_TYPE_ID_DESC = "TREE_STATUS_TYPE_ID_DESC",
  UNIT_1_ASC = "UNIT_1_ASC",
  UNIT_1_DESC = "UNIT_1_DESC",
  UNIT_2_ASC = "UNIT_2_ASC",
  UNIT_2_DESC = "UNIT_2_DESC",
  UNIT_3_ASC = "UNIT_3_ASC",
  UNIT_3_DESC = "UNIT_3_DESC",
  UNIT_4_ASC = "UNIT_4_ASC",
  UNIT_4_DESC = "UNIT_4_DESC",
  UNIT_5_ASC = "UNIT_5_ASC",
  UNIT_5_DESC = "UNIT_5_DESC",
  URGENCY_LEVEL_ASC = "URGENCY_LEVEL_ASC",
  URGENCY_LEVEL_DESC = "URGENCY_LEVEL_DESC",
  USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_ASC = "USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_DESC = "USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__APP_BUSINESS_RULES_ASC = "USER_APP_BY_APP_ID__APP_BUSINESS_RULES_ASC",
  USER_APP_BY_APP_ID__APP_BUSINESS_RULES_DESC = "USER_APP_BY_APP_ID__APP_BUSINESS_RULES_DESC",
  USER_APP_BY_APP_ID__APP_CATEGORY_ID_ASC = "USER_APP_BY_APP_ID__APP_CATEGORY_ID_ASC",
  USER_APP_BY_APP_ID__APP_CATEGORY_ID_DESC = "USER_APP_BY_APP_ID__APP_CATEGORY_ID_DESC",
  USER_APP_BY_APP_ID__APP_CATEGORY_NAME_ASC = "USER_APP_BY_APP_ID__APP_CATEGORY_NAME_ASC",
  USER_APP_BY_APP_ID__APP_CATEGORY_NAME_DESC = "USER_APP_BY_APP_ID__APP_CATEGORY_NAME_DESC",
  USER_APP_BY_APP_ID__APP_ID_ASC = "USER_APP_BY_APP_ID__APP_ID_ASC",
  USER_APP_BY_APP_ID__APP_ID_DESC = "USER_APP_BY_APP_ID__APP_ID_DESC",
  USER_APP_BY_APP_ID__APP_NAME_ASC = "USER_APP_BY_APP_ID__APP_NAME_ASC",
  USER_APP_BY_APP_ID__APP_NAME_DESC = "USER_APP_BY_APP_ID__APP_NAME_DESC",
  USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_ASC = "USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_ASC",
  USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_DESC = "USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_DESC",
  USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_ASC = "USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_ASC",
  USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_DESC = "USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_DESC",
  USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_APP_ENTRY_ASC = "USER_APP_BY_APP_ID__IS_APP_ENTRY_ASC",
  USER_APP_BY_APP_ID__IS_APP_ENTRY_DESC = "USER_APP_BY_APP_ID__IS_APP_ENTRY_DESC",
  USER_APP_BY_APP_ID__IS_APP_REPORTING_ASC = "USER_APP_BY_APP_ID__IS_APP_REPORTING_ASC",
  USER_APP_BY_APP_ID__IS_APP_REPORTING_DESC = "USER_APP_BY_APP_ID__IS_APP_REPORTING_DESC",
  USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_ASC = "USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_ASC",
  USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_DESC = "USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_DESC",
  USER_APP_BY_APP_ID__IS_APP_SERVICE_ASC = "USER_APP_BY_APP_ID__IS_APP_SERVICE_ASC",
  USER_APP_BY_APP_ID__IS_APP_SERVICE_DESC = "USER_APP_BY_APP_ID__IS_APP_SERVICE_DESC",
  USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_ASC = "USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_ASC",
  USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_DESC = "USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_DESC",
  USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_ASC = "USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_ASC",
  USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_DESC = "USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_DESC",
  USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_MOBILE_LINK_ASC = "USER_APP_BY_APP_ID__IS_MOBILE_LINK_ASC",
  USER_APP_BY_APP_ID__IS_MOBILE_LINK_DESC = "USER_APP_BY_APP_ID__IS_MOBILE_LINK_DESC",
  USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_READ_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_READ_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_READ_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_READ_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_ASC = "USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_ASC",
  USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_DESC = "USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_DESC",
  USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_WEB_LINK_ASC = "USER_APP_BY_APP_ID__IS_WEB_LINK_ASC",
  USER_APP_BY_APP_ID__IS_WEB_LINK_DESC = "USER_APP_BY_APP_ID__IS_WEB_LINK_DESC",
  USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_ASC = "USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_DESC = "USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__LINK_HINT_ASC = "USER_APP_BY_APP_ID__LINK_HINT_ASC",
  USER_APP_BY_APP_ID__LINK_HINT_DESC = "USER_APP_BY_APP_ID__LINK_HINT_DESC",
  USER_APP_BY_APP_ID__LINK_TITLE_ASC = "USER_APP_BY_APP_ID__LINK_TITLE_ASC",
  USER_APP_BY_APP_ID__LINK_TITLE_DESC = "USER_APP_BY_APP_ID__LINK_TITLE_DESC",
  USER_APP_BY_APP_ID__LINK_TYPE_ASC = "USER_APP_BY_APP_ID__LINK_TYPE_ASC",
  USER_APP_BY_APP_ID__LINK_TYPE_DESC = "USER_APP_BY_APP_ID__LINK_TYPE_DESC",
  USER_APP_BY_APP_ID__LINK_TYPE_ID_ASC = "USER_APP_BY_APP_ID__LINK_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__LINK_TYPE_ID_DESC = "USER_APP_BY_APP_ID__LINK_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_ASC = "USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_DESC = "USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_DESC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_ASC",
  USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC = "USER_MESSAGES_BY_ENTITY_ID__COUNT_DESC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC",
  USER_PROFILE_BY_ENTITY_ID__ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_ASC = "USER_PROFILE_BY_ENTITY_ID__INVITED_ASC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_DESC = "USER_PROFILE_BY_ENTITY_ID__INVITED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  USER_PROFILE_BY_ENTITY_ID__NAME_ASC = "USER_PROFILE_BY_ENTITY_ID__NAME_ASC",
  USER_PROFILE_BY_ENTITY_ID__NAME_DESC = "USER_PROFILE_BY_ENTITY_ID__NAME_DESC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__ID_ASC = "VENDOR_BY_ENTITY_ID__ID_ASC",
  VENDOR_BY_ENTITY_ID__ID_DESC = "VENDOR_BY_ENTITY_ID__ID_DESC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_ASC = "VENDOR_BY_ENTITY_ID___FULL_NAME_ASC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_DESC = "VENDOR_BY_ENTITY_ID___FULL_NAME_DESC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_ASC = "VENDOR_BY_ENTITY_ID___SORT_NAME_ASC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_DESC = "VENDOR_BY_ENTITY_ID___SORT_NAME_DESC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  _ACCOUNTING_STAMP_DATE_ASC = "_ACCOUNTING_STAMP_DATE_ASC",
  _ACCOUNTING_STAMP_DATE_DESC = "_ACCOUNTING_STAMP_DATE_DESC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  _ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "_ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  _ACCOUNTING_STAMP_USER_ID_ASC = "_ACCOUNTING_STAMP_USER_ID_ASC",
  _ACCOUNTING_STAMP_USER_ID_DESC = "_ACCOUNTING_STAMP_USER_ID_DESC",
  _ACCOUNTING_STAMP_USER_NAME_ASC = "_ACCOUNTING_STAMP_USER_NAME_ASC",
  _ACCOUNTING_STAMP_USER_NAME_DESC = "_ACCOUNTING_STAMP_USER_NAME_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_ASC",
  _IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC = "_IS_ACCOUNTING_ENTRY_STAMPED_COMPLETED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_ENVIRONMENTALS_EXIST_ASC = "_IS_ENVIRONMENTALS_EXIST_ASC",
  _IS_ENVIRONMENTALS_EXIST_DESC = "_IS_ENVIRONMENTALS_EXIST_DESC",
  _IS_LOOKUP_NAME_APPLIED_ASC = "_IS_LOOKUP_NAME_APPLIED_ASC",
  _IS_LOOKUP_NAME_APPLIED_DESC = "_IS_LOOKUP_NAME_APPLIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_REVOCABLE_ASC = "_IS_REVOCABLE_ASC",
  _IS_REVOCABLE_DESC = "_IS_REVOCABLE_DESC",
  _IS_TAGS_EXIST_ASC = "_IS_TAGS_EXIST_ASC",
  _IS_TAGS_EXIST_DESC = "_IS_TAGS_EXIST_DESC",
  _IS_TRANSACTION_CANCELLED_ASC = "_IS_TRANSACTION_CANCELLED_ASC",
  _IS_TRANSACTION_CANCELLED_DESC = "_IS_TRANSACTION_CANCELLED_DESC",
  _IS_URGENT_APPROVAL_ASC = "_IS_URGENT_APPROVAL_ASC",
  _IS_URGENT_APPROVAL_DESC = "_IS_URGENT_APPROVAL_DESC",
  _IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC = "_IS_USER_EN_MASSE_APPROVAL_ALLOWED_ASC",
  _IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC = "_IS_USER_EN_MASSE_APPROVAL_ALLOWED_DESC",
  _IS_VALIDATING_ASC = "_IS_VALIDATING_ASC",
  _IS_VALIDATING_DESC = "_IS_VALIDATING_DESC",
  _LOOKUP_NAME_ID_ASC = "_LOOKUP_NAME_ID_ASC",
  _LOOKUP_NAME_ID_DESC = "_LOOKUP_NAME_ID_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _PERIOD_END_DATE_ASC = "_PERIOD_END_DATE_ASC",
  _PERIOD_END_DATE_DESC = "_PERIOD_END_DATE_DESC",
  _PERIOD_ID_ASC = "_PERIOD_ID_ASC",
  _PERIOD_ID_DESC = "_PERIOD_ID_DESC",
  _PERIOD_START_DATE_ASC = "_PERIOD_START_DATE_ASC",
  _PERIOD_START_DATE_DESC = "_PERIOD_START_DATE_DESC",
  _PERIOD_YEAR_ASC = "_PERIOD_YEAR_ASC",
  _PERIOD_YEAR_DESC = "_PERIOD_YEAR_DESC",
  _SEARCH_DOCUMENT_ASC = "_SEARCH_DOCUMENT_ASC",
  _SEARCH_DOCUMENT_DESC = "_SEARCH_DOCUMENT_DESC",
  _VERIFICATION_DATE_ASC = "_VERIFICATION_DATE_ASC",
  _VERIFICATION_DATE_DESC = "_VERIFICATION_DATE_DESC",
  _VERIFICATION_LINK_TYPE_ID_ASC = "_VERIFICATION_LINK_TYPE_ID_ASC",
  _VERIFICATION_LINK_TYPE_ID_DESC = "_VERIFICATION_LINK_TYPE_ID_DESC",
}

/**
 * Methods to use when ordering `UserNotification`.
 */
export enum UserNotificationsOrderBy {
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_ASC",
  APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC = "APPROVAL_BY_ENTITY_ID__ABBREVIATION_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID__APPROVAL_TYPE_ID_DESC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_ASC",
  APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC = "APPROVAL_BY_ENTITY_ID__DESCRIPTION_DESC",
  APPROVAL_BY_ENTITY_ID__ID_ASC = "APPROVAL_BY_ENTITY_ID__ID_ASC",
  APPROVAL_BY_ENTITY_ID__ID_DESC = "APPROVAL_BY_ENTITY_ID__ID_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADDING_PERSONAL_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_ASC",
  APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC = "APPROVAL_BY_ENTITY_ID__IS_ADD_APPROVERS_ELIGIBLE_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID__IS_DELAYED_APPROVER_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_ASC",
  APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC = "APPROVAL_BY_ENTITY_ID__IS_DRAFT_DESC",
  APPROVAL_BY_ENTITY_ID__NAME_ASC = "APPROVAL_BY_ENTITY_ID__NAME_ASC",
  APPROVAL_BY_ENTITY_ID__NAME_DESC = "APPROVAL_BY_ENTITY_ID__NAME_DESC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_ASC",
  APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC = "APPROVAL_BY_ENTITY_ID__PRIORITY_NUMBER_DESC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "APPROVAL_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_ASC",
  APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC = "APPROVAL_BY_ENTITY_ID___CREATED_DATE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC = "APPROVAL_BY_ENTITY_ID___IS_ACTIVE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC = "APPROVAL_BY_ENTITY_ID___IS_APPROVED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_DELETABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC = "APPROVAL_BY_ENTITY_ID___IS_MODIFIED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "APPROVAL_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC = "APPROVAL_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "APPROVAL_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_ASC",
  APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC = "APPROVAL_BY_ENTITY_ID___IS_VALIDATED_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "APPROVAL_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "APPROVAL_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "APPROVAL_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "APPROVAL_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "APPROVAL_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  APP_ID_ASC = "APP_ID_ASC",
  APP_ID_DESC = "APP_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__CURRENCY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__DESCRIPTION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__IS_DRAFT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__POSTING_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__REFERENCE_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID__WEEK_NUMBER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_COUNT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___BATCH_TRANSACTION_TOTAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___COMPANY_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___CREATED_DATE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_ACTIVE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_APPROVED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_BATCH_SELECTABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DELETABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_HISTORY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_MODIFIED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VALIDATED_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_VERIFIABLE_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC = "BATCH_TRANSACTION_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  ENTITY_ID_ASC = "ENTITY_ID_ASC",
  ENTITY_ID_DESC = "ENTITY_ID_DESC",
  ENTITY_TYPE_ID_ASC = "ENTITY_TYPE_ID_ASC",
  ENTITY_TYPE_ID_DESC = "ENTITY_TYPE_ID_DESC",
  EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID__ID_ASC = "EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID__ID_ASC",
  EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID__ID_DESC = "EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID__ID_DESC",
  EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID___IS_ACTIVE_ASC = "EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID___IS_ACTIVE_ASC",
  EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID___IS_ACTIVE_DESC = "EXTENDED_MESSAGE_BY_EXTENDED_MESSAGE_ID___IS_ACTIVE_DESC",
  EXTENDED_MESSAGE_ID_ASC = "EXTENDED_MESSAGE_ID_ASC",
  EXTENDED_MESSAGE_ID_DESC = "EXTENDED_MESSAGE_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_DESC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__ADDITIONAL_INFORMATION_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_ASC",
  INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC = "INVOICE_BY_ENTITY_ID__APPROVAL_TRIGGER_DATE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_ASC",
  INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC = "INVOICE_BY_ENTITY_ID__BANK_CONFIRMATION_DESC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_ASC = "INVOICE_BY_ENTITY_ID__BANK_NAME_ASC",
  INVOICE_BY_ENTITY_ID__BANK_NAME_DESC = "INVOICE_BY_ENTITY_ID__BANK_NAME_DESC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "INVOICE_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_ASC = "INVOICE_BY_ENTITY_ID__BATCH_ID_ASC",
  INVOICE_BY_ENTITY_ID__BATCH_ID_DESC = "INVOICE_BY_ENTITY_ID__BATCH_ID_DESC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "INVOICE_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_ASC",
  INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC = "INVOICE_BY_ENTITY_ID__CARD_HOLDER_ID_DESC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__CONTROL_TOTAL_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID__CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_ASC",
  INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC = "INVOICE_BY_ENTITY_ID__DESCRIPTION_DESC",
  INVOICE_BY_ENTITY_ID__ID_ASC = "INVOICE_BY_ENTITY_ID__ID_ASC",
  INVOICE_BY_ENTITY_ID__ID_DESC = "INVOICE_BY_ENTITY_ID__ID_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_NUMBER_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_DATE_DESC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_ASC",
  INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC = "INVOICE_BY_ENTITY_ID__INVOICE_RECEIVED_TIME_DESC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_ASC",
  INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC = "INVOICE_BY_ENTITY_ID__IS_DRAFT_DESC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_ASC",
  INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC = "INVOICE_BY_ENTITY_ID__JOURNAL_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_DUE_DATE_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_GROUP_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_ID_DESC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_ASC",
  INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC = "INVOICE_BY_ENTITY_ID__PETTY_CASH_ID_DESC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "INVOICE_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__PURCHASE_ORDER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_ID_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_ACCOUNT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID__REIMBURSE_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "INVOICE_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_DESC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_ASC",
  INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC = "INVOICE_BY_ENTITY_ID__STATUS_HINT_OVERRIDE_ID_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_NUMBER_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ADDRESS_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_ID_DESC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID__VENDOR_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "INVOICE_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_ASC",
  INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC = "INVOICE_BY_ENTITY_ID___COMPANY_ID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC = "INVOICE_BY_ENTITY_ID___CREATED_BY_USER_NAME_DESC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_ASC",
  INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC = "INVOICE_BY_ENTITY_ID___CREATED_DATE_DESC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_ASC",
  INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC = "INVOICE_BY_ENTITY_ID___ELAPSED_TRIGGER_DAYS_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC = "INVOICE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_ASC",
  INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC = "INVOICE_BY_ENTITY_ID___IS_APPROVED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "INVOICE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_ASC",
  INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC = "INVOICE_BY_ENTITY_ID___IS_HISTORY_DESC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC = "INVOICE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_DESC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "INVOICE_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "INVOICE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "INVOICE_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "INVOICE_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "INVOICE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "INVOICE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC = "INVOICE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "INVOICE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_ASC",
  INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC = "INVOICE_BY_ENTITY_ID___LINKED_INVOICE_ID_DESC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "INVOICE_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_DATE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_REFERENCE_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_ASC",
  INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC = "INVOICE_BY_ENTITY_ID___PAYMENT_TOTAL_DESC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "INVOICE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "INVOICE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "INVOICE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "INVOICE_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "INVOICE_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "INVOICE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC = "INVOICE_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  LINK_ID_ASC = "LINK_ID_ASC",
  LINK_ID_DESC = "LINK_ID_DESC",
  LINK_TITLE_ASC = "LINK_TITLE_ASC",
  LINK_TITLE_DESC = "LINK_TITLE_DESC",
  LINK_TYPE_ASC = "LINK_TYPE_ASC",
  LINK_TYPE_DESC = "LINK_TYPE_DESC",
  MESSAGE_COMMENT_ASC = "MESSAGE_COMMENT_ASC",
  MESSAGE_COMMENT_DESC = "MESSAGE_COMMENT_DESC",
  NATURAL = "NATURAL",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__ID_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__ID_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__ID_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__ID_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_ACTION_ALERT_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_ACTION_ALERT_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_ACTION_ALERT_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_ACTION_ALERT_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_APP_USER_MESSAGE_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_APP_USER_MESSAGE_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_APP_USER_MESSAGE_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_APP_USER_MESSAGE_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_FRAUD_ALERT_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_FRAUD_ALERT_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_FRAUD_ALERT_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_FRAUD_ALERT_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_METRIC_ALERT_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_METRIC_ALERT_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_METRIC_ALERT_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_METRIC_ALERT_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_TRADING_PARTNER_ACTIVITY_ALERT_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_TRADING_PARTNER_ACTIVITY_ALERT_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_TRADING_PARTNER_ACTIVITY_ALERT_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__IS_TRADING_PARTNER_ACTIVITY_ALERT_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__NOTIFICATION_TYPE_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__NOTIFICATION_TYPE_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__NOTIFICATION_TYPE_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID__NOTIFICATION_TYPE_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_ACTIVE_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_ACTIVE_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_ACTIVE_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_ACTIVE_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DELETABLE_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DELETABLE_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DELETABLE_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DELETABLE_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DEPRECATED_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DEPRECATED_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DEPRECATED_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_DEPRECATED_DESC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_UPDATABLE_ASC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_UPDATABLE_ASC",
  NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_UPDATABLE_DESC = "NOTIFICATION_TYPE_BY_NOTIFICATION_TYPE_ID___IS_UPDATABLE_DESC",
  NOTIFICATION_TYPE_ID_ASC = "NOTIFICATION_TYPE_ID_ASC",
  NOTIFICATION_TYPE_ID_DESC = "NOTIFICATION_TYPE_ID_DESC",
  PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC = "PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_ASC",
  PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC = "PAYMENT_BY_ENTITY_ID__BUSINESS_UNIT_ID_DESC",
  PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC = "PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_ASC",
  PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC = "PAYMENT_BY_ENTITY_ID__CORPORATE_PERIOD_ID_DESC",
  PAYMENT_BY_ENTITY_ID__CURRENCY_ID_ASC = "PAYMENT_BY_ENTITY_ID__CURRENCY_ID_ASC",
  PAYMENT_BY_ENTITY_ID__CURRENCY_ID_DESC = "PAYMENT_BY_ENTITY_ID__CURRENCY_ID_DESC",
  PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "PAYMENT_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  PAYMENT_BY_ENTITY_ID__ID_ASC = "PAYMENT_BY_ENTITY_ID__ID_ASC",
  PAYMENT_BY_ENTITY_ID__ID_DESC = "PAYMENT_BY_ENTITY_ID__ID_DESC",
  PAYMENT_BY_ENTITY_ID__MEMO_ASC = "PAYMENT_BY_ENTITY_ID__MEMO_ASC",
  PAYMENT_BY_ENTITY_ID__MEMO_DESC = "PAYMENT_BY_ENTITY_ID__MEMO_DESC",
  PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_ASC = "PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_ASC",
  PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_DESC = "PAYMENT_BY_ENTITY_ID__PAID_AMOUNT_DESC",
  PAYMENT_BY_ENTITY_ID__PAID_DATE_ASC = "PAYMENT_BY_ENTITY_ID__PAID_DATE_ASC",
  PAYMENT_BY_ENTITY_ID__PAID_DATE_DESC = "PAYMENT_BY_ENTITY_ID__PAID_DATE_DESC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC = "PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_ASC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC = "PAYMENT_BY_ENTITY_ID__PAYMENT_ACCOUNT_ID_DESC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_ASC = "PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_ASC",
  PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_DESC = "PAYMENT_BY_ENTITY_ID__PAYMENT_REFERENCE_DESC",
  PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_ASC = "PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_ASC",
  PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_DESC = "PAYMENT_BY_ENTITY_ID__PAY_CYCLE_ID_DESC",
  PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC = "PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_ASC",
  PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC = "PAYMENT_BY_ENTITY_ID__PROJECT_PERIOD_ID_DESC",
  PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "PAYMENT_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC = "PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_ASC",
  PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC = "PAYMENT_BY_ENTITY_ID__TRANSACTION_TYPE_ID_DESC",
  PAYMENT_BY_ENTITY_ID__VENDOR_ID_ASC = "PAYMENT_BY_ENTITY_ID__VENDOR_ID_ASC",
  PAYMENT_BY_ENTITY_ID__VENDOR_ID_DESC = "PAYMENT_BY_ENTITY_ID__VENDOR_ID_DESC",
  PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_ASC = "PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_ASC",
  PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_DESC = "PAYMENT_BY_ENTITY_ID__VENDOR_REFERENCE_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_DATE_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_ID_DESC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_ASC",
  PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC = "PAYMENT_BY_ENTITY_ID___ACCOUNTING_STAMP_USER_NAME_DESC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_ASC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_AMOUNT_DESC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_ASC",
  PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC = "PAYMENT_BY_ENTITY_ID___BASE_CURRENCY_ID_DESC",
  PAYMENT_BY_ENTITY_ID___COMPANY_ID_ASC = "PAYMENT_BY_ENTITY_ID___COMPANY_ID_ASC",
  PAYMENT_BY_ENTITY_ID___COMPANY_ID_DESC = "PAYMENT_BY_ENTITY_ID___COMPANY_ID_DESC",
  PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "PAYMENT_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  PAYMENT_BY_ENTITY_ID___CREATED_DATE_ASC = "PAYMENT_BY_ENTITY_ID___CREATED_DATE_ASC",
  PAYMENT_BY_ENTITY_ID___CREATED_DATE_DESC = "PAYMENT_BY_ENTITY_ID___CREATED_DATE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC = "PAYMENT_BY_ENTITY_ID___IS_ACCOUNTING_ENTRY_STAMPED_COMPLETE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ACTIVE_ASC = "PAYMENT_BY_ENTITY_ID___IS_ACTIVE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ACTIVE_DESC = "PAYMENT_BY_ENTITY_ID___IS_ACTIVE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "PAYMENT_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_DELETABLE_ASC = "PAYMENT_BY_ENTITY_ID___IS_DELETABLE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_DELETABLE_DESC = "PAYMENT_BY_ENTITY_ID___IS_DELETABLE_DESC",
  PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_ASC = "PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_DESC = "PAYMENT_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_HISTORY_ASC = "PAYMENT_BY_ENTITY_ID___IS_HISTORY_ASC",
  PAYMENT_BY_ENTITY_ID___IS_HISTORY_DESC = "PAYMENT_BY_ENTITY_ID___IS_HISTORY_DESC",
  PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_ASC = "PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_DESC = "PAYMENT_BY_ENTITY_ID___IS_INVOICE_BALANCE_REQUIRED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_MODIFIED_ASC = "PAYMENT_BY_ENTITY_ID___IS_MODIFIED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_MODIFIED_DESC = "PAYMENT_BY_ENTITY_ID___IS_MODIFIED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_ASC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_DESC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "PAYMENT_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "PAYMENT_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_CANCELLED_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_ENTRY_DESC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_ASC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_ASC",
  PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_DESC = "PAYMENT_BY_ENTITY_ID___IS_TRANSACTION_PAYMENT_DESC",
  PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_ASC = "PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_DESC = "PAYMENT_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC = "PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_ASC",
  PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC = "PAYMENT_BY_ENTITY_ID___LOOKUP_NAME_ID_DESC",
  PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_ASC = "PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_ASC",
  PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_DESC = "PAYMENT_BY_ENTITY_ID___PAY_CYCLE_DESCRIPTION_DESC",
  PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "PAYMENT_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "PAYMENT_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "PAYMENT_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC = "PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_ASC",
  PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC = "PAYMENT_BY_ENTITY_ID___SPOT_CURRENCY_AMOUNT_DESC",
  PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "PAYMENT_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__DESCRIPTION_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_HINT_OVERRIDE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_APPROVAL_PROCESSING_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_DRAFT_RELEASE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__IS_RESPONSE_REQUIRED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_ACTIVE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DELETABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_DEPRECATED_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___IS_UPDATABLE_DESC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_ASC",
  STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC = "STATUS_TYPE_BY_STATUS_TYPE_ID___TRANSACTION_ICON_STATUS_DESC",
  STATUS_TYPE_ID_ASC = "STATUS_TYPE_ID_ASC",
  STATUS_TYPE_ID_DESC = "STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "SUPPLIER_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "SUPPLIER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "SUPPLIER_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC = "SUPPLIER_BY_ENTITY_ID__FIRST_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID__ID_ASC = "SUPPLIER_BY_ENTITY_ID__ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ID_DESC = "SUPPLIER_BY_ENTITY_ID__ID_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC = "SUPPLIER_BY_ENTITY_ID__IS_CORPORATION_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC = "SUPPLIER_BY_ENTITY_ID__IS_DRAFT_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "SUPPLIER_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "SUPPLIER_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_TRAVELER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "SUPPLIER_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_ASC",
  SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC = "SUPPLIER_BY_ENTITY_ID__IS_W9_ON_FILE_DESC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC = "SUPPLIER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "SUPPLIER_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "SUPPLIER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VAT_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC = "SUPPLIER_BY_ENTITY_ID__W9_EXPIRATION_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "SUPPLIER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_ASC",
  SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC = "SUPPLIER_BY_ENTITY_ID___CREATED_DATE_DESC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___FULL_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_APPROVED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_PURCHASE_ORDER_SETTINGS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "SUPPLIER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC = "SUPPLIER_BY_ENTITY_ID___IS_UPDATE_SUPPLIER_OVERRIDE_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "SUPPLIER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC = "SUPPLIER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "SUPPLIER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "SUPPLIER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "SUPPLIER_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "SUPPLIER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "SUPPLIER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "SUPPLIER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_ASC",
  SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC = "SUPPLIER_BY_ENTITY_ID___SORT_NAME_DESC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "SUPPLIER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "SUPPLIER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "TRAVELER_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "TRAVELER_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_ASC",
  TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC = "TRAVELER_BY_ENTITY_ID__FIRST_NAME_DESC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID__ID_ASC = "TRAVELER_BY_ENTITY_ID__ID_ASC",
  TRAVELER_BY_ENTITY_ID__ID_DESC = "TRAVELER_BY_ENTITY_ID__ID_DESC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_CHARTER_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_ASC",
  TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC = "TRAVELER_BY_ENTITY_ID__IS_DRAFT_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_PREMIUM_CLASS_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "TRAVELER_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_ASC",
  TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC = "TRAVELER_BY_ENTITY_ID__NAME_ALIAS_DESC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "TRAVELER_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "TRAVELER_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "TRAVELER_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "TRAVELER_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "TRAVELER_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_ASC",
  TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC = "TRAVELER_BY_ENTITY_ID___CREATED_DATE_DESC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC = "TRAVELER_BY_ENTITY_ID___FULL_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ACTIVE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_INVOKED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_ALIAS_ORIGINAL_NAME_VIEWABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC = "TRAVELER_BY_ENTITY_ID___IS_APPROVED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_DELETABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC = "TRAVELER_BY_ENTITY_ID___IS_MODIFIED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "TRAVELER_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "TRAVELER_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "TRAVELER_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_ASC",
  TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC = "TRAVELER_BY_ENTITY_ID___IS_UPDATE_TRAVELER_OVERRIDE_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "TRAVELER_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_ASC",
  TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC = "TRAVELER_BY_ENTITY_ID___IS_VALIDATED_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "TRAVELER_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "TRAVELER_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "TRAVELER_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "TRAVELER_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "TRAVELER_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_ASC",
  TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC = "TRAVELER_BY_ENTITY_ID___SORT_NAME_DESC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "TRAVELER_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC = "TRAVELER_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_ASC = "USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_DESC = "USER_APP_BY_APP_ID__APPROVAL_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__APP_BUSINESS_RULES_ASC = "USER_APP_BY_APP_ID__APP_BUSINESS_RULES_ASC",
  USER_APP_BY_APP_ID__APP_BUSINESS_RULES_DESC = "USER_APP_BY_APP_ID__APP_BUSINESS_RULES_DESC",
  USER_APP_BY_APP_ID__APP_CATEGORY_ID_ASC = "USER_APP_BY_APP_ID__APP_CATEGORY_ID_ASC",
  USER_APP_BY_APP_ID__APP_CATEGORY_ID_DESC = "USER_APP_BY_APP_ID__APP_CATEGORY_ID_DESC",
  USER_APP_BY_APP_ID__APP_CATEGORY_NAME_ASC = "USER_APP_BY_APP_ID__APP_CATEGORY_NAME_ASC",
  USER_APP_BY_APP_ID__APP_CATEGORY_NAME_DESC = "USER_APP_BY_APP_ID__APP_CATEGORY_NAME_DESC",
  USER_APP_BY_APP_ID__APP_ID_ASC = "USER_APP_BY_APP_ID__APP_ID_ASC",
  USER_APP_BY_APP_ID__APP_ID_DESC = "USER_APP_BY_APP_ID__APP_ID_DESC",
  USER_APP_BY_APP_ID__APP_NAME_ASC = "USER_APP_BY_APP_ID__APP_NAME_ASC",
  USER_APP_BY_APP_ID__APP_NAME_DESC = "USER_APP_BY_APP_ID__APP_NAME_DESC",
  USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_ASC = "USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_ASC",
  USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_DESC = "USER_APP_BY_APP_ID__DOCUMENT_GROUP_ID_DESC",
  USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_ASC = "USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_ASC",
  USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_DESC = "USER_APP_BY_APP_ID__IS_APPROVAL_PROCESS_EXECUTED_ON_SAVE_DESC",
  USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_APPROVAL_REQUEST_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_APP_ENTRY_ASC = "USER_APP_BY_APP_ID__IS_APP_ENTRY_ASC",
  USER_APP_BY_APP_ID__IS_APP_ENTRY_DESC = "USER_APP_BY_APP_ID__IS_APP_ENTRY_DESC",
  USER_APP_BY_APP_ID__IS_APP_REPORTING_ASC = "USER_APP_BY_APP_ID__IS_APP_REPORTING_ASC",
  USER_APP_BY_APP_ID__IS_APP_REPORTING_DESC = "USER_APP_BY_APP_ID__IS_APP_REPORTING_DESC",
  USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_ASC = "USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_ASC",
  USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_DESC = "USER_APP_BY_APP_ID__IS_APP_SEARCHABLE_DESC",
  USER_APP_BY_APP_ID__IS_APP_SERVICE_ASC = "USER_APP_BY_APP_ID__IS_APP_SERVICE_ASC",
  USER_APP_BY_APP_ID__IS_APP_SERVICE_DESC = "USER_APP_BY_APP_ID__IS_APP_SERVICE_DESC",
  USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_CHANGE_HISTORY_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_CREATE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_ASC = "USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_ASC",
  USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_DESC = "USER_APP_BY_APP_ID__IS_DASHBOARD_TILE_DESC",
  USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_DELETE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_DOCUMENTS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_ENVIRONMENTAL_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_ASC = "USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_ASC",
  USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_DESC = "USER_APP_BY_APP_ID__IS_GLOBAL_SEARCH_AVAILABLE_DESC",
  USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_MESSAGING_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_MOBILE_LINK_ASC = "USER_APP_BY_APP_ID__IS_MOBILE_LINK_ASC",
  USER_APP_BY_APP_ID__IS_MOBILE_LINK_DESC = "USER_APP_BY_APP_ID__IS_MOBILE_LINK_DESC",
  USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_NOTES_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_RATINGS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_READ_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_READ_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_READ_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_READ_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_ROW_PROTECTION_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_SOFT_ACCOUNT_CODING_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_ASC = "USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_ASC",
  USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_DESC = "USER_APP_BY_APP_ID__IS_SYSTEM_APP_CATEGORY_DESC",
  USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_TAGS_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_UPDATE_AFTER_APPROVAL_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_UPDATE_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_ASC = "USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_ASC",
  USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_DESC = "USER_APP_BY_APP_ID__IS_VALIDATION_REQUEST_ALLOWED_DESC",
  USER_APP_BY_APP_ID__IS_WEB_LINK_ASC = "USER_APP_BY_APP_ID__IS_WEB_LINK_ASC",
  USER_APP_BY_APP_ID__IS_WEB_LINK_DESC = "USER_APP_BY_APP_ID__IS_WEB_LINK_DESC",
  USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_ASC = "USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_DESC = "USER_APP_BY_APP_ID__LINK_ENTITY_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__LINK_HINT_ASC = "USER_APP_BY_APP_ID__LINK_HINT_ASC",
  USER_APP_BY_APP_ID__LINK_HINT_DESC = "USER_APP_BY_APP_ID__LINK_HINT_DESC",
  USER_APP_BY_APP_ID__LINK_TITLE_ASC = "USER_APP_BY_APP_ID__LINK_TITLE_ASC",
  USER_APP_BY_APP_ID__LINK_TITLE_DESC = "USER_APP_BY_APP_ID__LINK_TITLE_DESC",
  USER_APP_BY_APP_ID__LINK_TYPE_ASC = "USER_APP_BY_APP_ID__LINK_TYPE_ASC",
  USER_APP_BY_APP_ID__LINK_TYPE_DESC = "USER_APP_BY_APP_ID__LINK_TYPE_DESC",
  USER_APP_BY_APP_ID__LINK_TYPE_ID_ASC = "USER_APP_BY_APP_ID__LINK_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__LINK_TYPE_ID_DESC = "USER_APP_BY_APP_ID__LINK_TYPE_ID_DESC",
  USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_ASC = "USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_ASC",
  USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_DESC = "USER_APP_BY_APP_ID__VALIDATION_APPROVAL_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ACCESS_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__CHART_OF_ACCOUNT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__COMMUNICATION_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DATA_ACCESS_POLICY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC = "USER_PROFILE_BY_ENTITY_ID__DEFAULT_APP_ROUTER_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_ASC",
  USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC = "USER_PROFILE_BY_ENTITY_ID__DESCRIPTION_DESC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_ASC",
  USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC = "USER_PROFILE_BY_ENTITY_ID__EMAIL_ACCOUNT_DESC",
  USER_PROFILE_BY_ENTITY_ID__ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_ASC = "USER_PROFILE_BY_ENTITY_ID__INVITED_ASC",
  USER_PROFILE_BY_ENTITY_ID__INVITED_DESC = "USER_PROFILE_BY_ENTITY_ID__INVITED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_ADMINISTRATOR_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_DRAFT_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_INVITABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_PRIMARY_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_RESTRICTED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC = "USER_PROFILE_BY_ENTITY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  USER_PROFILE_BY_ENTITY_ID__NAME_ASC = "USER_PROFILE_BY_ENTITY_ID__NAME_ASC",
  USER_PROFILE_BY_ENTITY_ID__NAME_DESC = "USER_PROFILE_BY_ENTITY_ID__NAME_DESC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__ROLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__SECURE_ROW_LEVEL_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TAG_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TENANT_USER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__TRADING_PARTNER_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_GROUP_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID__USER_RANK_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___COMPANY_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___CREATED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___INVITATION_STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_ACTIVE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_APPROVED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_AUTHENTICATED_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DELETABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_MODIFIED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_PROFILE_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_STAGED_TO_INVITE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_REQUIRED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_USER_SIGNATURE_UPDATABLE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALIDATED_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_COMMON_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_TENANT_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_VALID_USER_PROFILE_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "USER_PROFILE_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_ASC",
  USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC = "USER_PROFILE_BY_ENTITY_ID___LAST_USED_DATE_DESC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "USER_PROFILE_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "USER_PROFILE_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "USER_PROFILE_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_PROFILE_BY_ENTITY_ID___USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ACCOUNT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_IBAN_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_ASC = "VENDOR_BY_ENTITY_ID__BANK_NAME_ASC",
  VENDOR_BY_ENTITY_ID__BANK_NAME_DESC = "VENDOR_BY_ENTITY_ID__BANK_NAME_DESC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__BANK_ROUTING_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SORT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_ASC",
  VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC = "VENDOR_BY_ENTITY_ID__BANK_SWIFT_CODE_DESC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC = "VENDOR_BY_ENTITY_ID__COMPANY_OR_LAST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_ASC",
  VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC = "VENDOR_BY_ENTITY_ID__CUSTOMER_ACCOUNT_DESC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_ASC",
  VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC = "VENDOR_BY_ENTITY_ID__DATE_OF_BIRTH_DESC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_ASC",
  VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC = "VENDOR_BY_ENTITY_ID__ENVIRONMENTAL_METRIC_ID_DESC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_ASC",
  VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC = "VENDOR_BY_ENTITY_ID__FEDERAL_TAX_REFERENCE_DESC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_ASC",
  VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC = "VENDOR_BY_ENTITY_ID__FIRST_NAME_DESC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__FREQUENT_FLYER_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__ID_ASC = "VENDOR_BY_ENTITY_ID__ID_ASC",
  VENDOR_BY_ENTITY_ID__ID_DESC = "VENDOR_BY_ENTITY_ID__ID_DESC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_BUSINESS_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_COMPETITIVE_BIDS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID__IS_CONTRACTUAL_PURCHASE_ORDER_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_ASC",
  VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC = "VENDOR_BY_ENTITY_ID__IS_CORPORATION_DESC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_ASC",
  VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC = "VENDOR_BY_ENTITY_ID__IS_CREW_MEMBER_DESC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_ASC",
  VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC = "VENDOR_BY_ENTITY_ID__IS_DRAFT_DESC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ECONOMY_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC = "VENDOR_BY_ENTITY_ID__IS_ENVIRONMENTAL_IMPACT_TRACKED_DESC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_FIRST_CLASS_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_ASC",
  VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC = "VENDOR_BY_ENTITY_ID__IS_INDIVIDUAL_DESC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_ISLE_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_ASC",
  VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC = "VENDOR_BY_ENTITY_ID__IS_PAYROLL_VENDOR_DESC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_PURCHASE_ORDER_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_ASC",
  VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC = "VENDOR_BY_ENTITY_ID__IS_RELATED_PARTY_SUPPLIER_DESC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_ASC",
  VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC = "VENDOR_BY_ENTITY_ID__IS_TRAVELER_DESC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_ASC",
  VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC = "VENDOR_BY_ENTITY_ID__IS_VENDOR_PLACEHOLDER_DESC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_ASC",
  VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC = "VENDOR_BY_ENTITY_ID__IS_WINDOW_SEAT_PREFERRED_DESC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__PAYMENT_TERM_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_ASC",
  VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC = "VENDOR_BY_ENTITY_ID__PURCHASE_DOCUMENTS_POOL_ID_DESC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_ASC",
  VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC = "VENDOR_BY_ENTITY_ID__ROW_SECURITY_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_AMOUNT_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_MASTER_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_COMPANION_TICKETS_DESC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVELER_DEPARTMENT_ID_DESC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_ASC",
  VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC = "VENDOR_BY_ENTITY_ID__TRAVEL_POLICY_ID_DESC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_ASC",
  VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC = "VENDOR_BY_ENTITY_ID__USER_OCCUPATION_TITLE_ID_DESC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VAT_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_NUMBER_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_RATE_DESC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID__VENDOR_SERVICE_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_ASC",
  VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC = "VENDOR_BY_ENTITY_ID___COMPANY_OR_POOLING_ID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC = "VENDOR_BY_ENTITY_ID___CREATED_BY_USERID_DESC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_ASC",
  VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC = "VENDOR_BY_ENTITY_ID___CREATED_DATE_DESC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_ASC = "VENDOR_BY_ENTITY_ID___FULL_NAME_ASC",
  VENDOR_BY_ENTITY_ID___FULL_NAME_DESC = "VENDOR_BY_ENTITY_ID___FULL_NAME_DESC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_ASC",
  VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC = "VENDOR_BY_ENTITY_ID___IS_ACTIVE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_HISTORY_EXISTS_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVAL_REVOCABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_ASC",
  VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC = "VENDOR_BY_ENTITY_ID___IS_APPROVED_DESC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_BANK_ACCOUNT_VERIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_DELETABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC = "VENDOR_BY_ENTITY_ID___IS_DEPRECATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_DOCUMENTS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_ENVIRONMENTALS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_MESSAGES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_ASC",
  VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC = "VENDOR_BY_ENTITY_ID___IS_MODIFIED_DESC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_NOTES_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_DESC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_ASC",
  VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC = "VENDOR_BY_ENTITY_ID___IS_PROTECTED_VIEW_DESC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_RATINGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "VENDOR_BY_ENTITY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_ASC",
  VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC = "VENDOR_BY_ENTITY_ID___IS_TAGS_EXIST_DESC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_ASC",
  VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC = "VENDOR_BY_ENTITY_ID___IS_UPDATABLE_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_APPROVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_OBSERVER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REQUESTER_DESC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_ASC",
  VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC = "VENDOR_BY_ENTITY_ID___IS_USER_REVOKER_DESC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_ASC",
  VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC = "VENDOR_BY_ENTITY_ID___IS_VALIDATED_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_APPROVAL_DESC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_ASC",
  VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC = "VENDOR_BY_ENTITY_ID___IS_WAITING_VALIDATION_DESC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_ASC",
  VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC = "VENDOR_BY_ENTITY_ID___PENDING_APPROVERS_DESC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_ASC",
  VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC = "VENDOR_BY_ENTITY_ID___RATING_VENDOR_ID_DESC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_ASC",
  VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC = "VENDOR_BY_ENTITY_ID___REQUIRED_APPROVAL_DOCUMENTS_DESC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_ASC",
  VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC = "VENDOR_BY_ENTITY_ID___ROW_TIMESTAMP_DESC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_ASC",
  VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC = "VENDOR_BY_ENTITY_ID___SEARCH_DOCUMENT_DESC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_ASC = "VENDOR_BY_ENTITY_ID___SORT_NAME_ASC",
  VENDOR_BY_ENTITY_ID___SORT_NAME_DESC = "VENDOR_BY_ENTITY_ID___SORT_NAME_DESC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_ASC",
  VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC = "VENDOR_BY_ENTITY_ID___STATUS_TYPE_ID_DESC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_ASC",
  VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC = "VENDOR_BY_ENTITY_ID___USER_GROUP_ID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_EXTENDED_MESSAGE_ASC = "_IS_EXTENDED_MESSAGE_ASC",
  _IS_EXTENDED_MESSAGE_DESC = "_IS_EXTENDED_MESSAGE_DESC",
  _IS_NEW_MESSAGE_ASC = "_IS_NEW_MESSAGE_ASC",
  _IS_NEW_MESSAGE_DESC = "_IS_NEW_MESSAGE_DESC",
  _NOTIFICATION_TITLE_ASC = "_NOTIFICATION_TITLE_ASC",
  _NOTIFICATION_TITLE_DESC = "_NOTIFICATION_TITLE_DESC",
  _RECEIVED_DATE_ASC = "_RECEIVED_DATE_ASC",
  _RECEIVED_DATE_DESC = "_RECEIVED_DATE_DESC",
}

/**
 * Methods to use when ordering `UserOccupationTitle`.
 */
export enum UserOccupationTitlesOrderBy {
  APPROVAL_BRANCHES_BY_USER_OCCUPATION_TITLE_ID_SELECTOR__COUNT_ASC = "APPROVAL_BRANCHES_BY_USER_OCCUPATION_TITLE_ID_SELECTOR__COUNT_ASC",
  APPROVAL_BRANCHES_BY_USER_OCCUPATION_TITLE_ID_SELECTOR__COUNT_DESC = "APPROVAL_BRANCHES_BY_USER_OCCUPATION_TITLE_ID_SELECTOR__COUNT_DESC",
  APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_USER_OCCUPATION_TITLE_ID__COUNT_ASC = "APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_USER_OCCUPATION_TITLE_ID__COUNT_ASC",
  APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_USER_OCCUPATION_TITLE_ID__COUNT_DESC = "APPROVAL_SELECTION_CONDITIONS_BY_REQUESTER_USER_OCCUPATION_TITLE_ID__COUNT_DESC",
  COMPANY_USER_OCCUPATION_TITLES_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC = "COMPANY_USER_OCCUPATION_TITLES_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC",
  COMPANY_USER_OCCUPATION_TITLES_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC = "COMPANY_USER_OCCUPATION_TITLES_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATES_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATES_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  SORT_ORDER_ASC = "SORT_ORDER_ASC",
  SORT_ORDER_DESC = "SORT_ORDER_DESC",
  TRAVELERS_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC = "TRAVELERS_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC",
  TRAVELERS_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC = "TRAVELERS_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC",
  USER_OCCUPATION_TITLE_ASC = "USER_OCCUPATION_TITLE_ASC",
  USER_OCCUPATION_TITLE_DESC = "USER_OCCUPATION_TITLE_DESC",
  USER_PROFILES_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC = "USER_PROFILES_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC",
  USER_PROFILES_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC = "USER_PROFILES_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_DESC",
  USER_RANK_TYPE_ID_ASC = "USER_RANK_TYPE_ID_ASC",
  USER_RANK_TYPE_ID_DESC = "USER_RANK_TYPE_ID_DESC",
  VENDORS_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC = "VENDORS_BY_USER_OCCUPATION_TITLE_ID__COUNT_ASC",
  VENDORS_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC = "VENDORS_BY_USER_OCCUPATION_TITLE_ID__COUNT_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
}

/**
 * Methods to use when ordering `UserProfile`.
 */
export enum UserProfilesOrderBy {
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ABBREVIATION_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__DESCRIPTION_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__ID_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__IS_DRAFT_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__NAME_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID__SORT_ORDER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___CREATED_DATE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_ACTIVE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_APPROVED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DELETABLE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_DEPRECATED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_MODIFIED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_UPDATABLE_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_APPROVER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_OBSERVER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REQUESTER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_USER_REVOKER_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_VALIDATED_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___ROW_TIMESTAMP_DESC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_ASC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_ASC",
  ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_DESC = "ACCESS_GROUP_BY_ACCESS_GROUP_ID___STATUS_TYPE_ID_DESC",
  ACCESS_GROUP_ID_ASC = "ACCESS_GROUP_ID_ASC",
  ACCESS_GROUP_ID_DESC = "ACCESS_GROUP_ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_BY_NAME_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__AMENDED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__APPROVERS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__ID_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTED_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REJECTORS_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_DATE_DESC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_ASC",
  APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC = "APPROVAL_ENTITY_APPROVER_BY_ID__REQUESTER_NAME_DESC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_HISTORY_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_ASC",
  APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC = "APPROVAL_SIGNATURES_BY_ENTITY_ID__COUNT_DESC",
  CHART_OF_ACCOUNT_ID_ASC = "CHART_OF_ACCOUNT_ID_ASC",
  CHART_OF_ACCOUNT_ID_DESC = "CHART_OF_ACCOUNT_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ABBREVIATION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__DESCRIPTION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__IS_DRAFT_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__NAME_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__SORT_ORDER_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID__STATUS_TYPE_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___CREATED_DATE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_ACTIVE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_APPROVED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DELETABLE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_DEPRECATED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_MODIFIED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_UPDATABLE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALIDATED_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_VALID_COMPLETE_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___ROW_TIMESTAMP_DESC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_ASC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_ASC",
  COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_DESC = "COMMUNICATION_GROUP_BY_COMMUNICATION_GROUP_ID___STATUS_TYPE_ID_DESC",
  COMMUNICATION_GROUP_ID_ASC = "COMMUNICATION_GROUP_ID_ASC",
  COMMUNICATION_GROUP_ID_DESC = "COMMUNICATION_GROUP_ID_DESC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_ASC",
  COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC = "COMPANY_BY_COMPANY_ID__ABBREVIATION_DESC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPLICATION_SUITE_ID_DESC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__APPROVAL_PHASE_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_ASC",
  COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC = "COMPANY_BY_COMPANY_ID__CHART_OF_ACCOUNTS_ID_DESC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__COMPANY_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC = "COMPANY_BY_COMPANY_ID__CORPORATE_WORKGROUP_ID_DESC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_ASC",
  COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC = "COMPANY_BY_COMPANY_ID__CORPORATION_NAME_DESC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_ASC",
  COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC = "COMPANY_BY_COMPANY_ID__CURRENCY_ID_DESC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_ASC",
  COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC = "COMPANY_BY_COMPANY_ID__DEFAULT_ROLLUP_BUSINESS_UNIT_ID_DESC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_ASC",
  COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC = "COMPANY_BY_COMPANY_ID__DESCRIPTION_DESC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_ASC",
  COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC = "COMPANY_BY_COMPANY_ID__DISTRIBUTION_LAYOUT_TYPE_DESC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_ASC",
  COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC = "COMPANY_BY_COMPANY_ID__EMAIL_SENDER_ITEM_ID_DESC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_ASC",
  COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC = "COMPANY_BY_COMPANY_ID__HOME_COUNTRY_ID_DESC",
  COMPANY_BY_COMPANY_ID__ID_ASC = "COMPANY_BY_COMPANY_ID__ID_ASC",
  COMPANY_BY_COMPANY_ID__ID_DESC = "COMPANY_BY_COMPANY_ID__ID_DESC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_ASC",
  COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC = "COMPANY_BY_COMPANY_ID__IS_DRAFT_DESC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC = "COMPANY_BY_COMPANY_ID__IS_PRE_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_ASC",
  COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC = "COMPANY_BY_COMPANY_ID__IS_SPOT_RATE_PREFERRED_DESC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_ASC",
  COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC = "COMPANY_BY_COMPANY_ID__LANGUAGE_ID_DESC",
  COMPANY_BY_COMPANY_ID__NAME_ASC = "COMPANY_BY_COMPANY_ID__NAME_ASC",
  COMPANY_BY_COMPANY_ID__NAME_DESC = "COMPANY_BY_COMPANY_ID__NAME_DESC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID__OVERRIDING_ADDRESS_FORMAT_TYPE_ID_DESC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_ASC",
  COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC = "COMPANY_BY_COMPANY_ID__SORT_ORDER_DESC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_ASC = "COMPANY_BY_COMPANY_ID__TENANT_ID_ASC",
  COMPANY_BY_COMPANY_ID__TENANT_ID_DESC = "COMPANY_BY_COMPANY_ID__TENANT_ID_DESC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_ASC",
  COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC = "COMPANY_BY_COMPANY_ID__TIME_ZONE_ID_DESC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_ASC",
  COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC = "COMPANY_BY_COMPANY_ID___CREATED_DATE_DESC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_ASC",
  COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC = "COMPANY_BY_COMPANY_ID___IS_ACTIVE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVAL_REVOCABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_ASC",
  COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC = "COMPANY_BY_COMPANY_ID___IS_APPROVED_DESC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_DELETABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC = "COMPANY_BY_COMPANY_ID___IS_DEPRECATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_ASC",
  COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC = "COMPANY_BY_COMPANY_ID___IS_MODIFIED_DESC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "COMPANY_BY_COMPANY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_ASC",
  COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC = "COMPANY_BY_COMPANY_ID___IS_UPDATABLE_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_APPROVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_OBSERVER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REQUESTER_DESC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_ASC",
  COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC = "COMPANY_BY_COMPANY_ID___IS_USER_REVOKER_DESC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_ASC",
  COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC = "COMPANY_BY_COMPANY_ID___IS_VALIDATED_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_APPROVAL_DESC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_ASC",
  COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC = "COMPANY_BY_COMPANY_ID___IS_WAITING_VALIDATION_DESC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_ASC",
  COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC = "COMPANY_BY_COMPANY_ID___ROW_TIMESTAMP_DESC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_ASC",
  COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC = "COMPANY_BY_COMPANY_ID___STATUS_TYPE_ID_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ABBREVIATION_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__DESCRIPTION_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__ID_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_BUSINESS_UNITS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_PROJECTS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SEASONS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_SERIES_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_ALL_TAG_ACCESS_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__IS_DRAFT_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__NAME_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID__SORT_ORDER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___COMPANY_OR_POOLING_ID_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___CREATED_DATE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_ACTIVE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVAL_REVOCABLE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_APPROVED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DELETABLE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_DEPRECATED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_MODIFIED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_UPDATABLE_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_APPROVER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_OBSERVER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REQUESTER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_USER_REVOKER_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_VALIDATED_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_APPROVAL_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___IS_WAITING_VALIDATION_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___ROW_TIMESTAMP_DESC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_ASC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_ASC",
  DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_DESC = "DATA_ACCESS_POLICY_BY_DATA_ACCESS_POLICY_ID___STATUS_TYPE_ID_DESC",
  DATA_ACCESS_POLICY_ID_ASC = "DATA_ACCESS_POLICY_ID_ASC",
  DATA_ACCESS_POLICY_ID_DESC = "DATA_ACCESS_POLICY_ID_DESC",
  DEFAULT_APP_ROUTER_ASC = "DEFAULT_APP_ROUTER_ASC",
  DEFAULT_APP_ROUTER_DESC = "DEFAULT_APP_ROUTER_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ABBREVIATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DEPARTMENT_TYPE_ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__DESCRIPTION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__ID_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__IS_DRAFT_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC = "DEPARTMENT_BY_DEPARTMENT_ID__SORT_ORDER_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___CREATED_DATE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_ACTIVE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVALS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_APPROVED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DELETABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_DOCUMENTS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MESSAGES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_MODIFIED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_NOTES_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_SYSTEM_INTERNAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_TAGS_EXIST_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALIDATED_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_VALID_COMPLETE_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_BY_DEPARTMENT_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ABBREVIATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__DESCRIPTION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_DRAFT_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_PRE_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__IS_TRAVEL_DEPARTMENT_GROUP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__NAME_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID__SORT_ORDER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___CREATED_DATE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_ACTIVE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVAL_REVOCABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_APPROVED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DELETABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_MODIFIED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_APPROVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_OBSERVER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REQUESTER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_USER_REVOKER_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_VALIDATED_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_ASC",
  DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC = "DEPARTMENT_GROUP_BY_DEPARTMENT_GROUP_ID___STATUS_TYPE_ID_DESC",
  DEPARTMENT_GROUP_ID_ASC = "DEPARTMENT_GROUP_ID_ASC",
  DEPARTMENT_GROUP_ID_DESC = "DEPARTMENT_GROUP_ID_DESC",
  DEPARTMENT_ID_ASC = "DEPARTMENT_ID_ASC",
  DEPARTMENT_ID_DESC = "DEPARTMENT_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ACCESS_GROUP_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ACCESS_GROUP_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ACCESS_GROUP_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ACCESS_GROUP_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COMMUNICATION_GROUP_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COMMUNICATION_GROUP_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COMMUNICATION_GROUP_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__COMMUNICATION_GROUP_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DATA_ACCESS_POLICY_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DATA_ACCESS_POLICY_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DATA_ACCESS_POLICY_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DATA_ACCESS_POLICY_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_GROUP_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_GROUP_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_GROUP_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_GROUP_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DEPARTMENT_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DESCRIPTION_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DESCRIPTION_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DESCRIPTION_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__DESCRIPTION_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_DEPARTMENT_USER_GROUP_REQUIRED_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_DEPARTMENT_USER_GROUP_REQUIRED_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_DEPARTMENT_USER_GROUP_REQUIRED_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_DEPARTMENT_USER_GROUP_REQUIRED_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_OCCUPATION_PLACED_IN_DESCRIPTION_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_OCCUPATION_PLACED_IN_DESCRIPTION_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_OCCUPATION_PLACED_IN_DESCRIPTION_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_OCCUPATION_PLACED_IN_DESCRIPTION_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_TEMPLATE_CONTROLLED_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_TEMPLATE_CONTROLLED_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_TEMPLATE_CONTROLLED_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_TEMPLATE_CONTROLLED_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_DEPARTMENT_DEFAULT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_DEPARTMENT_DEFAULT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_DEPARTMENT_DEFAULT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_DEPARTMENT_DEFAULT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_BUSINESS_UNIT_DEFAULT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_CURRENCY_DEFAULT_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_CURRENCY_DEFAULT_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_CURRENCY_DEFAULT_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__IS_USER_PRIMARY_CURRENCY_DEFAULT_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__NAME_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__NAME_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__NAME_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__NAME_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ROLE_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ROLE_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ROLE_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__ROLE_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__SECURE_ROW_LEVEL_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__SECURE_ROW_LEVEL_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__SECURE_ROW_LEVEL_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__SECURE_ROW_LEVEL_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__TAG_GROUP_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__TAG_GROUP_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__TAG_GROUP_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__TAG_GROUP_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_COMPANY_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_COMPANY_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_COMPANY_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_COMPANY_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_LOCATION_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_LOCATION_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_LOCATION_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_DEFAULT_LOCATION_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_GROUP_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_GROUP_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_GROUP_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_GROUP_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_OCCUPATION_TITLE_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_OCCUPATION_TITLE_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_OCCUPATION_TITLE_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_OCCUPATION_TITLE_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_RANK_TYPE_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_RANK_TYPE_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_RANK_TYPE_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID__USER_RANK_TYPE_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___COMPANY_OR_POOLING_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___COMPANY_OR_POOLING_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___COMPANY_OR_POOLING_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___COMPANY_OR_POOLING_ID_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___CREATED_DATE_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___CREATED_DATE_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___CREATED_DATE_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___CREATED_DATE_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_ACTIVE_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_ACTIVE_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_ACTIVE_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_ACTIVE_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DELETABLE_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DELETABLE_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DELETABLE_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DELETABLE_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DEPRECATED_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DEPRECATED_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DEPRECATED_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_DEPRECATED_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_MODIFIED_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_MODIFIED_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_MODIFIED_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_MODIFIED_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_UPDATABLE_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_UPDATABLE_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_UPDATABLE_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___IS_UPDATABLE_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___ROW_TIMESTAMP_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___ROW_TIMESTAMP_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___ROW_TIMESTAMP_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_BY_DEPARTMENT_OCCUPATION_TEMPLATE_ID___ROW_TIMESTAMP_DESC",
  DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC = "DEPARTMENT_OCCUPATION_TEMPLATE_ID_ASC",
  DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC = "DEPARTMENT_OCCUPATION_TEMPLATE_ID_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  EMAIL_ACCOUNT_ASC = "EMAIL_ACCOUNT_ASC",
  EMAIL_ACCOUNT_DESC = "EMAIL_ACCOUNT_DESC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_ASC",
  ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC = "ENTITY_DOCUMENTS_BY_ENTITY_ID__COUNT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__APP_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__APP_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__APP_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__APP_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__BUSINESS_UNIT_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__BUSINESS_UNIT_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__BUSINESS_UNIT_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__BUSINESS_UNIT_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_ACCOUNT_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_ACCOUNT_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_ACCOUNT_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_ACCOUNT_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_HOLDER_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_HOLDER_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_HOLDER_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__CARD_HOLDER_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMMENT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMMENT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMMENT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMMENT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMPANY_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMPANY_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMPANY_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__COMPANY_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DEPARTMENT_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DEPARTMENT_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DEPARTMENT_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DEPARTMENT_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_APPLIED_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_APPLIED_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_APPLIED_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_APPLIED_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_OVERAGE_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_OVERAGE_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_OVERAGE_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_OVERAGE_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_POSITION_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_POSITION_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_POSITION_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_POSITION_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_RETIRED_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_RETIRED_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_RETIRED_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_RETIRED_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_EXPLANATION_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_EXPLANATION_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_EXPLANATION_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_EXPLANATION_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_STATUS_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__DOCUMENT_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_DOCUMENT_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_DOCUMENT_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_DOCUMENT_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_DOCUMENT_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ENTITY_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__EXTRACTION_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__EXTRACTION_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__EXTRACTION_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__EXTRACTION_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_INDEX_INFORMATION_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_INDEX_INFORMATION_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_INDEX_INFORMATION_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_INDEX_INFORMATION_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_REFERENCE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_REFERENCE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_REFERENCE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__FILE_REFERENCE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ICON_TYPE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ICON_TYPE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ICON_TYPE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ICON_TYPE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_AMOUNT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_AMOUNT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_AMOUNT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_AMOUNT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NUMBER_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NUMBER_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NUMBER_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ACCOUNT_NUMBER_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_CONFIRMATION_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_CONFIRMATION_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_CONFIRMATION_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_CONFIRMATION_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_IBAN_CODE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_IBAN_CODE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_IBAN_CODE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_IBAN_CODE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ROUTING_NUMBER_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ROUTING_NUMBER_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ROUTING_NUMBER_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_ROUTING_NUMBER_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SORT_CODE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SORT_CODE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SORT_CODE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SORT_CODE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SWIFT_CODE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SWIFT_CODE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SWIFT_CODE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_BANK_SWIFT_CODE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_CURRENCY_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_CURRENCY_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_CURRENCY_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_CURRENCY_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_DESCRIPTION_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_DESCRIPTION_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_DESCRIPTION_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_DESCRIPTION_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_REFERENCE_NUMBER_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_REFERENCE_NUMBER_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_REFERENCE_NUMBER_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_REFERENCE_NUMBER_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_1_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_1_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_1_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_1_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_2_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_2_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_2_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_2_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_3_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_3_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_3_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_3_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_4_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_4_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_4_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_ACCOUNT_4_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_1_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_1_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_1_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_1_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_2_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_2_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_2_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_2_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_3_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_3_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_3_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_3_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_4_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_4_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_4_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_AMOUNT_4_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_1_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_1_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_1_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_1_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_2_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_2_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_2_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_2_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_3_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_3_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_3_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_3_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_4_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_4_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_4_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TAX_CODE_4_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TRANSACTION_DATE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TRANSACTION_DATE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TRANSACTION_DATE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__INDEX_TRANSACTION_DATE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_OVERAGE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_OVERAGE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_OVERAGE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_OVERAGE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_RETIRED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_RETIRED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_RETIRED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_APPLIED_AMOUNT_RETIRED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_SHAREABLE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_SHAREABLE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_SHAREABLE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__IS_SHAREABLE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ROW_SECURITY_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ROW_SECURITY_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ROW_SECURITY_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__ROW_SECURITY_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__VERIFIED_USER_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__VERIFIED_USER_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__VERIFIED_USER_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID__VERIFIED_USER_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CDN_THUMBNAIL_GUID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CDN_THUMBNAIL_GUID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CDN_THUMBNAIL_GUID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CDN_THUMBNAIL_GUID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USERID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USERID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USERID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USERID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USER_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USER_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USER_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_BY_USER_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_DATE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_DATE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_DATE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___CREATED_DATE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DIRECTORY_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DIRECTORY_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DIRECTORY_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DIRECTORY_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_CONTENTS_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_CONTENTS_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_CONTENTS_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_CONTENTS_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_FILE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_FILE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_FILE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_FILE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_POOL_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_TYPE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_TYPE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_TYPE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___DOCUMENT_TYPE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_DOCUMENT_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_DOCUMENT_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_DOCUMENT_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_DOCUMENT_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_ROUTE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_ROUTE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_ROUTE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_ROUTE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EMAIL_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORTED_BY_USER_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORTED_BY_USER_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORTED_BY_USER_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORTED_BY_USER_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_DATE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_DATE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_DATE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_DATE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_PROCESS_HISTORY_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_PROCESS_HISTORY_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_PROCESS_HISTORY_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___EXPORT_PROCESS_HISTORY_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_SIZE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_SIZE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_SIZE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_SIZE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_TYPE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_VIEWER_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_VIEWER_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_VIEWER_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FILE_VIEWER_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FULLY_QUALIFIED_DOCUMENT_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FULLY_QUALIFIED_DOCUMENT_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FULLY_QUALIFIED_DOCUMENT_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___FULLY_QUALIFIED_DOCUMENT_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACCOUNTING_DOCUMENT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACCOUNTING_DOCUMENT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACCOUNTING_DOCUMENT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACCOUNTING_DOCUMENT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACTIVE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACTIVE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACTIVE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_ACTIVE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DELETABLE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DELETABLE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DELETABLE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DELETABLE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DEPRECATED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DEPRECATED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DEPRECATED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DEPRECATED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_ACCUMULATED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_APPLIED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_APPLIED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_APPLIED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_APPLIED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_AMOUNT_AVAILABLE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_RECEIPT_ACCUMULATED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_SHARE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_SHARE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_SHARE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_SHARE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_TYPE_UPDATABLE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_TYPE_UPDATABLE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_TYPE_UPDATABLE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_DOCUMENT_TYPE_UPDATABLE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_PAYMENTS_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ACTIVE_TRANSACTIONS_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_AVAILABLE_DOCUMENTS_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_PAYMENTS_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_FOLDER_STATUS_ENTERED_TRANSACTIONS_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_INDEX_EXTRACTED_INFORMATION_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_INDEX_EXTRACTED_INFORMATION_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_INDEX_EXTRACTED_INFORMATION_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_INDEX_EXTRACTED_INFORMATION_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOADING_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOADING_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOADING_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOADING_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOAD_FAILED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOAD_FAILED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOAD_FAILED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_LOAD_FAILED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_MODIFIED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_MODIFIED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_MODIFIED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_MODIFIED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_ORIGIN_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_ORIGIN_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_ORIGIN_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_ORIGIN_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_RECIPIENT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_RECIPIENT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_RECIPIENT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PERSONAL_POOL_RECIPIENT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_VIEW_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_VIEW_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_VIEW_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_PROTECTED_VIEW_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_REPORTING_DOCUMENT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_REPORTING_DOCUMENT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_REPORTING_DOCUMENT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_REPORTING_DOCUMENT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_SELECTED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_SELECTED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_SELECTED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_SELECTED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_THUMBNAIL_CREATED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_THUMBNAIL_CREATED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_THUMBNAIL_CREATED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_THUMBNAIL_CREATED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_UPDATABLE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_UPDATABLE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_UPDATABLE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_UPDATABLE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_VERIFIED_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_VERIFIED_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_VERIFIED_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___IS_VERIFIED_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___LOOKUP_NAME_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___LOOKUP_NAME_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___LOOKUP_NAME_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___LOOKUP_NAME_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OUTPUT_FILE_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OUTPUT_FILE_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OUTPUT_FILE_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OUTPUT_FILE_NAME_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OVERRIDING_LANGUAGE_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OVERRIDING_LANGUAGE_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OVERRIDING_LANGUAGE_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___OVERRIDING_LANGUAGE_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___POOL_ENTITY_DOCUMENT_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___POOL_ENTITY_DOCUMENT_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___POOL_ENTITY_DOCUMENT_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___POOL_ENTITY_DOCUMENT_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___RECIPIENT_USER_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___RECIPIENT_USER_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___RECIPIENT_USER_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___RECIPIENT_USER_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___ROW_TIMESTAMP_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___ROW_TIMESTAMP_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___ROW_TIMESTAMP_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___ROW_TIMESTAMP_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SEARCH_DOCUMENT_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SEARCH_DOCUMENT_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SEARCH_DOCUMENT_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SEARCH_DOCUMENT_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_ID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_ID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_ID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SHARED_BY_USER_ID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SIGNED_EXECUTED_DATE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SIGNED_EXECUTED_DATE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SIGNED_EXECUTED_DATE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SIGNED_EXECUTED_DATE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SORT_EXPORT_DATE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SORT_EXPORT_DATE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SORT_EXPORT_DATE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___SORT_EXPORT_DATE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USERID_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USERID_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USERID_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USERID_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USER_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USER_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USER_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOADED_BY_USER_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOAD_DATE_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOAD_DATE_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOAD_DATE_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___UPLOAD_DATE_DESC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___VERIFIED_USER_NAME_ASC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___VERIFIED_USER_NAME_ASC",
  ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___VERIFIED_USER_NAME_DESC = "ENTITY_DOCUMENT_BY_USER_SIGNATURE_ENTITY_DOCUMENT_ID___VERIFIED_USER_NAME_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVITED_ASC = "INVITED_ASC",
  INVITED_DESC = "INVITED_DESC",
  IS_ADMINISTRATOR_ASC = "IS_ADMINISTRATOR_ASC",
  IS_ADMINISTRATOR_DESC = "IS_ADMINISTRATOR_DESC",
  IS_DRAFT_ASC = "IS_DRAFT_ASC",
  IS_DRAFT_DESC = "IS_DRAFT_DESC",
  IS_INVITABLE_ASC = "IS_INVITABLE_ASC",
  IS_INVITABLE_DESC = "IS_INVITABLE_DESC",
  IS_PRIMARY_ASC = "IS_PRIMARY_ASC",
  IS_PRIMARY_DESC = "IS_PRIMARY_DESC",
  IS_RESTRICTED_USER_ASC = "IS_RESTRICTED_USER_ASC",
  IS_RESTRICTED_USER_DESC = "IS_RESTRICTED_USER_DESC",
  IS_SIGNIN_ALLOWED_ASC = "IS_SIGNIN_ALLOWED_ASC",
  IS_SIGNIN_ALLOWED_DESC = "IS_SIGNIN_ALLOWED_DESC",
  IS_SPOT_RATE_PREFERRED_ASC = "IS_SPOT_RATE_PREFERRED_ASC",
  IS_SPOT_RATE_PREFERRED_DESC = "IS_SPOT_RATE_PREFERRED_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  NOTES_BY_ENTITY_ID__COUNT_ASC = "NOTES_BY_ENTITY_ID__COUNT_ASC",
  NOTES_BY_ENTITY_ID__COUNT_DESC = "NOTES_BY_ENTITY_ID__COUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  ROLE_BY_ROLE_ID__ABBREVIATION_ASC = "ROLE_BY_ROLE_ID__ABBREVIATION_ASC",
  ROLE_BY_ROLE_ID__ABBREVIATION_DESC = "ROLE_BY_ROLE_ID__ABBREVIATION_DESC",
  ROLE_BY_ROLE_ID__DESCRIPTION_ASC = "ROLE_BY_ROLE_ID__DESCRIPTION_ASC",
  ROLE_BY_ROLE_ID__DESCRIPTION_DESC = "ROLE_BY_ROLE_ID__DESCRIPTION_DESC",
  ROLE_BY_ROLE_ID__ID_ASC = "ROLE_BY_ROLE_ID__ID_ASC",
  ROLE_BY_ROLE_ID__ID_DESC = "ROLE_BY_ROLE_ID__ID_DESC",
  ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_DOCUMENT_MANAGEMENT_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_DRAFT_ASC = "ROLE_BY_ROLE_ID__IS_DRAFT_ASC",
  ROLE_BY_ROLE_ID__IS_DRAFT_DESC = "ROLE_BY_ROLE_ID__IS_DRAFT_DESC",
  ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_ENVIRONMENTAL_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_HISTORY_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_MESSAGING_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_NOTES_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_NOTIFICATIONS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_RATINGS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_ASC = "ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_DESC = "ROLE_BY_ROLE_ID__IS_TAGS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID__NAME_ASC = "ROLE_BY_ROLE_ID__NAME_ASC",
  ROLE_BY_ROLE_ID__NAME_DESC = "ROLE_BY_ROLE_ID__NAME_DESC",
  ROLE_BY_ROLE_ID__ROLE_TYPE_ASC = "ROLE_BY_ROLE_ID__ROLE_TYPE_ASC",
  ROLE_BY_ROLE_ID__ROLE_TYPE_DESC = "ROLE_BY_ROLE_ID__ROLE_TYPE_DESC",
  ROLE_BY_ROLE_ID__SORT_ORDER_ASC = "ROLE_BY_ROLE_ID__SORT_ORDER_ASC",
  ROLE_BY_ROLE_ID__SORT_ORDER_DESC = "ROLE_BY_ROLE_ID__SORT_ORDER_DESC",
  ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_ASC = "ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_ASC",
  ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_DESC = "ROLE_BY_ROLE_ID___COMPANY_OR_POOLING_ID_DESC",
  ROLE_BY_ROLE_ID___CREATED_DATE_ASC = "ROLE_BY_ROLE_ID___CREATED_DATE_ASC",
  ROLE_BY_ROLE_ID___CREATED_DATE_DESC = "ROLE_BY_ROLE_ID___CREATED_DATE_DESC",
  ROLE_BY_ROLE_ID___IS_ACTIVE_ASC = "ROLE_BY_ROLE_ID___IS_ACTIVE_ASC",
  ROLE_BY_ROLE_ID___IS_ACTIVE_DESC = "ROLE_BY_ROLE_ID___IS_ACTIVE_DESC",
  ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_ASC = "ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_ASC",
  ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_DESC = "ROLE_BY_ROLE_ID___IS_APPROVAL_REVOCABLE_DESC",
  ROLE_BY_ROLE_ID___IS_APPROVED_ASC = "ROLE_BY_ROLE_ID___IS_APPROVED_ASC",
  ROLE_BY_ROLE_ID___IS_APPROVED_DESC = "ROLE_BY_ROLE_ID___IS_APPROVED_DESC",
  ROLE_BY_ROLE_ID___IS_DELETABLE_ASC = "ROLE_BY_ROLE_ID___IS_DELETABLE_ASC",
  ROLE_BY_ROLE_ID___IS_DELETABLE_DESC = "ROLE_BY_ROLE_ID___IS_DELETABLE_DESC",
  ROLE_BY_ROLE_ID___IS_DEPRECATED_ASC = "ROLE_BY_ROLE_ID___IS_DEPRECATED_ASC",
  ROLE_BY_ROLE_ID___IS_DEPRECATED_DESC = "ROLE_BY_ROLE_ID___IS_DEPRECATED_DESC",
  ROLE_BY_ROLE_ID___IS_MODIFIED_ASC = "ROLE_BY_ROLE_ID___IS_MODIFIED_ASC",
  ROLE_BY_ROLE_ID___IS_MODIFIED_DESC = "ROLE_BY_ROLE_ID___IS_MODIFIED_DESC",
  ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "ROLE_BY_ROLE_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  ROLE_BY_ROLE_ID___IS_UPDATABLE_ASC = "ROLE_BY_ROLE_ID___IS_UPDATABLE_ASC",
  ROLE_BY_ROLE_ID___IS_UPDATABLE_DESC = "ROLE_BY_ROLE_ID___IS_UPDATABLE_DESC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "ROLE_BY_ROLE_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVER_ASC = "ROLE_BY_ROLE_ID___IS_USER_APPROVER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_APPROVER_DESC = "ROLE_BY_ROLE_ID___IS_USER_APPROVER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_OBSERVER_ASC = "ROLE_BY_ROLE_ID___IS_USER_OBSERVER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_OBSERVER_DESC = "ROLE_BY_ROLE_ID___IS_USER_OBSERVER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "ROLE_BY_ROLE_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  ROLE_BY_ROLE_ID___IS_USER_REQUESTER_ASC = "ROLE_BY_ROLE_ID___IS_USER_REQUESTER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_REQUESTER_DESC = "ROLE_BY_ROLE_ID___IS_USER_REQUESTER_DESC",
  ROLE_BY_ROLE_ID___IS_USER_REVOKER_ASC = "ROLE_BY_ROLE_ID___IS_USER_REVOKER_ASC",
  ROLE_BY_ROLE_ID___IS_USER_REVOKER_DESC = "ROLE_BY_ROLE_ID___IS_USER_REVOKER_DESC",
  ROLE_BY_ROLE_ID___IS_VALIDATED_ASC = "ROLE_BY_ROLE_ID___IS_VALIDATED_ASC",
  ROLE_BY_ROLE_ID___IS_VALIDATED_DESC = "ROLE_BY_ROLE_ID___IS_VALIDATED_DESC",
  ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_ASC = "ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_ASC",
  ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_DESC = "ROLE_BY_ROLE_ID___IS_WAITING_APPROVAL_DESC",
  ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_ASC = "ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_ASC",
  ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_DESC = "ROLE_BY_ROLE_ID___IS_WAITING_VALIDATION_DESC",
  ROLE_BY_ROLE_ID___ROW_TIMESTAMP_ASC = "ROLE_BY_ROLE_ID___ROW_TIMESTAMP_ASC",
  ROLE_BY_ROLE_ID___ROW_TIMESTAMP_DESC = "ROLE_BY_ROLE_ID___ROW_TIMESTAMP_DESC",
  ROLE_BY_ROLE_ID___STATUS_TYPE_ID_ASC = "ROLE_BY_ROLE_ID___STATUS_TYPE_ID_ASC",
  ROLE_BY_ROLE_ID___STATUS_TYPE_ID_DESC = "ROLE_BY_ROLE_ID___STATUS_TYPE_ID_DESC",
  ROLE_ID_ASC = "ROLE_ID_ASC",
  ROLE_ID_DESC = "ROLE_ID_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__DESCRIPTION_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__ID_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_MONITORED_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__IS_VENDOR_ALIAS_ACCESS_ALLOWED_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__NAME_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID__SECURITY_LEVEL_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_ACTIVE_DESC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_ASC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_ASC",
  SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_DESC = "SECURE_ROW_LEVEL_BY_SECURE_ROW_LEVEL_ID___IS_DEPRECATED_DESC",
  SECURE_ROW_LEVEL_ID_ASC = "SECURE_ROW_LEVEL_ID_ASC",
  SECURE_ROW_LEVEL_ID_DESC = "SECURE_ROW_LEVEL_ID_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__DESCRIPTION_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__ID_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__ID_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__ID_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__ID_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__IS_ADDED_TAGS_RECEIVED_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID__NAME_ASC = "TAG_GROUP_BY_TAG_GROUP_ID__NAME_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID__NAME_DESC = "TAG_GROUP_BY_TAG_GROUP_ID__NAME_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___COMPANY_OR_POOLING_ID_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___CREATED_DATE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_ACTIVE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DELETABLE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_DEPRECATED_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_MODIFIED_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___IS_UPDATABLE_DESC",
  TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_ASC = "TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_ASC",
  TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_DESC = "TAG_GROUP_BY_TAG_GROUP_ID___ROW_TIMESTAMP_DESC",
  TAG_GROUP_ID_ASC = "TAG_GROUP_ID_ASC",
  TAG_GROUP_ID_DESC = "TAG_GROUP_ID_DESC",
  TENANT_USER_ID_ASC = "TENANT_USER_ID_ASC",
  TENANT_USER_ID_DESC = "TENANT_USER_ID_DESC",
  TRADING_PARTNER_ID_ASC = "TRADING_PARTNER_ID_ASC",
  TRADING_PARTNER_ID_DESC = "TRADING_PARTNER_ID_DESC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_ASC",
  USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC = "USER_APPROVALS_BY_ENTITY_ID__COUNT_DESC",
  USER_APPROVAL_GRANTS_BY_USER_PROFILE_ID__COUNT_ASC = "USER_APPROVAL_GRANTS_BY_USER_PROFILE_ID__COUNT_ASC",
  USER_APPROVAL_GRANTS_BY_USER_PROFILE_ID__COUNT_DESC = "USER_APPROVAL_GRANTS_BY_USER_PROFILE_ID__COUNT_DESC",
  USER_BY_TENANT_USER_ID__DIGITAL_SIGNATURE_ASC = "USER_BY_TENANT_USER_ID__DIGITAL_SIGNATURE_ASC",
  USER_BY_TENANT_USER_ID__DIGITAL_SIGNATURE_DESC = "USER_BY_TENANT_USER_ID__DIGITAL_SIGNATURE_DESC",
  USER_BY_TENANT_USER_ID__EMAIL_ACCOUNT_ASC = "USER_BY_TENANT_USER_ID__EMAIL_ACCOUNT_ASC",
  USER_BY_TENANT_USER_ID__EMAIL_ACCOUNT_DESC = "USER_BY_TENANT_USER_ID__EMAIL_ACCOUNT_DESC",
  USER_BY_TENANT_USER_ID__ID_ASC = "USER_BY_TENANT_USER_ID__ID_ASC",
  USER_BY_TENANT_USER_ID__ID_DESC = "USER_BY_TENANT_USER_ID__ID_DESC",
  USER_BY_TENANT_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC = "USER_BY_TENANT_USER_ID__IS_ACCESS_ALL_COMPANIES_ASC",
  USER_BY_TENANT_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC = "USER_BY_TENANT_USER_ID__IS_ACCESS_ALL_COMPANIES_DESC",
  USER_BY_TENANT_USER_ID__IS_DRAFT_ASC = "USER_BY_TENANT_USER_ID__IS_DRAFT_ASC",
  USER_BY_TENANT_USER_ID__IS_DRAFT_DESC = "USER_BY_TENANT_USER_ID__IS_DRAFT_DESC",
  USER_BY_TENANT_USER_ID__IS_RESTRICTED_USER_ASC = "USER_BY_TENANT_USER_ID__IS_RESTRICTED_USER_ASC",
  USER_BY_TENANT_USER_ID__IS_RESTRICTED_USER_DESC = "USER_BY_TENANT_USER_ID__IS_RESTRICTED_USER_DESC",
  USER_BY_TENANT_USER_ID__IS_SIGNIN_ALLOWED_ASC = "USER_BY_TENANT_USER_ID__IS_SIGNIN_ALLOWED_ASC",
  USER_BY_TENANT_USER_ID__IS_SIGNIN_ALLOWED_DESC = "USER_BY_TENANT_USER_ID__IS_SIGNIN_ALLOWED_DESC",
  USER_BY_TENANT_USER_ID__LANGUAGE_ID_ASC = "USER_BY_TENANT_USER_ID__LANGUAGE_ID_ASC",
  USER_BY_TENANT_USER_ID__LANGUAGE_ID_DESC = "USER_BY_TENANT_USER_ID__LANGUAGE_ID_DESC",
  USER_BY_TENANT_USER_ID__NAME_ASC = "USER_BY_TENANT_USER_ID__NAME_ASC",
  USER_BY_TENANT_USER_ID__NAME_DESC = "USER_BY_TENANT_USER_ID__NAME_DESC",
  USER_BY_TENANT_USER_ID__TENANT_ID_ASC = "USER_BY_TENANT_USER_ID__TENANT_ID_ASC",
  USER_BY_TENANT_USER_ID__TENANT_ID_DESC = "USER_BY_TENANT_USER_ID__TENANT_ID_DESC",
  USER_BY_TENANT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "USER_BY_TENANT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  USER_BY_TENANT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "USER_BY_TENANT_USER_ID__USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
  USER_BY_TENANT_USER_ID___CREATED_BY_USERID_ASC = "USER_BY_TENANT_USER_ID___CREATED_BY_USERID_ASC",
  USER_BY_TENANT_USER_ID___CREATED_BY_USERID_DESC = "USER_BY_TENANT_USER_ID___CREATED_BY_USERID_DESC",
  USER_BY_TENANT_USER_ID___CREATED_DATE_ASC = "USER_BY_TENANT_USER_ID___CREATED_DATE_ASC",
  USER_BY_TENANT_USER_ID___CREATED_DATE_DESC = "USER_BY_TENANT_USER_ID___CREATED_DATE_DESC",
  USER_BY_TENANT_USER_ID___IS_ACTIVE_ASC = "USER_BY_TENANT_USER_ID___IS_ACTIVE_ASC",
  USER_BY_TENANT_USER_ID___IS_ACTIVE_DESC = "USER_BY_TENANT_USER_ID___IS_ACTIVE_DESC",
  USER_BY_TENANT_USER_ID___IS_ACTIVE_TENANT_USER_ASC = "USER_BY_TENANT_USER_ID___IS_ACTIVE_TENANT_USER_ASC",
  USER_BY_TENANT_USER_ID___IS_ACTIVE_TENANT_USER_DESC = "USER_BY_TENANT_USER_ID___IS_ACTIVE_TENANT_USER_DESC",
  USER_BY_TENANT_USER_ID___IS_APPROVAL_REVOCABLE_ASC = "USER_BY_TENANT_USER_ID___IS_APPROVAL_REVOCABLE_ASC",
  USER_BY_TENANT_USER_ID___IS_APPROVAL_REVOCABLE_DESC = "USER_BY_TENANT_USER_ID___IS_APPROVAL_REVOCABLE_DESC",
  USER_BY_TENANT_USER_ID___IS_APPROVED_ASC = "USER_BY_TENANT_USER_ID___IS_APPROVED_ASC",
  USER_BY_TENANT_USER_ID___IS_APPROVED_DESC = "USER_BY_TENANT_USER_ID___IS_APPROVED_DESC",
  USER_BY_TENANT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC = "USER_BY_TENANT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_ASC",
  USER_BY_TENANT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC = "USER_BY_TENANT_USER_ID___IS_AUTHORIZED_EMAIL_ACCOUNT_DESC",
  USER_BY_TENANT_USER_ID___IS_AUTHORIZED_USER_ASC = "USER_BY_TENANT_USER_ID___IS_AUTHORIZED_USER_ASC",
  USER_BY_TENANT_USER_ID___IS_AUTHORIZED_USER_DESC = "USER_BY_TENANT_USER_ID___IS_AUTHORIZED_USER_DESC",
  USER_BY_TENANT_USER_ID___IS_DELETABLE_ASC = "USER_BY_TENANT_USER_ID___IS_DELETABLE_ASC",
  USER_BY_TENANT_USER_ID___IS_DELETABLE_DESC = "USER_BY_TENANT_USER_ID___IS_DELETABLE_DESC",
  USER_BY_TENANT_USER_ID___IS_DEPRECATED_ASC = "USER_BY_TENANT_USER_ID___IS_DEPRECATED_ASC",
  USER_BY_TENANT_USER_ID___IS_DEPRECATED_DESC = "USER_BY_TENANT_USER_ID___IS_DEPRECATED_DESC",
  USER_BY_TENANT_USER_ID___IS_EMAIL_EXISTS_ASC = "USER_BY_TENANT_USER_ID___IS_EMAIL_EXISTS_ASC",
  USER_BY_TENANT_USER_ID___IS_EMAIL_EXISTS_DESC = "USER_BY_TENANT_USER_ID___IS_EMAIL_EXISTS_DESC",
  USER_BY_TENANT_USER_ID___IS_MODIFIED_ASC = "USER_BY_TENANT_USER_ID___IS_MODIFIED_ASC",
  USER_BY_TENANT_USER_ID___IS_MODIFIED_DESC = "USER_BY_TENANT_USER_ID___IS_MODIFIED_DESC",
  USER_BY_TENANT_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC = "USER_BY_TENANT_USER_ID___IS_STAGED_APPROVAL_REQUEST_ASC",
  USER_BY_TENANT_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC = "USER_BY_TENANT_USER_ID___IS_STAGED_APPROVAL_REQUEST_DESC",
  USER_BY_TENANT_USER_ID___IS_TENANT_USER_EXISTS_ASC = "USER_BY_TENANT_USER_ID___IS_TENANT_USER_EXISTS_ASC",
  USER_BY_TENANT_USER_ID___IS_TENANT_USER_EXISTS_DESC = "USER_BY_TENANT_USER_ID___IS_TENANT_USER_EXISTS_DESC",
  USER_BY_TENANT_USER_ID___IS_UPDATABLE_ASC = "USER_BY_TENANT_USER_ID___IS_UPDATABLE_ASC",
  USER_BY_TENANT_USER_ID___IS_UPDATABLE_DESC = "USER_BY_TENANT_USER_ID___IS_UPDATABLE_DESC",
  USER_BY_TENANT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC = "USER_BY_TENANT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_ASC",
  USER_BY_TENANT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC = "USER_BY_TENANT_USER_ID___IS_USER_APPROVAL_TREE_VIEWER_DESC",
  USER_BY_TENANT_USER_ID___IS_USER_APPROVER_ASC = "USER_BY_TENANT_USER_ID___IS_USER_APPROVER_ASC",
  USER_BY_TENANT_USER_ID___IS_USER_APPROVER_DESC = "USER_BY_TENANT_USER_ID___IS_USER_APPROVER_DESC",
  USER_BY_TENANT_USER_ID___IS_USER_OBSERVER_ASC = "USER_BY_TENANT_USER_ID___IS_USER_OBSERVER_ASC",
  USER_BY_TENANT_USER_ID___IS_USER_OBSERVER_DESC = "USER_BY_TENANT_USER_ID___IS_USER_OBSERVER_DESC",
  USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "USER_BY_TENANT_USER_ID___IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  USER_BY_TENANT_USER_ID___IS_USER_REQUESTER_ASC = "USER_BY_TENANT_USER_ID___IS_USER_REQUESTER_ASC",
  USER_BY_TENANT_USER_ID___IS_USER_REQUESTER_DESC = "USER_BY_TENANT_USER_ID___IS_USER_REQUESTER_DESC",
  USER_BY_TENANT_USER_ID___IS_USER_REVOKER_ASC = "USER_BY_TENANT_USER_ID___IS_USER_REVOKER_ASC",
  USER_BY_TENANT_USER_ID___IS_USER_REVOKER_DESC = "USER_BY_TENANT_USER_ID___IS_USER_REVOKER_DESC",
  USER_BY_TENANT_USER_ID___IS_VALIDATED_ASC = "USER_BY_TENANT_USER_ID___IS_VALIDATED_ASC",
  USER_BY_TENANT_USER_ID___IS_VALIDATED_DESC = "USER_BY_TENANT_USER_ID___IS_VALIDATED_DESC",
  USER_BY_TENANT_USER_ID___IS_WAITING_APPROVAL_ASC = "USER_BY_TENANT_USER_ID___IS_WAITING_APPROVAL_ASC",
  USER_BY_TENANT_USER_ID___IS_WAITING_APPROVAL_DESC = "USER_BY_TENANT_USER_ID___IS_WAITING_APPROVAL_DESC",
  USER_BY_TENANT_USER_ID___IS_WAITING_VALIDATION_ASC = "USER_BY_TENANT_USER_ID___IS_WAITING_VALIDATION_ASC",
  USER_BY_TENANT_USER_ID___IS_WAITING_VALIDATION_DESC = "USER_BY_TENANT_USER_ID___IS_WAITING_VALIDATION_DESC",
  USER_BY_TENANT_USER_ID___ROW_TIMESTAMP_ASC = "USER_BY_TENANT_USER_ID___ROW_TIMESTAMP_ASC",
  USER_BY_TENANT_USER_ID___ROW_TIMESTAMP_DESC = "USER_BY_TENANT_USER_ID___ROW_TIMESTAMP_DESC",
  USER_BY_TENANT_USER_ID___STATUS_TYPE_ID_ASC = "USER_BY_TENANT_USER_ID___STATUS_TYPE_ID_ASC",
  USER_BY_TENANT_USER_ID___STATUS_TYPE_ID_DESC = "USER_BY_TENANT_USER_ID___STATUS_TYPE_ID_DESC",
  USER_DEFAULTS_BY_DELEGATE_USER_PROFILE_ID__COUNT_ASC = "USER_DEFAULTS_BY_DELEGATE_USER_PROFILE_ID__COUNT_ASC",
  USER_DEFAULTS_BY_DELEGATE_USER_PROFILE_ID__COUNT_DESC = "USER_DEFAULTS_BY_DELEGATE_USER_PROFILE_ID__COUNT_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC = "USER_GROUP_BY_USER_GROUP_ID__ABBREVIATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_ASC",
  USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC = "USER_GROUP_BY_USER_GROUP_ID__DESCRIPTION_DESC",
  USER_GROUP_BY_USER_GROUP_ID__ID_ASC = "USER_GROUP_BY_USER_GROUP_ID__ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID__ID_DESC = "USER_GROUP_BY_USER_GROUP_ID__ID_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_ACCESS_ONLY_TO_CREATED_ROWS_DESC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_ASC",
  USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC = "USER_GROUP_BY_USER_GROUP_ID__IS_DRAFT_DESC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_ASC = "USER_GROUP_BY_USER_GROUP_ID__NAME_ASC",
  USER_GROUP_BY_USER_GROUP_ID__NAME_DESC = "USER_GROUP_BY_USER_GROUP_ID__NAME_DESC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_ASC",
  USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC = "USER_GROUP_BY_USER_GROUP_ID__SORT_ORDER_DESC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC = "USER_GROUP_BY_USER_GROUP_ID___CREATED_DATE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_ACTIVE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_APPROVED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DELETABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_DEPRECATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_MODIFIED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_UPDATABLE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALIDATED_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_VALID_COMPLETE_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_APPROVAL_DESC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_ASC",
  USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC = "USER_GROUP_BY_USER_GROUP_ID___IS_WAITING_VALIDATION_DESC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_ASC",
  USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC = "USER_GROUP_BY_USER_GROUP_ID___ROW_TIMESTAMP_DESC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_ASC",
  USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC = "USER_GROUP_BY_USER_GROUP_ID___STATUS_TYPE_ID_DESC",
  USER_GROUP_ID_ASC = "USER_GROUP_ID_ASC",
  USER_GROUP_ID_DESC = "USER_GROUP_ID_DESC",
  USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__ID_ASC = "USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__ID_ASC",
  USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__ID_DESC = "USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__ID_DESC",
  USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__STATUS_TYPE_ASC = "USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__STATUS_TYPE_DESC = "USER_INVITE_STATUS_TYPE_BY_INVITATION_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_ASC",
  USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC = "USER_NOTIFICATIONS_BY_ENTITY_ID__COUNT_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__ID_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__SORT_ORDER_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_OCCUPATION_TITLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID__USER_RANK_TYPE_ID_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___CREATED_DATE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_ACTIVE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DELETABLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_DEPRECATED_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___IS_UPDATABLE_DESC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_ASC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_ASC",
  USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_DESC = "USER_OCCUPATION_TITLE_BY_USER_OCCUPATION_TITLE_ID___ROW_TIMESTAMP_DESC",
  USER_OCCUPATION_TITLE_ID_ASC = "USER_OCCUPATION_TITLE_ID_ASC",
  USER_OCCUPATION_TITLE_ID_DESC = "USER_OCCUPATION_TITLE_ID_DESC",
  USER_PROFILE_HISTORIES_BY_ENTITY_ID__COUNT_ASC = "USER_PROFILE_HISTORIES_BY_ENTITY_ID__COUNT_ASC",
  USER_PROFILE_HISTORIES_BY_ENTITY_ID__COUNT_DESC = "USER_PROFILE_HISTORIES_BY_ENTITY_ID__COUNT_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__ID_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_ADMINISTRATOR_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_INTERNAL_USE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__IS_SETUP_USER_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SECURITY_LEVEL_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__SORT_ORDER_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID__USER_RANK_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_ACTIVE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DELETABLE_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_DEPRECATED_DESC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_ASC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_ASC",
  USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_DESC = "USER_RANK_TYPE_BY_USER_RANK_TYPE_ID___IS_UPDATABLE_DESC",
  USER_RANK_TYPE_ID_ASC = "USER_RANK_TYPE_ID_ASC",
  USER_RANK_TYPE_ID_DESC = "USER_RANK_TYPE_ID_DESC",
  USER_STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC = "USER_STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_ASC",
  USER_STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC = "USER_STATUS_TYPE_BY_STATUS_TYPE_ID__APPROVAL_STATE_DESC",
  USER_STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC = "USER_STATUS_TYPE_BY_STATUS_TYPE_ID__ID_ASC",
  USER_STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC = "USER_STATUS_TYPE_BY_STATUS_TYPE_ID__ID_DESC",
  USER_STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC = "USER_STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC",
  USER_STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC = "USER_STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC",
  _COMPANY_ID_ASC = "_COMPANY_ID_ASC",
  _COMPANY_ID_DESC = "_COMPANY_ID_DESC",
  _CREATED_BY_USERID_ASC = "_CREATED_BY_USERID_ASC",
  _CREATED_BY_USERID_DESC = "_CREATED_BY_USERID_DESC",
  _CREATED_DATE_ASC = "_CREATED_DATE_ASC",
  _CREATED_DATE_DESC = "_CREATED_DATE_DESC",
  _INVITATION_STATUS_TYPE_ID_ASC = "_INVITATION_STATUS_TYPE_ID_ASC",
  _INVITATION_STATUS_TYPE_ID_DESC = "_INVITATION_STATUS_TYPE_ID_DESC",
  _IS_ACTIVE_ASC = "_IS_ACTIVE_ASC",
  _IS_ACTIVE_DESC = "_IS_ACTIVE_DESC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_ASC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_ASC",
  _IS_APPROVAL_DOCUMENTS_REQUIRED_DESC = "_IS_APPROVAL_DOCUMENTS_REQUIRED_DESC",
  _IS_APPROVAL_HISTORY_EXISTS_ASC = "_IS_APPROVAL_HISTORY_EXISTS_ASC",
  _IS_APPROVAL_HISTORY_EXISTS_DESC = "_IS_APPROVAL_HISTORY_EXISTS_DESC",
  _IS_APPROVAL_REVOCABLE_ASC = "_IS_APPROVAL_REVOCABLE_ASC",
  _IS_APPROVAL_REVOCABLE_DESC = "_IS_APPROVAL_REVOCABLE_DESC",
  _IS_APPROVED_ASC = "_IS_APPROVED_ASC",
  _IS_APPROVED_DESC = "_IS_APPROVED_DESC",
  _IS_AUTHENTICATED_USER_ASC = "_IS_AUTHENTICATED_USER_ASC",
  _IS_AUTHENTICATED_USER_DESC = "_IS_AUTHENTICATED_USER_DESC",
  _IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC = "_IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_ASC",
  _IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC = "_IS_CONTROLLED_BY_DOCUMENT_OCCUPATION_TEMPLATE_DESC",
  _IS_DELETABLE_ASC = "_IS_DELETABLE_ASC",
  _IS_DELETABLE_DESC = "_IS_DELETABLE_DESC",
  _IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC = "_IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_ASC",
  _IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC = "_IS_DEPARTMENT_OCCUPATION_TEMPLATE_ONLY_DESC",
  _IS_DEPRECATED_ASC = "_IS_DEPRECATED_ASC",
  _IS_DEPRECATED_DESC = "_IS_DEPRECATED_DESC",
  _IS_DOCUMENTS_EXIST_ASC = "_IS_DOCUMENTS_EXIST_ASC",
  _IS_DOCUMENTS_EXIST_DESC = "_IS_DOCUMENTS_EXIST_DESC",
  _IS_MODIFIED_ASC = "_IS_MODIFIED_ASC",
  _IS_MODIFIED_DESC = "_IS_MODIFIED_DESC",
  _IS_NOTES_EXIST_ASC = "_IS_NOTES_EXIST_ASC",
  _IS_NOTES_EXIST_DESC = "_IS_NOTES_EXIST_DESC",
  _IS_PROFILE_VALIDATED_ASC = "_IS_PROFILE_VALIDATED_ASC",
  _IS_PROFILE_VALIDATED_DESC = "_IS_PROFILE_VALIDATED_DESC",
  _IS_STAGED_APPROVAL_REQUEST_ASC = "_IS_STAGED_APPROVAL_REQUEST_ASC",
  _IS_STAGED_APPROVAL_REQUEST_DESC = "_IS_STAGED_APPROVAL_REQUEST_DESC",
  _IS_STAGED_TO_INVITE_ASC = "_IS_STAGED_TO_INVITE_ASC",
  _IS_STAGED_TO_INVITE_DESC = "_IS_STAGED_TO_INVITE_DESC",
  _IS_UPDATABLE_ASC = "_IS_UPDATABLE_ASC",
  _IS_UPDATABLE_DESC = "_IS_UPDATABLE_DESC",
  _IS_USER_APPROVAL_TREE_VIEWER_ASC = "_IS_USER_APPROVAL_TREE_VIEWER_ASC",
  _IS_USER_APPROVAL_TREE_VIEWER_DESC = "_IS_USER_APPROVAL_TREE_VIEWER_DESC",
  _IS_USER_APPROVER_ASC = "_IS_USER_APPROVER_ASC",
  _IS_USER_APPROVER_DESC = "_IS_USER_APPROVER_DESC",
  _IS_USER_OBSERVER_ASC = "_IS_USER_OBSERVER_ASC",
  _IS_USER_OBSERVER_DESC = "_IS_USER_OBSERVER_DESC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_ASC",
  _IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC = "_IS_USER_PERSONAL_APPROVERS_ALLOWED_DESC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_ASC",
  _IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC = "_IS_USER_PERSONAL_APPROVERS_REQUIRED_DESC",
  _IS_USER_REQUESTER_ASC = "_IS_USER_REQUESTER_ASC",
  _IS_USER_REQUESTER_DESC = "_IS_USER_REQUESTER_DESC",
  _IS_USER_REVOKER_ASC = "_IS_USER_REVOKER_ASC",
  _IS_USER_REVOKER_DESC = "_IS_USER_REVOKER_DESC",
  _IS_USER_SIGNATURE_REQUIRED_ASC = "_IS_USER_SIGNATURE_REQUIRED_ASC",
  _IS_USER_SIGNATURE_REQUIRED_DESC = "_IS_USER_SIGNATURE_REQUIRED_DESC",
  _IS_USER_SIGNATURE_UPDATABLE_ASC = "_IS_USER_SIGNATURE_UPDATABLE_ASC",
  _IS_USER_SIGNATURE_UPDATABLE_DESC = "_IS_USER_SIGNATURE_UPDATABLE_DESC",
  _IS_VALIDATED_ASC = "_IS_VALIDATED_ASC",
  _IS_VALIDATED_DESC = "_IS_VALIDATED_DESC",
  _IS_VALID_COMMON_TENANT_USER_ASC = "_IS_VALID_COMMON_TENANT_USER_ASC",
  _IS_VALID_COMMON_TENANT_USER_DESC = "_IS_VALID_COMMON_TENANT_USER_DESC",
  _IS_VALID_COMMON_TENANT_USER_PROFILE_ASC = "_IS_VALID_COMMON_TENANT_USER_PROFILE_ASC",
  _IS_VALID_COMMON_TENANT_USER_PROFILE_DESC = "_IS_VALID_COMMON_TENANT_USER_PROFILE_DESC",
  _IS_VALID_TENANT_USER_ASC = "_IS_VALID_TENANT_USER_ASC",
  _IS_VALID_TENANT_USER_DESC = "_IS_VALID_TENANT_USER_DESC",
  _IS_VALID_TENANT_USER_PROFILE_ASC = "_IS_VALID_TENANT_USER_PROFILE_ASC",
  _IS_VALID_TENANT_USER_PROFILE_DESC = "_IS_VALID_TENANT_USER_PROFILE_DESC",
  _IS_VALID_USER_ASC = "_IS_VALID_USER_ASC",
  _IS_VALID_USER_DESC = "_IS_VALID_USER_DESC",
  _IS_VALID_USER_PROFILE_ASC = "_IS_VALID_USER_PROFILE_ASC",
  _IS_VALID_USER_PROFILE_DESC = "_IS_VALID_USER_PROFILE_DESC",
  _IS_WAITING_APPROVAL_ASC = "_IS_WAITING_APPROVAL_ASC",
  _IS_WAITING_APPROVAL_DESC = "_IS_WAITING_APPROVAL_DESC",
  _IS_WAITING_VALIDATION_ASC = "_IS_WAITING_VALIDATION_ASC",
  _IS_WAITING_VALIDATION_DESC = "_IS_WAITING_VALIDATION_DESC",
  _LAST_USED_DATE_ASC = "_LAST_USED_DATE_ASC",
  _LAST_USED_DATE_DESC = "_LAST_USED_DATE_DESC",
  _PENDING_APPROVERS_ASC = "_PENDING_APPROVERS_ASC",
  _PENDING_APPROVERS_DESC = "_PENDING_APPROVERS_DESC",
  _REQUIRED_APPROVAL_DOCUMENTS_ASC = "_REQUIRED_APPROVAL_DOCUMENTS_ASC",
  _REQUIRED_APPROVAL_DOCUMENTS_DESC = "_REQUIRED_APPROVAL_DOCUMENTS_DESC",
  _ROW_TIMESTAMP_ASC = "_ROW_TIMESTAMP_ASC",
  _ROW_TIMESTAMP_DESC = "_ROW_TIMESTAMP_DESC",
  _STATUS_TYPE_ID_ASC = "_STATUS_TYPE_ID_ASC",
  _STATUS_TYPE_ID_DESC = "_STATUS_TYPE_ID_DESC",
  _USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC = "_USER_SIGNATURE_ENTITY_DOCUMENT_ID_ASC",
  _USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC = "_USER_SIGNATURE_ENTITY_DOCUMENT_ID_DESC",
}

/**
 * A filter to be used against `AccessGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface AccessGroupFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  companyAccessGroups?: AccessGroupToManyCompanyAccessGroupFilter | null;
  companyAccessGroupsExist?: boolean | null;
  departmentOccupationTemplates?: AccessGroupToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: AccessGroupToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  and?: AccessGroupFilter[] | null;
  or?: AccessGroupFilter[] | null;
  not?: AccessGroupFilter | null;
}

/**
 * A filter to be used against many `CompanyAccessGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface AccessGroupToManyCompanyAccessGroupFilter {
  every?: CompanyAccessGroupFilter | null;
  some?: CompanyAccessGroupFilter | null;
  none?: CompanyAccessGroupFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface AccessGroupToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface AccessGroupToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `Account` object types. All fields are combined with a logical ‘and.’
 */
export interface AccountFilter {
  id?: UUIDFilter | null;
  code?: StringFilter | null;
  sortAccountCode?: StringFilter | null;
  formattedAccountCode?: StringFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  _chartOfAccountId?: UUIDFilter | null;
  accountTypeId?: IntFilter | null;
  currencyId?: IntFilter | null;
  primaryAccountId?: UUIDFilter | null;
  isSetAccountAllowed?: BooleanFilter | null;
  isTransactionEntryAllowed?: BooleanFilter | null;
  isCodeOverrideable?: BooleanFilter | null;
  isNameOverrideable?: BooleanFilter | null;
  isSharedAcrossTradingPartners?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendorDefaultAccountsByAccountIdAndChartOfAccountId?: AccountToManyVendorDefaultAccountFilter | null;
  vendorDefaultAccountsByAccountIdAndChartOfAccountIdExist?: boolean | null;
  chartOfAccount?: ChartOfAccountFilter | null;
  chartOfAccountExists?: boolean | null;
  accountType?: AccountTypeFilter | null;
  accountTypeExists?: boolean | null;
  currency?: CurrencyFilter | null;
  currencyExists?: boolean | null;
  and?: AccountFilter[] | null;
  or?: AccountFilter[] | null;
  not?: AccountFilter | null;
}

/**
 * A filter to be used against many `VendorDefaultAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface AccountToManyVendorDefaultAccountFilter {
  every?: VendorDefaultAccountFilter | null;
  some?: VendorDefaultAccountFilter | null;
  none?: VendorDefaultAccountFilter | null;
}

/**
 * A filter to be used against `AccountType` object types. All fields are combined with a logical ‘and.’
 */
export interface AccountTypeFilter {
  id?: IntFilter | null;
  accountType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isAsset?: BooleanFilter | null;
  isLiability?: BooleanFilter | null;
  isEquity?: BooleanFilter | null;
  isIncome?: BooleanFilter | null;
  isExpense?: BooleanFilter | null;
  isContraAccount?: BooleanFilter | null;
  isSuspenseAccount?: BooleanFilter | null;
  isNegativeBalance?: BooleanFilter | null;
  isBudgetedAccount?: BooleanFilter | null;
  isProjectCostAccount?: BooleanFilter | null;
  isPaymentAccount?: BooleanFilter | null;
  isEnvironmentalAccount?: BooleanFilter | null;
  isTradingPartnerAccount?: BooleanFilter | null;
  isCashAccount?: BooleanFilter | null;
  isPayCardAccount?: BooleanFilter | null;
  isPerDiemClearingAccount?: BooleanFilter | null;
  isPettyCashAdvanceAccount?: BooleanFilter | null;
  isVendorAccountEntry?: BooleanFilter | null;
  isAccountEntry?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  accounts?: AccountTypeToManyAccountFilter | null;
  accountsExist?: boolean | null;
  and?: AccountTypeFilter[] | null;
  or?: AccountTypeFilter[] | null;
  not?: AccountTypeFilter | null;
}

/**
 * A filter to be used against many `Account` object types. All fields are combined with a logical ‘and.’
 */
export interface AccountTypeToManyAccountFilter {
  every?: AccountFilter | null;
  some?: AccountFilter | null;
  none?: AccountFilter | null;
}

/**
 * All input for the `accountingEntryStampProcessing` mutation.
 */
export interface AccountingEntryStampProcessingInput {
  clientMutationId?: string | null;
  batchTransactionId?: GqlUUID | null;
  corporatePeriodId?: GqlUUID | null;
  processRequests?: (ProcessRequestInput | null)[] | null;
}

/**
 * A filter to be used against `AddressType` object types. All fields are combined with a logical ‘and.’
 */
export interface AddressTypeFilter {
  id?: IntFilter | null;
  addressType?: StringFilter | null;
  employeeAddresses?: AddressTypeToManyEmployeeAddressFilter | null;
  employeeAddressesExist?: boolean | null;
  and?: AddressTypeFilter[] | null;
  or?: AddressTypeFilter[] | null;
  not?: AddressTypeFilter | null;
}

/**
 * A filter to be used against many `EmployeeAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface AddressTypeToManyEmployeeAddressFilter {
  every?: EmployeeAddressFilter | null;
  some?: EmployeeAddressFilter | null;
  none?: EmployeeAddressFilter | null;
}

/**
 * A filter to be used against AlignmentTypes fields. All fields are combined with a logical ‘and.’
 */
export interface AlignmentTypesFilter {
  isNull?: boolean | null;
  equalTo?: AlignmentTypes | null;
  notEqualTo?: AlignmentTypes | null;
  distinctFrom?: AlignmentTypes | null;
  notDistinctFrom?: AlignmentTypes | null;
  in?: AlignmentTypes[] | null;
  notIn?: AlignmentTypes[] | null;
  lessThan?: AlignmentTypes | null;
  lessThanOrEqualTo?: AlignmentTypes | null;
  greaterThan?: AlignmentTypes | null;
  greaterThanOrEqualTo?: AlignmentTypes | null;
}

/**
 * A filter to be used against AppRouter fields. All fields are combined with a logical ‘and.’
 */
export interface AppRouterFilter {
  isNull?: boolean | null;
  equalTo?: AppRouter | null;
  notEqualTo?: AppRouter | null;
  distinctFrom?: AppRouter | null;
  notDistinctFrom?: AppRouter | null;
  in?: AppRouter[] | null;
  notIn?: AppRouter[] | null;
  lessThan?: AppRouter | null;
  lessThanOrEqualTo?: AppRouter | null;
  greaterThan?: AppRouter | null;
  greaterThanOrEqualTo?: AppRouter | null;
}

/**
 * All input for the `appendAdditionalApprovers` mutation.
 */
export interface AppendAdditionalApproversInput {
  clientMutationId?: string | null;
  entityApprovalBranchId: GqlUUID;
  newApproverUserId: (GqlUUID | null)[];
  comments?: string | null;
}

/**
 * A filter to be used against `AppliedEntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface AppliedEntityDocumentFilter {
  id?: UUIDFilter | null;
  _documentFileId?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  _documentPoolEntityTypeId?: IntFilter | null;
  transactionType?: StringFilter | null;
  vendorReference?: StringFilter | null;
  description?: StringFilter | null;
  invoiceNumber?: StringFilter | null;
  invoiceDate?: DateFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  openingBalance?: BigFloatFilter | null;
  appliedAmount?: BigFloatFilter | null;
  distributedAmount?: BigFloatFilter | null;
  differenceAmount?: BigFloatFilter | null;
  retiredAmount?: BigFloatFilter | null;
  remainingBalance?: BigFloatFilter | null;
  _createdDate?: DatetimeFilter | null;
  isoCode?: StringFilter | null;
  accountingStampTransactionReference?: StringFilter | null;
  accountingStampDate?: DatetimeFilter | null;
  overageAmount?: BigFloatFilter | null;
  _isRetireAvailable?: BooleanFilter | null;
  _poolAllocationTotal?: BigFloatFilter | null;
  invoiceDocument?: InvoiceFilter | null;
  invoiceDocumentExists?: boolean | null;
  documentAppliedInvoices?: EntityDocumentFilter | null;
  documentAppliedInvoicesExists?: boolean | null;
  and?: AppliedEntityDocumentFilter[] | null;
  or?: AppliedEntityDocumentFilter[] | null;
  not?: AppliedEntityDocumentFilter | null;
}

/**
 * All input for the `applyInvoiceSigningDocument` mutation.
 */
export interface ApplyInvoiceSigningDocumentInput {
  clientMutationId?: string | null;
  invoiceId: GqlUUID;
  entityDocuments: (EntityDocumentInput | null)[];
  poInvoiceSchedules?: (GqlUUID | null)[] | null;
}

/**
 * An input for mutations affecting `ApprovalActionType`
 */
export interface ApprovalActionTypeInput {
  entityApprovalBranchId?: GqlUUID | null;
  entityApprovalBranchRowTimestamp?: GqlDatetime | null;
  comments?: string | null;
  isApproval?: boolean | null;
  isDecline?: boolean | null;
  isApproveBypassLowerLevels?: boolean | null;
  isSuccessful?: boolean | null;
  isApprovalHistoryAction?: boolean | null;
  statusTypeId?: number | null;
  userApproval?: UserApprovalInput | null;
  approvalHistory?: ApprovalHistoryInput | null;
}

/**
 * A filter to be used against `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalBranchFilter {
  id?: UUIDFilter | null;
  description?: StringFilter | null;
  approvalTreeId?: UUIDFilter | null;
  userRankTypeIdSelector?: UUIDFilter | null;
  userOccupationTitleIdSelector?: UUIDFilter | null;
  isBusinessUnitSelector?: BooleanFilter | null;
  isUserGroupSelector?: BooleanFilter | null;
  isDepartmentSelector?: BooleanFilter | null;
  isProjectSelector?: BooleanFilter | null;
  branchOrder?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  transactionStartAmountTotal?: BigFloatFilter | null;
  transactionEndAmountTotal?: BigFloatFilter | null;
  transactionAmountConversionTypeId?: IntFilter | null;
  transactionBusinessUnitId?: UUIDFilter | null;
  transactionDepartmentId?: UUIDFilter | null;
  transactionDepartmentGroupId?: UUIDFilter | null;
  isTransactionNotCondition?: BooleanFilter | null;
  isOverBudgetSelector?: BooleanFilter | null;
  budgetLimitAmount?: BigFloatFilter | null;
  budgetLimitPercent?: BigFloatFilter | null;
  approverDelayStatusTypeId?: IntFilter | null;
  approverDelayDays?: IntFilter | null;
  approverDelayHours?: IntFilter | null;
  approverTransactionTypeId?: IntFilter | null;
  approvalTree?: ApprovalTreeFilter | null;
  approvalTreeExists?: boolean | null;
  userRank?: UserRankTypeFilter | null;
  userRankExists?: boolean | null;
  userOccupation?: UserOccupationTitleFilter | null;
  userOccupationExists?: boolean | null;
  businessUnit?: BusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  amountConversionType?: ExchangeRateTypeFilter | null;
  amountConversionTypeExists?: boolean | null;
  approverDelayType?: ApproverDelayTypeFilter | null;
  approverDelayTypeExists?: boolean | null;
  approverTransactionType?: ApproverTransactionTypeFilter | null;
  approverTransactionTypeExists?: boolean | null;
  and?: ApprovalBranchFilter[] | null;
  or?: ApprovalBranchFilter[] | null;
  not?: ApprovalBranchFilter | null;
}

/**
 * An input for mutations affecting `ApprovalBranch`
 */
export interface ApprovalBranchInput {
  description: string;
  userRankTypeIdSelector?: GqlUUID | null;
  userOccupationTitleIdSelector?: GqlUUID | null;
  isBusinessUnitSelector?: boolean | null;
  isUserGroupSelector?: boolean | null;
  isDepartmentSelector?: boolean | null;
  isProjectSelector?: boolean | null;
  branchOrder?: number | null;
  transactionStartAmountTotal?: GqlBigFloat | null;
  transactionEndAmountTotal?: GqlBigFloat | null;
  transactionAmountConversionTypeId?: number | null;
  transactionBusinessUnitId?: GqlUUID | null;
  transactionDepartmentId?: GqlUUID | null;
  transactionDepartmentGroupId?: GqlUUID | null;
  isTransactionNotCondition?: boolean | null;
  isOverBudgetSelector?: boolean | null;
  budgetLimitAmount?: GqlBigFloat | null;
  budgetLimitPercent?: GqlBigFloat | null;
  approverDelayStatusTypeId?: number | null;
  approverDelayDays?: number | null;
  approverDelayHours?: number | null;
  approverTransactionTypeId?: number | null;
}

/**
 * Represents an update to a `ApprovalBranch`. Fields that are set will be updated.
 */
export interface ApprovalBranchPatch {
  description?: string | null;
  userRankTypeIdSelector?: GqlUUID | null;
  userOccupationTitleIdSelector?: GqlUUID | null;
  isBusinessUnitSelector?: boolean | null;
  isUserGroupSelector?: boolean | null;
  isDepartmentSelector?: boolean | null;
  isProjectSelector?: boolean | null;
  branchOrder?: number | null;
  transactionStartAmountTotal?: GqlBigFloat | null;
  transactionEndAmountTotal?: GqlBigFloat | null;
  transactionAmountConversionTypeId?: number | null;
  transactionBusinessUnitId?: GqlUUID | null;
  transactionDepartmentId?: GqlUUID | null;
  transactionDepartmentGroupId?: GqlUUID | null;
  isTransactionNotCondition?: boolean | null;
  isOverBudgetSelector?: boolean | null;
  budgetLimitAmount?: GqlBigFloat | null;
  budgetLimitPercent?: GqlBigFloat | null;
  approverDelayStatusTypeId?: number | null;
  approverDelayDays?: number | null;
  approverDelayHours?: number | null;
  approverTransactionTypeId?: number | null;
}

/**
 * An input for mutations affecting `ApprovalBranchesUpdateType`
 */
export interface ApprovalBranchesUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  approvalBranchPatch?: ApprovalBranchPatch | null;
}

/**
 * A filter to be used against `ApprovalConditionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalConditionHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  approval_condition?: ApprovalSelectionConditionFilter | null;
  approval_conditionExists?: boolean | null;
  and?: ApprovalConditionHistoryFilter[] | null;
  or?: ApprovalConditionHistoryFilter[] | null;
  not?: ApprovalConditionHistoryFilter | null;
}

/**
 * An input for mutations affecting `ApprovalConditionsUpdateType`
 */
export interface ApprovalConditionsUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  approvalConditionPatch?: ApprovalSelectionConditionPatch | null;
}

/**
 * All input for the `approvalCopy` mutation.
 */
export interface ApprovalCopyInput {
  clientMutationId?: string | null;
  approvalId: GqlUUID;
  newApprovalTypeId: number;
  newName: string;
  newPriorityNumber: number;
}

/**
 * All input for the `approvalCreate` mutation.
 */
export interface ApprovalCreateInput {
  clientMutationId?: string | null;
  approval: ApprovalInput;
  approvalConditions: (ApprovalSelectionConditionInput | null)[];
  approvalTreeBranches: (ApprovalTreeBranchesTypeInput | null)[];
  entityDocumentId?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `approvalDelete` mutation.
 */
export interface ApprovalDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `ApprovalEntityApprover` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalEntityApproverFilter {
  id?: UUIDFilter | null;
  requesterName?: StringFilter | null;
  requesterDate?: DatetimeFilter | null;
  approvedDate?: DatetimeFilter | null;
  rejectedDate?: DatetimeFilter | null;
  amendedDate?: DatetimeFilter | null;
  amendedByName?: StringFilter | null;
  rejectors?: StringFilter | null;
  approvers?: StringFilter | null;
  batchTransactionHistoryApprover?: BatchTransactionFilter | null;
  batchTransactionHistoryApproverExists?: boolean | null;
  invoiceHistoryApprover?: InvoiceFilter | null;
  invoiceHistoryApproverExists?: boolean | null;
  payCycleHistoryApprover?: PayCycleFilter | null;
  payCycleHistoryApproverExists?: boolean | null;
  approvalHistoryApprover?: ApprovalFilter | null;
  approvalHistoryApproverExists?: boolean | null;
  userProfileHistoryApprover?: UserProfileFilter | null;
  userProfileHistoryApproverExists?: boolean | null;
  purchaseOrderHistoryApprover?: PurchaseOrderFilter | null;
  purchaseOrderHistoryApproverExists?: boolean | null;
  travelAuthorizationHistoryApprover?: TravelAuthorizationFilter | null;
  travelAuthorizationHistoryApproverExists?: boolean | null;
  and?: ApprovalEntityApproverFilter[] | null;
  or?: ApprovalEntityApproverFilter[] | null;
  not?: ApprovalEntityApproverFilter | null;
}

/**
 * A filter to be used against `Approval` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  priorityNumber?: IntFilter | null;
  approvalTypeId?: IntFilter | null;
  isAddApproversEligible?: BooleanFilter | null;
  isAddingPersonalApproversEligible?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  approvalPhaseTypeId?: IntFilter | null;
  _pendingApprovers?: StringFilter | null;
  isDelayedApproverAllowed?: BooleanFilter | null;
  _urgencyLevel?: IntFilter | null;
  approvalSelectionConditions?: ApprovalToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsExist?: boolean | null;
  approvalHistoriesByEntityId?: ApprovalToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: ApprovalToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: ApprovalToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  approvalTrees?: ApprovalToManyApprovalTreeFilter | null;
  approvalTreesExist?: boolean | null;
  userApprovalsByEntityId?: ApprovalToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  notesByEntityId?: ApprovalToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: ApprovalToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  approvalPhaseType?: ApprovalPhaseFilter | null;
  approvalPhaseTypeExists?: boolean | null;
  entityDocumentsByEntityId?: ApprovalToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  approvalSetupHistoriesByEntityId?: ApprovalToManyApprovalSetupHistoryFilter | null;
  approvalSetupHistoriesByEntityIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  approvalType?: ApprovalTypeFilter | null;
  approvalTypeExists?: boolean | null;
  approvalHistoryApprover?: ApprovalEntityApproverFilter | null;
  approvalHistoryApproverExists?: boolean | null;
  phaseType?: StatusTypeFilter | null;
  phaseTypeExists?: boolean | null;
  and?: ApprovalFilter[] | null;
  or?: ApprovalFilter[] | null;
  not?: ApprovalFilter | null;
}

/**
 * All input for the `approvalHistoryActions` mutation.
 */
export interface ApprovalHistoryActionsInput {
  clientMutationId?: string | null;
  approveDeclineActions: (ApprovalActionTypeInput | null)[];
}

/**
 * A filter to be used against `ApprovalHistoryApprover` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryApproverFilter {
  id?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  requesterName?: StringFilter | null;
  requesterDate?: DatetimeFilter | null;
  approvedDate?: DatetimeFilter | null;
  rejectedDate?: DatetimeFilter | null;
  amendedDate?: DatetimeFilter | null;
  amendedByName?: StringFilter | null;
  rejectors?: StringFilter | null;
  approvers?: StringFilter | null;
  approvalHistoryApprover?: ApprovalHistoryFilter | null;
  approvalHistoryApproverExists?: boolean | null;
  userApprover?: UserApprovalFilter | null;
  userApproverExists?: boolean | null;
  and?: ApprovalHistoryApproverFilter[] | null;
  or?: ApprovalHistoryApproverFilter[] | null;
  not?: ApprovalHistoryApproverFilter | null;
}

/**
 * A filter to be used against `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryFilter {
  id?: UUIDFilter | null;
  displayEntityApprovalId?: UUIDFilter | null;
  entityApprovalBranchId?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  approvalTypeId?: IntFilter | null;
  approvalTopic?: StringFilter | null;
  requesterUserProfileId?: UUIDFilter | null;
  approverUserProfileId?: UUIDFilter | null;
  requestedDate?: DatetimeFilter | null;
  requesterName?: StringFilter | null;
  requiredDate?: DateFilter | null;
  approvedDate?: DatetimeFilter | null;
  rejectedDate?: DatetimeFilter | null;
  finalizedDate?: DatetimeFilter | null;
  revokedDate?: DatetimeFilter | null;
  revokedByUserProfileId?: UUIDFilter | null;
  revokedByUserName?: StringFilter | null;
  isAutoApproval?: BooleanFilter | null;
  digitalSignature?: StringFilter | null;
  entityApprovalCompanyId?: UUIDFilter | null;
  entityApprovalBusinessUnitId?: UUIDFilter | null;
  statusTypeId?: IntFilter | null;
  iconTypeId?: IntFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  isRequester?: BooleanFilter | null;
  isApprover?: BooleanFilter | null;
  isObserver?: BooleanFilter | null;
  approvalPersona?: ApprovalPersonasFilter | null;
  isApproved?: BooleanFilter | null;
  isRejected?: BooleanFilter | null;
  isPending?: BooleanFilter | null;
  isHistory?: BooleanFilter | null;
  isRevoked?: BooleanFilter | null;
  _isRevocable?: BooleanFilter | null;
  isApprovalTreeViewerAllowed?: BooleanFilter | null;
  isPersonalApproverAddingAllowed?: BooleanFilter | null;
  isApproverAddingAllowed?: BooleanFilter | null;
  isLevelBypassingAllowed?: BooleanFilter | null;
  description1?: StringFilter | null;
  description2?: StringFilter | null;
  description3?: StringFilter | null;
  description4?: StringFilter | null;
  description5?: StringFilter | null;
  date1?: DateFilter | null;
  date2?: DateFilter | null;
  date3?: DateFilter | null;
  date4?: DatetimeFilter | null;
  date5?: DatetimeFilter | null;
  unit1?: IntFilter | null;
  unit2?: IntFilter | null;
  unit3?: IntFilter | null;
  unit4?: IntFilter | null;
  unit5?: IntFilter | null;
  rate1?: BigFloatFilter | null;
  rate2?: BigFloatFilter | null;
  rate3?: BigFloatFilter | null;
  rate4?: BigFloatFilter | null;
  rate5?: BigFloatFilter | null;
  amount1?: BigFloatFilter | null;
  amount2?: BigFloatFilter | null;
  amount3?: BigFloatFilter | null;
  amount4?: BigFloatFilter | null;
  amount5?: BigFloatFilter | null;
  approvalBranchId?: UUIDFilter | null;
  branchApprovedDate?: DatetimeFilter | null;
  branchRejectedDate?: DatetimeFilter | null;
  branchStatusTypeId?: IntFilter | null;
  branchDigitalSignature?: StringFilter | null;
  entityApprovalTreeId?: UUIDFilter | null;
  approvalTreeId?: UUIDFilter | null;
  approvalTreeLevel?: IntFilter | null;
  treeApprovedDate?: DatetimeFilter | null;
  treeRejectedDate?: DatetimeFilter | null;
  treeStatusTypeId?: IntFilter | null;
  entityApprovalBranchRowTimestamp?: DatetimeFilter | null;
  _companyId?: UUIDFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  currencyId?: IntFilter | null;
  businessUnitId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  currencyIsoCode?: StringFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  _periodYear?: StringFilter | null;
  _periodStartDate?: DateFilter | null;
  _periodEndDate?: DateFilter | null;
  _periodId?: UUIDFilter | null;
  _isAccountingEntryStampedCompleted?: BooleanFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  approvalGroup?: StringFilter | null;
  sortOrderGroup?: StringFilter | null;
  additionalApprovalInformation?: StringFilter | null;
  approvalPhaseTypeId?: IntFilter | null;
  _pendingApprovers?: StringFilter | null;
  _verificationLinkTypeId?: IntFilter | null;
  _isValidating?: BooleanFilter | null;
  _verificationDate?: DatetimeFilter | null;
  _isUserEnMasseApprovalAllowed?: BooleanFilter | null;
  _isLookupNameApplied?: BooleanFilter | null;
  _lookupNameId?: UUIDFilter | null;
  description6?: StringFilter | null;
  description7?: StringFilter | null;
  description8?: StringFilter | null;
  description9?: StringFilter | null;
  description10?: StringFilter | null;
  linkedEntityId?: UUIDFilter | null;
  urgencyLevel?: IntFilter | null;
  _isUrgentApproval?: BooleanFilter | null;
  isUrgencyUpdateAllowed?: BooleanFilter | null;
  _documentPackageId?: UUIDFilter | null;
  _isExtendedNotePrivileges?: BooleanFilter | null;
  _isExtendedUploadPrivileges?: BooleanFilter | null;
  approvalHistoryItemsByDisplayEntityApprovalId?: ApprovalHistoryToManyApprovalHistoryItemFilter | null;
  approvalHistoryItemsByDisplayEntityApprovalIdExist?: boolean | null;
  approvalHistorySignaturesByEntityApprovalId?: ApprovalHistoryToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityApprovalIdExist?: boolean | null;
  approvalSignaturesByEntityApprovalId?: ApprovalHistoryToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityApprovalIdExist?: boolean | null;
  entityTagsByEntityId?: ApprovalHistoryToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: ApprovalHistoryToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: ApprovalHistoryToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: ApprovalHistoryToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  approvalHistoryPhaseType?: ApprovalPhaseFilter | null;
  approvalHistoryPhaseTypeExists?: boolean | null;
  entityDocumentsByEntityId?: ApprovalHistoryToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  entityEnvironmentalsByEntityId?: ApprovalHistoryToManyEntityEnvironmentalFilter | null;
  entityEnvironmentalsByEntityIdExist?: boolean | null;
  userEntityApp?: UserAppFilter | null;
  userEntityAppExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  approvalType?: ApprovalTypeFilter | null;
  approvalTypeExists?: boolean | null;
  invoice?: InvoiceFilter | null;
  invoiceExists?: boolean | null;
  linkedInvoice?: InvoiceFilter | null;
  linkedInvoiceExists?: boolean | null;
  branchStatusType?: StatusTypeFilter | null;
  branchStatusTypeExists?: boolean | null;
  treeStatusType?: StatusTypeFilter | null;
  treeStatusTypeExists?: boolean | null;
  iconType?: IconTypeFilter | null;
  iconTypeExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  businessUnit?: BusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  department?: DepartmentFilter | null;
  departmentExists?: boolean | null;
  companyCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  companyCorporatePeriodExists?: boolean | null;
  approvalHistoryApprover?: ApprovalHistoryApproverFilter | null;
  approvalHistoryApproverExists?: boolean | null;
  payCycle?: PayCycleFilter | null;
  payCycleExists?: boolean | null;
  approvalSetup?: ApprovalFilter | null;
  approvalSetupExists?: boolean | null;
  userProfile?: UserProfileFilter | null;
  userProfileExists?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  batchTransaction?: BatchTransactionFilter | null;
  batchTransactionExists?: boolean | null;
  lookupName?: LookupNameFilter | null;
  lookupNameExists?: boolean | null;
  verificationLinkType?: LinkTypeFilter | null;
  verificationLinkTypeExists?: boolean | null;
  purchaseOrder?: PurchaseOrderFilter | null;
  purchaseOrderExists?: boolean | null;
  travelAuthorization?: TravelAuthorizationFilter | null;
  travelAuthorizationExists?: boolean | null;
  and?: ApprovalHistoryFilter[] | null;
  or?: ApprovalHistoryFilter[] | null;
  not?: ApprovalHistoryFilter | null;
}

/**
 * An input for mutations affecting `ApprovalHistory`
 */
export interface ApprovalHistoryInput {
  id: GqlUUID;
  displayEntityApprovalId?: GqlUUID | null;
  entityApprovalBranchId?: GqlUUID | null;
  entityId?: GqlUUID | null;
  entityTypeId?: number | null;
  appId?: number | null;
  approvalTypeId?: number | null;
  approvalTopic?: string | null;
  requesterUserProfileId?: GqlUUID | null;
  approverUserProfileId?: GqlUUID | null;
  requestedDate?: GqlDatetime | null;
  requesterName?: string | null;
  requiredDate?: GqlDate | null;
  approvedDate?: GqlDatetime | null;
  rejectedDate?: GqlDatetime | null;
  finalizedDate?: GqlDatetime | null;
  revokedDate?: GqlDatetime | null;
  revokedByUserProfileId?: GqlUUID | null;
  revokedByUserName?: string | null;
  isAutoApproval?: boolean | null;
  digitalSignature?: string | null;
  entityApprovalCompanyId?: GqlUUID | null;
  entityApprovalBusinessUnitId?: GqlUUID | null;
  statusTypeId?: number | null;
  iconTypeId?: number | null;
  layoutType?: TransactionLayout | null;
  isRequester?: boolean | null;
  isApprover?: boolean | null;
  isObserver?: boolean | null;
  approvalPersona?: ApprovalPersonas | null;
  isApproved?: boolean | null;
  isRejected?: boolean | null;
  isPending?: boolean | null;
  isHistory?: boolean | null;
  isRevoked?: boolean | null;
  _isRevocable?: boolean | null;
  isApprovalTreeViewerAllowed?: boolean | null;
  isPersonalApproverAddingAllowed?: boolean | null;
  isApproverAddingAllowed?: boolean | null;
  isLevelBypassingAllowed?: boolean | null;
  description1?: string | null;
  description2?: string | null;
  description3?: string | null;
  description4?: string | null;
  description5?: string | null;
  date1?: GqlDate | null;
  date2?: GqlDate | null;
  date3?: GqlDate | null;
  date4?: GqlDatetime | null;
  date5?: GqlDatetime | null;
  unit1?: number | null;
  unit2?: number | null;
  unit3?: number | null;
  unit4?: number | null;
  unit5?: number | null;
  rate1?: GqlBigFloat | null;
  rate2?: GqlBigFloat | null;
  rate3?: GqlBigFloat | null;
  rate4?: GqlBigFloat | null;
  rate5?: GqlBigFloat | null;
  amount1?: GqlBigFloat | null;
  amount2?: GqlBigFloat | null;
  amount3?: GqlBigFloat | null;
  amount4?: GqlBigFloat | null;
  amount5?: GqlBigFloat | null;
  approvalBranchId?: GqlUUID | null;
  branchApprovedDate?: GqlDatetime | null;
  branchRejectedDate?: GqlDatetime | null;
  branchStatusTypeId?: number | null;
  branchDigitalSignature?: string | null;
  entityApprovalTreeId?: GqlUUID | null;
  approvalTreeId?: GqlUUID | null;
  approvalTreeLevel?: number | null;
  treeApprovedDate?: GqlDatetime | null;
  treeRejectedDate?: GqlDatetime | null;
  treeStatusTypeId?: number | null;
  entityApprovalBranchRowTimestamp?: GqlDatetime | null;
  _companyId?: GqlUUID | null;
  _isEnvironmentalsExist?: boolean | null;
  _isNotesExist?: boolean | null;
  _isTagsExist?: boolean | null;
  _isDocumentsExist?: boolean | null;
  _searchDocument?: string | null;
  currencyId?: number | null;
  businessUnitId?: GqlUUID | null;
  departmentId?: GqlUUID | null;
  currencyIsoCode?: string | null;
  corporatePeriodId?: GqlUUID | null;
  projectPeriodId?: GqlUUID | null;
  _periodYear?: string | null;
  _periodStartDate?: GqlDate | null;
  _periodEndDate?: GqlDate | null;
  _periodId?: GqlUUID | null;
  _isAccountingEntryStampedCompleted?: boolean | null;
  _accountingStampUserId?: GqlUUID | null;
  _accountingStampUserName?: string | null;
  _accountingStampDate?: GqlDatetime | null;
  _accountingStampTransactionReference?: string | null;
  _isTransactionCancelled?: boolean | null;
  approvalGroup?: string | null;
  sortOrderGroup?: string | null;
  additionalApprovalInformation?: string | null;
  approvalPhaseTypeId?: number | null;
  _pendingApprovers?: string | null;
  _verificationLinkTypeId?: number | null;
  _isValidating?: boolean | null;
  _verificationDate?: GqlDatetime | null;
  _isUserEnMasseApprovalAllowed?: boolean | null;
  _isLookupNameApplied?: boolean | null;
  _lookupNameId?: GqlUUID | null;
  description6?: string | null;
  description7?: string | null;
  description8?: string | null;
  description9?: string | null;
  description10?: string | null;
  linkedEntityId?: GqlUUID | null;
  urgencyLevel?: number | null;
  _isUrgentApproval?: boolean | null;
  isUrgencyUpdateAllowed?: boolean | null;
}

/**
 * A filter to be used against `ApprovalHistoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryItemFilter {
  id?: UUIDFilter | null;
  displayEntityApprovalId?: UUIDFilter | null;
  entityItemId?: UUIDFilter | null;
  itemDescription1?: StringFilter | null;
  itemDescription2?: StringFilter | null;
  itemDescription3?: StringFilter | null;
  itemDescription4?: StringFilter | null;
  itemDescription5?: StringFilter | null;
  itemDate1?: DateFilter | null;
  itemDate2?: DateFilter | null;
  itemDate3?: DateFilter | null;
  itemDate4?: DatetimeFilter | null;
  itemDate5?: DatetimeFilter | null;
  itemUnit1?: IntFilter | null;
  itemUnit2?: IntFilter | null;
  itemUnit3?: IntFilter | null;
  itemUnit4?: IntFilter | null;
  itemUnit5?: IntFilter | null;
  itemRate1?: BigFloatFilter | null;
  itemRate2?: BigFloatFilter | null;
  itemRate3?: BigFloatFilter | null;
  itemRate4?: BigFloatFilter | null;
  itemRate5?: BigFloatFilter | null;
  itemAmount1?: BigFloatFilter | null;
  itemAmount2?: BigFloatFilter | null;
  itemAmount3?: BigFloatFilter | null;
  itemAmount4?: BigFloatFilter | null;
  itemAmount5?: BigFloatFilter | null;
  _searchDocument?: StringFilter | null;
  _createdDate?: DatetimeFilter | null;
  _isVerified?: BooleanFilter | null;
  _verificationDate?: DatetimeFilter | null;
  _verificationUserId?: UUIDFilter | null;
  _verificationComment?: StringFilter | null;
  id1?: UUIDFilter | null;
  setReference?: StringFilter | null;
  referenceCode1?: StringFilter | null;
  referenceCode2?: StringFilter | null;
  referenceCode3?: StringFilter | null;
  referenceCode4?: StringFilter | null;
  referenceCode5?: StringFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUser?: StringFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _batchComment?: StringFilter | null;
  referenceCode6?: StringFilter | null;
  referenceCode7?: StringFilter | null;
  _accountName?: StringFilter | null;
  approvalHistory?: ApprovalHistoryFilter | null;
  approvalHistoryExists?: boolean | null;
  userApproval?: UserApprovalFilter | null;
  userApprovalExists?: boolean | null;
  userVerified?: UserFilter | null;
  userVerifiedExists?: boolean | null;
  invoiceBatchComment?: InvoiceBatchFilter | null;
  invoiceBatchCommentExists?: boolean | null;
  purchaseOrderItem?: PurchaseOrderItemFilter | null;
  purchaseOrderItemExists?: boolean | null;
  trip?: TripFilter | null;
  tripExists?: boolean | null;
  and?: ApprovalHistoryItemFilter[] | null;
  or?: ApprovalHistoryItemFilter[] | null;
  not?: ApprovalHistoryItemFilter | null;
}

/**
 * A filter to be used against `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistorySignatureFilter {
  entityApprovalId?: UUIDFilter | null;
  id?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  userName?: StringFilter | null;
  actionDate?: DatetimeFilter | null;
  digitalSignature?: StringFilter | null;
  userSignature?: StringFilter | null;
  additionalInformation?: StringFilter | null;
  isRequested?: BooleanFilter | null;
  isApproved?: BooleanFilter | null;
  isRejected?: BooleanFilter | null;
  sortOrder?: StringFilter | null;
  entityDocumentId?: UUIDFilter | null;
  userOccupationTitle?: StringFilter | null;
  entityApprovalHistorySignature?: ApprovalHistoryFilter | null;
  entityApprovalHistorySignatureExists?: boolean | null;
  userApprovalHistorySignature?: UserApprovalFilter | null;
  userApprovalHistorySignatureExists?: boolean | null;
  userSignatureDocument?: EntityDocumentFilter | null;
  userSignatureDocumentExists?: boolean | null;
  entityApprovalSignature?: InvoiceFilter | null;
  entityApprovalSignatureExists?: boolean | null;
  entityPayCycleApprovalSignature?: PayCycleFilter | null;
  entityPayCycleApprovalSignatureExists?: boolean | null;
  entityApprovalSetupSignature?: ApprovalFilter | null;
  entityApprovalSetupSignatureExists?: boolean | null;
  entityUserProfileSignature?: UserProfileFilter | null;
  entityUserProfileSignatureExists?: boolean | null;
  entityVendorApprovalSignature?: VendorFilter | null;
  entityVendorApprovalSignatureExists?: boolean | null;
  entitySupplierApprovalSignature?: SupplierFilter | null;
  entitySupplierApprovalSignatureExists?: boolean | null;
  entityTravelerApprovalSignature?: TravelerFilter | null;
  entityTravelerApprovalSignatureExists?: boolean | null;
  entityBatchTransactionApprovalSignature?: BatchTransactionFilter | null;
  entityBatchTransactionApprovalSignatureExists?: boolean | null;
  purchaseOrderSignature?: PurchaseOrderFilter | null;
  purchaseOrderSignatureExists?: boolean | null;
  travelAuthorizationSignatures?: TravelAuthorizationFilter | null;
  travelAuthorizationSignaturesExists?: boolean | null;
  and?: ApprovalHistorySignatureFilter[] | null;
  or?: ApprovalHistorySignatureFilter[] | null;
  not?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyApprovalHistoryItemFilter {
  every?: ApprovalHistoryItemFilter | null;
  some?: ApprovalHistoryItemFilter | null;
  none?: ApprovalHistoryItemFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityEnvironmental` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyEntityEnvironmentalFilter {
  every?: EntityEnvironmentalFilter | null;
  some?: EntityEnvironmentalFilter | null;
  none?: EntityEnvironmentalFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalHistoryToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * An input for mutations affecting `Approval`
 */
export interface ApprovalInput {
  name: string;
  description?: string | null;
  priorityNumber: number;
  approvalTypeId: number;
  isAddApproversEligible?: boolean | null;
  approvalPhaseTypeId?: number | null;
  isDelayedApproverAllowed?: boolean | null;
}

/**
 * Represents an update to a `Approval`. Fields that are set will be updated.
 */
export interface ApprovalPatch {
  name?: string | null;
  description?: string | null;
  priorityNumber?: number | null;
  approvalTypeId?: number | null;
  isAddApproversEligible?: boolean | null;
  approvalPhaseTypeId?: number | null;
  isDelayedApproverAllowed?: boolean | null;
}

/**
 * A filter to be used against ApprovalPersonas fields. All fields are combined with a logical ‘and.’
 */
export interface ApprovalPersonasFilter {
  isNull?: boolean | null;
  equalTo?: ApprovalPersonas | null;
  notEqualTo?: ApprovalPersonas | null;
  distinctFrom?: ApprovalPersonas | null;
  notDistinctFrom?: ApprovalPersonas | null;
  in?: ApprovalPersonas[] | null;
  notIn?: ApprovalPersonas[] | null;
  lessThan?: ApprovalPersonas | null;
  lessThanOrEqualTo?: ApprovalPersonas | null;
  greaterThan?: ApprovalPersonas | null;
  greaterThanOrEqualTo?: ApprovalPersonas | null;
}

/**
 * A filter to be used against `ApprovalPhase` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalPhaseFilter {
  id?: IntFilter | null;
  phaseName?: StringFilter | null;
  companiesByApprovalPhaseTypeId?: ApprovalPhaseToManyCompanyFilter | null;
  companiesByApprovalPhaseTypeIdExist?: boolean | null;
  companyApprovalPhaseType?: CompanyFilter | null;
  companyApprovalPhaseTypeExists?: boolean | null;
  approvalPhaseType?: ApprovalFilter | null;
  approvalPhaseTypeExists?: boolean | null;
  approvalHistoryPhaseType?: ApprovalHistoryFilter | null;
  approvalHistoryPhaseTypeExists?: boolean | null;
  userApprovalPhaseType?: UserApprovalFilter | null;
  userApprovalPhaseTypeExists?: boolean | null;
  and?: ApprovalPhaseFilter[] | null;
  or?: ApprovalPhaseFilter[] | null;
  not?: ApprovalPhaseFilter | null;
}

/**
 * A filter to be used against many `Company` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalPhaseToManyCompanyFilter {
  every?: CompanyFilter | null;
  some?: CompanyFilter | null;
  none?: CompanyFilter | null;
}

/**
 * An input for mutations affecting `ApprovalRequest`
 */
export interface ApprovalRequestInput {
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  comments?: string | null;
  requiredDate?: GqlDatetime | null;
  additionalUserId?: (GqlUUID | null)[] | null;
}

/**
 * A filter to be used against `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalSelectionConditionFilter {
  id?: UUIDFilter | null;
  approvalId?: UUIDFilter | null;
  description?: StringFilter | null;
  importanceLevel?: IntFilter | null;
  requesterCurrencyId?: IntFilter | null;
  requesterUserRankId?: UUIDFilter | null;
  requesterUserOccupationTitleId?: UUIDFilter | null;
  startAmountTotal?: BigFloatFilter | null;
  endAmountTotal?: BigFloatFilter | null;
  requesterUserId?: UUIDFilter | null;
  requesterCompanyId?: UUIDFilter | null;
  requesterBusinessUnitId?: UUIDFilter | null;
  requesterPurchaseOrderTypeId?: IntFilter | null;
  isAutoApprovedEligible?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  requesterDepartmentId?: UUIDFilter | null;
  requesterDepartmentTypeId?: IntFilter | null;
  requesterSecureRowLevelId?: UUIDFilter | null;
  requesterTagId?: UUIDFilter | null;
  isAndCondition?: BooleanFilter | null;
  amountConversionTypeId?: IntFilter | null;
  requesterDepartmentGroupId?: UUIDFilter | null;
  startElapsedDays?: IntFilter | null;
  endElapsedDays?: IntFilter | null;
  approvalConditionExchangeRateType?: ExchangeRateTypeFilter | null;
  approvalConditionExchangeRateTypeExists?: boolean | null;
  approvalConditionHistoriesByEntityId?: ApprovalSelectionConditionToManyApprovalConditionHistoryFilter | null;
  approvalConditionHistoriesByEntityIdExist?: boolean | null;
  approval?: ApprovalFilter | null;
  approvalExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  userRank?: UserRankTypeFilter | null;
  userRankExists?: boolean | null;
  userOccupation?: UserOccupationTitleFilter | null;
  userOccupationExists?: boolean | null;
  user?: UserFilter | null;
  userExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  businessUnit?: BusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  purchaseOrderType?: PurchaseOrderTypeFilter | null;
  purchaseOrderTypeExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  departmentType?: DepartmentTypeFilter | null;
  departmentTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  tag?: TagFilter | null;
  tagExists?: boolean | null;
  amountConversionType?: ExchangeRateTypeFilter | null;
  amountConversionTypeExists?: boolean | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  and?: ApprovalSelectionConditionFilter[] | null;
  or?: ApprovalSelectionConditionFilter[] | null;
  not?: ApprovalSelectionConditionFilter | null;
}

/**
 * An input for mutations affecting `ApprovalSelectionCondition`
 */
export interface ApprovalSelectionConditionInput {
  description: string;
  importanceLevel: number;
  requesterCurrencyId?: number | null;
  requesterUserRankId?: GqlUUID | null;
  requesterUserOccupationTitleId?: GqlUUID | null;
  startAmountTotal?: GqlBigFloat | null;
  endAmountTotal?: GqlBigFloat | null;
  requesterUserId?: GqlUUID | null;
  requesterCompanyId?: GqlUUID | null;
  requesterBusinessUnitId?: GqlUUID | null;
  requesterPurchaseOrderTypeId?: number | null;
  isAutoApprovedEligible?: boolean | null;
  requesterDepartmentId?: GqlUUID | null;
  requesterDepartmentTypeId?: number | null;
  requesterSecureRowLevelId?: GqlUUID | null;
  requesterTagId?: GqlUUID | null;
  isAndCondition?: boolean | null;
  amountConversionTypeId?: number | null;
  requesterDepartmentGroupId?: GqlUUID | null;
  startElapsedDays?: number | null;
  endElapsedDays?: number | null;
}

/**
 * Represents an update to a `ApprovalSelectionCondition`. Fields that are set will be updated.
 */
export interface ApprovalSelectionConditionPatch {
  description?: string | null;
  importanceLevel?: number | null;
  requesterCurrencyId?: number | null;
  requesterUserRankId?: GqlUUID | null;
  requesterUserOccupationTitleId?: GqlUUID | null;
  startAmountTotal?: GqlBigFloat | null;
  endAmountTotal?: GqlBigFloat | null;
  requesterUserId?: GqlUUID | null;
  requesterCompanyId?: GqlUUID | null;
  requesterBusinessUnitId?: GqlUUID | null;
  requesterPurchaseOrderTypeId?: number | null;
  isAutoApprovedEligible?: boolean | null;
  requesterDepartmentId?: GqlUUID | null;
  requesterDepartmentTypeId?: number | null;
  requesterSecureRowLevelId?: GqlUUID | null;
  requesterTagId?: GqlUUID | null;
  isAndCondition?: boolean | null;
  amountConversionTypeId?: number | null;
  requesterDepartmentGroupId?: GqlUUID | null;
  startElapsedDays?: number | null;
  endElapsedDays?: number | null;
}

/**
 * A filter to be used against many `ApprovalConditionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalSelectionConditionToManyApprovalConditionHistoryFilter {
  every?: ApprovalConditionHistoryFilter | null;
  some?: ApprovalConditionHistoryFilter | null;
  none?: ApprovalConditionHistoryFilter | null;
}

/**
 * All input for the `approvalSetupApprovalCreate` mutation.
 */
export interface ApprovalSetupApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `approvalSetupApprovalRevoke` mutation.
 */
export interface ApprovalSetupApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * A filter to be used against `ApprovalSetupHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalSetupHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  approval?: ApprovalFilter | null;
  approvalExists?: boolean | null;
  and?: ApprovalSetupHistoryFilter[] | null;
  or?: ApprovalSetupHistoryFilter[] | null;
  not?: ApprovalSetupHistoryFilter | null;
}

/**
 * All input for the `approvalSetupUrgentUpdate` mutation.
 */
export interface ApprovalSetupUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * A filter to be used against `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalSignatureFilter {
  entityApprovalId?: UUIDFilter | null;
  id?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  userName?: StringFilter | null;
  actionDate?: DatetimeFilter | null;
  digitalSignature?: StringFilter | null;
  userSignature?: StringFilter | null;
  additionalInformation?: StringFilter | null;
  isRequested?: BooleanFilter | null;
  isApproved?: BooleanFilter | null;
  isRejected?: BooleanFilter | null;
  sortOrder?: StringFilter | null;
  entityDocumentId?: UUIDFilter | null;
  userOccupationTitle?: StringFilter | null;
  _isRequester?: BooleanFilter | null;
  _isApprover?: BooleanFilter | null;
  _iconState?: IconStateFilter | null;
  _iconHint?: StringFilter | null;
  entityApprovalHistorySignature?: ApprovalHistoryFilter | null;
  entityApprovalHistorySignatureExists?: boolean | null;
  userApprovalHistorySignature?: UserApprovalFilter | null;
  userApprovalHistorySignatureExists?: boolean | null;
  userSignatureDocument?: EntityDocumentFilter | null;
  userSignatureDocumentExists?: boolean | null;
  entityApprovalSignature?: InvoiceFilter | null;
  entityApprovalSignatureExists?: boolean | null;
  entityPayCycleApprovalSignature?: PayCycleFilter | null;
  entityPayCycleApprovalSignatureExists?: boolean | null;
  entityApprovalSetupSignature?: ApprovalFilter | null;
  entityApprovalSetupSignatureExists?: boolean | null;
  entityUserProfileSignature?: UserProfileFilter | null;
  entityUserProfileSignatureExists?: boolean | null;
  entityVendorApprovalSignature?: VendorFilter | null;
  entityVendorApprovalSignatureExists?: boolean | null;
  entitySupplierApprovalSignature?: SupplierFilter | null;
  entitySupplierApprovalSignatureExists?: boolean | null;
  entityTravelerApprovalSignature?: TravelerFilter | null;
  entityTravelerApprovalSignatureExists?: boolean | null;
  entityBatchTransactionApprovalSignature?: BatchTransactionFilter | null;
  entityBatchTransactionApprovalSignatureExists?: boolean | null;
  entityPurchaseOrderSignature?: PurchaseOrderFilter | null;
  entityPurchaseOrderSignatureExists?: boolean | null;
  entitytravelAuthorizationSignature?: TravelAuthorizationFilter | null;
  entitytravelAuthorizationSignatureExists?: boolean | null;
  and?: ApprovalSignatureFilter[] | null;
  or?: ApprovalSignatureFilter[] | null;
  not?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against ApprovalState fields. All fields are combined with a logical ‘and.’
 */
export interface ApprovalStateFilter {
  isNull?: boolean | null;
  equalTo?: ApprovalState | null;
  notEqualTo?: ApprovalState | null;
  distinctFrom?: ApprovalState | null;
  notDistinctFrom?: ApprovalState | null;
  in?: ApprovalState[] | null;
  notIn?: ApprovalState[] | null;
  lessThan?: ApprovalState | null;
  lessThanOrEqualTo?: ApprovalState | null;
  greaterThan?: ApprovalState | null;
  greaterThanOrEqualTo?: ApprovalState | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `ApprovalSetupHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyApprovalSetupHistoryFilter {
  every?: ApprovalSetupHistoryFilter | null;
  some?: ApprovalSetupHistoryFilter | null;
  none?: ApprovalSetupHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalTree` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyApprovalTreeFilter {
  every?: ApprovalTreeFilter | null;
  some?: ApprovalTreeFilter | null;
  none?: ApprovalTreeFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * An input for mutations affecting `ApprovalTreeBranchesType`
 */
export interface ApprovalTreeBranchesTypeInput {
  approvalTree?: ApprovalTreeInput | null;
  approvalTreeId?: GqlUUID | null;
  approvalBranches?: (ApprovalBranchInput | null)[] | null;
}

/**
 * A filter to be used against `ApprovalTree` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTreeFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  approvalId?: UUIDFilter | null;
  approvalLevel?: IntFilter | null;
  requiredBranchApprovalCount?: IntFilter | null;
  isUsedForAdditionalDepartments?: BooleanFilter | null;
  isRejectOnUnderApprovalCount?: BooleanFilter | null;
  isUsedForPersonalApprovers?: BooleanFilter | null;
  isPersonalApproversRequired?: BooleanFilter | null;
  isApprovedByConsensus?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isSingleDeclineConsensusInvoked?: BooleanFilter | null;
  approvalBranches?: ApprovalTreeToManyApprovalBranchFilter | null;
  approvalBranchesExist?: boolean | null;
  approval?: ApprovalFilter | null;
  approvalExists?: boolean | null;
  and?: ApprovalTreeFilter[] | null;
  or?: ApprovalTreeFilter[] | null;
  not?: ApprovalTreeFilter | null;
}

/**
 * An input for mutations affecting `ApprovalTree`
 */
export interface ApprovalTreeInput {
  name: string;
  description?: string | null;
  approvalLevel: number;
  requiredBranchApprovalCount: number;
  isUsedForAdditionalDepartments?: boolean | null;
  isRejectOnUnderApprovalCount?: boolean | null;
  isApprovedByConsensus?: boolean | null;
  isSingleDeclineConsensusInvoked?: boolean | null;
}

/**
 * Represents an update to a `ApprovalTree`. Fields that are set will be updated.
 */
export interface ApprovalTreePatch {
  name?: string | null;
  description?: string | null;
  approvalLevel?: number | null;
  requiredBranchApprovalCount?: number | null;
  isUsedForAdditionalDepartments?: boolean | null;
  isRejectOnUnderApprovalCount?: boolean | null;
  isApprovedByConsensus?: boolean | null;
  isSingleDeclineConsensusInvoked?: boolean | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTreeToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * An input for mutations affecting `ApprovalTreesUpdateType`
 */
export interface ApprovalTreesUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  approvalTreePatch?: ApprovalTreePatch | null;
}

/**
 * A filter to be used against `ApprovalType` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTypeFilter {
  id?: IntFilter | null;
  approvalType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isSigningStandalone?: BooleanFilter | null;
  _isPaymentTrackingStandalone?: BooleanFilter | null;
  _isPurchaseOrderSystem?: BooleanFilter | null;
  _isTravelAuthSystem?: BooleanFilter | null;
  approvalHistories?: ApprovalTypeToManyApprovalHistoryFilter | null;
  approvalHistoriesExist?: boolean | null;
  approvals?: ApprovalTypeToManyApprovalFilter | null;
  approvalsExist?: boolean | null;
  userApprovals?: ApprovalTypeToManyUserApprovalFilter | null;
  userApprovalsExist?: boolean | null;
  departmentOccupationApprovals?: ApprovalTypeToManyDepartmentOccupationApprovalFilter | null;
  departmentOccupationApprovalsExist?: boolean | null;
  userApprovalGrants?: ApprovalTypeToManyUserApprovalGrantFilter | null;
  userApprovalGrantsExist?: boolean | null;
  and?: ApprovalTypeFilter[] | null;
  or?: ApprovalTypeFilter[] | null;
  not?: ApprovalTypeFilter | null;
}

/**
 * A filter to be used against many `Approval` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTypeToManyApprovalFilter {
  every?: ApprovalFilter | null;
  some?: ApprovalFilter | null;
  none?: ApprovalFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTypeToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTypeToManyDepartmentOccupationApprovalFilter {
  every?: DepartmentOccupationApprovalFilter | null;
  some?: DepartmentOccupationApprovalFilter | null;
  none?: DepartmentOccupationApprovalFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTypeToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserApprovalGrant` object types. All fields are combined with a logical ‘and.’
 */
export interface ApprovalTypeToManyUserApprovalGrantFilter {
  every?: UserApprovalGrantFilter | null;
  some?: UserApprovalGrantFilter | null;
  none?: UserApprovalGrantFilter | null;
}

/**
 * All input for the `approvalUpdate` mutation.
 */
export interface ApprovalUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  approvalPatch?: ApprovalPatch | null;
  approvalConditionsCreate?: (ApprovalSelectionConditionInput | null)[] | null;
  approvalConditionsUpdate?: (ApprovalConditionsUpdateTypeInput | null)[] | null;
  approvalConditionsDelete?: (EntityDeleteInput | null)[] | null;
  approvalTreeBranches?: (ApprovalTreeBranchesTypeInput | null)[] | null;
  approvalTreesUpdate?: (ApprovalTreesUpdateTypeInput | null)[] | null;
  approvalTreesDelete?: (EntityDeleteInput | null)[] | null;
  approvalBranchesUpdate?: (ApprovalBranchesUpdateTypeInput | null)[] | null;
  approvalBranchesDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `approvalUserActions` mutation.
 */
export interface ApprovalUserActionsInput {
  clientMutationId?: string | null;
  approveDeclineActions: (ApprovalActionTypeInput | null)[];
}

/**
 * A filter to be used against `ApproverDelayType` object types. All fields are combined with a logical ‘and.’
 */
export interface ApproverDelayTypeFilter {
  id?: IntFilter | null;
  delayType?: StringFilter | null;
  _isDelayRule?: BooleanFilter | null;
  approvalBranchesByApproverDelayStatusTypeId?: ApproverDelayTypeToManyApprovalBranchFilter | null;
  approvalBranchesByApproverDelayStatusTypeIdExist?: boolean | null;
  and?: ApproverDelayTypeFilter[] | null;
  or?: ApproverDelayTypeFilter[] | null;
  not?: ApproverDelayTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface ApproverDelayTypeToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * A filter to be used against `ApproverTransactionType` object types. All fields are combined with a logical ‘and.’
 */
export interface ApproverTransactionTypeFilter {
  id?: IntFilter | null;
  approvalTransactionType?: StringFilter | null;
  approvalBranches?: ApproverTransactionTypeToManyApprovalBranchFilter | null;
  approvalBranchesExist?: boolean | null;
  and?: ApproverTransactionTypeFilter[] | null;
  or?: ApproverTransactionTypeFilter[] | null;
  not?: ApproverTransactionTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface ApproverTransactionTypeToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * All input for the `assignCorporateAccounts` mutation.
 */
export interface AssignCorporateAccountsInput {
  clientMutationId?: string | null;
  startingAccountId: GqlUUID;
  endingAccountId: GqlUUID;
  corporateAccountId: GqlUUID;
}

/**
 * All input for the `attachApprovalExtendedDocument` mutation.
 */
export interface AttachApprovalExtendedDocumentInput {
  clientMutationId?: string | null;
  entityApprovalId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachBatchTransactionDocument` mutation.
 */
export interface AttachBatchTransactionDocumentInput {
  clientMutationId?: string | null;
  batchTransactionId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachDocumentPool` mutation.
 */
export interface AttachDocumentPoolInput {
  clientMutationId?: string | null;
  poolId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachPayCycleDocument` mutation.
 */
export interface AttachPayCycleDocumentInput {
  clientMutationId?: string | null;
  payCycleId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachPaymentDocument` mutation.
 */
export interface AttachPaymentDocumentInput {
  clientMutationId?: string | null;
  paymentId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachPurchaseOrderDocument` mutation.
 */
export interface AttachPurchaseOrderDocumentInput {
  clientMutationId?: string | null;
  purchaseOrderId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachSupplierDocument` mutation.
 */
export interface AttachSupplierDocumentInput {
  clientMutationId?: string | null;
  supplierId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachTravelAuthorizationDocument` mutation.
 */
export interface AttachTravelAuthorizationDocumentInput {
  clientMutationId?: string | null;
  travelAuthorizationId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachTravelerDocument` mutation.
 */
export interface AttachTravelerDocumentInput {
  clientMutationId?: string | null;
  travelerId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachTripDocument` mutation.
 */
export interface AttachTripDocumentInput {
  clientMutationId?: string | null;
  tripId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * All input for the `attachUserProfileDocument` mutation.
 */
export interface AttachUserProfileDocumentInput {
  clientMutationId?: string | null;
  userProfileId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  documentTypeId?: number | null;
}

/**
 * A filter to be used against `AvailableCardCompaniesRecord` object types. All fields are combined with a logical ‘and.’
 */
export interface AvailableCardCompaniesRecordFilter {
  id?: IntFilter | null;
  name?: StringFilter | null;
  isManualProcess?: BooleanFilter | null;
  and?: AvailableCardCompaniesRecordFilter[] | null;
  or?: AvailableCardCompaniesRecordFilter[] | null;
  not?: AvailableCardCompaniesRecordFilter | null;
}

/**
 * A filter to be used against `AvailableCardHolderUser` object types. All fields are combined with a logical ‘and.’
 */
export interface AvailableCardHolderUserFilter {
  id?: UUIDFilter | null;
  userName?: StringFilter | null;
  departmentId?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  cardHolderUser?: CardHolderFilter | null;
  cardHolderUserExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  userGroup?: CompanyUserGroupFilter | null;
  userGroupExists?: boolean | null;
  and?: AvailableCardHolderUserFilter[] | null;
  or?: AvailableCardHolderUserFilter[] | null;
  not?: AvailableCardHolderUserFilter | null;
}

/**
 * A filter to be used against `AvailableSupplierDocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface AvailableSupplierDocumentPoolFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  supplierDocumentTotals?: DocumentPoolDocumentTotalFilter | null;
  supplierDocumentTotalsExists?: boolean | null;
  and?: AvailableSupplierDocumentPoolFilter[] | null;
  or?: AvailableSupplierDocumentPoolFilter[] | null;
  not?: AvailableSupplierDocumentPoolFilter | null;
}

/**
 * All input for the `batchEditApprovalCreate` mutation.
 */
export interface BatchEditApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `batchEditApprovalRevoke` mutation.
 */
export interface BatchEditApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `batchEditUrgentUpdate` mutation.
 */
export interface BatchEditUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * All input for the `batchTransactionCreate` mutation.
 */
export interface BatchTransactionCreateInput {
  clientMutationId?: string | null;
  batchTransaction: BatchTransactionInput;
  entityDocuments?: (EntityDocumentInput | null)[] | null;
}

/**
 * All input for the `batchTransactionDelete` mutation.
 */
export interface BatchTransactionDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `BatchTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionFilter {
  id?: UUIDFilter | null;
  referenceNumber?: StringFilter | null;
  description?: StringFilter | null;
  postingDate?: DateFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  currencyId?: IntFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _userGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _isHistory?: BooleanFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionEntry?: BooleanFilter | null;
  _isAccountingEntry?: BooleanFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  _isAccountingEntryStampedComplete?: BooleanFilter | null;
  _isBatchSelectable?: BooleanFilter | null;
  _pendingApprovers?: StringFilter | null;
  _isVerifiable?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _batchTransactionCount?: IntFilter | null;
  _batchTransactionTotal?: BigFloatFilter | null;
  weekNumber?: IntFilter | null;
  _createdByUserName?: StringFilter | null;
  _urgencyLevel?: IntFilter | null;
  invoiceBatches?: BatchTransactionToManyInvoiceBatchFilter | null;
  invoiceBatchesExist?: boolean | null;
  approvalHistoriesByEntityId?: BatchTransactionToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: BatchTransactionToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: BatchTransactionToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: BatchTransactionToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: BatchTransactionToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: BatchTransactionToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: BatchTransactionToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: BatchTransactionToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: BatchTransactionToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  batchTransactionHistoriesByEntityId?: BatchTransactionToManyBatchTransactionHistoryFilter | null;
  batchTransactionHistoriesByEntityIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  companyCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  companyCorporatePeriodExists?: boolean | null;
  batchTransactionHistoryApprover?: ApprovalEntityApproverFilter | null;
  batchTransactionHistoryApproverExists?: boolean | null;
  and?: BatchTransactionFilter[] | null;
  or?: BatchTransactionFilter[] | null;
  not?: BatchTransactionFilter | null;
}

/**
 * A filter to be used against `BatchTransactionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  batchTransaction?: BatchTransactionFilter | null;
  batchTransactionExists?: boolean | null;
  and?: BatchTransactionHistoryFilter[] | null;
  or?: BatchTransactionHistoryFilter[] | null;
  not?: BatchTransactionHistoryFilter | null;
}

/**
 * An input for mutations affecting `BatchTransaction`
 */
export interface BatchTransactionInput {
  referenceNumber: string;
  description: string;
  postingDate?: GqlDate | null;
  corporatePeriodId?: GqlUUID | null;
  controlTotalAmount?: GqlBigFloat | null;
  currencyId: number;
  weekNumber?: number | null;
}

/**
 * Represents an update to a `BatchTransaction`. Fields that are set will be updated.
 */
export interface BatchTransactionPatch {
  referenceNumber?: string | null;
  description?: string | null;
  postingDate?: GqlDate | null;
  corporatePeriodId?: GqlUUID | null;
  controlTotalAmount?: GqlBigFloat | null;
  currencyId?: number | null;
  weekNumber?: number | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `BatchTransactionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyBatchTransactionHistoryFilter {
  every?: BatchTransactionHistoryFilter | null;
  some?: BatchTransactionHistoryFilter | null;
  none?: BatchTransactionHistoryFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `InvoiceBatch` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyInvoiceBatchFilter {
  every?: InvoiceBatchFilter | null;
  some?: InvoiceBatchFilter | null;
  none?: InvoiceBatchFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface BatchTransactionToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * All input for the `batchTransactionUpdate` mutation.
 */
export interface BatchTransactionUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  batchTransactionPatch?: BatchTransactionPatch | null;
}

/**
 * A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’
 */
export interface BigFloatFilter {
  isNull?: boolean | null;
  equalTo?: GqlBigFloat | null;
  notEqualTo?: GqlBigFloat | null;
  distinctFrom?: GqlBigFloat | null;
  notDistinctFrom?: GqlBigFloat | null;
  in?: GqlBigFloat[] | null;
  notIn?: GqlBigFloat[] | null;
  lessThan?: GqlBigFloat | null;
  lessThanOrEqualTo?: GqlBigFloat | null;
  greaterThan?: GqlBigFloat | null;
  greaterThanOrEqualTo?: GqlBigFloat | null;
}

/**
 * A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’
 */
export interface BigIntFilter {
  isNull?: boolean | null;
  equalTo?: GqlBigInt | null;
  notEqualTo?: GqlBigInt | null;
  distinctFrom?: GqlBigInt | null;
  notDistinctFrom?: GqlBigInt | null;
  in?: GqlBigInt[] | null;
  notIn?: GqlBigInt[] | null;
  lessThan?: GqlBigInt | null;
  lessThanOrEqualTo?: GqlBigInt | null;
  greaterThan?: GqlBigInt | null;
  greaterThanOrEqualTo?: GqlBigInt | null;
}

/**
 * A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’
 */
export interface BooleanFilter {
  isNull?: boolean | null;
  equalTo?: boolean | null;
  notEqualTo?: boolean | null;
  distinctFrom?: boolean | null;
  notDistinctFrom?: boolean | null;
  in?: boolean[] | null;
  notIn?: boolean[] | null;
  lessThan?: boolean | null;
  lessThanOrEqualTo?: boolean | null;
  greaterThan?: boolean | null;
  greaterThanOrEqualTo?: boolean | null;
}

/**
 * All input for the `budgetCreate` mutation.
 */
export interface BudgetCreateInput {
  clientMutationId?: string | null;
  budget: BudgetInput;
}

/**
 * All input for the `budgetDelete` mutation.
 */
export interface BudgetDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `Budget` object types. All fields are combined with a logical ‘and.’
 */
export interface BudgetFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  budgetTypeId?: IntFilter | null;
  projectId?: UUIDFilter | null;
  financialPeriodId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  isMultipleCurrencyBudget?: BooleanFilter | null;
  isPrimary?: BooleanFilter | null;
  isLocked?: BooleanFilter | null;
  isPreApproved?: BooleanFilter | null;
  companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isCashFlowBudget?: BooleanFilter | null;
  _isDatesRequired?: BooleanFilter | null;
  _isPeriodsRequired?: BooleanFilter | null;
  _isBudgetMaintenanceAllowed?: BooleanFilter | null;
  startingBudgetDate?: DateFilter | null;
  endingBudgetDate?: DateFilter | null;
  _sortOrder?: IntFilter | null;
  departmentBudgets?: BudgetToManyDepartmentBudgetFilter | null;
  departmentBudgetsExist?: boolean | null;
  and?: BudgetFilter[] | null;
  or?: BudgetFilter[] | null;
  not?: BudgetFilter | null;
}

/**
 * An input for mutations affecting `Budget`
 */
export interface BudgetInput {
  name: string;
  isPrimary: boolean;
  startingBudgetDate?: GqlDate | null;
  endingBudgetDate?: GqlDate | null;
}

/**
 * Represents an update to a `Budget`. Fields that are set will be updated.
 */
export interface BudgetPatch {
  name?: string | null;
  isPrimary?: boolean | null;
  startingBudgetDate?: GqlDate | null;
  endingBudgetDate?: GqlDate | null;
}

/**
 * A filter to be used against many `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface BudgetToManyDepartmentBudgetFilter {
  every?: DepartmentBudgetFilter | null;
  some?: DepartmentBudgetFilter | null;
  none?: DepartmentBudgetFilter | null;
}

/**
 * All input for the `budgetUpdate` mutation.
 */
export interface BudgetUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  budgetPatch?: BudgetPatch | null;
}

/**
 * A filter to be used against `BusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  linkedBusinessUnitId?: UUIDFilter | null;
  rollupBusinessUnitId?: UUIDFilter | null;
  isSharedTenantBusinessUnit?: BooleanFilter | null;
  isExternalTradingPartner?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isProductionBusinessUnit?: BooleanFilter | null;
  vendorAccessBusinessUnits?: BusinessUnitToManyVendorAccessBusinessUnitFilter | null;
  vendorAccessBusinessUnitsExist?: boolean | null;
  approvalBranchesByTransactionBusinessUnitId?: BusinessUnitToManyApprovalBranchFilter | null;
  approvalBranchesByTransactionBusinessUnitIdExist?: boolean | null;
  approvalSelectionConditionsByRequesterBusinessUnitId?: BusinessUnitToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterBusinessUnitIdExist?: boolean | null;
  approvalHistories?: BusinessUnitToManyApprovalHistoryFilter | null;
  approvalHistoriesExist?: boolean | null;
  userApprovals?: BusinessUnitToManyUserApprovalFilter | null;
  userApprovalsExist?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  documentPoolsByDefaultBusinessUnitId?: BusinessUnitToManyDocumentPoolFilter | null;
  documentPoolsByDefaultBusinessUnitIdExist?: boolean | null;
  cardAccounts?: BusinessUnitToManyCardAccountFilter | null;
  cardAccountsExist?: boolean | null;
  corporateWorkgroups?: BusinessUnitToManyCorporateWorkgroupFilter | null;
  corporateWorkgroupsExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  and?: BusinessUnitFilter[] | null;
  or?: BusinessUnitFilter[] | null;
  not?: BusinessUnitFilter | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `CardAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyCardAccountFilter {
  every?: CardAccountFilter | null;
  some?: CardAccountFilter | null;
  none?: CardAccountFilter | null;
}

/**
 * A filter to be used against many `CorporateWorkgroup` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyCorporateWorkgroupFilter {
  every?: CorporateWorkgroupFilter | null;
  some?: CorporateWorkgroupFilter | null;
  none?: CorporateWorkgroupFilter | null;
}

/**
 * A filter to be used against many `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyDocumentPoolFilter {
  every?: DocumentPoolFilter | null;
  some?: DocumentPoolFilter | null;
  none?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `VendorAccessBusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface BusinessUnitToManyVendorAccessBusinessUnitFilter {
  every?: VendorAccessBusinessUnitFilter | null;
  some?: VendorAccessBusinessUnitFilter | null;
  none?: VendorAccessBusinessUnitFilter | null;
}

/**
 * A filter to be used against `CardAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountFilter {
  id?: UUIDFilter | null;
  companyCardCompanyId?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  businessUnitId?: UUIDFilter | null;
  paymentAccountId?: UUIDFilter | null;
  documentPoolId?: UUIDFilter | null;
  documentTypeId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _pendingApprovers?: StringFilter | null;
  lastEntryDate?: StringFilter | null;
  entityTagsByEntityId?: CardAccountToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  notesByEntityId?: CardAccountToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  cardAccountTransactions?: CardAccountToManyCardAccountTransactionFilter | null;
  cardAccountTransactionsExist?: boolean | null;
  cardHolders?: CardAccountToManyCardHolderFilter | null;
  cardHoldersExist?: boolean | null;
  cardAccountHistoriesByEntityId?: CardAccountToManyCardAccountHistoryFilter | null;
  cardAccountHistoriesByEntityIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  companyCardCompanies?: CompanyCardCompanyFilter | null;
  companyCardCompaniesExists?: boolean | null;
  businessUnits?: BusinessUnitFilter | null;
  businessUnitsExists?: boolean | null;
  documentPools?: DocumentPoolFilter | null;
  documentPoolsExists?: boolean | null;
  and?: CardAccountFilter[] | null;
  or?: CardAccountFilter[] | null;
  not?: CardAccountFilter | null;
}

/**
 * A filter to be used against `CardAccountHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  cardAccount?: CardAccountFilter | null;
  cardAccountExists?: boolean | null;
  and?: CardAccountHistoryFilter[] | null;
  or?: CardAccountHistoryFilter[] | null;
  not?: CardAccountHistoryFilter | null;
}

/**
 * An input for mutations affecting `CardAccount`
 */
export interface CardAccountInput {
  description?: string | null;
  businessUnitId?: GqlUUID | null;
  paymentAccountId?: GqlUUID | null;
  documentPoolId?: GqlUUID | null;
}

/**
 * Represents an update to a `CardAccount`. Fields that are set will be updated.
 */
export interface CardAccountPatch {
  description?: string | null;
  businessUnitId?: GqlUUID | null;
  paymentAccountId?: GqlUUID | null;
  documentPoolId?: GqlUUID | null;
}

/**
 * A filter to be used against many `CardAccountHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountToManyCardAccountHistoryFilter {
  every?: CardAccountHistoryFilter | null;
  some?: CardAccountHistoryFilter | null;
  none?: CardAccountHistoryFilter | null;
}

/**
 * A filter to be used against many `CardAccountTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountToManyCardAccountTransactionFilter {
  every?: CardAccountTransactionFilter | null;
  some?: CardAccountTransactionFilter | null;
  none?: CardAccountTransactionFilter | null;
}

/**
 * A filter to be used against many `CardHolder` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountToManyCardHolderFilter {
  every?: CardHolderFilter | null;
  some?: CardHolderFilter | null;
  none?: CardHolderFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against `CardAccountTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CardAccountTransactionFilter {
  id?: UUIDFilter | null;
  cardAccountId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  entryDate?: StringFilter | null;
  transactionDate?: DatetimeFilter | null;
  transactionType?: StringFilter | null;
  executionType?: StringFilter | null;
  description?: StringFilter | null;
  currencyId?: IntFilter | null;
  amount?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _entityDocumentId?: UUIDFilter | null;
  cardAccount?: CardAccountFilter | null;
  cardAccountExists?: boolean | null;
  currency?: CurrencyFilter | null;
  currencyExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  and?: CardAccountTransactionFilter[] | null;
  or?: CardAccountTransactionFilter[] | null;
  not?: CardAccountTransactionFilter | null;
}

/**
 * All input for the `cardAccountUpdate` mutation.
 */
export interface CardAccountUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  cardAccountPatch?: CardAccountPatch | null;
}

/**
 * All input for the `cardHolderCreate` mutation.
 */
export interface CardHolderCreateInput {
  clientMutationId?: string | null;
  cardHolder: CardHolderInput;
}

/**
 * All input for the `cardHolderDelete` mutation.
 */
export interface CardHolderDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `CardHolder` object types. All fields are combined with a logical ‘and.’
 */
export interface CardHolderFilter {
  id?: UUIDFilter | null;
  cardAccountId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  cardNumber?: StringFilter | null;
  cardExpiry?: StringFilter | null;
  cardStatus?: StringFilter | null;
  cardFirstName?: StringFilter | null;
  cardLastName?: StringFilter | null;
  cardDepartment?: StringFilter | null;
  departmentId?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  tenantUserId?: UUIDFilter | null;
  documentPoolId?: UUIDFilter | null;
  isMyDocumentsUsed?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _pendingApprovers?: StringFilter | null;
  documentTypeId?: IntFilter | null;
  cardFullName?: StringFilter | null;
  lastEntryDate?: StringFilter | null;
  entityTagsByEntityId?: CardHolderToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  notesByEntityId?: CardHolderToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  cardHolderUser?: AvailableCardHolderUserFilter | null;
  cardHolderUserExists?: boolean | null;
  cardHolderTransactions?: CardHolderToManyCardHolderTransactionFilter | null;
  cardHolderTransactionsExist?: boolean | null;
  cardHolderHistoriesByEntityId?: CardHolderToManyCardHolderHistoryFilter | null;
  cardHolderHistoriesByEntityIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  cardAccounts?: CardAccountFilter | null;
  cardAccountsExists?: boolean | null;
  departments?: DepartmentFilter | null;
  departmentsExists?: boolean | null;
  documentPools?: DocumentPoolFilter | null;
  documentPoolsExists?: boolean | null;
  userGroups?: UserGroupFilter | null;
  userGroupsExists?: boolean | null;
  and?: CardHolderFilter[] | null;
  or?: CardHolderFilter[] | null;
  not?: CardHolderFilter | null;
}

/**
 * A filter to be used against `CardHolderHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CardHolderHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  cardHolder?: CardHolderFilter | null;
  cardHolderExists?: boolean | null;
  and?: CardHolderHistoryFilter[] | null;
  or?: CardHolderHistoryFilter[] | null;
  not?: CardHolderHistoryFilter | null;
}

/**
 * An input for mutations affecting `CardHolder`
 */
export interface CardHolderInput {
  cardAccountId: GqlUUID;
  cardNumber?: string | null;
  departmentId?: GqlUUID | null;
  userGroupId?: GqlUUID | null;
  tenantUserId?: GqlUUID | null;
  documentPoolId?: GqlUUID | null;
  cardFullName?: string | null;
}

/**
 * Represents an update to a `CardHolder`. Fields that are set will be updated.
 */
export interface CardHolderPatch {
  cardAccountId?: GqlUUID | null;
  cardNumber?: string | null;
  departmentId?: GqlUUID | null;
  userGroupId?: GqlUUID | null;
  tenantUserId?: GqlUUID | null;
  documentPoolId?: GqlUUID | null;
  cardFullName?: string | null;
}

/**
 * A filter to be used against many `CardHolderHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CardHolderToManyCardHolderHistoryFilter {
  every?: CardHolderHistoryFilter | null;
  some?: CardHolderHistoryFilter | null;
  none?: CardHolderHistoryFilter | null;
}

/**
 * A filter to be used against many `CardHolderTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CardHolderToManyCardHolderTransactionFilter {
  every?: CardHolderTransactionFilter | null;
  some?: CardHolderTransactionFilter | null;
  none?: CardHolderTransactionFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface CardHolderToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface CardHolderToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against `CardHolderTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CardHolderTransactionFilter {
  id?: UUIDFilter | null;
  cardHolderId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  entryDate?: StringFilter | null;
  transactionDate?: DatetimeFilter | null;
  cardTransactionType?: StringFilter | null;
  responseCode?: StringFilter | null;
  responseDescription?: StringFilter | null;
  authNumber?: StringFilter | null;
  description?: StringFilter | null;
  merchantCategory?: StringFilter | null;
  terminalCountry?: StringFilter | null;
  terminalState?: StringFilter | null;
  terminalCity?: StringFilter | null;
  terminalLocation?: StringFilter | null;
  terminalOwner?: StringFilter | null;
  transactionCurrencyCode?: StringFilter | null;
  otherCurrencyCode?: StringFilter | null;
  currencyId?: IntFilter | null;
  amount?: BigFloatFilter | null;
  terminalAmount?: BigFloatFilter | null;
  terminalExchangeRate?: BigFloatFilter | null;
  clearedAmount?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _entityDocumentId?: UUIDFilter | null;
  linkInvoiceId?: UUIDFilter | null;
  cardHolder?: CardHolderFilter | null;
  cardHolderExists?: boolean | null;
  currency?: CurrencyFilter | null;
  currencyExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  and?: CardHolderTransactionFilter[] | null;
  or?: CardHolderTransactionFilter[] | null;
  not?: CardHolderTransactionFilter | null;
}

/**
 * All input for the `cardHolderUpdate` mutation.
 */
export interface CardHolderUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  cardHolderPatch?: CardHolderPatch | null;
}

/**
 * A filter to be used against `Carrier` object types. All fields are combined with a logical ‘and.’
 */
export interface CarrierFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  alias?: StringFilter | null;
  iataCode?: StringFilter | null;
  icaoCode?: StringFilter | null;
  countryId?: IntFilter | null;
  isAirline?: BooleanFilter | null;
  isTrainService?: BooleanFilter | null;
  isBusService?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _sortOrder?: StringFilter | null;
  tripItemsByDepartureCarrierId?: CarrierToManyTripItemFilter | null;
  tripItemsByDepartureCarrierIdExist?: boolean | null;
  tripItemsByReturnCarrierId?: CarrierToManyTripItemFilter | null;
  tripItemsByReturnCarrierIdExist?: boolean | null;
  and?: CarrierFilter[] | null;
  or?: CarrierFilter[] | null;
  not?: CarrierFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface CarrierToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against ChartLayoutType fields. All fields are combined with a logical ‘and.’
 */
export interface ChartLayoutTypeFilter {
  isNull?: boolean | null;
  equalTo?: ChartLayoutType | null;
  notEqualTo?: ChartLayoutType | null;
  distinctFrom?: ChartLayoutType | null;
  notDistinctFrom?: ChartLayoutType | null;
  in?: ChartLayoutType[] | null;
  notIn?: ChartLayoutType[] | null;
  lessThan?: ChartLayoutType | null;
  lessThanOrEqualTo?: ChartLayoutType | null;
  greaterThan?: ChartLayoutType | null;
  greaterThanOrEqualTo?: ChartLayoutType | null;
}

/**
 * All input for the `chartOfAccountCreate` mutation.
 */
export interface ChartOfAccountCreateInput {
  clientMutationId?: string | null;
  chartOfAccount: ChartOfAccountInput;
}

/**
 * All input for the `chartOfAccountDefaultUpdate` mutation.
 */
export interface ChartOfAccountDefaultUpdateInput {
  clientMutationId?: string | null;
  defaultChartOfAccountId?: GqlUUID | null;
}

/**
 * All input for the `chartOfAccountDelete` mutation.
 */
export interface ChartOfAccountDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `ChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  chartOfAccountTypeId?: IntFilter | null;
  isSubsetChartOfAccounts?: BooleanFilter | null;
  primaryChartOfAccountId?: UUIDFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  companyCode?: StringFilter | null;
  businessArea?: StringFilter | null;
  costCenter?: StringFilter | null;
  glAccount?: StringFilter | null;
  isNewProjectSelectable?: BooleanFilter | null;
  productionStatusTypeId?: IntFilter | null;
  corporateChartOfAccountId?: UUIDFilter | null;
  _isCorporate?: BooleanFilter | null;
  vendorDefaultAccounts?: ChartOfAccountToManyVendorDefaultAccountFilter | null;
  vendorDefaultAccountsExist?: boolean | null;
  lookupAccounts?: ChartOfAccountToManyLookupAccountFilter | null;
  lookupAccountsExist?: boolean | null;
  accountsByChartOfAccountId?: ChartOfAccountToManyAccountFilter | null;
  accountsByChartOfAccountIdExist?: boolean | null;
  projectsByChartOfAccountsId?: ChartOfAccountToManyProjectFilter | null;
  projectsByChartOfAccountsIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  chartOfAccountType?: ChartOfAccountTypeFilter | null;
  chartOfAccountTypeExists?: boolean | null;
  productionStatus?: ProductionStatusFilter | null;
  productionStatusExists?: boolean | null;
  and?: ChartOfAccountFilter[] | null;
  or?: ChartOfAccountFilter[] | null;
  not?: ChartOfAccountFilter | null;
}

/**
 * An input for mutations affecting `ChartOfAccount`
 */
export interface ChartOfAccountInput {
  name: string;
  description?: string | null;
  abbreviation?: string | null;
  isDraft?: boolean | null;
  isNewProjectSelectable?: boolean | null;
  productionStatusTypeId?: number | null;
  corporateChartOfAccountId?: GqlUUID | null;
}

/**
 * Represents an update to a `ChartOfAccount`. Fields that are set will be updated.
 */
export interface ChartOfAccountPatch {
  name?: string | null;
  description?: string | null;
  abbreviation?: string | null;
  isDraft?: boolean | null;
  isNewProjectSelectable?: boolean | null;
  productionStatusTypeId?: number | null;
  corporateChartOfAccountId?: GqlUUID | null;
}

/**
 * A filter to be used against many `Account` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountToManyAccountFilter {
  every?: AccountFilter | null;
  some?: AccountFilter | null;
  none?: AccountFilter | null;
}

/**
 * A filter to be used against many `LookupAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountToManyLookupAccountFilter {
  every?: LookupAccountFilter | null;
  some?: LookupAccountFilter | null;
  none?: LookupAccountFilter | null;
}

/**
 * A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountToManyProjectFilter {
  every?: ProjectFilter | null;
  some?: ProjectFilter | null;
  none?: ProjectFilter | null;
}

/**
 * A filter to be used against many `VendorDefaultAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountToManyVendorDefaultAccountFilter {
  every?: VendorDefaultAccountFilter | null;
  some?: VendorDefaultAccountFilter | null;
  none?: VendorDefaultAccountFilter | null;
}

/**
 * A filter to be used against `ChartOfAccountType` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountTypeFilter {
  id?: IntFilter | null;
  chartOfAccountType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isCorporate?: BooleanFilter | null;
  isFeature?: BooleanFilter | null;
  isEpisodic?: BooleanFilter | null;
  isCommercial?: BooleanFilter | null;
  isOtherProject?: BooleanFilter | null;
  isSubsetChart?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  chartOfAccounts?: ChartOfAccountTypeToManyChartOfAccountFilter | null;
  chartOfAccountsExist?: boolean | null;
  companyChartOfAccounts?: ChartOfAccountTypeToManyCompanyChartOfAccountFilter | null;
  companyChartOfAccountsExist?: boolean | null;
  corporateChartOfAccounts?: ChartOfAccountTypeToManyCorporateChartOfAccountFilter | null;
  corporateChartOfAccountsExist?: boolean | null;
  and?: ChartOfAccountTypeFilter[] | null;
  or?: ChartOfAccountTypeFilter[] | null;
  not?: ChartOfAccountTypeFilter | null;
}

/**
 * A filter to be used against many `ChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountTypeToManyChartOfAccountFilter {
  every?: ChartOfAccountFilter | null;
  some?: ChartOfAccountFilter | null;
  none?: ChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CompanyChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountTypeToManyCompanyChartOfAccountFilter {
  every?: CompanyChartOfAccountFilter | null;
  some?: CompanyChartOfAccountFilter | null;
  none?: CompanyChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CorporateChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartOfAccountTypeToManyCorporateChartOfAccountFilter {
  every?: CorporateChartOfAccountFilter | null;
  some?: CorporateChartOfAccountFilter | null;
  none?: CorporateChartOfAccountFilter | null;
}

/**
 * All input for the `chartOfAccountUpdate` mutation.
 */
export interface ChartOfAccountUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  chartOfAccountPatch?: ChartOfAccountPatch | null;
}

/**
 * A filter to be used against `ChartType` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartTypeFilter {
  id?: IntFilter | null;
  caption?: StringFilter | null;
  chartPosition?: IntFilter | null;
  isWebAllowed?: BooleanFilter | null;
  isMobileAllowed?: BooleanFilter | null;
  isUserDefault?: BooleanFilter | null;
  chartLayout?: ChartLayoutTypeFilter | null;
  isOmitEmptyChart?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  departmentOccupationCharts?: ChartTypeToManyDepartmentOccupationChartFilter | null;
  departmentOccupationChartsExist?: boolean | null;
  and?: ChartTypeFilter[] | null;
  or?: ChartTypeFilter[] | null;
  not?: ChartTypeFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationChart` object types. All fields are combined with a logical ‘and.’
 */
export interface ChartTypeToManyDepartmentOccupationChartFilter {
  every?: DepartmentOccupationChartFilter | null;
  some?: DepartmentOccupationChartFilter | null;
  none?: DepartmentOccupationChartFilter | null;
}

/**
 * A filter to be used against CitizenType fields. All fields are combined with a logical ‘and.’
 */
export interface CitizenTypeFilter {
  isNull?: boolean | null;
  equalTo?: CitizenType | null;
  notEqualTo?: CitizenType | null;
  distinctFrom?: CitizenType | null;
  notDistinctFrom?: CitizenType | null;
  in?: CitizenType[] | null;
  notIn?: CitizenType[] | null;
  lessThan?: CitizenType | null;
  lessThanOrEqualTo?: CitizenType | null;
  greaterThan?: CitizenType | null;
  greaterThanOrEqualTo?: CitizenType | null;
}

/**
 * A filter to be used against `CityArea` object types. All fields are combined with a logical ‘and.’
 */
export interface CityAreaFilter {
  id?: IntFilter | null;
  cityArea?: StringFilter | null;
  stateRegionId?: IntFilter | null;
  countryId?: IntFilter | null;
  description?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveGroups?: CityAreaToManyProductionIncentiveGroupFilter | null;
  productionIncentiveGroupsExist?: boolean | null;
  taxCodes?: CityAreaToManyTaxCodeFilter | null;
  taxCodesExist?: boolean | null;
  employeesByTaxCityAreaId?: CityAreaToManyEmployeeFilter | null;
  employeesByTaxCityAreaIdExist?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: CityAreaFilter[] | null;
  or?: CityAreaFilter[] | null;
  not?: CityAreaFilter | null;
}

/**
 * A filter to be used against many `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface CityAreaToManyEmployeeFilter {
  every?: EmployeeFilter | null;
  some?: EmployeeFilter | null;
  none?: EmployeeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CityAreaToManyProductionIncentiveGroupFilter {
  every?: ProductionIncentiveGroupFilter | null;
  some?: ProductionIncentiveGroupFilter | null;
  none?: ProductionIncentiveGroupFilter | null;
}

/**
 * A filter to be used against many `TaxCode` object types. All fields are combined with a logical ‘and.’
 */
export interface CityAreaToManyTaxCodeFilter {
  every?: TaxCodeFilter | null;
  some?: TaxCodeFilter | null;
  none?: TaxCodeFilter | null;
}

/**
 * A filter to be used against Colors fields. All fields are combined with a logical ‘and.’
 */
export interface ColorsFilter {
  isNull?: boolean | null;
  equalTo?: Colors | null;
  notEqualTo?: Colors | null;
  distinctFrom?: Colors | null;
  notDistinctFrom?: Colors | null;
  in?: Colors[] | null;
  notIn?: Colors[] | null;
  lessThan?: Colors | null;
  lessThanOrEqualTo?: Colors | null;
  greaterThan?: Colors | null;
  greaterThanOrEqualTo?: Colors | null;
}

/**
 * All input for the `communicationChannelCreate` mutation.
 */
export interface CommunicationChannelCreateInput {
  clientMutationId?: string | null;
  communicationChannel: (CommunicationChannelInput | null)[];
}

/**
 * All input for the `communicationChannelDelete` mutation.
 */
export interface CommunicationChannelDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `CommunicationChannel` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationChannelFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  isMessages?: BooleanFilter | null;
  isReports?: BooleanFilter | null;
  _isAlerts?: BooleanFilter | null;
  notificationTypeId?: IntFilter | null;
  _notificationType?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  communicationGroupItems?: CommunicationChannelToManyCommunicationGroupItemFilter | null;
  communicationGroupItemsExist?: boolean | null;
  and?: CommunicationChannelFilter[] | null;
  or?: CommunicationChannelFilter[] | null;
  not?: CommunicationChannelFilter | null;
}

/**
 * An input for mutations affecting `CommunicationChannel`
 */
export interface CommunicationChannelInput {
  name: string;
  description?: string | null;
  isMessages?: boolean | null;
  isReports?: boolean | null;
}

/**
 * Represents an update to a `CommunicationChannel`. Fields that are set will be updated.
 */
export interface CommunicationChannelPatch {
  name?: string | null;
  description?: string | null;
  isMessages?: boolean | null;
  isReports?: boolean | null;
}

/**
 * A filter to be used against many `CommunicationGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationChannelToManyCommunicationGroupItemFilter {
  every?: CommunicationGroupItemFilter | null;
  some?: CommunicationGroupItemFilter | null;
  none?: CommunicationGroupItemFilter | null;
}

/**
 * All input for the `communicationChannelUpdate` mutation.
 */
export interface CommunicationChannelUpdateInput {
  clientMutationId?: string | null;
  communicationChannelsUpdate: (CommunicationChannelUpdateTypeInput | null)[];
}

/**
 * An input for mutations affecting `CommunicationChannelUpdateType`
 */
export interface CommunicationChannelUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  communicationChannelPatch?: CommunicationChannelPatch | null;
}

/**
 * All input for the `communicationGroupCreate` mutation.
 */
export interface CommunicationGroupCreateInput {
  clientMutationId?: string | null;
  communicationGroup: CommunicationGroupInput;
  communicationGroupItems?: (CommunicationGroupItemInput | null)[] | null;
}

/**
 * All input for the `communicationGroupDelete` mutation.
 */
export interface CommunicationGroupDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `CommunicationGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationGroupFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  statusTypeId?: IntFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  communicationGroupItemsByCommunicationGroupId?: CommunicationGroupToManyCommunicationGroupItemFilter | null;
  communicationGroupItemsByCommunicationGroupIdExist?: boolean | null;
  companyCommunicationGroups?: CommunicationGroupToManyCompanyCommunicationGroupFilter | null;
  companyCommunicationGroupsExist?: boolean | null;
  departmentOccupationTemplates?: CommunicationGroupToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: CommunicationGroupToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  and?: CommunicationGroupFilter[] | null;
  or?: CommunicationGroupFilter[] | null;
  not?: CommunicationGroupFilter | null;
}

/**
 * An input for mutations affecting `CommunicationGroup`
 */
export interface CommunicationGroupInput {
  name: string;
  description?: string | null;
}

/**
 * A filter to be used against `CommunicationGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationGroupItemFilter {
  id?: UUIDFilter | null;
  _communicationGroupId?: UUIDFilter | null;
  communicationChannelId?: UUIDFilter | null;
  _communicationChannelName?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isRecipientOnly?: BooleanFilter | null;
  isSenderOnly?: BooleanFilter | null;
  communicationGroup?: CommunicationGroupFilter | null;
  communicationGroupExists?: boolean | null;
  communicationChannel?: CommunicationChannelFilter | null;
  communicationChannelExists?: boolean | null;
  and?: CommunicationGroupItemFilter[] | null;
  or?: CommunicationGroupItemFilter[] | null;
  not?: CommunicationGroupItemFilter | null;
}

/**
 * An input for mutations affecting `CommunicationGroupItem`
 */
export interface CommunicationGroupItemInput {
  communicationChannelId: GqlUUID;
  isRecipientOnly?: boolean | null;
  isSenderOnly?: boolean | null;
}

/**
 * Represents an update to a `CommunicationGroupItem`. Fields that are set will be updated.
 */
export interface CommunicationGroupItemPatch {
  communicationChannelId?: GqlUUID | null;
  isRecipientOnly?: boolean | null;
  isSenderOnly?: boolean | null;
}

/**
 * An input for mutations affecting `CommunicationGroupItemUpdateType`
 */
export interface CommunicationGroupItemUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  communicationGroupItemPatch?: CommunicationGroupItemPatch | null;
}

/**
 * Represents an update to a `CommunicationGroup`. Fields that are set will be updated.
 */
export interface CommunicationGroupPatch {
  name?: string | null;
  description?: string | null;
}

/**
 * A filter to be used against many `CommunicationGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationGroupToManyCommunicationGroupItemFilter {
  every?: CommunicationGroupItemFilter | null;
  some?: CommunicationGroupItemFilter | null;
  none?: CommunicationGroupItemFilter | null;
}

/**
 * A filter to be used against many `CompanyCommunicationGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationGroupToManyCompanyCommunicationGroupFilter {
  every?: CompanyCommunicationGroupFilter | null;
  some?: CompanyCommunicationGroupFilter | null;
  none?: CompanyCommunicationGroupFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationGroupToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface CommunicationGroupToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * All input for the `communicationGroupUpdate` mutation.
 */
export interface CommunicationGroupUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  communicationGroupPatch?: CommunicationGroupPatch | null;
  communicationGroupItemsCreate?: (CommunicationGroupItemInput | null)[] | null;
  communicationGroupItemsUpdate?: (CommunicationGroupItemUpdateTypeInput | null)[] | null;
  communicationGroupItemsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * A filter to be used against `CompanyAccessGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyAccessGroupFilter {
  id?: UUIDFilter | null;
  accessGroupId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  accessGroup?: AccessGroupFilter | null;
  accessGroupExists?: boolean | null;
  and?: CompanyAccessGroupFilter[] | null;
  or?: CompanyAccessGroupFilter[] | null;
  not?: CompanyAccessGroupFilter | null;
}

/**
 * A filter to be used against `CompanyAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyAddressFilter {
  id?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  _entityTypeId?: IntFilter | null;
  addressTypeId?: IntFilter | null;
  addressAlias?: StringFilter | null;
  addressLine1?: StringFilter | null;
  addressLine2?: StringFilter | null;
  cityName?: StringFilter | null;
  attention?: StringFilter | null;
  postalCode?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  isAddressOverridden?: BooleanFilter | null;
  addressOverride?: StringListFilter | null;
  mainContact?: StringFilter | null;
  alternateContact?: StringFilter | null;
  emailAddress?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  mobileNumber?: StringFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  isPrimaryAddress?: BooleanFilter | null;
  isDeliveryAddress?: BooleanFilter | null;
  companyBusinessUnits?: CompanyAddressToManyCompanyBusinessUnitFilter | null;
  companyBusinessUnitsExist?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  companyBusinessUnit?: CompanyBusinessUnitFilter | null;
  companyBusinessUnitExists?: boolean | null;
  and?: CompanyAddressFilter[] | null;
  or?: CompanyAddressFilter[] | null;
  not?: CompanyAddressFilter | null;
}

/**
 * An input for mutations affecting `CompanyAddress`
 */
export interface CompanyAddressInput {
  addressTypeId?: number | null;
  addressAlias?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  cityName?: string | null;
  attention?: string | null;
  postalCode?: string | null;
  countryId?: number | null;
  stateRegionId?: number | null;
  isAddressOverridden?: boolean | null;
  addressOverride?: (string | null)[] | null;
  mainContact?: string | null;
  alternateContact?: string | null;
  geoLocation?: PointInput | null;
  emailAddress?: string | null;
  telephoneNumber?: string | null;
  mobileNumber?: string | null;
  isPrimaryAddress?: boolean | null;
  isDeliveryAddress?: boolean | null;
}

/**
 * Represents an update to a `CompanyAddress`. Fields that are set will be updated.
 */
export interface CompanyAddressPatch {
  addressTypeId?: number | null;
  addressAlias?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  cityName?: string | null;
  attention?: string | null;
  postalCode?: string | null;
  countryId?: number | null;
  stateRegionId?: number | null;
  isAddressOverridden?: boolean | null;
  addressOverride?: (string | null)[] | null;
  mainContact?: string | null;
  alternateContact?: string | null;
  geoLocation?: PointInput | null;
  emailAddress?: string | null;
  telephoneNumber?: string | null;
  mobileNumber?: string | null;
  isPrimaryAddress?: boolean | null;
  isDeliveryAddress?: boolean | null;
}

/**
 * A filter to be used against many `CompanyBusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyAddressToManyCompanyBusinessUnitFilter {
  every?: CompanyBusinessUnitFilter | null;
  some?: CompanyBusinessUnitFilter | null;
  none?: CompanyBusinessUnitFilter | null;
}

/**
 * An input for mutations affecting `CompanyAddressUpdateType`
 */
export interface CompanyAddressUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  companyAddressPatch?: CompanyAddressPatch | null;
}

/**
 * A filter to be used against `CompanyAvailableCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyAvailableCurrencyFilter {
  id?: IntFilter | null;
  name?: StringFilter | null;
  isoCode?: StringFilter | null;
  companyExchangeRate?: BigFloatFilter | null;
  companySpotRate?: BigFloatFilter | null;
  currencySymbol?: StringFilter | null;
  companyBusinessUnitCurrenciesByCurrencyId?: CompanyAvailableCurrencyToManyCompanyBusinessUnitCurrencyFilter | null;
  companyBusinessUnitCurrenciesByCurrencyIdExist?: boolean | null;
  departmentBudgetsByCurrencyId?: CompanyAvailableCurrencyToManyDepartmentBudgetFilter | null;
  departmentBudgetsByCurrencyIdExist?: boolean | null;
  and?: CompanyAvailableCurrencyFilter[] | null;
  or?: CompanyAvailableCurrencyFilter[] | null;
  not?: CompanyAvailableCurrencyFilter | null;
}

/**
 * A filter to be used against many `CompanyBusinessUnitCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyAvailableCurrencyToManyCompanyBusinessUnitCurrencyFilter {
  every?: CompanyBusinessUnitCurrencyFilter | null;
  some?: CompanyBusinessUnitCurrencyFilter | null;
  none?: CompanyBusinessUnitCurrencyFilter | null;
}

/**
 * A filter to be used against many `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyAvailableCurrencyToManyDepartmentBudgetFilter {
  every?: DepartmentBudgetFilter | null;
  some?: DepartmentBudgetFilter | null;
  none?: DepartmentBudgetFilter | null;
}

/**
 * A filter to be used against `CompanyBusinessUnitCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitCurrencyFilter {
  id?: UUIDFilter | null;
  companyBusinessUnitId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  isPrimary?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  companyCurrency?: CompanyAvailableCurrencyFilter | null;
  companyCurrencyExists?: boolean | null;
  companyBusinessUnit?: CompanyBusinessUnitFilter | null;
  companyBusinessUnitExists?: boolean | null;
  and?: CompanyBusinessUnitCurrencyFilter[] | null;
  or?: CompanyBusinessUnitCurrencyFilter[] | null;
  not?: CompanyBusinessUnitCurrencyFilter | null;
}

/**
 * An input for mutations affecting `CompanyBusinessUnitCurrency`
 */
export interface CompanyBusinessUnitCurrencyInput {
  currencyId?: number | null;
  isPrimary?: boolean | null;
}

/**
 * Represents an update to a `CompanyBusinessUnitCurrency`. Fields that are set will be updated.
 */
export interface CompanyBusinessUnitCurrencyPatch {
  currencyId?: number | null;
  isPrimary?: boolean | null;
}

/**
 * An input for mutations affecting `CompanyBusinessUnitCurrencyUpdateType`
 */
export interface CompanyBusinessUnitCurrencyUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  companyBusinessUnitCurrencyPatch?: CompanyBusinessUnitCurrencyPatch | null;
}

/**
 * A filter to be used against `CompanyBusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  companyAddressId?: UUIDFilter | null;
  emailReplyTo?: StringFilter | null;
  emailReplyName?: StringFilter | null;
  emailProductionOfficeDescription?: StringFilter | null;
  emailFixedCc?: StringFilter | null;
  companyCode?: StringFilter | null;
  businessArea?: StringFilter | null;
  costCenter?: StringFilter | null;
  glAccount?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isProtected?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isHistory?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _companyBusinessUnitId?: UUIDFilter | null;
  _isProductionBusinessUnit?: BooleanFilter | null;
  chartOfAccountId?: UUIDFilter | null;
  _isCorporateWorkgroupRequired?: BooleanFilter | null;
  _companyBusinessUnitCompanyId?: UUIDFilter | null;
  batchTransactionsByBusinessUnitId?: CompanyBusinessUnitToManyBatchTransactionFilter | null;
  batchTransactionsByBusinessUnitIdExist?: boolean | null;
  invoiceDistributionsByBusinessUnitId?: CompanyBusinessUnitToManyInvoiceDistributionFilter | null;
  invoiceDistributionsByBusinessUnitIdExist?: boolean | null;
  invoicesByBusinessUnitId?: CompanyBusinessUnitToManyInvoiceFilter | null;
  invoicesByBusinessUnitIdExist?: boolean | null;
  payCyclesByBusinessUnitId?: CompanyBusinessUnitToManyPayCycleFilter | null;
  payCyclesByBusinessUnitIdExist?: boolean | null;
  paymentsByBusinessUnitId?: CompanyBusinessUnitToManyPaymentFilter | null;
  paymentsByBusinessUnitIdExist?: boolean | null;
  companyAddressesByCompanyIdAndEntityId?: CompanyBusinessUnitToManyCompanyAddressFilter | null;
  companyAddressesByCompanyIdAndEntityIdExist?: boolean | null;
  companyBusinessUnitCurrencies?: CompanyBusinessUnitToManyCompanyBusinessUnitCurrencyFilter | null;
  companyBusinessUnitCurrenciesExist?: boolean | null;
  departmentBudgetsByBusinessUnitId?: CompanyBusinessUnitToManyDepartmentBudgetFilter | null;
  departmentBudgetsByBusinessUnitIdExist?: boolean | null;
  userDefaultsByBusinessUnitId?: CompanyBusinessUnitToManyUserDefaultFilter | null;
  userDefaultsByBusinessUnitIdExist?: boolean | null;
  purchaseOrderInvoiceScheduleDistributionsByBusinessUnitId?: CompanyBusinessUnitToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByBusinessUnitIdExist?: boolean | null;
  purchaseOrdersByBusinessUnitId?: CompanyBusinessUnitToManyPurchaseOrderFilter | null;
  purchaseOrdersByBusinessUnitIdExist?: boolean | null;
  travelAuthorizationsByBusinessUnitId?: CompanyBusinessUnitToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByBusinessUnitIdExist?: boolean | null;
  tripBreakdownsByBusinessUnitId?: CompanyBusinessUnitToManyTripBreakdownFilter | null;
  tripBreakdownsByBusinessUnitIdExist?: boolean | null;
  businessUnit?: BusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  companyAddress?: CompanyAddressFilter | null;
  companyAddressExists?: boolean | null;
  companyChartOfAccount?: CompanyChartOfAccountFilter | null;
  companyChartOfAccountExists?: boolean | null;
  and?: CompanyBusinessUnitFilter[] | null;
  or?: CompanyBusinessUnitFilter[] | null;
  not?: CompanyBusinessUnitFilter | null;
}

/**
 * Represents an update to a `CompanyBusinessUnit`. Fields that are set will be updated.
 */
export interface CompanyBusinessUnitPatch {
  emailReplyTo?: string | null;
  emailReplyName?: string | null;
  emailProductionOfficeDescription?: string | null;
  emailFixedCc?: string | null;
  companyCode?: string | null;
  businessArea?: string | null;
  costCenter?: string | null;
  glAccount?: string | null;
  chartOfAccountId?: GqlUUID | null;
}

/**
 * A filter to be used against many `BatchTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyBatchTransactionFilter {
  every?: BatchTransactionFilter | null;
  some?: BatchTransactionFilter | null;
  none?: BatchTransactionFilter | null;
}

/**
 * A filter to be used against many `CompanyAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyCompanyAddressFilter {
  every?: CompanyAddressFilter | null;
  some?: CompanyAddressFilter | null;
  none?: CompanyAddressFilter | null;
}

/**
 * A filter to be used against many `CompanyBusinessUnitCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyCompanyBusinessUnitCurrencyFilter {
  every?: CompanyBusinessUnitCurrencyFilter | null;
  some?: CompanyBusinessUnitCurrencyFilter | null;
  none?: CompanyBusinessUnitCurrencyFilter | null;
}

/**
 * A filter to be used against many `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyDepartmentBudgetFilter {
  every?: DepartmentBudgetFilter | null;
  some?: DepartmentBudgetFilter | null;
  none?: DepartmentBudgetFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyInvoiceDistributionFilter {
  every?: InvoiceDistributionFilter | null;
  some?: InvoiceDistributionFilter | null;
  none?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyBusinessUnitToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * All input for the `companyBusinessUnitUpdate` mutation.
 */
export interface CompanyBusinessUnitUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  companyBusinessUnitPatch?: CompanyBusinessUnitPatch | null;
  companyAddressCreate?: (CompanyAddressInput | null)[] | null;
  companyAddressUpdate?: (CompanyAddressUpdateTypeInput | null)[] | null;
  companyAddressDelete?: (EntityDeleteInput | null)[] | null;
  companyBusinessUnitCurrencyCreate?: (CompanyBusinessUnitCurrencyInput | null)[] | null;
  companyBusinessUnitCurrencyUpdate?: (CompanyBusinessUnitCurrencyUpdateTypeInput | null)[] | null;
  companyBusinessUnitCurrencyDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `companyCardCompanyCreate` mutation.
 */
export interface CompanyCardCompanyCreateInput {
  clientMutationId?: string | null;
  companyCardCompany: CompanyCardCompanyInput;
  cardAccount?: CardAccountInput | null;
}

/**
 * All input for the `companyCardCompanyDelete` mutation.
 */
export interface CompanyCardCompanyDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `CompanyCardCompany` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCardCompanyFilter {
  id?: UUIDFilter | null;
  cardCompanyId?: IntFilter | null;
  apiKey?: StringFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _cardCompanyName?: StringFilter | null;
  _processTypeId?: IntFilter | null;
  _accountExtractionTypeId?: IntFilter | null;
  _accountDocumentTypeId?: IntFilter | null;
  _cardholderExtractionTypeId?: IntFilter | null;
  _cardholderDocumentTypeId?: IntFilter | null;
  _paymentCardTypeId?: IntFilter | null;
  _cardNumberSearchPrefix?: StringFilter | null;
  _isControllingCardAccountRequired?: BooleanFilter | null;
  _isCreditCard?: BooleanFilter | null;
  _isDebitCard?: BooleanFilter | null;
  _isPayCard?: BooleanFilter | null;
  _isManualProcess?: BooleanFilter | null;
  cardAccounts?: CompanyCardCompanyToManyCardAccountFilter | null;
  cardAccountsExist?: boolean | null;
  and?: CompanyCardCompanyFilter[] | null;
  or?: CompanyCardCompanyFilter[] | null;
  not?: CompanyCardCompanyFilter | null;
}

/**
 * An input for mutations affecting `CompanyCardCompany`
 */
export interface CompanyCardCompanyInput {
  cardCompanyId: number;
  apiKey?: string | null;
}

/**
 * Represents an update to a `CompanyCardCompany`. Fields that are set will be updated.
 */
export interface CompanyCardCompanyPatch {
  cardCompanyId?: number | null;
  apiKey?: string | null;
}

/**
 * A filter to be used against many `CardAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCardCompanyToManyCardAccountFilter {
  every?: CardAccountFilter | null;
  some?: CardAccountFilter | null;
  none?: CardAccountFilter | null;
}

/**
 * All input for the `companyCardCompanyUpdate` mutation.
 */
export interface CompanyCardCompanyUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  companyCardCompanyPatch?: CompanyCardCompanyPatch | null;
}

/**
 * A filter to be used against `CompanyCardHolder` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCardHolderFilter {
  id?: UUIDFilter | null;
  cardAccountId?: UUIDFilter | null;
  cardNumber?: StringFilter | null;
  cardExpiry?: StringFilter | null;
  cardStatus?: StringFilter | null;
  cardFirstName?: StringFilter | null;
  cardLastName?: StringFilter | null;
  cardFullName?: StringFilter | null;
  cardDepartment?: StringFilter | null;
  departmentId?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  tenantUserId?: UUIDFilter | null;
  documentPoolId?: UUIDFilter | null;
  documentTypeId?: IntFilter | null;
  isMyDocumentsUsed?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  invoicesByCardHolderId?: CompanyCardHolderToManyInvoiceFilter | null;
  invoicesByCardHolderIdExist?: boolean | null;
  entityDocumentsByCardHolderId?: CompanyCardHolderToManyEntityDocumentFilter | null;
  entityDocumentsByCardHolderIdExist?: boolean | null;
  and?: CompanyCardHolderFilter[] | null;
  or?: CompanyCardHolderFilter[] | null;
  not?: CompanyCardHolderFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCardHolderToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCardHolderToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against `CompanyChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyChartOfAccountFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  chartOfAccountTypeId?: IntFilter | null;
  isSubsetChartOfAccounts?: BooleanFilter | null;
  primaryChartOfAccountId?: UUIDFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  companyCode?: StringFilter | null;
  businessArea?: StringFilter | null;
  costCenter?: StringFilter | null;
  glAccount?: StringFilter | null;
  isNewProjectSelectable?: BooleanFilter | null;
  productionStatusTypeId?: IntFilter | null;
  corporateChartOfAccountId?: UUIDFilter | null;
  _isCorporate?: BooleanFilter | null;
  companyBusinessUnitsByChartOfAccountId?: CompanyChartOfAccountToManyCompanyBusinessUnitFilter | null;
  companyBusinessUnitsByChartOfAccountIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  chartOfAccountType?: ChartOfAccountTypeFilter | null;
  chartOfAccountTypeExists?: boolean | null;
  productionStatus?: ProductionStatusFilter | null;
  productionStatusExists?: boolean | null;
  and?: CompanyChartOfAccountFilter[] | null;
  or?: CompanyChartOfAccountFilter[] | null;
  not?: CompanyChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CompanyBusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyChartOfAccountToManyCompanyBusinessUnitFilter {
  every?: CompanyBusinessUnitFilter | null;
  some?: CompanyBusinessUnitFilter | null;
  none?: CompanyBusinessUnitFilter | null;
}

/**
 * A filter to be used against `CompanyCommunicationGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCommunicationGroupFilter {
  id?: UUIDFilter | null;
  communicationGroupId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  communicationGroup?: CommunicationGroupFilter | null;
  communicationGroupExists?: boolean | null;
  and?: CompanyCommunicationGroupFilter[] | null;
  or?: CompanyCommunicationGroupFilter[] | null;
  not?: CompanyCommunicationGroupFilter | null;
}

/**
 * A filter to be used against `CompanyCorporatePeriod` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodFilter {
  id?: UUIDFilter | null;
  fiscalYearId?: UUIDFilter | null;
  fiscalPeriod?: IntFilter | null;
  startDate?: DateFilter | null;
  endDate?: DateFilter | null;
  transactionPostingCutoffDate?: DateFilter | null;
  isPeriodOpen?: BooleanFilter | null;
  isEntryAllowed?: BooleanFilter | null;
  isPeriodPreClose?: BooleanFilter | null;
  isPeriodAdjustmentAllowed?: BooleanFilter | null;
  closedByUserId?: UUIDFilter | null;
  closedDate?: DatetimeFilter | null;
  reopenedByUserId?: UUIDFilter | null;
  reopenedDate?: DatetimeFilter | null;
  reopenedReason?: StringFilter | null;
  periodStatusTypeId?: IntFilter | null;
  _periodYear?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isEntrySelectable?: BooleanFilter | null;
  batchTransactionsByCorporatePeriodId?: CompanyCorporatePeriodToManyBatchTransactionFilter | null;
  batchTransactionsByCorporatePeriodIdExist?: boolean | null;
  invoicesByCorporatePeriodId?: CompanyCorporatePeriodToManyInvoiceFilter | null;
  invoicesByCorporatePeriodIdExist?: boolean | null;
  payCyclesByCorporatePeriodId?: CompanyCorporatePeriodToManyPayCycleFilter | null;
  payCyclesByCorporatePeriodIdExist?: boolean | null;
  paymentsByCorporatePeriodId?: CompanyCorporatePeriodToManyPaymentFilter | null;
  paymentsByCorporatePeriodIdExist?: boolean | null;
  approvalHistoriesByCorporatePeriodId?: CompanyCorporatePeriodToManyApprovalHistoryFilter | null;
  approvalHistoriesByCorporatePeriodIdExist?: boolean | null;
  userApprovalsByCorporatePeriodId?: CompanyCorporatePeriodToManyUserApprovalFilter | null;
  userApprovalsByCorporatePeriodIdExist?: boolean | null;
  departmentBudgetsByStartCorporatePeriodId?: CompanyCorporatePeriodToManyDepartmentBudgetFilter | null;
  departmentBudgetsByStartCorporatePeriodIdExist?: boolean | null;
  departmentBudgetsByEndCorporatePeriodId?: CompanyCorporatePeriodToManyDepartmentBudgetFilter | null;
  departmentBudgetsByEndCorporatePeriodIdExist?: boolean | null;
  userDefaultsByCorporatePeriodId?: CompanyCorporatePeriodToManyUserDefaultFilter | null;
  userDefaultsByCorporatePeriodIdExist?: boolean | null;
  corporateYear?: CompanyCorporateYearFilter | null;
  corporateYearExists?: boolean | null;
  and?: CompanyCorporatePeriodFilter[] | null;
  or?: CompanyCorporatePeriodFilter[] | null;
  not?: CompanyCorporatePeriodFilter | null;
}

/**
 * An input for mutations affecting `CompanyCorporatePeriod`
 */
export interface CompanyCorporatePeriodInput {
  fiscalPeriod: number;
  startDate: GqlDate;
  endDate: GqlDate;
  isPeriodOpen?: boolean | null;
  isEntryAllowed?: boolean | null;
}

/**
 * Represents an update to a `CompanyCorporatePeriod`. Fields that are set will be updated.
 */
export interface CompanyCorporatePeriodPatch {
  isPeriodOpen?: boolean | null;
  isEntryAllowed?: boolean | null;
}

/**
 * All input for the `companyCorporatePeriodRangeCreate` mutation.
 */
export interface CompanyCorporatePeriodRangeCreateInput {
  clientMutationId?: string | null;
  companyCorporateYearId: GqlUUID;
  startPeriodNumber: number;
  startDate: GqlDate;
  frequencyId: number;
  intervals: number;
  isPeriodOpen: boolean;
  isEntryAllowed: boolean;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `BatchTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyBatchTransactionFilter {
  every?: BatchTransactionFilter | null;
  some?: BatchTransactionFilter | null;
  none?: BatchTransactionFilter | null;
}

/**
 * A filter to be used against many `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyDepartmentBudgetFilter {
  every?: DepartmentBudgetFilter | null;
  some?: DepartmentBudgetFilter | null;
  none?: DepartmentBudgetFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporatePeriodToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * An input for mutations affecting `CompanyCorporatePeriodUpdateType`
 */
export interface CompanyCorporatePeriodUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  companyCorporatePeriodPatch?: CompanyCorporatePeriodPatch | null;
}

/**
 * All input for the `companyCorporateYearCreate` mutation.
 */
export interface CompanyCorporateYearCreateInput {
  clientMutationId?: string | null;
  companyCorporateYear: CompanyCorporateYearInput;
  companyCorporatePeriods?: (CompanyCorporatePeriodInput | null)[] | null;
}

/**
 * All input for the `companyCorporateYearDelete` mutation.
 */
export interface CompanyCorporateYearDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `CompanyCorporateYear` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporateYearFilter {
  id?: UUIDFilter | null;
  fiscalYear?: IntFilter | null;
  startDate?: DateFilter | null;
  endDate?: DateFilter | null;
  isYearOpen?: BooleanFilter | null;
  isEntryAllowed?: BooleanFilter | null;
  isYearPreClose?: BooleanFilter | null;
  isYearEndAdjustmentsAllowed?: BooleanFilter | null;
  closedByUserId?: UUIDFilter | null;
  closedDate?: DatetimeFilter | null;
  reopenedByUserId?: UUIDFilter | null;
  reopenedDate?: DatetimeFilter | null;
  reopenedReason?: StringFilter | null;
  yearStatusTypeId?: IntFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  companyCorporatePeriodsByFiscalYearId?: CompanyCorporateYearToManyCompanyCorporatePeriodFilter | null;
  companyCorporatePeriodsByFiscalYearIdExist?: boolean | null;
  and?: CompanyCorporateYearFilter[] | null;
  or?: CompanyCorporateYearFilter[] | null;
  not?: CompanyCorporateYearFilter | null;
}

/**
 * An input for mutations affecting `CompanyCorporateYear`
 */
export interface CompanyCorporateYearInput {
  fiscalYear: number;
  startDate: GqlDate;
  endDate: GqlDate;
  isYearOpen?: boolean | null;
  isEntryAllowed?: boolean | null;
}

/**
 * Represents an update to a `CompanyCorporateYear`. Fields that are set will be updated.
 */
export interface CompanyCorporateYearPatch {
  isYearOpen?: boolean | null;
  isEntryAllowed?: boolean | null;
}

/**
 * A filter to be used against many `CompanyCorporatePeriod` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCorporateYearToManyCompanyCorporatePeriodFilter {
  every?: CompanyCorporatePeriodFilter | null;
  some?: CompanyCorporatePeriodFilter | null;
  none?: CompanyCorporatePeriodFilter | null;
}

/**
 * All input for the `companyCorporateYearUpdate` mutation.
 */
export interface CompanyCorporateYearUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  companyCorporateYearPatch?: CompanyCorporateYearPatch | null;
  companyCorporatePeriodsCreate?: (CompanyCorporatePeriodInput | null)[] | null;
  companyCorporatePeriodsUpdate?: (CompanyCorporatePeriodUpdateTypeInput | null)[] | null;
  companyCorporatePeriodsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * A filter to be used against `CompanyCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyFilter {
  id?: IntFilter | null;
  name?: StringFilter | null;
  isoCode?: StringFilter | null;
  companyExchangeRate?: BigFloatFilter | null;
  companySpotRate?: BigFloatFilter | null;
  currencySymbol?: StringFilter | null;
  batchTransactionsByCurrencyId?: CompanyCurrencyToManyBatchTransactionFilter | null;
  batchTransactionsByCurrencyIdExist?: boolean | null;
  invoicesByCurrencyId?: CompanyCurrencyToManyInvoiceFilter | null;
  invoicesByCurrencyIdExist?: boolean | null;
  payCyclesByCurrencyId?: CompanyCurrencyToManyPayCycleFilter | null;
  payCyclesByCurrencyIdExist?: boolean | null;
  paymentsByCurrencyId?: CompanyCurrencyToManyPaymentFilter | null;
  paymentsByCurrencyIdExist?: boolean | null;
  approvalSelectionConditionsByRequesterCurrencyId?: CompanyCurrencyToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterCurrencyIdExist?: boolean | null;
  approvalHistoriesByCurrencyId?: CompanyCurrencyToManyApprovalHistoryFilter | null;
  approvalHistoriesByCurrencyIdExist?: boolean | null;
  userApprovalsByCurrencyId?: CompanyCurrencyToManyUserApprovalFilter | null;
  userApprovalsByCurrencyIdExist?: boolean | null;
  companyBusinessUnitCurrenciesByCurrencyId?: CompanyCurrencyToManyCompanyBusinessUnitCurrencyFilter | null;
  companyBusinessUnitCurrenciesByCurrencyIdExist?: boolean | null;
  userDefaultsByCurrencyId?: CompanyCurrencyToManyUserDefaultFilter | null;
  userDefaultsByCurrencyIdExist?: boolean | null;
  entityDocumentsByIndexCurrencyId?: CompanyCurrencyToManyEntityDocumentFilter | null;
  entityDocumentsByIndexCurrencyIdExist?: boolean | null;
  paymentAccountsByCurrencyId?: CompanyCurrencyToManyPaymentAccountFilter | null;
  paymentAccountsByCurrencyIdExist?: boolean | null;
  purchaseOrdersByCurrencyId?: CompanyCurrencyToManyPurchaseOrderFilter | null;
  purchaseOrdersByCurrencyIdExist?: boolean | null;
  travelAuthorizationsByCurrencyId?: CompanyCurrencyToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByCurrencyIdExist?: boolean | null;
  travelPoliciesByCurrencyId?: CompanyCurrencyToManyTravelPolicyFilter | null;
  travelPoliciesByCurrencyIdExist?: boolean | null;
  tripBreakdownsByCurrencyId?: CompanyCurrencyToManyTripBreakdownFilter | null;
  tripBreakdownsByCurrencyIdExist?: boolean | null;
  tripDistributionsByCurrencyId?: CompanyCurrencyToManyTripDistributionFilter | null;
  tripDistributionsByCurrencyIdExist?: boolean | null;
  tripItemsByCurrencyId?: CompanyCurrencyToManyTripItemFilter | null;
  tripItemsByCurrencyIdExist?: boolean | null;
  tripItemsByPickupCurrencyId?: CompanyCurrencyToManyTripItemFilter | null;
  tripItemsByPickupCurrencyIdExist?: boolean | null;
  tripItemsByPickupDropCurrencyId?: CompanyCurrencyToManyTripItemFilter | null;
  tripItemsByPickupDropCurrencyIdExist?: boolean | null;
  tripItemsByReturnCurrencyId?: CompanyCurrencyToManyTripItemFilter | null;
  tripItemsByReturnCurrencyIdExist?: boolean | null;
  tripItemsByReturnDropCurrencyId?: CompanyCurrencyToManyTripItemFilter | null;
  tripItemsByReturnDropCurrencyIdExist?: boolean | null;
  and?: CompanyCurrencyFilter[] | null;
  or?: CompanyCurrencyFilter[] | null;
  not?: CompanyCurrencyFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `BatchTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyBatchTransactionFilter {
  every?: BatchTransactionFilter | null;
  some?: BatchTransactionFilter | null;
  none?: BatchTransactionFilter | null;
}

/**
 * A filter to be used against many `CompanyBusinessUnitCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyCompanyBusinessUnitCurrencyFilter {
  every?: CompanyBusinessUnitCurrencyFilter | null;
  some?: CompanyBusinessUnitCurrencyFilter | null;
  none?: CompanyBusinessUnitCurrencyFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `PaymentAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyPaymentAccountFilter {
  every?: PaymentAccountFilter | null;
  some?: PaymentAccountFilter | null;
  none?: PaymentAccountFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `TravelPolicy` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyTravelPolicyFilter {
  every?: TravelPolicyFilter | null;
  some?: TravelPolicyFilter | null;
  none?: TravelPolicyFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyCurrencyToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * A filter to be used against `CompanyDataAccessPolicy` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDataAccessPolicyFilter {
  id?: UUIDFilter | null;
  dataAccessPolicyId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  dataAccessPolicy?: DataAccessPolicyFilter | null;
  dataAccessPolicyExists?: boolean | null;
  and?: CompanyDataAccessPolicyFilter[] | null;
  or?: CompanyDataAccessPolicyFilter[] | null;
  not?: CompanyDataAccessPolicyFilter | null;
}

/**
 * A filter to be used against `CompanyDepartment` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  _isSystemInternal?: BooleanFilter | null;
  _isTransSelectable?: BooleanFilter | null;
  _isTaSelectable?: BooleanFilter | null;
  _isPoSelectable?: BooleanFilter | null;
  _isDocSelectable?: BooleanFilter | null;
  invoiceDistributionsByDepartmentId?: CompanyDepartmentToManyInvoiceDistributionFilter | null;
  invoiceDistributionsByDepartmentIdExist?: boolean | null;
  invoicesByDepartmentId?: CompanyDepartmentToManyInvoiceFilter | null;
  invoicesByDepartmentIdExist?: boolean | null;
  payCyclesByDepartmentId?: CompanyDepartmentToManyPayCycleFilter | null;
  payCyclesByDepartmentIdExist?: boolean | null;
  paymentsByDepartmentId?: CompanyDepartmentToManyPaymentFilter | null;
  paymentsByDepartmentIdExist?: boolean | null;
  vendorsByTravelerDepartmentId?: CompanyDepartmentToManyVendorFilter | null;
  vendorsByTravelerDepartmentIdExist?: boolean | null;
  approvalBranchesByTransactionDepartmentId?: CompanyDepartmentToManyApprovalBranchFilter | null;
  approvalBranchesByTransactionDepartmentIdExist?: boolean | null;
  approvalSelectionConditionsByRequesterDepartmentId?: CompanyDepartmentToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterDepartmentIdExist?: boolean | null;
  availableCardHolderUsersByDepartmentId?: CompanyDepartmentToManyAvailableCardHolderUserFilter | null;
  availableCardHolderUsersByDepartmentIdExist?: boolean | null;
  deliveryAddressDepartmentsByDepartmentId?: CompanyDepartmentToManyDeliveryAddressDepartmentFilter | null;
  deliveryAddressDepartmentsByDepartmentIdExist?: boolean | null;
  departmentOccupationTemplatesByDepartmentId?: CompanyDepartmentToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesByDepartmentIdExist?: boolean | null;
  userDefaultsByDepartmentId?: CompanyDepartmentToManyUserDefaultFilter | null;
  userDefaultsByDepartmentIdExist?: boolean | null;
  purchaseOrderInvoiceScheduleDistributionsByDepartmentId?: CompanyDepartmentToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByDepartmentIdExist?: boolean | null;
  purchaseOrderItemDistributionsByDepartmentId?: CompanyDepartmentToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsByDepartmentIdExist?: boolean | null;
  purchaseOrderItemsByAdditionalDepartmentId?: CompanyDepartmentToManyPurchaseOrderItemFilter | null;
  purchaseOrderItemsByAdditionalDepartmentIdExist?: boolean | null;
  purchaseOrdersByDepartmentId?: CompanyDepartmentToManyPurchaseOrderFilter | null;
  purchaseOrdersByDepartmentIdExist?: boolean | null;
  travelAuthorizationsByDepartmentId?: CompanyDepartmentToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByDepartmentIdExist?: boolean | null;
  travelersByTravelerDepartmentId?: CompanyDepartmentToManyTravelerFilter | null;
  travelersByTravelerDepartmentIdExist?: boolean | null;
  tripBreakdownsByDepartmentId?: CompanyDepartmentToManyTripBreakdownFilter | null;
  tripBreakdownsByDepartmentIdExist?: boolean | null;
  tripDistributionsByDepartmentId?: CompanyDepartmentToManyTripDistributionFilter | null;
  tripDistributionsByDepartmentIdExist?: boolean | null;
  userDepartment?: UserDepartmentFilter | null;
  userDepartmentExists?: boolean | null;
  and?: CompanyDepartmentFilter[] | null;
  or?: CompanyDepartmentFilter[] | null;
  not?: CompanyDepartmentFilter | null;
}

/**
 * A filter to be used against `CompanyDepartmentGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentGroupFilter {
  id?: UUIDFilter | null;
  departmentGroupId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  and?: CompanyDepartmentGroupFilter[] | null;
  or?: CompanyDepartmentGroupFilter[] | null;
  not?: CompanyDepartmentGroupFilter | null;
}

/**
 * A filter to be used against `CompanyDepartmentOccupationTemplate` object types.
 * All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentOccupationTemplateFilter {
  id?: UUIDFilter | null;
  departmentOccupationTemplateId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  departmentOccupationTemplate?: DepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplateExists?: boolean | null;
  and?: CompanyDepartmentOccupationTemplateFilter[] | null;
  or?: CompanyDepartmentOccupationTemplateFilter[] | null;
  not?: CompanyDepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `AvailableCardHolderUser` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyAvailableCardHolderUserFilter {
  every?: AvailableCardHolderUserFilter | null;
  some?: AvailableCardHolderUserFilter | null;
  none?: AvailableCardHolderUserFilter | null;
}

/**
 * A filter to be used against many `DeliveryAddressDepartment` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyDeliveryAddressDepartmentFilter {
  every?: DeliveryAddressDepartmentFilter | null;
  some?: DeliveryAddressDepartmentFilter | null;
  none?: DeliveryAddressDepartmentFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyInvoiceDistributionFilter {
  every?: InvoiceDistributionFilter | null;
  some?: InvoiceDistributionFilter | null;
  none?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItem` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyPurchaseOrderItemFilter {
  every?: PurchaseOrderItemFilter | null;
  some?: PurchaseOrderItemFilter | null;
  none?: PurchaseOrderItemFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `Traveler` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyTravelerFilter {
  every?: TravelerFilter | null;
  some?: TravelerFilter | null;
  none?: TravelerFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyDepartmentToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * A filter to be used against `Company` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  companyTypeId?: IntFilter | null;
  chartOfAccountsId?: UUIDFilter | null;
  applicationSuiteId?: UUIDFilter | null;
  languageId?: IntFilter | null;
  currencyId?: IntFilter | null;
  defaultRollupBusinessUnitId?: UUIDFilter | null;
  tenantId?: UUIDFilter | null;
  emailSenderItemId?: UUIDFilter | null;
  isPreApproved?: BooleanFilter | null;
  homeCountryId?: IntFilter | null;
  overridingAddressFormatTypeId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  approvalPhaseTypeId?: IntFilter | null;
  isSpotRatePreferred?: BooleanFilter | null;
  distributionLayoutType?: TransactionLayoutFilter | null;
  timeZoneId?: IntFilter | null;
  corporationName?: StringFilter | null;
  corporateWorkgroupId?: UUIDFilter | null;
  invoicesByCompanyId?: CompanyToManyInvoiceFilter | null;
  invoicesByCompanyIdExist?: boolean | null;
  paymentsByCompanyId?: CompanyToManyPaymentFilter | null;
  paymentsByCompanyIdExist?: boolean | null;
  approvalSelectionConditionsByRequesterCompanyId?: CompanyToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterCompanyIdExist?: boolean | null;
  companyApprovalPhaseType?: ApprovalPhaseFilter | null;
  companyApprovalPhaseTypeExists?: boolean | null;
  companyAddressesByEntityId?: CompanyToManyCompanyAddressFilter | null;
  companyAddressesByEntityIdExist?: boolean | null;
  companyValidCurrencies?: CompanyToManyCompanyValidCurrencyFilter | null;
  companyValidCurrenciesExist?: boolean | null;
  userProfilesByCompanyId?: CompanyToManyUserProfileFilter | null;
  userProfilesByCompanyIdExist?: boolean | null;
  companyHistoriesByEntityId?: CompanyToManyCompanyHistoryFilter | null;
  companyHistoriesByEntityIdExist?: boolean | null;
  purchaseOrdersByCompanyId?: CompanyToManyPurchaseOrderFilter | null;
  purchaseOrdersByCompanyIdExist?: boolean | null;
  travelAuthorizationsByCompanyId?: CompanyToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByCompanyIdExist?: boolean | null;
  tripBreakdownsByCompanyId?: CompanyToManyTripBreakdownFilter | null;
  tripBreakdownsByCompanyIdExist?: boolean | null;
  approvalPhaseType?: ApprovalPhaseFilter | null;
  approvalPhaseTypeExists?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  currency?: CurrencyFilter | null;
  currencyExists?: boolean | null;
  timeZone?: TimeZoneFilter | null;
  timeZoneExists?: boolean | null;
  workgroup?: CorporateWorkgroupFilter | null;
  workgroupExists?: boolean | null;
  and?: CompanyFilter[] | null;
  or?: CompanyFilter[] | null;
  not?: CompanyFilter | null;
}

/**
 * A filter to be used against `CompanyHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  and?: CompanyHistoryFilter[] | null;
  or?: CompanyHistoryFilter[] | null;
  not?: CompanyHistoryFilter | null;
}

/**
 * Represents an update to a `Company`. Fields that are set will be updated.
 */
export interface CompanyPatch {
  name?: string | null;
  currencyId?: number | null;
  homeCountryId?: number | null;
  approvalPhaseTypeId?: number | null;
  distributionLayoutType?: TransactionLayout | null;
  timeZoneId?: number | null;
  corporationName?: string | null;
  corporateWorkgroupId?: GqlUUID | null;
}

/**
 * A filter to be used against `CompanyPurchaseOrderItemType` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyPurchaseOrderItemTypeFilter {
  id?: UUIDFilter | null;
  companyPurchaseOrderTypeId?: UUIDFilter | null;
  purchaseOrderItemTypeId?: UUIDFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  itemListSortOrder?: IntFilter | null;
  unitOfMeasureGroupId?: UUIDFilter | null;
  calculationType?: StandardCalculationTypeFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  isTax?: BooleanFilter | null;
  isDiscount?: BooleanFilter | null;
  isDelivery?: BooleanFilter | null;
  isExpenditureTypeAllowed?: BooleanFilter | null;
  isExpenditureTypeRequired?: BooleanFilter | null;
  isUnitOfMeasureRequired?: BooleanFilter | null;
  isTaxAllowed?: BooleanFilter | null;
  isDiscountAllowed?: BooleanFilter | null;
  isQuantityAllowed?: BooleanFilter | null;
  isAutoCalculated?: BooleanFilter | null;
  isOrderItemSelectable?: BooleanFilter | null;
  companyPurchaseOrderType?: CompanyPurchaseOrderTypeFilter | null;
  companyPurchaseOrderTypeExists?: boolean | null;
  unitOfMeasureGroup?: UnitOfMeasureGroupFilter | null;
  unitOfMeasureGroupExists?: boolean | null;
  and?: CompanyPurchaseOrderItemTypeFilter[] | null;
  or?: CompanyPurchaseOrderItemTypeFilter[] | null;
  not?: CompanyPurchaseOrderItemTypeFilter | null;
}

/**
 * A filter to be used against `CompanyPurchaseOrderType` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyPurchaseOrderTypeFilter {
  id?: UUIDFilter | null;
  purchaseOrderTypeId?: IntFilter | null;
  purchaseOrderType?: StringFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  statementLayoutType?: TransactionLayoutFilter | null;
  isDefault?: BooleanFilter | null;
  sortOrder?: IntFilter | null;
  isCreateEntryAllowed?: BooleanFilter | null;
  isPunchoutProcessing?: BooleanFilter | null;
  isUserTemplateAllowed?: BooleanFilter | null;
  isProtectedFromUpdate?: BooleanFilter | null;
  distributionLayoutType?: TransactionLayoutFilter | null;
  companyPurchaseOrderItemTypes?: CompanyPurchaseOrderTypeToManyCompanyPurchaseOrderItemTypeFilter | null;
  companyPurchaseOrderItemTypesExist?: boolean | null;
  and?: CompanyPurchaseOrderTypeFilter[] | null;
  or?: CompanyPurchaseOrderTypeFilter[] | null;
  not?: CompanyPurchaseOrderTypeFilter | null;
}

/**
 * A filter to be used against many `CompanyPurchaseOrderItemType` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyPurchaseOrderTypeToManyCompanyPurchaseOrderItemTypeFilter {
  every?: CompanyPurchaseOrderItemTypeFilter | null;
  some?: CompanyPurchaseOrderItemTypeFilter | null;
  none?: CompanyPurchaseOrderItemTypeFilter | null;
}

/**
 * A filter to be used against `CompanyTagGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyTagGroupFilter {
  id?: UUIDFilter | null;
  tagGroupId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  tagGroup?: TagGroupFilter | null;
  tagGroupExists?: boolean | null;
  and?: CompanyTagGroupFilter[] | null;
  or?: CompanyTagGroupFilter[] | null;
  not?: CompanyTagGroupFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `CompanyAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyCompanyAddressFilter {
  every?: CompanyAddressFilter | null;
  some?: CompanyAddressFilter | null;
  none?: CompanyAddressFilter | null;
}

/**
 * A filter to be used against many `CompanyHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyCompanyHistoryFilter {
  every?: CompanyHistoryFilter | null;
  some?: CompanyHistoryFilter | null;
  none?: CompanyHistoryFilter | null;
}

/**
 * A filter to be used against many `CompanyValidCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyCompanyValidCurrencyFilter {
  every?: CompanyValidCurrencyFilter | null;
  some?: CompanyValidCurrencyFilter | null;
  none?: CompanyValidCurrencyFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `CompanyTransactionType` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyTransactionTypeFilter {
  id?: UUIDFilter | null;
  transactionTypeId?: IntFilter | null;
  transactionType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  exportLayout?: ExtractionLayoutFilter | null;
  invoiceBatchesByTransactionTypeId?: CompanyTransactionTypeToManyInvoiceBatchFilter | null;
  invoiceBatchesByTransactionTypeIdExist?: boolean | null;
  invoicesByTransactionTypeId?: CompanyTransactionTypeToManyInvoiceFilter | null;
  invoicesByTransactionTypeIdExist?: boolean | null;
  documentTypesByTransactionTypeId?: CompanyTransactionTypeToManyDocumentTypeFilter | null;
  documentTypesByTransactionTypeIdExist?: boolean | null;
  fileCabinetDocumentTypesByTransactionTypeId?: CompanyTransactionTypeToManyFileCabinetDocumentTypeFilter | null;
  fileCabinetDocumentTypesByTransactionTypeIdExist?: boolean | null;
  and?: CompanyTransactionTypeFilter[] | null;
  or?: CompanyTransactionTypeFilter[] | null;
  not?: CompanyTransactionTypeFilter | null;
}

/**
 * A filter to be used against many `DocumentType` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyTransactionTypeToManyDocumentTypeFilter {
  every?: DocumentTypeFilter | null;
  some?: DocumentTypeFilter | null;
  none?: DocumentTypeFilter | null;
}

/**
 * A filter to be used against many `FileCabinetDocumentType` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyTransactionTypeToManyFileCabinetDocumentTypeFilter {
  every?: FileCabinetDocumentTypeFilter | null;
  some?: FileCabinetDocumentTypeFilter | null;
  none?: FileCabinetDocumentTypeFilter | null;
}

/**
 * A filter to be used against many `InvoiceBatch` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyTransactionTypeToManyInvoiceBatchFilter {
  every?: InvoiceBatchFilter | null;
  some?: InvoiceBatchFilter | null;
  none?: InvoiceBatchFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyTransactionTypeToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * All input for the `companyUpdate` mutation.
 */
export interface CompanyUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  companyPatch?: CompanyPatch | null;
  companyAddressCreate?: (CompanyAddressInput | null)[] | null;
  companyAddressUpdate?: (CompanyAddressUpdateTypeInput | null)[] | null;
  companyAddressDelete?: (EntityDeleteInput | null)[] | null;
  companyCurrencyCreate?: (CompanyValidCurrencyInput | null)[] | null;
  companyCurrencyUpdate?: (CompanyValidCurrencyUpdateTypeInput | null)[] | null;
  companyCurrencyDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * A filter to be used against `CompanyUser` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyUserFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  userDefaultsByProxyUserId?: CompanyUserToManyUserDefaultFilter | null;
  userDefaultsByProxyUserIdExist?: boolean | null;
  userDefaultsByDelegateUserId?: CompanyUserToManyUserDefaultFilter | null;
  userDefaultsByDelegateUserIdExist?: boolean | null;
  and?: CompanyUserFilter[] | null;
  or?: CompanyUserFilter[] | null;
  not?: CompanyUserFilter | null;
}

/**
 * A filter to be used against `CompanyUserGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyUserGroupFilter {
  id?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  availableCardHolderUsersByUserGroupId?: CompanyUserGroupToManyAvailableCardHolderUserFilter | null;
  availableCardHolderUsersByUserGroupIdExist?: boolean | null;
  userGroup?: UserGroupFilter | null;
  userGroupExists?: boolean | null;
  and?: CompanyUserGroupFilter[] | null;
  or?: CompanyUserGroupFilter[] | null;
  not?: CompanyUserGroupFilter | null;
}

/**
 * A filter to be used against many `AvailableCardHolderUser` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyUserGroupToManyAvailableCardHolderUserFilter {
  every?: AvailableCardHolderUserFilter | null;
  some?: AvailableCardHolderUserFilter | null;
  none?: AvailableCardHolderUserFilter | null;
}

/**
 * A filter to be used against `CompanyUserOccupationTitle` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyUserOccupationTitleFilter {
  id?: UUIDFilter | null;
  userOccupationTitleId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  userOccupationTitles?: UserOccupationTitleFilter | null;
  userOccupationTitlesExists?: boolean | null;
  and?: CompanyUserOccupationTitleFilter[] | null;
  or?: CompanyUserOccupationTitleFilter[] | null;
  not?: CompanyUserOccupationTitleFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyUserToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * A filter to be used against `CompanyValidCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyValidCurrencyFilter {
  id?: UUIDFilter | null;
  companyId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  _isModified?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  companyExchangeRate?: BigFloatFilter | null;
  companySpotRate?: BigFloatFilter | null;
  _isHomeCurrency?: BooleanFilter | null;
  companyValidCurrencyHistoriesByEntityId?: CompanyValidCurrencyToManyCompanyValidCurrencyHistoryFilter | null;
  companyValidCurrencyHistoriesByEntityIdExist?: boolean | null;
  currency?: CurrencyFilter | null;
  currencyExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  and?: CompanyValidCurrencyFilter[] | null;
  or?: CompanyValidCurrencyFilter[] | null;
  not?: CompanyValidCurrencyFilter | null;
}

/**
 * A filter to be used against `CompanyValidCurrencyHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyValidCurrencyHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  companyValidCurrency?: CompanyValidCurrencyFilter | null;
  companyValidCurrencyExists?: boolean | null;
  and?: CompanyValidCurrencyHistoryFilter[] | null;
  or?: CompanyValidCurrencyHistoryFilter[] | null;
  not?: CompanyValidCurrencyHistoryFilter | null;
}

/**
 * An input for mutations affecting `CompanyValidCurrency`
 */
export interface CompanyValidCurrencyInput {
  currencyId?: number | null;
  companyExchangeRate?: GqlBigFloat | null;
  companySpotRate?: GqlBigFloat | null;
}

/**
 * Represents an update to a `CompanyValidCurrency`. Fields that are set will be updated.
 */
export interface CompanyValidCurrencyPatch {
  currencyId?: number | null;
  companyExchangeRate?: GqlBigFloat | null;
  companySpotRate?: GqlBigFloat | null;
}

/**
 * A filter to be used against many `CompanyValidCurrencyHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface CompanyValidCurrencyToManyCompanyValidCurrencyHistoryFilter {
  every?: CompanyValidCurrencyHistoryFilter | null;
  some?: CompanyValidCurrencyHistoryFilter | null;
  none?: CompanyValidCurrencyHistoryFilter | null;
}

/**
 * An input for mutations affecting `CompanyValidCurrencyUpdateType`
 */
export interface CompanyValidCurrencyUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  companyValidCurrencyPatch?: CompanyValidCurrencyPatch | null;
}

/**
 * All input for the `corporateChartOfAccountCreate` mutation.
 */
export interface CorporateChartOfAccountCreateInput {
  clientMutationId?: string | null;
  chartOfAccount: CorporateChartOfAccountInput;
}

/**
 * All input for the `corporateChartOfAccountDelete` mutation.
 */
export interface CorporateChartOfAccountDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `CorporateChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface CorporateChartOfAccountFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  chartOfAccountTypeId?: IntFilter | null;
  isSubsetChartOfAccounts?: BooleanFilter | null;
  primaryChartOfAccountId?: UUIDFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  companyCode?: StringFilter | null;
  businessArea?: StringFilter | null;
  costCenter?: StringFilter | null;
  glAccount?: StringFilter | null;
  isNewProjectSelectable?: BooleanFilter | null;
  productionStatusTypeId?: IntFilter | null;
  corporateChartOfAccountId?: UUIDFilter | null;
  _isCorporate?: BooleanFilter | null;
  lookupCorporateAccountsByChartOfAccountId?: CorporateChartOfAccountToManyLookupCorporateAccountFilter | null;
  lookupCorporateAccountsByChartOfAccountIdExist?: boolean | null;
  corporateWorkgroupsByChartOfAccountsId?: CorporateChartOfAccountToManyCorporateWorkgroupFilter | null;
  corporateWorkgroupsByChartOfAccountsIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  chartOfAccountType?: ChartOfAccountTypeFilter | null;
  chartOfAccountTypeExists?: boolean | null;
  productionStatus?: ProductionStatusFilter | null;
  productionStatusExists?: boolean | null;
  and?: CorporateChartOfAccountFilter[] | null;
  or?: CorporateChartOfAccountFilter[] | null;
  not?: CorporateChartOfAccountFilter | null;
}

/**
 * An input for mutations affecting `CorporateChartOfAccount`
 */
export interface CorporateChartOfAccountInput {
  name: string;
  description?: string | null;
  abbreviation?: string | null;
  isDraft?: boolean | null;
  companyCode?: string | null;
  businessArea?: string | null;
  costCenter?: string | null;
  glAccount?: string | null;
  isNewProjectSelectable?: boolean | null;
  productionStatusTypeId?: number | null;
}

/**
 * Represents an update to a `CorporateChartOfAccount`. Fields that are set will be updated.
 */
export interface CorporateChartOfAccountPatch {
  name?: string | null;
  description?: string | null;
  abbreviation?: string | null;
  isDraft?: boolean | null;
  companyCode?: string | null;
  businessArea?: string | null;
  costCenter?: string | null;
  glAccount?: string | null;
  isNewProjectSelectable?: boolean | null;
  productionStatusTypeId?: number | null;
}

/**
 * A filter to be used against many `CorporateWorkgroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CorporateChartOfAccountToManyCorporateWorkgroupFilter {
  every?: CorporateWorkgroupFilter | null;
  some?: CorporateWorkgroupFilter | null;
  none?: CorporateWorkgroupFilter | null;
}

/**
 * A filter to be used against many `LookupCorporateAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface CorporateChartOfAccountToManyLookupCorporateAccountFilter {
  every?: LookupCorporateAccountFilter | null;
  some?: LookupCorporateAccountFilter | null;
  none?: LookupCorporateAccountFilter | null;
}

/**
 * All input for the `corporateChartOfAccountUpdate` mutation.
 */
export interface CorporateChartOfAccountUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  corporateChartOfAccountPatch?: CorporateChartOfAccountPatch | null;
}

/**
 * All input for the `corporateWorkgroupCreate` mutation.
 */
export interface CorporateWorkgroupCreateInput {
  clientMutationId?: string | null;
  corporateWorkgroup: CorporateWorkgroupInput;
}

/**
 * All input for the `corporateWorkgroupDelete` mutation.
 */
export interface CorporateWorkgroupDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `CorporateWorkgroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CorporateWorkgroupFilter {
  id?: UUIDFilter | null;
  workgroupNumber?: StringFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortedCode?: StringFilter | null;
  prefixCode?: StringFilter | null;
  controllingWorkgroupId?: UUIDFilter | null;
  productionPhaseId?: IntFilter | null;
  chartOfAccountsId?: UUIDFilter | null;
  isNewWorkgroupSelectable?: BooleanFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isUpdatableAfterApproved?: BooleanFilter | null;
  productionStatusTypeId?: IntFilter | null;
  companies?: CorporateWorkgroupToManyCompanyFilter | null;
  companiesExist?: boolean | null;
  travelAuthorizations?: CorporateWorkgroupToManyTravelAuthorizationFilter | null;
  travelAuthorizationsExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  corporateChartOfAccount?: CorporateChartOfAccountFilter | null;
  corporateChartOfAccountExists?: boolean | null;
  productionPhase?: ProductionPhaseFilter | null;
  productionPhaseExists?: boolean | null;
  businessUnit?: BusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  productionStatus?: ProductionStatusFilter | null;
  productionStatusExists?: boolean | null;
  and?: CorporateWorkgroupFilter[] | null;
  or?: CorporateWorkgroupFilter[] | null;
  not?: CorporateWorkgroupFilter | null;
}

/**
 * An input for mutations affecting `CorporateWorkgroup`
 */
export interface CorporateWorkgroupInput {
  workgroupNumber: string;
  name: string;
  description?: string | null;
  prefixCode: string;
  productionPhaseId?: number | null;
  chartOfAccountsId: GqlUUID;
  isNewWorkgroupSelectable?: boolean | null;
  businessUnitId: GqlUUID;
  productionStatusTypeId: number;
}

/**
 * Represents an update to a `CorporateWorkgroup`. Fields that are set will be updated.
 */
export interface CorporateWorkgroupPatch {
  workgroupNumber?: string | null;
  name?: string | null;
  description?: string | null;
  prefixCode?: string | null;
  productionPhaseId?: number | null;
  chartOfAccountsId?: GqlUUID | null;
  isNewWorkgroupSelectable?: boolean | null;
  businessUnitId?: GqlUUID | null;
  productionStatusTypeId?: number | null;
}

/**
 * A filter to be used against many `Company` object types. All fields are combined with a logical ‘and.’
 */
export interface CorporateWorkgroupToManyCompanyFilter {
  every?: CompanyFilter | null;
  some?: CompanyFilter | null;
  none?: CompanyFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface CorporateWorkgroupToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * All input for the `corporateWorkgroupUpdate` mutation.
 */
export interface CorporateWorkgroupUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  corporateWorkgroupPatch?: CorporateWorkgroupPatch | null;
}

/**
 * A filter to be used against `Country` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryFilter {
  id?: IntFilter | null;
  country?: StringFilter | null;
  abbreviation?: StringFilter | null;
  shortAbbreviation?: StringFilter | null;
  isoCode?: StringFilter | null;
  dateFormatTypeId?: IntFilter | null;
  addressFormatTypeId?: IntFilter | null;
  addressApiFormatTypeId?: IntFilter | null;
  controllingCountryId?: IntFilter | null;
  isControllingNamePreceding?: BooleanFilter | null;
  isPostalCodeOptional?: BooleanFilter | null;
  isStateRegionOptional?: BooleanFilter | null;
  isCityAreaOptional?: BooleanFilter | null;
  countryHint?: StringFilter | null;
  cityAreaHint?: StringFilter | null;
  stateRegionHint?: StringFilter | null;
  postalCodeHint?: StringFilter | null;
  telephoneCode?: StringFilter | null;
  telephoneCodeHint?: StringFilter | null;
  isAddressStateAbbreviationPreferred?: BooleanFilter | null;
  isAddressCountryOmittedOnHomeCountry?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  vendorAddresses?: CountryToManyVendorAddressFilter | null;
  vendorAddressesExist?: boolean | null;
  cityAreas?: CountryToManyCityAreaFilter | null;
  cityAreasExist?: boolean | null;
  companiesByHomeCountryId?: CountryToManyCompanyFilter | null;
  companiesByHomeCountryIdExist?: boolean | null;
  companyAddresses?: CountryToManyCompanyAddressFilter | null;
  companyAddressesExist?: boolean | null;
  deliveryAddresses?: CountryToManyDeliveryAddressFilter | null;
  deliveryAddressesExist?: boolean | null;
  lookupCities?: CountryToManyLookupCityFilter | null;
  lookupCitiesExist?: boolean | null;
  productionIncentiveGroups?: CountryToManyProductionIncentiveGroupFilter | null;
  productionIncentiveGroupsExist?: boolean | null;
  stateRegions?: CountryToManyStateRegionFilter | null;
  stateRegionsExist?: boolean | null;
  taxCodes?: CountryToManyTaxCodeFilter | null;
  taxCodesExist?: boolean | null;
  supplierAddresses?: CountryToManySupplierAddressFilter | null;
  supplierAddressesExist?: boolean | null;
  employeeAddresses?: CountryToManyEmployeeAddressFilter | null;
  employeeAddressesExist?: boolean | null;
  employeesByPassportCountryId?: CountryToManyEmployeeFilter | null;
  employeesByPassportCountryIdExist?: boolean | null;
  employeesByTaxCountryId?: CountryToManyEmployeeFilter | null;
  employeesByTaxCountryIdExist?: boolean | null;
  depotCities?: CountryToManyDepotCityFilter | null;
  depotCitiesExist?: boolean | null;
  travelPolicies?: CountryToManyTravelPolicyFilter | null;
  travelPoliciesExist?: boolean | null;
  travelPolicyAllowancesByCountry1Id?: CountryToManyTravelPolicyAllowanceFilter | null;
  travelPolicyAllowancesByCountry1IdExist?: boolean | null;
  travelPolicyAllowancesByCountry2Id?: CountryToManyTravelPolicyAllowanceFilter | null;
  travelPolicyAllowancesByCountry2IdExist?: boolean | null;
  travelerAddresses?: CountryToManyTravelerAddressFilter | null;
  travelerAddressesExist?: boolean | null;
  tripItemsByDepartureCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByDepartureCountryIdExist?: boolean | null;
  tripItemsByDepartureArrivalCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByDepartureArrivalCountryIdExist?: boolean | null;
  tripItemsByReturnCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByReturnCountryIdExist?: boolean | null;
  tripItemsByReturnArrivalCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByReturnArrivalCountryIdExist?: boolean | null;
  tripItemsByRentalCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByRentalCountryIdExist?: boolean | null;
  tripItemsByPickupCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByPickupCountryIdExist?: boolean | null;
  tripItemsByPickupDropCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByPickupDropCountryIdExist?: boolean | null;
  tripItemsByReturnPickupCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByReturnPickupCountryIdExist?: boolean | null;
  tripItemsByReturnDropCountryId?: CountryToManyTripItemFilter | null;
  tripItemsByReturnDropCountryIdExist?: boolean | null;
  countryAddressFormatType?: FormatTypeFilter | null;
  countryAddressFormatTypeExists?: boolean | null;
  countryDateFormatType?: FormatTypeFilter | null;
  countryDateFormatTypeExists?: boolean | null;
  and?: CountryFilter[] | null;
  or?: CountryFilter[] | null;
  not?: CountryFilter | null;
}

/**
 * A filter to be used against many `CityArea` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyCityAreaFilter {
  every?: CityAreaFilter | null;
  some?: CityAreaFilter | null;
  none?: CityAreaFilter | null;
}

/**
 * A filter to be used against many `CompanyAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyCompanyAddressFilter {
  every?: CompanyAddressFilter | null;
  some?: CompanyAddressFilter | null;
  none?: CompanyAddressFilter | null;
}

/**
 * A filter to be used against many `Company` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyCompanyFilter {
  every?: CompanyFilter | null;
  some?: CompanyFilter | null;
  none?: CompanyFilter | null;
}

/**
 * A filter to be used against many `DeliveryAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyDeliveryAddressFilter {
  every?: DeliveryAddressFilter | null;
  some?: DeliveryAddressFilter | null;
  none?: DeliveryAddressFilter | null;
}

/**
 * A filter to be used against many `DepotCity` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyDepotCityFilter {
  every?: DepotCityFilter | null;
  some?: DepotCityFilter | null;
  none?: DepotCityFilter | null;
}

/**
 * A filter to be used against many `EmployeeAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyEmployeeAddressFilter {
  every?: EmployeeAddressFilter | null;
  some?: EmployeeAddressFilter | null;
  none?: EmployeeAddressFilter | null;
}

/**
 * A filter to be used against many `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyEmployeeFilter {
  every?: EmployeeFilter | null;
  some?: EmployeeFilter | null;
  none?: EmployeeFilter | null;
}

/**
 * A filter to be used against many `LookupCity` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyLookupCityFilter {
  every?: LookupCityFilter | null;
  some?: LookupCityFilter | null;
  none?: LookupCityFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyProductionIncentiveGroupFilter {
  every?: ProductionIncentiveGroupFilter | null;
  some?: ProductionIncentiveGroupFilter | null;
  none?: ProductionIncentiveGroupFilter | null;
}

/**
 * A filter to be used against many `StateRegion` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyStateRegionFilter {
  every?: StateRegionFilter | null;
  some?: StateRegionFilter | null;
  none?: StateRegionFilter | null;
}

/**
 * A filter to be used against many `SupplierAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManySupplierAddressFilter {
  every?: SupplierAddressFilter | null;
  some?: SupplierAddressFilter | null;
  none?: SupplierAddressFilter | null;
}

/**
 * A filter to be used against many `TaxCode` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyTaxCodeFilter {
  every?: TaxCodeFilter | null;
  some?: TaxCodeFilter | null;
  none?: TaxCodeFilter | null;
}

/**
 * A filter to be used against many `TravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyTravelPolicyAllowanceFilter {
  every?: TravelPolicyAllowanceFilter | null;
  some?: TravelPolicyAllowanceFilter | null;
  none?: TravelPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against many `TravelPolicy` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyTravelPolicyFilter {
  every?: TravelPolicyFilter | null;
  some?: TravelPolicyFilter | null;
  none?: TravelPolicyFilter | null;
}

/**
 * A filter to be used against many `TravelerAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyTravelerAddressFilter {
  every?: TravelerAddressFilter | null;
  some?: TravelerAddressFilter | null;
  none?: TravelerAddressFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against many `VendorAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface CountryToManyVendorAddressFilter {
  every?: VendorAddressFilter | null;
  some?: VendorAddressFilter | null;
  none?: VendorAddressFilter | null;
}

/**
 * A filter to be used against CrudOperations fields. All fields are combined with a logical ‘and.’
 */
export interface CrudOperationsFilter {
  isNull?: boolean | null;
  equalTo?: CrudOperations | null;
  notEqualTo?: CrudOperations | null;
  distinctFrom?: CrudOperations | null;
  notDistinctFrom?: CrudOperations | null;
  in?: CrudOperations[] | null;
  notIn?: CrudOperations[] | null;
  lessThan?: CrudOperations | null;
  lessThanOrEqualTo?: CrudOperations | null;
  greaterThan?: CrudOperations | null;
  greaterThanOrEqualTo?: CrudOperations | null;
}

/**
 * A filter to be used against `Currency` object types. All fields are combined with a logical ‘and.’
 */
export interface CurrencyFilter {
  id?: IntFilter | null;
  name?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isoCode?: StringFilter | null;
  currencySymbol?: StringFilter | null;
  dollarName?: StringFilter | null;
  formatMask?: StringFilter | null;
  companies?: CurrencyToManyCompanyFilter | null;
  companiesExist?: boolean | null;
  companyValidCurrencies?: CurrencyToManyCompanyValidCurrencyFilter | null;
  companyValidCurrenciesExist?: boolean | null;
  accounts?: CurrencyToManyAccountFilter | null;
  accountsExist?: boolean | null;
  cardAccountTransactions?: CurrencyToManyCardAccountTransactionFilter | null;
  cardAccountTransactionsExist?: boolean | null;
  cardHolderTransactions?: CurrencyToManyCardHolderTransactionFilter | null;
  cardHolderTransactionsExist?: boolean | null;
  and?: CurrencyFilter[] | null;
  or?: CurrencyFilter[] | null;
  not?: CurrencyFilter | null;
}

/**
 * A filter to be used against many `Account` object types. All fields are combined with a logical ‘and.’
 */
export interface CurrencyToManyAccountFilter {
  every?: AccountFilter | null;
  some?: AccountFilter | null;
  none?: AccountFilter | null;
}

/**
 * A filter to be used against many `CardAccountTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CurrencyToManyCardAccountTransactionFilter {
  every?: CardAccountTransactionFilter | null;
  some?: CardAccountTransactionFilter | null;
  none?: CardAccountTransactionFilter | null;
}

/**
 * A filter to be used against many `CardHolderTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface CurrencyToManyCardHolderTransactionFilter {
  every?: CardHolderTransactionFilter | null;
  some?: CardHolderTransactionFilter | null;
  none?: CardHolderTransactionFilter | null;
}

/**
 * A filter to be used against many `Company` object types. All fields are combined with a logical ‘and.’
 */
export interface CurrencyToManyCompanyFilter {
  every?: CompanyFilter | null;
  some?: CompanyFilter | null;
  none?: CompanyFilter | null;
}

/**
 * A filter to be used against many `CompanyValidCurrency` object types. All fields are combined with a logical ‘and.’
 */
export interface CurrencyToManyCompanyValidCurrencyFilter {
  every?: CompanyValidCurrencyFilter | null;
  some?: CompanyValidCurrencyFilter | null;
  none?: CompanyValidCurrencyFilter | null;
}

/**
 * A filter to be used against `DataAccessPolicy` object types. All fields are combined with a logical ‘and.’
 */
export interface DataAccessPolicyFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isAllBusinessUnits?: BooleanFilter | null;
  isAllSeries?: BooleanFilter | null;
  isAllSeasons?: BooleanFilter | null;
  isAllProjects?: BooleanFilter | null;
  isAllTagAccess?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  companyDataAccessPolicies?: DataAccessPolicyToManyCompanyDataAccessPolicyFilter | null;
  companyDataAccessPoliciesExist?: boolean | null;
  departmentOccupationTemplates?: DataAccessPolicyToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: DataAccessPolicyToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  and?: DataAccessPolicyFilter[] | null;
  or?: DataAccessPolicyFilter[] | null;
  not?: DataAccessPolicyFilter | null;
}

/**
 * A filter to be used against many `CompanyDataAccessPolicy` object types. All fields are combined with a logical ‘and.’
 */
export interface DataAccessPolicyToManyCompanyDataAccessPolicyFilter {
  every?: CompanyDataAccessPolicyFilter | null;
  some?: CompanyDataAccessPolicyFilter | null;
  none?: CompanyDataAccessPolicyFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface DataAccessPolicyToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface DataAccessPolicyToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against Date fields. All fields are combined with a logical ‘and.’
 */
export interface DateFilter {
  isNull?: boolean | null;
  equalTo?: GqlDate | null;
  notEqualTo?: GqlDate | null;
  distinctFrom?: GqlDate | null;
  notDistinctFrom?: GqlDate | null;
  in?: GqlDate[] | null;
  notIn?: GqlDate[] | null;
  lessThan?: GqlDate | null;
  lessThanOrEqualTo?: GqlDate | null;
  greaterThan?: GqlDate | null;
  greaterThanOrEqualTo?: GqlDate | null;
}

/**
 * A filter to be used against Date List fields. All fields are combined with a logical ‘and.’
 */
export interface DateListFilter {
  isNull?: boolean | null;
  equalTo?: (GqlDate | null)[] | null;
  notEqualTo?: (GqlDate | null)[] | null;
  distinctFrom?: (GqlDate | null)[] | null;
  notDistinctFrom?: (GqlDate | null)[] | null;
  lessThan?: (GqlDate | null)[] | null;
  lessThanOrEqualTo?: (GqlDate | null)[] | null;
  greaterThan?: (GqlDate | null)[] | null;
  greaterThanOrEqualTo?: (GqlDate | null)[] | null;
  contains?: (GqlDate | null)[] | null;
  containedBy?: (GqlDate | null)[] | null;
  overlaps?: (GqlDate | null)[] | null;
  anyEqualTo?: GqlDate | null;
  anyNotEqualTo?: GqlDate | null;
  anyLessThan?: GqlDate | null;
  anyLessThanOrEqualTo?: GqlDate | null;
  anyGreaterThan?: GqlDate | null;
  anyGreaterThanOrEqualTo?: GqlDate | null;
}

/**
 * A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’
 */
export interface DatetimeFilter {
  isNull?: boolean | null;
  equalTo?: GqlDatetime | null;
  notEqualTo?: GqlDatetime | null;
  distinctFrom?: GqlDatetime | null;
  notDistinctFrom?: GqlDatetime | null;
  in?: GqlDatetime[] | null;
  notIn?: GqlDatetime[] | null;
  lessThan?: GqlDatetime | null;
  lessThanOrEqualTo?: GqlDatetime | null;
  greaterThan?: GqlDatetime | null;
  greaterThanOrEqualTo?: GqlDatetime | null;
}

/**
 * A filter to be used against Datetime List fields. All fields are combined with a logical ‘and.’
 */
export interface DatetimeListFilter {
  isNull?: boolean | null;
  equalTo?: (GqlDatetime | null)[] | null;
  notEqualTo?: (GqlDatetime | null)[] | null;
  distinctFrom?: (GqlDatetime | null)[] | null;
  notDistinctFrom?: (GqlDatetime | null)[] | null;
  lessThan?: (GqlDatetime | null)[] | null;
  lessThanOrEqualTo?: (GqlDatetime | null)[] | null;
  greaterThan?: (GqlDatetime | null)[] | null;
  greaterThanOrEqualTo?: (GqlDatetime | null)[] | null;
  contains?: (GqlDatetime | null)[] | null;
  containedBy?: (GqlDatetime | null)[] | null;
  overlaps?: (GqlDatetime | null)[] | null;
  anyEqualTo?: GqlDatetime | null;
  anyNotEqualTo?: GqlDatetime | null;
  anyLessThan?: GqlDatetime | null;
  anyLessThanOrEqualTo?: GqlDatetime | null;
  anyGreaterThan?: GqlDatetime | null;
  anyGreaterThanOrEqualTo?: GqlDatetime | null;
}

/**
 * A filter to be used against `DeliveryAddressDepartment` object types. All fields are combined with a logical ‘and.’
 */
export interface DeliveryAddressDepartmentFilter {
  id?: UUIDFilter | null;
  deliveryAddressId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  deliveryAddress?: DeliveryAddressFilter | null;
  deliveryAddressExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  and?: DeliveryAddressDepartmentFilter[] | null;
  or?: DeliveryAddressDepartmentFilter[] | null;
  not?: DeliveryAddressDepartmentFilter | null;
}

/**
 * A filter to be used against `DeliveryAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface DeliveryAddressFilter {
  id?: UUIDFilter | null;
  _companyAddressId?: UUIDFilter | null;
  addressAlias?: StringFilter | null;
  businessUnitId?: UUIDFilter | null;
  isUsedByAllDepartments?: BooleanFilter | null;
  addressTypeId?: IntFilter | null;
  addressLine1?: StringFilter | null;
  addressLine2?: StringFilter | null;
  cityName?: StringFilter | null;
  attention?: StringFilter | null;
  postalCode?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  isAddressOverridden?: BooleanFilter | null;
  addressOverride?: StringListFilter | null;
  mainContact?: StringFilter | null;
  alternateContact?: StringFilter | null;
  emailAddress?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  mobileNumber?: StringFilter | null;
  _companyId?: UUIDFilter | null;
  _isModified?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  deliveryAddressDepartments?: DeliveryAddressToManyDeliveryAddressDepartmentFilter | null;
  deliveryAddressDepartmentsExist?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: DeliveryAddressFilter[] | null;
  or?: DeliveryAddressFilter[] | null;
  not?: DeliveryAddressFilter | null;
}

/**
 * A filter to be used against many `DeliveryAddressDepartment` object types. All fields are combined with a logical ‘and.’
 */
export interface DeliveryAddressToManyDeliveryAddressDepartmentFilter {
  every?: DeliveryAddressDepartmentFilter | null;
  some?: DeliveryAddressDepartmentFilter | null;
  none?: DeliveryAddressDepartmentFilter | null;
}

/**
 * A filter to be used against `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentBudgetFilter {
  id?: UUIDFilter | null;
  budgetId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  lookupAccountId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  budgetAmount?: BigFloatFilter | null;
  budgetPeriodStartDate?: DateFilter | null;
  budgetPeriodEndDate?: DateFilter | null;
  startCorporatePeriodId?: UUIDFilter | null;
  endCorporatePeriodId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  budgetTypeId?: IntFilter | null;
  budget?: BudgetFilter | null;
  budgetExists?: boolean | null;
  lookupAccount?: LookupAccountFilter | null;
  lookupAccountExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  startCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  startCorporatePeriodExists?: boolean | null;
  endCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  endCorporatePeriodExists?: boolean | null;
  companyCurrency?: CompanyAvailableCurrencyFilter | null;
  companyCurrencyExists?: boolean | null;
  department?: DepartmentFilter | null;
  departmentExists?: boolean | null;
  departmentBudgetType?: DepartmentBudgetTypeFilter | null;
  departmentBudgetTypeExists?: boolean | null;
  and?: DepartmentBudgetFilter[] | null;
  or?: DepartmentBudgetFilter[] | null;
  not?: DepartmentBudgetFilter | null;
}

/**
 * An input for mutations affecting `DepartmentBudget`
 */
export interface DepartmentBudgetInput {
  departmentId: GqlUUID;
  currencyId: number;
  lookupAccountId?: GqlUUID | null;
  businessUnitId?: GqlUUID | null;
  budgetAmount: GqlBigFloat;
  budgetTypeId: number;
}

/**
 * Represents an update to a `DepartmentBudget`. Fields that are set will be updated.
 */
export interface DepartmentBudgetPatch {
  departmentId?: GqlUUID | null;
  currencyId?: number | null;
  lookupAccountId?: GqlUUID | null;
  businessUnitId?: GqlUUID | null;
  budgetAmount?: GqlBigFloat | null;
  budgetTypeId?: number | null;
}

/**
 * A filter to be used against `DepartmentBudgetType` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentBudgetTypeFilter {
  id?: IntFilter | null;
  budgetType?: StringFilter | null;
  isLaborBudget?: BooleanFilter | null;
  departmentBudgetsByBudgetTypeId?: DepartmentBudgetTypeToManyDepartmentBudgetFilter | null;
  departmentBudgetsByBudgetTypeIdExist?: boolean | null;
  and?: DepartmentBudgetTypeFilter[] | null;
  or?: DepartmentBudgetTypeFilter[] | null;
  not?: DepartmentBudgetTypeFilter | null;
}

/**
 * A filter to be used against many `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentBudgetTypeToManyDepartmentBudgetFilter {
  every?: DepartmentBudgetFilter | null;
  some?: DepartmentBudgetFilter | null;
  none?: DepartmentBudgetFilter | null;
}

/**
 * All input for the `departmentBudgetUpdate` mutation.
 */
export interface DepartmentBudgetUpdateInput {
  clientMutationId?: string | null;
  budgetId: GqlUUID;
  departmentBudgetCreate?: (DepartmentBudgetInput | null)[] | null;
  departmentBudgetUpdate?: (DepartmentBudgetUpdateTypeInput | null)[] | null;
  departmentBudgetDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * An input for mutations affecting `DepartmentBudgetUpdateType`
 */
export interface DepartmentBudgetUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  departmentBudgetPatch?: DepartmentBudgetPatch | null;
}

/**
 * All input for the `departmentCreate` mutation.
 */
export interface DepartmentCreateInput {
  clientMutationId?: string | null;
  department: DepartmentInput;
}

/**
 * All input for the `departmentDelete` mutation.
 */
export interface DepartmentDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `Department` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  departmentTypeId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isSystemInternal?: BooleanFilter | null;
  approvalHistories?: DepartmentToManyApprovalHistoryFilter | null;
  approvalHistoriesExist?: boolean | null;
  userApprovals?: DepartmentToManyUserApprovalFilter | null;
  userApprovalsExist?: boolean | null;
  departmentBudgets?: DepartmentToManyDepartmentBudgetFilter | null;
  departmentBudgetsExist?: boolean | null;
  departmentGroupItems?: DepartmentToManyDepartmentGroupItemFilter | null;
  departmentGroupItemsExist?: boolean | null;
  userProfiles?: DepartmentToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  documentPoolsByDefaultDepartmentId?: DepartmentToManyDocumentPoolFilter | null;
  documentPoolsByDefaultDepartmentIdExist?: boolean | null;
  cardHolders?: DepartmentToManyCardHolderFilter | null;
  cardHoldersExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  departmentType?: DepartmentTypeFilter | null;
  departmentTypeExists?: boolean | null;
  and?: DepartmentFilter[] | null;
  or?: DepartmentFilter[] | null;
  not?: DepartmentFilter | null;
}

/**
 * All input for the `departmentGroupCreate` mutation.
 */
export interface DepartmentGroupCreateInput {
  clientMutationId?: string | null;
  departmentGroup: DepartmentGroupInput;
  departmentGroupItems?: (DepartmentGroupItemInput | null)[] | null;
}

/**
 * All input for the `departmentGroupDelete` mutation.
 */
export interface DepartmentGroupDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `DepartmentGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isPreApproved?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isTravelDepartmentGroup?: BooleanFilter | null;
  approvalBranchesByTransactionDepartmentGroupId?: DepartmentGroupToManyApprovalBranchFilter | null;
  approvalBranchesByTransactionDepartmentGroupIdExist?: boolean | null;
  approvalSelectionConditionsByRequesterDepartmentGroupId?: DepartmentGroupToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterDepartmentGroupIdExist?: boolean | null;
  companyDepartmentGroups?: DepartmentGroupToManyCompanyDepartmentGroupFilter | null;
  companyDepartmentGroupsExist?: boolean | null;
  departmentGroupItems?: DepartmentGroupToManyDepartmentGroupItemFilter | null;
  departmentGroupItemsExist?: boolean | null;
  departmentOccupationTemplates?: DepartmentGroupToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  lookupAccounts?: DepartmentGroupToManyLookupAccountFilter | null;
  lookupAccountsExist?: boolean | null;
  lookupCorporateAccounts?: DepartmentGroupToManyLookupCorporateAccountFilter | null;
  lookupCorporateAccountsExist?: boolean | null;
  userProfiles?: DepartmentGroupToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  and?: DepartmentGroupFilter[] | null;
  or?: DepartmentGroupFilter[] | null;
  not?: DepartmentGroupFilter | null;
}

/**
 * An input for mutations affecting `DepartmentGroup`
 */
export interface DepartmentGroupInput {
  name: string;
  description?: string | null;
  isTravelDepartmentGroup?: boolean | null;
}

/**
 * A filter to be used against `DepartmentGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupItemFilter {
  id?: UUIDFilter | null;
  departmentGroupId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  isVendorIsolationRequired?: BooleanFilter | null;
  isAccountIsolationRequired?: BooleanFilter | null;
  isExpenditureTypeIsolationRequired?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  department?: DepartmentFilter | null;
  departmentExists?: boolean | null;
  and?: DepartmentGroupItemFilter[] | null;
  or?: DepartmentGroupItemFilter[] | null;
  not?: DepartmentGroupItemFilter | null;
}

/**
 * An input for mutations affecting `DepartmentGroupItem`
 */
export interface DepartmentGroupItemInput {
  departmentId: GqlUUID;
}

/**
 * Represents an update to a `DepartmentGroup`. Fields that are set will be updated.
 */
export interface DepartmentGroupPatch {
  name?: string | null;
  description?: string | null;
  isTravelDepartmentGroup?: boolean | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `CompanyDepartmentGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyCompanyDepartmentGroupFilter {
  every?: CompanyDepartmentGroupFilter | null;
  some?: CompanyDepartmentGroupFilter | null;
  none?: CompanyDepartmentGroupFilter | null;
}

/**
 * A filter to be used against many `DepartmentGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyDepartmentGroupItemFilter {
  every?: DepartmentGroupItemFilter | null;
  some?: DepartmentGroupItemFilter | null;
  none?: DepartmentGroupItemFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `LookupAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyLookupAccountFilter {
  every?: LookupAccountFilter | null;
  some?: LookupAccountFilter | null;
  none?: LookupAccountFilter | null;
}

/**
 * A filter to be used against many `LookupCorporateAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyLookupCorporateAccountFilter {
  every?: LookupCorporateAccountFilter | null;
  some?: LookupCorporateAccountFilter | null;
  none?: LookupCorporateAccountFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentGroupToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * All input for the `departmentGroupUpdate` mutation.
 */
export interface DepartmentGroupUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  departmentGroupPatch?: DepartmentGroupPatch | null;
  departmentGroupItemsCreate?: (DepartmentGroupItemInput | null)[] | null;
  departmentGroupItemsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * An input for mutations affecting `Department`
 */
export interface DepartmentInput {
  name: string;
  description?: string | null;
}

/**
 * A filter to be used against `DepartmentOccupationApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationApprovalFilter {
  id?: UUIDFilter | null;
  departmentOccupationTemplateId?: UUIDFilter | null;
  approvalTypeId?: IntFilter | null;
  isApprover?: BooleanFilter | null;
  isRequester?: BooleanFilter | null;
  isObserver?: BooleanFilter | null;
  isEnMasseApprovalsEnabled?: BooleanFilter | null;
  isAutoApproveEnabled?: BooleanFilter | null;
  isSelfApproveEnabled?: BooleanFilter | null;
  isApprovalTreeViewerAllowed?: BooleanFilter | null;
  isAddingNonApproversAllowed?: BooleanFilter | null;
  isAddingExternalApproversAllowed?: BooleanFilter | null;
  isApprovalRevokingEnabled?: BooleanFilter | null;
  isLowerTreeLevelBypassEnabled?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isExtendedApprover?: BooleanFilter | null;
  approvalType?: ApprovalTypeFilter | null;
  approvalTypeExists?: boolean | null;
  departmentOccupationTemplate?: DepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplateExists?: boolean | null;
  and?: DepartmentOccupationApprovalFilter[] | null;
  or?: DepartmentOccupationApprovalFilter[] | null;
  not?: DepartmentOccupationApprovalFilter | null;
}

/**
 * An input for mutations affecting `DepartmentOccupationApproval`
 */
export interface DepartmentOccupationApprovalInput {
  approvalTypeId: number;
  isApprover?: boolean | null;
  isRequester?: boolean | null;
  isObserver?: boolean | null;
  isEnMasseApprovalsEnabled?: boolean | null;
  isAutoApproveEnabled?: boolean | null;
  isSelfApproveEnabled?: boolean | null;
  isApprovalTreeViewerAllowed?: boolean | null;
  isAddingNonApproversAllowed?: boolean | null;
  isApprovalRevokingEnabled?: boolean | null;
  isLowerTreeLevelBypassEnabled?: boolean | null;
  isExtendedApprover?: boolean | null;
}

/**
 * Represents an update to a `DepartmentOccupationApproval`. Fields that are set will be updated.
 */
export interface DepartmentOccupationApprovalPatch {
  approvalTypeId?: number | null;
  isApprover?: boolean | null;
  isRequester?: boolean | null;
  isObserver?: boolean | null;
  isEnMasseApprovalsEnabled?: boolean | null;
  isAutoApproveEnabled?: boolean | null;
  isSelfApproveEnabled?: boolean | null;
  isApprovalTreeViewerAllowed?: boolean | null;
  isAddingNonApproversAllowed?: boolean | null;
  isApprovalRevokingEnabled?: boolean | null;
  isLowerTreeLevelBypassEnabled?: boolean | null;
  isExtendedApprover?: boolean | null;
}

/**
 * An input for mutations affecting `DepartmentOccupationApprovalUpdateType`
 */
export interface DepartmentOccupationApprovalUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  departmentOccupationApprovalPatch?: DepartmentOccupationApprovalPatch | null;
}

/**
 * A filter to be used against `DepartmentOccupationChart` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationChartFilter {
  id?: UUIDFilter | null;
  departmentOccupationTemplateId?: UUIDFilter | null;
  chartTypeId?: IntFilter | null;
  chartPosition?: IntFilter | null;
  isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  chartType?: ChartTypeFilter | null;
  chartTypeExists?: boolean | null;
  departmentOccupationTemplate?: DepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplateExists?: boolean | null;
  and?: DepartmentOccupationChartFilter[] | null;
  or?: DepartmentOccupationChartFilter[] | null;
  not?: DepartmentOccupationChartFilter | null;
}

/**
 * An input for mutations affecting `DepartmentOccupationChart`
 */
export interface DepartmentOccupationChartInput {
  chartTypeId: number;
  chartPosition?: number | null;
  isActive?: boolean | null;
}

/**
 * Represents an update to a `DepartmentOccupationChart`. Fields that are set will be updated.
 */
export interface DepartmentOccupationChartPatch {
  chartTypeId?: number | null;
  chartPosition?: number | null;
  isActive?: boolean | null;
}

/**
 * An input for mutations affecting `DepartmentOccupationChartUpdateType`
 */
export interface DepartmentOccupationChartUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  departmentOccupationChartPatch?: DepartmentOccupationChartPatch | null;
}

/**
 * A filter to be used against `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationTemplateFilter {
  id?: UUIDFilter | null;
  description?: StringFilter | null;
  departmentId?: UUIDFilter | null;
  userOccupationTitleId?: UUIDFilter | null;
  roleId?: UUIDFilter | null;
  userRankTypeId?: UUIDFilter | null;
  dataAccessPolicyId?: UUIDFilter | null;
  tagGroupId?: UUIDFilter | null;
  communicationGroupId?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  accessGroupId?: UUIDFilter | null;
  departmentGroupId?: UUIDFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  name?: StringFilter | null;
  isTemplateControlled?: BooleanFilter | null;
  isOccupationPlacedInDescription?: BooleanFilter | null;
  secureRowLevelId?: UUIDFilter | null;
  isDepartmentUserGroupRequired?: BooleanFilter | null;
  isUserDepartmentDefault?: BooleanFilter | null;
  isUserPrimaryCurrencyDefault?: BooleanFilter | null;
  isUserPrimaryBusinessUnitDefault?: BooleanFilter | null;
  userDefaultCompany?: StringFilter | null;
  userDefaultLocation?: StringFilter | null;
  _isSyncAllowed?: BooleanFilter | null;
  companyDepartmentOccupationTemplates?: DepartmentOccupationTemplateToManyCompanyDepartmentOccupationTemplateFilter | null;
  companyDepartmentOccupationTemplatesExist?: boolean | null;
  departmentOccupationApprovals?: DepartmentOccupationTemplateToManyDepartmentOccupationApprovalFilter | null;
  departmentOccupationApprovalsExist?: boolean | null;
  departmentOccupationCharts?: DepartmentOccupationTemplateToManyDepartmentOccupationChartFilter | null;
  departmentOccupationChartsExist?: boolean | null;
  userProfiles?: DepartmentOccupationTemplateToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  departmentOccupationTemplatesHistoriesByEntityId?: DepartmentOccupationTemplateToManyDepartmentOccupationTemplatesHistoryFilter | null;
  departmentOccupationTemplatesHistoriesByEntityIdExist?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  userOccupationTitle?: UserOccupationTitleFilter | null;
  userOccupationTitleExists?: boolean | null;
  role?: RoleFilter | null;
  roleExists?: boolean | null;
  userRank?: UserRankTypeFilter | null;
  userRankExists?: boolean | null;
  dataAccessPolicy?: DataAccessPolicyFilter | null;
  dataAccessPolicyExists?: boolean | null;
  tagGroup?: TagGroupFilter | null;
  tagGroupExists?: boolean | null;
  communicationGroup?: CommunicationGroupFilter | null;
  communicationGroupExists?: boolean | null;
  userGroup?: UserGroupFilter | null;
  userGroupExists?: boolean | null;
  accessGroup?: AccessGroupFilter | null;
  accessGroupExists?: boolean | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  and?: DepartmentOccupationTemplateFilter[] | null;
  or?: DepartmentOccupationTemplateFilter[] | null;
  not?: DepartmentOccupationTemplateFilter | null;
}

/**
 * An input for mutations affecting `DepartmentOccupationTemplate`
 */
export interface DepartmentOccupationTemplateInput {
  description?: string | null;
  departmentId?: GqlUUID | null;
  userOccupationTitleId?: GqlUUID | null;
  roleId?: GqlUUID | null;
  userRankTypeId?: GqlUUID | null;
  dataAccessPolicyId?: GqlUUID | null;
  tagGroupId?: GqlUUID | null;
  communicationGroupId?: GqlUUID | null;
  userGroupId?: GqlUUID | null;
  accessGroupId?: GqlUUID | null;
  departmentGroupId?: GqlUUID | null;
  name: string;
  isTemplateControlled?: boolean | null;
  secureRowLevelId?: GqlUUID | null;
  isDepartmentUserGroupRequired?: boolean | null;
  isUserDepartmentDefault?: boolean | null;
  isUserPrimaryCurrencyDefault?: boolean | null;
  isUserPrimaryBusinessUnitDefault?: boolean | null;
  userDefaultCompany?: string | null;
  userDefaultLocation?: string | null;
}

/**
 * Represents an update to a `DepartmentOccupationTemplate`. Fields that are set will be updated.
 */
export interface DepartmentOccupationTemplatePatch {
  description?: string | null;
  departmentId?: GqlUUID | null;
  userOccupationTitleId?: GqlUUID | null;
  roleId?: GqlUUID | null;
  userRankTypeId?: GqlUUID | null;
  dataAccessPolicyId?: GqlUUID | null;
  tagGroupId?: GqlUUID | null;
  communicationGroupId?: GqlUUID | null;
  userGroupId?: GqlUUID | null;
  accessGroupId?: GqlUUID | null;
  departmentGroupId?: GqlUUID | null;
  name?: string | null;
  isTemplateControlled?: boolean | null;
  secureRowLevelId?: GqlUUID | null;
  isDepartmentUserGroupRequired?: boolean | null;
  isUserDepartmentDefault?: boolean | null;
  isUserPrimaryCurrencyDefault?: boolean | null;
  isUserPrimaryBusinessUnitDefault?: boolean | null;
  userDefaultCompany?: string | null;
  userDefaultLocation?: string | null;
}

/**
 * A filter to be used against many `CompanyDepartmentOccupationTemplate` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationTemplateToManyCompanyDepartmentOccupationTemplateFilter {
  every?: CompanyDepartmentOccupationTemplateFilter | null;
  some?: CompanyDepartmentOccupationTemplateFilter | null;
  none?: CompanyDepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationTemplateToManyDepartmentOccupationApprovalFilter {
  every?: DepartmentOccupationApprovalFilter | null;
  some?: DepartmentOccupationApprovalFilter | null;
  none?: DepartmentOccupationApprovalFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationChart` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationTemplateToManyDepartmentOccupationChartFilter {
  every?: DepartmentOccupationChartFilter | null;
  some?: DepartmentOccupationChartFilter | null;
  none?: DepartmentOccupationChartFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplatesHistory` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationTemplateToManyDepartmentOccupationTemplatesHistoryFilter {
  every?: DepartmentOccupationTemplatesHistoryFilter | null;
  some?: DepartmentOccupationTemplatesHistoryFilter | null;
  none?: DepartmentOccupationTemplatesHistoryFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationTemplateToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `DepartmentOccupationTemplatesHistory` object types.
 * All fields are combined with a logical ‘and.’
 */
export interface DepartmentOccupationTemplatesHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  departmentOccupationTemplate?: DepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplateExists?: boolean | null;
  and?: DepartmentOccupationTemplatesHistoryFilter[] | null;
  or?: DepartmentOccupationTemplatesHistoryFilter[] | null;
  not?: DepartmentOccupationTemplatesHistoryFilter | null;
}

/**
 * Represents an update to a `Department`. Fields that are set will be updated.
 */
export interface DepartmentPatch {
  name?: string | null;
  description?: string | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `CardHolder` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentToManyCardHolderFilter {
  every?: CardHolderFilter | null;
  some?: CardHolderFilter | null;
  none?: CardHolderFilter | null;
}

/**
 * A filter to be used against many `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentToManyDepartmentBudgetFilter {
  every?: DepartmentBudgetFilter | null;
  some?: DepartmentBudgetFilter | null;
  none?: DepartmentBudgetFilter | null;
}

/**
 * A filter to be used against many `DepartmentGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentToManyDepartmentGroupItemFilter {
  every?: DepartmentGroupItemFilter | null;
  some?: DepartmentGroupItemFilter | null;
  none?: DepartmentGroupItemFilter | null;
}

/**
 * A filter to be used against many `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentToManyDocumentPoolFilter {
  every?: DocumentPoolFilter | null;
  some?: DocumentPoolFilter | null;
  none?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `DepartmentType` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentTypeFilter {
  id?: IntFilter | null;
  departmentType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isAboveTheLine?: BooleanFilter | null;
  isBelowTheLine?: BooleanFilter | null;
  isAdministrator?: BooleanFilter | null;
  isAccountingDepartment?: BooleanFilter | null;
  isPayrollDepartment?: BooleanFilter | null;
  isExternalDepartment?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  approvalSelectionConditionsByRequesterDepartmentTypeId?: DepartmentTypeToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterDepartmentTypeIdExist?: boolean | null;
  departments?: DepartmentTypeToManyDepartmentFilter | null;
  departmentsExist?: boolean | null;
  and?: DepartmentTypeFilter[] | null;
  or?: DepartmentTypeFilter[] | null;
  not?: DepartmentTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentTypeToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `Department` object types. All fields are combined with a logical ‘and.’
 */
export interface DepartmentTypeToManyDepartmentFilter {
  every?: DepartmentFilter | null;
  some?: DepartmentFilter | null;
  none?: DepartmentFilter | null;
}

/**
 * All input for the `departmentUpdate` mutation.
 */
export interface DepartmentUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  departmentPatch?: DepartmentPatch | null;
}

/**
 * A filter to be used against `DepotCity` object types. All fields are combined with a logical ‘and.’
 */
export interface DepotCityFilter {
  id?: UUIDFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  cityArea?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _sortOrder?: StringFilter | null;
  depotsByCityAreaId?: DepotCityToManyDepotFilter | null;
  depotsByCityAreaIdExist?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: DepotCityFilter[] | null;
  or?: DepotCityFilter[] | null;
  not?: DepotCityFilter | null;
}

/**
 * A filter to be used against many `Depot` object types. All fields are combined with a logical ‘and.’
 */
export interface DepotCityToManyDepotFilter {
  every?: DepotFilter | null;
  some?: DepotFilter | null;
  none?: DepotFilter | null;
}

/**
 * A filter to be used against `Depot` object types. All fields are combined with a logical ‘and.’
 */
export interface DepotFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  iataCode?: StringFilter | null;
  icaoCode?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  cityArea?: StringFilter | null;
  isAirport?: BooleanFilter | null;
  isTrainDepot?: BooleanFilter | null;
  isBusDepot?: BooleanFilter | null;
  cityAreaId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _sortOrder?: StringFilter | null;
  stateRegion?: StringFilter | null;
  tripItemsByDepartureDepotId?: DepotToManyTripItemFilter | null;
  tripItemsByDepartureDepotIdExist?: boolean | null;
  tripItemsByDepartureArrivalDepotId?: DepotToManyTripItemFilter | null;
  tripItemsByDepartureArrivalDepotIdExist?: boolean | null;
  tripItemsByReturnDepotId?: DepotToManyTripItemFilter | null;
  tripItemsByReturnDepotIdExist?: boolean | null;
  tripItemsByReturnArrivalDepotId?: DepotToManyTripItemFilter | null;
  tripItemsByReturnArrivalDepotIdExist?: boolean | null;
  tripItemsByDepart1DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByDepart1DepotIdExist?: boolean | null;
  tripItemsByDepart2DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByDepart2DepotIdExist?: boolean | null;
  tripItemsByDepart3DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByDepart3DepotIdExist?: boolean | null;
  tripItemsByDepart4DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByDepart4DepotIdExist?: boolean | null;
  tripItemsByReturn1DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByReturn1DepotIdExist?: boolean | null;
  tripItemsByReturn2DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByReturn2DepotIdExist?: boolean | null;
  tripItemsByReturn3DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByReturn3DepotIdExist?: boolean | null;
  tripItemsByReturn4DepotId?: DepotToManyTripItemFilter | null;
  tripItemsByReturn4DepotIdExist?: boolean | null;
  depotCity?: DepotCityFilter | null;
  depotCityExists?: boolean | null;
  and?: DepotFilter[] | null;
  or?: DepotFilter[] | null;
  not?: DepotFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface DepotToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against `DietaryRequirement` object types. All fields are combined with a logical ‘and.’
 */
export interface DietaryRequirementFilter {
  id?: IntFilter | null;
  description?: StringFilter | null;
  isDescriptionRequired?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  sortOrder?: IntFilter | null;
  vendorTravelDietaryRequirements?: DietaryRequirementToManyVendorTravelDietaryRequirementFilter | null;
  vendorTravelDietaryRequirementsExist?: boolean | null;
  travelerDietaryRequirements?: DietaryRequirementToManyTravelerDietaryRequirementFilter | null;
  travelerDietaryRequirementsExist?: boolean | null;
  and?: DietaryRequirementFilter[] | null;
  or?: DietaryRequirementFilter[] | null;
  not?: DietaryRequirementFilter | null;
}

/**
 * A filter to be used against many `TravelerDietaryRequirement` object types. All fields are combined with a logical ‘and.’
 */
export interface DietaryRequirementToManyTravelerDietaryRequirementFilter {
  every?: TravelerDietaryRequirementFilter | null;
  some?: TravelerDietaryRequirementFilter | null;
  none?: TravelerDietaryRequirementFilter | null;
}

/**
 * A filter to be used against many `VendorTravelDietaryRequirement` object types.
 * All fields are combined with a logical ‘and.’
 */
export interface DietaryRequirementToManyVendorTravelDietaryRequirementFilter {
  every?: VendorTravelDietaryRequirementFilter | null;
  some?: VendorTravelDietaryRequirementFilter | null;
  none?: VendorTravelDietaryRequirementFilter | null;
}

/**
 * A filter to be used against `DirectoryType` object types. All fields are combined with a logical ‘and.’
 */
export interface DirectoryTypeFilter {
  id?: IntFilter | null;
  directory?: StringFilter | null;
  isExternalService?: BooleanFilter | null;
  isThumbnail?: BooleanFilter | null;
  isPoolSelectable?: BooleanFilter | null;
  description?: StringFilter | null;
  documentPoolsByDefaultDirectoryTypeId?: DirectoryTypeToManyDocumentPoolFilter | null;
  documentPoolsByDefaultDirectoryTypeIdExist?: boolean | null;
  and?: DirectoryTypeFilter[] | null;
  or?: DirectoryTypeFilter[] | null;
  not?: DirectoryTypeFilter | null;
}

/**
 * A filter to be used against many `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface DirectoryTypeToManyDocumentPoolFilter {
  every?: DocumentPoolFilter | null;
  some?: DocumentPoolFilter | null;
  none?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against `DistributionLayoutType` object types. All fields are combined with a logical ‘and.’
 */
export interface DistributionLayoutTypeFilter {
  id?: IntFilter | null;
  name?: StringFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  accountMask?: StringFilter | null;
  projectMask?: StringFilter | null;
  setMask?: StringFilter | null;
  userDefaults?: DistributionLayoutTypeToManyUserDefaultFilter | null;
  userDefaultsExist?: boolean | null;
  and?: DistributionLayoutTypeFilter[] | null;
  or?: DistributionLayoutTypeFilter[] | null;
  not?: DistributionLayoutTypeFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface DistributionLayoutTypeToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * A filter to be used against `DocumentAppliedAmount` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentAppliedAmountFilter {
  id?: UUIDFilter | null;
  documentTotal?: BigFloatFilter | null;
  usedTotal?: BigFloatFilter | null;
  appliedTotal?: BigFloatFilter | null;
  retiredTotal?: BigFloatFilter | null;
  overageTotal?: BigFloatFilter | null;
  remainingTotal?: BigFloatFilter | null;
  _isRemainingAmountAvailable?: BooleanFilter | null;
  _isRetiringBalanceAllowed?: BooleanFilter | null;
  entityDocumentsByDocumentFileId?: DocumentAppliedAmountToManyEntityDocumentFilter | null;
  entityDocumentsByDocumentFileIdExist?: boolean | null;
  and?: DocumentAppliedAmountFilter[] | null;
  or?: DocumentAppliedAmountFilter[] | null;
  not?: DocumentAppliedAmountFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentAppliedAmountToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against `DocumentFileDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentFileDistributionFilter {
  id?: UUIDFilter | null;
  documentFileId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  projectId?: UUIDFilter | null;
  accountId?: UUIDFilter | null;
  setAccountId?: UUIDFilter | null;
  referenceCode1Id?: UUIDFilter | null;
  referenceCode2Id?: UUIDFilter | null;
  referenceCode3Id?: UUIDFilter | null;
  referenceCode4Id?: UUIDFilter | null;
  referenceCode5Id?: UUIDFilter | null;
  purchaseOrderReference?: StringFilter | null;
  accountReference?: StringFilter | null;
  projectReference?: StringFilter | null;
  setReference?: StringFilter | null;
  additionalDescription?: StringFilter | null;
  referenceCode1?: StringFilter | null;
  referenceCode2?: StringFilter | null;
  referenceCode3?: StringFilter | null;
  referenceCode4?: StringFilter | null;
  referenceCode5?: StringFilter | null;
  distributionAmount?: BigFloatFilter | null;
  retiredAmount?: BigFloatFilter | null;
  approvedOverageAmount?: BigFloatFilter | null;
  statusExplanation?: StringFilter | null;
  productionIncentiveId?: UUIDFilter | null;
  tax1099T4TypeId?: IntFilter | null;
  lookupAccountId?: UUIDFilter | null;
  isInvoiceSelectable?: BooleanFilter | null;
  documentAppliedAmount?: BigFloatFilter | null;
  businessUnitId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _invoiceReceiptAmount?: BigFloatFilter | null;
  _sortOrder?: StringFilter | null;
  referenceCode6Id?: UUIDFilter | null;
  referenceCode7Id?: UUIDFilter | null;
  referenceCode6?: StringFilter | null;
  referenceCode7?: StringFilter | null;
  distributionDate?: DateFilter | null;
  _accountName?: StringFilter | null;
  _ff1Name?: StringFilter | null;
  _ff2Name?: StringFilter | null;
  _ff3Name?: StringFilter | null;
  _ff4Name?: StringFilter | null;
  documentFile?: EntityDocumentFilter | null;
  documentFileExists?: boolean | null;
  lookupAccount?: LookupAccountFilter | null;
  lookupAccountExists?: boolean | null;
  and?: DocumentFileDistributionFilter[] | null;
  or?: DocumentFileDistributionFilter[] | null;
  not?: DocumentFileDistributionFilter | null;
}

/**
 * An input for mutations affecting `DocumentFileDistribution`
 */
export interface DocumentFileDistributionInput {
  accountReference?: string | null;
  projectReference?: string | null;
  setReference?: string | null;
  additionalDescription?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  distributionAmount?: GqlBigFloat | null;
  tax1099T4TypeId?: number | null;
  lookupAccountId?: GqlUUID | null;
  isInvoiceSelectable?: boolean | null;
  documentAppliedAmount?: GqlBigFloat | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  distributionDate?: GqlDate | null;
}

/**
 * Represents an update to a `DocumentFileDistribution`. Fields that are set will be updated.
 */
export interface DocumentFileDistributionPatch {
  accountReference?: string | null;
  projectReference?: string | null;
  setReference?: string | null;
  additionalDescription?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  distributionAmount?: GqlBigFloat | null;
  tax1099T4TypeId?: number | null;
  lookupAccountId?: GqlUUID | null;
  isInvoiceSelectable?: boolean | null;
  documentAppliedAmount?: GqlBigFloat | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  distributionDate?: GqlDate | null;
}

/**
 * An input for mutations affecting `DocumentFileDistributionUpdateType`
 */
export interface DocumentFileDistributionUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  documentFileDistributionPatch?: DocumentFileDistributionPatch | null;
}

/**
 * All input for the `documentPoolCreate` mutation.
 */
export interface DocumentPoolCreateInput {
  clientMutationId?: string | null;
  documentPool: (DocumentPoolInput | null)[];
}

/**
 * All input for the `documentPoolDelete` mutation.
 */
export interface DocumentPoolDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `DocumentPoolDocumentTotal` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolDocumentTotalFilter {
  documentPoolId?: UUIDFilter | null;
  availableDocuments?: IntFilter | null;
  selectedDocuments?: IntFilter | null;
  supplierDocumentTotals?: AvailableSupplierDocumentPoolFilter | null;
  supplierDocumentTotalsExists?: boolean | null;
  recipientPoolTotals?: DocumentPoolRecipientFilter | null;
  recipientPoolTotalsExists?: boolean | null;
  documentPoolTotals?: DocumentPoolFilter | null;
  documentPoolTotalsExists?: boolean | null;
  fileCabinetRecipientPoolTotals?: FileCabinetPoolRecipientFilter | null;
  fileCabinetRecipientPoolTotalsExists?: boolean | null;
  fileCabinetPoolTotals?: FileCabinetPoolFilter | null;
  fileCabinetPoolTotalsExists?: boolean | null;
  and?: DocumentPoolDocumentTotalFilter[] | null;
  or?: DocumentPoolDocumentTotalFilter[] | null;
  not?: DocumentPoolDocumentTotalFilter | null;
}

/**
 * A filter to be used against `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  defaultDirectoryTypeId?: IntFilter | null;
  defaultDocumentTypeId?: IntFilter | null;
  defaultEntityTypeId?: IntFilter | null;
  defaultBusinessUnitId?: UUIDFilter | null;
  defaultDepartmentId?: UUIDFilter | null;
  isShareableDefault?: BooleanFilter | null;
  defaultRowSecurityId?: UUIDFilter | null;
  isRetainedWithSelection?: BooleanFilter | null;
  isCreateCopyWithSelection?: BooleanFilter | null;
  isCompanySelector?: BooleanFilter | null;
  isBusinessUnitSelector?: BooleanFilter | null;
  isDepartmentSelector?: BooleanFilter | null;
  isEntityTypeSelector?: BooleanFilter | null;
  isDocumentTypeSelector?: BooleanFilter | null;
  isPrivatePool?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _entityTypeId?: IntFilter | null;
  extractionTypeId?: IntFilter | null;
  parentDocumentPoolId?: UUIDFilter | null;
  notesByEntityId?: DocumentPoolToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  entityDocumentsByDocumentPoolIdAndEntityTypeId?: DocumentPoolToManyEntityDocumentFilter | null;
  entityDocumentsByDocumentPoolIdAndEntityTypeIdExist?: boolean | null;
  cardAccounts?: DocumentPoolToManyCardAccountFilter | null;
  cardAccountsExist?: boolean | null;
  cardHolders?: DocumentPoolToManyCardHolderFilter | null;
  cardHoldersExist?: boolean | null;
  documentPoolHistoriesByEntityId?: DocumentPoolToManyDocumentPoolHistoryFilter | null;
  documentPoolHistoriesByEntityIdExist?: boolean | null;
  suppliersByPurchaseDocumentsPoolId?: DocumentPoolToManySupplierFilter | null;
  suppliersByPurchaseDocumentsPoolIdExist?: boolean | null;
  documentPoolSecureRowLevels?: SecureRowLevelFilter | null;
  documentPoolSecureRowLevelsExists?: boolean | null;
  defaultDocumentPoolSecureRowLevels?: SecureRowLevelFilter | null;
  defaultDocumentPoolSecureRowLevelsExists?: boolean | null;
  defaultDirectoryTypes?: DirectoryTypeFilter | null;
  defaultDirectoryTypesExists?: boolean | null;
  defaultDocumentTypes?: DocumentTypeFilter | null;
  defaultDocumentTypesExists?: boolean | null;
  defaultDepartments?: DepartmentFilter | null;
  defaultDepartmentsExists?: boolean | null;
  defaultBusinessUnits?: BusinessUnitFilter | null;
  defaultBusinessUnitsExists?: boolean | null;
  documentPoolTotals?: DocumentPoolDocumentTotalFilter | null;
  documentPoolTotalsExists?: boolean | null;
  userGroups?: UserGroupFilter | null;
  userGroupsExists?: boolean | null;
  extractionType?: ExtractionTypeFilter | null;
  extractionTypeExists?: boolean | null;
  and?: DocumentPoolFilter[] | null;
  or?: DocumentPoolFilter[] | null;
  not?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against `DocumentPoolHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  documentPool?: DocumentPoolFilter | null;
  documentPoolExists?: boolean | null;
  and?: DocumentPoolHistoryFilter[] | null;
  or?: DocumentPoolHistoryFilter[] | null;
  not?: DocumentPoolHistoryFilter | null;
}

/**
 * An input for mutations affecting `DocumentPool`
 */
export interface DocumentPoolInput {
  name: string;
  description?: string | null;
  defaultDirectoryTypeId?: number | null;
  defaultDocumentTypeId?: number | null;
  defaultBusinessUnitId?: GqlUUID | null;
  defaultDepartmentId?: GqlUUID | null;
  isShareableDefault?: boolean | null;
  isRetainedWithSelection?: boolean | null;
  isCreateCopyWithSelection?: boolean | null;
  isBusinessUnitSelector?: boolean | null;
  isDepartmentSelector?: boolean | null;
  isDocumentTypeSelector?: boolean | null;
  userGroupId?: GqlUUID | null;
  rowSecurityId?: GqlUUID | null;
  extractionTypeId?: number | null;
  parentDocumentPoolId?: GqlUUID | null;
}

/**
 * Represents an update to a `DocumentPool`. Fields that are set will be updated.
 */
export interface DocumentPoolPatch {
  name?: string | null;
  description?: string | null;
  defaultDirectoryTypeId?: number | null;
  defaultDocumentTypeId?: number | null;
  defaultBusinessUnitId?: GqlUUID | null;
  defaultDepartmentId?: GqlUUID | null;
  isShareableDefault?: boolean | null;
  isRetainedWithSelection?: boolean | null;
  isCreateCopyWithSelection?: boolean | null;
  isBusinessUnitSelector?: boolean | null;
  isDepartmentSelector?: boolean | null;
  isDocumentTypeSelector?: boolean | null;
  userGroupId?: GqlUUID | null;
  rowSecurityId?: GqlUUID | null;
  extractionTypeId?: number | null;
  parentDocumentPoolId?: GqlUUID | null;
}

/**
 * A filter to be used against `DocumentPoolRecipient` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolRecipientFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  defaultDirectoryTypeId?: IntFilter | null;
  defaultDocumentTypeId?: IntFilter | null;
  defaultEntityTypeId?: IntFilter | null;
  defaultBusinessUnitId?: UUIDFilter | null;
  defaultDepartmentId?: UUIDFilter | null;
  isShareableDefault?: BooleanFilter | null;
  defaultRowSecurityId?: UUIDFilter | null;
  isRetainedWithSelection?: BooleanFilter | null;
  isCreateCopyWithSelection?: BooleanFilter | null;
  isCompanySelector?: BooleanFilter | null;
  isBusinessUnitSelector?: BooleanFilter | null;
  isDepartmentSelector?: BooleanFilter | null;
  isEntityTypeSelector?: BooleanFilter | null;
  isDocumentTypeSelector?: BooleanFilter | null;
  isPrivatePool?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _entityTypeId?: IntFilter | null;
  extractionTypeId?: IntFilter | null;
  recipientDefaultDocumentTypes?: DocumentTypeFilter | null;
  recipientDefaultDocumentTypesExists?: boolean | null;
  recipientPoolTotals?: DocumentPoolDocumentTotalFilter | null;
  recipientPoolTotalsExists?: boolean | null;
  and?: DocumentPoolRecipientFilter[] | null;
  or?: DocumentPoolRecipientFilter[] | null;
  not?: DocumentPoolRecipientFilter | null;
}

/**
 * All input for the `documentPoolRowProtection` mutation.
 */
export interface DocumentPoolRowProtectionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  isProtectionRemoval?: boolean | null;
}

/**
 * All input for the `documentPoolSubFolderCreate` mutation.
 */
export interface DocumentPoolSubFolderCreateInput {
  clientMutationId?: string | null;
  parentPoolId: GqlUUID;
  documentPool: DocumentPoolInput;
}

/**
 * A filter to be used against many `CardAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolToManyCardAccountFilter {
  every?: CardAccountFilter | null;
  some?: CardAccountFilter | null;
  none?: CardAccountFilter | null;
}

/**
 * A filter to be used against many `CardHolder` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolToManyCardHolderFilter {
  every?: CardHolderFilter | null;
  some?: CardHolderFilter | null;
  none?: CardHolderFilter | null;
}

/**
 * A filter to be used against many `DocumentPoolHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolToManyDocumentPoolHistoryFilter {
  every?: DocumentPoolHistoryFilter | null;
  some?: DocumentPoolHistoryFilter | null;
  none?: DocumentPoolHistoryFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentPoolToManySupplierFilter {
  every?: SupplierFilter | null;
  some?: SupplierFilter | null;
  none?: SupplierFilter | null;
}

/**
 * All input for the `documentPoolUpdate` mutation.
 */
export interface DocumentPoolUpdateInput {
  clientMutationId?: string | null;
  documentPoolsUpdate: (DocumentPoolUpdateTypeInput | null)[];
}

/**
 * An input for mutations affecting `DocumentPoolUpdateType`
 */
export interface DocumentPoolUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  documentPoolPatch?: DocumentPoolPatch | null;
}

/**
 * A filter to be used against `DocumentStorageAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentStorageAccountFilter {
  id?: UUIDFilter | null;
  fileName?: StringFilter | null;
  storageAccount?: StringFilter | null;
  containerName?: StringFilter | null;
  blobService?: StringFilter | null;
  fileService?: StringFilter | null;
  queueService?: StringFilter | null;
  tableService?: StringFilter | null;
  dataLakeService?: StringFilter | null;
  accountResource?: StringFilter | null;
  blobResource?: StringFilter | null;
  documentStorage?: EntityDocumentFilter | null;
  documentStorageExists?: boolean | null;
  and?: DocumentStorageAccountFilter[] | null;
  or?: DocumentStorageAccountFilter[] | null;
  not?: DocumentStorageAccountFilter | null;
}

/**
 * All input for the `documentTransactionBuild` mutation.
 */
export interface DocumentTransactionBuildInput {
  clientMutationId?: string | null;
  businessUnitId: GqlUUID;
  departmentId: GqlUUID;
  entityDocumentId: (GqlUUID | null)[];
  isMultiPageBundle?: boolean | null;
}

/**
 * A filter to be used against `DocumentType` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentTypeFilter {
  id?: IntFilter | null;
  documentType?: StringFilter | null;
  isSigningRequired?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  isReportingDocument?: BooleanFilter | null;
  _isDefault?: BooleanFilter | null;
  isAccountingDocument?: BooleanFilter | null;
  _isAppliableDocumentType?: BooleanFilter | null;
  transactionTypeId?: IntFilter | null;
  _isDocumentDistributionAllowed?: BooleanFilter | null;
  _isAutoViewDocument?: BooleanFilter | null;
  _isMultiDocumentBundleAllowed?: BooleanFilter | null;
  _isPoSystemDocument?: BooleanFilter | null;
  _isTaxFieldTracked?: BooleanFilter | null;
  extractionTypes?: DocumentTypeToManyExtractionTypeFilter | null;
  extractionTypesExist?: boolean | null;
  documentPoolRecipientsByDefaultDocumentTypeId?: DocumentTypeToManyDocumentPoolRecipientFilter | null;
  documentPoolRecipientsByDefaultDocumentTypeIdExist?: boolean | null;
  documentPoolsByDefaultDocumentTypeId?: DocumentTypeToManyDocumentPoolFilter | null;
  documentPoolsByDefaultDocumentTypeIdExist?: boolean | null;
  entityDocuments?: DocumentTypeToManyEntityDocumentFilter | null;
  entityDocumentsExist?: boolean | null;
  fileCabinetPoolRecipientsByDefaultDocumentTypeId?: DocumentTypeToManyFileCabinetPoolRecipientFilter | null;
  fileCabinetPoolRecipientsByDefaultDocumentTypeIdExist?: boolean | null;
  transactionType?: CompanyTransactionTypeFilter | null;
  transactionTypeExists?: boolean | null;
  and?: DocumentTypeFilter[] | null;
  or?: DocumentTypeFilter[] | null;
  not?: DocumentTypeFilter | null;
}

/**
 * A filter to be used against many `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentTypeToManyDocumentPoolFilter {
  every?: DocumentPoolFilter | null;
  some?: DocumentPoolFilter | null;
  none?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against many `DocumentPoolRecipient` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentTypeToManyDocumentPoolRecipientFilter {
  every?: DocumentPoolRecipientFilter | null;
  some?: DocumentPoolRecipientFilter | null;
  none?: DocumentPoolRecipientFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentTypeToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `ExtractionType` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentTypeToManyExtractionTypeFilter {
  every?: ExtractionTypeFilter | null;
  some?: ExtractionTypeFilter | null;
  none?: ExtractionTypeFilter | null;
}

/**
 * A filter to be used against many `FileCabinetPoolRecipient` object types. All fields are combined with a logical ‘and.’
 */
export interface DocumentTypeToManyFileCabinetPoolRecipientFilter {
  every?: FileCabinetPoolRecipientFilter | null;
  some?: FileCabinetPoolRecipientFilter | null;
  none?: FileCabinetPoolRecipientFilter | null;
}

export interface DocumentUploadInput {
  filename: string;
  documentTypeId: number;
  description?: string | null;
  comment?: string | null;
  indexName?: string | null;
  indexDescription?: string | null;
  indexReferenceNumber?: string | null;
  indexTransactionDate?: GqlDate | null;
  indexAmount?: number | null;
  indexCurrencyId?: number | null;
  extractionTypeId?: number | null;
}

/**
 * A filter to be used against `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface EmailDocumentFilter {
  id?: UUIDFilter | null;
  emailSender?: StringFilter | null;
  emailRecipient?: StringFilter | null;
  emailCc?: StringFilter | null;
  emailBcc?: StringFilter | null;
  subject?: StringFilter | null;
  body?: StringFilter | null;
  _emailRoutingDate?: DatetimeFilter | null;
  emailRouteId?: UUIDFilter | null;
  documentPoolId?: UUIDFilter | null;
  _emailTypeId?: IntFilter | null;
  _emailType?: StringFilter | null;
  companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  documentFileId?: UUIDFilter | null;
  entityDocumentFileId?: UUIDFilter | null;
  emailSenderName?: StringFilter | null;
  replyTo?: StringFilter | null;
  replyToName?: StringFilter | null;
  documentAccessExpiry?: IntFilter | null;
  companyProjectInfo?: StringFilter | null;
  transactionBodyLine1?: StringFilter | null;
  transactionBodyLine2?: StringFilter | null;
  productionOfficeInfo?: StringFilter | null;
  documentTypeId?: IntFilter | null;
  documentReportType?: ReportTypesFilter | null;
  isOutgoingEmail?: BooleanFilter | null;
  _isEmailDocumentExists?: BooleanFilter | null;
  _isEmailExists?: BooleanFilter | null;
  _isEmailCreateAllowed?: BooleanFilter | null;
  _isEmailFreeFormAllowed?: BooleanFilter | null;
  sendStatusTypeId?: IntFilter | null;
  sendStatusType?: StringFilter | null;
  sendNotificationTypeId?: IntFilter | null;
  sendLayoutType?: TransactionLayoutFilter | null;
  _recipientList?: StringFilter | null;
  _currentSupplier?: StringFilter | null;
  _isSupplierEmailAllowed?: BooleanFilter | null;
  _isTravelAgencyEmail?: BooleanFilter | null;
  _senderList?: StringFilter | null;
  _isSenderListAvailable?: BooleanFilter | null;
  _isTravelPlanEmail?: BooleanFilter | null;
  transactionBodyLine3?: StringFilter | null;
  transactionBodyLine4?: StringFilter | null;
  transactionBodyLine5?: StringFilter | null;
  transactionIntro?: StringFilter | null;
  transactionDisclaimer?: StringFilter | null;
  _sendIconState?: IconStateFilter | null;
  requestedByUserId?: UUIDFilter | null;
  dateSent?: DatetimeFilter | null;
  emailMemo?: StringFilter | null;
  isUserSenderIncluded?: BooleanFilter | null;
  _isUserSenderAllowed?: BooleanFilter | null;
  emailFiles?: EmailDocumentToManyEmailFileFilter | null;
  emailFilesExist?: boolean | null;
  emailRecipients?: EmailDocumentToManyEmailRecipientFilter | null;
  emailRecipientsExist?: boolean | null;
  invoiceEmail?: InvoiceFilter | null;
  invoiceEmailExists?: boolean | null;
  purchaseOrderEmail?: PurchaseOrderFilter | null;
  purchaseOrderEmailExists?: boolean | null;
  travelAuthorizationEmail?: TravelAuthorizationFilter | null;
  travelAuthorizationEmailExists?: boolean | null;
  movementTravelerEmail?: MovementTravelerFilter | null;
  movementTravelerEmailExists?: boolean | null;
  paymentEmail?: PaymentFilter | null;
  paymentEmailExists?: boolean | null;
  entityDocumentEmail?: EntityDocumentFilter | null;
  entityDocumentEmailExists?: boolean | null;
  requestedByUser?: UserFilter | null;
  requestedByUserExists?: boolean | null;
  and?: EmailDocumentFilter[] | null;
  or?: EmailDocumentFilter[] | null;
  not?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `EmailFile` object types. All fields are combined with a logical ‘and.’
 */
export interface EmailDocumentToManyEmailFileFilter {
  every?: EmailFileFilter | null;
  some?: EmailFileFilter | null;
  none?: EmailFileFilter | null;
}

/**
 * A filter to be used against many `EmailRecipient` object types. All fields are combined with a logical ‘and.’
 */
export interface EmailDocumentToManyEmailRecipientFilter {
  every?: EmailRecipientFilter | null;
  some?: EmailRecipientFilter | null;
  none?: EmailRecipientFilter | null;
}

/**
 * A filter to be used against `EmailFile` object types. All fields are combined with a logical ‘and.’
 */
export interface EmailFileFilter {
  id?: UUIDFilter | null;
  emailDocumentId?: UUIDFilter | null;
  entityDocumentId?: UUIDFilter | null;
  documentFileId?: UUIDFilter | null;
  documentTypeId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  emailDocument?: EmailDocumentFilter | null;
  emailDocumentExists?: boolean | null;
  and?: EmailFileFilter[] | null;
  or?: EmailFileFilter[] | null;
  not?: EmailFileFilter | null;
}

/**
 * A filter to be used against `EmailRecipient` object types. All fields are combined with a logical ‘and.’
 */
export interface EmailRecipientFilter {
  id?: UUIDFilter | null;
  emailDocumentId?: UUIDFilter | null;
  recipientUserId?: UUIDFilter | null;
  recipientEntityId?: UUIDFilter | null;
  recipientEntityTypeId?: IntFilter | null;
  recipientEntityItemId?: UUIDFilter | null;
  recipientEmailAccount?: StringFilter | null;
  isMainRecipient?: BooleanFilter | null;
  isBccRecipient?: BooleanFilter | null;
  isManualEmail?: BooleanFilter | null;
  isDelivered?: BooleanFilter | null;
  isFailed?: BooleanFilter | null;
  resentByUserId?: UUIDFilter | null;
  isResent?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  statusTypeId?: IntFilter | null;
  statusType?: StringFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _recipientOrder?: IntFilter | null;
  _recipientDescription?: StringFilter | null;
  _recipientName?: StringFilter | null;
  emailDocument?: EmailDocumentFilter | null;
  emailDocumentExists?: boolean | null;
  and?: EmailRecipientFilter[] | null;
  or?: EmailRecipientFilter[] | null;
  not?: EmailRecipientFilter | null;
}

/**
 * A filter to be used against `EmployeeAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeAddressFilter {
  id?: UUIDFilter | null;
  _employeeId?: UUIDFilter | null;
  addressTypeId?: IntFilter | null;
  addressLine1?: StringFilter | null;
  addressLine2?: StringFilter | null;
  addressLine3?: StringFilter | null;
  addressLine4?: StringFilter | null;
  postalCode?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  emailAddress?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  isPrimary?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  employeeAddressHistoriesByEntityId?: EmployeeAddressToManyEmployeeAddressHistoryFilter | null;
  employeeAddressHistoriesByEntityIdExist?: boolean | null;
  employee?: EmployeeFilter | null;
  employeeExists?: boolean | null;
  addressType?: AddressTypeFilter | null;
  addressTypeExists?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: EmployeeAddressFilter[] | null;
  or?: EmployeeAddressFilter[] | null;
  not?: EmployeeAddressFilter | null;
}

/**
 * A filter to be used against `EmployeeAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeAddressHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  employeeAddress?: EmployeeAddressFilter | null;
  employeeAddressExists?: boolean | null;
  and?: EmployeeAddressHistoryFilter[] | null;
  or?: EmployeeAddressHistoryFilter[] | null;
  not?: EmployeeAddressHistoryFilter | null;
}

/**
 * A filter to be used against many `EmployeeAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeAddressToManyEmployeeAddressHistoryFilter {
  every?: EmployeeAddressHistoryFilter | null;
  some?: EmployeeAddressHistoryFilter | null;
  none?: EmployeeAddressHistoryFilter | null;
}

/**
 * A filter to be used against `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeFilter {
  id?: UUIDFilter | null;
  lastName?: StringFilter | null;
  firstName?: StringFilter | null;
  taxReferenceNumber?: StringFilter | null;
  taxPayerTypeId?: IntFilter | null;
  isVerified?: BooleanFilter | null;
  isNewHire?: BooleanFilter | null;
  alienExpirationDate?: DatetimeFilter | null;
  isAlienExpirationNotApplicable?: BooleanFilter | null;
  alienRegistrationUscisNumber?: StringFilter | null;
  admissionNumber?: StringFilter | null;
  passportNumber?: StringFilter | null;
  passportCountryId?: IntFilter | null;
  guildReportingLastName?: StringFilter | null;
  guildReportingFirstName?: StringFilter | null;
  guildReportingTaxReference?: StringFilter | null;
  isLienEnabled?: BooleanFilter | null;
  maritalStatusTypeId?: IntFilter | null;
  gender?: StringFilter | null;
  birthDate?: DatetimeFilter | null;
  taxCountryId?: IntFilter | null;
  countryExemptions?: IntFilter | null;
  taxStateRegionId?: IntFilter | null;
  stateRegionExemptions?: IntFilter | null;
  taxCityAreaId?: IntFilter | null;
  cityAreaExemptions?: IntFilter | null;
  isPayCardEnabled?: BooleanFilter | null;
  isDirectDepositEnabled?: BooleanFilter | null;
  citizenType?: CitizenTypeFilter | null;
  statusTypeId?: IntFilter | null;
  _companyId?: UUIDFilter | null;
  _businessUnitId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _dummyStatusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isRatingsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  entityTagsByEntityId?: EmployeeToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: EmployeeToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: EmployeeToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  ratingsByEntityId?: EmployeeToManyRatingFilter | null;
  ratingsByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: EmployeeToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: EmployeeToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  employeeHistoriesByEntityId?: EmployeeToManyEmployeeHistoryFilter | null;
  employeeHistoriesByEntityIdExist?: boolean | null;
  employeeAddressesByEmployeeId?: EmployeeToManyEmployeeAddressFilter | null;
  employeeAddressesByEmployeeIdExist?: boolean | null;
  taxPayerType?: TaxPayerTypeFilter | null;
  taxPayerTypeExists?: boolean | null;
  passportCountry?: CountryFilter | null;
  passportCountryExists?: boolean | null;
  maritalStatusType?: MaritalStatusTypeFilter | null;
  maritalStatusTypeExists?: boolean | null;
  taxCountry?: CountryFilter | null;
  taxCountryExists?: boolean | null;
  taxStateRegion?: StateRegionFilter | null;
  taxStateRegionExists?: boolean | null;
  taxCityArea?: CityAreaFilter | null;
  taxCityAreaExists?: boolean | null;
  employeeRatingResults?: RatingEntityResultFilter | null;
  employeeRatingResultsExists?: boolean | null;
  employeeSecureRowLevels?: SecureRowLevelFilter | null;
  employeeSecureRowLevelsExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  and?: EmployeeFilter[] | null;
  or?: EmployeeFilter[] | null;
  not?: EmployeeFilter | null;
}

/**
 * A filter to be used against `EmployeeHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  employee?: EmployeeFilter | null;
  employeeExists?: boolean | null;
  and?: EmployeeHistoryFilter[] | null;
  or?: EmployeeHistoryFilter[] | null;
  not?: EmployeeHistoryFilter | null;
}

/**
 * A filter to be used against many `EmployeeAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyEmployeeAddressFilter {
  every?: EmployeeAddressFilter | null;
  some?: EmployeeAddressFilter | null;
  none?: EmployeeAddressFilter | null;
}

/**
 * A filter to be used against many `EmployeeHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyEmployeeHistoryFilter {
  every?: EmployeeHistoryFilter | null;
  some?: EmployeeHistoryFilter | null;
  none?: EmployeeHistoryFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `Rating` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyRatingFilter {
  every?: RatingFilter | null;
  some?: RatingFilter | null;
  none?: RatingFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface EmployeeToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * An input for mutations affecting `EntityDelete`
 */
export interface EntityDeleteInput {
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `entityDocumentAppliedUpdate` mutation.
 */
export interface EntityDocumentAppliedUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  documentAppliedAmount: GqlBigFloat;
  documentRetiredAmount: GqlBigFloat;
  isAppliedAmountRetired: boolean;
  isAppliedAmountOverage: boolean;
  documentStatusExplanation?: string | null;
}

/**
 * All input for the `entityDocumentDelete` mutation.
 */
export interface EntityDocumentDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `entityDocumentDistributionUpdate` mutation.
 */
export interface EntityDocumentDistributionUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  entityDocumentPatch?: EntityDocumentPatch | null;
  documentDistributionsCreate?: (DocumentFileDistributionInput | null)[] | null;
  documentDistributionsUpdate?: (DocumentFileDistributionUpdateTypeInput | null)[] | null;
  documentDistributionsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `entityDocumentEmailCreate` mutation.
 */
export interface EntityDocumentEmailCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  isSupplierEmail?: boolean | null;
  userId?: (GqlUUID | null)[] | null;
  emailAccount?: (string | null)[] | null;
  externalEmail?: string | null;
  memoArea?: string | null;
  isUserSenderIncluded?: boolean | null;
}

/**
 * A filter to be used against `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentFilter {
  id?: UUIDFilter | null;
  _documentFileId?: UUIDFilter | null;
  comment?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  _isSelected?: BooleanFilter | null;
  _isDocumentShare?: BooleanFilter | null;
  _poolEntityDocumentId?: UUIDFilter | null;
  _documentPoolId?: UUIDFilter | null;
  _documentPoolName?: StringFilter | null;
  _emailDocumentId?: UUIDFilter | null;
  _emailTypeId?: IntFilter | null;
  _emailType?: StringFilter | null;
  _emailRouteId?: UUIDFilter | null;
  _directoryTypeId?: IntFilter | null;
  documentTypeId?: IntFilter | null;
  isShareable?: BooleanFilter | null;
  fileReference?: StringFilter | null;
  fileIndexInformation?: StringFilter | null;
  _outputFileName?: StringFilter | null;
  _signedExecutedDate?: DatetimeFilter | null;
  _fileName?: StringFilter | null;
  _fileTypeId?: IntFilter | null;
  _fileType?: StringFilter | null;
  _cdnThumbnailGuid?: StringFilter | null;
  _fileViewer?: StringFilter | null;
  _fileSize?: BigFloatFilter | null;
  _isThumbnailCreated?: BooleanFilter | null;
  _isLoading?: BooleanFilter | null;
  _isLoadFailed?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  _recipientUserId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _createdByUserName?: StringFilter | null;
  _overridingLanguageId?: IntFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _fullyQualifiedDocumentName?: StringFilter | null;
  iconType?: FileIconTypeFilter | null;
  _uploadedByUserid?: UUIDFilter | null;
  _uploadedByUser?: StringFilter | null;
  _isPersonalPoolOrigin?: BooleanFilter | null;
  _isPersonalPoolRecipient?: BooleanFilter | null;
  entityDocumentTypeId?: IntFilter | null;
  _isReportingDocument?: BooleanFilter | null;
  _sharedByUserId?: UUIDFilter | null;
  _sharedByUser?: StringFilter | null;
  _uploadDate?: DatetimeFilter | null;
  indexName?: StringFilter | null;
  indexDescription?: StringFilter | null;
  indexReferenceNumber?: StringFilter | null;
  indexTransactionDate?: DateFilter | null;
  indexAmount?: BigFloatFilter | null;
  indexCurrencyId?: IntFilter | null;
  documentAppliedAmount?: BigFloatFilter | null;
  documentRetiredAmount?: BigFloatFilter | null;
  documentOverageAmount?: BigFloatFilter | null;
  documentStatusTypeId?: IntFilter | null;
  documentStatusExplanation?: StringFilter | null;
  _documentContents?: StringFilter | null;
  _isAccountingDocument?: BooleanFilter | null;
  _isDocumentAmountAvailable?: BooleanFilter | null;
  _isDocumentAmountApplied?: BooleanFilter | null;
  _isDocumentTypeUpdatable?: BooleanFilter | null;
  isAppliedAmountRetired?: BooleanFilter | null;
  isAppliedAmountOverage?: BooleanFilter | null;
  _lookupNameId?: UUIDFilter | null;
  indexBankConfirmation?: StringFilter | null;
  indexBankName?: StringFilter | null;
  indexBankIbanCode?: StringFilter | null;
  indexBankRoutingNumber?: StringFilter | null;
  indexBankSortCode?: StringFilter | null;
  indexBankAccountNumber?: StringFilter | null;
  indexBankSwiftCode?: StringFilter | null;
  indexBankAccountName?: StringFilter | null;
  extractionTypeId?: IntFilter | null;
  _isIndexExtractedInformation?: BooleanFilter | null;
  _isDocumentAmountAccumulated?: BooleanFilter | null;
  cardAccountId?: UUIDFilter | null;
  cardHolderId?: UUIDFilter | null;
  _isDocumentReceiptAccumulated?: BooleanFilter | null;
  _isFolderStatusActiveTransactions?: BooleanFilter | null;
  _isFolderStatusEnteredTransactions?: BooleanFilter | null;
  _isFolderStatusActivePayments?: BooleanFilter | null;
  _isFolderStatusEnteredPayments?: BooleanFilter | null;
  _isFolderStatusAvailableDocuments?: BooleanFilter | null;
  documentPosition?: IntFilter | null;
  verifiedUserId?: UUIDFilter | null;
  _verifiedUserName?: StringFilter | null;
  _isVerified?: BooleanFilter | null;
  _documentType?: StringFilter | null;
  _exportProcessHistoryId?: UUIDFilter | null;
  _exportDate?: DatetimeFilter | null;
  _sortExportDate?: StringFilter | null;
  _exportedByUser?: StringFilter | null;
  indexTaxCode1?: StringFilter | null;
  indexTaxCode2?: StringFilter | null;
  indexTaxCode3?: StringFilter | null;
  indexTaxCode4?: StringFilter | null;
  indexTaxAmount1?: BigFloatFilter | null;
  indexTaxAmount2?: BigFloatFilter | null;
  indexTaxAmount3?: BigFloatFilter | null;
  indexTaxAmount4?: BigFloatFilter | null;
  indexTaxAccount1?: StringFilter | null;
  indexTaxAccount2?: StringFilter | null;
  indexTaxAccount3?: StringFilter | null;
  indexTaxAccount4?: StringFilter | null;
  _entryLayout?: TransactionLayoutFilter | null;
  _isDocumentDistributionAttachable?: BooleanFilter | null;
  _poAllocationTotal?: BigFloatFilter | null;
  _protectedWithAccountingUpdate?: BooleanFilter | null;
  _resetAccountingAllowed?: BooleanFilter | null;
  _retirePrivilege?: BooleanFilter | null;
  _revisePrivilege?: BooleanFilter | null;
  _transactionHistoryId?: UUIDFilter | null;
  _unretirePrivilege?: BooleanFilter | null;
  approvalHistorySignatures?: EntityDocumentToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesExist?: boolean | null;
  approvalSignatures?: EntityDocumentToManyApprovalSignatureFilter | null;
  approvalSignaturesExist?: boolean | null;
  userProfilesByUserSignatureEntityDocumentId?: EntityDocumentToManyUserProfileFilter | null;
  userProfilesByUserSignatureEntityDocumentIdExist?: boolean | null;
  userEntityDocument?: UserFilter | null;
  userEntityDocumentExists?: boolean | null;
  appliedEntityDocumentsByDocumentFileIdAndDocumentPoolEntityTypeId?: EntityDocumentToManyAppliedEntityDocumentFilter | null;
  appliedEntityDocumentsByDocumentFileIdAndDocumentPoolEntityTypeIdExist?: boolean | null;
  documentFileDistributionsByDocumentFileId?: EntityDocumentToManyDocumentFileDistributionFilter | null;
  documentFileDistributionsByDocumentFileIdExist?: boolean | null;
  documentStorage?: DocumentStorageAccountFilter | null;
  documentStorageExists?: boolean | null;
  emailDocumentsByEntityId?: EntityDocumentToManyEmailDocumentFilter | null;
  emailDocumentsByEntityIdExist?: boolean | null;
  entityDocumentHistoriesByEntityId?: EntityDocumentToManyEntityDocumentHistoryFilter | null;
  entityDocumentHistoriesByEntityIdExist?: boolean | null;
  employeeDocument?: EmployeeFilter | null;
  employeeDocumentExists?: boolean | null;
  vendorDocument?: VendorFilter | null;
  vendorDocumentExists?: boolean | null;
  entityDocumentPool?: DocumentPoolFilter | null;
  entityDocumentPoolExists?: boolean | null;
  entityDocumentSecureRowLevels?: SecureRowLevelFilter | null;
  entityDocumentSecureRowLevelsExists?: boolean | null;
  documentTypes?: DocumentTypeFilter | null;
  documentTypesExists?: boolean | null;
  invoiceDocument?: InvoiceFilter | null;
  invoiceDocumentExists?: boolean | null;
  approvalEntityDocument?: ApprovalHistoryFilter | null;
  approvalEntityDocumentExists?: boolean | null;
  userApprovalDocument?: UserApprovalFilter | null;
  userApprovalDocumentExists?: boolean | null;
  paymentDocument?: PaymentFilter | null;
  paymentDocumentExists?: boolean | null;
  approvalSetupDocument?: ApprovalFilter | null;
  approvalSetupDocumentExists?: boolean | null;
  userProfileDocument?: UserProfileFilter | null;
  userProfileDocumentExists?: boolean | null;
  payCycleDocument?: PayCycleFilter | null;
  payCycleDocumentExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  documentStatusType?: InvoiceDistributionStatusTypeFilter | null;
  documentStatusTypeExists?: boolean | null;
  documentAppliedAmounts?: DocumentAppliedAmountFilter | null;
  documentAppliedAmountsExists?: boolean | null;
  lookupName?: LookupNameFilter | null;
  lookupNameExists?: boolean | null;
  extractionType?: ExtractionTypeFilter | null;
  extractionTypeExists?: boolean | null;
  cardHolder?: CompanyCardHolderFilter | null;
  cardHolderExists?: boolean | null;
  supplierDocument?: SupplierFilter | null;
  supplierDocumentExists?: boolean | null;
  travelerDocument?: TravelerFilter | null;
  travelerDocumentExists?: boolean | null;
  travelAuthorizationDocument?: TravelAuthorizationFilter | null;
  travelAuthorizationDocumentExists?: boolean | null;
  tripDocument?: TripFilter | null;
  tripDocumentExists?: boolean | null;
  batchTransactionDocument?: BatchTransactionFilter | null;
  batchTransactionDocumentExists?: boolean | null;
  fileCabinetPool?: FileCabinetPoolFilter | null;
  fileCabinetPoolExists?: boolean | null;
  purchaseOrderDocument?: PurchaseOrderFilter | null;
  purchaseOrderDocumentExists?: boolean | null;
  and?: EntityDocumentFilter[] | null;
  or?: EntityDocumentFilter[] | null;
  not?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against `EntityDocumentHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  entityDocuments?: EntityDocumentFilter | null;
  entityDocumentsExists?: boolean | null;
  and?: EntityDocumentHistoryFilter[] | null;
  or?: EntityDocumentHistoryFilter[] | null;
  not?: EntityDocumentHistoryFilter | null;
}

/**
 * An input for mutations affecting `EntityDocument`
 */
export interface EntityDocumentInput {
  id: GqlUUID;
  comment?: string | null;
  entityId: GqlUUID;
  fileReference: string;
  fileIndexInformation?: string | null;
  entityDocumentTypeId?: number | null;
  indexName?: string | null;
  indexDescription?: string | null;
  indexReferenceNumber?: string | null;
  indexTransactionDate?: GqlDate | null;
  indexAmount?: GqlBigFloat | null;
  indexCurrencyId?: number | null;
  documentAppliedAmount?: GqlBigFloat | null;
  documentRetiredAmount?: GqlBigFloat | null;
  documentStatusExplanation?: string | null;
  isAppliedAmountRetired?: boolean | null;
  isAppliedAmountOverage?: boolean | null;
  _lookupNameId?: GqlUUID | null;
  indexBankConfirmation?: string | null;
  indexBankName?: string | null;
  indexBankIbanCode?: string | null;
  indexBankRoutingNumber?: string | null;
  indexBankSortCode?: string | null;
  indexBankAccountNumber?: string | null;
  indexBankSwiftCode?: string | null;
  indexBankAccountName?: string | null;
  extractionTypeId?: number | null;
  cardAccountId?: GqlUUID | null;
  cardHolderId?: GqlUUID | null;
  verifiedUserId?: GqlUUID | null;
  indexTaxCode1?: string | null;
  indexTaxCode2?: string | null;
  indexTaxCode3?: string | null;
  indexTaxCode4?: string | null;
  indexTaxAmount1?: GqlBigFloat | null;
  indexTaxAmount2?: GqlBigFloat | null;
  indexTaxAmount3?: GqlBigFloat | null;
  indexTaxAmount4?: GqlBigFloat | null;
  indexTaxAccount1?: string | null;
  indexTaxAccount2?: string | null;
  indexTaxAccount3?: string | null;
  indexTaxAccount4?: string | null;
}

/**
 * Represents an update to a `EntityDocument`. Fields that are set will be updated.
 */
export interface EntityDocumentPatch {
  id?: GqlUUID | null;
  comment?: string | null;
  entityId?: GqlUUID | null;
  entityDocumentTypeId?: number | null;
  indexName?: string | null;
  indexDescription?: string | null;
  indexReferenceNumber?: string | null;
  indexTransactionDate?: GqlDate | null;
  indexAmount?: GqlBigFloat | null;
  indexCurrencyId?: number | null;
  documentAppliedAmount?: GqlBigFloat | null;
  documentRetiredAmount?: GqlBigFloat | null;
  documentStatusExplanation?: string | null;
  _documentContents?: string | null;
  isAppliedAmountRetired?: boolean | null;
  isAppliedAmountOverage?: boolean | null;
  _lookupNameId?: GqlUUID | null;
  indexBankConfirmation?: string | null;
  indexBankName?: string | null;
  indexBankIbanCode?: string | null;
  indexBankRoutingNumber?: string | null;
  indexBankSortCode?: string | null;
  indexBankAccountNumber?: string | null;
  indexBankSwiftCode?: string | null;
  indexBankAccountName?: string | null;
  extractionTypeId?: number | null;
  cardAccountId?: GqlUUID | null;
  cardHolderId?: GqlUUID | null;
  verifiedUserId?: GqlUUID | null;
  indexTaxCode1?: string | null;
  indexTaxCode2?: string | null;
  indexTaxCode3?: string | null;
  indexTaxCode4?: string | null;
  indexTaxAmount1?: GqlBigFloat | null;
  indexTaxAmount2?: GqlBigFloat | null;
  indexTaxAmount3?: GqlBigFloat | null;
  indexTaxAmount4?: GqlBigFloat | null;
  indexTaxAccount1?: string | null;
  indexTaxAccount2?: string | null;
  indexTaxAccount3?: string | null;
  indexTaxAccount4?: string | null;
}

/**
 * All input for the `entityDocumentResetAccountingArea` mutation.
 */
export interface EntityDocumentResetAccountingAreaInput {
  clientMutationId?: string | null;
  entityDocumentId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `entityDocumentRetireBalance` mutation.
 */
export interface EntityDocumentRetireBalanceInput {
  clientMutationId?: string | null;
  entityDocumentId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `entityDocumentRowProtection` mutation.
 */
export interface EntityDocumentRowProtectionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  isProtectionRemoval?: boolean | null;
}

/**
 * All input for the `entityDocumentShareCreate` mutation.
 */
export interface EntityDocumentShareCreateInput {
  clientMutationId?: string | null;
  entityDocument: (GqlUUID | null)[];
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `entityDocumentSingleDelete` mutation.
 */
export interface EntityDocumentSingleDeleteInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * A filter to be used against many `AppliedEntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentToManyAppliedEntityDocumentFilter {
  every?: AppliedEntityDocumentFilter | null;
  some?: AppliedEntityDocumentFilter | null;
  none?: AppliedEntityDocumentFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `DocumentFileDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentToManyDocumentFileDistributionFilter {
  every?: DocumentFileDistributionFilter | null;
  some?: DocumentFileDistributionFilter | null;
  none?: DocumentFileDistributionFilter | null;
}

/**
 * A filter to be used against many `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentToManyEmailDocumentFilter {
  every?: EmailDocumentFilter | null;
  some?: EmailDocumentFilter | null;
  none?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityDocumentHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentToManyEntityDocumentHistoryFilter {
  every?: EntityDocumentHistoryFilter | null;
  some?: EntityDocumentHistoryFilter | null;
  none?: EntityDocumentHistoryFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityDocumentToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * All input for the `entityDocumentUnretireBalance` mutation.
 */
export interface EntityDocumentUnretireBalanceInput {
  clientMutationId?: string | null;
  entityDocumentId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `entityDocumentUpdate` mutation.
 */
export interface EntityDocumentUpdateInput {
  clientMutationId?: string | null;
  entityDocumentsUpdate: (EntityDocumentUpdateTypeInput | null)[];
}

/**
 * An input for mutations affecting `EntityDocumentUpdateType`
 */
export interface EntityDocumentUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  entityDocumentPatch?: EntityDocumentPatch | null;
}

/**
 * All input for the `entityDocumentVerify` mutation.
 */
export interface EntityDocumentVerifyInput {
  clientMutationId?: string | null;
  entitySelection: (EntitySelectionInput | null)[];
}

/**
 * All input for the `entityEnvironmentalDelete` mutation.
 */
export interface EntityEnvironmentalDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `EntityEnvironmental` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityEnvironmentalFilter {
  id?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  environmentalMetricId?: UUIDFilter | null;
  description?: StringFilter | null;
  startDate?: DateFilter | null;
  endDate?: DateFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  statusTypeId?: IntFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  entityEnvironmentalItems?: EntityEnvironmentalToManyEntityEnvironmentalItemFilter | null;
  entityEnvironmentalItemsExist?: boolean | null;
  environmentalMetric?: EnvironmentalMetricFilter | null;
  environmentalMetricExists?: boolean | null;
  invoiceEnvironmental?: InvoiceFilter | null;
  invoiceEnvironmentalExists?: boolean | null;
  approvalHistory?: ApprovalHistoryFilter | null;
  approvalHistoryExists?: boolean | null;
  userApproval?: UserApprovalFilter | null;
  userApprovalExists?: boolean | null;
  purchaseOrderEnvironmental?: PurchaseOrderFilter | null;
  purchaseOrderEnvironmentalExists?: boolean | null;
  tripEnvironmental?: TripFilter | null;
  tripEnvironmentalExists?: boolean | null;
  and?: EntityEnvironmentalFilter[] | null;
  or?: EntityEnvironmentalFilter[] | null;
  not?: EntityEnvironmentalFilter | null;
}

/**
 * An input for mutations affecting `EntityEnvironmental`
 */
export interface EntityEnvironmentalInput {
  entityId: GqlUUID;
  environmentalMetricId: GqlUUID;
  description?: string | null;
  startDate?: GqlDate | null;
  endDate?: GqlDate | null;
}

/**
 * All input for the `entityEnvironmentalInvoiceCreate` mutation.
 */
export interface EntityEnvironmentalInvoiceCreateInput {
  clientMutationId?: string | null;
  entityEnvironmental: EntityEnvironmentalInput;
  entityEnvironmentalItems?: (EntityEnvironmentalItemInput | null)[] | null;
}

/**
 * A filter to be used against `EntityEnvironmentalItem` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityEnvironmentalItemFilter {
  id?: UUIDFilter | null;
  entityEnvironmentalId?: UUIDFilter | null;
  environmentalImpactTypeId?: IntFilter | null;
  quantity?: BigFloatFilter | null;
  gallons?: BigFloatFilter | null;
  litres?: BigFloatFilter | null;
  feet?: BigFloatFilter | null;
  meters?: BigFloatFilter | null;
  yards?: BigFloatFilter | null;
  miles?: BigFloatFilter | null;
  kilometers?: BigFloatFilter | null;
  tons?: BigFloatFilter | null;
  metricTonnes?: BigFloatFilter | null;
  hours?: BigFloatFilter | null;
  kilowattHours?: BigFloatFilter | null;
  pounds?: BigFloatFilter | null;
  kilogram?: BigFloatFilter | null;
  therms?: BigFloatFilter | null;
  omissionsEquivalent?: BigFloatFilter | null;
  carbonCreditsAmount?: BigFloatFilter | null;
  isGenerated?: BooleanFilter | null;
  isReductionTransaction?: BooleanFilter | null;
  isReconciled?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isAmountFormat?: BooleanFilter | null;
  _isIntegerFormat?: BooleanFilter | null;
  _isFloatFormat?: BooleanFilter | null;
  entityEnvironmental?: EntityEnvironmentalFilter | null;
  entityEnvironmentalExists?: boolean | null;
  environmentalImpactType?: EnvironmentalImpactTypeFilter | null;
  environmentalImpactTypeExists?: boolean | null;
  and?: EntityEnvironmentalItemFilter[] | null;
  or?: EntityEnvironmentalItemFilter[] | null;
  not?: EntityEnvironmentalItemFilter | null;
}

/**
 * An input for mutations affecting `EntityEnvironmentalItem`
 */
export interface EntityEnvironmentalItemInput {
  environmentalImpactTypeId: number;
  quantity?: GqlBigFloat | null;
  gallons?: GqlBigFloat | null;
  litres?: GqlBigFloat | null;
  feet?: GqlBigFloat | null;
  meters?: GqlBigFloat | null;
  yards?: GqlBigFloat | null;
  miles?: GqlBigFloat | null;
  kilometers?: GqlBigFloat | null;
  tons?: GqlBigFloat | null;
  metricTonnes?: GqlBigFloat | null;
  hours?: GqlBigFloat | null;
  kilowattHours?: GqlBigFloat | null;
  pounds?: GqlBigFloat | null;
  kilogram?: GqlBigFloat | null;
  therms?: GqlBigFloat | null;
  omissionsEquivalent?: GqlBigFloat | null;
  carbonCreditsAmount?: GqlBigFloat | null;
}

/**
 * Represents an update to a `EntityEnvironmentalItem`. Fields that are set will be updated.
 */
export interface EntityEnvironmentalItemPatch {
  environmentalImpactTypeId?: number | null;
  quantity?: GqlBigFloat | null;
  gallons?: GqlBigFloat | null;
  litres?: GqlBigFloat | null;
  feet?: GqlBigFloat | null;
  meters?: GqlBigFloat | null;
  yards?: GqlBigFloat | null;
  miles?: GqlBigFloat | null;
  kilometers?: GqlBigFloat | null;
  tons?: GqlBigFloat | null;
  metricTonnes?: GqlBigFloat | null;
  hours?: GqlBigFloat | null;
  kilowattHours?: GqlBigFloat | null;
  pounds?: GqlBigFloat | null;
  kilogram?: GqlBigFloat | null;
  therms?: GqlBigFloat | null;
  omissionsEquivalent?: GqlBigFloat | null;
  carbonCreditsAmount?: GqlBigFloat | null;
}

/**
 * An input for mutations affecting `EntityEnvironmentalItemUpdateType`
 */
export interface EntityEnvironmentalItemUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  entityEnvironmentalItemPatch?: EntityEnvironmentalItemPatch | null;
}

/**
 * Represents an update to a `EntityEnvironmental`. Fields that are set will be updated.
 */
export interface EntityEnvironmentalPatch {
  description?: string | null;
  startDate?: GqlDate | null;
  endDate?: GqlDate | null;
}

/**
 * All input for the `entityEnvironmentalPurchaseOrderCreate` mutation.
 */
export interface EntityEnvironmentalPurchaseOrderCreateInput {
  clientMutationId?: string | null;
  entityEnvironmental: EntityEnvironmentalInput;
  entityEnvironmentalItems?: (EntityEnvironmentalItemInput | null)[] | null;
}

/**
 * A filter to be used against many `EntityEnvironmentalItem` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityEnvironmentalToManyEntityEnvironmentalItemFilter {
  every?: EntityEnvironmentalItemFilter | null;
  some?: EntityEnvironmentalItemFilter | null;
  none?: EntityEnvironmentalItemFilter | null;
}

/**
 * All input for the `entityEnvironmentalTripCreate` mutation.
 */
export interface EntityEnvironmentalTripCreateInput {
  clientMutationId?: string | null;
  entityEnvironmental: EntityEnvironmentalInput;
  entityEnvironmentalItems?: (EntityEnvironmentalItemInput | null)[] | null;
}

/**
 * All input for the `entityEnvironmentalUpdate` mutation.
 */
export interface EntityEnvironmentalUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  entityEnvironmentalPatch?: EntityEnvironmentalPatch | null;
  entityEnvironmentalItemsCreate?: (EntityEnvironmentalItemInput | null)[] | null;
  entityEnvironmentalItemsUpdate?: (EntityEnvironmentalItemUpdateTypeInput | null)[] | null;
  entityEnvironmentalItemsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * An input for mutations affecting `EntitySelection`
 */
export interface EntitySelectionInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
}

/**
 * All input for the `entityTagApprovalCreate` mutation.
 */
export interface EntityTagApprovalCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagCardAccountCreate` mutation.
 */
export interface EntityTagCardAccountCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagCardHolderCreate` mutation.
 */
export interface EntityTagCardHolderCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagDelete` mutation.
 */
export interface EntityTagDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface EntityTagFilter {
  id?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  tagId?: UUIDFilter | null;
  _tagBadgeName?: StringFilter | null;
  _isPrivate?: BooleanFilter | null;
  _isMonitored?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  createdByUser?: StringFilter | null;
  _tagBadgeColor?: ColorsFilter | null;
  employeeTag?: EmployeeFilter | null;
  employeeTagExists?: boolean | null;
  vendorTag?: VendorFilter | null;
  vendorTagExists?: boolean | null;
  invoiceTag?: InvoiceFilter | null;
  invoiceTagExists?: boolean | null;
  approvalHistoryTag?: ApprovalHistoryFilter | null;
  approvalHistoryTagExists?: boolean | null;
  userApprovalTag?: UserApprovalFilter | null;
  userApprovalTagExists?: boolean | null;
  cardAccountTag?: CardAccountFilter | null;
  cardAccountTagExists?: boolean | null;
  cardHolderTag?: CardHolderFilter | null;
  cardHolderTagExists?: boolean | null;
  paymentTag?: PaymentFilter | null;
  paymentTagExists?: boolean | null;
  travelerTag?: TravelerFilter | null;
  travelerTagExists?: boolean | null;
  supplierTag?: SupplierFilter | null;
  supplierTagExists?: boolean | null;
  purchaseOrderTag?: PurchaseOrderFilter | null;
  purchaseOrderTagExists?: boolean | null;
  travelAuthorizationTag?: TravelAuthorizationFilter | null;
  travelAuthorizationTagExists?: boolean | null;
  and?: EntityTagFilter[] | null;
  or?: EntityTagFilter[] | null;
  not?: EntityTagFilter | null;
}

/**
 * An input for mutations affecting `EntityTag`
 */
export interface EntityTagInput {
  entityId: GqlUUID;
  tagId: GqlUUID;
}

/**
 * All input for the `entityTagInvoiceSigningCreate` mutation.
 */
export interface EntityTagInvoiceSigningCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagPaymentCreate` mutation.
 */
export interface EntityTagPaymentCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagPurchaseOrderCreate` mutation.
 */
export interface EntityTagPurchaseOrderCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagSupplierCreate` mutation.
 */
export interface EntityTagSupplierCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagTravelAuthorizationCreate` mutation.
 */
export interface EntityTagTravelAuthorizationCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * All input for the `entityTagTravelerCreate` mutation.
 */
export interface EntityTagTravelerCreateInput {
  clientMutationId?: string | null;
  entityTag: (EntityTagInput | null)[];
}

/**
 * A filter to be used against `EnvironmentalImpactType` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalImpactTypeFilter {
  id?: IntFilter | null;
  environmentalImpact?: StringFilter | null;
  description?: StringFilter | null;
  isScope1?: BooleanFilter | null;
  isScope2?: BooleanFilter | null;
  isScope3?: BooleanFilter | null;
  isUpstream?: BooleanFilter | null;
  isDownstream?: BooleanFilter | null;
  isEmissionReportingRequired?: BooleanFilter | null;
  isEmissionBased?: BooleanFilter | null;
  isWaterBased?: BooleanFilter | null;
  isPlantBased?: BooleanFilter | null;
  isAnimalBased?: BooleanFilter | null;
  carbonDioxideEmissionsFactor?: BigFloatFilter | null;
  methaneEmissionsFactor?: BigFloatFilter | null;
  nitrousOxideEmissionsFactor?: BigFloatFilter | null;
  sortOrder?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  isAmountFormat?: BooleanFilter | null;
  isIntegerFormat?: BooleanFilter | null;
  isFloatFormat?: BooleanFilter | null;
  environmentalMetricItems?: EnvironmentalImpactTypeToManyEnvironmentalMetricItemFilter | null;
  environmentalMetricItemsExist?: boolean | null;
  entityEnvironmentalItems?: EnvironmentalImpactTypeToManyEntityEnvironmentalItemFilter | null;
  entityEnvironmentalItemsExist?: boolean | null;
  and?: EnvironmentalImpactTypeFilter[] | null;
  or?: EnvironmentalImpactTypeFilter[] | null;
  not?: EnvironmentalImpactTypeFilter | null;
}

/**
 * A filter to be used against many `EntityEnvironmentalItem` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalImpactTypeToManyEntityEnvironmentalItemFilter {
  every?: EntityEnvironmentalItemFilter | null;
  some?: EntityEnvironmentalItemFilter | null;
  none?: EntityEnvironmentalItemFilter | null;
}

/**
 * A filter to be used against many `EnvironmentalMetricItem` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalImpactTypeToManyEnvironmentalMetricItemFilter {
  every?: EnvironmentalMetricItemFilter | null;
  some?: EnvironmentalMetricItemFilter | null;
  none?: EnvironmentalMetricItemFilter | null;
}

/**
 * A filter to be used against `EnvironmentalMetric` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalMetricFilter {
  id?: UUIDFilter | null;
  environmentalMetric?: StringFilter | null;
  description?: StringFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  cityAreasId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  expenditureTypes?: EnvironmentalMetricToManyExpenditureTypeFilter | null;
  expenditureTypesExist?: boolean | null;
  vendors?: EnvironmentalMetricToManyVendorFilter | null;
  vendorsExist?: boolean | null;
  environmentalMetricItems?: EnvironmentalMetricToManyEnvironmentalMetricItemFilter | null;
  environmentalMetricItemsExist?: boolean | null;
  entityEnvironmentals?: EnvironmentalMetricToManyEntityEnvironmentalFilter | null;
  entityEnvironmentalsExist?: boolean | null;
  suppliers?: EnvironmentalMetricToManySupplierFilter | null;
  suppliersExist?: boolean | null;
  and?: EnvironmentalMetricFilter[] | null;
  or?: EnvironmentalMetricFilter[] | null;
  not?: EnvironmentalMetricFilter | null;
}

/**
 * A filter to be used against `EnvironmentalMetricItem` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalMetricItemFilter {
  id?: UUIDFilter | null;
  environmentalMetricId?: UUIDFilter | null;
  environmentalImpactTypeId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  environmentalMetric?: EnvironmentalMetricFilter | null;
  environmentalMetricExists?: boolean | null;
  environmentalImpact?: EnvironmentalImpactTypeFilter | null;
  environmentalImpactExists?: boolean | null;
  and?: EnvironmentalMetricItemFilter[] | null;
  or?: EnvironmentalMetricItemFilter[] | null;
  not?: EnvironmentalMetricItemFilter | null;
}

/**
 * A filter to be used against many `EntityEnvironmental` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalMetricToManyEntityEnvironmentalFilter {
  every?: EntityEnvironmentalFilter | null;
  some?: EntityEnvironmentalFilter | null;
  none?: EntityEnvironmentalFilter | null;
}

/**
 * A filter to be used against many `EnvironmentalMetricItem` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalMetricToManyEnvironmentalMetricItemFilter {
  every?: EnvironmentalMetricItemFilter | null;
  some?: EnvironmentalMetricItemFilter | null;
  none?: EnvironmentalMetricItemFilter | null;
}

/**
 * A filter to be used against many `ExpenditureType` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalMetricToManyExpenditureTypeFilter {
  every?: ExpenditureTypeFilter | null;
  some?: ExpenditureTypeFilter | null;
  none?: ExpenditureTypeFilter | null;
}

/**
 * A filter to be used against many `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalMetricToManySupplierFilter {
  every?: SupplierFilter | null;
  some?: SupplierFilter | null;
  none?: SupplierFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface EnvironmentalMetricToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * A filter to be used against `ExchangeRateType` object types. All fields are combined with a logical ‘and.’
 */
export interface ExchangeRateTypeFilter {
  id?: IntFilter | null;
  phaseName?: StringFilter | null;
  approvalBranchesByTransactionAmountConversionTypeId?: ExchangeRateTypeToManyApprovalBranchFilter | null;
  approvalBranchesByTransactionAmountConversionTypeIdExist?: boolean | null;
  approvalSelectionConditionsByAmountConversionTypeId?: ExchangeRateTypeToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByAmountConversionTypeIdExist?: boolean | null;
  approvalConditionExchangeRateType?: ApprovalSelectionConditionFilter | null;
  approvalConditionExchangeRateTypeExists?: boolean | null;
  and?: ExchangeRateTypeFilter[] | null;
  or?: ExchangeRateTypeFilter[] | null;
  not?: ExchangeRateTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface ExchangeRateTypeToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface ExchangeRateTypeToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against `ExpenditureType` object types. All fields are combined with a logical ‘and.’
 */
export interface ExpenditureTypeFilter {
  id?: UUIDFilter | null;
  expenditureType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  unitOfMeasureGroupId?: UUIDFilter | null;
  incentiveTypeId?: IntFilter | null;
  environmentalMetricId?: UUIDFilter | null;
  isDefaultExpenditure?: BooleanFilter | null;
  isPurchaseOrderSelectable?: BooleanFilter | null;
  isGoodsReceiptSelectable?: BooleanFilter | null;
  isInvoiceSelectable?: BooleanFilter | null;
  isTravelAuthorizationSelectable?: BooleanFilter | null;
  isDeliveryAddressAllowed?: BooleanFilter | null;
  isGoodsReceivedExpected?: BooleanFilter | null;
  isTax1099Allowed?: BooleanFilter | null;
  isAgreementRequired?: BooleanFilter | null;
  isAssetAllowed?: BooleanFilter | null;
  isQuantityRequired?: BooleanFilter | null;
  isShippingExpenditure?: BooleanFilter | null;
  isTaxExpenditure?: BooleanFilter | null;
  isTaxable?: BooleanFilter | null;
  isAccountExpenditureSpecific?: BooleanFilter | null;
  isVendorExpenditureSpecific?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  isTravelPolicySelectable?: BooleanFilter | null;
  vendorServiceTypeId?: IntFilter | null;
  isBooking?: BooleanFilter | null;
  isPlanning?: BooleanFilter | null;
  isDistribution?: BooleanFilter | null;
  travelSectionId?: IntFilter | null;
  parentExpenditureTypeId?: UUIDFilter | null;
  isDeparture?: BooleanFilter | null;
  isReturn?: BooleanFilter | null;
  maximumEntries?: IntFilter | null;
  isRequired?: BooleanFilter | null;
  isFee?: BooleanFilter | null;
  purchaseOrderItems?: ExpenditureTypeToManyPurchaseOrderItemFilter | null;
  purchaseOrderItemsExist?: boolean | null;
  unitOfMeasureGroup?: UnitOfMeasureGroupFilter | null;
  unitOfMeasureGroupExists?: boolean | null;
  incentiveType?: IncentiveTypeFilter | null;
  incentiveTypeExists?: boolean | null;
  environmentalMetric?: EnvironmentalMetricFilter | null;
  environmentalMetricExists?: boolean | null;
  vendorServiceType?: VendorServiceTypeFilter | null;
  vendorServiceTypeExists?: boolean | null;
  and?: ExpenditureTypeFilter[] | null;
  or?: ExpenditureTypeFilter[] | null;
  not?: ExpenditureTypeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItem` object types. All fields are combined with a logical ‘and.’
 */
export interface ExpenditureTypeToManyPurchaseOrderItemFilter {
  every?: PurchaseOrderItemFilter | null;
  some?: PurchaseOrderItemFilter | null;
  none?: PurchaseOrderItemFilter | null;
}

export interface ExportDocumentsInput {
  clientMutationId?: string | null;
  documentIds: GqlUUID[];
}

/**
 * A filter to be used against `ExtendedMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtendedMessageFilter {
  id?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  messageSections?: ExtendedMessageToManyMessageSectionFilter | null;
  messageSectionsExist?: boolean | null;
  mobileUserMessages?: ExtendedMessageToManyMobileUserMessageFilter | null;
  mobileUserMessagesExist?: boolean | null;
  mobileUserNotifications?: ExtendedMessageToManyMobileUserNotificationFilter | null;
  mobileUserNotificationsExist?: boolean | null;
  userMessageDetails?: ExtendedMessageToManyUserMessageDetailFilter | null;
  userMessageDetailsExist?: boolean | null;
  userMessages?: ExtendedMessageToManyUserMessageFilter | null;
  userMessagesExist?: boolean | null;
  userNotifications?: ExtendedMessageToManyUserNotificationFilter | null;
  userNotificationsExist?: boolean | null;
  and?: ExtendedMessageFilter[] | null;
  or?: ExtendedMessageFilter[] | null;
  not?: ExtendedMessageFilter | null;
}

/**
 * A filter to be used against many `MessageSection` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtendedMessageToManyMessageSectionFilter {
  every?: MessageSectionFilter | null;
  some?: MessageSectionFilter | null;
  none?: MessageSectionFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtendedMessageToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `MobileUserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtendedMessageToManyMobileUserNotificationFilter {
  every?: MobileUserNotificationFilter | null;
  some?: MobileUserNotificationFilter | null;
  none?: MobileUserNotificationFilter | null;
}

/**
 * A filter to be used against many `UserMessageDetail` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtendedMessageToManyUserMessageDetailFilter {
  every?: UserMessageDetailFilter | null;
  some?: UserMessageDetailFilter | null;
  none?: UserMessageDetailFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtendedMessageToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtendedMessageToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * A filter to be used against ExtractionLayout fields. All fields are combined with a logical ‘and.’
 */
export interface ExtractionLayoutFilter {
  isNull?: boolean | null;
  equalTo?: ExtractionLayout | null;
  notEqualTo?: ExtractionLayout | null;
  distinctFrom?: ExtractionLayout | null;
  notDistinctFrom?: ExtractionLayout | null;
  in?: ExtractionLayout[] | null;
  notIn?: ExtractionLayout[] | null;
  lessThan?: ExtractionLayout | null;
  lessThanOrEqualTo?: ExtractionLayout | null;
  greaterThan?: ExtractionLayout | null;
  greaterThanOrEqualTo?: ExtractionLayout | null;
}

/**
 * A filter to be used against `ExtractionType` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtractionTypeFilter {
  id?: IntFilter | null;
  extractionType?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isDocumentExtractionModel?: BooleanFilter | null;
  isExportDataModel?: BooleanFilter | null;
  isPdfDocument?: BooleanFilter | null;
  isImageDocument?: BooleanFilter | null;
  isExcelDocument?: BooleanFilter | null;
  isRawTextDocument?: BooleanFilter | null;
  isCsvTextDocument?: BooleanFilter | null;
  isTabTextDocument?: BooleanFilter | null;
  isJsonTextDocument?: BooleanFilter | null;
  isHtmlTextDocument?: BooleanFilter | null;
  excelVersion?: StringFilter | null;
  extractionLayoutType?: ExtractionLayoutFilter | null;
  isExtractProtected?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  documentTypeId?: IntFilter | null;
  currencyId?: IntFilter | null;
  isSupplierCodeUsed?: BooleanFilter | null;
  isNonPoDistributionExtracted?: BooleanFilter | null;
  isDistributionExtracted?: BooleanFilter | null;
  transferCycles?: ExtractionTypeToManyTransferCycleFilter | null;
  transferCyclesExist?: boolean | null;
  documentPools?: ExtractionTypeToManyDocumentPoolFilter | null;
  documentPoolsExist?: boolean | null;
  entityDocuments?: ExtractionTypeToManyEntityDocumentFilter | null;
  entityDocumentsExist?: boolean | null;
  documentType?: DocumentTypeFilter | null;
  documentTypeExists?: boolean | null;
  and?: ExtractionTypeFilter[] | null;
  or?: ExtractionTypeFilter[] | null;
  not?: ExtractionTypeFilter | null;
}

/**
 * A filter to be used against many `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtractionTypeToManyDocumentPoolFilter {
  every?: DocumentPoolFilter | null;
  some?: DocumentPoolFilter | null;
  none?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtractionTypeToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `TransferCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface ExtractionTypeToManyTransferCycleFilter {
  every?: TransferCycleFilter | null;
  some?: TransferCycleFilter | null;
  none?: TransferCycleFilter | null;
}

/**
 * A filter to be used against `FileCabinetDocumentType` object types. All fields are combined with a logical ‘and.’
 */
export interface FileCabinetDocumentTypeFilter {
  id?: IntFilter | null;
  documentType?: StringFilter | null;
  isSigningRequired?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  isReportingDocument?: BooleanFilter | null;
  _isDefault?: BooleanFilter | null;
  isAccountingDocument?: BooleanFilter | null;
  _isAppliableDocumentType?: BooleanFilter | null;
  transactionTypeId?: IntFilter | null;
  _isDocumentDistributionAllowed?: BooleanFilter | null;
  transactionType?: CompanyTransactionTypeFilter | null;
  transactionTypeExists?: boolean | null;
  and?: FileCabinetDocumentTypeFilter[] | null;
  or?: FileCabinetDocumentTypeFilter[] | null;
  not?: FileCabinetDocumentTypeFilter | null;
}

/**
 * A filter to be used against `FileCabinetPool` object types. All fields are combined with a logical ‘and.’
 */
export interface FileCabinetPoolFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  defaultDirectoryTypeId?: IntFilter | null;
  defaultDocumentTypeId?: IntFilter | null;
  defaultEntityTypeId?: IntFilter | null;
  defaultBusinessUnitId?: UUIDFilter | null;
  defaultDepartmentId?: UUIDFilter | null;
  isShareableDefault?: BooleanFilter | null;
  defaultRowSecurityId?: UUIDFilter | null;
  isRetainedWithSelection?: BooleanFilter | null;
  isCreateCopyWithSelection?: BooleanFilter | null;
  isCompanySelector?: BooleanFilter | null;
  isBusinessUnitSelector?: BooleanFilter | null;
  isDepartmentSelector?: BooleanFilter | null;
  isEntityTypeSelector?: BooleanFilter | null;
  isDocumentTypeSelector?: BooleanFilter | null;
  isPrivatePool?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _entityTypeId?: IntFilter | null;
  extractionTypeId?: IntFilter | null;
  entityDocumentsByDocumentPoolIdAndEntityTypeId?: FileCabinetPoolToManyEntityDocumentFilter | null;
  entityDocumentsByDocumentPoolIdAndEntityTypeIdExist?: boolean | null;
  fileCabinetPoolTotals?: DocumentPoolDocumentTotalFilter | null;
  fileCabinetPoolTotalsExists?: boolean | null;
  and?: FileCabinetPoolFilter[] | null;
  or?: FileCabinetPoolFilter[] | null;
  not?: FileCabinetPoolFilter | null;
}

/**
 * A filter to be used against `FileCabinetPoolRecipient` object types. All fields are combined with a logical ‘and.’
 */
export interface FileCabinetPoolRecipientFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  defaultDirectoryTypeId?: IntFilter | null;
  defaultDocumentTypeId?: IntFilter | null;
  defaultEntityTypeId?: IntFilter | null;
  defaultBusinessUnitId?: UUIDFilter | null;
  defaultDepartmentId?: UUIDFilter | null;
  isShareableDefault?: BooleanFilter | null;
  defaultRowSecurityId?: UUIDFilter | null;
  isRetainedWithSelection?: BooleanFilter | null;
  isCreateCopyWithSelection?: BooleanFilter | null;
  isCompanySelector?: BooleanFilter | null;
  isBusinessUnitSelector?: BooleanFilter | null;
  isDepartmentSelector?: BooleanFilter | null;
  isEntityTypeSelector?: BooleanFilter | null;
  isDocumentTypeSelector?: BooleanFilter | null;
  isPrivatePool?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _entityTypeId?: IntFilter | null;
  extractionTypeId?: IntFilter | null;
  fileCabinetRecipientDefaultDocumentTypes?: DocumentTypeFilter | null;
  fileCabinetRecipientDefaultDocumentTypesExists?: boolean | null;
  fileCabinetRecipientPoolTotals?: DocumentPoolDocumentTotalFilter | null;
  fileCabinetRecipientPoolTotalsExists?: boolean | null;
  and?: FileCabinetPoolRecipientFilter[] | null;
  or?: FileCabinetPoolRecipientFilter[] | null;
  not?: FileCabinetPoolRecipientFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface FileCabinetPoolToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against FileIconType fields. All fields are combined with a logical ‘and.’
 */
export interface FileIconTypeFilter {
  isNull?: boolean | null;
  equalTo?: FileIconType | null;
  notEqualTo?: FileIconType | null;
  distinctFrom?: FileIconType | null;
  notDistinctFrom?: FileIconType | null;
  in?: FileIconType[] | null;
  notIn?: FileIconType[] | null;
  lessThan?: FileIconType | null;
  lessThanOrEqualTo?: FileIconType | null;
  greaterThan?: FileIconType | null;
  greaterThanOrEqualTo?: FileIconType | null;
}

/**
 * A filter to be used against `FormatType` object types. All fields are combined with a logical ‘and.’
 */
export interface FormatTypeFilter {
  id?: IntFilter | null;
  description?: StringFilter | null;
  formatType?: StringFilter | null;
  isDateFormat?: BooleanFilter | null;
  isDateTimeFormat?: BooleanFilter | null;
  isTimeFormat?: BooleanFilter | null;
  isCurrencyFormat?: BooleanFilter | null;
  isIntegerFormat?: BooleanFilter | null;
  isNumberFormat?: BooleanFilter | null;
  isPercentageFormat?: BooleanFilter | null;
  isDomainFormat?: BooleanFilter | null;
  isAddressFormat?: BooleanFilter | null;
  isAddressApiFormat?: BooleanFilter | null;
  isDefault?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  countriesByAddressFormatTypeId?: FormatTypeToManyCountryFilter | null;
  countriesByAddressFormatTypeIdExist?: boolean | null;
  countriesByDateFormatTypeId?: FormatTypeToManyCountryFilter | null;
  countriesByDateFormatTypeIdExist?: boolean | null;
  and?: FormatTypeFilter[] | null;
  or?: FormatTypeFilter[] | null;
  not?: FormatTypeFilter | null;
}

/**
 * A filter to be used against many `Country` object types. All fields are combined with a logical ‘and.’
 */
export interface FormatTypeToManyCountryFilter {
  every?: CountryFilter | null;
  some?: CountryFilter | null;
  none?: CountryFilter | null;
}

export interface GenerateIntegrationInput {
  clientMutationId?: string | null;
  entitySelection: EntitySelectionInput[];
}

/**
 * All input for the `generateMovementOrders` mutation.
 */
export interface GenerateMovementOrdersInput {
  clientMutationId?: string | null;
  travelAuthorizationId: GqlUUID;
  movementTravelers?: (GqlUUID | null)[] | null;
}

/**
 * A filter to be used against `GlobalHint` object types. All fields are combined with a logical ‘and.’
 */
export interface GlobalHintFilter {
  id?: IntFilter | null;
  caption?: StringFilter | null;
  linkType?: AppRouterFilter | null;
  linkEntityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  isWebLink?: BooleanFilter | null;
  isMobileLink?: BooleanFilter | null;
  userAppsByLinkTypeId?: GlobalHintToManyUserAppFilter | null;
  userAppsByLinkTypeIdExist?: boolean | null;
  and?: GlobalHintFilter[] | null;
  or?: GlobalHintFilter[] | null;
  not?: GlobalHintFilter | null;
}

/**
 * A filter to be used against many `UserApp` object types. All fields are combined with a logical ‘and.’
 */
export interface GlobalHintToManyUserAppFilter {
  every?: UserAppFilter | null;
  some?: UserAppFilter | null;
  none?: UserAppFilter | null;
}

/**
 * A filter to be used against IconState fields. All fields are combined with a logical ‘and.’
 */
export interface IconStateFilter {
  isNull?: boolean | null;
  equalTo?: IconState | null;
  notEqualTo?: IconState | null;
  distinctFrom?: IconState | null;
  notDistinctFrom?: IconState | null;
  in?: IconState[] | null;
  notIn?: IconState[] | null;
  lessThan?: IconState | null;
  lessThanOrEqualTo?: IconState | null;
  greaterThan?: IconState | null;
  greaterThanOrEqualTo?: IconState | null;
}

/**
 * A filter to be used against `IconType` object types. All fields are combined with a logical ‘and.’
 */
export interface IconTypeFilter {
  id?: IntFilter | null;
  iconType?: StringFilter | null;
  isApprovalIcon?: BooleanFilter | null;
  isApprovalActionAvailable?: BooleanFilter | null;
  isApprovalRevoked?: BooleanFilter | null;
  iconState?: IconStateFilter | null;
  approvalHistories?: IconTypeToManyApprovalHistoryFilter | null;
  approvalHistoriesExist?: boolean | null;
  userApprovals?: IconTypeToManyUserApprovalFilter | null;
  userApprovalsExist?: boolean | null;
  and?: IconTypeFilter[] | null;
  or?: IconTypeFilter[] | null;
  not?: IconTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface IconTypeToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface IconTypeToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against `IncentiveType` object types. All fields are combined with a logical ‘and.’
 */
export interface IncentiveTypeFilter {
  id?: IntFilter | null;
  incentiveType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isSelectableOnTransactions?: BooleanFilter | null;
  isTaxCredit?: BooleanFilter | null;
  isRebate?: BooleanFilter | null;
  isTransferable?: BooleanFilter | null;
  isRefundable?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  expenditureTypes?: IncentiveTypeToManyExpenditureTypeFilter | null;
  expenditureTypesExist?: boolean | null;
  productionIncentives?: IncentiveTypeToManyProductionIncentiveFilter | null;
  productionIncentivesExist?: boolean | null;
  and?: IncentiveTypeFilter[] | null;
  or?: IncentiveTypeFilter[] | null;
  not?: IncentiveTypeFilter | null;
}

/**
 * A filter to be used against many `ExpenditureType` object types. All fields are combined with a logical ‘and.’
 */
export interface IncentiveTypeToManyExpenditureTypeFilter {
  every?: ExpenditureTypeFilter | null;
  some?: ExpenditureTypeFilter | null;
  none?: ExpenditureTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentive` object types. All fields are combined with a logical ‘and.’
 */
export interface IncentiveTypeToManyProductionIncentiveFilter {
  every?: ProductionIncentiveFilter | null;
  some?: ProductionIncentiveFilter | null;
  none?: ProductionIncentiveFilter | null;
}

/**
 * A filter to be used against Int fields. All fields are combined with a logical ‘and.’
 */
export interface IntFilter {
  isNull?: boolean | null;
  equalTo?: number | null;
  notEqualTo?: number | null;
  distinctFrom?: number | null;
  notDistinctFrom?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
}

/**
 * All input for the `invoiceBatchCreate` mutation.
 */
export interface InvoiceBatchCreateInput {
  clientMutationId?: string | null;
  batchTransactionId: GqlUUID;
  invoice: (EntityDeleteInput | null)[];
}

/**
 * All input for the `invoiceBatchDelete` mutation.
 */
export interface InvoiceBatchDeleteInput {
  clientMutationId?: string | null;
  batchTransactionId: GqlUUID;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `InvoiceBatch` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceBatchFilter {
  id?: UUIDFilter | null;
  batchTransactionId?: UUIDFilter | null;
  invoiceId?: UUIDFilter | null;
  appliedAmount?: BigFloatFilter | null;
  isVerified?: BooleanFilter | null;
  comment?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  transactionTypeId?: IntFilter | null;
  _isVerifiable?: BooleanFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _accountingStampUser?: StringFilter | null;
  _sortAccountingReference?: StringFilter | null;
  approvalHistoryItemsByEntityItemId?: InvoiceBatchToManyApprovalHistoryItemFilter | null;
  approvalHistoryItemsByEntityItemIdExist?: boolean | null;
  invoiceBatchHistoriesByEntityId?: InvoiceBatchToManyInvoiceBatchHistoryFilter | null;
  invoiceBatchHistoriesByEntityIdExist?: boolean | null;
  batch?: BatchTransactionFilter | null;
  batchExists?: boolean | null;
  invoice?: InvoiceFilter | null;
  invoiceExists?: boolean | null;
  invoiceBatchTransactionType?: CompanyTransactionTypeFilter | null;
  invoiceBatchTransactionTypeExists?: boolean | null;
  and?: InvoiceBatchFilter[] | null;
  or?: InvoiceBatchFilter[] | null;
  not?: InvoiceBatchFilter | null;
}

/**
 * A filter to be used against `InvoiceBatchHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceBatchHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  invoice_batch?: InvoiceBatchFilter | null;
  invoice_batchExists?: boolean | null;
  and?: InvoiceBatchHistoryFilter[] | null;
  or?: InvoiceBatchHistoryFilter[] | null;
  not?: InvoiceBatchHistoryFilter | null;
}

/**
 * Represents an update to a `InvoiceBatch`. Fields that are set will be updated.
 */
export interface InvoiceBatchPatch {
  isVerified?: boolean | null;
  comment?: string | null;
}

/**
 * A filter to be used against many `ApprovalHistoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceBatchToManyApprovalHistoryItemFilter {
  every?: ApprovalHistoryItemFilter | null;
  some?: ApprovalHistoryItemFilter | null;
  none?: ApprovalHistoryItemFilter | null;
}

/**
 * A filter to be used against many `InvoiceBatchHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceBatchToManyInvoiceBatchHistoryFilter {
  every?: InvoiceBatchHistoryFilter | null;
  some?: InvoiceBatchHistoryFilter | null;
  none?: InvoiceBatchHistoryFilter | null;
}

/**
 * An input for mutations affecting `InvoiceBatchUpdateType`
 */
export interface InvoiceBatchUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  invoiceBatchPatch?: InvoiceBatchPatch | null;
}

/**
 * All input for the `invoiceBatchValidate` mutation.
 */
export interface InvoiceBatchValidateInput {
  clientMutationId?: string | null;
  batchTransactionId: GqlUUID;
  invoiceBatchesUpdate: (InvoiceBatchUpdateTypeInput | null)[];
}

/**
 * A filter to be used against `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceDistributionFilter {
  id?: UUIDFilter | null;
  invoiceId?: UUIDFilter | null;
  goodsReceiptItemId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  projectId?: UUIDFilter | null;
  accountId?: UUIDFilter | null;
  setAccountId?: UUIDFilter | null;
  referenceCode1Id?: UUIDFilter | null;
  referenceCode2Id?: UUIDFilter | null;
  referenceCode3Id?: UUIDFilter | null;
  referenceCode4Id?: UUIDFilter | null;
  referenceCode5Id?: UUIDFilter | null;
  purchaseOrderReference?: StringFilter | null;
  accountReference?: StringFilter | null;
  projectReference?: StringFilter | null;
  setReference?: StringFilter | null;
  additionalDescription?: StringFilter | null;
  distributionAmount?: BigFloatFilter | null;
  retiredAmount?: BigFloatFilter | null;
  approvedOverageAmount?: BigFloatFilter | null;
  statusTypeId?: IntFilter | null;
  statusExplanation?: StringFilter | null;
  productionIncentiveId?: UUIDFilter | null;
  tax1099T4TypeId?: IntFilter | null;
  businessUnitId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  lookupAccountId?: UUIDFilter | null;
  referenceCode1?: StringFilter | null;
  referenceCode2?: StringFilter | null;
  referenceCode3?: StringFilter | null;
  referenceCode4?: StringFilter | null;
  referenceCode5?: StringFilter | null;
  documentFileDistributionId?: UUIDFilter | null;
  documentFileDistributionAmount?: BigFloatFilter | null;
  isPoCharge?: BooleanFilter | null;
  poEntityDocumentId?: UUIDFilter | null;
  _isNewPoDistributionAllowed?: BooleanFilter | null;
  purchaseOrderInvoiceScheduleDistributionId?: UUIDFilter | null;
  purchaseOrderId?: UUIDFilter | null;
  referenceCode6Id?: UUIDFilter | null;
  referenceCode7Id?: UUIDFilter | null;
  referenceCode6?: StringFilter | null;
  referenceCode7?: StringFilter | null;
  _accountName?: StringFilter | null;
  _ff1Name?: StringFilter | null;
  _ff2Name?: StringFilter | null;
  _ff3Name?: StringFilter | null;
  _ff4Name?: StringFilter | null;
  invoiceDistributionHistoriesByEntityId?: InvoiceDistributionToManyInvoiceDistributionHistoryFilter | null;
  invoiceDistributionHistoriesByEntityIdExist?: boolean | null;
  invoice?: InvoiceFilter | null;
  invoiceExists?: boolean | null;
  statusType?: InvoiceDistributionStatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  productionIncentive?: ProductionIncentiveFilter | null;
  productionIncentiveExists?: boolean | null;
  tax1099t4Type?: Tax1099T4TypeFilter | null;
  tax1099t4TypeExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  lookupAccount?: LookupAccountFilter | null;
  lookupAccountExists?: boolean | null;
  and?: InvoiceDistributionFilter[] | null;
  or?: InvoiceDistributionFilter[] | null;
  not?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against `InvoiceDistributionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceDistributionHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  invoiceDistribution?: InvoiceDistributionFilter | null;
  invoiceDistributionExists?: boolean | null;
  and?: InvoiceDistributionHistoryFilter[] | null;
  or?: InvoiceDistributionHistoryFilter[] | null;
  not?: InvoiceDistributionHistoryFilter | null;
}

/**
 * An input for mutations affecting `InvoiceDistribution`
 */
export interface InvoiceDistributionInput {
  departmentId?: GqlUUID | null;
  purchaseOrderReference?: string | null;
  accountReference?: string | null;
  projectReference?: string | null;
  setReference?: string | null;
  additionalDescription?: string | null;
  distributionAmount?: GqlBigFloat | null;
  statusTypeId?: number | null;
  statusExplanation?: string | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  businessUnitId?: GqlUUID | null;
  lookupAccountId?: GqlUUID | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  documentFileDistributionId?: GqlUUID | null;
  documentFileDistributionAmount?: GqlBigFloat | null;
  isPoCharge?: boolean | null;
  poEntityDocumentId?: GqlUUID | null;
  _isNewPoDistributionAllowed?: boolean | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
}

/**
 * Represents an update to a `InvoiceDistribution`. Fields that are set will be updated.
 */
export interface InvoiceDistributionPatch {
  departmentId?: GqlUUID | null;
  purchaseOrderReference?: string | null;
  accountReference?: string | null;
  projectReference?: string | null;
  setReference?: string | null;
  additionalDescription?: string | null;
  distributionAmount?: GqlBigFloat | null;
  statusTypeId?: number | null;
  statusExplanation?: string | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  businessUnitId?: GqlUUID | null;
  lookupAccountId?: GqlUUID | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  documentFileDistributionId?: GqlUUID | null;
  documentFileDistributionAmount?: GqlBigFloat | null;
  isPoCharge?: boolean | null;
  poEntityDocumentId?: GqlUUID | null;
  _isNewPoDistributionAllowed?: boolean | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
}

/**
 * A filter to be used against `InvoiceDistributionStatusType` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceDistributionStatusTypeFilter {
  id?: IntFilter | null;
  statusType?: StringFilter | null;
  isExplanationRequired?: BooleanFilter | null;
  invoiceDistributionsByStatusTypeId?: InvoiceDistributionStatusTypeToManyInvoiceDistributionFilter | null;
  invoiceDistributionsByStatusTypeIdExist?: boolean | null;
  entityDocumentsByDocumentStatusTypeId?: InvoiceDistributionStatusTypeToManyEntityDocumentFilter | null;
  entityDocumentsByDocumentStatusTypeIdExist?: boolean | null;
  and?: InvoiceDistributionStatusTypeFilter[] | null;
  or?: InvoiceDistributionStatusTypeFilter[] | null;
  not?: InvoiceDistributionStatusTypeFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceDistributionStatusTypeToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceDistributionStatusTypeToManyInvoiceDistributionFilter {
  every?: InvoiceDistributionFilter | null;
  some?: InvoiceDistributionFilter | null;
  none?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistributionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceDistributionToManyInvoiceDistributionHistoryFilter {
  every?: InvoiceDistributionHistoryFilter | null;
  some?: InvoiceDistributionHistoryFilter | null;
  none?: InvoiceDistributionHistoryFilter | null;
}

/**
 * An input for mutations affecting `InvoiceDistributionUpdateType`
 */
export interface InvoiceDistributionUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  invoiceDistributionPatch?: InvoiceDistributionPatch | null;
}

/**
 * All input for the `invoiceEmailCreate` mutation.
 */
export interface InvoiceEmailCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  isSupplierEmail?: boolean | null;
  userId?: (GqlUUID | null)[] | null;
  emailAccount?: (string | null)[] | null;
  externalEmail?: string | null;
  memoArea?: string | null;
  isUserSenderIncluded?: boolean | null;
}

/**
 * A filter to be used against `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceFilter {
  id?: UUIDFilter | null;
  vendorId?: UUIDFilter | null;
  vendorReference?: StringFilter | null;
  transactionTypeId?: IntFilter | null;
  description?: StringFilter | null;
  invoiceNumber?: StringFilter | null;
  invoiceDate?: DateFilter | null;
  purchaseOrderReference?: StringFilter | null;
  paymentDueDate?: DateFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  currencyId?: IntFilter | null;
  invoiceReceivedDate?: DateFilter | null;
  invoiceReceivedTime?: TimeFilter | null;
  transactionNumberReference?: StringFilter | null;
  vendorAddressId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  batchId?: UUIDFilter | null;
  paymentId?: UUIDFilter | null;
  pettyCashId?: UUIDFilter | null;
  paymentTermTypeId?: IntFilter | null;
  paymentGroupTypeId?: IntFilter | null;
  journalDate?: DateFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _isHistory?: BooleanFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionEntry?: BooleanFilter | null;
  _isAccountingEntry?: BooleanFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  _isAccountingEntryStampedComplete?: BooleanFilter | null;
  _paymentReference?: StringFilter | null;
  _paymentDate?: DateFilter | null;
  _paymentTotal?: BigFloatFilter | null;
  _paymentStatusTypeId?: IntFilter | null;
  _lookupNameId?: UUIDFilter | null;
  additionalInformationStatus?: StringFilter | null;
  additionalInformationStatusTypeId?: IntFilter | null;
  bankName?: StringFilter | null;
  bankIbanCode?: StringFilter | null;
  bankRoutingNumber?: StringFilter | null;
  bankSortCode?: StringFilter | null;
  bankAccountNumber?: StringFilter | null;
  bankSwiftCode?: StringFilter | null;
  bankAccountName?: StringFilter | null;
  _pendingApprovers?: StringFilter | null;
  bankConfirmation?: StringFilter | null;
  _baseCurrencyId?: IntFilter | null;
  _baseCurrencyAmount?: BigFloatFilter | null;
  _spotCurrencyAmount?: BigFloatFilter | null;
  cardAccountId?: UUIDFilter | null;
  cardHolderId?: UUIDFilter | null;
  reimburseAmount?: BigFloatFilter | null;
  reimburseAccountId?: UUIDFilter | null;
  reimburseAccountReference?: StringFilter | null;
  statusHintOverrideId?: IntFilter | null;
  statusHintOverride?: StringFilter | null;
  _createdByUserName?: StringFilter | null;
  _linkedInvoiceId?: UUIDFilter | null;
  approvalTriggerDate?: DateFilter | null;
  _elapsedTriggerDays?: IntFilter | null;
  _batchTransactionId?: UUIDFilter | null;
  _documentPackageId?: UUIDFilter | null;
  _receiptsTotal?: BigFloatFilter | null;
  _urgencyLevel?: IntFilter | null;
  invoiceBatches?: InvoiceToManyInvoiceBatchFilter | null;
  invoiceBatchesExist?: boolean | null;
  invoiceDistributions?: InvoiceToManyInvoiceDistributionFilter | null;
  invoiceDistributionsExist?: boolean | null;
  invoicePayments?: InvoiceToManyInvoicePaymentFilter | null;
  invoicePaymentsExist?: boolean | null;
  approvalHistoriesByEntityId?: InvoiceToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistoriesByLinkedEntityId?: InvoiceToManyApprovalHistoryFilter | null;
  approvalHistoriesByLinkedEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: InvoiceToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: InvoiceToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: InvoiceToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  userApprovalsByLinkedEntityId?: InvoiceToManyUserApprovalFilter | null;
  userApprovalsByLinkedEntityIdExist?: boolean | null;
  entityTagsByEntityId?: InvoiceToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: InvoiceToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: InvoiceToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: InvoiceToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: InvoiceToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  userInvoice?: UserFilter | null;
  userInvoiceExists?: boolean | null;
  appliedEntityDocumentsByEntityId?: InvoiceToManyAppliedEntityDocumentFilter | null;
  appliedEntityDocumentsByEntityIdExist?: boolean | null;
  emailDocumentsByEntityId?: InvoiceToManyEmailDocumentFilter | null;
  emailDocumentsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: InvoiceToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  entityEnvironmentalsByEntityId?: InvoiceToManyEntityEnvironmentalFilter | null;
  entityEnvironmentalsByEntityIdExist?: boolean | null;
  invoiceHistoriesByEntityId?: InvoiceToManyInvoiceHistoryFilter | null;
  invoiceHistoriesByEntityIdExist?: boolean | null;
  purchaseOrderInvoiceSchedulesByInvoiceId?: InvoiceToManyPurchaseOrderInvoiceScheduleFilter | null;
  purchaseOrderInvoiceSchedulesByInvoiceIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  transactionType?: InvoiceTransactionTypeFilter | null;
  transactionTypeExists?: boolean | null;
  companyTransactionType?: CompanyTransactionTypeFilter | null;
  companyTransactionTypeExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  companyCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  companyCorporatePeriodExists?: boolean | null;
  invoiceHistoryApprover?: ApprovalEntityApproverFilter | null;
  invoiceHistoryApproverExists?: boolean | null;
  paymentStatusType?: PaymentStatusTypeFilter | null;
  paymentStatusTypeExists?: boolean | null;
  entityAppliedAmounts?: InvoiceSigningAppliedAmountFilter | null;
  entityAppliedAmountsExists?: boolean | null;
  additionalStatusType?: StatusTypeFilter | null;
  additionalStatusTypeExists?: boolean | null;
  lookupName?: LookupNameFilter | null;
  lookupNameExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  cardHolder?: CompanyCardHolderFilter | null;
  cardHolderExists?: boolean | null;
  and?: InvoiceFilter[] | null;
  or?: InvoiceFilter[] | null;
  not?: InvoiceFilter | null;
}

/**
 * A filter to be used against `InvoiceHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  invoice?: InvoiceFilter | null;
  invoiceExists?: boolean | null;
  and?: InvoiceHistoryFilter[] | null;
  or?: InvoiceHistoryFilter[] | null;
  not?: InvoiceHistoryFilter | null;
}

/**
 * An input for mutations affecting `Invoice`
 */
export interface InvoiceInput {
  vendorId?: GqlUUID | null;
  vendorReference?: string | null;
  transactionTypeId: number;
  description: string;
  invoiceNumber?: string | null;
  invoiceDate?: GqlDate | null;
  purchaseOrderReference?: string | null;
  controlTotalAmount: GqlBigFloat;
  currencyId: number;
  departmentId?: GqlUUID | null;
  corporatePeriodId?: GqlUUID | null;
  businessUnitId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  _lookupNameId?: GqlUUID | null;
  bankName?: string | null;
  bankIbanCode?: string | null;
  bankRoutingNumber?: string | null;
  bankSortCode?: string | null;
  bankAccountNumber?: string | null;
  bankSwiftCode?: string | null;
  bankAccountName?: string | null;
  bankConfirmation?: string | null;
  cardAccountId?: GqlUUID | null;
  cardHolderId?: GqlUUID | null;
  reimburseAmount?: GqlBigFloat | null;
  reimburseAccountReference?: string | null;
  statusHintOverrideId?: number | null;
  statusHintOverride?: string | null;
  approvalTriggerDate?: GqlDate | null;
}

/**
 * Represents an update to a `Invoice`. Fields that are set will be updated.
 */
export interface InvoicePatch {
  vendorId?: GqlUUID | null;
  vendorReference?: string | null;
  transactionTypeId?: number | null;
  description?: string | null;
  invoiceNumber?: string | null;
  invoiceDate?: GqlDate | null;
  purchaseOrderReference?: string | null;
  controlTotalAmount?: GqlBigFloat | null;
  currencyId?: number | null;
  departmentId?: GqlUUID | null;
  corporatePeriodId?: GqlUUID | null;
  rowSecurityId?: GqlUUID | null;
  _lookupNameId?: GqlUUID | null;
  bankName?: string | null;
  bankIbanCode?: string | null;
  bankRoutingNumber?: string | null;
  bankSortCode?: string | null;
  bankAccountNumber?: string | null;
  bankSwiftCode?: string | null;
  bankAccountName?: string | null;
  bankConfirmation?: string | null;
  cardAccountId?: GqlUUID | null;
  cardHolderId?: GqlUUID | null;
  reimburseAmount?: GqlBigFloat | null;
  reimburseAccountReference?: string | null;
  statusHintOverrideId?: number | null;
  statusHintOverride?: string | null;
  approvalTriggerDate?: GqlDate | null;
}

/**
 * A filter to be used against `InvoicePayment` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoicePaymentFilter {
  id?: UUIDFilter | null;
  paymentId?: UUIDFilter | null;
  invoiceId?: UUIDFilter | null;
  appliedAmount?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isTransactionPayment?: BooleanFilter | null;
  invoicePaymentHistoriesByEntityId?: InvoicePaymentToManyInvoicePaymentHistoryFilter | null;
  invoicePaymentHistoriesByEntityIdExist?: boolean | null;
  payment?: PaymentFilter | null;
  paymentExists?: boolean | null;
  invoice?: InvoiceFilter | null;
  invoiceExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  and?: InvoicePaymentFilter[] | null;
  or?: InvoicePaymentFilter[] | null;
  not?: InvoicePaymentFilter | null;
}

/**
 * A filter to be used against `InvoicePaymentHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoicePaymentHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  invoicePayment?: InvoicePaymentFilter | null;
  invoicePaymentExists?: boolean | null;
  and?: InvoicePaymentHistoryFilter[] | null;
  or?: InvoicePaymentHistoryFilter[] | null;
  not?: InvoicePaymentHistoryFilter | null;
}

/**
 * An input for mutations affecting `InvoicePayment`
 */
export interface InvoicePaymentInput {
  invoiceId: GqlUUID;
}

/**
 * A filter to be used against many `InvoicePaymentHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoicePaymentToManyInvoicePaymentHistoryFilter {
  every?: InvoicePaymentHistoryFilter | null;
  some?: InvoicePaymentHistoryFilter | null;
  none?: InvoicePaymentHistoryFilter | null;
}

/**
 * All input for the `invoiceSigningAccountingStamper` mutation.
 */
export interface InvoiceSigningAccountingStamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  transactionReference?: string | null;
  corporatePeriodId?: GqlUUID | null;
  batchTransactionId?: GqlUUID | null;
  reimbursementTransactionReference?: string | null;
  reimbursementBatchTransactionId?: GqlUUID | null;
}

/**
 * All input for the `invoiceSigningAccountingUnstamper` mutation.
 */
export interface InvoiceSigningAccountingUnstamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  batchTransactionId?: GqlUUID | null;
}

/**
 * A filter to be used against `InvoiceSigningAppliedAmount` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceSigningAppliedAmountFilter {
  id?: UUIDFilter | null;
  usedTotal?: BigFloatFilter | null;
  appliedTotal?: BigFloatFilter | null;
  retiredTotal?: BigFloatFilter | null;
  overageTotal?: BigFloatFilter | null;
  entityAppliedAmounts?: InvoiceFilter | null;
  entityAppliedAmountsExists?: boolean | null;
  and?: InvoiceSigningAppliedAmountFilter[] | null;
  or?: InvoiceSigningAppliedAmountFilter[] | null;
  not?: InvoiceSigningAppliedAmountFilter | null;
}

/**
 * All input for the `invoiceSigningApprovalCreate` mutation.
 */
export interface InvoiceSigningApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `invoiceSigningApprovalRevoke` mutation.
 */
export interface InvoiceSigningApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `invoiceSigningCreateApply` mutation.
 */
export interface InvoiceSigningCreateApplyInput {
  clientMutationId?: string | null;
  invoice: InvoiceInput;
  invoiceDistributions?: (InvoiceDistributionInput | null)[] | null;
  entityDocuments?: (EntityDocumentInput | null)[] | null;
  poInvoiceSchedules?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `invoiceSigningDelete` mutation.
 */
export interface InvoiceSigningDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `invoiceSigningDraftRelease` mutation.
 */
export interface InvoiceSigningDraftReleaseInput {
  clientMutationId?: string | null;
  entitySelection: (EntitySelectionInput | null)[];
}

/**
 * All input for the `invoiceSigningExport` mutation.
 */
export interface InvoiceSigningExportInput {
  clientMutationId?: string | null;
  exportDescription?: string | null;
  entityDelete?: (EntityDeleteInput | null)[] | null;
  corporatePeriodId?: GqlUUID | null;
  isDistributionIncluded?: boolean | null;
}

/**
 * All input for the `invoiceSigningRowProtection` mutation.
 */
export interface InvoiceSigningRowProtectionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  isProtectionRemoval?: boolean | null;
}

/**
 * All input for the `invoiceSigningUpdate` mutation.
 */
export interface InvoiceSigningUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  invoicePatch?: InvoicePatch | null;
  invoiceDistributionsCreate?: (InvoiceDistributionInput | null)[] | null;
  invoiceDistributionsUpdate?: (InvoiceDistributionUpdateTypeInput | null)[] | null;
  invoiceDistributionsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `invoiceSigningUrgentUpdate` mutation.
 */
export interface InvoiceSigningUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * A filter to be used against many `AppliedEntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyAppliedEntityDocumentFilter {
  every?: AppliedEntityDocumentFilter | null;
  some?: AppliedEntityDocumentFilter | null;
  none?: AppliedEntityDocumentFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyEmailDocumentFilter {
  every?: EmailDocumentFilter | null;
  some?: EmailDocumentFilter | null;
  none?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityEnvironmental` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyEntityEnvironmentalFilter {
  every?: EntityEnvironmentalFilter | null;
  some?: EntityEnvironmentalFilter | null;
  none?: EntityEnvironmentalFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `InvoiceBatch` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyInvoiceBatchFilter {
  every?: InvoiceBatchFilter | null;
  some?: InvoiceBatchFilter | null;
  none?: InvoiceBatchFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyInvoiceDistributionFilter {
  every?: InvoiceDistributionFilter | null;
  some?: InvoiceDistributionFilter | null;
  none?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against many `InvoiceHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyInvoiceHistoryFilter {
  every?: InvoiceHistoryFilter | null;
  some?: InvoiceHistoryFilter | null;
  none?: InvoiceHistoryFilter | null;
}

/**
 * A filter to be used against many `InvoicePayment` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyInvoicePaymentFilter {
  every?: InvoicePaymentFilter | null;
  some?: InvoicePaymentFilter | null;
  none?: InvoicePaymentFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceSchedule` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyPurchaseOrderInvoiceScheduleFilter {
  every?: PurchaseOrderInvoiceScheduleFilter | null;
  some?: PurchaseOrderInvoiceScheduleFilter | null;
  none?: PurchaseOrderInvoiceScheduleFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * A filter to be used against `InvoiceTransactionType` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceTransactionTypeFilter {
  id?: IntFilter | null;
  transactionType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  isDocumentSigningPayment?: BooleanFilter | null;
  invoicesByTransactionTypeId?: InvoiceTransactionTypeToManyInvoiceFilter | null;
  invoicesByTransactionTypeIdExist?: boolean | null;
  transactionTransfersByTransactionTypeId?: InvoiceTransactionTypeToManyTransactionTransferFilter | null;
  transactionTransfersByTransactionTypeIdExist?: boolean | null;
  and?: InvoiceTransactionTypeFilter[] | null;
  or?: InvoiceTransactionTypeFilter[] | null;
  not?: InvoiceTransactionTypeFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceTransactionTypeToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `TransactionTransfer` object types. All fields are combined with a logical ‘and.’
 */
export interface InvoiceTransactionTypeToManyTransactionTransferFilter {
  every?: TransactionTransferFilter | null;
  some?: TransactionTransferFilter | null;
  none?: TransactionTransferFilter | null;
}

/**
 * A filter to be used against JSON fields. All fields are combined with a logical ‘and.’
 */
export interface JSONFilter {
  isNull?: boolean | null;
  equalTo?: GqlJSON | null;
  notEqualTo?: GqlJSON | null;
  distinctFrom?: GqlJSON | null;
  notDistinctFrom?: GqlJSON | null;
  in?: GqlJSON[] | null;
  notIn?: GqlJSON[] | null;
  lessThan?: GqlJSON | null;
  lessThanOrEqualTo?: GqlJSON | null;
  greaterThan?: GqlJSON | null;
  greaterThanOrEqualTo?: GqlJSON | null;
  contains?: GqlJSON | null;
  containsKey?: string | null;
  containsAllKeys?: string[] | null;
  containsAnyKeys?: string[] | null;
  containedBy?: GqlJSON | null;
}

/**
 * A filter to be used against `LinkType` object types. All fields are combined with a logical ‘and.’
 */
export interface LinkTypeFilter {
  id?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkHint?: StringFilter | null;
  entityTypeId?: IntFilter | null;
  linkRouter?: AppRouterFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  approvalHistoriesByVerificationLinkTypeId?: LinkTypeToManyApprovalHistoryFilter | null;
  approvalHistoriesByVerificationLinkTypeIdExist?: boolean | null;
  userApprovalsByVerificationLinkTypeId?: LinkTypeToManyUserApprovalFilter | null;
  userApprovalsByVerificationLinkTypeIdExist?: boolean | null;
  and?: LinkTypeFilter[] | null;
  or?: LinkTypeFilter[] | null;
  not?: LinkTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface LinkTypeToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface LinkTypeToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * All input for the `lookupAccountCreate` mutation.
 */
export interface LookupAccountCreateInput {
  clientMutationId?: string | null;
  chartOfAccountId: GqlUUID;
  lookupAccount: LookupAccountInput;
}

/**
 * All input for the `lookupAccountDelete` mutation.
 */
export interface LookupAccountDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `LookupAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountFilter {
  id?: UUIDFilter | null;
  lookupAccount?: StringFilter | null;
  lookupName?: StringFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  accountTypeId?: IntFilter | null;
  isApprovedAccount?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  userGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isProtected?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isHistory?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _accountId?: UUIDFilter | null;
  subAccountNumber?: StringFilter | null;
  projectAccount?: StringFilter | null;
  setAccount?: StringFilter | null;
  corporateLookupAccountId?: UUIDFilter | null;
  isOption1Allowed?: BooleanFilter | null;
  isOption2Allowed?: BooleanFilter | null;
  isOption3Allowed?: BooleanFilter | null;
  sortProjectAccount?: StringFilter | null;
  sortSetAccount?: StringFilter | null;
  sortCorporateAccount?: StringFilter | null;
  chartOfAccountId?: UUIDFilter | null;
  travelAccountTypeId?: IntFilter | null;
  departmentGroupId?: UUIDFilter | null;
  subAccount?: StringFilter | null;
  isTravelAccountForAllDepartments?: BooleanFilter | null;
  account?: StringFilter | null;
  sortTravelAccountType?: StringFilter | null;
  sortDeptGroup?: StringFilter | null;
  invoiceDistributions?: LookupAccountToManyInvoiceDistributionFilter | null;
  invoiceDistributionsExist?: boolean | null;
  departmentBudgets?: LookupAccountToManyDepartmentBudgetFilter | null;
  departmentBudgetsExist?: boolean | null;
  userDefaults?: LookupAccountToManyUserDefaultFilter | null;
  userDefaultsExist?: boolean | null;
  documentFileDistributions?: LookupAccountToManyDocumentFileDistributionFilter | null;
  documentFileDistributionsExist?: boolean | null;
  purchaseOrderInvoiceScheduleDistributions?: LookupAccountToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsExist?: boolean | null;
  purchaseOrderItemDistributions?: LookupAccountToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsExist?: boolean | null;
  tripDistributions?: LookupAccountToManyTripDistributionFilter | null;
  tripDistributionsExist?: boolean | null;
  corporateAccount?: LookupCorporateAccountFilter | null;
  corporateAccountExists?: boolean | null;
  chartOfAccount?: ChartOfAccountFilter | null;
  chartOfAccountExists?: boolean | null;
  travelAccountType?: TravelAccountTypeFilter | null;
  travelAccountTypeExists?: boolean | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  and?: LookupAccountFilter[] | null;
  or?: LookupAccountFilter[] | null;
  not?: LookupAccountFilter | null;
}

/**
 * An input for mutations affecting `LookupAccount`
 */
export interface LookupAccountInput {
  lookupAccount: string;
  lookupName: string;
  projectAccount?: string | null;
  setAccount?: string | null;
  corporateLookupAccountId?: GqlUUID | null;
  travelAccountTypeId?: number | null;
  departmentGroupId?: GqlUUID | null;
  isTravelAccountForAllDepartments?: boolean | null;
}

/**
 * Represents an update to a `LookupAccount`. Fields that are set will be updated.
 */
export interface LookupAccountPatch {
  lookupAccount?: string | null;
  lookupName?: string | null;
  projectAccount?: string | null;
  setAccount?: string | null;
  corporateLookupAccountId?: GqlUUID | null;
  travelAccountTypeId?: number | null;
  departmentGroupId?: GqlUUID | null;
  isTravelAccountForAllDepartments?: boolean | null;
}

/**
 * A filter to be used against many `DepartmentBudget` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountToManyDepartmentBudgetFilter {
  every?: DepartmentBudgetFilter | null;
  some?: DepartmentBudgetFilter | null;
  none?: DepartmentBudgetFilter | null;
}

/**
 * A filter to be used against many `DocumentFileDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountToManyDocumentFileDistributionFilter {
  every?: DocumentFileDistributionFilter | null;
  some?: DocumentFileDistributionFilter | null;
  none?: DocumentFileDistributionFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountToManyInvoiceDistributionFilter {
  every?: InvoiceDistributionFilter | null;
  some?: InvoiceDistributionFilter | null;
  none?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupAccountToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * All input for the `lookupAccountUpdate` mutation.
 */
export interface LookupAccountUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  lookupAccountPatch?: LookupAccountPatch | null;
}

/**
 * A filter to be used against `LookupCity` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupCityFilter {
  id?: UUIDFilter | null;
  lookupCity?: StringFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  description?: StringFilter | null;
  isActive?: BooleanFilter | null;
  isDeprecated?: BooleanFilter | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: LookupCityFilter[] | null;
  or?: LookupCityFilter[] | null;
  not?: LookupCityFilter | null;
}

/**
 * All input for the `lookupCorporateAccountCreate` mutation.
 */
export interface LookupCorporateAccountCreateInput {
  clientMutationId?: string | null;
  chartOfAccountId: GqlUUID;
  lookupCorporateAccount: LookupCorporateAccountInput;
}

/**
 * All input for the `lookupCorporateAccountDelete` mutation.
 */
export interface LookupCorporateAccountDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `LookupCorporateAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupCorporateAccountFilter {
  id?: UUIDFilter | null;
  lookupAccount?: StringFilter | null;
  lookupName?: StringFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  accountTypeId?: IntFilter | null;
  isApprovedAccount?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  userGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isProtected?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isHistory?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _accountId?: UUIDFilter | null;
  subAccountNumber?: StringFilter | null;
  projectAccount?: StringFilter | null;
  setAccount?: StringFilter | null;
  corporateLookupAccountId?: UUIDFilter | null;
  isOption1Allowed?: BooleanFilter | null;
  isOption2Allowed?: BooleanFilter | null;
  isOption3Allowed?: BooleanFilter | null;
  sortProjectAccount?: StringFilter | null;
  sortSetAccount?: StringFilter | null;
  sortCorporateAccount?: StringFilter | null;
  chartOfAccountId?: UUIDFilter | null;
  travelAccountTypeId?: IntFilter | null;
  departmentGroupId?: UUIDFilter | null;
  subAccount?: StringFilter | null;
  isTravelAccountForAllDepartments?: BooleanFilter | null;
  account?: StringFilter | null;
  sortTravelAccountType?: StringFilter | null;
  sortDeptGroup?: StringFilter | null;
  lookupAccountsByCorporateLookupAccountId?: LookupCorporateAccountToManyLookupAccountFilter | null;
  lookupAccountsByCorporateLookupAccountIdExist?: boolean | null;
  corporateChartOfAccount?: CorporateChartOfAccountFilter | null;
  corporateChartOfAccountExists?: boolean | null;
  travelAccountType?: TravelAccountTypeFilter | null;
  travelAccountTypeExists?: boolean | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  and?: LookupCorporateAccountFilter[] | null;
  or?: LookupCorporateAccountFilter[] | null;
  not?: LookupCorporateAccountFilter | null;
}

/**
 * An input for mutations affecting `LookupCorporateAccount`
 */
export interface LookupCorporateAccountInput {
  lookupName: string;
  travelAccountTypeId?: number | null;
  departmentGroupId?: GqlUUID | null;
  subAccount?: string | null;
  isTravelAccountForAllDepartments?: boolean | null;
  account: string;
}

/**
 * Represents an update to a `LookupCorporateAccount`. Fields that are set will be updated.
 */
export interface LookupCorporateAccountPatch {
  lookupName?: string | null;
  travelAccountTypeId?: number | null;
  departmentGroupId?: GqlUUID | null;
  subAccount?: string | null;
  isTravelAccountForAllDepartments?: boolean | null;
  account?: string | null;
}

/**
 * A filter to be used against many `LookupAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupCorporateAccountToManyLookupAccountFilter {
  every?: LookupAccountFilter | null;
  some?: LookupAccountFilter | null;
  none?: LookupAccountFilter | null;
}

/**
 * All input for the `lookupCorporateAccountUpdate` mutation.
 */
export interface LookupCorporateAccountUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  lookupCorporateAccountPatch?: LookupCorporateAccountPatch | null;
}

/**
 * A filter to be used against `LookupName` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupNameFilter {
  id?: UUIDFilter | null;
  lookupName?: StringFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  isTransactionSigningLookup?: BooleanFilter | null;
  lookupGroupSequence?: IntFilter | null;
  businessUnitId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  isApprovedVendor?: BooleanFilter | null;
  lookupName2?: StringFilter | null;
  lookupCode?: StringFilter | null;
  studioCode?: StringFilter | null;
  address1?: StringFilter | null;
  address2?: StringFilter | null;
  city?: StringFilter | null;
  state?: StringFilter | null;
  zipCode?: StringFilter | null;
  contact?: StringFilter | null;
  phone?: StringFilter | null;
  emailAccount?: StringFilter | null;
  country?: StringFilter | null;
  bankName?: StringFilter | null;
  bankIbanCode?: StringFilter | null;
  bankRoutingNumber?: StringFilter | null;
  bankSortCode?: StringFilter | null;
  bankAccountNumber?: StringFilter | null;
  bankSwiftCode?: StringFilter | null;
  _searchDocument?: StringFilter | null;
  userGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isProtected?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isHistory?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  bankAccountName?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  lookupDescription?: StringFilter | null;
  invoicesByLookupNameId?: LookupNameToManyInvoiceFilter | null;
  invoicesByLookupNameIdExist?: boolean | null;
  paymentsByLookupNameId?: LookupNameToManyPaymentFilter | null;
  paymentsByLookupNameIdExist?: boolean | null;
  approvalHistoriesByLookupNameId?: LookupNameToManyApprovalHistoryFilter | null;
  approvalHistoriesByLookupNameIdExist?: boolean | null;
  userApprovalsByLookupNameId?: LookupNameToManyUserApprovalFilter | null;
  userApprovalsByLookupNameIdExist?: boolean | null;
  entityDocumentsByLookupNameId?: LookupNameToManyEntityDocumentFilter | null;
  entityDocumentsByLookupNameIdExist?: boolean | null;
  and?: LookupNameFilter[] | null;
  or?: LookupNameFilter[] | null;
  not?: LookupNameFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupNameToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupNameToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupNameToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupNameToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface LookupNameToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against `MaritalStatusType` object types. All fields are combined with a logical ‘and.’
 */
export interface MaritalStatusTypeFilter {
  id?: IntFilter | null;
  maritalStatusType?: StringFilter | null;
  countryId?: IntFilter | null;
  employees?: MaritalStatusTypeToManyEmployeeFilter | null;
  employeesExist?: boolean | null;
  and?: MaritalStatusTypeFilter[] | null;
  or?: MaritalStatusTypeFilter[] | null;
  not?: MaritalStatusTypeFilter | null;
}

/**
 * A filter to be used against many `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface MaritalStatusTypeToManyEmployeeFilter {
  every?: EmployeeFilter | null;
  some?: EmployeeFilter | null;
  none?: EmployeeFilter | null;
}

/**
 * All input for the `messageApprovalCreate` mutation.
 */
export interface MessageApprovalCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `messageBatchTransactionCreate` mutation.
 */
export interface MessageBatchTransactionCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `messageDelete` mutation.
 */
export interface MessageDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `messageDetailDelete` mutation.
 */
export interface MessageDetailDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `messageInvoiceSigningCreate` mutation.
 */
export interface MessageInvoiceSigningCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `messagePaymentCreate` mutation.
 */
export interface MessagePaymentCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `messagePurchaseOrderCreate` mutation.
 */
export interface MessagePurchaseOrderCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `messageResetUserNewMessageCount` mutation.
 */
export interface MessageResetUserNewMessageCountInput {
  clientMutationId?: string | null;
}

/**
 * All input for the `messageResetUserNotificationCount` mutation.
 */
export interface MessageResetUserNotificationCountInput {
  clientMutationId?: string | null;
}

/**
 * All input for the `messageResponseUpdate` mutation.
 */
export interface MessageResponseUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  responseMessage?: string | null;
}

/**
 * A filter to be used against `MessageSection` object types. All fields are combined with a logical ‘and.’
 */
export interface MessageSectionFilter {
  id?: UUIDFilter | null;
  extendedMessageId?: UUIDFilter | null;
  sequenceNumber?: IntFilter | null;
  sectionHeader?: StringFilter | null;
  sectionBody?: StringFilter | null;
  sectionFooter?: StringFilter | null;
  tableColumns?: IntFilter | null;
  columnHeading1?: StringFilter | null;
  columnAlignment1?: AlignmentTypesFilter | null;
  columnHeading2?: StringFilter | null;
  columnAlignment2?: AlignmentTypesFilter | null;
  columnHeading3?: StringFilter | null;
  columnAlignment3?: AlignmentTypesFilter | null;
  columnHeading4?: StringFilter | null;
  columnAlignment4?: AlignmentTypesFilter | null;
  columnHeading5?: StringFilter | null;
  columnAlignment5?: AlignmentTypesFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkId?: UUIDFilter | null;
  linkType?: AppRouterFilter | null;
  appId?: IntFilter | null;
  messageTableColumns?: MessageSectionToManyMessageTableColumnFilter | null;
  messageTableColumnsExist?: boolean | null;
  extendedMessage?: ExtendedMessageFilter | null;
  extendedMessageExists?: boolean | null;
  and?: MessageSectionFilter[] | null;
  or?: MessageSectionFilter[] | null;
  not?: MessageSectionFilter | null;
}

/**
 * A filter to be used against many `MessageTableColumn` object types. All fields are combined with a logical ‘and.’
 */
export interface MessageSectionToManyMessageTableColumnFilter {
  every?: MessageTableColumnFilter | null;
  some?: MessageTableColumnFilter | null;
  none?: MessageTableColumnFilter | null;
}

/**
 * All input for the `messageSupplierCreate` mutation.
 */
export interface MessageSupplierCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * A filter to be used against `MessageTableColumn` object types. All fields are combined with a logical ‘and.’
 */
export interface MessageTableColumnFilter {
  id?: UUIDFilter | null;
  messageSectionId?: UUIDFilter | null;
  sortOrder?: StringFilter | null;
  column1Value?: StringFilter | null;
  column2Value?: StringFilter | null;
  column3Value?: StringFilter | null;
  column4Value?: StringFilter | null;
  column5Value?: StringFilter | null;
  messageSection?: MessageSectionFilter | null;
  messageSectionExists?: boolean | null;
  and?: MessageTableColumnFilter[] | null;
  or?: MessageTableColumnFilter[] | null;
  not?: MessageTableColumnFilter | null;
}

/**
 * All input for the `messageTravelAuthorizationCreate` mutation.
 */
export interface MessageTravelAuthorizationCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `messageTravelerCreate` mutation.
 */
export interface MessageTravelerCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `messageUpdate` mutation.
 */
export interface MessageUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  messagePatch?: UserMessagePatch | null;
}

/**
 * All input for the `messageUserCreate` mutation.
 */
export interface MessageUserCreateInput {
  clientMutationId?: string | null;
  message: UserMessageInput;
  department?: (GqlUUID | null)[] | null;
  communicationChannel?: (GqlUUID | null)[] | null;
  user?: (GqlUUID | null)[] | null;
}

/**
 * A filter to be used against `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface MobileUserMessageFilter {
  id?: UUIDFilter | null;
  messageComment?: StringFilter | null;
  _recipientUserId?: UUIDFilter | null;
  _threadId?: UUIDFilter | null;
  _recipientUser?: StringFilter | null;
  _sendingUser?: StringFilter | null;
  _associatedUserId?: UUIDFilter | null;
  _associatedUser?: StringFilter | null;
  _associatedUserAcknowledgeDate?: DatetimeFilter | null;
  _userAcknowledgeDate?: DatetimeFilter | null;
  _isOutgoingMessage?: BooleanFilter | null;
  _isIncomingMessage?: BooleanFilter | null;
  _communicationChannelId?: UUIDFilter | null;
  _communicationChannel?: StringFilter | null;
  _departmentId?: UUIDFilter | null;
  _department?: StringFilter | null;
  notificationTypeId?: IntFilter | null;
  _notificationTitle?: StringFilter | null;
  reminderDates?: DatetimeListFilter | null;
  _remindedDate?: DatetimeFilter | null;
  messageResponse?: StringFilter | null;
  messageWithResponse?: StringFilter | null;
  isResponseRequired?: BooleanFilter | null;
  _respondedDate?: DatetimeFilter | null;
  _receivedDate?: DatetimeFilter | null;
  _sentDate?: DatetimeFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkId?: UUIDFilter | null;
  linkType?: AppRouterFilter | null;
  appId?: IntFilter | null;
  _isSystemGenerated?: BooleanFilter | null;
  _companyId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _sortDate?: DatetimeFilter | null;
  _isNewMessage?: BooleanFilter | null;
  _isMultipleOutgoingMessages?: BooleanFilter | null;
  _outgoingMessageCount?: BigIntFilter | null;
  _isExtendedMessage?: BooleanFilter | null;
  extendedMessageId?: UUIDFilter | null;
  userMessageDetailsByThreadId?: MobileUserMessageToManyUserMessageDetailFilter | null;
  userMessageDetailsByThreadIdExist?: boolean | null;
  employeeMessage?: EmployeeFilter | null;
  employeeMessageExists?: boolean | null;
  vendorMessage?: VendorFilter | null;
  vendorMessageExists?: boolean | null;
  userMessageApp?: UserAppFilter | null;
  userMessageAppExists?: boolean | null;
  invoiceMessage?: InvoiceFilter | null;
  invoiceMessageExists?: boolean | null;
  approvalHistoryMessage?: ApprovalHistoryFilter | null;
  approvalHistoryMessageExists?: boolean | null;
  userApprovalMessage?: UserApprovalFilter | null;
  userApprovalMessageExists?: boolean | null;
  extendedMessage?: ExtendedMessageFilter | null;
  extendedMessageExists?: boolean | null;
  paymentMessage?: PaymentFilter | null;
  paymentMessageExists?: boolean | null;
  supplierMessage?: SupplierFilter | null;
  supplierMessageExists?: boolean | null;
  travelerMessage?: TravelerFilter | null;
  travelerMessageExists?: boolean | null;
  batchTransactionMessage?: BatchTransactionFilter | null;
  batchTransactionMessageExists?: boolean | null;
  and?: MobileUserMessageFilter[] | null;
  or?: MobileUserMessageFilter[] | null;
  not?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `UserMessageDetail` object types. All fields are combined with a logical ‘and.’
 */
export interface MobileUserMessageToManyUserMessageDetailFilter {
  every?: UserMessageDetailFilter | null;
  some?: UserMessageDetailFilter | null;
  none?: UserMessageDetailFilter | null;
}

/**
 * A filter to be used against `MobileUserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface MobileUserNotificationFilter {
  id?: UUIDFilter | null;
  messageComment?: StringFilter | null;
  notificationTypeId?: IntFilter | null;
  _notificationTitle?: StringFilter | null;
  _receivedDate?: DatetimeFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkId?: UUIDFilter | null;
  linkType?: AppRouterFilter | null;
  appId?: IntFilter | null;
  _createdDate?: DatetimeFilter | null;
  _isNewMessage?: BooleanFilter | null;
  statusTypeId?: IntFilter | null;
  _isExtendedMessage?: BooleanFilter | null;
  extendedMessageId?: UUIDFilter | null;
  userMessageApp?: UserAppFilter | null;
  userMessageAppExists?: boolean | null;
  notification?: NotificationTypeFilter | null;
  notificationExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  extendedMessage?: ExtendedMessageFilter | null;
  extendedMessageExists?: boolean | null;
  and?: MobileUserNotificationFilter[] | null;
  or?: MobileUserNotificationFilter[] | null;
  not?: MobileUserNotificationFilter | null;
}

/**
 * A filter to be used against `MovementOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface MovementOrderFilter {
  id?: UUIDFilter | null;
  travelAuthorizationId?: UUIDFilter | null;
  isGroupMovementOrder?: BooleanFilter | null;
  movementOrderNumber?: StringFilter | null;
  movementOrderDate?: DateFilter | null;
  originalMovementOrderId?: UUIDFilter | null;
  revisionNumber?: IntFilter | null;
  amendedDate?: DatetimeFilter | null;
  revisedDate?: DatetimeFilter | null;
  revisedUserId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _isRebuildMovementOrdersAllowed?: BooleanFilter | null;
  movementTravelers?: MovementOrderToManyMovementTravelerFilter | null;
  movementTravelersExist?: boolean | null;
  travelAuthorizationsByTripMovementId?: MovementOrderToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByTripMovementIdExist?: boolean | null;
  and?: MovementOrderFilter[] | null;
  or?: MovementOrderFilter[] | null;
  not?: MovementOrderFilter | null;
}

/**
 * A filter to be used against many `MovementTraveler` object types. All fields are combined with a logical ‘and.’
 */
export interface MovementOrderToManyMovementTravelerFilter {
  every?: MovementTravelerFilter | null;
  some?: MovementTravelerFilter | null;
  none?: MovementTravelerFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface MovementOrderToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * All input for the `movementReportRequest` mutation.
 */
export interface MovementReportRequestInput {
  clientMutationId?: string | null;
  entityId: (GqlUUID | null)[];
}

/**
 * All input for the `movementTravelerEmailCreate` mutation.
 */
export interface MovementTravelerEmailCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  isSupplierEmail?: boolean | null;
  userId?: (GqlUUID | null)[] | null;
  emailAccount?: (string | null)[] | null;
  externalEmail?: string | null;
  memoArea?: string | null;
  isUserSenderIncluded?: boolean | null;
}

/**
 * A filter to be used against `MovementTraveler` object types. All fields are combined with a logical ‘and.’
 */
export interface MovementTravelerFilter {
  id?: UUIDFilter | null;
  movementOrderId?: UUIDFilter | null;
  travelerId?: UUIDFilter | null;
  travelerName?: StringFilter | null;
  revisionReference?: StringFilter | null;
  entityDocumentId?: UUIDFilter | null;
  reportDate?: DatetimeFilter | null;
  _isModified?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  movementAreas?: StringFilter | null;
  emailDocumentsByEntityId?: MovementTravelerToManyEmailDocumentFilter | null;
  emailDocumentsByEntityIdExist?: boolean | null;
  movementTravelerJourneys?: MovementTravelerToManyMovementTravelerJourneyFilter | null;
  movementTravelerJourneysExist?: boolean | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  movementOrder?: MovementOrderFilter | null;
  movementOrderExists?: boolean | null;
  and?: MovementTravelerFilter[] | null;
  or?: MovementTravelerFilter[] | null;
  not?: MovementTravelerFilter | null;
}

/**
 * A filter to be used against `MovementTravelerJourney` object types. All fields are combined with a logical ‘and.’
 */
export interface MovementTravelerJourneyFilter {
  id?: UUIDFilter | null;
  movementTravelerId?: UUIDFilter | null;
  journeyDate?: DateFilter | null;
  journeySectionId?: IntFilter | null;
  journeyTime?: TimeFilter | null;
  journeySequence?: IntFilter | null;
  journeySortOrder?: StringFilter | null;
  movementLayoutType?: TransactionLayoutFilter | null;
  isAirTravel?: BooleanFilter | null;
  isTrainTravel?: BooleanFilter | null;
  isGroundTranspo?: BooleanFilter | null;
  isCarRental?: BooleanFilter | null;
  isHotel?: BooleanFilter | null;
  isNote?: BooleanFilter | null;
  headerPhraseTypeId?: IntFilter | null;
  isHeaderBold?: BooleanFilter | null;
  isHeaderRed?: BooleanFilter | null;
  headerTitle?: StringFilter | null;
  headerCityFromTo?: StringFilter | null;
  isHeader?: BooleanFilter | null;
  isSubHeader?: BooleanFilter | null;
  isFooter?: BooleanFilter | null;
  bodyLayoutType?: TransactionLayoutFilter | null;
  bodyArea1?: StringFilter | null;
  isBodyArea1Red?: BooleanFilter | null;
  isBodyArea1Bold?: BooleanFilter | null;
  bodyArea2?: StringFilter | null;
  isBodyArea2Red?: BooleanFilter | null;
  isBodyArea2Bold?: BooleanFilter | null;
  bodyArea3?: StringFilter | null;
  isBodyArea3Red?: BooleanFilter | null;
  isBodyArea3Bold?: BooleanFilter | null;
  bodyArea4?: StringFilter | null;
  isBodyArea4Red?: BooleanFilter | null;
  isBodyArea4Bold?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  phraseType?: StringFilter | null;
  movementTraveler?: MovementTravelerFilter | null;
  movementTravelerExists?: boolean | null;
  and?: MovementTravelerJourneyFilter[] | null;
  or?: MovementTravelerJourneyFilter[] | null;
  not?: MovementTravelerJourneyFilter | null;
}

/**
 * An input for mutations affecting `MovementTravelerJourney`
 */
export interface MovementTravelerJourneyInput {
  journeyDate: GqlDate;
  journeyTime: GqlTime;
  journeySequence: number;
  headerTitle?: string | null;
  headerCityFromTo?: string | null;
  bodyArea1?: string | null;
  phraseType?: string | null;
}

/**
 * Represents an update to a `MovementTravelerJourney`. Fields that are set will be updated.
 */
export interface MovementTravelerJourneyPatch {
  journeyDate?: GqlDate | null;
  journeyTime?: GqlTime | null;
  journeySequence?: number | null;
  headerTitle?: string | null;
  headerCityFromTo?: string | null;
  bodyArea1?: string | null;
  phraseType?: string | null;
}

/**
 * An input for mutations affecting `MovementTravelerJourneyUpdateType`
 */
export interface MovementTravelerJourneyUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  movementTravelerJourneyUpdatePatch?: MovementTravelerJourneyPatch | null;
}

/**
 * Represents an update to a `MovementTraveler`. Fields that are set will be updated.
 */
export interface MovementTravelerPatch {
  revisionReference?: string | null;
  movementAreas?: string | null;
}

/**
 * A filter to be used against many `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface MovementTravelerToManyEmailDocumentFilter {
  every?: EmailDocumentFilter | null;
  some?: EmailDocumentFilter | null;
  none?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `MovementTravelerJourney` object types. All fields are combined with a logical ‘and.’
 */
export interface MovementTravelerToManyMovementTravelerJourneyFilter {
  every?: MovementTravelerJourneyFilter | null;
  some?: MovementTravelerJourneyFilter | null;
  none?: MovementTravelerJourneyFilter | null;
}

/**
 * All input for the `movementTravelerUpdate` mutation.
 */
export interface MovementTravelerUpdateInput {
  clientMutationId?: string | null;
  movementTravelerId: GqlUUID;
  movementTravelerUpdate?: MovementTravelerUpdateTypeInput | null;
  movementTravelerJourneyCreate?: (MovementTravelerJourneyInput | null)[] | null;
  movementTravelerJourneyUpdate?: (MovementTravelerJourneyUpdateTypeInput | null)[] | null;
  movementTravelerJourneyDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * An input for mutations affecting `MovementTravelerUpdateType`
 */
export interface MovementTravelerUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  movementTravelerUpdatePatch?: MovementTravelerPatch | null;
}

/**
 * All input for the `noteApprovalCreate` mutation.
 */
export interface NoteApprovalCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteBatchTransactionCreate` mutation.
 */
export interface NoteBatchTransactionCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteCardAccountCreate` mutation.
 */
export interface NoteCardAccountCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteCardHolderCreate` mutation.
 */
export interface NoteCardHolderCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteDelete` mutation.
 */
export interface NoteDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `noteDocumentPoolCreate` mutation.
 */
export interface NoteDocumentPoolCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * A filter to be used against `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface NoteFilter {
  id?: UUIDFilter | null;
  noteComment?: StringFilter | null;
  threadNoteId?: UUIDFilter | null;
  isShared?: BooleanFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _createdByUserName?: StringFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  employeeNote?: EmployeeFilter | null;
  employeeNoteExists?: boolean | null;
  documentPoolNote?: DocumentPoolFilter | null;
  documentPoolNoteExists?: boolean | null;
  vendorNote?: VendorFilter | null;
  vendorNoteExists?: boolean | null;
  approvalHistoryNote?: ApprovalHistoryFilter | null;
  approvalHistoryNoteExists?: boolean | null;
  invoiceNote?: InvoiceFilter | null;
  invoiceNoteExists?: boolean | null;
  userApprovalNote?: UserApprovalFilter | null;
  userApprovalNoteExists?: boolean | null;
  paymentNote?: PaymentFilter | null;
  paymentNoteExists?: boolean | null;
  payCycleNote?: PayCycleFilter | null;
  payCycleNoteExists?: boolean | null;
  approvalSetupNote?: ApprovalFilter | null;
  approvalSetupNoteExists?: boolean | null;
  cardAccountNote?: CardAccountFilter | null;
  cardAccountNoteExists?: boolean | null;
  cardHolderNote?: CardHolderFilter | null;
  cardHolderNoteExists?: boolean | null;
  userProfileNote?: UserProfileFilter | null;
  userProfileNoteExists?: boolean | null;
  supplierNote?: SupplierFilter | null;
  supplierNoteExists?: boolean | null;
  travelerNote?: TravelerFilter | null;
  travelerNoteExists?: boolean | null;
  batchTransactionNote?: BatchTransactionFilter | null;
  batchTransactionNoteExists?: boolean | null;
  purchaseOrderNote?: PurchaseOrderFilter | null;
  purchaseOrderNoteExists?: boolean | null;
  tripNote?: TripFilter | null;
  tripNoteExists?: boolean | null;
  travelAuthorizationNote?: TravelAuthorizationFilter | null;
  travelAuthorizationNoteExists?: boolean | null;
  and?: NoteFilter[] | null;
  or?: NoteFilter[] | null;
  not?: NoteFilter | null;
}

/**
 * An input for mutations affecting `Note`
 */
export interface NoteInput {
  noteComment: string;
  threadNoteId?: GqlUUID | null;
  isShared: boolean;
  entityId: GqlUUID;
}

/**
 * All input for the `noteInvoiceSigningCreate` mutation.
 */
export interface NoteInvoiceSigningCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * Represents an update to a `Note`. Fields that are set will be updated.
 */
export interface NotePatch {
  noteComment?: string | null;
  threadNoteId?: GqlUUID | null;
  isShared?: boolean | null;
  entityId?: GqlUUID | null;
}

/**
 * All input for the `notePayCycleCreate` mutation.
 */
export interface NotePayCycleCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `notePaymentCreate` mutation.
 */
export interface NotePaymentCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `notePendingApprovalCreate` mutation.
 */
export interface NotePendingApprovalCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `notePurchaseOrderCreate` mutation.
 */
export interface NotePurchaseOrderCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteSupplierCreate` mutation.
 */
export interface NoteSupplierCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteTravelAuthorizationCreate` mutation.
 */
export interface NoteTravelAuthorizationCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteTravelerCreate` mutation.
 */
export interface NoteTravelerCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteTripCreate` mutation.
 */
export interface NoteTripCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * All input for the `noteUpdate` mutation.
 */
export interface NoteUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  notePatch?: NotePatch | null;
}

/**
 * All input for the `noteUserProfileCreate` mutation.
 */
export interface NoteUserProfileCreateInput {
  clientMutationId?: string | null;
  note: NoteInput;
}

/**
 * A filter to be used against `NotificationType` object types. All fields are combined with a logical ‘and.’
 */
export interface NotificationTypeFilter {
  id?: IntFilter | null;
  notificationType?: StringFilter | null;
  isAppUserMessage?: BooleanFilter | null;
  isActionAlert?: BooleanFilter | null;
  isMetricAlert?: BooleanFilter | null;
  isFraudAlert?: BooleanFilter | null;
  isTradingPartnerActivityAlert?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  mobileUserNotifications?: NotificationTypeToManyMobileUserNotificationFilter | null;
  mobileUserNotificationsExist?: boolean | null;
  userNotifications?: NotificationTypeToManyUserNotificationFilter | null;
  userNotificationsExist?: boolean | null;
  and?: NotificationTypeFilter[] | null;
  or?: NotificationTypeFilter[] | null;
  not?: NotificationTypeFilter | null;
}

/**
 * A filter to be used against many `MobileUserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface NotificationTypeToManyMobileUserNotificationFilter {
  every?: MobileUserNotificationFilter | null;
  some?: MobileUserNotificationFilter | null;
  none?: MobileUserNotificationFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface NotificationTypeToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * All input for the `occupationCreate` mutation.
 */
export interface OccupationCreateInput {
  clientMutationId?: string | null;
  occupation: UserOccupationTitleInput;
}

/**
 * All input for the `occupationDelete` mutation.
 */
export interface OccupationDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `occupationUpdate` mutation.
 */
export interface OccupationUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  occupationPatch?: UserOccupationTitlePatch | null;
}

/**
 * All input for the `payCycleAccountingStamper` mutation.
 */
export interface PayCycleAccountingStamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  transactionReference?: string | null;
  corporatePeriodId?: GqlUUID | null;
}

/**
 * All input for the `payCycleAccountingUnstamper` mutation.
 */
export interface PayCycleAccountingUnstamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `payCycleApprovalCreate` mutation.
 */
export interface PayCycleApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `payCycleApprovalRevoke` mutation.
 */
export interface PayCycleApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `payCycleCreate` mutation.
 */
export interface PayCycleCreateInput {
  clientMutationId?: string | null;
  payCycle: PayCycleInput;
  entityDocuments?: (EntityDocumentInput | null)[] | null;
}

/**
 * All input for the `payCycleDelete` mutation.
 */
export interface PayCycleDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleFilter {
  id?: UUIDFilter | null;
  transactionTypeId?: IntFilter | null;
  paymentAccountId?: UUIDFilter | null;
  defaultPaymentDate?: DateFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  description?: StringFilter | null;
  isCycleOpenEnded?: BooleanFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  _totalPayments?: BigFloatFilter | null;
  _paymentCount?: BigIntFilter | null;
  currencyId?: IntFilter | null;
  transactionNumberReference?: StringFilter | null;
  departmentId?: UUIDFilter | null;
  journalDate?: DateFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _isHistory?: BooleanFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionEntry?: BooleanFilter | null;
  _isAccountingEntry?: BooleanFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  _isAccountingEntryStampedComplete?: BooleanFilter | null;
  _isPaymentSelectable?: BooleanFilter | null;
  _pendingApprovers?: StringFilter | null;
  _urgencyLevel?: IntFilter | null;
  payments?: PayCycleToManyPaymentFilter | null;
  paymentsExist?: boolean | null;
  approvalHistoriesByEntityId?: PayCycleToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: PayCycleToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: PayCycleToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: PayCycleToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  notesByEntityId?: PayCycleToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: PayCycleToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  payCycleHistoriesByEntityId?: PayCycleToManyPayCycleHistoryFilter | null;
  payCycleHistoriesByEntityIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  paymentAccount?: PaymentAccountFilter | null;
  paymentAccountExists?: boolean | null;
  transactionType?: PaymentTransactionTypeFilter | null;
  transactionTypeExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  companyCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  companyCorporatePeriodExists?: boolean | null;
  payCycleHistoryApprover?: ApprovalEntityApproverFilter | null;
  payCycleHistoryApproverExists?: boolean | null;
  and?: PayCycleFilter[] | null;
  or?: PayCycleFilter[] | null;
  not?: PayCycleFilter | null;
}

/**
 * A filter to be used against `PayCycleHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  payCycle?: PayCycleFilter | null;
  payCycleExists?: boolean | null;
  and?: PayCycleHistoryFilter[] | null;
  or?: PayCycleHistoryFilter[] | null;
  not?: PayCycleHistoryFilter | null;
}

/**
 * An input for mutations affecting `PayCycle`
 */
export interface PayCycleInput {
  transactionTypeId: number;
  paymentAccountId: GqlUUID;
  defaultPaymentDate?: GqlDate | null;
  corporatePeriodId?: GqlUUID | null;
  description: string;
  controlTotalAmount?: GqlBigFloat | null;
  rowSecurityId?: GqlUUID | null;
}

/**
 * Represents an update to a `PayCycle`. Fields that are set will be updated.
 */
export interface PayCyclePatch {
  transactionTypeId?: number | null;
  paymentAccountId?: GqlUUID | null;
  defaultPaymentDate?: GqlDate | null;
  corporatePeriodId?: GqlUUID | null;
  description?: string | null;
  controlTotalAmount?: GqlBigFloat | null;
  rowSecurityId?: GqlUUID | null;
}

/**
 * All input for the `payCycleRowProtection` mutation.
 */
export interface PayCycleRowProtectionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  isProtectionRemoval?: boolean | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `PayCycleHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyPayCycleHistoryFilter {
  every?: PayCycleHistoryFilter | null;
  some?: PayCycleHistoryFilter | null;
  none?: PayCycleHistoryFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface PayCycleToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * All input for the `payCycleUpdate` mutation.
 */
export interface PayCycleUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  payCyclePatch?: PayCyclePatch | null;
}

/**
 * All input for the `payCycleUrgentUpdate` mutation.
 */
export interface PayCycleUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * A filter to be used against `PaymentAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentAccountFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  bankAccountNumber?: StringFilter | null;
  paymentTypeId?: IntFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  payCycles?: PaymentAccountToManyPayCycleFilter | null;
  payCyclesExist?: boolean | null;
  payments?: PaymentAccountToManyPaymentFilter | null;
  paymentsExist?: boolean | null;
  paymentType?: PaymentTypeFilter | null;
  paymentTypeExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  and?: PaymentAccountFilter[] | null;
  or?: PaymentAccountFilter[] | null;
  not?: PaymentAccountFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentAccountToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentAccountToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * All input for the `paymentCreate` mutation.
 */
export interface PaymentCreateInput {
  clientMutationId?: string | null;
  payment: PaymentInput;
  invoicePayments?: (InvoicePaymentInput | null)[] | null;
  entityDocumentId?: (GqlUUID | null)[] | null;
  documentTypeId?: number | null;
}

/**
 * All input for the `paymentDelete` mutation.
 */
export interface PaymentDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `paymentEmailCreate` mutation.
 */
export interface PaymentEmailCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  isSupplierEmail?: boolean | null;
  userId?: (GqlUUID | null)[] | null;
  emailAccount?: (string | null)[] | null;
  externalEmail?: string | null;
  memoArea?: string | null;
  isUserSenderIncluded?: boolean | null;
}

/**
 * A filter to be used against `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentFilter {
  id?: UUIDFilter | null;
  payCycleId?: UUIDFilter | null;
  vendorId?: UUIDFilter | null;
  vendorReference?: StringFilter | null;
  memo?: StringFilter | null;
  paymentReference?: StringFilter | null;
  paidDate?: DateFilter | null;
  paidAmount?: BigFloatFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  transactionTypeId?: IntFilter | null;
  paymentAccountId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  departmentId?: UUIDFilter | null;
  _statusTypeId?: IntFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _isHistory?: BooleanFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionEntry?: BooleanFilter | null;
  _isAccountingEntry?: BooleanFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  _isAccountingEntryStampedComplete?: BooleanFilter | null;
  _lookupNameId?: UUIDFilter | null;
  _baseCurrencyId?: IntFilter | null;
  _baseCurrencyAmount?: BigFloatFilter | null;
  _spotCurrencyAmount?: BigFloatFilter | null;
  _isTransactionPayment?: BooleanFilter | null;
  _isInvoiceBalanceRequired?: BooleanFilter | null;
  _payCycleDescription?: StringFilter | null;
  _documentPackageId?: UUIDFilter | null;
  _invoiceTotal?: BigFloatFilter | null;
  invoicePayments?: PaymentToManyInvoicePaymentFilter | null;
  invoicePaymentsExist?: boolean | null;
  entityTagsByEntityId?: PaymentToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: PaymentToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: PaymentToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: PaymentToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: PaymentToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  emailDocumentsByEntityId?: PaymentToManyEmailDocumentFilter | null;
  emailDocumentsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: PaymentToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  paymentHistoriesByEntityId?: PaymentToManyPaymentHistoryFilter | null;
  paymentHistoriesByEntityIdExist?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  payCycle?: PayCycleFilter | null;
  payCycleExists?: boolean | null;
  paymentAccount?: PaymentAccountFilter | null;
  paymentAccountExists?: boolean | null;
  transactionType?: PaymentTransactionTypeFilter | null;
  transactionTypeExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  companyCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  companyCorporatePeriodExists?: boolean | null;
  lookupName?: LookupNameFilter | null;
  lookupNameExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  and?: PaymentFilter[] | null;
  or?: PaymentFilter[] | null;
  not?: PaymentFilter | null;
}

/**
 * A filter to be used against `PaymentHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  payment?: PaymentFilter | null;
  paymentExists?: boolean | null;
  and?: PaymentHistoryFilter[] | null;
  or?: PaymentHistoryFilter[] | null;
  not?: PaymentHistoryFilter | null;
}

/**
 * An input for mutations affecting `Payment`
 */
export interface PaymentInput {
  payCycleId: GqlUUID;
  vendorId?: GqlUUID | null;
  vendorReference: string;
  memo?: string | null;
  paymentReference: string;
  paidDate: GqlDate;
  paidAmount: GqlBigFloat;
  rowSecurityId?: GqlUUID | null;
  _lookupNameId?: GqlUUID | null;
}

/**
 * Represents an update to a `Payment`. Fields that are set will be updated.
 */
export interface PaymentPatch {
  payCycleId?: GqlUUID | null;
  vendorId?: GqlUUID | null;
  vendorReference?: string | null;
  memo?: string | null;
  paymentReference?: string | null;
  paidDate?: GqlDate | null;
  paidAmount?: GqlBigFloat | null;
  rowSecurityId?: GqlUUID | null;
  _lookupNameId?: GqlUUID | null;
}

/**
 * All input for the `paymentRowProtection` mutation.
 */
export interface PaymentRowProtectionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  isProtectionRemoval?: boolean | null;
}

/**
 * A filter to be used against `PaymentStatusType` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentStatusTypeFilter {
  id?: IntFilter | null;
  statusType?: StringFilter | null;
  invoicesByPaymentStatusTypeId?: PaymentStatusTypeToManyInvoiceFilter | null;
  invoicesByPaymentStatusTypeIdExist?: boolean | null;
  and?: PaymentStatusTypeFilter[] | null;
  or?: PaymentStatusTypeFilter[] | null;
  not?: PaymentStatusTypeFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentStatusTypeToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against `PaymentTermType` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTermTypeFilter {
  id?: UUIDFilter | null;
  paymentTermType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  dueDays?: IntFilter | null;
  discountDays?: IntFilter | null;
  discountPercent?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  vendors?: PaymentTermTypeToManyVendorFilter | null;
  vendorsExist?: boolean | null;
  suppliers?: PaymentTermTypeToManySupplierFilter | null;
  suppliersExist?: boolean | null;
  and?: PaymentTermTypeFilter[] | null;
  or?: PaymentTermTypeFilter[] | null;
  not?: PaymentTermTypeFilter | null;
}

/**
 * A filter to be used against many `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTermTypeToManySupplierFilter {
  every?: SupplierFilter | null;
  some?: SupplierFilter | null;
  none?: SupplierFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTermTypeToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * A filter to be used against many `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyEmailDocumentFilter {
  every?: EmailDocumentFilter | null;
  some?: EmailDocumentFilter | null;
  none?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `InvoicePayment` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyInvoicePaymentFilter {
  every?: InvoicePaymentFilter | null;
  some?: InvoicePaymentFilter | null;
  none?: InvoicePaymentFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `PaymentHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyPaymentHistoryFilter {
  every?: PaymentHistoryFilter | null;
  some?: PaymentHistoryFilter | null;
  none?: PaymentHistoryFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * A filter to be used against `PaymentTransactionType` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTransactionTypeFilter {
  id?: IntFilter | null;
  transactionType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  payCyclesByTransactionTypeId?: PaymentTransactionTypeToManyPayCycleFilter | null;
  payCyclesByTransactionTypeIdExist?: boolean | null;
  paymentsByTransactionTypeId?: PaymentTransactionTypeToManyPaymentFilter | null;
  paymentsByTransactionTypeIdExist?: boolean | null;
  and?: PaymentTransactionTypeFilter[] | null;
  or?: PaymentTransactionTypeFilter[] | null;
  not?: PaymentTransactionTypeFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTransactionTypeToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTransactionTypeToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against `PaymentType` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTypeFilter {
  id?: IntFilter | null;
  paymentType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isBankFund?: BooleanFilter | null;
  isCheck?: BooleanFilter | null;
  isCash?: BooleanFilter | null;
  isPettyCash?: BooleanFilter | null;
  isCreditCard?: BooleanFilter | null;
  isDebitCard?: BooleanFilter | null;
  isPaymentService?: BooleanFilter | null;
  isCryptoCurrency?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  paymentAccounts?: PaymentTypeToManyPaymentAccountFilter | null;
  paymentAccountsExist?: boolean | null;
  and?: PaymentTypeFilter[] | null;
  or?: PaymentTypeFilter[] | null;
  not?: PaymentTypeFilter | null;
}

/**
 * A filter to be used against many `PaymentAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface PaymentTypeToManyPaymentAccountFilter {
  every?: PaymentAccountFilter | null;
  some?: PaymentAccountFilter | null;
  none?: PaymentAccountFilter | null;
}

/**
 * All input for the `paymentUpdate` mutation.
 */
export interface PaymentUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  paymentPatch?: PaymentPatch | null;
  invoicePaymentsCreate?: (InvoicePaymentInput | null)[] | null;
  invoicePaymentsDelete?: (EntityDeleteInput | null)[] | null;
}

export interface PointInput {
  x: number;
  y: number;
}

/**
 * An input for mutations affecting `ProcessRequest`
 */
export interface ProcessRequestInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  reference?: string | null;
  isSuccess?: boolean | null;
  isFail?: boolean | null;
  isWarning?: boolean | null;
  message?: string | null;
}

/**
 * A filter to be used against `ProductionBudgetType` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionBudgetTypeFilter {
  id?: IntFilter | null;
  productionBudgetType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: StringFilter | null;
  minimumBudgetAmount?: BigFloatFilter | null;
  maximumBudgetAmount?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: ProductionBudgetTypeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  and?: ProductionBudgetTypeFilter[] | null;
  or?: ProductionBudgetTypeFilter[] | null;
  not?: ProductionBudgetTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionBudgetTypeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against `ProductionIncentive` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveFilter {
  id?: UUIDFilter | null;
  description?: StringFilter | null;
  incentiveTypeId?: IntFilter | null;
  _productionIncentiveGroupId?: UUIDFilter | null;
  sortOrder?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _fullDescription?: StringFilter | null;
  invoiceDistributions?: ProductionIncentiveToManyInvoiceDistributionFilter | null;
  invoiceDistributionsExist?: boolean | null;
  productionIncentiveRates?: ProductionIncentiveToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  purchaseOrderInvoiceScheduleDistributions?: ProductionIncentiveToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsExist?: boolean | null;
  purchaseOrderItemDistributions?: ProductionIncentiveToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsExist?: boolean | null;
  tripDistributions?: ProductionIncentiveToManyTripDistributionFilter | null;
  tripDistributionsExist?: boolean | null;
  productionIncentiveGroup?: ProductionIncentiveGroupFilter | null;
  productionIncentiveGroupExists?: boolean | null;
  incentiveType?: IncentiveTypeFilter | null;
  incentiveTypeExists?: boolean | null;
  and?: ProductionIncentiveFilter[] | null;
  or?: ProductionIncentiveFilter[] | null;
  not?: ProductionIncentiveFilter | null;
}

/**
 * A filter to be used against `ProductionIncentiveGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveGroupFilter {
  id?: UUIDFilter | null;
  description?: StringFilter | null;
  sortOrder?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  cityAreaId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  productionIncentivesByProductionIncentiveGroupId?: ProductionIncentiveGroupToManyProductionIncentiveFilter | null;
  productionIncentivesByProductionIncentiveGroupIdExist?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  cityArea?: CityAreaFilter | null;
  cityAreaExists?: boolean | null;
  and?: ProductionIncentiveGroupFilter[] | null;
  or?: ProductionIncentiveGroupFilter[] | null;
  not?: ProductionIncentiveGroupFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentive` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveGroupToManyProductionIncentiveFilter {
  every?: ProductionIncentiveFilter | null;
  some?: ProductionIncentiveFilter | null;
  none?: ProductionIncentiveFilter | null;
}

/**
 * A filter to be used against `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveRateFilter {
  id?: UUIDFilter | null;
  productionIncentiveId?: UUIDFilter | null;
  description?: StringFilter | null;
  taxCodeId?: UUIDFilter | null;
  effectiveDate?: DateFilter | null;
  expirationDate?: DateFilter | null;
  taxRate?: BigFloatFilter | null;
  taxLimit?: BigFloatFilter | null;
  projectCategoryTypeId?: IntFilter | null;
  productionStudioZoneTypeId?: IntFilter | null;
  productionStudioTypeId?: IntFilter | null;
  productionShowTypeId?: IntFilter | null;
  productionProgramLengthTypeId?: IntFilter | null;
  productionMediumTypeId?: IntFilter | null;
  productionBudgetTypeId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  productionIncentive?: ProductionIncentiveFilter | null;
  productionIncentiveExists?: boolean | null;
  taxCode?: TaxCodeFilter | null;
  taxCodeExists?: boolean | null;
  projectCategoryType?: ProjectCategoryTypeFilter | null;
  projectCategoryTypeExists?: boolean | null;
  productionStudioZoneType?: ProductionStudioZoneTypeFilter | null;
  productionStudioZoneTypeExists?: boolean | null;
  productionStudioType?: ProductionStudioTypeFilter | null;
  productionStudioTypeExists?: boolean | null;
  productionShowType?: ProductionShowTypeFilter | null;
  productionShowTypeExists?: boolean | null;
  productionProgramLengthType?: ProductionProgramLengthTypeFilter | null;
  productionProgramLengthTypeExists?: boolean | null;
  productionMediumType?: ProductionMediumTypeFilter | null;
  productionMediumTypeExists?: boolean | null;
  productionBudgetType?: ProductionBudgetTypeFilter | null;
  productionBudgetTypeExists?: boolean | null;
  and?: ProductionIncentiveRateFilter[] | null;
  or?: ProductionIncentiveRateFilter[] | null;
  not?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveToManyInvoiceDistributionFilter {
  every?: InvoiceDistributionFilter | null;
  some?: InvoiceDistributionFilter | null;
  none?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionIncentiveToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against `ProductionMediumType` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionMediumTypeFilter {
  id?: IntFilter | null;
  productionMediumType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: ProductionMediumTypeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  and?: ProductionMediumTypeFilter[] | null;
  or?: ProductionMediumTypeFilter[] | null;
  not?: ProductionMediumTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionMediumTypeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against `ProductionPhase` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionPhaseFilter {
  id?: IntFilter | null;
  statusType?: StringFilter | null;
  statusTypeSmallintValue?: IntFilter | null;
  corporateWorkgroups?: ProductionPhaseToManyCorporateWorkgroupFilter | null;
  corporateWorkgroupsExist?: boolean | null;
  and?: ProductionPhaseFilter[] | null;
  or?: ProductionPhaseFilter[] | null;
  not?: ProductionPhaseFilter | null;
}

/**
 * A filter to be used against many `CorporateWorkgroup` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionPhaseToManyCorporateWorkgroupFilter {
  every?: CorporateWorkgroupFilter | null;
  some?: CorporateWorkgroupFilter | null;
  none?: CorporateWorkgroupFilter | null;
}

/**
 * A filter to be used against `ProductionProgramLengthType` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionProgramLengthTypeFilter {
  id?: IntFilter | null;
  productionProgramLengthType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: StringFilter | null;
  minimumLengthInMinutes?: IntFilter | null;
  maximumLengthInMinutes?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: ProductionProgramLengthTypeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  and?: ProductionProgramLengthTypeFilter[] | null;
  or?: ProductionProgramLengthTypeFilter[] | null;
  not?: ProductionProgramLengthTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionProgramLengthTypeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against `ProductionShowType` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionShowTypeFilter {
  id?: IntFilter | null;
  productionShowType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: ProductionShowTypeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  and?: ProductionShowTypeFilter[] | null;
  or?: ProductionShowTypeFilter[] | null;
  not?: ProductionShowTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionShowTypeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against `ProductionStatus` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStatusFilter {
  id?: IntFilter | null;
  statusType?: StringFilter | null;
  statusTypeSmallintValue?: IntFilter | null;
  chartOfAccountsByProductionStatusTypeId?: ProductionStatusToManyChartOfAccountFilter | null;
  chartOfAccountsByProductionStatusTypeIdExist?: boolean | null;
  companyChartOfAccountsByProductionStatusTypeId?: ProductionStatusToManyCompanyChartOfAccountFilter | null;
  companyChartOfAccountsByProductionStatusTypeIdExist?: boolean | null;
  corporateChartOfAccountsByProductionStatusTypeId?: ProductionStatusToManyCorporateChartOfAccountFilter | null;
  corporateChartOfAccountsByProductionStatusTypeIdExist?: boolean | null;
  corporateWorkgroupsByProductionStatusTypeId?: ProductionStatusToManyCorporateWorkgroupFilter | null;
  corporateWorkgroupsByProductionStatusTypeIdExist?: boolean | null;
  and?: ProductionStatusFilter[] | null;
  or?: ProductionStatusFilter[] | null;
  not?: ProductionStatusFilter | null;
}

/**
 * A filter to be used against many `ChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStatusToManyChartOfAccountFilter {
  every?: ChartOfAccountFilter | null;
  some?: ChartOfAccountFilter | null;
  none?: ChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CompanyChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStatusToManyCompanyChartOfAccountFilter {
  every?: CompanyChartOfAccountFilter | null;
  some?: CompanyChartOfAccountFilter | null;
  none?: CompanyChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CorporateChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStatusToManyCorporateChartOfAccountFilter {
  every?: CorporateChartOfAccountFilter | null;
  some?: CorporateChartOfAccountFilter | null;
  none?: CorporateChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CorporateWorkgroup` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStatusToManyCorporateWorkgroupFilter {
  every?: CorporateWorkgroupFilter | null;
  some?: CorporateWorkgroupFilter | null;
  none?: CorporateWorkgroupFilter | null;
}

/**
 * A filter to be used against `ProductionStudioType` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStudioTypeFilter {
  id?: IntFilter | null;
  productionStudioType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: ProductionStudioTypeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  and?: ProductionStudioTypeFilter[] | null;
  or?: ProductionStudioTypeFilter[] | null;
  not?: ProductionStudioTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStudioTypeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against `ProductionStudioZoneType` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStudioZoneTypeFilter {
  id?: IntFilter | null;
  productionStudioZoneType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: StringFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: ProductionStudioZoneTypeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  and?: ProductionStudioZoneTypeFilter[] | null;
  or?: ProductionStudioZoneTypeFilter[] | null;
  not?: ProductionStudioZoneTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProductionStudioZoneTypeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against `ProjectCategoryType` object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectCategoryTypeFilter {
  id?: IntFilter | null;
  projectCategoryType?: StringFilter | null;
  isProduction?: BooleanFilter | null;
  isCommercial?: BooleanFilter | null;
  isCorporate?: BooleanFilter | null;
  description?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: ProjectCategoryTypeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  projects?: ProjectCategoryTypeToManyProjectFilter | null;
  projectsExist?: boolean | null;
  and?: ProjectCategoryTypeFilter[] | null;
  or?: ProjectCategoryTypeFilter[] | null;
  not?: ProjectCategoryTypeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectCategoryTypeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectCategoryTypeToManyProjectFilter {
  every?: ProjectFilter | null;
  some?: ProjectFilter | null;
  none?: ProjectFilter | null;
}

/**
 * A filter to be used against `Project` object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectFilter {
  id?: UUIDFilter | null;
  code?: StringFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortedCode?: StringFilter | null;
  projectCategoryTypeId?: IntFilter | null;
  chartOfAccountsId?: UUIDFilter | null;
  isPayrollProject?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  userDefaults?: ProjectToManyUserDefaultFilter | null;
  userDefaultsExist?: boolean | null;
  purchaseOrderInvoiceScheduleDistributions?: ProjectToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsExist?: boolean | null;
  purchaseOrderItemDistributions?: ProjectToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsExist?: boolean | null;
  tripDistributions?: ProjectToManyTripDistributionFilter | null;
  tripDistributionsExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  chartOfAccountType?: ChartOfAccountFilter | null;
  chartOfAccountTypeExists?: boolean | null;
  projectCategory?: ProjectCategoryTypeFilter | null;
  projectCategoryExists?: boolean | null;
  and?: ProjectFilter[] | null;
  or?: ProjectFilter[] | null;
  not?: ProjectFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface ProjectToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * All input for the `purchaseOrderAccountingStamper` mutation.
 */
export interface PurchaseOrderAccountingStamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  transactionReference?: string | null;
  corporatePeriodId?: GqlUUID | null;
}

/**
 * All input for the `purchaseOrderAccountingUnstamper` mutation.
 */
export interface PurchaseOrderAccountingUnstamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `purchaseOrderApprovalCreate` mutation.
 */
export interface PurchaseOrderApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `purchaseOrderApprovalRevision` mutation.
 */
export interface PurchaseOrderApprovalRevisionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `purchaseOrderApprovalRevoke` mutation.
 */
export interface PurchaseOrderApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `purchaseOrderCopy` mutation.
 */
export interface PurchaseOrderCopyInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `purchaseOrderCreate` mutation.
 */
export interface PurchaseOrderCreateInput {
  clientMutationId?: string | null;
  purchaseOrder: PurchaseOrderInput;
}

/**
 * All input for the `purchaseOrderDelete` mutation.
 */
export interface PurchaseOrderDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `purchaseOrderEmailCreate` mutation.
 */
export interface PurchaseOrderEmailCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  isSupplierEmail?: boolean | null;
  userId?: (GqlUUID | null)[] | null;
  emailAccount?: (string | null)[] | null;
  externalEmail?: string | null;
  memoArea?: string | null;
  isUserSenderIncluded?: boolean | null;
}

/**
 * All input for the `purchaseOrderEntryStampProcessing` mutation.
 */
export interface PurchaseOrderEntryStampProcessingInput {
  clientMutationId?: string | null;
  corporatePeriodId?: GqlUUID | null;
  processRequests?: (ProcessRequestInput | null)[] | null;
}

/**
 * All input for the `purchaseOrderExport` mutation.
 */
export interface PurchaseOrderExportInput {
  clientMutationId?: string | null;
  exportDescription?: string | null;
  entityDelete?: (EntityDeleteInput | null)[] | null;
  corporatePeriodId?: GqlUUID | null;
  isDistributionIncluded?: boolean | null;
}

/**
 * A filter to be used against `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderFilter {
  id?: UUIDFilter | null;
  supplierId?: UUIDFilter | null;
  purchaseOrderTypeId?: IntFilter | null;
  supplierReferenceName?: StringFilter | null;
  sortSupplierName?: StringFilter | null;
  description?: StringFilter | null;
  reason?: StringFilter | null;
  comment?: StringFilter | null;
  purchaseOrderNumber?: StringFilter | null;
  purchaseOrderDate?: DateFilter | null;
  purchaseOrderTime?: TimeFilter | null;
  isScheduledPickup?: BooleanFilter | null;
  entityDocumentId?: UUIDFilter | null;
  requiredDate?: DateFilter | null;
  requiredTime?: TimeFilter | null;
  paymentTypeId?: IntFilter | null;
  paymentAccountId?: UUIDFilter | null;
  paymentAccountReference?: StringFilter | null;
  paymentDate?: DateFilter | null;
  paymentNumber?: StringFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  currencyId?: IntFilter | null;
  _baseCurrencyId?: IntFilter | null;
  _baseCurrencyAmount?: BigFloatFilter | null;
  _spotCurrencyAmount?: BigFloatFilter | null;
  journalDate?: DateFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  isTemplate?: BooleanFilter | null;
  originalPurchaseOrderId?: UUIDFilter | null;
  contractualPurchaseOrderId?: UUIDFilter | null;
  templatePurchaseOrderId?: UUIDFilter | null;
  travelAuthorizationId?: UUIDFilter | null;
  travelSectionId?: IntFilter | null;
  revisionNumber?: IntFilter | null;
  amendedDate?: DatetimeFilter | null;
  voidedDate?: DatetimeFilter | null;
  retiredDate?: DatetimeFilter | null;
  accountingTransferDate?: DatetimeFilter | null;
  _isHistory?: BooleanFilter | null;
  accountingTransferUserId?: UUIDFilter | null;
  accountingTransferReferenceNumber?: StringFilter | null;
  approvedPurchaseOrderId?: UUIDFilter | null;
  isSupplierPoApproved?: BooleanFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _createdByUserName?: StringFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _isFlaggedHistory?: BooleanFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionEntry?: BooleanFilter | null;
  _isAccountingEntry?: BooleanFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  _isAccountingEntryStampedComplete?: BooleanFilter | null;
  _purchaseOrderDeliveryId?: UUIDFilter | null;
  deliveryAddressId?: UUIDFilter | null;
  isDeliveryAddressProtected?: BooleanFilter | null;
  scheduledDeliveryDate?: DateFilter | null;
  isDeliveryTracked?: BooleanFilter | null;
  isPackingSlipAttachmentRequired?: BooleanFilter | null;
  _isDeliveryComplete?: BooleanFilter | null;
  _isReturned?: BooleanFilter | null;
  _isPartialReturn?: BooleanFilter | null;
  deliveryNotes?: StringFilter | null;
  deliveryAddressLine1?: StringFilter | null;
  deliveryAddressLine2?: StringFilter | null;
  deliveryCityName?: StringFilter | null;
  deliveryAttention?: StringFilter | null;
  deliveryPostalCode?: StringFilter | null;
  deliveryCountryId?: IntFilter | null;
  deliveryStateRegionId?: IntFilter | null;
  deliveryMainContact?: StringFilter | null;
  deliveryAlternateContact?: StringFilter | null;
  deliveryEmailAddress?: StringFilter | null;
  deliveryTelephoneNumber?: StringFilter | null;
  deliveryMobileNumber?: StringFilter | null;
  _purchaseOrderType?: StringFilter | null;
  _isPromoteAddressAllowed?: BooleanFilter | null;
  deliveryAddressAlias?: StringFilter | null;
  _isDistributionUpdateAllowed?: BooleanFilter | null;
  _revisedDate?: DatetimeFilter | null;
  _revisedUserId?: UUIDFilter | null;
  _isRevisable?: BooleanFilter | null;
  _isRevised?: BooleanFilter | null;
  _referenceItemTypes?: StringFilter | null;
  _isStampAllowed?: BooleanFilter | null;
  _isUnstampAllowed?: BooleanFilter | null;
  _isInvoiceScheduleViewable?: BooleanFilter | null;
  _isInvoiceScheduleUpdatable?: BooleanFilter | null;
  _pendingApprovers?: StringFilter | null;
  stampedEntityDocumentId?: UUIDFilter | null;
  documentFileId?: UUIDFilter | null;
  _isRetireAllowed?: BooleanFilter | null;
  _accountingExportDate?: DatetimeFilter | null;
  _accountingExportCycleId?: UUIDFilter | null;
  _accountingExportUserId?: UUIDFilter | null;
  _subjectToDiscount?: BigFloatFilter | null;
  _subjectToTax?: BigFloatFilter | null;
  _urgencyLevel?: IntFilter | null;
  approvalHistoriesByEntityId?: PurchaseOrderToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: PurchaseOrderToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: PurchaseOrderToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: PurchaseOrderToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  entityTagsByEntityId?: PurchaseOrderToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  notesByEntityId?: PurchaseOrderToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: PurchaseOrderToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  emailDocumentsByEntityId?: PurchaseOrderToManyEmailDocumentFilter | null;
  emailDocumentsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: PurchaseOrderToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  entityEnvironmentalsByEntityId?: PurchaseOrderToManyEntityEnvironmentalFilter | null;
  entityEnvironmentalsByEntityIdExist?: boolean | null;
  purchaseOrderHistoriesByEntityId?: PurchaseOrderToManyPurchaseOrderHistoryFilter | null;
  purchaseOrderHistoriesByEntityIdExist?: boolean | null;
  purchaseOrderInvoiceSchedules?: PurchaseOrderToManyPurchaseOrderInvoiceScheduleFilter | null;
  purchaseOrderInvoiceSchedulesExist?: boolean | null;
  purchaseOrderItems?: PurchaseOrderToManyPurchaseOrderItemFilter | null;
  purchaseOrderItemsExist?: boolean | null;
  purchaseOrderRevisionHistoriesByPoGroupId?: PurchaseOrderToManyPurchaseOrderRevisionHistoryFilter | null;
  purchaseOrderRevisionHistoriesByPoGroupIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  purchaseOrderHistoryApprover?: ApprovalEntityApproverFilter | null;
  purchaseOrderHistoryApproverExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  purchaseOrderType?: PurchaseOrderTypeFilter | null;
  purchaseOrderTypeExists?: boolean | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  userRevision?: UserFilter | null;
  userRevisionExists?: boolean | null;
  userExport?: UserFilter | null;
  userExportExists?: boolean | null;
  and?: PurchaseOrderFilter[] | null;
  or?: PurchaseOrderFilter[] | null;
  not?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against `PurchaseOrderHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  purchase_order?: PurchaseOrderFilter | null;
  purchase_orderExists?: boolean | null;
  and?: PurchaseOrderHistoryFilter[] | null;
  or?: PurchaseOrderHistoryFilter[] | null;
  not?: PurchaseOrderHistoryFilter | null;
}

/**
 * An input for mutations affecting `PurchaseOrder`
 */
export interface PurchaseOrderInput {
  supplierId: GqlUUID;
  purchaseOrderTypeId: number;
  description?: string | null;
  reason?: string | null;
  purchaseOrderDate: GqlDate;
  currencyId: number;
  departmentId: GqlUUID;
  businessUnitId: GqlUUID;
  deliveryAddressId?: GqlUUID | null;
  scheduledDeliveryDate?: GqlDate | null;
  isDeliveryTracked?: boolean | null;
  isPackingSlipAttachmentRequired?: boolean | null;
  deliveryNotes?: string | null;
  deliveryAddressLine1?: string | null;
  deliveryAddressLine2?: string | null;
  deliveryCityName?: string | null;
  deliveryAttention?: string | null;
  deliveryPostalCode?: string | null;
  deliveryCountryId?: number | null;
  deliveryStateRegionId?: number | null;
  deliveryMainContact?: string | null;
  deliveryAlternateContact?: string | null;
  deliveryEmailAddress?: string | null;
  deliveryTelephoneNumber?: string | null;
  deliveryMobileNumber?: string | null;
  _pendingApprovers?: string | null;
  _accountingExportDate?: GqlDatetime | null;
  _accountingExportCycleId?: GqlUUID | null;
  _accountingExportUserId?: GqlUUID | null;
}

/**
 * All input for the `purchaseOrderInvoiceScheduleCreate` mutation.
 */
export interface PurchaseOrderInvoiceScheduleCreateInput {
  clientMutationId?: string | null;
  purchaseOrderId: GqlUUID;
  purchaseOrderInvoiceSchedule: PurchaseOrderInvoiceScheduleInput;
}

/**
 * All input for the `purchaseOrderInvoiceScheduleDelete` mutation.
 */
export interface PurchaseOrderInvoiceScheduleDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `PurchaseOrderInvoiceScheduleDistribution` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderInvoiceScheduleDistributionFilter {
  id?: UUIDFilter | null;
  purchaseOrderInvoiceScheduleId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  projectId?: UUIDFilter | null;
  accountId?: UUIDFilter | null;
  setAccountId?: UUIDFilter | null;
  taxCodeRateId?: UUIDFilter | null;
  referenceCode1Id?: UUIDFilter | null;
  referenceCode2Id?: UUIDFilter | null;
  referenceCode3Id?: UUIDFilter | null;
  referenceCode4Id?: UUIDFilter | null;
  referenceCode5Id?: UUIDFilter | null;
  referenceCode6Id?: UUIDFilter | null;
  referenceCode7Id?: UUIDFilter | null;
  productionIncentiveId?: UUIDFilter | null;
  tax1099T4TypeId?: IntFilter | null;
  lookupAccountId?: UUIDFilter | null;
  projectReference?: StringFilter | null;
  accountReference?: StringFilter | null;
  setReference?: StringFilter | null;
  taxReference?: StringFilter | null;
  referenceCode1?: StringFilter | null;
  referenceCode2?: StringFilter | null;
  referenceCode3?: StringFilter | null;
  referenceCode4?: StringFilter | null;
  referenceCode5?: StringFilter | null;
  referenceCode6?: StringFilter | null;
  referenceCode7?: StringFilter | null;
  additionalDescription?: StringFilter | null;
  scheduledAmount?: BigFloatFilter | null;
  distributionAmount?: BigFloatFilter | null;
  retiredAmount?: BigFloatFilter | null;
  overageAmount?: BigFloatFilter | null;
  referenceAmount?: BigFloatFilter | null;
  isInvoiceGenerated?: BooleanFilter | null;
  invoiceDistributionId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isAppliedAmount?: BooleanFilter | null;
  _isOtherChargeAmount?: BooleanFilter | null;
  _isRetiredAmount?: BooleanFilter | null;
  _isScheduledAmount?: BooleanFilter | null;
  _accountName?: StringFilter | null;
  purchaseOrderInvoiceSchedule?: PurchaseOrderInvoiceScheduleFilter | null;
  purchaseOrderInvoiceScheduleExists?: boolean | null;
  productionIncentive?: ProductionIncentiveFilter | null;
  productionIncentiveExists?: boolean | null;
  tax1099t4Type?: Tax1099T4TypeFilter | null;
  tax1099t4TypeExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  lookupAccount?: LookupAccountFilter | null;
  lookupAccountExists?: boolean | null;
  reference1Code?: Reference1CodeFilter | null;
  reference1CodeExists?: boolean | null;
  reference2Code?: Reference2CodeFilter | null;
  reference2CodeExists?: boolean | null;
  reference3Code?: Reference3CodeFilter | null;
  reference3CodeExists?: boolean | null;
  reference4Code?: Reference4CodeFilter | null;
  reference4CodeExists?: boolean | null;
  reference5Code?: Reference5CodeFilter | null;
  reference5CodeExists?: boolean | null;
  project?: ProjectFilter | null;
  projectExists?: boolean | null;
  and?: PurchaseOrderInvoiceScheduleDistributionFilter[] | null;
  or?: PurchaseOrderInvoiceScheduleDistributionFilter[] | null;
  not?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * An input for mutations affecting `PurchaseOrderInvoiceScheduleDistribution`
 */
export interface PurchaseOrderInvoiceScheduleDistributionInput {
  projectId?: GqlUUID | null;
  accountId?: GqlUUID | null;
  setAccountId?: GqlUUID | null;
  taxCodeRateId?: GqlUUID | null;
  referenceCode1Id?: GqlUUID | null;
  referenceCode2Id?: GqlUUID | null;
  referenceCode3Id?: GqlUUID | null;
  referenceCode4Id?: GqlUUID | null;
  referenceCode5Id?: GqlUUID | null;
  referenceCode6Id?: GqlUUID | null;
  referenceCode7Id?: GqlUUID | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  lookupAccountId?: GqlUUID | null;
  projectReference?: string | null;
  accountReference?: string | null;
  setReference?: string | null;
  taxReference?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  additionalDescription?: string | null;
  scheduledAmount?: GqlBigFloat | null;
}

/**
 * Represents an update to a `PurchaseOrderInvoiceScheduleDistribution`. Fields that are set will be updated.
 */
export interface PurchaseOrderInvoiceScheduleDistributionPatch {
  projectId?: GqlUUID | null;
  accountId?: GqlUUID | null;
  setAccountId?: GqlUUID | null;
  taxCodeRateId?: GqlUUID | null;
  referenceCode1Id?: GqlUUID | null;
  referenceCode2Id?: GqlUUID | null;
  referenceCode3Id?: GqlUUID | null;
  referenceCode4Id?: GqlUUID | null;
  referenceCode5Id?: GqlUUID | null;
  referenceCode6Id?: GqlUUID | null;
  referenceCode7Id?: GqlUUID | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  lookupAccountId?: GqlUUID | null;
  projectReference?: string | null;
  accountReference?: string | null;
  setReference?: string | null;
  taxReference?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  additionalDescription?: string | null;
  scheduledAmount?: GqlBigFloat | null;
}

/**
 * An input for mutations affecting `PurchaseOrderInvoiceScheduleDistributionUpdateType`
 */
export interface PurchaseOrderInvoiceScheduleDistributionUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  purchaseOrderInvoiceScheduleDistributionUpdatePatch?: PurchaseOrderInvoiceScheduleDistributionPatch | null;
}

/**
 * A filter to be used against `PurchaseOrderInvoiceSchedule` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderInvoiceScheduleFilter {
  id?: UUIDFilter | null;
  purchaseOrderId?: UUIDFilter | null;
  scheduledDate?: DateFilter | null;
  invoiceSequence?: IntFilter | null;
  scheduledNote?: StringFilter | null;
  scheduledAmount?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _invoiceId?: UUIDFilter | null;
  _isInvoiced?: BooleanFilter | null;
  _isSchedule?: BooleanFilter | null;
  purchaseOrderInvoiceScheduleDistributions?: PurchaseOrderInvoiceScheduleToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsExist?: boolean | null;
  purchaseOrder?: PurchaseOrderFilter | null;
  purchaseOrderExists?: boolean | null;
  invoice?: InvoiceFilter | null;
  invoiceExists?: boolean | null;
  and?: PurchaseOrderInvoiceScheduleFilter[] | null;
  or?: PurchaseOrderInvoiceScheduleFilter[] | null;
  not?: PurchaseOrderInvoiceScheduleFilter | null;
}

/**
 * An input for mutations affecting `PurchaseOrderInvoiceSchedule`
 */
export interface PurchaseOrderInvoiceScheduleInput {
  scheduledDate?: GqlDate | null;
  invoiceSequence?: number | null;
  scheduledNote?: string | null;
  scheduledAmount?: GqlBigFloat | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderInvoiceScheduleToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * All input for the `purchaseOrderInvoiceScheduleUpdate` mutation.
 */
export interface PurchaseOrderInvoiceScheduleUpdateInput {
  clientMutationId?: string | null;
  poInvoiceScheduleId: GqlUUID;
  poInvoiceScheduleRowTimestamp: GqlDatetime;
  purchaseOrderInvoiceSchedule: PurchaseOrderInvoiceScheduleInput;
  purchaseOrderInvoiceScheduleDistributionCreate?: (PurchaseOrderInvoiceScheduleDistributionInput | null)[] | null;
  purchaseOrderInvoiceScheduleDistributionUpdate?: (PurchaseOrderInvoiceScheduleDistributionUpdateTypeInput | null)[] | null;
  purchaseOrderInvoiceScheduleDistributionDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `purchaseOrderItemCreate` mutation.
 */
export interface PurchaseOrderItemCreateInput {
  clientMutationId?: string | null;
  purchaseOrderId: GqlUUID;
  rowTimestamp: GqlDatetime;
  purchaseOrderItem: PurchaseOrderItemInput;
  purchaseOrderItemDistribution?: (PurchaseOrderItemDistributionInput | null)[] | null;
}

/**
 * All input for the `purchaseOrderItemDelete` mutation.
 */
export interface PurchaseOrderItemDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderItemDistributionFilter {
  id?: UUIDFilter | null;
  purchaseOrderItemId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  projectId?: UUIDFilter | null;
  accountId?: UUIDFilter | null;
  setAccountId?: UUIDFilter | null;
  taxCodeRateId?: UUIDFilter | null;
  referenceCode1Id?: UUIDFilter | null;
  referenceCode2Id?: UUIDFilter | null;
  referenceCode3Id?: UUIDFilter | null;
  referenceCode4Id?: UUIDFilter | null;
  referenceCode5Id?: UUIDFilter | null;
  referenceCode6Id?: UUIDFilter | null;
  referenceCode7Id?: UUIDFilter | null;
  productionIncentiveId?: UUIDFilter | null;
  tax1099T4TypeId?: IntFilter | null;
  lookupAccountId?: UUIDFilter | null;
  projectReference?: StringFilter | null;
  accountReference?: StringFilter | null;
  setReference?: StringFilter | null;
  taxReference?: StringFilter | null;
  referenceCode1?: StringFilter | null;
  referenceCode2?: StringFilter | null;
  referenceCode3?: StringFilter | null;
  referenceCode4?: StringFilter | null;
  referenceCode5?: StringFilter | null;
  referenceCode6?: StringFilter | null;
  referenceCode7?: StringFilter | null;
  additionalDescription?: StringFilter | null;
  allocationPercent?: BigFloatFilter | null;
  distributionAmount?: BigFloatFilter | null;
  retiredAmount?: BigFloatFilter | null;
  businessUnitId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _accountName?: StringFilter | null;
  purchaseOrderItem?: PurchaseOrderItemFilter | null;
  purchaseOrderItemExists?: boolean | null;
  productionIncentive?: ProductionIncentiveFilter | null;
  productionIncentiveExists?: boolean | null;
  tax1099t4Type?: Tax1099T4TypeFilter | null;
  tax1099t4TypeExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  lookupAccount?: LookupAccountFilter | null;
  lookupAccountExists?: boolean | null;
  reference1Code?: Reference1CodeFilter | null;
  reference1CodeExists?: boolean | null;
  reference2Code?: Reference2CodeFilter | null;
  reference2CodeExists?: boolean | null;
  reference3Code?: Reference3CodeFilter | null;
  reference3CodeExists?: boolean | null;
  reference4Code?: Reference4CodeFilter | null;
  reference4CodeExists?: boolean | null;
  reference5Code?: Reference5CodeFilter | null;
  reference5CodeExists?: boolean | null;
  project?: ProjectFilter | null;
  projectExists?: boolean | null;
  and?: PurchaseOrderItemDistributionFilter[] | null;
  or?: PurchaseOrderItemDistributionFilter[] | null;
  not?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * An input for mutations affecting `PurchaseOrderItemDistribution`
 */
export interface PurchaseOrderItemDistributionInput {
  projectId?: GqlUUID | null;
  accountId?: GqlUUID | null;
  setAccountId?: GqlUUID | null;
  referenceCode1Id?: GqlUUID | null;
  referenceCode2Id?: GqlUUID | null;
  referenceCode3Id?: GqlUUID | null;
  referenceCode4Id?: GqlUUID | null;
  referenceCode5Id?: GqlUUID | null;
  referenceCode6Id?: GqlUUID | null;
  referenceCode7Id?: GqlUUID | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  projectReference?: string | null;
  accountReference?: string | null;
  setReference?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  additionalDescription?: string | null;
  allocationPercent?: GqlBigFloat | null;
  distributionAmount?: GqlBigFloat | null;
}

/**
 * Represents an update to a `PurchaseOrderItemDistribution`. Fields that are set will be updated.
 */
export interface PurchaseOrderItemDistributionPatch {
  projectId?: GqlUUID | null;
  accountId?: GqlUUID | null;
  setAccountId?: GqlUUID | null;
  referenceCode1Id?: GqlUUID | null;
  referenceCode2Id?: GqlUUID | null;
  referenceCode3Id?: GqlUUID | null;
  referenceCode4Id?: GqlUUID | null;
  referenceCode5Id?: GqlUUID | null;
  referenceCode6Id?: GqlUUID | null;
  referenceCode7Id?: GqlUUID | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  projectReference?: string | null;
  accountReference?: string | null;
  setReference?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  additionalDescription?: string | null;
  allocationPercent?: GqlBigFloat | null;
  distributionAmount?: GqlBigFloat | null;
}

/**
 * An input for mutations affecting `PurchaseOrderItemDistributionUpdateType`
 */
export interface PurchaseOrderItemDistributionUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  purchaseOrderItemDistributionUpdatePatch?: PurchaseOrderItemDistributionPatch | null;
}

/**
 * A filter to be used against `PurchaseOrderItem` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderItemFilter {
  id?: UUIDFilter | null;
  purchaseOrderId?: UUIDFilter | null;
  purchaseOrderItemTypeId?: UUIDFilter | null;
  additionalDepartmentId?: UUIDFilter | null;
  expenditureTypeId?: UUIDFilter | null;
  unitOfMeasureItemId?: UUIDFilter | null;
  itemNumber?: StringFilter | null;
  description?: StringFilter | null;
  itemFromDate?: DateFilter | null;
  itemToDate?: DateFilter | null;
  itemRentalDates?: DateListFilter | null;
  itemQuantity?: BigFloatFilter | null;
  itemUnitPrice?: BigFloatFilter | null;
  itemExtendedAmount?: BigFloatFilter | null;
  itemYears?: IntFilter | null;
  itemYearRate?: BigFloatFilter | null;
  itemYearAmount?: BigFloatFilter | null;
  itemMonths?: IntFilter | null;
  itemMonthRate?: BigFloatFilter | null;
  itemMonthAmount?: BigFloatFilter | null;
  itemWeeks?: IntFilter | null;
  itemWeekRate?: BigFloatFilter | null;
  itemWeekAmount?: BigFloatFilter | null;
  itemDays?: IntFilter | null;
  itemDayRate?: BigFloatFilter | null;
  itemDayAmount?: BigFloatFilter | null;
  itemHours?: BigFloatFilter | null;
  itemHourRate?: BigFloatFilter | null;
  itemHourAmount?: BigFloatFilter | null;
  isTaxable?: BooleanFilter | null;
  isDiscounted?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  displayPoItemRentalTerm?: StringFilter | null;
  displayPoItemDescription?: StringFilter | null;
  displayPoItemAccount?: StringFilter | null;
  displayPoItemUnitPrice?: StringFilter | null;
  displayPoItemTotal?: StringFilter | null;
  calculationType?: StandardCalculationTypeFilter | null;
  _purchaseOrderDeliveryId?: UUIDFilter | null;
  deliveryAddressId?: UUIDFilter | null;
  isDeliveryAddressProtected?: BooleanFilter | null;
  scheduledDeliveryDate?: DateFilter | null;
  isDeliveryTracked?: BooleanFilter | null;
  isPackingSlipAttachmentRequired?: BooleanFilter | null;
  _isDeliveryComplete?: BooleanFilter | null;
  _isReturned?: BooleanFilter | null;
  _isPartialReturn?: BooleanFilter | null;
  deliveryNotes?: StringFilter | null;
  deliveryAddressLine1?: StringFilter | null;
  deliveryAddressLine2?: StringFilter | null;
  deliveryCityName?: StringFilter | null;
  deliveryAttention?: StringFilter | null;
  deliveryPostalCode?: StringFilter | null;
  deliveryCountryId?: IntFilter | null;
  deliveryStateRegionId?: IntFilter | null;
  deliveryMainContact?: StringFilter | null;
  deliveryAlternateContact?: StringFilter | null;
  deliveryEmailAddress?: StringFilter | null;
  deliveryTelephoneNumber?: StringFilter | null;
  deliveryMobileNumber?: StringFilter | null;
  additionalBusinessUnitId?: UUIDFilter | null;
  _isPromoteAddressAllowed?: BooleanFilter | null;
  displayPoItemQuantity?: StringFilter | null;
  taxTypeId?: IntFilter | null;
  subjectToAmount?: BigFloatFilter | null;
  _extendedItemTotal?: BigFloatFilter | null;
  _sortItemNumber?: StringFilter | null;
  displayPoItemType?: StringFilter | null;
  approvalHistoryItemsByEntityItemId?: PurchaseOrderItemToManyApprovalHistoryItemFilter | null;
  approvalHistoryItemsByEntityItemIdExist?: boolean | null;
  purchaseOrderItemDistributions?: PurchaseOrderItemToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsExist?: boolean | null;
  purchaseOrder?: PurchaseOrderFilter | null;
  purchaseOrderExists?: boolean | null;
  purchaseOrderItemType?: PurchaseOrderItemTypeFilter | null;
  purchaseOrderItemTypeExists?: boolean | null;
  expenditureType?: ExpenditureTypeFilter | null;
  expenditureTypeExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  unitOfMeasureItem?: UnitOfMeasureItemFilter | null;
  unitOfMeasureItemExists?: boolean | null;
  taxTypes?: TaxTypeFilter | null;
  taxTypesExists?: boolean | null;
  and?: PurchaseOrderItemFilter[] | null;
  or?: PurchaseOrderItemFilter[] | null;
  not?: PurchaseOrderItemFilter | null;
}

/**
 * An input for mutations affecting `PurchaseOrderItem`
 */
export interface PurchaseOrderItemInput {
  purchaseOrderItemTypeId: GqlUUID;
  additionalDepartmentId?: GqlUUID | null;
  expenditureTypeId?: GqlUUID | null;
  unitOfMeasureItemId?: GqlUUID | null;
  itemNumber?: string | null;
  description?: string | null;
  itemFromDate?: GqlDate | null;
  itemToDate?: GqlDate | null;
  itemRentalDates?: (GqlDate | null)[] | null;
  itemQuantity?: GqlBigFloat | null;
  itemUnitPrice?: GqlBigFloat | null;
  itemExtendedAmount?: GqlBigFloat | null;
  itemYears?: number | null;
  itemYearRate?: GqlBigFloat | null;
  itemYearAmount?: GqlBigFloat | null;
  itemMonths?: number | null;
  itemMonthRate?: GqlBigFloat | null;
  itemMonthAmount?: GqlBigFloat | null;
  itemWeeks?: number | null;
  itemWeekRate?: GqlBigFloat | null;
  itemWeekAmount?: GqlBigFloat | null;
  itemDays?: number | null;
  itemDayRate?: GqlBigFloat | null;
  itemDayAmount?: GqlBigFloat | null;
  itemHours?: GqlBigFloat | null;
  itemHourRate?: GqlBigFloat | null;
  itemHourAmount?: GqlBigFloat | null;
  isTaxable?: boolean | null;
  isDiscounted?: boolean | null;
  deliveryAddressId?: GqlUUID | null;
  scheduledDeliveryDate?: GqlDate | null;
  isDeliveryTracked?: boolean | null;
  isPackingSlipAttachmentRequired?: boolean | null;
  deliveryNotes?: string | null;
  deliveryAddressLine1?: string | null;
  deliveryAddressLine2?: string | null;
  deliveryCityName?: string | null;
  deliveryAttention?: string | null;
  deliveryPostalCode?: string | null;
  deliveryCountryId?: number | null;
  deliveryStateRegionId?: number | null;
  deliveryMainContact?: string | null;
  deliveryAlternateContact?: string | null;
  deliveryEmailAddress?: string | null;
  deliveryTelephoneNumber?: string | null;
  deliveryMobileNumber?: string | null;
  additionalBusinessUnitId?: GqlUUID | null;
  taxTypeId?: number | null;
  subjectToAmount?: GqlBigFloat | null;
}

/**
 * Represents an update to a `PurchaseOrderItem`. Fields that are set will be updated.
 */
export interface PurchaseOrderItemPatch {
  purchaseOrderItemTypeId?: GqlUUID | null;
  additionalDepartmentId?: GqlUUID | null;
  expenditureTypeId?: GqlUUID | null;
  unitOfMeasureItemId?: GqlUUID | null;
  itemNumber?: string | null;
  description?: string | null;
  itemFromDate?: GqlDate | null;
  itemToDate?: GqlDate | null;
  itemRentalDates?: (GqlDate | null)[] | null;
  itemQuantity?: GqlBigFloat | null;
  itemUnitPrice?: GqlBigFloat | null;
  itemExtendedAmount?: GqlBigFloat | null;
  itemYears?: number | null;
  itemYearRate?: GqlBigFloat | null;
  itemYearAmount?: GqlBigFloat | null;
  itemMonths?: number | null;
  itemMonthRate?: GqlBigFloat | null;
  itemMonthAmount?: GqlBigFloat | null;
  itemWeeks?: number | null;
  itemWeekRate?: GqlBigFloat | null;
  itemWeekAmount?: GqlBigFloat | null;
  itemDays?: number | null;
  itemDayRate?: GqlBigFloat | null;
  itemDayAmount?: GqlBigFloat | null;
  itemHours?: GqlBigFloat | null;
  itemHourRate?: GqlBigFloat | null;
  itemHourAmount?: GqlBigFloat | null;
  isTaxable?: boolean | null;
  isDiscounted?: boolean | null;
  deliveryAddressId?: GqlUUID | null;
  scheduledDeliveryDate?: GqlDate | null;
  isDeliveryTracked?: boolean | null;
  isPackingSlipAttachmentRequired?: boolean | null;
  deliveryNotes?: string | null;
  deliveryAddressLine1?: string | null;
  deliveryAddressLine2?: string | null;
  deliveryCityName?: string | null;
  deliveryAttention?: string | null;
  deliveryPostalCode?: string | null;
  deliveryCountryId?: number | null;
  deliveryStateRegionId?: number | null;
  deliveryMainContact?: string | null;
  deliveryAlternateContact?: string | null;
  deliveryEmailAddress?: string | null;
  deliveryTelephoneNumber?: string | null;
  deliveryMobileNumber?: string | null;
  additionalBusinessUnitId?: GqlUUID | null;
  taxTypeId?: number | null;
  subjectToAmount?: GqlBigFloat | null;
}

/**
 * A filter to be used against many `ApprovalHistoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderItemToManyApprovalHistoryItemFilter {
  every?: ApprovalHistoryItemFilter | null;
  some?: ApprovalHistoryItemFilter | null;
  none?: ApprovalHistoryItemFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderItemToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against `PurchaseOrderItemType` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderItemTypeFilter {
  id?: UUIDFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  itemListSortOrder?: IntFilter | null;
  unitOfMeasureGroupId?: UUIDFilter | null;
  calculationType?: StandardCalculationTypeFilter | null;
  isRental?: BooleanFilter | null;
  isPurchase?: BooleanFilter | null;
  isTravel?: BooleanFilter | null;
  isTax?: BooleanFilter | null;
  isDiscount?: BooleanFilter | null;
  isDelivery?: BooleanFilter | null;
  isExpenditureTypeAllowed?: BooleanFilter | null;
  isExpenditureTypeRequired?: BooleanFilter | null;
  isUnitOfMeasureRequired?: BooleanFilter | null;
  isTaxAllowed?: BooleanFilter | null;
  isDiscountAllowed?: BooleanFilter | null;
  isQuantityAllowed?: BooleanFilter | null;
  isDescriptionDateLast?: BooleanFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  requiredDocumentGroupId?: UUIDFilter | null;
  attachedDocumentGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  isAutoCalculated?: BooleanFilter | null;
  isOrderItemSelectable?: BooleanFilter | null;
  purchaseOrderItems?: PurchaseOrderItemTypeToManyPurchaseOrderItemFilter | null;
  purchaseOrderItemsExist?: boolean | null;
  unitOfMeasureGroup?: UnitOfMeasureGroupFilter | null;
  unitOfMeasureGroupExists?: boolean | null;
  and?: PurchaseOrderItemTypeFilter[] | null;
  or?: PurchaseOrderItemTypeFilter[] | null;
  not?: PurchaseOrderItemTypeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItem` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderItemTypeToManyPurchaseOrderItemFilter {
  every?: PurchaseOrderItemFilter | null;
  some?: PurchaseOrderItemFilter | null;
  none?: PurchaseOrderItemFilter | null;
}

/**
 * All input for the `purchaseOrderItemUpdate` mutation.
 */
export interface PurchaseOrderItemUpdateInput {
  clientMutationId?: string | null;
  purchaseOrderItemId: GqlUUID;
  rowTimestamp: GqlDatetime;
  purchaseOrderItemPatch?: PurchaseOrderItemPatch | null;
  purchaseOrderItemDistributionCreate?: (PurchaseOrderItemDistributionInput | null)[] | null;
  purchaseOrderItemDistributionUpdate?: (PurchaseOrderItemDistributionUpdateTypeInput | null)[] | null;
  purchaseOrderItemDistributionDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * Represents an update to a `PurchaseOrder`. Fields that are set will be updated.
 */
export interface PurchaseOrderPatch {
  supplierId?: GqlUUID | null;
  purchaseOrderTypeId?: number | null;
  description?: string | null;
  reason?: string | null;
  purchaseOrderDate?: GqlDate | null;
  currencyId?: number | null;
  departmentId?: GqlUUID | null;
  businessUnitId?: GqlUUID | null;
  deliveryAddressId?: GqlUUID | null;
  scheduledDeliveryDate?: GqlDate | null;
  isDeliveryTracked?: boolean | null;
  isPackingSlipAttachmentRequired?: boolean | null;
  deliveryNotes?: string | null;
  deliveryAddressLine1?: string | null;
  deliveryAddressLine2?: string | null;
  deliveryCityName?: string | null;
  deliveryAttention?: string | null;
  deliveryPostalCode?: string | null;
  deliveryCountryId?: number | null;
  deliveryStateRegionId?: number | null;
  deliveryMainContact?: string | null;
  deliveryAlternateContact?: string | null;
  deliveryEmailAddress?: string | null;
  deliveryTelephoneNumber?: string | null;
  deliveryMobileNumber?: string | null;
  _pendingApprovers?: string | null;
  _accountingExportDate?: GqlDatetime | null;
  _accountingExportCycleId?: GqlUUID | null;
  _accountingExportUserId?: GqlUUID | null;
}

/**
 * All input for the `purchaseOrderPromoteDeliveryAddress` mutation.
 */
export interface PurchaseOrderPromoteDeliveryAddressInput {
  clientMutationId?: string | null;
  purchaseOrderId: GqlUUID;
  rowTimeStamp: GqlDatetime;
  aliasName: string;
}

/**
 * A filter to be used against `PurchaseOrderRevisionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderRevisionHistoryFilter {
  id?: UUIDFilter | null;
  purchaseOrderType?: StringFilter | null;
  supplierReferenceName?: StringFilter | null;
  description?: StringFilter | null;
  purchaseOrderNumber?: StringFilter | null;
  poTotal?: BigFloatFilter | null;
  poGroupId?: UUIDFilter | null;
  _purchaseOrderType?: StringFilter | null;
  dateRequested?: DatetimeFilter | null;
  requesterName?: StringFilter | null;
  poRevisionHistory?: PurchaseOrderFilter | null;
  poRevisionHistoryExists?: boolean | null;
  and?: PurchaseOrderRevisionHistoryFilter[] | null;
  or?: PurchaseOrderRevisionHistoryFilter[] | null;
  not?: PurchaseOrderRevisionHistoryFilter | null;
}

/**
 * All input for the `purchaseOrderRevisionUpdate` mutation.
 */
export interface PurchaseOrderRevisionUpdateInput {
  clientMutationId?: string | null;
  sourceEntityDocumentId: GqlUUID;
  destinationEntityDocumentId: GqlUUID;
}

/**
 * All input for the `purchaseOrderRowProtection` mutation.
 */
export interface PurchaseOrderRowProtectionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  isProtectionRemoval?: boolean | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyEmailDocumentFilter {
  every?: EmailDocumentFilter | null;
  some?: EmailDocumentFilter | null;
  none?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityEnvironmental` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyEntityEnvironmentalFilter {
  every?: EntityEnvironmentalFilter | null;
  some?: EntityEnvironmentalFilter | null;
  none?: EntityEnvironmentalFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyPurchaseOrderHistoryFilter {
  every?: PurchaseOrderHistoryFilter | null;
  some?: PurchaseOrderHistoryFilter | null;
  none?: PurchaseOrderHistoryFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceSchedule` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyPurchaseOrderInvoiceScheduleFilter {
  every?: PurchaseOrderInvoiceScheduleFilter | null;
  some?: PurchaseOrderInvoiceScheduleFilter | null;
  none?: PurchaseOrderInvoiceScheduleFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItem` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyPurchaseOrderItemFilter {
  every?: PurchaseOrderItemFilter | null;
  some?: PurchaseOrderItemFilter | null;
  none?: PurchaseOrderItemFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderRevisionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyPurchaseOrderRevisionHistoryFilter {
  every?: PurchaseOrderRevisionHistoryFilter | null;
  some?: PurchaseOrderRevisionHistoryFilter | null;
  none?: PurchaseOrderRevisionHistoryFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against `PurchaseOrderType` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderTypeFilter {
  id?: IntFilter | null;
  purchaseOrderType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isDefault?: BooleanFilter | null;
  isStandardPurchaseOrder?: BooleanFilter | null;
  isBlanketPurchaseOrder?: BooleanFilter | null;
  isPlannedPurchaseOrder?: BooleanFilter | null;
  isContractualPurchaseOrder?: BooleanFilter | null;
  isExternalPurchaseOrder?: BooleanFilter | null;
  isScheduledRelease?: BooleanFilter | null;
  isBlanketRelease?: BooleanFilter | null;
  isRequestForQuote?: BooleanFilter | null;
  isTravelAuthorization?: BooleanFilter | null;
  isCreateEntryAllowed?: BooleanFilter | null;
  isApprovalRequired?: BooleanFilter | null;
  isReceivingGoodsRequired?: BooleanFilter | null;
  isInvoiceMatchingRequired?: BooleanFilter | null;
  isAccountingIntegrationRequired?: BooleanFilter | null;
  isPurchasedGoods?: BooleanFilter | null;
  requiredDocumentGroupId?: UUIDFilter | null;
  isSupplierSendAllowed?: BooleanFilter | null;
  approvalTypeId?: IntFilter | null;
  isPunchoutProcessing?: BooleanFilter | null;
  isUserTemplateAllowed?: BooleanFilter | null;
  isProtectedFromUpdate?: BooleanFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  statementLayoutType?: TransactionLayoutFilter | null;
  attachedDocumentGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  approvalSelectionConditionsByRequesterPurchaseOrderTypeId?: PurchaseOrderTypeToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterPurchaseOrderTypeIdExist?: boolean | null;
  purchaseOrders?: PurchaseOrderTypeToManyPurchaseOrderFilter | null;
  purchaseOrdersExist?: boolean | null;
  and?: PurchaseOrderTypeFilter[] | null;
  or?: PurchaseOrderTypeFilter[] | null;
  not?: PurchaseOrderTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderTypeToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface PurchaseOrderTypeToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * All input for the `purchaseOrderUpdate` mutation.
 */
export interface PurchaseOrderUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  purchaseOrderPatch?: PurchaseOrderPatch | null;
}

/**
 * All input for the `purchaseOrderUrgentUpdate` mutation.
 */
export interface PurchaseOrderUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * A filter to be used against `RatingEntityResult` object types. All fields are combined with a logical ‘and.’
 */
export interface RatingEntityResultFilter {
  id?: UUIDFilter | null;
  countTotal?: IntFilter | null;
  starValue?: BigFloatFilter | null;
  star1Percent?: IntFilter | null;
  star2Percent?: IntFilter | null;
  star3Percent?: IntFilter | null;
  star4Percent?: IntFilter | null;
  star5Percent?: IntFilter | null;
  vendorsByRatingVendorId?: RatingEntityResultToManyVendorFilter | null;
  vendorsByRatingVendorIdExist?: boolean | null;
  suppliersByRatingVendorId?: RatingEntityResultToManySupplierFilter | null;
  suppliersByRatingVendorIdExist?: boolean | null;
  employeeRatingResults?: EmployeeFilter | null;
  employeeRatingResultsExists?: boolean | null;
  and?: RatingEntityResultFilter[] | null;
  or?: RatingEntityResultFilter[] | null;
  not?: RatingEntityResultFilter | null;
}

/**
 * A filter to be used against many `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface RatingEntityResultToManySupplierFilter {
  every?: SupplierFilter | null;
  some?: SupplierFilter | null;
  none?: SupplierFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface RatingEntityResultToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * A filter to be used against `Rating` object types. All fields are combined with a logical ‘and.’
 */
export interface RatingFilter {
  id?: UUIDFilter | null;
  ratingComment?: StringFilter | null;
  ratingValue?: IntFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _createdByUserName?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  employeeRating?: EmployeeFilter | null;
  employeeRatingExists?: boolean | null;
  vendorRating?: VendorFilter | null;
  vendorRatingExists?: boolean | null;
  supplierRating?: SupplierFilter | null;
  supplierRatingExists?: boolean | null;
  and?: RatingFilter[] | null;
  or?: RatingFilter[] | null;
  not?: RatingFilter | null;
}

/**
 * An input for mutations affecting `Rating`
 */
export interface RatingInput {
  ratingComment?: string | null;
  ratingValue: number;
  entityId: GqlUUID;
}

/**
 * All input for the `ratingSupplierCreate` mutation.
 */
export interface RatingSupplierCreateInput {
  clientMutationId?: string | null;
  rating: RatingInput;
}

/**
 * A filter to be used against `Reference1Code` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference1CodeFilter {
  id?: UUIDFilter | null;
  referenceTypeId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  name?: StringFilter | null;
  abbreviation?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode1Id?: Reference1CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode1IdExist?: boolean | null;
  purchaseOrderItemDistributionsByReferenceCode1Id?: Reference1CodeToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsByReferenceCode1IdExist?: boolean | null;
  tripDistributionsByReferenceCode1Id?: Reference1CodeToManyTripDistributionFilter | null;
  tripDistributionsByReferenceCode1IdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  referenceType?: ReferenceTypeFilter | null;
  referenceTypeExists?: boolean | null;
  and?: Reference1CodeFilter[] | null;
  or?: Reference1CodeFilter[] | null;
  not?: Reference1CodeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface Reference1CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference1CodeToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference1CodeToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against `Reference2Code` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference2CodeFilter {
  id?: UUIDFilter | null;
  referenceTypeId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  name?: StringFilter | null;
  abbreviation?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode2Id?: Reference2CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode2IdExist?: boolean | null;
  purchaseOrderItemDistributionsByReferenceCode2Id?: Reference2CodeToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsByReferenceCode2IdExist?: boolean | null;
  tripDistributionsByReferenceCode2Id?: Reference2CodeToManyTripDistributionFilter | null;
  tripDistributionsByReferenceCode2IdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  referenceType?: ReferenceTypeFilter | null;
  referenceTypeExists?: boolean | null;
  and?: Reference2CodeFilter[] | null;
  or?: Reference2CodeFilter[] | null;
  not?: Reference2CodeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface Reference2CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference2CodeToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference2CodeToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against `Reference3Code` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference3CodeFilter {
  id?: UUIDFilter | null;
  referenceTypeId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  name?: StringFilter | null;
  abbreviation?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode3Id?: Reference3CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode3IdExist?: boolean | null;
  purchaseOrderItemDistributionsByReferenceCode3Id?: Reference3CodeToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsByReferenceCode3IdExist?: boolean | null;
  tripDistributionsByReferenceCode3Id?: Reference3CodeToManyTripDistributionFilter | null;
  tripDistributionsByReferenceCode3IdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  referenceType?: ReferenceTypeFilter | null;
  referenceTypeExists?: boolean | null;
  and?: Reference3CodeFilter[] | null;
  or?: Reference3CodeFilter[] | null;
  not?: Reference3CodeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface Reference3CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference3CodeToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference3CodeToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against `Reference4Code` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference4CodeFilter {
  id?: UUIDFilter | null;
  referenceTypeId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  name?: StringFilter | null;
  abbreviation?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode4Id?: Reference4CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode4IdExist?: boolean | null;
  purchaseOrderItemDistributionsByReferenceCode4Id?: Reference4CodeToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsByReferenceCode4IdExist?: boolean | null;
  tripDistributionsByReferenceCode4Id?: Reference4CodeToManyTripDistributionFilter | null;
  tripDistributionsByReferenceCode4IdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  referenceType?: ReferenceTypeFilter | null;
  referenceTypeExists?: boolean | null;
  and?: Reference4CodeFilter[] | null;
  or?: Reference4CodeFilter[] | null;
  not?: Reference4CodeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface Reference4CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference4CodeToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference4CodeToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against `Reference5Code` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference5CodeFilter {
  id?: UUIDFilter | null;
  referenceTypeId?: UUIDFilter | null;
  referenceCode?: StringFilter | null;
  name?: StringFilter | null;
  abbreviation?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode5Id?: Reference5CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsByReferenceCode5IdExist?: boolean | null;
  purchaseOrderItemDistributionsByReferenceCode5Id?: Reference5CodeToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsByReferenceCode5IdExist?: boolean | null;
  tripDistributionsByReferenceCode5Id?: Reference5CodeToManyTripDistributionFilter | null;
  tripDistributionsByReferenceCode5IdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  referenceType?: ReferenceTypeFilter | null;
  referenceTypeExists?: boolean | null;
  and?: Reference5CodeFilter[] | null;
  or?: Reference5CodeFilter[] | null;
  not?: Reference5CodeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface Reference5CodeToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference5CodeToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Reference5CodeToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against `ReferenceType` object types. All fields are combined with a logical ‘and.’
 */
export interface ReferenceTypeFilter {
  id?: UUIDFilter | null;
  referenceType?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  isReference1?: BooleanFilter | null;
  isReference2?: BooleanFilter | null;
  isReference3?: BooleanFilter | null;
  isReference4?: BooleanFilter | null;
  isReference5?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  reference1Codes?: ReferenceTypeToManyReference1CodeFilter | null;
  reference1CodesExist?: boolean | null;
  reference2Codes?: ReferenceTypeToManyReference2CodeFilter | null;
  reference2CodesExist?: boolean | null;
  reference3Codes?: ReferenceTypeToManyReference3CodeFilter | null;
  reference3CodesExist?: boolean | null;
  reference4Codes?: ReferenceTypeToManyReference4CodeFilter | null;
  reference4CodesExist?: boolean | null;
  reference5Codes?: ReferenceTypeToManyReference5CodeFilter | null;
  reference5CodesExist?: boolean | null;
  and?: ReferenceTypeFilter[] | null;
  or?: ReferenceTypeFilter[] | null;
  not?: ReferenceTypeFilter | null;
}

/**
 * A filter to be used against many `Reference1Code` object types. All fields are combined with a logical ‘and.’
 */
export interface ReferenceTypeToManyReference1CodeFilter {
  every?: Reference1CodeFilter | null;
  some?: Reference1CodeFilter | null;
  none?: Reference1CodeFilter | null;
}

/**
 * A filter to be used against many `Reference2Code` object types. All fields are combined with a logical ‘and.’
 */
export interface ReferenceTypeToManyReference2CodeFilter {
  every?: Reference2CodeFilter | null;
  some?: Reference2CodeFilter | null;
  none?: Reference2CodeFilter | null;
}

/**
 * A filter to be used against many `Reference3Code` object types. All fields are combined with a logical ‘and.’
 */
export interface ReferenceTypeToManyReference3CodeFilter {
  every?: Reference3CodeFilter | null;
  some?: Reference3CodeFilter | null;
  none?: Reference3CodeFilter | null;
}

/**
 * A filter to be used against many `Reference4Code` object types. All fields are combined with a logical ‘and.’
 */
export interface ReferenceTypeToManyReference4CodeFilter {
  every?: Reference4CodeFilter | null;
  some?: Reference4CodeFilter | null;
  none?: Reference4CodeFilter | null;
}

/**
 * A filter to be used against many `Reference5Code` object types. All fields are combined with a logical ‘and.’
 */
export interface ReferenceTypeToManyReference5CodeFilter {
  every?: Reference5CodeFilter | null;
  some?: Reference5CodeFilter | null;
  none?: Reference5CodeFilter | null;
}

/**
 * All input for the `replaceApprover` mutation.
 */
export interface ReplaceApproverInput {
  clientMutationId?: string | null;
  originalUserProfileId: GqlUUID;
  isBypassOnly: boolean;
  reason: string;
  newUserProfileId?: GqlUUID | null;
}

/**
 * A filter to be used against ReportTypes fields. All fields are combined with a logical ‘and.’
 */
export interface ReportTypesFilter {
  isNull?: boolean | null;
  equalTo?: ReportTypes | null;
  notEqualTo?: ReportTypes | null;
  distinctFrom?: ReportTypes | null;
  notDistinctFrom?: ReportTypes | null;
  in?: ReportTypes[] | null;
  notIn?: ReportTypes[] | null;
  lessThan?: ReportTypes | null;
  lessThanOrEqualTo?: ReportTypes | null;
  greaterThan?: ReportTypes | null;
  greaterThanOrEqualTo?: ReportTypes | null;
}

/**
 * A filter to be used against `Role` object types. All fields are combined with a logical ‘and.’
 */
export interface RoleFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isEnvironmentalAllowed?: BooleanFilter | null;
  isDocumentManagementAllowed?: BooleanFilter | null;
  isNotificationsAllowed?: BooleanFilter | null;
  isNotesAllowed?: BooleanFilter | null;
  isTagsAllowed?: BooleanFilter | null;
  isRatingsAllowed?: BooleanFilter | null;
  isMessagingAllowed?: BooleanFilter | null;
  isHistoryAllowed?: BooleanFilter | null;
  roleType?: RoleTypesFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  departmentOccupationTemplates?: RoleToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: RoleToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  and?: RoleFilter[] | null;
  or?: RoleFilter[] | null;
  not?: RoleFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface RoleToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface RoleToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against RoleTypes fields. All fields are combined with a logical ‘and.’
 */
export interface RoleTypesFilter {
  isNull?: boolean | null;
  equalTo?: RoleTypes | null;
  notEqualTo?: RoleTypes | null;
  distinctFrom?: RoleTypes | null;
  notDistinctFrom?: RoleTypes | null;
  in?: RoleTypes[] | null;
  notIn?: RoleTypes[] | null;
  lessThan?: RoleTypes | null;
  lessThanOrEqualTo?: RoleTypes | null;
  greaterThan?: RoleTypes | null;
  greaterThanOrEqualTo?: RoleTypes | null;
}

/**
 * A filter to be used against `SearchHint` object types. All fields are combined with a logical ‘and.’
 */
export interface SearchHintFilter {
  id?: IntFilter | null;
  caption?: StringFilter | null;
  resultsCount?: IntFilter | null;
  linkType?: AppRouterFilter | null;
  hintLinkType?: UserAppFilter | null;
  hintLinkTypeExists?: boolean | null;
  and?: SearchHintFilter[] | null;
  or?: SearchHintFilter[] | null;
  not?: SearchHintFilter | null;
}

/**
 * An input for mutations affecting `SearchRequest`
 */
export interface SearchRequestInput {
  searchText: (string | null)[];
  isMatchAny?: boolean | null;
  searchHintId?: number | null;
}

/**
 * A filter to be used against `SecureRowLevel` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  securityLevel?: IntFilter | null;
  isMonitored?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  isVendorAliasAccessAllowed?: BooleanFilter | null;
  invoicesByRowSecurityId?: SecureRowLevelToManyInvoiceFilter | null;
  invoicesByRowSecurityIdExist?: boolean | null;
  payCyclesByRowSecurityId?: SecureRowLevelToManyPayCycleFilter | null;
  payCyclesByRowSecurityIdExist?: boolean | null;
  paymentsByRowSecurityId?: SecureRowLevelToManyPaymentFilter | null;
  paymentsByRowSecurityIdExist?: boolean | null;
  vendorsByRowSecurityId?: SecureRowLevelToManyVendorFilter | null;
  vendorsByRowSecurityIdExist?: boolean | null;
  approvalSelectionConditionsByRequesterSecureRowLevelId?: SecureRowLevelToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterSecureRowLevelIdExist?: boolean | null;
  departmentOccupationTemplates?: SecureRowLevelToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: SecureRowLevelToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  documentPoolsByRowSecurityId?: SecureRowLevelToManyDocumentPoolFilter | null;
  documentPoolsByRowSecurityIdExist?: boolean | null;
  documentPoolsByDefaultRowSecurityId?: SecureRowLevelToManyDocumentPoolFilter | null;
  documentPoolsByDefaultRowSecurityIdExist?: boolean | null;
  entityDocumentsByRowSecurityId?: SecureRowLevelToManyEntityDocumentFilter | null;
  entityDocumentsByRowSecurityIdExist?: boolean | null;
  purchaseOrdersByRowSecurityId?: SecureRowLevelToManyPurchaseOrderFilter | null;
  purchaseOrdersByRowSecurityIdExist?: boolean | null;
  suppliersByRowSecurityId?: SecureRowLevelToManySupplierFilter | null;
  suppliersByRowSecurityIdExist?: boolean | null;
  employeesByRowSecurityId?: SecureRowLevelToManyEmployeeFilter | null;
  employeesByRowSecurityIdExist?: boolean | null;
  travelAuthorizationsByRowSecurityId?: SecureRowLevelToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByRowSecurityIdExist?: boolean | null;
  travelersByRowSecurityId?: SecureRowLevelToManyTravelerFilter | null;
  travelersByRowSecurityIdExist?: boolean | null;
  tripBreakdownsByRowSecurityId?: SecureRowLevelToManyTripBreakdownFilter | null;
  tripBreakdownsByRowSecurityIdExist?: boolean | null;
  and?: SecureRowLevelFilter[] | null;
  or?: SecureRowLevelFilter[] | null;
  not?: SecureRowLevelFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyDocumentPoolFilter {
  every?: DocumentPoolFilter | null;
  some?: DocumentPoolFilter | null;
  none?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against many `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyEmployeeFilter {
  every?: EmployeeFilter | null;
  some?: EmployeeFilter | null;
  none?: EmployeeFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManySupplierFilter {
  every?: SupplierFilter | null;
  some?: SupplierFilter | null;
  none?: SupplierFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `Traveler` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyTravelerFilter {
  every?: TravelerFilter | null;
  some?: TravelerFilter | null;
  none?: TravelerFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface SecureRowLevelToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * A filter to be used against `SetAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface SetAccountFilter {
  id?: UUIDFilter | null;
  setAccountCode?: StringFilter | null;
  sortSetAccountCode?: StringFilter | null;
  formattedSetAccountCode?: StringFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  and?: SetAccountFilter[] | null;
  or?: SetAccountFilter[] | null;
  not?: SetAccountFilter | null;
}

/**
 * A filter to be used against `SicCode` object types. All fields are combined with a logical ‘and.’
 */
export interface SicCodeFilter {
  id?: UUIDFilter | null;
  code?: StringFilter | null;
  description?: StringFilter | null;
  isSicClassification?: BooleanFilter | null;
  isNaicsClassification?: BooleanFilter | null;
  isGicsClassification?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  vendorClassifications?: SicCodeToManyVendorClassificationFilter | null;
  vendorClassificationsExist?: boolean | null;
  and?: SicCodeFilter[] | null;
  or?: SicCodeFilter[] | null;
  not?: SicCodeFilter | null;
}

/**
 * A filter to be used against many `VendorClassification` object types. All fields are combined with a logical ‘and.’
 */
export interface SicCodeToManyVendorClassificationFilter {
  every?: VendorClassificationFilter | null;
  some?: VendorClassificationFilter | null;
  none?: VendorClassificationFilter | null;
}

/**
 * A filter to be used against StandardCalculationType fields. All fields are combined with a logical ‘and.’
 */
export interface StandardCalculationTypeFilter {
  isNull?: boolean | null;
  equalTo?: StandardCalculationType | null;
  notEqualTo?: StandardCalculationType | null;
  distinctFrom?: StandardCalculationType | null;
  notDistinctFrom?: StandardCalculationType | null;
  in?: StandardCalculationType[] | null;
  notIn?: StandardCalculationType[] | null;
  lessThan?: StandardCalculationType | null;
  lessThanOrEqualTo?: StandardCalculationType | null;
  greaterThan?: StandardCalculationType | null;
  greaterThanOrEqualTo?: StandardCalculationType | null;
}

/**
 * A filter to be used against `StateRegion` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionFilter {
  id?: IntFilter | null;
  stateRegion?: StringFilter | null;
  abbreviation?: StringFilter | null;
  countryId?: IntFilter | null;
  description?: StringFilter | null;
  stateHint?: StringFilter | null;
  apiSearch?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  stateRegionDescription?: StringFilter | null;
  vendorAddresses?: StateRegionToManyVendorAddressFilter | null;
  vendorAddressesExist?: boolean | null;
  cityAreas?: StateRegionToManyCityAreaFilter | null;
  cityAreasExist?: boolean | null;
  companyAddresses?: StateRegionToManyCompanyAddressFilter | null;
  companyAddressesExist?: boolean | null;
  deliveryAddresses?: StateRegionToManyDeliveryAddressFilter | null;
  deliveryAddressesExist?: boolean | null;
  lookupCities?: StateRegionToManyLookupCityFilter | null;
  lookupCitiesExist?: boolean | null;
  productionIncentiveGroups?: StateRegionToManyProductionIncentiveGroupFilter | null;
  productionIncentiveGroupsExist?: boolean | null;
  taxCodes?: StateRegionToManyTaxCodeFilter | null;
  taxCodesExist?: boolean | null;
  supplierAddresses?: StateRegionToManySupplierAddressFilter | null;
  supplierAddressesExist?: boolean | null;
  employeeAddresses?: StateRegionToManyEmployeeAddressFilter | null;
  employeeAddressesExist?: boolean | null;
  employeesByTaxStateRegionId?: StateRegionToManyEmployeeFilter | null;
  employeesByTaxStateRegionIdExist?: boolean | null;
  depotCities?: StateRegionToManyDepotCityFilter | null;
  depotCitiesExist?: boolean | null;
  travelPolicyAllowancesByStateRegion1Id?: StateRegionToManyTravelPolicyAllowanceFilter | null;
  travelPolicyAllowancesByStateRegion1IdExist?: boolean | null;
  travelPolicyAllowancesByStateRegion2Id?: StateRegionToManyTravelPolicyAllowanceFilter | null;
  travelPolicyAllowancesByStateRegion2IdExist?: boolean | null;
  travelerAddresses?: StateRegionToManyTravelerAddressFilter | null;
  travelerAddressesExist?: boolean | null;
  tripItemsByDepartureStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByDepartureStateRegionIdExist?: boolean | null;
  tripItemsByDepartureArrivalStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByDepartureArrivalStateRegionIdExist?: boolean | null;
  tripItemsByReturnStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByReturnStateRegionIdExist?: boolean | null;
  tripItemsByReturnArrivalStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByReturnArrivalStateRegionIdExist?: boolean | null;
  tripItemsByRentalStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByRentalStateRegionIdExist?: boolean | null;
  tripItemsByPickupStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByPickupStateRegionIdExist?: boolean | null;
  tripItemsByPickupDropStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByPickupDropStateRegionIdExist?: boolean | null;
  tripItemsByReturnPickupStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByReturnPickupStateRegionIdExist?: boolean | null;
  tripItemsByReturnDropStateRegionId?: StateRegionToManyTripItemFilter | null;
  tripItemsByReturnDropStateRegionIdExist?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  and?: StateRegionFilter[] | null;
  or?: StateRegionFilter[] | null;
  not?: StateRegionFilter | null;
}

/**
 * A filter to be used against many `CityArea` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyCityAreaFilter {
  every?: CityAreaFilter | null;
  some?: CityAreaFilter | null;
  none?: CityAreaFilter | null;
}

/**
 * A filter to be used against many `CompanyAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyCompanyAddressFilter {
  every?: CompanyAddressFilter | null;
  some?: CompanyAddressFilter | null;
  none?: CompanyAddressFilter | null;
}

/**
 * A filter to be used against many `DeliveryAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyDeliveryAddressFilter {
  every?: DeliveryAddressFilter | null;
  some?: DeliveryAddressFilter | null;
  none?: DeliveryAddressFilter | null;
}

/**
 * A filter to be used against many `DepotCity` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyDepotCityFilter {
  every?: DepotCityFilter | null;
  some?: DepotCityFilter | null;
  none?: DepotCityFilter | null;
}

/**
 * A filter to be used against many `EmployeeAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyEmployeeAddressFilter {
  every?: EmployeeAddressFilter | null;
  some?: EmployeeAddressFilter | null;
  none?: EmployeeAddressFilter | null;
}

/**
 * A filter to be used against many `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyEmployeeFilter {
  every?: EmployeeFilter | null;
  some?: EmployeeFilter | null;
  none?: EmployeeFilter | null;
}

/**
 * A filter to be used against many `LookupCity` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyLookupCityFilter {
  every?: LookupCityFilter | null;
  some?: LookupCityFilter | null;
  none?: LookupCityFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyProductionIncentiveGroupFilter {
  every?: ProductionIncentiveGroupFilter | null;
  some?: ProductionIncentiveGroupFilter | null;
  none?: ProductionIncentiveGroupFilter | null;
}

/**
 * A filter to be used against many `SupplierAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManySupplierAddressFilter {
  every?: SupplierAddressFilter | null;
  some?: SupplierAddressFilter | null;
  none?: SupplierAddressFilter | null;
}

/**
 * A filter to be used against many `TaxCode` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyTaxCodeFilter {
  every?: TaxCodeFilter | null;
  some?: TaxCodeFilter | null;
  none?: TaxCodeFilter | null;
}

/**
 * A filter to be used against many `TravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyTravelPolicyAllowanceFilter {
  every?: TravelPolicyAllowanceFilter | null;
  some?: TravelPolicyAllowanceFilter | null;
  none?: TravelPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against many `TravelerAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyTravelerAddressFilter {
  every?: TravelerAddressFilter | null;
  some?: TravelerAddressFilter | null;
  none?: TravelerAddressFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against many `VendorAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface StateRegionToManyVendorAddressFilter {
  every?: VendorAddressFilter | null;
  some?: VendorAddressFilter | null;
  none?: VendorAddressFilter | null;
}

/**
 * A filter to be used against `StatusType` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeFilter {
  id?: IntFilter | null;
  statusType?: StringFilter | null;
  description?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  isResponseRequired?: BooleanFilter | null;
  approvalState?: ApprovalStateFilter | null;
  isApprovalProcessing?: BooleanFilter | null;
  isDraftReleaseRequired?: BooleanFilter | null;
  isApprovalHintOverride?: BooleanFilter | null;
  _transactionIconStatus?: IconStateFilter | null;
  batchTransactionsByStatusTypeId?: StatusTypeToManyBatchTransactionFilter | null;
  batchTransactionsByStatusTypeIdExist?: boolean | null;
  invoicePaymentsByStatusTypeId?: StatusTypeToManyInvoicePaymentFilter | null;
  invoicePaymentsByStatusTypeIdExist?: boolean | null;
  invoicesByStatusTypeId?: StatusTypeToManyInvoiceFilter | null;
  invoicesByStatusTypeIdExist?: boolean | null;
  invoicesByAdditionalInformationStatusTypeId?: StatusTypeToManyInvoiceFilter | null;
  invoicesByAdditionalInformationStatusTypeIdExist?: boolean | null;
  payCyclesByStatusTypeId?: StatusTypeToManyPayCycleFilter | null;
  payCyclesByStatusTypeIdExist?: boolean | null;
  paymentsByStatusTypeId?: StatusTypeToManyPaymentFilter | null;
  paymentsByStatusTypeIdExist?: boolean | null;
  vendorsByStatusTypeId?: StatusTypeToManyVendorFilter | null;
  vendorsByStatusTypeIdExist?: boolean | null;
  approvalHistories?: StatusTypeToManyApprovalHistoryFilter | null;
  approvalHistoriesExist?: boolean | null;
  approvalHistoriesByBranchStatusTypeId?: StatusTypeToManyApprovalHistoryFilter | null;
  approvalHistoriesByBranchStatusTypeIdExist?: boolean | null;
  approvalHistoriesByTreeStatusTypeId?: StatusTypeToManyApprovalHistoryFilter | null;
  approvalHistoriesByTreeStatusTypeIdExist?: boolean | null;
  approvalsByStatusTypeId?: StatusTypeToManyApprovalFilter | null;
  approvalsByStatusTypeIdExist?: boolean | null;
  approvalsByApprovalPhaseTypeId?: StatusTypeToManyApprovalFilter | null;
  approvalsByApprovalPhaseTypeIdExist?: boolean | null;
  userApprovals?: StatusTypeToManyUserApprovalFilter | null;
  userApprovalsExist?: boolean | null;
  userApprovalsByBranchStatusTypeId?: StatusTypeToManyUserApprovalFilter | null;
  userApprovalsByBranchStatusTypeIdExist?: boolean | null;
  userApprovalsByTreeStatusTypeId?: StatusTypeToManyUserApprovalFilter | null;
  userApprovalsByTreeStatusTypeIdExist?: boolean | null;
  mobileUserNotifications?: StatusTypeToManyMobileUserNotificationFilter | null;
  mobileUserNotificationsExist?: boolean | null;
  userNotifications?: StatusTypeToManyUserNotificationFilter | null;
  userNotificationsExist?: boolean | null;
  businessUnitsByStatusTypeId?: StatusTypeToManyBusinessUnitFilter | null;
  businessUnitsByStatusTypeIdExist?: boolean | null;
  departmentsByStatusTypeId?: StatusTypeToManyDepartmentFilter | null;
  departmentsByStatusTypeIdExist?: boolean | null;
  transactionTransfersByStatusTypeId?: StatusTypeToManyTransactionTransferFilter | null;
  transactionTransfersByStatusTypeIdExist?: boolean | null;
  transferCyclesByStatusTypeId?: StatusTypeToManyTransferCycleFilter | null;
  transferCyclesByStatusTypeIdExist?: boolean | null;
  cardAccountTransactionsByStatusTypeId?: StatusTypeToManyCardAccountTransactionFilter | null;
  cardAccountTransactionsByStatusTypeIdExist?: boolean | null;
  cardAccountsByStatusTypeId?: StatusTypeToManyCardAccountFilter | null;
  cardAccountsByStatusTypeIdExist?: boolean | null;
  cardHolderTransactionsByStatusTypeId?: StatusTypeToManyCardHolderTransactionFilter | null;
  cardHolderTransactionsByStatusTypeIdExist?: boolean | null;
  cardHoldersByStatusTypeId?: StatusTypeToManyCardHolderFilter | null;
  cardHoldersByStatusTypeIdExist?: boolean | null;
  chartOfAccountsByStatusTypeId?: StatusTypeToManyChartOfAccountFilter | null;
  chartOfAccountsByStatusTypeIdExist?: boolean | null;
  companyChartOfAccountsByStatusTypeId?: StatusTypeToManyCompanyChartOfAccountFilter | null;
  companyChartOfAccountsByStatusTypeIdExist?: boolean | null;
  corporateChartOfAccountsByStatusTypeId?: StatusTypeToManyCorporateChartOfAccountFilter | null;
  corporateChartOfAccountsByStatusTypeIdExist?: boolean | null;
  corporateWorkgroupsByStatusTypeId?: StatusTypeToManyCorporateWorkgroupFilter | null;
  corporateWorkgroupsByStatusTypeIdExist?: boolean | null;
  projectsByStatusTypeId?: StatusTypeToManyProjectFilter | null;
  projectsByStatusTypeIdExist?: boolean | null;
  reference1CodesByStatusTypeId?: StatusTypeToManyReference1CodeFilter | null;
  reference1CodesByStatusTypeIdExist?: boolean | null;
  reference2CodesByStatusTypeId?: StatusTypeToManyReference2CodeFilter | null;
  reference2CodesByStatusTypeIdExist?: boolean | null;
  reference3CodesByStatusTypeId?: StatusTypeToManyReference3CodeFilter | null;
  reference3CodesByStatusTypeIdExist?: boolean | null;
  reference4CodesByStatusTypeId?: StatusTypeToManyReference4CodeFilter | null;
  reference4CodesByStatusTypeIdExist?: boolean | null;
  reference5CodesByStatusTypeId?: StatusTypeToManyReference5CodeFilter | null;
  reference5CodesByStatusTypeIdExist?: boolean | null;
  setAccountsByStatusTypeId?: StatusTypeToManySetAccountFilter | null;
  setAccountsByStatusTypeIdExist?: boolean | null;
  purchaseOrdersByStatusTypeId?: StatusTypeToManyPurchaseOrderFilter | null;
  purchaseOrdersByStatusTypeIdExist?: boolean | null;
  suppliersByStatusTypeId?: StatusTypeToManySupplierFilter | null;
  suppliersByStatusTypeIdExist?: boolean | null;
  employeesByDummyStatusTypeId?: StatusTypeToManyEmployeeFilter | null;
  employeesByDummyStatusTypeIdExist?: boolean | null;
  travelAuthorizationsByStatusTypeId?: StatusTypeToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByStatusTypeIdExist?: boolean | null;
  travelersByStatusTypeId?: StatusTypeToManyTravelerFilter | null;
  travelersByStatusTypeIdExist?: boolean | null;
  tripBreakdownsByTaStatusTypeId?: StatusTypeToManyTripBreakdownFilter | null;
  tripBreakdownsByTaStatusTypeIdExist?: boolean | null;
  tripsByStatusTypeId?: StatusTypeToManyTripFilter | null;
  tripsByStatusTypeIdExist?: boolean | null;
  and?: StatusTypeFilter[] | null;
  or?: StatusTypeFilter[] | null;
  not?: StatusTypeFilter | null;
}

/**
 * A filter to be used against many `Approval` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyApprovalFilter {
  every?: ApprovalFilter | null;
  some?: ApprovalFilter | null;
  none?: ApprovalFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `BatchTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyBatchTransactionFilter {
  every?: BatchTransactionFilter | null;
  some?: BatchTransactionFilter | null;
  none?: BatchTransactionFilter | null;
}

/**
 * A filter to be used against many `BusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyBusinessUnitFilter {
  every?: BusinessUnitFilter | null;
  some?: BusinessUnitFilter | null;
  none?: BusinessUnitFilter | null;
}

/**
 * A filter to be used against many `CardAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyCardAccountFilter {
  every?: CardAccountFilter | null;
  some?: CardAccountFilter | null;
  none?: CardAccountFilter | null;
}

/**
 * A filter to be used against many `CardAccountTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyCardAccountTransactionFilter {
  every?: CardAccountTransactionFilter | null;
  some?: CardAccountTransactionFilter | null;
  none?: CardAccountTransactionFilter | null;
}

/**
 * A filter to be used against many `CardHolder` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyCardHolderFilter {
  every?: CardHolderFilter | null;
  some?: CardHolderFilter | null;
  none?: CardHolderFilter | null;
}

/**
 * A filter to be used against many `CardHolderTransaction` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyCardHolderTransactionFilter {
  every?: CardHolderTransactionFilter | null;
  some?: CardHolderTransactionFilter | null;
  none?: CardHolderTransactionFilter | null;
}

/**
 * A filter to be used against many `ChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyChartOfAccountFilter {
  every?: ChartOfAccountFilter | null;
  some?: ChartOfAccountFilter | null;
  none?: ChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CompanyChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyCompanyChartOfAccountFilter {
  every?: CompanyChartOfAccountFilter | null;
  some?: CompanyChartOfAccountFilter | null;
  none?: CompanyChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CorporateChartOfAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyCorporateChartOfAccountFilter {
  every?: CorporateChartOfAccountFilter | null;
  some?: CorporateChartOfAccountFilter | null;
  none?: CorporateChartOfAccountFilter | null;
}

/**
 * A filter to be used against many `CorporateWorkgroup` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyCorporateWorkgroupFilter {
  every?: CorporateWorkgroupFilter | null;
  some?: CorporateWorkgroupFilter | null;
  none?: CorporateWorkgroupFilter | null;
}

/**
 * A filter to be used against many `Department` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyDepartmentFilter {
  every?: DepartmentFilter | null;
  some?: DepartmentFilter | null;
  none?: DepartmentFilter | null;
}

/**
 * A filter to be used against many `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyEmployeeFilter {
  every?: EmployeeFilter | null;
  some?: EmployeeFilter | null;
  none?: EmployeeFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `InvoicePayment` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyInvoicePaymentFilter {
  every?: InvoicePaymentFilter | null;
  some?: InvoicePaymentFilter | null;
  none?: InvoicePaymentFilter | null;
}

/**
 * A filter to be used against many `MobileUserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyMobileUserNotificationFilter {
  every?: MobileUserNotificationFilter | null;
  some?: MobileUserNotificationFilter | null;
  none?: MobileUserNotificationFilter | null;
}

/**
 * A filter to be used against many `PayCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyPayCycleFilter {
  every?: PayCycleFilter | null;
  some?: PayCycleFilter | null;
  none?: PayCycleFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyProjectFilter {
  every?: ProjectFilter | null;
  some?: ProjectFilter | null;
  none?: ProjectFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `Reference1Code` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyReference1CodeFilter {
  every?: Reference1CodeFilter | null;
  some?: Reference1CodeFilter | null;
  none?: Reference1CodeFilter | null;
}

/**
 * A filter to be used against many `Reference2Code` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyReference2CodeFilter {
  every?: Reference2CodeFilter | null;
  some?: Reference2CodeFilter | null;
  none?: Reference2CodeFilter | null;
}

/**
 * A filter to be used against many `Reference3Code` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyReference3CodeFilter {
  every?: Reference3CodeFilter | null;
  some?: Reference3CodeFilter | null;
  none?: Reference3CodeFilter | null;
}

/**
 * A filter to be used against many `Reference4Code` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyReference4CodeFilter {
  every?: Reference4CodeFilter | null;
  some?: Reference4CodeFilter | null;
  none?: Reference4CodeFilter | null;
}

/**
 * A filter to be used against many `Reference5Code` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyReference5CodeFilter {
  every?: Reference5CodeFilter | null;
  some?: Reference5CodeFilter | null;
  none?: Reference5CodeFilter | null;
}

/**
 * A filter to be used against many `SetAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManySetAccountFilter {
  every?: SetAccountFilter | null;
  some?: SetAccountFilter | null;
  none?: SetAccountFilter | null;
}

/**
 * A filter to be used against many `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManySupplierFilter {
  every?: SupplierFilter | null;
  some?: SupplierFilter | null;
  none?: SupplierFilter | null;
}

/**
 * A filter to be used against many `TransactionTransfer` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyTransactionTransferFilter {
  every?: TransactionTransferFilter | null;
  some?: TransactionTransferFilter | null;
  none?: TransactionTransferFilter | null;
}

/**
 * A filter to be used against many `TransferCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyTransferCycleFilter {
  every?: TransferCycleFilter | null;
  some?: TransferCycleFilter | null;
  none?: TransferCycleFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `Traveler` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyTravelerFilter {
  every?: TravelerFilter | null;
  some?: TravelerFilter | null;
  none?: TravelerFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `Trip` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyTripFilter {
  every?: TripFilter | null;
  some?: TripFilter | null;
  none?: TripFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface StatusTypeToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * A filter to be used against String fields. All fields are combined with a logical ‘and.’
 */
export interface StringFilter {
  isNull?: boolean | null;
  equalTo?: string | null;
  notEqualTo?: string | null;
  distinctFrom?: string | null;
  notDistinctFrom?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  includes?: string | null;
  notIncludes?: string | null;
  includesInsensitive?: string | null;
  notIncludesInsensitive?: string | null;
  startsWith?: string | null;
  notStartsWith?: string | null;
  startsWithInsensitive?: string | null;
  notStartsWithInsensitive?: string | null;
  endsWith?: string | null;
  notEndsWith?: string | null;
  endsWithInsensitive?: string | null;
  notEndsWithInsensitive?: string | null;
  like?: string | null;
  notLike?: string | null;
  likeInsensitive?: string | null;
  notLikeInsensitive?: string | null;
  equalToInsensitive?: string | null;
  notEqualToInsensitive?: string | null;
  distinctFromInsensitive?: string | null;
  notDistinctFromInsensitive?: string | null;
  inInsensitive?: string[] | null;
  notInInsensitive?: string[] | null;
  lessThanInsensitive?: string | null;
  lessThanOrEqualToInsensitive?: string | null;
  greaterThanInsensitive?: string | null;
  greaterThanOrEqualToInsensitive?: string | null;
}

/**
 * A filter to be used against String List fields. All fields are combined with a logical ‘and.’
 */
export interface StringListFilter {
  isNull?: boolean | null;
  equalTo?: (string | null)[] | null;
  notEqualTo?: (string | null)[] | null;
  distinctFrom?: (string | null)[] | null;
  notDistinctFrom?: (string | null)[] | null;
  lessThan?: (string | null)[] | null;
  lessThanOrEqualTo?: (string | null)[] | null;
  greaterThan?: (string | null)[] | null;
  greaterThanOrEqualTo?: (string | null)[] | null;
  contains?: (string | null)[] | null;
  containedBy?: (string | null)[] | null;
  overlaps?: (string | null)[] | null;
  anyEqualTo?: string | null;
  anyNotEqualTo?: string | null;
  anyLessThan?: string | null;
  anyLessThanOrEqualTo?: string | null;
  anyGreaterThan?: string | null;
  anyGreaterThanOrEqualTo?: string | null;
}

/**
 * A filter to be used against `SupplierAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierAddressFilter {
  id?: UUIDFilter | null;
  _supplierId?: UUIDFilter | null;
  addressLine1?: StringFilter | null;
  addressLine2?: StringFilter | null;
  cityName?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  postalCode?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  emailAddress?: StringFilter | null;
  webAddress?: StringFilter | null;
  isSendEmailAccount?: BooleanFilter | null;
  isReceiveEmailAccount?: BooleanFilter | null;
  isPrimary?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isAddressVerified?: BooleanFilter | null;
  supplierAddressHistoriesByEntityId?: SupplierAddressToManySupplierAddressHistoryFilter | null;
  supplierAddressHistoriesByEntityIdExist?: boolean | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: SupplierAddressFilter[] | null;
  or?: SupplierAddressFilter[] | null;
  not?: SupplierAddressFilter | null;
}

/**
 * A filter to be used against `SupplierAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierAddressHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  supplierAddress?: SupplierAddressFilter | null;
  supplierAddressExists?: boolean | null;
  and?: SupplierAddressHistoryFilter[] | null;
  or?: SupplierAddressHistoryFilter[] | null;
  not?: SupplierAddressHistoryFilter | null;
}

/**
 * An input for mutations affecting `SupplierAddress`
 */
export interface SupplierAddressInput {
  addressLine1: string;
  addressLine2?: string | null;
  cityName?: string | null;
  countryId?: number | null;
  stateRegionId?: number | null;
  postalCode?: string | null;
  telephoneNumber?: string | null;
  emailAddress?: string | null;
  webAddress?: string | null;
  isSendEmailAccount?: boolean | null;
  isReceiveEmailAccount?: boolean | null;
  isPrimary?: boolean | null;
}

/**
 * Represents an update to a `SupplierAddress`. Fields that are set will be updated.
 */
export interface SupplierAddressPatch {
  addressLine1?: string | null;
  addressLine2?: string | null;
  cityName?: string | null;
  countryId?: number | null;
  stateRegionId?: number | null;
  postalCode?: string | null;
  telephoneNumber?: string | null;
  emailAddress?: string | null;
  webAddress?: string | null;
  isSendEmailAccount?: boolean | null;
  isReceiveEmailAccount?: boolean | null;
  isPrimary?: boolean | null;
}

/**
 * A filter to be used against many `SupplierAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierAddressToManySupplierAddressHistoryFilter {
  every?: SupplierAddressHistoryFilter | null;
  some?: SupplierAddressHistoryFilter | null;
  none?: SupplierAddressHistoryFilter | null;
}

/**
 * An input for mutations affecting `SupplierAddressUpdateType`
 */
export interface SupplierAddressUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  supplierAddressPatch?: SupplierAddressPatch | null;
}

/**
 * All input for the `supplierAliasNameUpdate` mutation.
 */
export interface SupplierAliasNameUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  companyOrLastName: string;
  firstName: string;
  nameAlias: string;
  isPurchaseOrderMutation?: boolean | null;
}

/**
 * All input for the `supplierApprovalCreate` mutation.
 */
export interface SupplierApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `supplierApprovalRevoke` mutation.
 */
export interface SupplierApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `supplierCompetitiveBidUpdate` mutation.
 */
export interface SupplierCompetitiveBidUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  isCompetitiveBidsRequired: boolean;
}

/**
 * A filter to be used against `SupplierContact` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierContactFilter {
  id?: UUIDFilter | null;
  _supplierId?: UUIDFilter | null;
  contactName?: StringFilter | null;
  emailAddress?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  isSendEmailAccount?: BooleanFilter | null;
  isReceiveEmailAccount?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  supplierContactHistoriesByEntityId?: SupplierContactToManySupplierContactHistoryFilter | null;
  supplierContactHistoriesByEntityIdExist?: boolean | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  and?: SupplierContactFilter[] | null;
  or?: SupplierContactFilter[] | null;
  not?: SupplierContactFilter | null;
}

/**
 * A filter to be used against `SupplierContactHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierContactHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  supplierContact?: SupplierContactFilter | null;
  supplierContactExists?: boolean | null;
  and?: SupplierContactHistoryFilter[] | null;
  or?: SupplierContactHistoryFilter[] | null;
  not?: SupplierContactHistoryFilter | null;
}

/**
 * An input for mutations affecting `SupplierContact`
 */
export interface SupplierContactInput {
  contactName: string;
  emailAddress?: string | null;
  telephoneNumber?: string | null;
  isSendEmailAccount?: boolean | null;
  isReceiveEmailAccount?: boolean | null;
}

/**
 * Represents an update to a `SupplierContact`. Fields that are set will be updated.
 */
export interface SupplierContactPatch {
  contactName?: string | null;
  emailAddress?: string | null;
  telephoneNumber?: string | null;
  isSendEmailAccount?: boolean | null;
  isReceiveEmailAccount?: boolean | null;
}

/**
 * A filter to be used against many `SupplierContactHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierContactToManySupplierContactHistoryFilter {
  every?: SupplierContactHistoryFilter | null;
  some?: SupplierContactHistoryFilter | null;
  none?: SupplierContactHistoryFilter | null;
}

/**
 * An input for mutations affecting `SupplierContactUpdateType`
 */
export interface SupplierContactUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  supplierContactPatch?: SupplierContactPatch | null;
}

/**
 * All input for the `supplierCreate` mutation.
 */
export interface SupplierCreateInput {
  clientMutationId?: string | null;
  supplier: SupplierInput;
  supplierAddresses?: (SupplierAddressInput | null)[] | null;
  supplierContacts?: (SupplierContactInput | null)[] | null;
  isPurchaseOrderMutation?: boolean | null;
}

/**
 * All input for the `supplierDelete` mutation.
 */
export interface SupplierDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
  isPurchaseOrderMutation?: boolean | null;
}

/**
 * All input for the `supplierExport` mutation.
 */
export interface SupplierExportInput {
  clientMutationId?: string | null;
  exportDescription?: string | null;
  entitySelection?: (EntitySelectionInput | null)[] | null;
  isAllExported?: boolean | null;
}

/**
 * A filter to be used against `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierFilter {
  id?: UUIDFilter | null;
  companyOrLastName?: StringFilter | null;
  firstName?: StringFilter | null;
  vendorNumber?: StringFilter | null;
  customerAccount?: StringFilter | null;
  _ratingVendorId?: UUIDFilter | null;
  isIndividual?: BooleanFilter | null;
  isCorporation?: BooleanFilter | null;
  paymentTermTypeId?: UUIDFilter | null;
  isRelatedPartySupplier?: BooleanFilter | null;
  isCompetitiveBidsRequired?: BooleanFilter | null;
  isContractualPurchaseOrderRequired?: BooleanFilter | null;
  isVendorPlaceholder?: BooleanFilter | null;
  isEnvironmentalImpactTracked?: BooleanFilter | null;
  environmentalMetricId?: UUIDFilter | null;
  purchaseDocumentsPoolId?: UUIDFilter | null;
  bankAccountName?: StringFilter | null;
  bankRoutingNumber?: StringFilter | null;
  bankName?: StringFilter | null;
  bankSortCode?: StringFilter | null;
  bankIbanCode?: StringFilter | null;
  bankAccountNumber?: StringFilter | null;
  bankSwiftCode?: StringFilter | null;
  _isBankAccountVerified?: BooleanFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isRatingsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _pendingApprovers?: StringFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _sortName?: StringFilter | null;
  _fullName?: StringFilter | null;
  vendorServiceTypeId?: IntFilter | null;
  vendorServiceRate?: BigFloatFilter | null;
  isW9OnFile?: BooleanFilter | null;
  w9ExpirationDate?: DateFilter | null;
  _isPurchaseOrderSettingsUpdatable?: BooleanFilter | null;
  _isUpdateSupplierOverride?: BooleanFilter | null;
  isTraveler?: BooleanFilter | null;
  nameAlias?: StringFilter | null;
  _isAliasInvoked?: BooleanFilter | null;
  _isAliasOriginalNameViewable?: BooleanFilter | null;
  federalTaxReference?: StringFilter | null;
  vatNumber?: StringFilter | null;
  _urgencyLevel?: IntFilter | null;
  approvalHistoriesByEntityId?: SupplierToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: SupplierToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: SupplierToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: SupplierToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  entityTagsByEntityId?: SupplierToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: SupplierToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: SupplierToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  ratingsByEntityId?: SupplierToManyRatingFilter | null;
  ratingsByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: SupplierToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: SupplierToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: SupplierToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  supplierHistoriesByEntityId?: SupplierToManySupplierHistoryFilter | null;
  supplierHistoriesByEntityIdExist?: boolean | null;
  purchaseOrders?: SupplierToManyPurchaseOrderFilter | null;
  purchaseOrdersExist?: boolean | null;
  supplierAddressesBySupplierId?: SupplierToManySupplierAddressFilter | null;
  supplierAddressesBySupplierIdExist?: boolean | null;
  supplierContactsBySupplierId?: SupplierToManySupplierContactFilter | null;
  supplierContactsBySupplierIdExist?: boolean | null;
  travelAuthorizations?: SupplierToManyTravelAuthorizationFilter | null;
  travelAuthorizationsExist?: boolean | null;
  tripItems?: SupplierToManyTripItemFilter | null;
  tripItemsExist?: boolean | null;
  tripItemsByPickupSupplierId?: SupplierToManyTripItemFilter | null;
  tripItemsByPickupSupplierIdExist?: boolean | null;
  tripItemsByPickupDropSupplierId?: SupplierToManyTripItemFilter | null;
  tripItemsByPickupDropSupplierIdExist?: boolean | null;
  tripItemsByReturnSupplierId?: SupplierToManyTripItemFilter | null;
  tripItemsByReturnSupplierIdExist?: boolean | null;
  tripItemsByReturnDropSupplierId?: SupplierToManyTripItemFilter | null;
  tripItemsByReturnDropSupplierIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  supplierRatingResults?: RatingEntityResultFilter | null;
  supplierRatingResultsExists?: boolean | null;
  paymentTermType?: PaymentTermTypeFilter | null;
  paymentTermTypeExists?: boolean | null;
  environmentMetric?: EnvironmentalMetricFilter | null;
  environmentMetricExists?: boolean | null;
  purchaseDocumentPool?: DocumentPoolFilter | null;
  purchaseDocumentPoolExists?: boolean | null;
  vendorServiceType?: VendorServiceTypeFilter | null;
  vendorServiceTypeExists?: boolean | null;
  and?: SupplierFilter[] | null;
  or?: SupplierFilter[] | null;
  not?: SupplierFilter | null;
}

/**
 * A filter to be used against `SupplierHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  and?: SupplierHistoryFilter[] | null;
  or?: SupplierHistoryFilter[] | null;
  not?: SupplierHistoryFilter | null;
}

/**
 * An input for mutations affecting `Supplier`
 */
export interface SupplierInput {
  companyOrLastName: string;
  firstName?: string | null;
  vendorNumber?: string | null;
  customerAccount?: string | null;
  isIndividual?: boolean | null;
  isCorporation?: boolean | null;
  paymentTermTypeId?: GqlUUID | null;
  isRelatedPartySupplier?: boolean | null;
  isCompetitiveBidsRequired?: boolean | null;
  isContractualPurchaseOrderRequired?: boolean | null;
  isEnvironmentalImpactTracked?: boolean | null;
  environmentalMetricId?: GqlUUID | null;
  purchaseDocumentsPoolId?: GqlUUID | null;
  bankAccountName?: string | null;
  bankRoutingNumber?: string | null;
  bankName?: string | null;
  bankSortCode?: string | null;
  bankIbanCode?: string | null;
  bankAccountNumber?: string | null;
  bankSwiftCode?: string | null;
  vendorServiceTypeId?: number | null;
  vendorServiceRate?: GqlBigFloat | null;
  isW9OnFile?: boolean | null;
  w9ExpirationDate?: GqlDate | null;
  isTraveler?: boolean | null;
  federalTaxReference?: string | null;
  vatNumber?: string | null;
}

/**
 * Represents an update to a `Supplier`. Fields that are set will be updated.
 */
export interface SupplierPatch {
  companyOrLastName?: string | null;
  firstName?: string | null;
  vendorNumber?: string | null;
  customerAccount?: string | null;
  isIndividual?: boolean | null;
  isCorporation?: boolean | null;
  paymentTermTypeId?: GqlUUID | null;
  isRelatedPartySupplier?: boolean | null;
  isCompetitiveBidsRequired?: boolean | null;
  isContractualPurchaseOrderRequired?: boolean | null;
  isEnvironmentalImpactTracked?: boolean | null;
  environmentalMetricId?: GqlUUID | null;
  purchaseDocumentsPoolId?: GqlUUID | null;
  bankAccountName?: string | null;
  bankRoutingNumber?: string | null;
  bankName?: string | null;
  bankSortCode?: string | null;
  bankIbanCode?: string | null;
  bankAccountNumber?: string | null;
  bankSwiftCode?: string | null;
  vendorServiceTypeId?: number | null;
  vendorServiceRate?: GqlBigFloat | null;
  isW9OnFile?: boolean | null;
  w9ExpirationDate?: GqlDate | null;
  isTraveler?: boolean | null;
  federalTaxReference?: string | null;
  vatNumber?: string | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `Rating` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyRatingFilter {
  every?: RatingFilter | null;
  some?: RatingFilter | null;
  none?: RatingFilter | null;
}

/**
 * A filter to be used against many `SupplierAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManySupplierAddressFilter {
  every?: SupplierAddressFilter | null;
  some?: SupplierAddressFilter | null;
  none?: SupplierAddressFilter | null;
}

/**
 * A filter to be used against many `SupplierContact` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManySupplierContactFilter {
  every?: SupplierContactFilter | null;
  some?: SupplierContactFilter | null;
  none?: SupplierContactFilter | null;
}

/**
 * A filter to be used against many `SupplierHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManySupplierHistoryFilter {
  every?: SupplierHistoryFilter | null;
  some?: SupplierHistoryFilter | null;
  none?: SupplierHistoryFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface SupplierToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * All input for the `supplierUpdate` mutation.
 */
export interface SupplierUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  supplierPatch?: SupplierPatch | null;
  supplierAddressesCreate?: (SupplierAddressInput | null)[] | null;
  supplierAddressesUpdate?: (SupplierAddressUpdateTypeInput | null)[] | null;
  supplierAddressesDelete?: (EntityDeleteInput | null)[] | null;
  supplierContactsCreate?: (SupplierContactInput | null)[] | null;
  supplierContactsUpdate?: (SupplierContactUpdateTypeInput | null)[] | null;
  supplierContactsDelete?: (EntityDeleteInput | null)[] | null;
  isPurchaseOrderMutation?: boolean | null;
}

/**
 * All input for the `supplierUrgentUpdate` mutation.
 */
export interface SupplierUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * All input for the `tagCategoryCreate` mutation.
 */
export interface TagCategoryCreateInput {
  clientMutationId?: string | null;
  tagCategory: TagCategoryInput;
  tagCategoryItems?: (TagCategoryItemInput | null)[] | null;
}

/**
 * All input for the `tagCategoryDelete` mutation.
 */
export interface TagCategoryDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `TagCategory` object types. All fields are combined with a logical ‘and.’
 */
export interface TagCategoryFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  isCostMetric?: BooleanFilter | null;
  isVendorMetric?: BooleanFilter | null;
  isSupplierMetric?: BooleanFilter | null;
  isEmployeeMetric?: BooleanFilter | null;
  isCustomerMetric?: BooleanFilter | null;
  isPayrollMetric?: BooleanFilter | null;
  isPayablesMetric?: BooleanFilter | null;
  isReceivablesMetric?: BooleanFilter | null;
  isPettyCashMetric?: BooleanFilter | null;
  isPurchasingMetric?: BooleanFilter | null;
  isTravelMetric?: BooleanFilter | null;
  isEnvironmentalMetric?: BooleanFilter | null;
  isRedFlag?: BooleanFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  tagCategoryItemsByTagCategoryId?: TagCategoryToManyTagCategoryItemFilter | null;
  tagCategoryItemsByTagCategoryIdExist?: boolean | null;
  and?: TagCategoryFilter[] | null;
  or?: TagCategoryFilter[] | null;
  not?: TagCategoryFilter | null;
}

/**
 * An input for mutations affecting `TagCategory`
 */
export interface TagCategoryInput {
  name: string;
  description?: string | null;
  isCostMetric?: boolean | null;
  isVendorMetric?: boolean | null;
  isSupplierMetric?: boolean | null;
  isEmployeeMetric?: boolean | null;
  isCustomerMetric?: boolean | null;
  isPayrollMetric?: boolean | null;
  isPayablesMetric?: boolean | null;
  isReceivablesMetric?: boolean | null;
  isPettyCashMetric?: boolean | null;
  isPurchasingMetric?: boolean | null;
  isTravelMetric?: boolean | null;
  isEnvironmentalMetric?: boolean | null;
  isRedFlag?: boolean | null;
}

/**
 * A filter to be used against `TagCategoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TagCategoryItemFilter {
  id?: UUIDFilter | null;
  _tagCategoryId?: UUIDFilter | null;
  tagId?: UUIDFilter | null;
  _tagBadgeName?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  tagCategory?: TagCategoryFilter | null;
  tagCategoryExists?: boolean | null;
  tag?: TagFilter | null;
  tagExists?: boolean | null;
  and?: TagCategoryItemFilter[] | null;
  or?: TagCategoryItemFilter[] | null;
  not?: TagCategoryItemFilter | null;
}

/**
 * An input for mutations affecting `TagCategoryItem`
 */
export interface TagCategoryItemInput {
  tagId: GqlUUID;
}

/**
 * Represents an update to a `TagCategory`. Fields that are set will be updated.
 */
export interface TagCategoryPatch {
  name?: string | null;
  description?: string | null;
  isCostMetric?: boolean | null;
  isVendorMetric?: boolean | null;
  isSupplierMetric?: boolean | null;
  isEmployeeMetric?: boolean | null;
  isCustomerMetric?: boolean | null;
  isPayrollMetric?: boolean | null;
  isPayablesMetric?: boolean | null;
  isReceivablesMetric?: boolean | null;
  isPettyCashMetric?: boolean | null;
  isPurchasingMetric?: boolean | null;
  isTravelMetric?: boolean | null;
  isEnvironmentalMetric?: boolean | null;
  isRedFlag?: boolean | null;
}

/**
 * A filter to be used against many `TagCategoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TagCategoryToManyTagCategoryItemFilter {
  every?: TagCategoryItemFilter | null;
  some?: TagCategoryItemFilter | null;
  none?: TagCategoryItemFilter | null;
}

/**
 * All input for the `tagCategoryUpdate` mutation.
 */
export interface TagCategoryUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  tagCategoryPatch?: TagCategoryPatch | null;
  tagCategoryItemsCreate?: (TagCategoryItemInput | null)[] | null;
  tagCategoryItemsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `tagCreate` mutation.
 */
export interface TagCreateInput {
  clientMutationId?: string | null;
  tag: TagInput;
}

/**
 * All input for the `tagDelete` mutation.
 */
export interface TagDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `Tag` object types. All fields are combined with a logical ‘and.’
 */
export interface TagFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  badgeName?: StringFilter | null;
  isPrivate?: BooleanFilter | null;
  isMonitored?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isSystemGenerated?: BooleanFilter | null;
  badgeColor?: ColorsFilter | null;
  isAccessTag?: BooleanFilter | null;
  approvalSelectionConditionsByRequesterTagId?: TagToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterTagIdExist?: boolean | null;
  tagCategoryItems?: TagToManyTagCategoryItemFilter | null;
  tagCategoryItemsExist?: boolean | null;
  tagGroupItems?: TagToManyTagGroupItemFilter | null;
  tagGroupItemsExist?: boolean | null;
  and?: TagFilter[] | null;
  or?: TagFilter[] | null;
  not?: TagFilter | null;
}

/**
 * All input for the `tagGroupCreate` mutation.
 */
export interface TagGroupCreateInput {
  clientMutationId?: string | null;
  tagGroup: TagGroupInput;
  tagGroupItems?: (TagGroupItemInput | null)[] | null;
}

/**
 * All input for the `tagGroupDelete` mutation.
 */
export interface TagGroupDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `TagGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface TagGroupFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  isAddedTagsReceived?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  tagGroupItemsByTagGroupId?: TagGroupToManyTagGroupItemFilter | null;
  tagGroupItemsByTagGroupIdExist?: boolean | null;
  companyTagGroups?: TagGroupToManyCompanyTagGroupFilter | null;
  companyTagGroupsExist?: boolean | null;
  departmentOccupationTemplates?: TagGroupToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: TagGroupToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  and?: TagGroupFilter[] | null;
  or?: TagGroupFilter[] | null;
  not?: TagGroupFilter | null;
}

/**
 * An input for mutations affecting `TagGroup`
 */
export interface TagGroupInput {
  name: string;
  description?: string | null;
}

/**
 * A filter to be used against `TagGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TagGroupItemFilter {
  id?: UUIDFilter | null;
  _tagGroupId?: UUIDFilter | null;
  tagId?: UUIDFilter | null;
  _tagBadgeName?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  tagGroup?: TagGroupFilter | null;
  tagGroupExists?: boolean | null;
  tag?: TagFilter | null;
  tagExists?: boolean | null;
  and?: TagGroupItemFilter[] | null;
  or?: TagGroupItemFilter[] | null;
  not?: TagGroupItemFilter | null;
}

/**
 * An input for mutations affecting `TagGroupItem`
 */
export interface TagGroupItemInput {
  tagId: GqlUUID;
}

/**
 * Represents an update to a `TagGroup`. Fields that are set will be updated.
 */
export interface TagGroupPatch {
  name?: string | null;
  description?: string | null;
}

/**
 * A filter to be used against many `CompanyTagGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface TagGroupToManyCompanyTagGroupFilter {
  every?: CompanyTagGroupFilter | null;
  some?: CompanyTagGroupFilter | null;
  none?: CompanyTagGroupFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface TagGroupToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `TagGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TagGroupToManyTagGroupItemFilter {
  every?: TagGroupItemFilter | null;
  some?: TagGroupItemFilter | null;
  none?: TagGroupItemFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface TagGroupToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * All input for the `tagGroupUpdate` mutation.
 */
export interface TagGroupUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  tagGroupPatch?: TagGroupPatch | null;
  tagGroupItemsCreate?: (TagGroupItemInput | null)[] | null;
  tagGroupItemsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * An input for mutations affecting `Tag`
 */
export interface TagInput {
  name: string;
  description?: string | null;
  badgeName: string;
  isPrivate?: boolean | null;
  isMonitored?: boolean | null;
  _isActive?: boolean | null;
  badgeColor: Colors;
  isAccessTag?: boolean | null;
}

/**
 * Represents an update to a `Tag`. Fields that are set will be updated.
 */
export interface TagPatch {
  name?: string | null;
  description?: string | null;
  badgeName?: string | null;
  isPrivate?: boolean | null;
  isMonitored?: boolean | null;
  _isActive?: boolean | null;
  badgeColor?: Colors | null;
  isAccessTag?: boolean | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface TagToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `TagCategoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TagToManyTagCategoryItemFilter {
  every?: TagCategoryItemFilter | null;
  some?: TagCategoryItemFilter | null;
  none?: TagCategoryItemFilter | null;
}

/**
 * A filter to be used against many `TagGroupItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TagToManyTagGroupItemFilter {
  every?: TagGroupItemFilter | null;
  some?: TagGroupItemFilter | null;
  none?: TagGroupItemFilter | null;
}

/**
 * All input for the `tagUpdate` mutation.
 */
export interface TagUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  tagPatch?: TagPatch | null;
}

/**
 * A filter to be used against `Tax1099T4Type` object types. All fields are combined with a logical ‘and.’
 */
export interface Tax1099T4TypeFilter {
  id?: IntFilter | null;
  tax1099T4Type?: StringFilter | null;
  sortOrder?: StringFilter | null;
  abbreviation?: StringFilter | null;
  countryId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  invoiceDistributions?: Tax1099T4TypeToManyInvoiceDistributionFilter | null;
  invoiceDistributionsExist?: boolean | null;
  purchaseOrderInvoiceScheduleDistributions?: Tax1099T4TypeToManyPurchaseOrderInvoiceScheduleDistributionFilter | null;
  purchaseOrderInvoiceScheduleDistributionsExist?: boolean | null;
  purchaseOrderItemDistributions?: Tax1099T4TypeToManyPurchaseOrderItemDistributionFilter | null;
  purchaseOrderItemDistributionsExist?: boolean | null;
  tripDistributions?: Tax1099T4TypeToManyTripDistributionFilter | null;
  tripDistributionsExist?: boolean | null;
  and?: Tax1099T4TypeFilter[] | null;
  or?: Tax1099T4TypeFilter[] | null;
  not?: Tax1099T4TypeFilter | null;
}

/**
 * A filter to be used against many `InvoiceDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Tax1099T4TypeToManyInvoiceDistributionFilter {
  every?: InvoiceDistributionFilter | null;
  some?: InvoiceDistributionFilter | null;
  none?: InvoiceDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderInvoiceScheduleDistribution`
 * object types. All fields are combined with a logical ‘and.’
 */
export interface Tax1099T4TypeToManyPurchaseOrderInvoiceScheduleDistributionFilter {
  every?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  some?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
  none?: PurchaseOrderInvoiceScheduleDistributionFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Tax1099T4TypeToManyPurchaseOrderItemDistributionFilter {
  every?: PurchaseOrderItemDistributionFilter | null;
  some?: PurchaseOrderItemDistributionFilter | null;
  none?: PurchaseOrderItemDistributionFilter | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface Tax1099T4TypeToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * A filter to be used against `TaxCode` object types. All fields are combined with a logical ‘and.’
 */
export interface TaxCodeFilter {
  id?: UUIDFilter | null;
  taxCode?: StringFilter | null;
  taxTypeId?: IntFilter | null;
  description?: StringFilter | null;
  sortOrder?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  cityAreaId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  productionIncentiveRates?: TaxCodeToManyProductionIncentiveRateFilter | null;
  productionIncentiveRatesExist?: boolean | null;
  taxType?: TaxTypeFilter | null;
  taxTypeExists?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  cityArea?: CityAreaFilter | null;
  cityAreaExists?: boolean | null;
  and?: TaxCodeFilter[] | null;
  or?: TaxCodeFilter[] | null;
  not?: TaxCodeFilter | null;
}

/**
 * A filter to be used against many `ProductionIncentiveRate` object types. All fields are combined with a logical ‘and.’
 */
export interface TaxCodeToManyProductionIncentiveRateFilter {
  every?: ProductionIncentiveRateFilter | null;
  some?: ProductionIncentiveRateFilter | null;
  none?: ProductionIncentiveRateFilter | null;
}

/**
 * A filter to be used against `TaxPayerType` object types. All fields are combined with a logical ‘and.’
 */
export interface TaxPayerTypeFilter {
  id?: IntFilter | null;
  taxPayerType?: StringFilter | null;
  abbreviation?: StringFilter | null;
  countryId?: IntFilter | null;
  isCorporation?: BooleanFilter | null;
  isTaxPayer?: BooleanFilter | null;
  isCountryTaxExempt?: BooleanFilter | null;
  isStateRegionTaxExempt?: BooleanFilter | null;
  isCityAreaTaxExempt?: BooleanFilter | null;
  employees?: TaxPayerTypeToManyEmployeeFilter | null;
  employeesExist?: boolean | null;
  and?: TaxPayerTypeFilter[] | null;
  or?: TaxPayerTypeFilter[] | null;
  not?: TaxPayerTypeFilter | null;
}

/**
 * A filter to be used against many `Employee` object types. All fields are combined with a logical ‘and.’
 */
export interface TaxPayerTypeToManyEmployeeFilter {
  every?: EmployeeFilter | null;
  some?: EmployeeFilter | null;
  none?: EmployeeFilter | null;
}

/**
 * A filter to be used against `TaxType` object types. All fields are combined with a logical ‘and.’
 */
export interface TaxTypeFilter {
  id?: IntFilter | null;
  taxType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: StringFilter | null;
  isTaxBasedOnCompanyLocation?: BooleanFilter | null;
  isTaxBasedOnSellerLocation?: BooleanFilter | null;
  isRebatable?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  taxCodes?: TaxTypeToManyTaxCodeFilter | null;
  taxCodesExist?: boolean | null;
  purchaseOrderItems?: TaxTypeToManyPurchaseOrderItemFilter | null;
  purchaseOrderItemsExist?: boolean | null;
  and?: TaxTypeFilter[] | null;
  or?: TaxTypeFilter[] | null;
  not?: TaxTypeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TaxTypeToManyPurchaseOrderItemFilter {
  every?: PurchaseOrderItemFilter | null;
  some?: PurchaseOrderItemFilter | null;
  none?: PurchaseOrderItemFilter | null;
}

/**
 * A filter to be used against many `TaxCode` object types. All fields are combined with a logical ‘and.’
 */
export interface TaxTypeToManyTaxCodeFilter {
  every?: TaxCodeFilter | null;
  some?: TaxCodeFilter | null;
  none?: TaxCodeFilter | null;
}

/**
 * All input for the `tempUserProfileInvite` mutation.
 */
export interface TempUserProfileInviteInput {
  clientMutationId?: string | null;
  entitySelection: (EntitySelectionInput | null)[];
}

/**
 * A filter to be used against Time fields. All fields are combined with a logical ‘and.’
 */
export interface TimeFilter {
  isNull?: boolean | null;
  equalTo?: GqlTime | null;
  notEqualTo?: GqlTime | null;
  distinctFrom?: GqlTime | null;
  notDistinctFrom?: GqlTime | null;
  in?: GqlTime[] | null;
  notIn?: GqlTime[] | null;
  lessThan?: GqlTime | null;
  lessThanOrEqualTo?: GqlTime | null;
  greaterThan?: GqlTime | null;
  greaterThanOrEqualTo?: GqlTime | null;
}

/**
 * A filter to be used against `TimeZone` object types. All fields are combined with a logical ‘and.’
 */
export interface TimeZoneFilter {
  id?: IntFilter | null;
  description?: StringFilter | null;
  standardTimeZoneCode?: StringFilter | null;
  isDaylightSavingExist?: BooleanFilter | null;
  daylightSavingStartingMonth?: IntFilter | null;
  daylightSavingStartingDay?: IntFilter | null;
  daylightSavingEndingMonth?: IntFilter | null;
  daylightSavingEndingDay?: IntFilter | null;
  daylightSavingTimeZoneCode?: StringFilter | null;
  isActive?: BooleanFilter | null;
  isDeprecated?: BooleanFilter | null;
  activeTimeZoneCode?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  companies?: TimeZoneToManyCompanyFilter | null;
  companiesExist?: boolean | null;
  and?: TimeZoneFilter[] | null;
  or?: TimeZoneFilter[] | null;
  not?: TimeZoneFilter | null;
}

/**
 * A filter to be used against many `Company` object types. All fields are combined with a logical ‘and.’
 */
export interface TimeZoneToManyCompanyFilter {
  every?: CompanyFilter | null;
  some?: CompanyFilter | null;
  none?: CompanyFilter | null;
}

/**
 * A filter to be used against TransactionLayout fields. All fields are combined with a logical ‘and.’
 */
export interface TransactionLayoutFilter {
  isNull?: boolean | null;
  equalTo?: TransactionLayout | null;
  notEqualTo?: TransactionLayout | null;
  distinctFrom?: TransactionLayout | null;
  notDistinctFrom?: TransactionLayout | null;
  in?: TransactionLayout[] | null;
  notIn?: TransactionLayout[] | null;
  lessThan?: TransactionLayout | null;
  lessThanOrEqualTo?: TransactionLayout | null;
  greaterThan?: TransactionLayout | null;
  greaterThanOrEqualTo?: TransactionLayout | null;
}

/**
 * A filter to be used against `TransactionTransfer` object types. All fields are combined with a logical ‘and.’
 */
export interface TransactionTransferFilter {
  id?: UUIDFilter | null;
  transferCycleId?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  transactionTypeId?: IntFilter | null;
  lineNumber?: IntFilter | null;
  transferValues?: StringFilter | null;
  accountingStampDate?: DatetimeFilter | null;
  accountingTransferDate?: DatetimeFilter | null;
  accountingTransferUserId?: UUIDFilter | null;
  accountingTransferTransactionReference?: StringFilter | null;
  transferStatusTypeId?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  transferCycle?: TransferCycleFilter | null;
  transferCycleExists?: boolean | null;
  transactionType?: InvoiceTransactionTypeFilter | null;
  transactionTypeExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  and?: TransactionTransferFilter[] | null;
  or?: TransactionTransferFilter[] | null;
  not?: TransactionTransferFilter | null;
}

/**
 * A filter to be used against `TransferCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface TransferCycleFilter {
  id?: UUIDFilter | null;
  transferCycle?: StringFilter | null;
  description?: StringFilter | null;
  extractionTypeId?: IntFilter | null;
  transferHeaders?: StringFilter | null;
  _companyId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _exportFilename?: StringFilter | null;
  transactionTransfers?: TransferCycleToManyTransactionTransferFilter | null;
  transactionTransfersExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  user?: UserFilter | null;
  userExists?: boolean | null;
  extractionType?: ExtractionTypeFilter | null;
  extractionTypeExists?: boolean | null;
  and?: TransferCycleFilter[] | null;
  or?: TransferCycleFilter[] | null;
  not?: TransferCycleFilter | null;
}

/**
 * A filter to be used against many `TransactionTransfer` object types. All fields are combined with a logical ‘and.’
 */
export interface TransferCycleToManyTransactionTransferFilter {
  every?: TransactionTransferFilter | null;
  some?: TransactionTransferFilter | null;
  none?: TransactionTransferFilter | null;
}

/**
 * A filter to be used against `TravelAccountType` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAccountTypeFilter {
  id?: IntFilter | null;
  accountType?: StringFilter | null;
  tripSectionId?: UUIDFilter | null;
  lookupAccounts?: TravelAccountTypeToManyLookupAccountFilter | null;
  lookupAccountsExist?: boolean | null;
  lookupCorporateAccounts?: TravelAccountTypeToManyLookupCorporateAccountFilter | null;
  lookupCorporateAccountsExist?: boolean | null;
  and?: TravelAccountTypeFilter[] | null;
  or?: TravelAccountTypeFilter[] | null;
  not?: TravelAccountTypeFilter | null;
}

/**
 * A filter to be used against many `LookupAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAccountTypeToManyLookupAccountFilter {
  every?: LookupAccountFilter | null;
  some?: LookupAccountFilter | null;
  none?: LookupAccountFilter | null;
}

/**
 * A filter to be used against many `LookupCorporateAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAccountTypeToManyLookupCorporateAccountFilter {
  every?: LookupCorporateAccountFilter | null;
  some?: LookupCorporateAccountFilter | null;
  none?: LookupCorporateAccountFilter | null;
}

/**
 * All input for the `travelAuthorizationAccountingStamper` mutation.
 */
export interface TravelAuthorizationAccountingStamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  transactionReference?: string | null;
  corporatePeriodId?: GqlUUID | null;
}

/**
 * All input for the `travelAuthorizationAccountingUnstamper` mutation.
 */
export interface TravelAuthorizationAccountingUnstamperInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `travelAuthorizationApprovalCreate` mutation.
 */
export interface TravelAuthorizationApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `travelAuthorizationApprovalRevision` mutation.
 */
export interface TravelAuthorizationApprovalRevisionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `travelAuthorizationApprovalRevoke` mutation.
 */
export interface TravelAuthorizationApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `travelAuthorizationCopy` mutation.
 */
export interface TravelAuthorizationCopyInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  departureDate?: GqlDate | null;
  returnDate?: GqlDate | null;
  isAmountIncluded?: boolean | null;
}

/**
 * All input for the `travelAuthorizationCreate` mutation.
 */
export interface TravelAuthorizationCreateInput {
  clientMutationId?: string | null;
  travelAuthorization: TravelAuthorizationInput;
}

/**
 * All input for the `travelAuthorizationDelete` mutation.
 */
export interface TravelAuthorizationDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `travelAuthorizationEmailCreate` mutation.
 */
export interface TravelAuthorizationEmailCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  isSupplierEmail?: boolean | null;
  userId?: (GqlUUID | null)[] | null;
  emailAccount?: (string | null)[] | null;
  externalEmail?: string | null;
  memoArea?: string | null;
  isUserSenderIncluded?: boolean | null;
}

/**
 * All input for the `travelAuthorizationEntryStampProcessing` mutation.
 */
export interface TravelAuthorizationEntryStampProcessingInput {
  clientMutationId?: string | null;
  corporatePeriodId?: GqlUUID | null;
  processRequests?: (ProcessRequestInput | null)[] | null;
}

/**
 * A filter to be used against `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationFilter {
  id?: UUIDFilter | null;
  supplierId?: UUIDFilter | null;
  tripPurpose?: StringFilter | null;
  comment?: StringFilter | null;
  travelAuthorizationNumber?: StringFilter | null;
  travelAuthorizationDate?: DateFilter | null;
  travelAuthorizationTime?: TimeFilter | null;
  requiredDate?: DateFilter | null;
  requiredTime?: TimeFilter | null;
  _purchaseOrderCreated?: DatetimeFilter | null;
  bookingFee?: BigFloatFilter | null;
  tripMovementId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  budgetAmount?: BigFloatFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  currencyId?: IntFilter | null;
  originalTravelAuthorizationId?: UUIDFilter | null;
  taNumber?: IntFilter | null;
  revisionNumber?: IntFilter | null;
  amendedDate?: DatetimeFilter | null;
  voidedDate?: DatetimeFilter | null;
  entityDocumentId?: UUIDFilter | null;
  linkedEntityId?: UUIDFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  agencyReferenceName?: StringFilter | null;
  sortAgencyName?: StringFilter | null;
  _revisedDate?: DatetimeFilter | null;
  _revisedUserId?: UUIDFilter | null;
  _isHistory?: BooleanFilter | null;
  _isFlaggedHistory?: BooleanFilter | null;
  isPersonalTravelAuthorization?: BooleanFilter | null;
  _companyId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionEntry?: BooleanFilter | null;
  _isAccountingEntry?: BooleanFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  _isAccountingEntryStampedComplete?: BooleanFilter | null;
  _isDistributionUpdateAllowed?: BooleanFilter | null;
  _isRevisable?: BooleanFilter | null;
  _isRevised?: BooleanFilter | null;
  _isStampAllowed?: BooleanFilter | null;
  _isUnstampAllowed?: BooleanFilter | null;
  _pendingApprovers?: StringFilter | null;
  stampedEntityDocumentId?: UUIDFilter | null;
  documentFileId?: UUIDFilter | null;
  referenceItemTypes?: StringFilter | null;
  _isOverBudget?: BooleanFilter | null;
  _createdByUserName?: StringFilter | null;
  _travelerReference?: StringFilter | null;
  _airfareAmount?: BigFloatFilter | null;
  _vehicleRentalAmount?: BigFloatFilter | null;
  _accommodationAmount?: BigFloatFilter | null;
  _transportationAmount?: BigFloatFilter | null;
  _trainAmount?: BigFloatFilter | null;
  _incidentalAmount?: BigFloatFilter | null;
  _isAirfareExist?: BooleanFilter | null;
  _isVehicleRentalExist?: BooleanFilter | null;
  _isAccommodationExist?: BooleanFilter | null;
  _isTransportationExist?: BooleanFilter | null;
  _isTrainExist?: BooleanFilter | null;
  _isIncidentalExist?: BooleanFilter | null;
  _suppliers?: StringFilter | null;
  _isCloningAllowed?: BooleanFilter | null;
  _isTripMovementAllowed?: BooleanFilter | null;
  _isNonAmountUpdatableAfterApproval?: BooleanFilter | null;
  corporateWorkgroupId?: UUIDFilter | null;
  _isCorporateWorkgroupRequired?: BooleanFilter | null;
  _isTripPreferredAreaAllowed?: BooleanFilter | null;
  corporateWorkgroupName?: StringFilter | null;
  _isCorporateTravelAuthorization?: BooleanFilter | null;
  _isPlanningPhase?: BooleanFilter | null;
  _poStatus?: StringFilter | null;
  _urgencyLevel?: IntFilter | null;
  approvalHistoriesByEntityId?: TravelAuthorizationToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: TravelAuthorizationToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: TravelAuthorizationToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: TravelAuthorizationToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  entityTagsByEntityId?: TravelAuthorizationToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  notesByEntityId?: TravelAuthorizationToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: TravelAuthorizationToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  emailDocumentsByEntityId?: TravelAuthorizationToManyEmailDocumentFilter | null;
  emailDocumentsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: TravelAuthorizationToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  travelAuthorizationHistoriesByEntityId?: TravelAuthorizationToManyTravelAuthorizationHistoryFilter | null;
  travelAuthorizationHistoriesByEntityIdExist?: boolean | null;
  travelAuthorizationRevisionHistoriesByTaGroupId?: TravelAuthorizationToManyTravelAuthorizationRevisionHistoryFilter | null;
  travelAuthorizationRevisionHistoriesByTaGroupIdExist?: boolean | null;
  tripBreakdowns?: TravelAuthorizationToManyTripBreakdownFilter | null;
  tripBreakdownsExist?: boolean | null;
  trips?: TravelAuthorizationToManyTripFilter | null;
  tripsExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  travelAuthorizationHistoryApprover?: ApprovalEntityApproverFilter | null;
  travelAuthorizationHistoryApproverExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  userRevision?: UserFilter | null;
  userRevisionExists?: boolean | null;
  movementOrder?: MovementOrderFilter | null;
  movementOrderExists?: boolean | null;
  corporateWorkgroup?: CorporateWorkgroupFilter | null;
  corporateWorkgroupExists?: boolean | null;
  and?: TravelAuthorizationFilter[] | null;
  or?: TravelAuthorizationFilter[] | null;
  not?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against `TravelAuthorizationHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  travelAuthorization?: TravelAuthorizationFilter | null;
  travelAuthorizationExists?: boolean | null;
  and?: TravelAuthorizationHistoryFilter[] | null;
  or?: TravelAuthorizationHistoryFilter[] | null;
  not?: TravelAuthorizationHistoryFilter | null;
}

/**
 * An input for mutations affecting `TravelAuthorization`
 */
export interface TravelAuthorizationInput {
  supplierId?: GqlUUID | null;
  tripPurpose: string;
  comment: string;
  travelAuthorizationDate: GqlDate;
  bookingFee?: GqlBigFloat | null;
  departmentId: GqlUUID;
  currencyId: number;
  isPersonalTravelAuthorization?: boolean | null;
  businessUnitId: GqlUUID;
  referenceItemTypes?: string | null;
  _isOverBudget?: boolean | null;
  corporateWorkgroupId?: GqlUUID | null;
}

/**
 * Represents an update to a `TravelAuthorization`. Fields that are set will be updated.
 */
export interface TravelAuthorizationPatch {
  supplierId?: GqlUUID | null;
  tripPurpose?: string | null;
  comment?: string | null;
  travelAuthorizationDate?: GqlDate | null;
  bookingFee?: GqlBigFloat | null;
  departmentId?: GqlUUID | null;
  currencyId?: number | null;
  isPersonalTravelAuthorization?: boolean | null;
  businessUnitId?: GqlUUID | null;
  referenceItemTypes?: string | null;
  _isOverBudget?: boolean | null;
  corporateWorkgroupId?: GqlUUID | null;
}

/**
 * All input for the `travelAuthorizationPoGeneration` mutation.
 */
export interface TravelAuthorizationPoGenerationInput {
  clientMutationId?: string | null;
  processRequests?: (ProcessRequestInput | null)[] | null;
  isPoConsolodated?: boolean | null;
}

/**
 * A filter to be used against `TravelAuthorizationRevisionHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationRevisionHistoryFilter {
  id?: UUIDFilter | null;
  travelPlan?: StringFilter | null;
  tripPurpose?: StringFilter | null;
  travelAuthorizationNumber?: StringFilter | null;
  taTotal?: BigFloatFilter | null;
  taGroupId?: UUIDFilter | null;
  dateRequested?: DatetimeFilter | null;
  requesterName?: StringFilter | null;
  taRevisionHistory?: TravelAuthorizationFilter | null;
  taRevisionHistoryExists?: boolean | null;
  and?: TravelAuthorizationRevisionHistoryFilter[] | null;
  or?: TravelAuthorizationRevisionHistoryFilter[] | null;
  not?: TravelAuthorizationRevisionHistoryFilter | null;
}

/**
 * All input for the `travelAuthorizationRowProtection` mutation.
 */
export interface TravelAuthorizationRowProtectionInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowSecurityId?: GqlUUID | null;
  isProtectionRemoval?: boolean | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyEmailDocumentFilter {
  every?: EmailDocumentFilter | null;
  some?: EmailDocumentFilter | null;
  none?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorizationHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyTravelAuthorizationHistoryFilter {
  every?: TravelAuthorizationHistoryFilter | null;
  some?: TravelAuthorizationHistoryFilter | null;
  none?: TravelAuthorizationHistoryFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorizationRevisionHistory` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyTravelAuthorizationRevisionHistoryFilter {
  every?: TravelAuthorizationRevisionHistoryFilter | null;
  some?: TravelAuthorizationRevisionHistoryFilter | null;
  none?: TravelAuthorizationRevisionHistoryFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `Trip` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyTripFilter {
  every?: TripFilter | null;
  some?: TripFilter | null;
  none?: TripFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelAuthorizationToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * All input for the `travelAuthorizationUpdate` mutation.
 */
export interface TravelAuthorizationUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  travelAuthorizationPatch?: TravelAuthorizationPatch | null;
}

/**
 * All input for the `travelAuthorizationUrgentUpdate` mutation.
 */
export interface TravelAuthorizationUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * All input for the `travelPlanEmailCreate` mutation.
 */
export interface TravelPlanEmailCreateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  isSupplierEmail?: boolean | null;
  userId?: (GqlUUID | null)[] | null;
  emailAccount?: (string | null)[] | null;
  externalEmail?: string | null;
  memoArea?: string | null;
  isUserSenderIncluded?: boolean | null;
}

/**
 * A filter to be used against `TravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyAllowanceFilter {
  id?: UUIDFilter | null;
  _travelPolicyId?: UUIDFilter | null;
  expenditureTypeId?: UUIDFilter | null;
  policyAllowance?: BigFloatFilter | null;
  policyMaxLimit?: BigFloatFilter | null;
  isMaxAutomaticallyDenied?: BooleanFilter | null;
  isAllowanceCustomizable?: BooleanFilter | null;
  sortOrder?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _allowanceDescription?: StringFilter | null;
  _travelerPolicyTravelerId?: UUIDFilter | null;
  _travelerPolicyAllowanceId?: UUIDFilter | null;
  _travelerPolicyAllowanceRowTimestamp?: DatetimeFilter | null;
  allowanceOverrideAmount?: BigFloatFilter | null;
  _travelerPolicyAllowanceIsUpdatable?: BooleanFilter | null;
  _travelerPolicyAllowanceIsDeletable?: BooleanFilter | null;
  policyMiles?: IntFilter | null;
  country1Id?: IntFilter | null;
  country2Id?: IntFilter | null;
  stateRegion1Id?: IntFilter | null;
  stateRegion2Id?: IntFilter | null;
  vendorTravelPolicyAllowances?: TravelPolicyAllowanceToManyVendorTravelPolicyAllowanceFilter | null;
  vendorTravelPolicyAllowancesExist?: boolean | null;
  travelerPolicyAllowances?: TravelPolicyAllowanceToManyTravelerPolicyAllowanceFilter | null;
  travelerPolicyAllowancesExist?: boolean | null;
  travelPolicy?: TravelPolicyFilter | null;
  travelPolicyExists?: boolean | null;
  expenditureType?: TravelPolicyExpenditureTypeFilter | null;
  expenditureTypeExists?: boolean | null;
  country1?: CountryFilter | null;
  country1Exists?: boolean | null;
  country2?: CountryFilter | null;
  country2Exists?: boolean | null;
  stateRegion1?: StateRegionFilter | null;
  stateRegion1Exists?: boolean | null;
  stateRegion2?: StateRegionFilter | null;
  stateRegion2Exists?: boolean | null;
  and?: TravelPolicyAllowanceFilter[] | null;
  or?: TravelPolicyAllowanceFilter[] | null;
  not?: TravelPolicyAllowanceFilter | null;
}

/**
 * An input for mutations affecting `TravelPolicyAllowance`
 */
export interface TravelPolicyAllowanceInput {
  expenditureTypeId: GqlUUID;
  policyAllowance: GqlBigFloat;
  isAllowanceCustomizable?: boolean | null;
  policyMiles?: number | null;
  country1Id?: number | null;
  country2Id?: number | null;
  stateRegion1Id?: number | null;
  stateRegion2Id?: number | null;
}

/**
 * Represents an update to a `TravelPolicyAllowance`. Fields that are set will be updated.
 */
export interface TravelPolicyAllowancePatch {
  expenditureTypeId?: GqlUUID | null;
  policyAllowance?: GqlBigFloat | null;
  isAllowanceCustomizable?: boolean | null;
  policyMiles?: number | null;
  country1Id?: number | null;
  country2Id?: number | null;
  stateRegion1Id?: number | null;
  stateRegion2Id?: number | null;
}

/**
 * A filter to be used against many `TravelerPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyAllowanceToManyTravelerPolicyAllowanceFilter {
  every?: TravelerPolicyAllowanceFilter | null;
  some?: TravelerPolicyAllowanceFilter | null;
  none?: TravelerPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against many `VendorTravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyAllowanceToManyVendorTravelPolicyAllowanceFilter {
  every?: VendorTravelPolicyAllowanceFilter | null;
  some?: VendorTravelPolicyAllowanceFilter | null;
  none?: VendorTravelPolicyAllowanceFilter | null;
}

/**
 * An input for mutations affecting `TravelPolicyAllowanceUpdateType`
 */
export interface TravelPolicyAllowanceUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  travelPolicyAllowancePatch?: TravelPolicyAllowancePatch | null;
}

/**
 * All input for the `travelPolicyCreate` mutation.
 */
export interface TravelPolicyCreateInput {
  clientMutationId?: string | null;
  travelPolicy: TravelPolicyInput;
  travelPolicyAllowance?: (TravelPolicyAllowanceInput | null)[] | null;
}

/**
 * All input for the `travelPolicyDelete` mutation.
 */
export interface TravelPolicyDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `TravelPolicyExpenditureType` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyExpenditureTypeFilter {
  id?: UUIDFilter | null;
  expenditureType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  _isMilesAllowed?: BooleanFilter | null;
  allowanceHint?: StringFilter | null;
  _isCountry1Allowed?: BooleanFilter | null;
  _isCountry2Allowed?: BooleanFilter | null;
  _isStateReference1Allowed?: BooleanFilter | null;
  _isStateReference2Allowed?: BooleanFilter | null;
  travelPolicyAllowancesByExpenditureTypeId?: TravelPolicyExpenditureTypeToManyTravelPolicyAllowanceFilter | null;
  travelPolicyAllowancesByExpenditureTypeIdExist?: boolean | null;
  and?: TravelPolicyExpenditureTypeFilter[] | null;
  or?: TravelPolicyExpenditureTypeFilter[] | null;
  not?: TravelPolicyExpenditureTypeFilter | null;
}

/**
 * A filter to be used against many `TravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyExpenditureTypeToManyTravelPolicyAllowanceFilter {
  every?: TravelPolicyAllowanceFilter | null;
  some?: TravelPolicyAllowanceFilter | null;
  none?: TravelPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against `TravelPolicy` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyFilter {
  id?: UUIDFilter | null;
  description?: StringFilter | null;
  sortOrder?: StringFilter | null;
  countryId?: IntFilter | null;
  currencyId?: IntFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isContractual?: BooleanFilter | null;
  isCompanion?: BooleanFilter | null;
  isFirstClassAllowed?: BooleanFilter | null;
  isBusinessClassAllowed?: BooleanFilter | null;
  isEconomyClassAllowed?: BooleanFilter | null;
  companionTickets?: IntFilter | null;
  companionAmount?: BigFloatFilter | null;
  isDefaultCustomizable?: BooleanFilter | null;
  isPremiumClassAllowed?: BooleanFilter | null;
  isCharterClassAllowed?: BooleanFilter | null;
  vendors?: TravelPolicyToManyVendorFilter | null;
  vendorsExist?: boolean | null;
  travelPolicyAllowancesByTravelPolicyId?: TravelPolicyToManyTravelPolicyAllowanceFilter | null;
  travelPolicyAllowancesByTravelPolicyIdExist?: boolean | null;
  travelers?: TravelPolicyToManyTravelerFilter | null;
  travelersExist?: boolean | null;
  tripBreakdownsByTravelPolicyId?: TravelPolicyToManyTripBreakdownFilter | null;
  tripBreakdownsByTravelPolicyIdExist?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  companyCurrency?: CompanyCurrencyFilter | null;
  companyCurrencyExists?: boolean | null;
  and?: TravelPolicyFilter[] | null;
  or?: TravelPolicyFilter[] | null;
  not?: TravelPolicyFilter | null;
}

/**
 * An input for mutations affecting `TravelPolicy`
 */
export interface TravelPolicyInput {
  description: string;
  currencyId: number;
  isContractual?: boolean | null;
  isCompanion?: boolean | null;
  isFirstClassAllowed?: boolean | null;
  isBusinessClassAllowed?: boolean | null;
  isEconomyClassAllowed?: boolean | null;
  companionTickets?: number | null;
  companionAmount?: GqlBigFloat | null;
  isDefaultCustomizable?: boolean | null;
  isPremiumClassAllowed?: boolean | null;
  isCharterClassAllowed?: boolean | null;
}

/**
 * Represents an update to a `TravelPolicy`. Fields that are set will be updated.
 */
export interface TravelPolicyPatch {
  description?: string | null;
  currencyId?: number | null;
  isContractual?: boolean | null;
  isCompanion?: boolean | null;
  isFirstClassAllowed?: boolean | null;
  isBusinessClassAllowed?: boolean | null;
  isEconomyClassAllowed?: boolean | null;
  companionTickets?: number | null;
  companionAmount?: GqlBigFloat | null;
  isDefaultCustomizable?: boolean | null;
  isPremiumClassAllowed?: boolean | null;
  isCharterClassAllowed?: boolean | null;
}

/**
 * A filter to be used against many `TravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyToManyTravelPolicyAllowanceFilter {
  every?: TravelPolicyAllowanceFilter | null;
  some?: TravelPolicyAllowanceFilter | null;
  none?: TravelPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against many `Traveler` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyToManyTravelerFilter {
  every?: TravelerFilter | null;
  some?: TravelerFilter | null;
  none?: TravelerFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelPolicyToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * All input for the `travelPolicyUpdate` mutation.
 */
export interface TravelPolicyUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  travelPolicyPatch?: TravelPolicyPatch | null;
  travelPolicyAllowancesCreate?: (TravelPolicyAllowanceInput | null)[] | null;
  travelPolicyAllowancesUpdate?: (TravelPolicyAllowanceUpdateTypeInput | null)[] | null;
  travelPolicyAllowancesDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * A filter to be used against `TravelerAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerAddressFilter {
  id?: UUIDFilter | null;
  _travelerId?: UUIDFilter | null;
  addressLine1?: StringFilter | null;
  addressLine2?: StringFilter | null;
  cityName?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  postalCode?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  emailAddress?: StringFilter | null;
  isGroundTransferAddress?: BooleanFilter | null;
  isDeliveryAddress?: BooleanFilter | null;
  isPrimary?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isAddressVerified?: BooleanFilter | null;
  _travelAddressSort?: IntFilter | null;
  travelerAddressHistoriesByEntityId?: TravelerAddressToManyTravelerAddressHistoryFilter | null;
  travelerAddressHistoriesByEntityIdExist?: boolean | null;
  vendor?: TravelerFilter | null;
  vendorExists?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: TravelerAddressFilter[] | null;
  or?: TravelerAddressFilter[] | null;
  not?: TravelerAddressFilter | null;
}

/**
 * A filter to be used against `TravelerAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerAddressHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  travelerAddress?: TravelerAddressFilter | null;
  travelerAddressExists?: boolean | null;
  and?: TravelerAddressHistoryFilter[] | null;
  or?: TravelerAddressHistoryFilter[] | null;
  not?: TravelerAddressHistoryFilter | null;
}

/**
 * An input for mutations affecting `TravelerAddress`
 */
export interface TravelerAddressInput {
  addressLine1: string;
  addressLine2?: string | null;
  cityName?: string | null;
  countryId?: number | null;
  stateRegionId?: number | null;
  postalCode?: string | null;
  telephoneNumber?: string | null;
  emailAddress?: string | null;
  isGroundTransferAddress?: boolean | null;
  isDeliveryAddress?: boolean | null;
  isPrimary?: boolean | null;
}

/**
 * Represents an update to a `TravelerAddress`. Fields that are set will be updated.
 */
export interface TravelerAddressPatch {
  addressLine1?: string | null;
  addressLine2?: string | null;
  cityName?: string | null;
  countryId?: number | null;
  stateRegionId?: number | null;
  postalCode?: string | null;
  telephoneNumber?: string | null;
  emailAddress?: string | null;
  isGroundTransferAddress?: boolean | null;
  isDeliveryAddress?: boolean | null;
  isPrimary?: boolean | null;
}

/**
 * A filter to be used against many `TravelerAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerAddressToManyTravelerAddressHistoryFilter {
  every?: TravelerAddressHistoryFilter | null;
  some?: TravelerAddressHistoryFilter | null;
  none?: TravelerAddressHistoryFilter | null;
}

/**
 * An input for mutations affecting `TravelerAddressUpdateType`
 */
export interface TravelerAddressUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  travelerAddressPatch?: TravelerAddressPatch | null;
}

/**
 * All input for the `travelerAliasNameUpdate` mutation.
 */
export interface TravelerAliasNameUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  companyOrLastName: string;
  firstName: string;
  nameAlias: string;
  isTravelAuthMutation?: boolean | null;
}

/**
 * All input for the `travelerApprovalCreate` mutation.
 */
export interface TravelerApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `travelerApprovalRevoke` mutation.
 */
export interface TravelerApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * A filter to be used against `TravelerCompanionMaster` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerCompanionMasterFilter {
  id?: UUIDFilter | null;
  companyOrLastName?: StringFilter | null;
  firstName?: StringFilter | null;
  vendorNumber?: StringFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _sortName?: StringFilter | null;
  _fullName?: StringFilter | null;
  travelPolicyId?: UUIDFilter | null;
  frequentFlyerNumber?: StringFilter | null;
  isIsleSeatPreferred?: BooleanFilter | null;
  isWindowSeatPreferred?: BooleanFilter | null;
  userOccupationTitleId?: UUIDFilter | null;
  travelerDepartmentId?: UUIDFilter | null;
  travelerCompanionTickets?: IntFilter | null;
  travelerCompanionAmount?: BigFloatFilter | null;
  isFirstClassPreferred?: BooleanFilter | null;
  isBusinessClassPreferred?: BooleanFilter | null;
  isEconomyClassPreferred?: BooleanFilter | null;
  isPremiumClassPreferred?: BooleanFilter | null;
  isCharterClassPreferred?: BooleanFilter | null;
  vendors?: TravelerCompanionMasterToManyVendorFilter | null;
  vendorsExist?: boolean | null;
  travelers?: TravelerCompanionMasterToManyTravelerFilter | null;
  travelersExist?: boolean | null;
  and?: TravelerCompanionMasterFilter[] | null;
  or?: TravelerCompanionMasterFilter[] | null;
  not?: TravelerCompanionMasterFilter | null;
}

/**
 * A filter to be used against many `Traveler` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerCompanionMasterToManyTravelerFilter {
  every?: TravelerFilter | null;
  some?: TravelerFilter | null;
  none?: TravelerFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerCompanionMasterToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * All input for the `travelerCreate` mutation.
 */
export interface TravelerCreateInput {
  clientMutationId?: string | null;
  traveler: TravelerInput;
  travelerAddresses?: (TravelerAddressInput | null)[] | null;
  travelerPolicyAllowances?: (TravelerPolicyAllowanceInput | null)[] | null;
  travelerDietaryRequirements?: (TravelerDietaryRequirementInput | null)[] | null;
  isTravelAuthMutation?: boolean | null;
}

/**
 * All input for the `travelerDelete` mutation.
 */
export interface TravelerDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
  isTravelAuthMutation?: boolean | null;
}

/**
 * A filter to be used against `TravelerDietaryRequirement` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerDietaryRequirementFilter {
  id?: UUIDFilter | null;
  _travelerId?: UUIDFilter | null;
  dietaryRequirementId?: IntFilter | null;
  description?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  travelerDietaryRequirementHistoriesByEntityId?: TravelerDietaryRequirementToManyTravelerDietaryRequirementHistoryFilter | null;
  travelerDietaryRequirementHistoriesByEntityIdExist?: boolean | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  dietaryRequirement?: DietaryRequirementFilter | null;
  dietaryRequirementExists?: boolean | null;
  and?: TravelerDietaryRequirementFilter[] | null;
  or?: TravelerDietaryRequirementFilter[] | null;
  not?: TravelerDietaryRequirementFilter | null;
}

/**
 * A filter to be used against `TravelerDietaryRequirementHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerDietaryRequirementHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  travelerDietaryRequirement?: TravelerDietaryRequirementFilter | null;
  travelerDietaryRequirementExists?: boolean | null;
  and?: TravelerDietaryRequirementHistoryFilter[] | null;
  or?: TravelerDietaryRequirementHistoryFilter[] | null;
  not?: TravelerDietaryRequirementHistoryFilter | null;
}

/**
 * An input for mutations affecting `TravelerDietaryRequirement`
 */
export interface TravelerDietaryRequirementInput {
  dietaryRequirementId: number;
  description?: string | null;
}

/**
 * Represents an update to a `TravelerDietaryRequirement`. Fields that are set will be updated.
 */
export interface TravelerDietaryRequirementPatch {
  dietaryRequirementId?: number | null;
  description?: string | null;
}

/**
 * A filter to be used against many `TravelerDietaryRequirementHistory` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface TravelerDietaryRequirementToManyTravelerDietaryRequirementHistoryFilter {
  every?: TravelerDietaryRequirementHistoryFilter | null;
  some?: TravelerDietaryRequirementHistoryFilter | null;
  none?: TravelerDietaryRequirementHistoryFilter | null;
}

/**
 * An input for mutations affecting `TravelerDietaryRequirementUpdateType`
 */
export interface TravelerDietaryRequirementUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  travelerDietaryRequirementPatch?: TravelerDietaryRequirementPatch | null;
}

/**
 * All input for the `travelerExport` mutation.
 */
export interface TravelerExportInput {
  clientMutationId?: string | null;
  exportDescription?: string | null;
  entitySelection?: (EntitySelectionInput | null)[] | null;
  isAllExported?: boolean | null;
}

/**
 * A filter to be used against `Traveler` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerFilter {
  id?: UUIDFilter | null;
  companyOrLastName?: StringFilter | null;
  firstName?: StringFilter | null;
  vendorNumber?: StringFilter | null;
  customerAccount?: StringFilter | null;
  travelPolicyId?: UUIDFilter | null;
  dateOfBirth?: DateFilter | null;
  frequentFlyerNumber?: StringFilter | null;
  isIsleSeatPreferred?: BooleanFilter | null;
  isWindowSeatPreferred?: BooleanFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _pendingApprovers?: StringFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _sortName?: StringFilter | null;
  _fullName?: StringFilter | null;
  userOccupationTitleId?: UUIDFilter | null;
  travelerDepartmentId?: UUIDFilter | null;
  travelerCompanionTickets?: IntFilter | null;
  travelerCompanionAmount?: BigFloatFilter | null;
  isFirstClassPreferred?: BooleanFilter | null;
  isBusinessClassPreferred?: BooleanFilter | null;
  isEconomyClassPreferred?: BooleanFilter | null;
  travelerCompanionMasterId?: UUIDFilter | null;
  _isUpdateTravelerOverride?: BooleanFilter | null;
  isCharterClassPreferred?: BooleanFilter | null;
  isPremiumClassPreferred?: BooleanFilter | null;
  nameAlias?: StringFilter | null;
  _isAliasInvoked?: BooleanFilter | null;
  _isAliasOriginalNameViewable?: BooleanFilter | null;
  _pickupAddress?: StringFilter | null;
  _urgencyLevel?: IntFilter | null;
  approvalHistoriesByEntityId?: TravelerToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: TravelerToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: TravelerToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: TravelerToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  entityTagsByEntityId?: TravelerToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: TravelerToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: TravelerToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: TravelerToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: TravelerToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: TravelerToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  travelerHistoriesByEntityId?: TravelerToManyTravelerHistoryFilter | null;
  travelerHistoriesByEntityIdExist?: boolean | null;
  movementTravelers?: TravelerToManyMovementTravelerFilter | null;
  movementTravelersExist?: boolean | null;
  travelerAddressesByTravelerId?: TravelerToManyTravelerAddressFilter | null;
  travelerAddressesByTravelerIdExist?: boolean | null;
  travelerDietaryRequirementsByTravelerId?: TravelerToManyTravelerDietaryRequirementFilter | null;
  travelerDietaryRequirementsByTravelerIdExist?: boolean | null;
  travelerPolicyAllowancesByTravelerId?: TravelerToManyTravelerPolicyAllowanceFilter | null;
  travelerPolicyAllowancesByTravelerIdExist?: boolean | null;
  tripBreakdownsByPrimaryTravelerId?: TravelerToManyTripBreakdownFilter | null;
  tripBreakdownsByPrimaryTravelerIdExist?: boolean | null;
  tripBreakdowns?: TravelerToManyTripBreakdownFilter | null;
  tripBreakdownsExist?: boolean | null;
  trips?: TravelerToManyTripFilter | null;
  tripsExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  travelPolicy?: TravelPolicyFilter | null;
  travelPolicyExists?: boolean | null;
  userOccupation?: UserOccupationTitleFilter | null;
  userOccupationExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  travelerHost?: TravelerCompanionMasterFilter | null;
  travelerHostExists?: boolean | null;
  and?: TravelerFilter[] | null;
  or?: TravelerFilter[] | null;
  not?: TravelerFilter | null;
}

/**
 * A filter to be used against `TravelerHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  and?: TravelerHistoryFilter[] | null;
  or?: TravelerHistoryFilter[] | null;
  not?: TravelerHistoryFilter | null;
}

/**
 * An input for mutations affecting `Traveler`
 */
export interface TravelerInput {
  companyOrLastName: string;
  firstName?: string | null;
  vendorNumber?: string | null;
  travelPolicyId?: GqlUUID | null;
  dateOfBirth?: GqlDate | null;
  frequentFlyerNumber?: string | null;
  isIsleSeatPreferred?: boolean | null;
  isWindowSeatPreferred?: boolean | null;
  userOccupationTitleId?: GqlUUID | null;
  travelerDepartmentId: GqlUUID;
  travelerCompanionTickets?: number | null;
  travelerCompanionAmount?: GqlBigFloat | null;
  isFirstClassPreferred?: boolean | null;
  isBusinessClassPreferred?: boolean | null;
  isEconomyClassPreferred?: boolean | null;
  travelerCompanionMasterId?: GqlUUID | null;
  isCharterClassPreferred?: boolean | null;
  isPremiumClassPreferred?: boolean | null;
}

/**
 * Represents an update to a `Traveler`. Fields that are set will be updated.
 */
export interface TravelerPatch {
  companyOrLastName?: string | null;
  firstName?: string | null;
  vendorNumber?: string | null;
  travelPolicyId?: GqlUUID | null;
  dateOfBirth?: GqlDate | null;
  frequentFlyerNumber?: string | null;
  isIsleSeatPreferred?: boolean | null;
  isWindowSeatPreferred?: boolean | null;
  userOccupationTitleId?: GqlUUID | null;
  travelerDepartmentId?: GqlUUID | null;
  travelerCompanionTickets?: number | null;
  travelerCompanionAmount?: GqlBigFloat | null;
  isFirstClassPreferred?: boolean | null;
  isBusinessClassPreferred?: boolean | null;
  isEconomyClassPreferred?: boolean | null;
  travelerCompanionMasterId?: GqlUUID | null;
  isCharterClassPreferred?: boolean | null;
  isPremiumClassPreferred?: boolean | null;
}

/**
 * A filter to be used against `TravelerPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerPolicyAllowanceFilter {
  id?: UUIDFilter | null;
  _travelerId?: UUIDFilter | null;
  travelPolicyAllowanceId?: UUIDFilter | null;
  allowanceOverrideAmount?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _allowanceDescription?: StringFilter | null;
  travelerPolicyAllowanceHistoriesByEntityId?: TravelerPolicyAllowanceToManyTravelerPolicyAllowanceHistoryFilter | null;
  travelerPolicyAllowanceHistoriesByEntityIdExist?: boolean | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  policyAllowanceOverride?: TravelPolicyAllowanceFilter | null;
  policyAllowanceOverrideExists?: boolean | null;
  and?: TravelerPolicyAllowanceFilter[] | null;
  or?: TravelerPolicyAllowanceFilter[] | null;
  not?: TravelerPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against `TravelerPolicyAllowanceHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerPolicyAllowanceHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  travelerPolicyAllowance?: TravelerPolicyAllowanceFilter | null;
  travelerPolicyAllowanceExists?: boolean | null;
  and?: TravelerPolicyAllowanceHistoryFilter[] | null;
  or?: TravelerPolicyAllowanceHistoryFilter[] | null;
  not?: TravelerPolicyAllowanceHistoryFilter | null;
}

/**
 * An input for mutations affecting `TravelerPolicyAllowance`
 */
export interface TravelerPolicyAllowanceInput {
  travelPolicyAllowanceId: GqlUUID;
  allowanceOverrideAmount?: GqlBigFloat | null;
  _allowanceDescription?: string | null;
}

/**
 * Represents an update to a `TravelerPolicyAllowance`. Fields that are set will be updated.
 */
export interface TravelerPolicyAllowancePatch {
  travelPolicyAllowanceId?: GqlUUID | null;
  allowanceOverrideAmount?: GqlBigFloat | null;
  _allowanceDescription?: string | null;
}

/**
 * A filter to be used against many `TravelerPolicyAllowanceHistory` object types.
 * All fields are combined with a logical ‘and.’
 */
export interface TravelerPolicyAllowanceToManyTravelerPolicyAllowanceHistoryFilter {
  every?: TravelerPolicyAllowanceHistoryFilter | null;
  some?: TravelerPolicyAllowanceHistoryFilter | null;
  none?: TravelerPolicyAllowanceHistoryFilter | null;
}

/**
 * An input for mutations affecting `TravelerPolicyAllowanceUpdateType`
 */
export interface TravelerPolicyAllowanceUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  travelerPolicyAllowancePatch?: TravelerPolicyAllowancePatch | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `MovementTraveler` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyMovementTravelerFilter {
  every?: MovementTravelerFilter | null;
  some?: MovementTravelerFilter | null;
  none?: MovementTravelerFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `TravelerAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyTravelerAddressFilter {
  every?: TravelerAddressFilter | null;
  some?: TravelerAddressFilter | null;
  none?: TravelerAddressFilter | null;
}

/**
 * A filter to be used against many `TravelerDietaryRequirement` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyTravelerDietaryRequirementFilter {
  every?: TravelerDietaryRequirementFilter | null;
  some?: TravelerDietaryRequirementFilter | null;
  none?: TravelerDietaryRequirementFilter | null;
}

/**
 * A filter to be used against many `TravelerHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyTravelerHistoryFilter {
  every?: TravelerHistoryFilter | null;
  some?: TravelerHistoryFilter | null;
  none?: TravelerHistoryFilter | null;
}

/**
 * A filter to be used against many `TravelerPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyTravelerPolicyAllowanceFilter {
  every?: TravelerPolicyAllowanceFilter | null;
  some?: TravelerPolicyAllowanceFilter | null;
  none?: TravelerPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against many `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyTripBreakdownFilter {
  every?: TripBreakdownFilter | null;
  some?: TripBreakdownFilter | null;
  none?: TripBreakdownFilter | null;
}

/**
 * A filter to be used against many `Trip` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyTripFilter {
  every?: TripFilter | null;
  some?: TripFilter | null;
  none?: TripFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface TravelerToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * All input for the `travelerUpdate` mutation.
 */
export interface TravelerUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  travelerPatch?: TravelerPatch | null;
  travelerAddressesCreate?: (TravelerAddressInput | null)[] | null;
  travelerAddressesUpdate?: (TravelerAddressUpdateTypeInput | null)[] | null;
  travelerAddressesDelete?: (EntityDeleteInput | null)[] | null;
  travelerPolicyAllowancesCreate?: (TravelerPolicyAllowanceInput | null)[] | null;
  travelerPolicyAllowancesUpdate?: (TravelerPolicyAllowanceUpdateTypeInput | null)[] | null;
  travelerPolicyAllowancesDelete?: (EntityDeleteInput | null)[] | null;
  travelerDietaryRequirementsCreate?: (TravelerDietaryRequirementInput | null)[] | null;
  travelerDietaryRequirementsUpdate?: (TravelerDietaryRequirementUpdateTypeInput | null)[] | null;
  travelerDietaryRequirementsDelete?: (EntityDeleteInput | null)[] | null;
  isTravelAuthMutation?: boolean | null;
}

/**
 * All input for the `travelerUrgentUpdate` mutation.
 */
export interface TravelerUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * All input for the `tripBreakdownExport` mutation.
 */
export interface TripBreakdownExportInput {
  clientMutationId?: string | null;
  exportDescription?: string | null;
  entityDelete?: (EntityDeleteInput | null)[] | null;
  corporatePeriodId?: GqlUUID | null;
  isDistributionIncluded?: boolean | null;
}

/**
 * A filter to be used against `TripBreakdown` object types. All fields are combined with a logical ‘and.’
 */
export interface TripBreakdownFilter {
  id?: UUIDFilter | null;
  travelAuthorizationId?: UUIDFilter | null;
  travelerId?: UUIDFilter | null;
  tripLocator?: StringFilter | null;
  travelerReferenceName?: StringFilter | null;
  sortTravelerName?: StringFilter | null;
  specialInstructions?: StringFilter | null;
  comments?: StringFilter | null;
  airfareAmount?: BigFloatFilter | null;
  accommodationAmount?: BigFloatFilter | null;
  vehicleRentalAmount?: BigFloatFilter | null;
  transportationAmount?: BigFloatFilter | null;
  incidentalAmount?: BigFloatFilter | null;
  trainAmount?: BigFloatFilter | null;
  agencyAmount?: BigFloatFilter | null;
  otherAmount?: BigFloatFilter | null;
  budgetAmount?: BigFloatFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  accountReference?: StringFilter | null;
  referenceItemTypes?: StringFilter | null;
  bookingCreated?: DatetimeFilter | null;
  confirmationCode?: StringFilter | null;
  _companyId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  airfareBudgetAmount?: BigFloatFilter | null;
  accommodationBudgetAmount?: BigFloatFilter | null;
  vehicleRentalBudgetAmount?: BigFloatFilter | null;
  transportationBudgetAmount?: BigFloatFilter | null;
  incidentalBudgetAmount?: BigFloatFilter | null;
  trainBudgetAmount?: BigFloatFilter | null;
  agencyBudgetAmount?: BigFloatFilter | null;
  otherBudgetAmount?: BigFloatFilter | null;
  linkedEntityId?: UUIDFilter | null;
  warningInfo1?: StringFilter | null;
  warningValue1?: StringFilter | null;
  warningInfo2?: StringFilter | null;
  warningValue2?: StringFilter | null;
  warningInfo3?: StringFilter | null;
  warningValue3?: StringFilter | null;
  _isOverBudget?: BooleanFilter | null;
  travelAuthorizationNumber?: StringFilter | null;
  travelAuthorizationDate?: DateFilter | null;
  comment?: StringFilter | null;
  tripPurpose?: StringFilter | null;
  departmentId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  businessUnitId?: UUIDFilter | null;
  _taStatusTypeId?: IntFilter | null;
  _taStatusType?: StringFilter | null;
  _businessUnitName?: StringFilter | null;
  _isoCode?: StringFilter | null;
  _currencySymbol?: StringFilter | null;
  _departmentName?: StringFilter | null;
  _baseCurrencyId?: IntFilter | null;
  _baseCurrencyAmount?: BigFloatFilter | null;
  _spotCurrencyAmount?: BigFloatFilter | null;
  _baseIsoCode?: StringFilter | null;
  _baseCurrencySymbol?: StringFilter | null;
  _createdByUserId?: UUIDFilter | null;
  _createdByUserName?: StringFilter | null;
  _isAirfareOverBudget?: BooleanFilter | null;
  _isAccommodationOverBudget?: BooleanFilter | null;
  _isVehicleRentalOverBudget?: BooleanFilter | null;
  _isTransportationOverBudget?: BooleanFilter | null;
  _isTrainOverBudget?: BooleanFilter | null;
  _isIncidentalOverBudget?: BooleanFilter | null;
  _isAirfareExist?: BooleanFilter | null;
  _isVehicleRentalExist?: BooleanFilter | null;
  _isAccommodationExist?: BooleanFilter | null;
  _isTransportationExist?: BooleanFilter | null;
  _isTrainExist?: BooleanFilter | null;
  _isIncidentalExist?: BooleanFilter | null;
  _companionTicketUsed?: IntFilter | null;
  _companionAmountUsed?: BigFloatFilter | null;
  _primaryTravelerId?: UUIDFilter | null;
  _primaryTravelerName?: StringFilter | null;
  _travelPolicyId?: UUIDFilter | null;
  _travelPolicyDescription?: StringFilter | null;
  _tripWarningInfo?: StringFilter | null;
  _travelTags?: StringFilter | null;
  corporateWorkgroupId?: UUIDFilter | null;
  corporateWorkgroupName?: StringFilter | null;
  _isCorporateTravelAuthorization?: BooleanFilter | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  primaryTraveler?: TravelerFilter | null;
  primaryTravelerExists?: boolean | null;
  travelPolicy?: TravelPolicyFilter | null;
  travelPolicyExists?: boolean | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  travelAuthorization?: TravelAuthorizationFilter | null;
  travelAuthorizationExists?: boolean | null;
  and?: TripBreakdownFilter[] | null;
  or?: TripBreakdownFilter[] | null;
  not?: TripBreakdownFilter | null;
}

/**
 * All input for the `tripCreate` mutation.
 */
export interface TripCreateInput {
  clientMutationId?: string | null;
  travelAuthorizationId: GqlUUID;
  rowTimestamp: GqlDatetime;
  trip: TripInput;
  tripItem?: (TripItemInput | null)[] | null;
  tripDistribution?: (TripDistributionInput | null)[] | null;
}

/**
 * All input for the `tripDelete` mutation.
 */
export interface TripDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface TripDistributionFilter {
  id?: UUIDFilter | null;
  tripItemId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  projectId?: UUIDFilter | null;
  accountId?: UUIDFilter | null;
  setAccountId?: UUIDFilter | null;
  taxCodeRateId?: UUIDFilter | null;
  referenceCode1Id?: UUIDFilter | null;
  referenceCode2Id?: UUIDFilter | null;
  referenceCode3Id?: UUIDFilter | null;
  referenceCode4Id?: UUIDFilter | null;
  referenceCode5Id?: UUIDFilter | null;
  referenceCode6Id?: UUIDFilter | null;
  referenceCode7Id?: UUIDFilter | null;
  productionIncentiveId?: UUIDFilter | null;
  tax1099T4TypeId?: IntFilter | null;
  lookupAccountId?: UUIDFilter | null;
  projectReference?: StringFilter | null;
  accountReference?: StringFilter | null;
  setReference?: StringFilter | null;
  taxReference?: StringFilter | null;
  referenceCode1?: StringFilter | null;
  referenceCode2?: StringFilter | null;
  referenceCode3?: StringFilter | null;
  referenceCode4?: StringFilter | null;
  referenceCode5?: StringFilter | null;
  referenceCode6?: StringFilter | null;
  referenceCode7?: StringFilter | null;
  additionalDescription?: StringFilter | null;
  allocationPercent?: BigFloatFilter | null;
  distributionAmount?: BigFloatFilter | null;
  retiredAmount?: BigFloatFilter | null;
  businessUnitId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _tripId?: UUIDFilter | null;
  _tripSectionItemId?: UUIDFilter | null;
  _itemLayoutType?: TransactionLayoutFilter | null;
  _expenditureTypeId?: UUIDFilter | null;
  _purchaseOrderItemTypeId?: UUIDFilter | null;
  _unitOfMeasureItemTypeId?: UUIDFilter | null;
  tripSectionId?: UUIDFilter | null;
  _sectionLayoutType?: TransactionLayoutFilter | null;
  currencyId?: IntFilter | null;
  _accountName?: StringFilter | null;
  tripItem?: TripItemFilter | null;
  tripItemExists?: boolean | null;
  productionIncentive?: ProductionIncentiveFilter | null;
  productionIncentiveExists?: boolean | null;
  tax1099t4Type?: Tax1099T4TypeFilter | null;
  tax1099t4TypeExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  lookupAccount?: LookupAccountFilter | null;
  lookupAccountExists?: boolean | null;
  reference1Code?: Reference1CodeFilter | null;
  reference1CodeExists?: boolean | null;
  reference2Code?: Reference2CodeFilter | null;
  reference2CodeExists?: boolean | null;
  reference3Code?: Reference3CodeFilter | null;
  reference3CodeExists?: boolean | null;
  reference4Code?: Reference4CodeFilter | null;
  reference4CodeExists?: boolean | null;
  reference5Code?: Reference5CodeFilter | null;
  reference5CodeExists?: boolean | null;
  project?: ProjectFilter | null;
  projectExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  and?: TripDistributionFilter[] | null;
  or?: TripDistributionFilter[] | null;
  not?: TripDistributionFilter | null;
}

/**
 * An input for mutations affecting `TripDistribution`
 */
export interface TripDistributionInput {
  tripItemId?: GqlUUID | null;
  projectId?: GqlUUID | null;
  accountId?: GqlUUID | null;
  setAccountId?: GqlUUID | null;
  referenceCode1Id?: GqlUUID | null;
  referenceCode2Id?: GqlUUID | null;
  referenceCode3Id?: GqlUUID | null;
  referenceCode4Id?: GqlUUID | null;
  referenceCode5Id?: GqlUUID | null;
  referenceCode6Id?: GqlUUID | null;
  referenceCode7Id?: GqlUUID | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  projectReference?: string | null;
  accountReference?: string | null;
  setReference?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  additionalDescription?: string | null;
  allocationPercent?: GqlBigFloat | null;
  distributionAmount?: GqlBigFloat | null;
  tripSectionId?: GqlUUID | null;
  currencyId?: number | null;
}

/**
 * Represents an update to a `TripDistribution`. Fields that are set will be updated.
 */
export interface TripDistributionPatch {
  tripItemId?: GqlUUID | null;
  projectId?: GqlUUID | null;
  accountId?: GqlUUID | null;
  setAccountId?: GqlUUID | null;
  referenceCode1Id?: GqlUUID | null;
  referenceCode2Id?: GqlUUID | null;
  referenceCode3Id?: GqlUUID | null;
  referenceCode4Id?: GqlUUID | null;
  referenceCode5Id?: GqlUUID | null;
  referenceCode6Id?: GqlUUID | null;
  referenceCode7Id?: GqlUUID | null;
  productionIncentiveId?: GqlUUID | null;
  tax1099T4TypeId?: number | null;
  projectReference?: string | null;
  accountReference?: string | null;
  setReference?: string | null;
  referenceCode1?: string | null;
  referenceCode2?: string | null;
  referenceCode3?: string | null;
  referenceCode4?: string | null;
  referenceCode5?: string | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  additionalDescription?: string | null;
  allocationPercent?: GqlBigFloat | null;
  distributionAmount?: GqlBigFloat | null;
  tripSectionId?: GqlUUID | null;
  currencyId?: number | null;
}

/**
 * An input for mutations affecting `TripDistributionUpdateType`
 */
export interface TripDistributionUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  tripDistributionUpdatePatch?: TripDistributionPatch | null;
}

/**
 * A filter to be used against `Trip` object types. All fields are combined with a logical ‘and.’
 */
export interface TripFilter {
  id?: UUIDFilter | null;
  travelAuthorizationId?: UUIDFilter | null;
  travelerId?: UUIDFilter | null;
  tripLocator?: StringFilter | null;
  travelerReferenceName?: StringFilter | null;
  sortTravelerName?: StringFilter | null;
  specialInstructions?: StringFilter | null;
  comments?: StringFilter | null;
  airfareAmount?: BigFloatFilter | null;
  accommodationAmount?: BigFloatFilter | null;
  vehicleRentalAmount?: BigFloatFilter | null;
  transportationAmount?: BigFloatFilter | null;
  incidentalAmount?: BigFloatFilter | null;
  trainAmount?: BigFloatFilter | null;
  agencyAmount?: BigFloatFilter | null;
  otherAmount?: BigFloatFilter | null;
  budgetAmount?: BigFloatFilter | null;
  controlTotalAmount?: BigFloatFilter | null;
  accountReference?: StringFilter | null;
  referenceItemTypes?: StringFilter | null;
  bookingCreated?: DatetimeFilter | null;
  confirmationCode?: StringFilter | null;
  _companyId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  airfareBudgetAmount?: BigFloatFilter | null;
  accommodationBudgetAmount?: BigFloatFilter | null;
  vehicleRentalBudgetAmount?: BigFloatFilter | null;
  transportationBudgetAmount?: BigFloatFilter | null;
  incidentalBudgetAmount?: BigFloatFilter | null;
  trainBudgetAmount?: BigFloatFilter | null;
  agencyBudgetAmount?: BigFloatFilter | null;
  otherBudgetAmount?: BigFloatFilter | null;
  linkedEntityId?: UUIDFilter | null;
  warningInfo1?: StringFilter | null;
  warningValue1?: StringFilter | null;
  warningInfo2?: StringFilter | null;
  warningValue2?: StringFilter | null;
  warningInfo3?: StringFilter | null;
  warningValue3?: StringFilter | null;
  _isOverBudget?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _stopsValidation?: StringFilter | null;
  approvalHistoryItemsByEntityItemId?: TripToManyApprovalHistoryItemFilter | null;
  approvalHistoryItemsByEntityItemIdExist?: boolean | null;
  notesByEntityId?: TripToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: TripToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  entityEnvironmentalsByEntityId?: TripToManyEntityEnvironmentalFilter | null;
  entityEnvironmentalsByEntityIdExist?: boolean | null;
  tripHistoriesByEntityId?: TripToManyTripHistoryFilter | null;
  tripHistoriesByEntityIdExist?: boolean | null;
  tripItems?: TripToManyTripItemFilter | null;
  tripItemsExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  travelAuthorization?: TravelAuthorizationFilter | null;
  travelAuthorizationExists?: boolean | null;
  and?: TripFilter[] | null;
  or?: TripFilter[] | null;
  not?: TripFilter | null;
}

/**
 * A filter to be used against `TripHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TripHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  trip?: TripFilter | null;
  tripExists?: boolean | null;
  and?: TripHistoryFilter[] | null;
  or?: TripHistoryFilter[] | null;
  not?: TripHistoryFilter | null;
}

/**
 * An input for mutations affecting `Trip`
 */
export interface TripInput {
  travelerId: GqlUUID;
  specialInstructions?: string | null;
  comments?: string | null;
  _isOverBudget?: boolean | null;
}

/**
 * A filter to be used against `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TripItemFilter {
  id?: UUIDFilter | null;
  tripId?: UUIDFilter | null;
  tripSectionItemId?: UUIDFilter | null;
  supplierId?: UUIDFilter | null;
  comments?: StringFilter | null;
  returnComments?: StringFilter | null;
  reservationNumber?: StringFilter | null;
  departureDepotId?: UUIDFilter | null;
  departureDate?: DateFilter | null;
  departureTime?: TimeFilter | null;
  departureCountryId?: IntFilter | null;
  departureStateRegionId?: IntFilter | null;
  departureArrivalDepotId?: UUIDFilter | null;
  departureArrivalDate?: DateFilter | null;
  departureArrivalTime?: TimeFilter | null;
  departureArrivalCountryId?: IntFilter | null;
  departureArrivalStateRegionId?: IntFilter | null;
  departureCarrierId?: UUIDFilter | null;
  departureCarrierTripNumber?: StringFilter | null;
  departureCarrierConfirmationCode?: StringFilter | null;
  departureTerminal?: StringFilter | null;
  departureSeat?: StringFilter | null;
  isDepartureEconomyClass?: BooleanFilter | null;
  isDepartureBusinessClass?: BooleanFilter | null;
  isDepartureFirstClass?: BooleanFilter | null;
  isDepartureCharterClass?: BooleanFilter | null;
  isDepartureRefundable?: BooleanFilter | null;
  departureDays?: IntFilter | null;
  departureHours?: IntFilter | null;
  departureMinutes?: IntFilter | null;
  departureMiles?: IntFilter | null;
  departureAirfare?: BigFloatFilter | null;
  returnDepotId?: UUIDFilter | null;
  returnDate?: DateFilter | null;
  returnTime?: TimeFilter | null;
  returnCountryId?: IntFilter | null;
  returnStateRegionId?: IntFilter | null;
  returnArrivalDepotId?: UUIDFilter | null;
  returnArrivalDate?: DateFilter | null;
  returnArrivalTime?: TimeFilter | null;
  returnArrivalCountryId?: IntFilter | null;
  returnArrivalStateRegionId?: IntFilter | null;
  returnCarrierId?: UUIDFilter | null;
  returnCarrierTripNumber?: StringFilter | null;
  returnCarrierConfirmationCode?: StringFilter | null;
  returnTerminal?: StringFilter | null;
  returnSeat?: StringFilter | null;
  isReturnEconomyClass?: BooleanFilter | null;
  isReturnBusinessClass?: BooleanFilter | null;
  isReturnFirstClass?: BooleanFilter | null;
  isReturnCharterClass?: BooleanFilter | null;
  isReturnRefundable?: BooleanFilter | null;
  returnDays?: IntFilter | null;
  returnHours?: IntFilter | null;
  returnMinutes?: IntFilter | null;
  returnMiles?: IntFilter | null;
  returnAirfare?: BigFloatFilter | null;
  rentalLookupNameId?: UUIDFilter | null;
  rentalName?: StringFilter | null;
  rentalAddress1?: StringFilter | null;
  rentalAddress2?: StringFilter | null;
  rentalCityName?: StringFilter | null;
  rentalPostalCode?: StringFilter | null;
  rentalStateRegionId?: IntFilter | null;
  rentalCountryId?: IntFilter | null;
  checkInDate?: DateFilter | null;
  checkInTime?: TimeFilter | null;
  checkOutDate?: DateFilter | null;
  checkOutTime?: TimeFilter | null;
  rentalTerm?: IntFilter | null;
  rentalPrice?: BigFloatFilter | null;
  rentalExtendedPrice?: BigFloatFilter | null;
  pickupLookupNameId?: UUIDFilter | null;
  pickupName?: StringFilter | null;
  pickupAddress1?: StringFilter | null;
  pickupAddress2?: StringFilter | null;
  pickupCityName?: StringFilter | null;
  pickupPostalCode?: StringFilter | null;
  pickupStateRegionId?: IntFilter | null;
  pickupCountryId?: IntFilter | null;
  pickupDropLookupNameId?: UUIDFilter | null;
  pickupDropName?: StringFilter | null;
  pickupDropAddress1?: StringFilter | null;
  pickupDropAddress2?: StringFilter | null;
  pickupDropCityName?: StringFilter | null;
  pickupDropPostalCode?: StringFilter | null;
  pickupDropStateRegionId?: IntFilter | null;
  pickupDropCountryId?: IntFilter | null;
  pickupPrice?: BigFloatFilter | null;
  returnLookupNameId?: UUIDFilter | null;
  returnName?: StringFilter | null;
  returnAddress1?: StringFilter | null;
  returnAddress2?: StringFilter | null;
  returnCityName?: StringFilter | null;
  returnPostalCode?: StringFilter | null;
  returnPickupStateRegionId?: IntFilter | null;
  returnPickupCountryId?: IntFilter | null;
  returnDropLookupNameId?: UUIDFilter | null;
  returnDropName?: StringFilter | null;
  returnDropAddress1?: StringFilter | null;
  returnDropAddress2?: StringFilter | null;
  returnDropCityName?: StringFilter | null;
  returnDropPostalCode?: StringFilter | null;
  returnDropStateRegionId?: IntFilter | null;
  returnDropCountryId?: IntFilter | null;
  returnPrice?: BigFloatFilter | null;
  bookingFeeQuantity?: IntFilter | null;
  bookingFeeRate?: BigFloatFilter | null;
  bookingFeeTotal?: BigFloatFilter | null;
  perDiemQuantity?: IntFilter | null;
  perDiemRate?: BigFloatFilter | null;
  perDiemTotal?: BigFloatFilter | null;
  incidentalQuantity?: IntFilter | null;
  incidentalRate?: BigFloatFilter | null;
  incidentalTotal?: BigFloatFilter | null;
  miscellaneousQuantity?: IntFilter | null;
  miscellaneousRate?: BigFloatFilter | null;
  miscellaneousTotal?: BigFloatFilter | null;
  itemTotal?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _searchDocument?: StringFilter | null;
  _tripSectionId?: UUIDFilter | null;
  _tripSectionItemLayout?: TransactionLayoutFilter | null;
  _unitOfMeasureItemTypeId?: UUIDFilter | null;
  _tripSectionLayout?: TransactionLayoutFilter | null;
  _calculationType?: StandardCalculationTypeFilter | null;
  _purchaseOrderItemTypeId?: UUIDFilter | null;
  departureCityAreaId?: UUIDFilter | null;
  departureArrivalCityAreaId?: UUIDFilter | null;
  returnCityAreaId?: UUIDFilter | null;
  returnArrivalCityAreaId?: UUIDFilter | null;
  rentalEmailAddress?: StringFilter | null;
  rentalTelephoneNumber?: StringFilter | null;
  pickupEmailAddress?: StringFilter | null;
  pickupTelephoneNumber?: StringFilter | null;
  pickupDropEmailAddress?: StringFilter | null;
  pickupDropTelephoneNumber?: StringFilter | null;
  returnEmailAddress?: StringFilter | null;
  returnTelephoneNumber?: StringFilter | null;
  returnDropEmailAddress?: StringFilter | null;
  returnDropTelephoneNumber?: StringFilter | null;
  departureStops?: IntFilter | null;
  returnStops?: IntFilter | null;
  isDeparturePremiumClass?: BooleanFilter | null;
  isReturnPremiumClass?: BooleanFilter | null;
  pickupArrivalName?: StringFilter | null;
  pickupArrivalDropName?: StringFilter | null;
  pickupArrivalPrice?: BigFloatFilter | null;
  returnArrivalName?: StringFilter | null;
  returnArrivalDropName?: StringFilter | null;
  returnArrivalPrice?: BigFloatFilter | null;
  pickupSupplierId?: UUIDFilter | null;
  pickupDropSupplierId?: UUIDFilter | null;
  returnSupplierId?: UUIDFilter | null;
  returnDropSupplierId?: UUIDFilter | null;
  isSupplierBreakdownRequired?: BooleanFilter | null;
  policyExpenditureTypeId?: UUIDFilter | null;
  policyAllowance?: BigFloatFilter | null;
  policyMiles?: IntFilter | null;
  currencyId?: IntFilter | null;
  pickupCurrencyId?: IntFilter | null;
  pickupDropCurrencyId?: IntFilter | null;
  returnCurrencyId?: IntFilter | null;
  returnDropCurrencyId?: IntFilter | null;
  departureEticketNumber?: StringFilter | null;
  returnEticketNumber?: StringFilter | null;
  departureTerminalArrival?: StringFilter | null;
  returnTerminalArrival?: StringFilter | null;
  _naAvailableForReuse1?: BooleanFilter | null;
  preferredRateTypeId?: IntFilter | null;
  isPreferredSupplier?: BooleanFilter | null;
  isExceptionRequired?: BooleanFilter | null;
  exceptionComments?: StringFilter | null;
  depart1DepotId?: UUIDFilter | null;
  depart1ArriveDate?: DateFilter | null;
  depart1ArriveTime?: TimeFilter | null;
  depart1ArriveTerminal?: StringFilter | null;
  depart1DepartDate?: DateFilter | null;
  depart1DepartTime?: TimeFilter | null;
  depart2DepotId?: UUIDFilter | null;
  depart2ArriveDate?: DateFilter | null;
  depart2ArriveTime?: TimeFilter | null;
  depart2ArriveTerminal?: StringFilter | null;
  depart2DepartDate?: DateFilter | null;
  depart2DepartTime?: TimeFilter | null;
  depart3DepotId?: UUIDFilter | null;
  depart3ArriveDate?: DateFilter | null;
  depart3ArriveTime?: TimeFilter | null;
  depart3ArriveTerminal?: StringFilter | null;
  depart3DepartDate?: DateFilter | null;
  depart3DepartTime?: TimeFilter | null;
  depart4DepotId?: UUIDFilter | null;
  depart4ArriveDate?: DateFilter | null;
  depart4ArriveTime?: TimeFilter | null;
  depart4ArriveTerminal?: StringFilter | null;
  depart4DepartDate?: DateFilter | null;
  depart4DepartTime?: TimeFilter | null;
  return1DepotId?: UUIDFilter | null;
  return1ArriveDate?: DateFilter | null;
  return1ArriveTime?: TimeFilter | null;
  return1ArriveTerminal?: StringFilter | null;
  return1DepartDate?: DateFilter | null;
  return1DepartTime?: TimeFilter | null;
  return2DepotId?: UUIDFilter | null;
  return2ArriveDate?: DateFilter | null;
  return2ArriveTime?: TimeFilter | null;
  return2ArriveTerminal?: StringFilter | null;
  return2DepartDate?: DateFilter | null;
  return2DepartTime?: TimeFilter | null;
  return3DepotId?: UUIDFilter | null;
  return3ArriveDate?: DateFilter | null;
  return3ArriveTime?: TimeFilter | null;
  return3ArriveTerminal?: StringFilter | null;
  return3DepartDate?: DateFilter | null;
  return3DepartTime?: TimeFilter | null;
  return4DepotId?: UUIDFilter | null;
  return4ArriveDate?: DateFilter | null;
  return4ArriveTime?: TimeFilter | null;
  return4ArriveTerminal?: StringFilter | null;
  return4DepartDate?: DateFilter | null;
  return4DepartTime?: TimeFilter | null;
  departureArrivalConfirmationCode?: StringFilter | null;
  returnArrivalConfirmationCode?: StringFilter | null;
  depart1DepartTerminal?: StringFilter | null;
  depart2DepartTerminal?: StringFilter | null;
  depart3DepartTerminal?: StringFilter | null;
  depart4DepartTerminal?: StringFilter | null;
  return1DepartTerminal?: StringFilter | null;
  return2DepartTerminal?: StringFilter | null;
  return3DepartTerminal?: StringFilter | null;
  return4DepartTerminal?: StringFilter | null;
  tripDistributions?: TripItemToManyTripDistributionFilter | null;
  tripDistributionsExist?: boolean | null;
  trip?: TripFilter | null;
  tripExists?: boolean | null;
  tripSectionItem?: TripSectionItemFilter | null;
  tripSectionItemExists?: boolean | null;
  tripSection?: TripSectionFilter | null;
  tripSectionExists?: boolean | null;
  unitOfMeasureItem?: UnitOfMeasureItemFilter | null;
  unitOfMeasureItemExists?: boolean | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  departureCountry?: CountryFilter | null;
  departureCountryExists?: boolean | null;
  departureArrivalCountry?: CountryFilter | null;
  departureArrivalCountryExists?: boolean | null;
  returnCountry?: CountryFilter | null;
  returnCountryExists?: boolean | null;
  returnArrivalCountry?: CountryFilter | null;
  returnArrivalCountryExists?: boolean | null;
  rentalCountry?: CountryFilter | null;
  rentalCountryExists?: boolean | null;
  pickupCountry?: CountryFilter | null;
  pickupCountryExists?: boolean | null;
  pickupDropCountry?: CountryFilter | null;
  pickupDropCountryExists?: boolean | null;
  returnPickupCountry?: CountryFilter | null;
  returnPickupCountryExists?: boolean | null;
  returnDropCountry?: CountryFilter | null;
  returnDropCountryExists?: boolean | null;
  departureStateRegion?: StateRegionFilter | null;
  departureStateRegionExists?: boolean | null;
  departureArrivalStateRegion?: StateRegionFilter | null;
  departureArrivalStateRegionExists?: boolean | null;
  returnStateRegion?: StateRegionFilter | null;
  returnStateRegionExists?: boolean | null;
  returnArrivalStateRegion?: StateRegionFilter | null;
  returnArrivalStateRegionExists?: boolean | null;
  rentalStateRegion?: StateRegionFilter | null;
  rentalStateRegionExists?: boolean | null;
  pickupStateRegion?: StateRegionFilter | null;
  pickupStateRegionExists?: boolean | null;
  pickupDropStateRegion?: StateRegionFilter | null;
  pickupDropStateRegionExists?: boolean | null;
  returnPickupStateRegion?: StateRegionFilter | null;
  returnPickupStateRegionExists?: boolean | null;
  returnDropStateRegion?: StateRegionFilter | null;
  returnDropStateRegionExists?: boolean | null;
  departureDepot?: DepotFilter | null;
  departureDepotExists?: boolean | null;
  departureArrivalDepot?: DepotFilter | null;
  departureArrivalDepotExists?: boolean | null;
  returnDepot?: DepotFilter | null;
  returnDepotExists?: boolean | null;
  returnArrivalDepot?: DepotFilter | null;
  returnArrivalDepotExists?: boolean | null;
  departureCarrier?: CarrierFilter | null;
  departureCarrierExists?: boolean | null;
  returnCarrier?: CarrierFilter | null;
  returnCarrierExists?: boolean | null;
  pickupSupplier?: SupplierFilter | null;
  pickupSupplierExists?: boolean | null;
  pickupDropSupplier?: SupplierFilter | null;
  pickupDropSupplierExists?: boolean | null;
  returnSupplier?: SupplierFilter | null;
  returnSupplierExists?: boolean | null;
  returnDropSupplier?: SupplierFilter | null;
  returnDropSupplierExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  pickupCurrency?: CompanyCurrencyFilter | null;
  pickupCurrencyExists?: boolean | null;
  pickupDropCurrency?: CompanyCurrencyFilter | null;
  pickupDropCurrencyExists?: boolean | null;
  returnCurrency?: CompanyCurrencyFilter | null;
  returnCurrencyExists?: boolean | null;
  returnDropCurrency?: CompanyCurrencyFilter | null;
  returnDropCurrencyExists?: boolean | null;
  departure1Depot?: DepotFilter | null;
  departure1DepotExists?: boolean | null;
  departure2Depot?: DepotFilter | null;
  departure2DepotExists?: boolean | null;
  departure3Depot?: DepotFilter | null;
  departure3DepotExists?: boolean | null;
  departure4Depot?: DepotFilter | null;
  departure4DepotExists?: boolean | null;
  return1Depot?: DepotFilter | null;
  return1DepotExists?: boolean | null;
  return2Depot?: DepotFilter | null;
  return2DepotExists?: boolean | null;
  return3Depot?: DepotFilter | null;
  return3DepotExists?: boolean | null;
  return4Depot?: DepotFilter | null;
  return4DepotExists?: boolean | null;
  and?: TripItemFilter[] | null;
  or?: TripItemFilter[] | null;
  not?: TripItemFilter | null;
}

/**
 * An input for mutations affecting `TripItem`
 */
export interface TripItemInput {
  tripSectionItemId: GqlUUID;
  supplierId?: GqlUUID | null;
  comments?: string | null;
  returnComments?: string | null;
  reservationNumber?: string | null;
  departureDepotId?: GqlUUID | null;
  departureDate?: GqlDate | null;
  departureTime?: GqlTime | null;
  departureArrivalDepotId?: GqlUUID | null;
  departureArrivalDate?: GqlDate | null;
  departureArrivalTime?: GqlTime | null;
  departureCarrierId?: GqlUUID | null;
  departureCarrierTripNumber?: string | null;
  departureCarrierConfirmationCode?: string | null;
  departureTerminal?: string | null;
  departureSeat?: string | null;
  isDepartureEconomyClass?: boolean | null;
  isDepartureBusinessClass?: boolean | null;
  isDepartureFirstClass?: boolean | null;
  isDepartureCharterClass?: boolean | null;
  isDepartureRefundable?: boolean | null;
  departureMiles?: number | null;
  departureAirfare?: GqlBigFloat | null;
  returnDepotId?: GqlUUID | null;
  returnDate?: GqlDate | null;
  returnTime?: GqlTime | null;
  returnArrivalDepotId?: GqlUUID | null;
  returnArrivalDate?: GqlDate | null;
  returnArrivalTime?: GqlTime | null;
  returnCarrierId?: GqlUUID | null;
  returnCarrierTripNumber?: string | null;
  returnCarrierConfirmationCode?: string | null;
  returnTerminal?: string | null;
  returnSeat?: string | null;
  isReturnEconomyClass?: boolean | null;
  isReturnBusinessClass?: boolean | null;
  isReturnFirstClass?: boolean | null;
  isReturnCharterClass?: boolean | null;
  isReturnRefundable?: boolean | null;
  returnMiles?: number | null;
  returnAirfare?: GqlBigFloat | null;
  rentalName?: string | null;
  rentalAddress1?: string | null;
  rentalAddress2?: string | null;
  rentalCityName?: string | null;
  rentalPostalCode?: string | null;
  rentalStateRegionId?: number | null;
  rentalCountryId?: number | null;
  checkInDate?: GqlDate | null;
  checkInTime?: GqlTime | null;
  checkOutDate?: GqlDate | null;
  checkOutTime?: GqlTime | null;
  rentalTerm?: number | null;
  rentalPrice?: GqlBigFloat | null;
  rentalExtendedPrice?: GqlBigFloat | null;
  pickupName?: string | null;
  pickupAddress1?: string | null;
  pickupAddress2?: string | null;
  pickupCityName?: string | null;
  pickupPostalCode?: string | null;
  pickupStateRegionId?: number | null;
  pickupCountryId?: number | null;
  pickupDropName?: string | null;
  pickupDropAddress1?: string | null;
  pickupDropAddress2?: string | null;
  pickupDropCityName?: string | null;
  pickupDropPostalCode?: string | null;
  pickupDropStateRegionId?: number | null;
  pickupDropCountryId?: number | null;
  pickupPrice?: GqlBigFloat | null;
  returnName?: string | null;
  returnAddress1?: string | null;
  returnAddress2?: string | null;
  returnCityName?: string | null;
  returnPostalCode?: string | null;
  returnPickupStateRegionId?: number | null;
  returnPickupCountryId?: number | null;
  returnDropName?: string | null;
  returnDropAddress1?: string | null;
  returnDropAddress2?: string | null;
  returnDropCityName?: string | null;
  returnDropPostalCode?: string | null;
  returnDropStateRegionId?: number | null;
  returnDropCountryId?: number | null;
  returnPrice?: GqlBigFloat | null;
  bookingFeeQuantity?: number | null;
  bookingFeeRate?: GqlBigFloat | null;
  bookingFeeTotal?: GqlBigFloat | null;
  perDiemQuantity?: number | null;
  perDiemRate?: GqlBigFloat | null;
  perDiemTotal?: GqlBigFloat | null;
  incidentalQuantity?: number | null;
  incidentalRate?: GqlBigFloat | null;
  incidentalTotal?: GqlBigFloat | null;
  miscellaneousQuantity?: number | null;
  miscellaneousRate?: GqlBigFloat | null;
  miscellaneousTotal?: GqlBigFloat | null;
  itemTotal?: GqlBigFloat | null;
  rentalEmailAddress?: string | null;
  rentalTelephoneNumber?: string | null;
  pickupEmailAddress?: string | null;
  pickupTelephoneNumber?: string | null;
  pickupDropEmailAddress?: string | null;
  pickupDropTelephoneNumber?: string | null;
  returnEmailAddress?: string | null;
  returnTelephoneNumber?: string | null;
  returnDropEmailAddress?: string | null;
  returnDropTelephoneNumber?: string | null;
  departureStops?: number | null;
  returnStops?: number | null;
  isDeparturePremiumClass?: boolean | null;
  isReturnPremiumClass?: boolean | null;
  pickupArrivalName?: string | null;
  pickupArrivalDropName?: string | null;
  pickupArrivalPrice?: GqlBigFloat | null;
  returnArrivalName?: string | null;
  returnArrivalDropName?: string | null;
  returnArrivalPrice?: GqlBigFloat | null;
  pickupSupplierId?: GqlUUID | null;
  pickupDropSupplierId?: GqlUUID | null;
  returnSupplierId?: GqlUUID | null;
  returnDropSupplierId?: GqlUUID | null;
  isSupplierBreakdownRequired?: boolean | null;
  policyExpenditureTypeId?: GqlUUID | null;
  policyAllowance?: GqlBigFloat | null;
  policyMiles?: number | null;
  currencyId?: number | null;
  pickupCurrencyId?: number | null;
  pickupDropCurrencyId?: number | null;
  returnCurrencyId?: number | null;
  returnDropCurrencyId?: number | null;
  departureEticketNumber?: string | null;
  returnEticketNumber?: string | null;
  departureTerminalArrival?: string | null;
  returnTerminalArrival?: string | null;
  preferredRateTypeId?: number | null;
  isPreferredSupplier?: boolean | null;
  isExceptionRequired?: boolean | null;
  exceptionComments?: string | null;
  depart1DepotId?: GqlUUID | null;
  depart1ArriveDate?: GqlDate | null;
  depart1ArriveTime?: GqlTime | null;
  depart1ArriveTerminal?: string | null;
  depart1DepartDate?: GqlDate | null;
  depart1DepartTime?: GqlTime | null;
  depart2DepotId?: GqlUUID | null;
  depart2ArriveDate?: GqlDate | null;
  depart2ArriveTime?: GqlTime | null;
  depart2ArriveTerminal?: string | null;
  depart2DepartDate?: GqlDate | null;
  depart2DepartTime?: GqlTime | null;
  depart3DepotId?: GqlUUID | null;
  depart3ArriveDate?: GqlDate | null;
  depart3ArriveTime?: GqlTime | null;
  depart3ArriveTerminal?: string | null;
  depart3DepartDate?: GqlDate | null;
  depart3DepartTime?: GqlTime | null;
  depart4DepotId?: GqlUUID | null;
  depart4ArriveDate?: GqlDate | null;
  depart4ArriveTime?: GqlTime | null;
  depart4ArriveTerminal?: string | null;
  depart4DepartDate?: GqlDate | null;
  depart4DepartTime?: GqlTime | null;
  return1DepotId?: GqlUUID | null;
  return1ArriveDate?: GqlDate | null;
  return1ArriveTime?: GqlTime | null;
  return1ArriveTerminal?: string | null;
  return1DepartDate?: GqlDate | null;
  return1DepartTime?: GqlTime | null;
  return2DepotId?: GqlUUID | null;
  return2ArriveDate?: GqlDate | null;
  return2ArriveTime?: GqlTime | null;
  return2ArriveTerminal?: string | null;
  return2DepartDate?: GqlDate | null;
  return2DepartTime?: GqlTime | null;
  return3DepotId?: GqlUUID | null;
  return3ArriveDate?: GqlDate | null;
  return3ArriveTime?: GqlTime | null;
  return3ArriveTerminal?: string | null;
  return3DepartDate?: GqlDate | null;
  return3DepartTime?: GqlTime | null;
  return4DepotId?: GqlUUID | null;
  return4ArriveDate?: GqlDate | null;
  return4ArriveTime?: GqlTime | null;
  return4ArriveTerminal?: string | null;
  return4DepartDate?: GqlDate | null;
  return4DepartTime?: GqlTime | null;
  departureArrivalConfirmationCode?: string | null;
  returnArrivalConfirmationCode?: string | null;
  depart1DepartTerminal?: string | null;
  depart2DepartTerminal?: string | null;
  depart3DepartTerminal?: string | null;
  depart4DepartTerminal?: string | null;
  return1DepartTerminal?: string | null;
  return2DepartTerminal?: string | null;
  return3DepartTerminal?: string | null;
  return4DepartTerminal?: string | null;
}

/**
 * Represents an update to a `TripItem`. Fields that are set will be updated.
 */
export interface TripItemPatch {
  tripSectionItemId?: GqlUUID | null;
  supplierId?: GqlUUID | null;
  comments?: string | null;
  returnComments?: string | null;
  reservationNumber?: string | null;
  departureDepotId?: GqlUUID | null;
  departureDate?: GqlDate | null;
  departureTime?: GqlTime | null;
  departureArrivalDepotId?: GqlUUID | null;
  departureArrivalDate?: GqlDate | null;
  departureArrivalTime?: GqlTime | null;
  departureCarrierId?: GqlUUID | null;
  departureCarrierTripNumber?: string | null;
  departureCarrierConfirmationCode?: string | null;
  departureTerminal?: string | null;
  departureSeat?: string | null;
  isDepartureEconomyClass?: boolean | null;
  isDepartureBusinessClass?: boolean | null;
  isDepartureFirstClass?: boolean | null;
  isDepartureCharterClass?: boolean | null;
  isDepartureRefundable?: boolean | null;
  departureMiles?: number | null;
  departureAirfare?: GqlBigFloat | null;
  returnDepotId?: GqlUUID | null;
  returnDate?: GqlDate | null;
  returnTime?: GqlTime | null;
  returnArrivalDepotId?: GqlUUID | null;
  returnArrivalDate?: GqlDate | null;
  returnArrivalTime?: GqlTime | null;
  returnCarrierId?: GqlUUID | null;
  returnCarrierTripNumber?: string | null;
  returnCarrierConfirmationCode?: string | null;
  returnTerminal?: string | null;
  returnSeat?: string | null;
  isReturnEconomyClass?: boolean | null;
  isReturnBusinessClass?: boolean | null;
  isReturnFirstClass?: boolean | null;
  isReturnCharterClass?: boolean | null;
  isReturnRefundable?: boolean | null;
  returnMiles?: number | null;
  returnAirfare?: GqlBigFloat | null;
  rentalName?: string | null;
  rentalAddress1?: string | null;
  rentalAddress2?: string | null;
  rentalCityName?: string | null;
  rentalPostalCode?: string | null;
  rentalStateRegionId?: number | null;
  rentalCountryId?: number | null;
  checkInDate?: GqlDate | null;
  checkInTime?: GqlTime | null;
  checkOutDate?: GqlDate | null;
  checkOutTime?: GqlTime | null;
  rentalTerm?: number | null;
  rentalPrice?: GqlBigFloat | null;
  rentalExtendedPrice?: GqlBigFloat | null;
  pickupName?: string | null;
  pickupAddress1?: string | null;
  pickupAddress2?: string | null;
  pickupCityName?: string | null;
  pickupPostalCode?: string | null;
  pickupStateRegionId?: number | null;
  pickupCountryId?: number | null;
  pickupDropName?: string | null;
  pickupDropAddress1?: string | null;
  pickupDropAddress2?: string | null;
  pickupDropCityName?: string | null;
  pickupDropPostalCode?: string | null;
  pickupDropStateRegionId?: number | null;
  pickupDropCountryId?: number | null;
  pickupPrice?: GqlBigFloat | null;
  returnName?: string | null;
  returnAddress1?: string | null;
  returnAddress2?: string | null;
  returnCityName?: string | null;
  returnPostalCode?: string | null;
  returnPickupStateRegionId?: number | null;
  returnPickupCountryId?: number | null;
  returnDropName?: string | null;
  returnDropAddress1?: string | null;
  returnDropAddress2?: string | null;
  returnDropCityName?: string | null;
  returnDropPostalCode?: string | null;
  returnDropStateRegionId?: number | null;
  returnDropCountryId?: number | null;
  returnPrice?: GqlBigFloat | null;
  bookingFeeQuantity?: number | null;
  bookingFeeRate?: GqlBigFloat | null;
  bookingFeeTotal?: GqlBigFloat | null;
  perDiemQuantity?: number | null;
  perDiemRate?: GqlBigFloat | null;
  perDiemTotal?: GqlBigFloat | null;
  incidentalQuantity?: number | null;
  incidentalRate?: GqlBigFloat | null;
  incidentalTotal?: GqlBigFloat | null;
  miscellaneousQuantity?: number | null;
  miscellaneousRate?: GqlBigFloat | null;
  miscellaneousTotal?: GqlBigFloat | null;
  itemTotal?: GqlBigFloat | null;
  rentalEmailAddress?: string | null;
  rentalTelephoneNumber?: string | null;
  pickupEmailAddress?: string | null;
  pickupTelephoneNumber?: string | null;
  pickupDropEmailAddress?: string | null;
  pickupDropTelephoneNumber?: string | null;
  returnEmailAddress?: string | null;
  returnTelephoneNumber?: string | null;
  returnDropEmailAddress?: string | null;
  returnDropTelephoneNumber?: string | null;
  departureStops?: number | null;
  returnStops?: number | null;
  isDeparturePremiumClass?: boolean | null;
  isReturnPremiumClass?: boolean | null;
  pickupArrivalName?: string | null;
  pickupArrivalDropName?: string | null;
  pickupArrivalPrice?: GqlBigFloat | null;
  returnArrivalName?: string | null;
  returnArrivalDropName?: string | null;
  returnArrivalPrice?: GqlBigFloat | null;
  pickupSupplierId?: GqlUUID | null;
  pickupDropSupplierId?: GqlUUID | null;
  returnSupplierId?: GqlUUID | null;
  returnDropSupplierId?: GqlUUID | null;
  isSupplierBreakdownRequired?: boolean | null;
  policyExpenditureTypeId?: GqlUUID | null;
  policyAllowance?: GqlBigFloat | null;
  policyMiles?: number | null;
  currencyId?: number | null;
  pickupCurrencyId?: number | null;
  pickupDropCurrencyId?: number | null;
  returnCurrencyId?: number | null;
  returnDropCurrencyId?: number | null;
  departureEticketNumber?: string | null;
  returnEticketNumber?: string | null;
  departureTerminalArrival?: string | null;
  returnTerminalArrival?: string | null;
  preferredRateTypeId?: number | null;
  isPreferredSupplier?: boolean | null;
  isExceptionRequired?: boolean | null;
  exceptionComments?: string | null;
  depart1DepotId?: GqlUUID | null;
  depart1ArriveDate?: GqlDate | null;
  depart1ArriveTime?: GqlTime | null;
  depart1ArriveTerminal?: string | null;
  depart1DepartDate?: GqlDate | null;
  depart1DepartTime?: GqlTime | null;
  depart2DepotId?: GqlUUID | null;
  depart2ArriveDate?: GqlDate | null;
  depart2ArriveTime?: GqlTime | null;
  depart2ArriveTerminal?: string | null;
  depart2DepartDate?: GqlDate | null;
  depart2DepartTime?: GqlTime | null;
  depart3DepotId?: GqlUUID | null;
  depart3ArriveDate?: GqlDate | null;
  depart3ArriveTime?: GqlTime | null;
  depart3ArriveTerminal?: string | null;
  depart3DepartDate?: GqlDate | null;
  depart3DepartTime?: GqlTime | null;
  depart4DepotId?: GqlUUID | null;
  depart4ArriveDate?: GqlDate | null;
  depart4ArriveTime?: GqlTime | null;
  depart4ArriveTerminal?: string | null;
  depart4DepartDate?: GqlDate | null;
  depart4DepartTime?: GqlTime | null;
  return1DepotId?: GqlUUID | null;
  return1ArriveDate?: GqlDate | null;
  return1ArriveTime?: GqlTime | null;
  return1ArriveTerminal?: string | null;
  return1DepartDate?: GqlDate | null;
  return1DepartTime?: GqlTime | null;
  return2DepotId?: GqlUUID | null;
  return2ArriveDate?: GqlDate | null;
  return2ArriveTime?: GqlTime | null;
  return2ArriveTerminal?: string | null;
  return2DepartDate?: GqlDate | null;
  return2DepartTime?: GqlTime | null;
  return3DepotId?: GqlUUID | null;
  return3ArriveDate?: GqlDate | null;
  return3ArriveTime?: GqlTime | null;
  return3ArriveTerminal?: string | null;
  return3DepartDate?: GqlDate | null;
  return3DepartTime?: GqlTime | null;
  return4DepotId?: GqlUUID | null;
  return4ArriveDate?: GqlDate | null;
  return4ArriveTime?: GqlTime | null;
  return4ArriveTerminal?: string | null;
  return4DepartDate?: GqlDate | null;
  return4DepartTime?: GqlTime | null;
  departureArrivalConfirmationCode?: string | null;
  returnArrivalConfirmationCode?: string | null;
  depart1DepartTerminal?: string | null;
  depart2DepartTerminal?: string | null;
  depart3DepartTerminal?: string | null;
  depart4DepartTerminal?: string | null;
  return1DepartTerminal?: string | null;
  return2DepartTerminal?: string | null;
  return3DepartTerminal?: string | null;
  return4DepartTerminal?: string | null;
}

/**
 * A filter to be used against many `TripDistribution` object types. All fields are combined with a logical ‘and.’
 */
export interface TripItemToManyTripDistributionFilter {
  every?: TripDistributionFilter | null;
  some?: TripDistributionFilter | null;
  none?: TripDistributionFilter | null;
}

/**
 * An input for mutations affecting `TripItemUpdateType`
 */
export interface TripItemUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  tripItemUpdatePatch?: TripItemPatch | null;
}

/**
 * Represents an update to a `Trip`. Fields that are set will be updated.
 */
export interface TripPatch {
  travelerId?: GqlUUID | null;
  specialInstructions?: string | null;
  comments?: string | null;
  _isOverBudget?: boolean | null;
}

/**
 * A filter to be used against `TripSection` object types. All fields are combined with a logical ‘and.’
 */
export interface TripSectionFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  sortOrder?: StringFilter | null;
  description?: StringFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  lookupNameGroupSequence?: IntFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  tripItemsByTripSectionId?: TripSectionToManyTripItemFilter | null;
  tripItemsByTripSectionIdExist?: boolean | null;
  tripSectionItems?: TripSectionToManyTripSectionItemFilter | null;
  tripSectionItemsExist?: boolean | null;
  and?: TripSectionFilter[] | null;
  or?: TripSectionFilter[] | null;
  not?: TripSectionFilter | null;
}

/**
 * A filter to be used against `TripSectionItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TripSectionItemFilter {
  id?: UUIDFilter | null;
  tripSectionId?: UUIDFilter | null;
  name?: StringFilter | null;
  sortOrder?: StringFilter | null;
  description?: StringFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  expenditureTypeId?: UUIDFilter | null;
  purchaseOrderItemTypeId?: UUIDFilter | null;
  unitOfMeasureItemTypeId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  tripItems?: TripSectionItemToManyTripItemFilter | null;
  tripItemsExist?: boolean | null;
  tripSection?: TripSectionFilter | null;
  tripSectionExists?: boolean | null;
  and?: TripSectionItemFilter[] | null;
  or?: TripSectionItemFilter[] | null;
  not?: TripSectionItemFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TripSectionItemToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TripSectionToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against many `TripSectionItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TripSectionToManyTripSectionItemFilter {
  every?: TripSectionItemFilter | null;
  some?: TripSectionItemFilter | null;
  none?: TripSectionItemFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TripToManyApprovalHistoryItemFilter {
  every?: ApprovalHistoryItemFilter | null;
  some?: ApprovalHistoryItemFilter | null;
  none?: ApprovalHistoryItemFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface TripToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityEnvironmental` object types. All fields are combined with a logical ‘and.’
 */
export interface TripToManyEntityEnvironmentalFilter {
  every?: EntityEnvironmentalFilter | null;
  some?: EntityEnvironmentalFilter | null;
  none?: EntityEnvironmentalFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface TripToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `TripHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface TripToManyTripHistoryFilter {
  every?: TripHistoryFilter | null;
  some?: TripHistoryFilter | null;
  none?: TripHistoryFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface TripToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * All input for the `tripUpdate` mutation.
 */
export interface TripUpdateInput {
  clientMutationId?: string | null;
  tripId: GqlUUID;
  rowTimestamp: GqlDatetime;
  tripPatch?: TripPatch | null;
  tripItemCreate?: (TripItemInput | null)[] | null;
  tripItemUpdate?: (TripItemUpdateTypeInput | null)[] | null;
  tripItemDelete?: (EntityDeleteInput | null)[] | null;
  tripDistributionCreate?: (TripDistributionInput | null)[] | null;
  tripDistributionUpdate?: (TripDistributionUpdateTypeInput | null)[] | null;
  tripDistributionDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * A filter to be used against UUID fields. All fields are combined with a logical ‘and.’
 */
export interface UUIDFilter {
  isNull?: boolean | null;
  equalTo?: GqlUUID | null;
  notEqualTo?: GqlUUID | null;
  distinctFrom?: GqlUUID | null;
  notDistinctFrom?: GqlUUID | null;
  in?: GqlUUID[] | null;
  notIn?: GqlUUID[] | null;
  lessThan?: GqlUUID | null;
  lessThanOrEqualTo?: GqlUUID | null;
  greaterThan?: GqlUUID | null;
  greaterThanOrEqualTo?: GqlUUID | null;
}

/**
 * A filter to be used against `UnitOfMeasureGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureGroupFilter {
  id?: UUIDFilter | null;
  unitOfMeasureGroup?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isPurchaseOrderCalculation?: BooleanFilter | null;
  isBudgetCalculation?: BooleanFilter | null;
  isBillingCalculation?: BooleanFilter | null;
  isPayrollCalculation?: BooleanFilter | null;
  isEnvironmentalCalculation?: BooleanFilter | null;
  isIncentiveCalculation?: BooleanFilter | null;
  isTaxCalculation?: BooleanFilter | null;
  isShippingCalculation?: BooleanFilter | null;
  isInsuranceCalculation?: BooleanFilter | null;
  isCalendarSelection?: BooleanFilter | null;
  isMultiDaySelection?: BooleanFilter | null;
  isTravelCalculation?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  expenditureTypes?: UnitOfMeasureGroupToManyExpenditureTypeFilter | null;
  expenditureTypesExist?: boolean | null;
  unitOfMeasureItemsByUnitOfMeasureGroupId?: UnitOfMeasureGroupToManyUnitOfMeasureItemFilter | null;
  unitOfMeasureItemsByUnitOfMeasureGroupIdExist?: boolean | null;
  companyPurchaseOrderItemTypes?: UnitOfMeasureGroupToManyCompanyPurchaseOrderItemTypeFilter | null;
  companyPurchaseOrderItemTypesExist?: boolean | null;
  purchaseOrderItemTypes?: UnitOfMeasureGroupToManyPurchaseOrderItemTypeFilter | null;
  purchaseOrderItemTypesExist?: boolean | null;
  and?: UnitOfMeasureGroupFilter[] | null;
  or?: UnitOfMeasureGroupFilter[] | null;
  not?: UnitOfMeasureGroupFilter | null;
}

/**
 * A filter to be used against many `CompanyPurchaseOrderItemType` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureGroupToManyCompanyPurchaseOrderItemTypeFilter {
  every?: CompanyPurchaseOrderItemTypeFilter | null;
  some?: CompanyPurchaseOrderItemTypeFilter | null;
  none?: CompanyPurchaseOrderItemTypeFilter | null;
}

/**
 * A filter to be used against many `ExpenditureType` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureGroupToManyExpenditureTypeFilter {
  every?: ExpenditureTypeFilter | null;
  some?: ExpenditureTypeFilter | null;
  none?: ExpenditureTypeFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItemType` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureGroupToManyPurchaseOrderItemTypeFilter {
  every?: PurchaseOrderItemTypeFilter | null;
  some?: PurchaseOrderItemTypeFilter | null;
  none?: PurchaseOrderItemTypeFilter | null;
}

/**
 * A filter to be used against many `UnitOfMeasureItem` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureGroupToManyUnitOfMeasureItemFilter {
  every?: UnitOfMeasureItemFilter | null;
  some?: UnitOfMeasureItemFilter | null;
  none?: UnitOfMeasureItemFilter | null;
}

/**
 * A filter to be used against `UnitOfMeasureItem` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureItemFilter {
  id?: UUIDFilter | null;
  _unitOfMeasureGroupId?: UUIDFilter | null;
  unitOfMeasureItem?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: IntFilter | null;
  prorationValue?: BigFloatFilter | null;
  unitFactor1?: BigFloatFilter | null;
  unitFactor2?: BigFloatFilter | null;
  unitFactor3?: BigFloatFilter | null;
  unitFactor4?: BigFloatFilter | null;
  unitFactor5?: BigFloatFilter | null;
  isMinuteBased?: BooleanFilter | null;
  isDayBased?: BooleanFilter | null;
  isWeekBased?: BooleanFilter | null;
  isMonthBased?: BooleanFilter | null;
  isPoundBased?: BooleanFilter | null;
  isInchBased?: BooleanFilter | null;
  isMileBased?: BooleanFilter | null;
  isWattBased?: BooleanFilter | null;
  isRectangleBased?: BooleanFilter | null;
  isCubicBased?: BooleanFilter | null;
  isGallonBased?: BooleanFilter | null;
  isQuantityBased?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  partialAbbreviation?: StringFilter | null;
  isDateDurationAddAllowed?: BooleanFilter | null;
  calculationType?: StandardCalculationTypeFilter | null;
  abbreviation?: StringFilter | null;
  purchaseOrderItems?: UnitOfMeasureItemToManyPurchaseOrderItemFilter | null;
  purchaseOrderItemsExist?: boolean | null;
  tripItemsByUnitOfMeasureItemTypeId?: UnitOfMeasureItemToManyTripItemFilter | null;
  tripItemsByUnitOfMeasureItemTypeIdExist?: boolean | null;
  unitOfMeasureGroup?: UnitOfMeasureGroupFilter | null;
  unitOfMeasureGroupExists?: boolean | null;
  and?: UnitOfMeasureItemFilter[] | null;
  or?: UnitOfMeasureItemFilter[] | null;
  not?: UnitOfMeasureItemFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrderItem` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureItemToManyPurchaseOrderItemFilter {
  every?: PurchaseOrderItemFilter | null;
  some?: PurchaseOrderItemFilter | null;
  none?: PurchaseOrderItemFilter | null;
}

/**
 * A filter to be used against many `TripItem` object types. All fields are combined with a logical ‘and.’
 */
export interface UnitOfMeasureItemToManyTripItemFilter {
  every?: TripItemFilter | null;
  some?: TripItemFilter | null;
  none?: TripItemFilter | null;
}

/**
 * A filter to be used against `UserApp` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppFilter {
  appId?: IntFilter | null;
  appName?: StringFilter | null;
  linkTypeId?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkHint?: StringFilter | null;
  linkType?: AppRouterFilter | null;
  linkEntityTypeId?: IntFilter | null;
  appCategoryId?: IntFilter | null;
  appCategoryName?: StringFilter | null;
  documentGroupId?: UUIDFilter | null;
  approvalTypeId?: IntFilter | null;
  validationApprovalTypeId?: IntFilter | null;
  isCreateAllowed?: BooleanFilter | null;
  isReadAllowed?: BooleanFilter | null;
  isUpdateAllowed?: BooleanFilter | null;
  isDeleteAllowed?: BooleanFilter | null;
  isNotesAllowed?: BooleanFilter | null;
  isRatingsAllowed?: BooleanFilter | null;
  isDocumentsAllowed?: BooleanFilter | null;
  isTagsAllowed?: BooleanFilter | null;
  isEnvironmentalAllowed?: BooleanFilter | null;
  isChangeHistoryAllowed?: BooleanFilter | null;
  isMessagingAllowed?: BooleanFilter | null;
  isRowProtectionAllowed?: BooleanFilter | null;
  isAppSearchable?: BooleanFilter | null;
  isAppEntry?: BooleanFilter | null;
  isAppReporting?: BooleanFilter | null;
  isAppService?: BooleanFilter | null;
  isSystemAppCategory?: BooleanFilter | null;
  isUpdateAfterApprovalAllowed?: BooleanFilter | null;
  isSoftAccountCodingAllowed?: BooleanFilter | null;
  isApprovalRequestAllowed?: BooleanFilter | null;
  isApprovalProcessExecutedOnSave?: BooleanFilter | null;
  isValidationRequestAllowed?: BooleanFilter | null;
  isDashboardTile?: BooleanFilter | null;
  isWebLink?: BooleanFilter | null;
  isMobileLink?: BooleanFilter | null;
  isGlobalSearchAvailable?: BooleanFilter | null;
  approvalHistoriesByAppId?: UserAppToManyApprovalHistoryFilter | null;
  approvalHistoriesByAppIdExist?: boolean | null;
  userApprovalsByAppId?: UserAppToManyUserApprovalFilter | null;
  userApprovalsByAppIdExist?: boolean | null;
  mobileUserMessagesByAppId?: UserAppToManyMobileUserMessageFilter | null;
  mobileUserMessagesByAppIdExist?: boolean | null;
  mobileUserNotificationsByAppId?: UserAppToManyMobileUserNotificationFilter | null;
  mobileUserNotificationsByAppIdExist?: boolean | null;
  userMessagesByAppId?: UserAppToManyUserMessageFilter | null;
  userMessagesByAppIdExist?: boolean | null;
  userNotificationsByAppId?: UserAppToManyUserNotificationFilter | null;
  userNotificationsByAppIdExist?: boolean | null;
  searchHintsByLinkType?: UserAppToManySearchHintFilter | null;
  searchHintsByLinkTypeExist?: boolean | null;
  globalHintLink?: GlobalHintFilter | null;
  globalHintLinkExists?: boolean | null;
  and?: UserAppFilter[] | null;
  or?: UserAppFilter[] | null;
  not?: UserAppFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `MobileUserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppToManyMobileUserNotificationFilter {
  every?: MobileUserNotificationFilter | null;
  some?: MobileUserNotificationFilter | null;
  none?: MobileUserNotificationFilter | null;
}

/**
 * A filter to be used against many `SearchHint` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppToManySearchHintFilter {
  every?: SearchHintFilter | null;
  some?: SearchHintFilter | null;
  none?: SearchHintFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface UserAppToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * A filter to be used against `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalFilter {
  id?: UUIDFilter | null;
  displayEntityApprovalId?: UUIDFilter | null;
  entityApprovalBranchId?: UUIDFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  appId?: IntFilter | null;
  approvalTypeId?: IntFilter | null;
  approvalTopic?: StringFilter | null;
  requesterUserProfileId?: UUIDFilter | null;
  approverUserProfileId?: UUIDFilter | null;
  requestedDate?: DatetimeFilter | null;
  requesterName?: StringFilter | null;
  requiredDate?: DateFilter | null;
  approvedDate?: DatetimeFilter | null;
  rejectedDate?: DatetimeFilter | null;
  finalizedDate?: DatetimeFilter | null;
  revokedDate?: DatetimeFilter | null;
  revokedByUserProfileId?: UUIDFilter | null;
  revokedByUserName?: StringFilter | null;
  isAutoApproval?: BooleanFilter | null;
  digitalSignature?: StringFilter | null;
  entityApprovalCompanyId?: UUIDFilter | null;
  entityApprovalBusinessUnitId?: UUIDFilter | null;
  statusTypeId?: IntFilter | null;
  iconTypeId?: IntFilter | null;
  layoutType?: TransactionLayoutFilter | null;
  isRequester?: BooleanFilter | null;
  isApprover?: BooleanFilter | null;
  isObserver?: BooleanFilter | null;
  approvalPersona?: ApprovalPersonasFilter | null;
  isApproved?: BooleanFilter | null;
  isRejected?: BooleanFilter | null;
  isPending?: BooleanFilter | null;
  isHistory?: BooleanFilter | null;
  isRevoked?: BooleanFilter | null;
  _isRevocable?: BooleanFilter | null;
  isApprovalTreeViewerAllowed?: BooleanFilter | null;
  isPersonalApproverAddingAllowed?: BooleanFilter | null;
  isApproverAddingAllowed?: BooleanFilter | null;
  isLevelBypassingAllowed?: BooleanFilter | null;
  description1?: StringFilter | null;
  description2?: StringFilter | null;
  description3?: StringFilter | null;
  description4?: StringFilter | null;
  description5?: StringFilter | null;
  date1?: DateFilter | null;
  date2?: DateFilter | null;
  date3?: DateFilter | null;
  date4?: DatetimeFilter | null;
  date5?: DatetimeFilter | null;
  unit1?: IntFilter | null;
  unit2?: IntFilter | null;
  unit3?: IntFilter | null;
  unit4?: IntFilter | null;
  unit5?: IntFilter | null;
  rate1?: BigFloatFilter | null;
  rate2?: BigFloatFilter | null;
  rate3?: BigFloatFilter | null;
  rate4?: BigFloatFilter | null;
  rate5?: BigFloatFilter | null;
  amount1?: BigFloatFilter | null;
  amount2?: BigFloatFilter | null;
  amount3?: BigFloatFilter | null;
  amount4?: BigFloatFilter | null;
  amount5?: BigFloatFilter | null;
  approvalBranchId?: UUIDFilter | null;
  branchApprovedDate?: DatetimeFilter | null;
  branchRejectedDate?: DatetimeFilter | null;
  branchStatusTypeId?: IntFilter | null;
  branchDigitalSignature?: StringFilter | null;
  entityApprovalTreeId?: UUIDFilter | null;
  approvalTreeId?: UUIDFilter | null;
  approvalTreeLevel?: IntFilter | null;
  treeApprovedDate?: DatetimeFilter | null;
  treeRejectedDate?: DatetimeFilter | null;
  treeStatusTypeId?: IntFilter | null;
  entityApprovalBranchRowTimestamp?: DatetimeFilter | null;
  _companyId?: UUIDFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  currencyId?: IntFilter | null;
  businessUnitId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  currencyIsoCode?: StringFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectPeriodId?: UUIDFilter | null;
  _periodYear?: StringFilter | null;
  _periodStartDate?: DateFilter | null;
  _periodEndDate?: DateFilter | null;
  _periodId?: UUIDFilter | null;
  _isAccountingEntryStampedCompleted?: BooleanFilter | null;
  _accountingStampUserId?: UUIDFilter | null;
  _accountingStampUserName?: StringFilter | null;
  _accountingStampDate?: DatetimeFilter | null;
  _accountingStampTransactionReference?: StringFilter | null;
  _isTransactionCancelled?: BooleanFilter | null;
  approvalGroup?: StringFilter | null;
  sortOrderGroup?: StringFilter | null;
  additionalApprovalInformation?: StringFilter | null;
  approvalPhaseTypeId?: IntFilter | null;
  _pendingApprovers?: StringFilter | null;
  _verificationLinkTypeId?: IntFilter | null;
  _isValidating?: BooleanFilter | null;
  _verificationDate?: DatetimeFilter | null;
  _isUserEnMasseApprovalAllowed?: BooleanFilter | null;
  _isLookupNameApplied?: BooleanFilter | null;
  _lookupNameId?: UUIDFilter | null;
  description6?: StringFilter | null;
  description7?: StringFilter | null;
  description8?: StringFilter | null;
  description9?: StringFilter | null;
  description10?: StringFilter | null;
  linkedEntityId?: UUIDFilter | null;
  urgencyLevel?: IntFilter | null;
  _isUrgentApproval?: BooleanFilter | null;
  isUrgencyUpdateAllowed?: BooleanFilter | null;
  _documentPackageId?: UUIDFilter | null;
  _isExtendedNotePrivileges?: BooleanFilter | null;
  _isExtendedUploadPrivileges?: BooleanFilter | null;
  approvalHistoryItemsByDisplayEntityApprovalId?: UserApprovalToManyApprovalHistoryItemFilter | null;
  approvalHistoryItemsByDisplayEntityApprovalIdExist?: boolean | null;
  approvalHistorySignaturesByEntityApprovalId?: UserApprovalToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityApprovalIdExist?: boolean | null;
  approvalSignaturesByEntityApprovalId?: UserApprovalToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityApprovalIdExist?: boolean | null;
  entityTagsByEntityId?: UserApprovalToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: UserApprovalToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: UserApprovalToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: UserApprovalToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  userApprovalPhaseType?: ApprovalPhaseFilter | null;
  userApprovalPhaseTypeExists?: boolean | null;
  entityDocumentsByEntityId?: UserApprovalToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  entityEnvironmentalsByEntityId?: UserApprovalToManyEntityEnvironmentalFilter | null;
  entityEnvironmentalsByEntityIdExist?: boolean | null;
  userEntityApp?: UserAppFilter | null;
  userEntityAppExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  approvalType?: ApprovalTypeFilter | null;
  approvalTypeExists?: boolean | null;
  invoice?: InvoiceFilter | null;
  invoiceExists?: boolean | null;
  linkedInvoice?: InvoiceFilter | null;
  linkedInvoiceExists?: boolean | null;
  branchStatusType?: StatusTypeFilter | null;
  branchStatusTypeExists?: boolean | null;
  treeStatusType?: StatusTypeFilter | null;
  treeStatusTypeExists?: boolean | null;
  iconType?: IconTypeFilter | null;
  iconTypeExists?: boolean | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  businessUnit?: BusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  department?: DepartmentFilter | null;
  departmentExists?: boolean | null;
  companyCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  companyCorporatePeriodExists?: boolean | null;
  userApprover?: ApprovalHistoryApproverFilter | null;
  userApproverExists?: boolean | null;
  payCycle?: PayCycleFilter | null;
  payCycleExists?: boolean | null;
  approvalSetup?: ApprovalFilter | null;
  approvalSetupExists?: boolean | null;
  userProfile?: UserProfileFilter | null;
  userProfileExists?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  supplier?: SupplierFilter | null;
  supplierExists?: boolean | null;
  traveler?: TravelerFilter | null;
  travelerExists?: boolean | null;
  batchTransaction?: BatchTransactionFilter | null;
  batchTransactionExists?: boolean | null;
  lookupName?: LookupNameFilter | null;
  lookupNameExists?: boolean | null;
  verificationLinkType?: LinkTypeFilter | null;
  verificationLinkTypeExists?: boolean | null;
  purchaseOrder?: PurchaseOrderFilter | null;
  purchaseOrderExists?: boolean | null;
  travelAuthorization?: TravelAuthorizationFilter | null;
  travelAuthorizationExists?: boolean | null;
  and?: UserApprovalFilter[] | null;
  or?: UserApprovalFilter[] | null;
  not?: UserApprovalFilter | null;
}

/**
 * A filter to be used against `UserApprovalGrant` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalGrantFilter {
  id?: UUIDFilter | null;
  userProfileId?: UUIDFilter | null;
  approvalTypeId?: IntFilter | null;
  isApprover?: BooleanFilter | null;
  isRequester?: BooleanFilter | null;
  isObserver?: BooleanFilter | null;
  isEnMasseApprovalsEnabled?: BooleanFilter | null;
  isAutoApproveEnabled?: BooleanFilter | null;
  isSelfApproveEnabled?: BooleanFilter | null;
  isApprovalTreeViewerAllowed?: BooleanFilter | null;
  isAddingNonApproversAllowed?: BooleanFilter | null;
  isAddingExternalApproversAllowed?: BooleanFilter | null;
  isApprovalRevokingEnabled?: BooleanFilter | null;
  isLowerTreeLevelBypassEnabled?: BooleanFilter | null;
  isApprovalNotificationRecapReceived?: BooleanFilter | null;
  isApprovalActionNotificationBlocked?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  isQueuedApprovalsAllowed?: BooleanFilter | null;
  isExtendedApprover?: BooleanFilter | null;
  userApprovalHistoriesByEntityId?: UserApprovalGrantToManyUserApprovalHistoryFilter | null;
  userApprovalHistoriesByEntityIdExist?: boolean | null;
  approvalType?: ApprovalTypeFilter | null;
  approvalTypeExists?: boolean | null;
  userProfile?: UserProfileFilter | null;
  userProfileExists?: boolean | null;
  and?: UserApprovalGrantFilter[] | null;
  or?: UserApprovalGrantFilter[] | null;
  not?: UserApprovalGrantFilter | null;
}

/**
 * An input for mutations affecting `UserApprovalGrant`
 */
export interface UserApprovalGrantInput {
  approvalTypeId: number;
  isApprover?: boolean | null;
  isRequester?: boolean | null;
  isObserver?: boolean | null;
  isEnMasseApprovalsEnabled?: boolean | null;
  isAutoApproveEnabled?: boolean | null;
  isSelfApproveEnabled?: boolean | null;
  isApprovalTreeViewerAllowed?: boolean | null;
  isAddingNonApproversAllowed?: boolean | null;
  isApprovalRevokingEnabled?: boolean | null;
  isLowerTreeLevelBypassEnabled?: boolean | null;
  isQueuedApprovalsAllowed?: boolean | null;
  isExtendedApprover?: boolean | null;
}

/**
 * Represents an update to a `UserApprovalGrant`. Fields that are set will be updated.
 */
export interface UserApprovalGrantPatch {
  approvalTypeId?: number | null;
  isApprover?: boolean | null;
  isRequester?: boolean | null;
  isObserver?: boolean | null;
  isEnMasseApprovalsEnabled?: boolean | null;
  isAutoApproveEnabled?: boolean | null;
  isSelfApproveEnabled?: boolean | null;
  isApprovalTreeViewerAllowed?: boolean | null;
  isAddingNonApproversAllowed?: boolean | null;
  isApprovalRevokingEnabled?: boolean | null;
  isLowerTreeLevelBypassEnabled?: boolean | null;
  isQueuedApprovalsAllowed?: boolean | null;
  isExtendedApprover?: boolean | null;
}

/**
 * A filter to be used against many `UserApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalGrantToManyUserApprovalHistoryFilter {
  every?: UserApprovalHistoryFilter | null;
  some?: UserApprovalHistoryFilter | null;
  none?: UserApprovalHistoryFilter | null;
}

/**
 * An input for mutations affecting `UserApprovalGrantsUpdateType`
 */
export interface UserApprovalGrantsUpdateTypeInput {
  id?: GqlUUID | null;
  rowTimestamp?: GqlDatetime | null;
  userApprovalGrantPatch?: UserApprovalGrantPatch | null;
}

/**
 * A filter to be used against `UserApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  user_approval_grant?: UserApprovalGrantFilter | null;
  user_approval_grantExists?: boolean | null;
  and?: UserApprovalHistoryFilter[] | null;
  or?: UserApprovalHistoryFilter[] | null;
  not?: UserApprovalHistoryFilter | null;
}

/**
 * An input for mutations affecting `UserApproval`
 */
export interface UserApprovalInput {
  id: GqlUUID;
  displayEntityApprovalId?: GqlUUID | null;
  entityApprovalBranchId?: GqlUUID | null;
  entityId?: GqlUUID | null;
  entityTypeId?: number | null;
  appId?: number | null;
  approvalTypeId?: number | null;
  approvalTopic?: string | null;
  requesterUserProfileId?: GqlUUID | null;
  approverUserProfileId?: GqlUUID | null;
  requestedDate?: GqlDatetime | null;
  requesterName?: string | null;
  requiredDate?: GqlDate | null;
  approvedDate?: GqlDatetime | null;
  rejectedDate?: GqlDatetime | null;
  finalizedDate?: GqlDatetime | null;
  revokedDate?: GqlDatetime | null;
  revokedByUserProfileId?: GqlUUID | null;
  revokedByUserName?: string | null;
  isAutoApproval?: boolean | null;
  digitalSignature?: string | null;
  entityApprovalCompanyId?: GqlUUID | null;
  entityApprovalBusinessUnitId?: GqlUUID | null;
  statusTypeId?: number | null;
  iconTypeId?: number | null;
  layoutType?: TransactionLayout | null;
  isRequester?: boolean | null;
  isApprover?: boolean | null;
  isObserver?: boolean | null;
  approvalPersona?: ApprovalPersonas | null;
  isApproved?: boolean | null;
  isRejected?: boolean | null;
  isPending?: boolean | null;
  isHistory?: boolean | null;
  isRevoked?: boolean | null;
  _isRevocable?: boolean | null;
  isApprovalTreeViewerAllowed?: boolean | null;
  isPersonalApproverAddingAllowed?: boolean | null;
  isApproverAddingAllowed?: boolean | null;
  isLevelBypassingAllowed?: boolean | null;
  description1?: string | null;
  description2?: string | null;
  description3?: string | null;
  description4?: string | null;
  description5?: string | null;
  date1?: GqlDate | null;
  date2?: GqlDate | null;
  date3?: GqlDate | null;
  date4?: GqlDatetime | null;
  date5?: GqlDatetime | null;
  unit1?: number | null;
  unit2?: number | null;
  unit3?: number | null;
  unit4?: number | null;
  unit5?: number | null;
  rate1?: GqlBigFloat | null;
  rate2?: GqlBigFloat | null;
  rate3?: GqlBigFloat | null;
  rate4?: GqlBigFloat | null;
  rate5?: GqlBigFloat | null;
  amount1?: GqlBigFloat | null;
  amount2?: GqlBigFloat | null;
  amount3?: GqlBigFloat | null;
  amount4?: GqlBigFloat | null;
  amount5?: GqlBigFloat | null;
  approvalBranchId?: GqlUUID | null;
  branchApprovedDate?: GqlDatetime | null;
  branchRejectedDate?: GqlDatetime | null;
  branchStatusTypeId?: number | null;
  branchDigitalSignature?: string | null;
  entityApprovalTreeId?: GqlUUID | null;
  approvalTreeId?: GqlUUID | null;
  approvalTreeLevel?: number | null;
  treeApprovedDate?: GqlDatetime | null;
  treeRejectedDate?: GqlDatetime | null;
  treeStatusTypeId?: number | null;
  entityApprovalBranchRowTimestamp?: GqlDatetime | null;
  _companyId?: GqlUUID | null;
  _isEnvironmentalsExist?: boolean | null;
  _isNotesExist?: boolean | null;
  _isTagsExist?: boolean | null;
  _isDocumentsExist?: boolean | null;
  _searchDocument?: string | null;
  currencyId?: number | null;
  businessUnitId?: GqlUUID | null;
  departmentId?: GqlUUID | null;
  currencyIsoCode?: string | null;
  corporatePeriodId?: GqlUUID | null;
  projectPeriodId?: GqlUUID | null;
  _periodYear?: string | null;
  _periodStartDate?: GqlDate | null;
  _periodEndDate?: GqlDate | null;
  _periodId?: GqlUUID | null;
  _isAccountingEntryStampedCompleted?: boolean | null;
  _accountingStampUserId?: GqlUUID | null;
  _accountingStampUserName?: string | null;
  _accountingStampDate?: GqlDatetime | null;
  _accountingStampTransactionReference?: string | null;
  _isTransactionCancelled?: boolean | null;
  approvalGroup?: string | null;
  sortOrderGroup?: string | null;
  additionalApprovalInformation?: string | null;
  approvalPhaseTypeId?: number | null;
  _pendingApprovers?: string | null;
  _verificationLinkTypeId?: number | null;
  _isValidating?: boolean | null;
  _verificationDate?: GqlDatetime | null;
  _isUserEnMasseApprovalAllowed?: boolean | null;
  _isLookupNameApplied?: boolean | null;
  _lookupNameId?: GqlUUID | null;
  description6?: string | null;
  description7?: string | null;
  description8?: string | null;
  description9?: string | null;
  description10?: string | null;
  linkedEntityId?: GqlUUID | null;
  urgencyLevel?: number | null;
  _isUrgentApproval?: boolean | null;
  isUrgencyUpdateAllowed?: boolean | null;
}

/**
 * A filter to be used against many `ApprovalHistoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyApprovalHistoryItemFilter {
  every?: ApprovalHistoryItemFilter | null;
  some?: ApprovalHistoryItemFilter | null;
  none?: ApprovalHistoryItemFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityEnvironmental` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyEntityEnvironmentalFilter {
  every?: EntityEnvironmentalFilter | null;
  some?: EntityEnvironmentalFilter | null;
  none?: EntityEnvironmentalFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface UserApprovalToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * An input for mutations affecting `UserChart`
 */
export interface UserChartInput {
  id: GqlUUID;
  chartPosition: number;
  isActive: boolean;
}

/**
 * A filter to be used against `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface UserDefaultFilter {
  id?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  currencyId?: IntFilter | null;
  corporatePeriodId?: UUIDFilter | null;
  projectId?: UUIDFilter | null;
  proxyUserId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _userSignatureEntityDocumentId?: UUIDFilter | null;
  _isProjectsAvailable?: BooleanFilter | null;
  _isApproverProxyAllowed?: BooleanFilter | null;
  isDocumentAutoviewEnabled?: BooleanFilter | null;
  distributionLayoutTypeId?: IntFilter | null;
  isViewAllDataDefault?: BooleanFilter | null;
  defaultPurchaseOrderTypeId?: IntFilter | null;
  referenceCode6Id?: UUIDFilter | null;
  referenceCode7Id?: UUIDFilter | null;
  referenceCode6?: StringFilter | null;
  referenceCode7?: StringFilter | null;
  lookupAccountId?: UUIDFilter | null;
  accountCode?: StringFilter | null;
  _homeCountryId?: IntFilter | null;
  delegateUserProfileId?: UUIDFilter | null;
  delegateStartDate?: DateFilter | null;
  delegateStartTime?: TimeFilter | null;
  delegateEndDate?: DateFilter | null;
  delegateEndTime?: TimeFilter | null;
  _delegateUserId?: UUIDFilter | null;
  _isDelegateActive?: BooleanFilter | null;
  _isRequestingFinalApprovalAllowed?: BooleanFilter | null;
  isEmailSentOnFinalApproval?: BooleanFilter | null;
  isRequesterEmailSentEachApprovalCycle?: BooleanFilter | null;
  _isRequesterEmailAllowed?: BooleanFilter | null;
  _isTripPreferredAreaAllowed?: BooleanFilter | null;
  _isWorkgroupPreferred?: BooleanFilter | null;
  _isAliasingAllowed?: BooleanFilter | null;
  _isUserUrgencyUpdateAllowed?: BooleanFilter | null;
  isAccountingAreaExpanded?: BooleanFilter | null;
  listviewSize?: TransactionLayoutFilter | null;
  isRowSelectorBold?: BooleanFilter | null;
  projectReference?: StringFilter | null;
  setReference?: StringFilter | null;
  currency?: CompanyCurrencyFilter | null;
  currencyExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  businessUnit?: CompanyBusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  companyCorporatePeriod?: CompanyCorporatePeriodFilter | null;
  companyCorporatePeriodExists?: boolean | null;
  companyUser?: CompanyUserFilter | null;
  companyUserExists?: boolean | null;
  project?: ProjectFilter | null;
  projectExists?: boolean | null;
  distributionLayoutType?: DistributionLayoutTypeFilter | null;
  distributionLayoutTypeExists?: boolean | null;
  lookupAccounts?: LookupAccountFilter | null;
  lookupAccountsExists?: boolean | null;
  delegateUser?: CompanyUserFilter | null;
  delegateUserExists?: boolean | null;
  delegateUserProfile?: UserProfileFilter | null;
  delegateUserProfileExists?: boolean | null;
  and?: UserDefaultFilter[] | null;
  or?: UserDefaultFilter[] | null;
  not?: UserDefaultFilter | null;
}

/**
 * Represents an update to a `UserDefault`. Fields that are set will be updated.
 */
export interface UserDefaultPatch {
  departmentId?: GqlUUID | null;
  businessUnitId?: GqlUUID | null;
  currencyId?: number | null;
  corporatePeriodId?: GqlUUID | null;
  projectId?: GqlUUID | null;
  proxyUserId?: GqlUUID | null;
  isDocumentAutoviewEnabled?: boolean | null;
  distributionLayoutTypeId?: number | null;
  isViewAllDataDefault?: boolean | null;
  defaultPurchaseOrderTypeId?: number | null;
  referenceCode6Id?: GqlUUID | null;
  referenceCode7Id?: GqlUUID | null;
  referenceCode6?: string | null;
  referenceCode7?: string | null;
  lookupAccountId?: GqlUUID | null;
  accountCode?: string | null;
  _homeCountryId?: number | null;
  delegateUserProfileId?: GqlUUID | null;
  delegateStartDate?: GqlDate | null;
  delegateStartTime?: GqlTime | null;
  delegateEndDate?: GqlDate | null;
  delegateEndTime?: GqlTime | null;
  isEmailSentOnFinalApproval?: boolean | null;
  isRequesterEmailSentEachApprovalCycle?: boolean | null;
  _isUserUrgencyUpdateAllowed?: boolean | null;
  isAccountingAreaExpanded?: boolean | null;
  listviewSize?: TransactionLayout | null;
  isRowSelectorBold?: boolean | null;
  projectReference?: string | null;
  setReference?: string | null;
}

/**
 * All input for the `userDefaultUpdate` mutation.
 */
export interface UserDefaultUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  userDefaultPatch?: UserDefaultPatch | null;
  userCharts?: (UserChartInput | null)[] | null;
}

/**
 * A filter to be used against `UserDepartment` object types. All fields are combined with a logical ‘and.’
 */
export interface UserDepartmentFilter {
  id?: UUIDFilter | null;
  _isUserAssigned?: BooleanFilter | null;
  _isUserControlled?: BooleanFilter | null;
  _isUserAdministrator?: BooleanFilter | null;
  userDepartment?: CompanyDepartmentFilter | null;
  userDepartmentExists?: boolean | null;
  and?: UserDepartmentFilter[] | null;
  or?: UserDepartmentFilter[] | null;
  not?: UserDepartmentFilter | null;
}

/**
 * A filter to be used against `User` object types. All fields are combined with a logical ‘and.’
 */
export interface UserFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  emailAccount?: StringFilter | null;
  languageId?: IntFilter | null;
  isAccessAllCompanies?: BooleanFilter | null;
  isRestrictedUser?: BooleanFilter | null;
  isSigninAllowed?: BooleanFilter | null;
  tenantId?: UUIDFilter | null;
  digitalSignature?: StringFilter | null;
  userSignatureEntityDocumentId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isActiveTenantUser?: BooleanFilter | null;
  _isAuthorizedUser?: BooleanFilter | null;
  _isAuthorizedEmailAccount?: BooleanFilter | null;
  _isTenantUserExists?: BooleanFilter | null;
  _isEmailExists?: BooleanFilter | null;
  approvalSelectionConditionsByRequesterUserId?: UserToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterUserIdExist?: boolean | null;
  approvalHistoryItemsByVerificationUserId?: UserToManyApprovalHistoryItemFilter | null;
  approvalHistoryItemsByVerificationUserIdExist?: boolean | null;
  transferCyclesByCreatedByUserid?: UserToManyTransferCycleFilter | null;
  transferCyclesByCreatedByUseridExist?: boolean | null;
  userProfilesByTenantUserId?: UserToManyUserProfileFilter | null;
  userProfilesByTenantUserIdExist?: boolean | null;
  emailDocumentsByRequestedByUserId?: UserToManyEmailDocumentFilter | null;
  emailDocumentsByRequestedByUserIdExist?: boolean | null;
  purchaseOrdersByRevisedUserId?: UserToManyPurchaseOrderFilter | null;
  purchaseOrdersByRevisedUserIdExist?: boolean | null;
  purchaseOrdersByAccountingExportUserId?: UserToManyPurchaseOrderFilter | null;
  purchaseOrdersByAccountingExportUserIdExist?: boolean | null;
  travelAuthorizationsByRevisedUserId?: UserToManyTravelAuthorizationFilter | null;
  travelAuthorizationsByRevisedUserIdExist?: boolean | null;
  userInvoice?: InvoiceFilter | null;
  userInvoiceExists?: boolean | null;
  userEntityDocument?: EntityDocumentFilter | null;
  userEntityDocumentExists?: boolean | null;
  and?: UserFilter[] | null;
  or?: UserFilter[] | null;
  not?: UserFilter | null;
}

/**
 * A filter to be used against `UserGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface UserGroupFilter {
  id?: UUIDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  abbreviation?: StringFilter | null;
  sortOrder?: IntFilter | null;
  isAccessOnlyToCreatedRows?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isValidComplete?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  companyUserGroups?: UserGroupToManyCompanyUserGroupFilter | null;
  companyUserGroupsExist?: boolean | null;
  departmentOccupationTemplates?: UserGroupToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: UserGroupToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  documentPools?: UserGroupToManyDocumentPoolFilter | null;
  documentPoolsExist?: boolean | null;
  cardHolders?: UserGroupToManyCardHolderFilter | null;
  cardHoldersExist?: boolean | null;
  and?: UserGroupFilter[] | null;
  or?: UserGroupFilter[] | null;
  not?: UserGroupFilter | null;
}

/**
 * A filter to be used against many `CardHolder` object types. All fields are combined with a logical ‘and.’
 */
export interface UserGroupToManyCardHolderFilter {
  every?: CardHolderFilter | null;
  some?: CardHolderFilter | null;
  none?: CardHolderFilter | null;
}

/**
 * A filter to be used against many `CompanyUserGroup` object types. All fields are combined with a logical ‘and.’
 */
export interface UserGroupToManyCompanyUserGroupFilter {
  every?: CompanyUserGroupFilter | null;
  some?: CompanyUserGroupFilter | null;
  none?: CompanyUserGroupFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface UserGroupToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `DocumentPool` object types. All fields are combined with a logical ‘and.’
 */
export interface UserGroupToManyDocumentPoolFilter {
  every?: DocumentPoolFilter | null;
  some?: DocumentPoolFilter | null;
  none?: DocumentPoolFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface UserGroupToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `UserInviteStatusType` object types. All fields are combined with a logical ‘and.’
 */
export interface UserInviteStatusTypeFilter {
  id?: IntFilter | null;
  statusType?: StringFilter | null;
  approvalState?: ApprovalStateFilter | null;
  userProfilesByInvitationStatusTypeId?: UserInviteStatusTypeToManyUserProfileFilter | null;
  userProfilesByInvitationStatusTypeIdExist?: boolean | null;
  and?: UserInviteStatusTypeFilter[] | null;
  or?: UserInviteStatusTypeFilter[] | null;
  not?: UserInviteStatusTypeFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface UserInviteStatusTypeToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `UserMessageDetail` object types. All fields are combined with a logical ‘and.’
 */
export interface UserMessageDetailFilter {
  id?: UUIDFilter | null;
  messageComment?: StringFilter | null;
  _recipientUserId?: UUIDFilter | null;
  _threadId?: UUIDFilter | null;
  _recipientUser?: StringFilter | null;
  _sendingUser?: StringFilter | null;
  _associatedUserId?: UUIDFilter | null;
  _associatedUser?: StringFilter | null;
  _associatedUserAcknowledgeDate?: DatetimeFilter | null;
  _userAcknowledgeDate?: DatetimeFilter | null;
  _isOutgoingMessage?: BooleanFilter | null;
  _isIncomingMessage?: BooleanFilter | null;
  _communicationChannelId?: UUIDFilter | null;
  _communicationChannel?: StringFilter | null;
  _departmentId?: UUIDFilter | null;
  _department?: StringFilter | null;
  notificationTypeId?: IntFilter | null;
  _notificationTitle?: StringFilter | null;
  reminderDates?: DatetimeListFilter | null;
  _remindedDate?: DatetimeFilter | null;
  messageResponse?: StringFilter | null;
  messageWithResponse?: StringFilter | null;
  isResponseRequired?: BooleanFilter | null;
  _respondedDate?: DatetimeFilter | null;
  _receivedDate?: DatetimeFilter | null;
  _sentDate?: DatetimeFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkId?: UUIDFilter | null;
  linkType?: AppRouterFilter | null;
  appId?: IntFilter | null;
  _isSystemGenerated?: BooleanFilter | null;
  _companyId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _sortDate?: DatetimeFilter | null;
  _isNewMessage?: BooleanFilter | null;
  _isMultipleOutgoingMessages?: BooleanFilter | null;
  _outgoingMessageCount?: BigIntFilter | null;
  _isExtendedMessage?: BooleanFilter | null;
  extendedMessageId?: UUIDFilter | null;
  userMessage?: UserMessageFilter | null;
  userMessageExists?: boolean | null;
  extendedMessage?: ExtendedMessageFilter | null;
  extendedMessageExists?: boolean | null;
  mobileUserMessage?: MobileUserMessageFilter | null;
  mobileUserMessageExists?: boolean | null;
  and?: UserMessageDetailFilter[] | null;
  or?: UserMessageDetailFilter[] | null;
  not?: UserMessageDetailFilter | null;
}

/**
 * A filter to be used against `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface UserMessageFilter {
  id?: UUIDFilter | null;
  messageComment?: StringFilter | null;
  _recipientUserId?: UUIDFilter | null;
  _threadId?: UUIDFilter | null;
  _recipientUser?: StringFilter | null;
  _sendingUser?: StringFilter | null;
  _associatedUserId?: UUIDFilter | null;
  _associatedUser?: StringFilter | null;
  _associatedUserAcknowledgeDate?: DatetimeFilter | null;
  _userAcknowledgeDate?: DatetimeFilter | null;
  _isOutgoingMessage?: BooleanFilter | null;
  _isIncomingMessage?: BooleanFilter | null;
  _communicationChannelId?: UUIDFilter | null;
  _communicationChannel?: StringFilter | null;
  _departmentId?: UUIDFilter | null;
  _department?: StringFilter | null;
  notificationTypeId?: IntFilter | null;
  _notificationTitle?: StringFilter | null;
  reminderDates?: DatetimeListFilter | null;
  _remindedDate?: DatetimeFilter | null;
  messageResponse?: StringFilter | null;
  messageWithResponse?: StringFilter | null;
  isResponseRequired?: BooleanFilter | null;
  _respondedDate?: DatetimeFilter | null;
  _receivedDate?: DatetimeFilter | null;
  _sentDate?: DatetimeFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkId?: UUIDFilter | null;
  linkType?: AppRouterFilter | null;
  appId?: IntFilter | null;
  _isSystemGenerated?: BooleanFilter | null;
  _companyId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _overridingLanguageId?: IntFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _sortDate?: DatetimeFilter | null;
  _isNewMessage?: BooleanFilter | null;
  _isMultipleOutgoingMessages?: BooleanFilter | null;
  _outgoingMessageCount?: BigIntFilter | null;
  _isExtendedMessage?: BooleanFilter | null;
  extendedMessageId?: UUIDFilter | null;
  userMessageDetailsByThreadId?: UserMessageToManyUserMessageDetailFilter | null;
  userMessageDetailsByThreadIdExist?: boolean | null;
  employeeMessage?: EmployeeFilter | null;
  employeeMessageExists?: boolean | null;
  vendorMessage?: VendorFilter | null;
  vendorMessageExists?: boolean | null;
  userMessageApp?: UserAppFilter | null;
  userMessageAppExists?: boolean | null;
  invoiceMessage?: InvoiceFilter | null;
  invoiceMessageExists?: boolean | null;
  approvalHistoryMessage?: ApprovalHistoryFilter | null;
  approvalHistoryMessageExists?: boolean | null;
  userApprovalMessage?: UserApprovalFilter | null;
  userApprovalMessageExists?: boolean | null;
  extendedMessage?: ExtendedMessageFilter | null;
  extendedMessageExists?: boolean | null;
  paymentMessage?: PaymentFilter | null;
  paymentMessageExists?: boolean | null;
  supplierMessage?: SupplierFilter | null;
  supplierMessageExists?: boolean | null;
  travelerMessage?: TravelerFilter | null;
  travelerMessageExists?: boolean | null;
  batchTransactionMessage?: BatchTransactionFilter | null;
  batchTransactionMessageExists?: boolean | null;
  purchaseOrderMessage?: PurchaseOrderFilter | null;
  purchaseOrderMessageExists?: boolean | null;
  travelAuthorizationMessage?: TravelAuthorizationFilter | null;
  travelAuthorizationMessageExists?: boolean | null;
  and?: UserMessageFilter[] | null;
  or?: UserMessageFilter[] | null;
  not?: UserMessageFilter | null;
}

/**
 * An input for mutations affecting `UserMessage`
 */
export interface UserMessageInput {
  messageComment: string;
  reminderDates?: (GqlDatetime | null)[] | null;
  isResponseRequired?: boolean | null;
}

/**
 * Represents an update to a `UserMessage`. Fields that are set will be updated.
 */
export interface UserMessagePatch {
  messageComment?: string | null;
  reminderDates?: (GqlDatetime | null)[] | null;
  isResponseRequired?: boolean | null;
}

/**
 * A filter to be used against many `UserMessageDetail` object types. All fields are combined with a logical ‘and.’
 */
export interface UserMessageToManyUserMessageDetailFilter {
  every?: UserMessageDetailFilter | null;
  some?: UserMessageDetailFilter | null;
  none?: UserMessageDetailFilter | null;
}

/**
 * All input for the `userMessageUnreadCountReminderUpdate` mutation.
 */
export interface UserMessageUnreadCountReminderUpdateInput {
  clientMutationId?: string | null;
  updateAllReminders?: boolean | null;
}

/**
 * A filter to be used against `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface UserNotificationFilter {
  id?: UUIDFilter | null;
  messageComment?: StringFilter | null;
  notificationTypeId?: IntFilter | null;
  _notificationTitle?: StringFilter | null;
  _receivedDate?: DatetimeFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  linkTitle?: StringFilter | null;
  linkId?: UUIDFilter | null;
  linkType?: AppRouterFilter | null;
  appId?: IntFilter | null;
  _createdDate?: DatetimeFilter | null;
  _isNewMessage?: BooleanFilter | null;
  statusTypeId?: IntFilter | null;
  _isExtendedMessage?: BooleanFilter | null;
  extendedMessageId?: UUIDFilter | null;
  userMessageApp?: UserAppFilter | null;
  userMessageAppExists?: boolean | null;
  notification?: NotificationTypeFilter | null;
  notificationExists?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  invoiceMessage?: InvoiceFilter | null;
  invoiceMessageExists?: boolean | null;
  approvalSetupMessage?: ApprovalFilter | null;
  approvalSetupMessageExists?: boolean | null;
  userProfileSetupMessage?: UserProfileFilter | null;
  userProfileSetupMessageExists?: boolean | null;
  extendedMessage?: ExtendedMessageFilter | null;
  extendedMessageExists?: boolean | null;
  paymentMessage?: PaymentFilter | null;
  paymentMessageExists?: boolean | null;
  vendorMessage?: VendorFilter | null;
  vendorMessageExists?: boolean | null;
  supplierMessage?: SupplierFilter | null;
  supplierMessageExists?: boolean | null;
  travelerMessage?: TravelerFilter | null;
  travelerMessageExists?: boolean | null;
  batchTransactionMessage?: BatchTransactionFilter | null;
  batchTransactionMessageExists?: boolean | null;
  and?: UserNotificationFilter[] | null;
  or?: UserNotificationFilter[] | null;
  not?: UserNotificationFilter | null;
}

/**
 * A filter to be used against `UserOccupationTitle` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleFilter {
  id?: UUIDFilter | null;
  userOccupationTitle?: StringFilter | null;
  sortOrder?: IntFilter | null;
  userRankTypeId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendors?: UserOccupationTitleToManyVendorFilter | null;
  vendorsExist?: boolean | null;
  approvalBranchesByUserOccupationTitleIdSelector?: UserOccupationTitleToManyApprovalBranchFilter | null;
  approvalBranchesByUserOccupationTitleIdSelectorExist?: boolean | null;
  approvalSelectionConditionsByRequesterUserOccupationTitleId?: UserOccupationTitleToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterUserOccupationTitleIdExist?: boolean | null;
  companyUserOccupationTitles?: UserOccupationTitleToManyCompanyUserOccupationTitleFilter | null;
  companyUserOccupationTitlesExist?: boolean | null;
  departmentOccupationTemplates?: UserOccupationTitleToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userProfiles?: UserOccupationTitleToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  travelers?: UserOccupationTitleToManyTravelerFilter | null;
  travelersExist?: boolean | null;
  userRank?: UserRankTypeFilter | null;
  userRankExists?: boolean | null;
  and?: UserOccupationTitleFilter[] | null;
  or?: UserOccupationTitleFilter[] | null;
  not?: UserOccupationTitleFilter | null;
}

/**
 * An input for mutations affecting `UserOccupationTitle`
 */
export interface UserOccupationTitleInput {
  userOccupationTitle: string;
}

/**
 * Represents an update to a `UserOccupationTitle`. Fields that are set will be updated.
 */
export interface UserOccupationTitlePatch {
  userOccupationTitle?: string | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `CompanyUserOccupationTitle` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleToManyCompanyUserOccupationTitleFilter {
  every?: CompanyUserOccupationTitleFilter | null;
  some?: CompanyUserOccupationTitleFilter | null;
  none?: CompanyUserOccupationTitleFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `Traveler` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleToManyTravelerFilter {
  every?: TravelerFilter | null;
  some?: TravelerFilter | null;
  none?: TravelerFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface UserOccupationTitleToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * All input for the `userProfileApprovalCreate` mutation.
 */
export interface UserProfileApprovalCreateInput {
  clientMutationId?: string | null;
  entityApproval: (ApprovalRequestInput | null)[];
  urgencyLevel?: number | null;
}

/**
 * All input for the `userProfileApprovalRevoke` mutation.
 */
export interface UserProfileApprovalRevokeInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * All input for the `userProfileCreate` mutation.
 */
export interface UserProfileCreateInput {
  clientMutationId?: string | null;
  userProfile: UserProfileInput;
  userApprovalGrants?: (UserApprovalGrantInput | null)[] | null;
  entityDocumentId?: (GqlUUID | null)[] | null;
}

/**
 * All input for the `userProfileDelete` mutation.
 */
export interface UserProfileDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * A filter to be used against `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileFilter {
  id?: UUIDFilter | null;
  tenantUserId?: UUIDFilter | null;
  description?: StringFilter | null;
  roleId?: UUIDFilter | null;
  userOccupationTitleId?: UUIDFilter | null;
  dataAccessPolicyId?: UUIDFilter | null;
  chartOfAccountId?: UUIDFilter | null;
  departmentGroupId?: UUIDFilter | null;
  departmentId?: UUIDFilter | null;
  accessGroupId?: UUIDFilter | null;
  tagGroupId?: UUIDFilter | null;
  isRestrictedUser?: BooleanFilter | null;
  isAdministrator?: BooleanFilter | null;
  isSigninAllowed?: BooleanFilter | null;
  isPrimary?: BooleanFilter | null;
  isInvitable?: BooleanFilter | null;
  invited?: DatetimeFilter | null;
  tradingPartnerId?: UUIDFilter | null;
  communicationGroupId?: UUIDFilter | null;
  _companyId?: UUIDFilter | null;
  _createdByUserid?: UUIDFilter | null;
  userGroupId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  emailAccount?: StringFilter | null;
  departmentOccupationTemplateId?: UUIDFilter | null;
  _isDepartmentOccupationTemplateOnly?: BooleanFilter | null;
  _isControlledByDocumentOccupationTemplate?: BooleanFilter | null;
  name?: StringFilter | null;
  _isAuthenticatedUser?: BooleanFilter | null;
  _isValidCommonTenantUser?: BooleanFilter | null;
  _isValidTenantUser?: BooleanFilter | null;
  _isValidUser?: BooleanFilter | null;
  _isValidCommonTenantUserProfile?: BooleanFilter | null;
  _isValidTenantUserProfile?: BooleanFilter | null;
  _isValidUserProfile?: BooleanFilter | null;
  _isProfileValidated?: BooleanFilter | null;
  _isStagedToInvite?: BooleanFilter | null;
  _invitationStatusTypeId?: IntFilter | null;
  _userSignatureEntityDocumentId?: UUIDFilter | null;
  _isUserSignatureRequired?: BooleanFilter | null;
  _isUserSignatureUpdatable?: BooleanFilter | null;
  _lastUsedDate?: DatetimeFilter | null;
  userRankTypeId?: UUIDFilter | null;
  secureRowLevelId?: UUIDFilter | null;
  _pendingApprovers?: StringFilter | null;
  isSpotRatePreferred?: BooleanFilter | null;
  defaultAppRouter?: AppRouterFilter | null;
  _activeApprovalCount?: IntFilter | null;
  _urgencyLevel?: IntFilter | null;
  approvalHistoriesByEntityId?: UserProfileToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: UserProfileToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: UserProfileToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: UserProfileToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  notesByEntityId?: UserProfileToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: UserProfileToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  userApprovalGrants?: UserProfileToManyUserApprovalGrantFilter | null;
  userApprovalGrantsExist?: boolean | null;
  userDefaultsByDelegateUserProfileId?: UserProfileToManyUserDefaultFilter | null;
  userDefaultsByDelegateUserProfileIdExist?: boolean | null;
  entityDocumentsByEntityId?: UserProfileToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  userProfileHistoriesByEntityId?: UserProfileToManyUserProfileHistoryFilter | null;
  userProfileHistoriesByEntityIdExist?: boolean | null;
  statusType?: UserStatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  user?: UserFilter | null;
  userExists?: boolean | null;
  company?: CompanyFilter | null;
  companyExists?: boolean | null;
  role?: RoleFilter | null;
  roleExists?: boolean | null;
  userOccupationTitle?: UserOccupationTitleFilter | null;
  userOccupationTitleExists?: boolean | null;
  dataAccessPolicy?: DataAccessPolicyFilter | null;
  dataAccessPolicyExists?: boolean | null;
  departmentGroup?: DepartmentGroupFilter | null;
  departmentGroupExists?: boolean | null;
  department?: DepartmentFilter | null;
  departmentExists?: boolean | null;
  accessGroup?: AccessGroupFilter | null;
  accessGroupExists?: boolean | null;
  tagGroup?: TagGroupFilter | null;
  tagGroupExists?: boolean | null;
  communicationGroup?: CommunicationGroupFilter | null;
  communicationGroupExists?: boolean | null;
  userGroup?: UserGroupFilter | null;
  userGroupExists?: boolean | null;
  departmentOccupationTemplate?: DepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplateExists?: boolean | null;
  userProfileHistoryApprover?: ApprovalEntityApproverFilter | null;
  userProfileHistoryApproverExists?: boolean | null;
  inviteStatusType?: UserInviteStatusTypeFilter | null;
  inviteStatusTypeExists?: boolean | null;
  signatureDocument?: EntityDocumentFilter | null;
  signatureDocumentExists?: boolean | null;
  userRankType?: UserRankTypeFilter | null;
  userRankTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  and?: UserProfileFilter[] | null;
  or?: UserProfileFilter[] | null;
  not?: UserProfileFilter | null;
}

/**
 * A filter to be used against `UserProfileHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  user_profile?: UserProfileFilter | null;
  user_profileExists?: boolean | null;
  and?: UserProfileHistoryFilter[] | null;
  or?: UserProfileHistoryFilter[] | null;
  not?: UserProfileHistoryFilter | null;
}

/**
 * An input for mutations affecting `UserProfile`
 */
export interface UserProfileInput {
  description?: string | null;
  roleId?: GqlUUID | null;
  userOccupationTitleId?: GqlUUID | null;
  dataAccessPolicyId?: GqlUUID | null;
  departmentGroupId?: GqlUUID | null;
  departmentId?: GqlUUID | null;
  accessGroupId?: GqlUUID | null;
  tagGroupId?: GqlUUID | null;
  communicationGroupId?: GqlUUID | null;
  userGroupId?: GqlUUID | null;
  emailAccount: string;
  departmentOccupationTemplateId?: GqlUUID | null;
  name: string;
  userRankTypeId?: GqlUUID | null;
  secureRowLevelId?: GqlUUID | null;
  isSpotRatePreferred?: boolean | null;
  defaultAppRouter?: AppRouter | null;
}

/**
 * Represents an update to a `UserProfile`. Fields that are set will be updated.
 */
export interface UserProfilePatch {
  description?: string | null;
  roleId?: GqlUUID | null;
  userOccupationTitleId?: GqlUUID | null;
  dataAccessPolicyId?: GqlUUID | null;
  departmentGroupId?: GqlUUID | null;
  departmentId?: GqlUUID | null;
  accessGroupId?: GqlUUID | null;
  tagGroupId?: GqlUUID | null;
  communicationGroupId?: GqlUUID | null;
  userGroupId?: GqlUUID | null;
  emailAccount?: string | null;
  departmentOccupationTemplateId?: GqlUUID | null;
  name?: string | null;
  userRankTypeId?: GqlUUID | null;
  secureRowLevelId?: GqlUUID | null;
  isSpotRatePreferred?: boolean | null;
  defaultAppRouter?: AppRouter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserApprovalGrant` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyUserApprovalGrantFilter {
  every?: UserApprovalGrantFilter | null;
  some?: UserApprovalGrantFilter | null;
  none?: UserApprovalGrantFilter | null;
}

/**
 * A filter to be used against many `UserDefault` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyUserDefaultFilter {
  every?: UserDefaultFilter | null;
  some?: UserDefaultFilter | null;
  none?: UserDefaultFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * A filter to be used against many `UserProfileHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface UserProfileToManyUserProfileHistoryFilter {
  every?: UserProfileHistoryFilter | null;
  some?: UserProfileHistoryFilter | null;
  none?: UserProfileHistoryFilter | null;
}

/**
 * All input for the `userProfileUpdate` mutation.
 */
export interface UserProfileUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  userProfilePatch?: UserProfilePatch | null;
  userApprovalGrantsCreate?: (UserApprovalGrantInput | null)[] | null;
  userApprovalGrantsUpdate?: (UserApprovalGrantsUpdateTypeInput | null)[] | null;
  userApprovalGrantsDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `userProfileUrgentUpdate` mutation.
 */
export interface UserProfileUrgentUpdateInput {
  clientMutationId?: string | null;
  entityId: GqlUUID;
  rowTimestamp: GqlDatetime;
  urgencyLevel: number;
  explanationComment?: string | null;
}

/**
 * A filter to be used against `UserRankType` object types. All fields are combined with a logical ‘and.’
 */
export interface UserRankTypeFilter {
  id?: UUIDFilter | null;
  userRank?: StringFilter | null;
  sortOrder?: IntFilter | null;
  securityLevel?: IntFilter | null;
  isInternalUse?: BooleanFilter | null;
  isAdministrator?: BooleanFilter | null;
  isSetupUser?: BooleanFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  approvalBranchesByUserRankTypeIdSelector?: UserRankTypeToManyApprovalBranchFilter | null;
  approvalBranchesByUserRankTypeIdSelectorExist?: boolean | null;
  approvalSelectionConditionsByRequesterUserRankId?: UserRankTypeToManyApprovalSelectionConditionFilter | null;
  approvalSelectionConditionsByRequesterUserRankIdExist?: boolean | null;
  departmentOccupationTemplates?: UserRankTypeToManyDepartmentOccupationTemplateFilter | null;
  departmentOccupationTemplatesExist?: boolean | null;
  userOccupationTitles?: UserRankTypeToManyUserOccupationTitleFilter | null;
  userOccupationTitlesExist?: boolean | null;
  userProfiles?: UserRankTypeToManyUserProfileFilter | null;
  userProfilesExist?: boolean | null;
  and?: UserRankTypeFilter[] | null;
  or?: UserRankTypeFilter[] | null;
  not?: UserRankTypeFilter | null;
}

/**
 * A filter to be used against many `ApprovalBranch` object types. All fields are combined with a logical ‘and.’
 */
export interface UserRankTypeToManyApprovalBranchFilter {
  every?: ApprovalBranchFilter | null;
  some?: ApprovalBranchFilter | null;
  none?: ApprovalBranchFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface UserRankTypeToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `DepartmentOccupationTemplate` object types. All fields are combined with a logical ‘and.’
 */
export interface UserRankTypeToManyDepartmentOccupationTemplateFilter {
  every?: DepartmentOccupationTemplateFilter | null;
  some?: DepartmentOccupationTemplateFilter | null;
  none?: DepartmentOccupationTemplateFilter | null;
}

/**
 * A filter to be used against many `UserOccupationTitle` object types. All fields are combined with a logical ‘and.’
 */
export interface UserRankTypeToManyUserOccupationTitleFilter {
  every?: UserOccupationTitleFilter | null;
  some?: UserOccupationTitleFilter | null;
  none?: UserOccupationTitleFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface UserRankTypeToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `UserStatusType` object types. All fields are combined with a logical ‘and.’
 */
export interface UserStatusTypeFilter {
  id?: IntFilter | null;
  statusType?: StringFilter | null;
  approvalState?: ApprovalStateFilter | null;
  userProfilesByStatusTypeId?: UserStatusTypeToManyUserProfileFilter | null;
  userProfilesByStatusTypeIdExist?: boolean | null;
  and?: UserStatusTypeFilter[] | null;
  or?: UserStatusTypeFilter[] | null;
  not?: UserStatusTypeFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface UserStatusTypeToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * All input for the `userTemplateCopy` mutation.
 */
export interface UserTemplateCopyInput {
  clientMutationId?: string | null;
  userTemplateId: GqlUUID;
  rowTimestamp: GqlDatetime;
  newTemplateName: string;
}

/**
 * All input for the `userTemplateCreate` mutation.
 */
export interface UserTemplateCreateInput {
  clientMutationId?: string | null;
  userTemplate: DepartmentOccupationTemplateInput;
  userTemplateApprovals?: (DepartmentOccupationApprovalInput | null)[] | null;
  userTemplateCharts?: (DepartmentOccupationChartInput | null)[] | null;
}

/**
 * All input for the `userTemplateDelete` mutation.
 */
export interface UserTemplateDeleteInput {
  clientMutationId?: string | null;
  entityDelete: (EntityDeleteInput | null)[];
}

/**
 * All input for the `userTemplateUpdate` mutation.
 */
export interface UserTemplateUpdateInput {
  clientMutationId?: string | null;
  id: GqlUUID;
  rowTimestamp: GqlDatetime;
  userTemplatePatch?: DepartmentOccupationTemplatePatch | null;
  userTemplateApprovalCreate?: (DepartmentOccupationApprovalInput | null)[] | null;
  userTemplateApprovalUpdate?: (DepartmentOccupationApprovalUpdateTypeInput | null)[] | null;
  userTemplateApprovalDelete?: (EntityDeleteInput | null)[] | null;
  userTemplateChartCreate?: (DepartmentOccupationChartInput | null)[] | null;
  userTemplateChartUpdate?: (DepartmentOccupationChartUpdateTypeInput | null)[] | null;
  userTemplateChartDelete?: (EntityDeleteInput | null)[] | null;
}

/**
 * All input for the `userTemplateUserSync` mutation.
 */
export interface UserTemplateUserSyncInput {
  clientMutationId?: string | null;
  departmentOccupationTemplateId: GqlUUID;
  rowTimestamp: GqlDatetime;
}

/**
 * A filter to be used against many `ApprovalHistoryItem` object types. All fields are combined with a logical ‘and.’
 */
export interface UserToManyApprovalHistoryItemFilter {
  every?: ApprovalHistoryItemFilter | null;
  some?: ApprovalHistoryItemFilter | null;
  none?: ApprovalHistoryItemFilter | null;
}

/**
 * A filter to be used against many `ApprovalSelectionCondition` object types. All fields are combined with a logical ‘and.’
 */
export interface UserToManyApprovalSelectionConditionFilter {
  every?: ApprovalSelectionConditionFilter | null;
  some?: ApprovalSelectionConditionFilter | null;
  none?: ApprovalSelectionConditionFilter | null;
}

/**
 * A filter to be used against many `EmailDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface UserToManyEmailDocumentFilter {
  every?: EmailDocumentFilter | null;
  some?: EmailDocumentFilter | null;
  none?: EmailDocumentFilter | null;
}

/**
 * A filter to be used against many `PurchaseOrder` object types. All fields are combined with a logical ‘and.’
 */
export interface UserToManyPurchaseOrderFilter {
  every?: PurchaseOrderFilter | null;
  some?: PurchaseOrderFilter | null;
  none?: PurchaseOrderFilter | null;
}

/**
 * A filter to be used against many `TransferCycle` object types. All fields are combined with a logical ‘and.’
 */
export interface UserToManyTransferCycleFilter {
  every?: TransferCycleFilter | null;
  some?: TransferCycleFilter | null;
  none?: TransferCycleFilter | null;
}

/**
 * A filter to be used against many `TravelAuthorization` object types. All fields are combined with a logical ‘and.’
 */
export interface UserToManyTravelAuthorizationFilter {
  every?: TravelAuthorizationFilter | null;
  some?: TravelAuthorizationFilter | null;
  none?: TravelAuthorizationFilter | null;
}

/**
 * A filter to be used against many `UserProfile` object types. All fields are combined with a logical ‘and.’
 */
export interface UserToManyUserProfileFilter {
  every?: UserProfileFilter | null;
  some?: UserProfileFilter | null;
  none?: UserProfileFilter | null;
}

/**
 * A filter to be used against `VendorAccessBusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorAccessBusinessUnitFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  businessUnitId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  businessUnit?: BusinessUnitFilter | null;
  businessUnitExists?: boolean | null;
  and?: VendorAccessBusinessUnitFilter[] | null;
  or?: VendorAccessBusinessUnitFilter[] | null;
  not?: VendorAccessBusinessUnitFilter | null;
}

/**
 * A filter to be used against `VendorAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorAddressFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  addressLine1?: StringFilter | null;
  addressLine2?: StringFilter | null;
  cityName?: StringFilter | null;
  countryId?: IntFilter | null;
  stateRegionId?: IntFilter | null;
  postalCode?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  emailAddress?: StringFilter | null;
  webAddress?: StringFilter | null;
  isSendEmailAccount?: BooleanFilter | null;
  isReceiveEmailAccount?: BooleanFilter | null;
  isGroundTransferAddress?: BooleanFilter | null;
  isDeliveryAddress?: BooleanFilter | null;
  isPrimary?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _isAddressVerified?: BooleanFilter | null;
  vendorAddressHistoriesByEntityId?: VendorAddressToManyVendorAddressHistoryFilter | null;
  vendorAddressHistoriesByEntityIdExist?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  country?: CountryFilter | null;
  countryExists?: boolean | null;
  stateRegion?: StateRegionFilter | null;
  stateRegionExists?: boolean | null;
  and?: VendorAddressFilter[] | null;
  or?: VendorAddressFilter[] | null;
  not?: VendorAddressFilter | null;
}

/**
 * A filter to be used against `VendorAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorAddressHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  vendorAddress?: VendorAddressFilter | null;
  vendorAddressExists?: boolean | null;
  and?: VendorAddressHistoryFilter[] | null;
  or?: VendorAddressHistoryFilter[] | null;
  not?: VendorAddressHistoryFilter | null;
}

/**
 * A filter to be used against many `VendorAddressHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorAddressToManyVendorAddressHistoryFilter {
  every?: VendorAddressHistoryFilter | null;
  some?: VendorAddressHistoryFilter | null;
  none?: VendorAddressHistoryFilter | null;
}

/**
 * A filter to be used against `VendorClassification` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorClassificationFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  sicCodeId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  sicCode?: SicCodeFilter | null;
  sicCodeExists?: boolean | null;
  and?: VendorClassificationFilter[] | null;
  or?: VendorClassificationFilter[] | null;
  not?: VendorClassificationFilter | null;
}

/**
 * A filter to be used against `VendorContact` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorContactFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  contactName?: StringFilter | null;
  telephoneNumber?: StringFilter | null;
  emailAddress?: StringFilter | null;
  isSendEmailAccount?: BooleanFilter | null;
  isReceiveEmailAccount?: BooleanFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendorContactHistoriesByEntityId?: VendorContactToManyVendorContactHistoryFilter | null;
  vendorContactHistoriesByEntityIdExist?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  and?: VendorContactFilter[] | null;
  or?: VendorContactFilter[] | null;
  not?: VendorContactFilter | null;
}

/**
 * A filter to be used against `VendorContactHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorContactHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  vendorContact?: VendorContactFilter | null;
  vendorContactExists?: boolean | null;
  and?: VendorContactHistoryFilter[] | null;
  or?: VendorContactHistoryFilter[] | null;
  not?: VendorContactHistoryFilter | null;
}

/**
 * A filter to be used against many `VendorContactHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorContactToManyVendorContactHistoryFilter {
  every?: VendorContactHistoryFilter | null;
  some?: VendorContactHistoryFilter | null;
  none?: VendorContactHistoryFilter | null;
}

/**
 * A filter to be used against `VendorDefaultAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorDefaultAccountFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  chartOfAccountId?: UUIDFilter | null;
  lineNumber?: IntFilter | null;
  accountId?: UUIDFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  chartOfAccount?: ChartOfAccountFilter | null;
  chartOfAccountExists?: boolean | null;
  account?: AccountFilter | null;
  accountExists?: boolean | null;
  and?: VendorDefaultAccountFilter[] | null;
  or?: VendorDefaultAccountFilter[] | null;
  not?: VendorDefaultAccountFilter | null;
}

/**
 * A filter to be used against `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorFilter {
  id?: UUIDFilter | null;
  companyOrLastName?: StringFilter | null;
  firstName?: StringFilter | null;
  vendorNumber?: StringFilter | null;
  customerAccount?: StringFilter | null;
  _ratingVendorId?: UUIDFilter | null;
  isIndividual?: BooleanFilter | null;
  isCorporation?: BooleanFilter | null;
  paymentTermTypeId?: UUIDFilter | null;
  isCrewMember?: BooleanFilter | null;
  isTraveler?: BooleanFilter | null;
  isPayrollVendor?: BooleanFilter | null;
  isPurchaseOrderSupplier?: BooleanFilter | null;
  isRelatedPartySupplier?: BooleanFilter | null;
  isCompetitiveBidsRequired?: BooleanFilter | null;
  isContractualPurchaseOrderRequired?: BooleanFilter | null;
  isVendorPlaceholder?: BooleanFilter | null;
  isEnvironmentalImpactTracked?: BooleanFilter | null;
  environmentalMetricId?: UUIDFilter | null;
  purchaseDocumentsPoolId?: UUIDFilter | null;
  bankAccountName?: StringFilter | null;
  bankRoutingNumber?: StringFilter | null;
  bankName?: StringFilter | null;
  bankSortCode?: StringFilter | null;
  bankIbanCode?: StringFilter | null;
  bankAccountNumber?: StringFilter | null;
  bankSwiftCode?: StringFilter | null;
  _isBankAccountVerified?: BooleanFilter | null;
  travelPolicyId?: UUIDFilter | null;
  dateOfBirth?: DateFilter | null;
  frequentFlyerNumber?: StringFilter | null;
  isIsleSeatPreferred?: BooleanFilter | null;
  isWindowSeatPreferred?: BooleanFilter | null;
  _createdByUserid?: UUIDFilter | null;
  _userGroupId?: UUIDFilter | null;
  rowSecurityId?: UUIDFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isApprovalRevocable?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isApproved?: BooleanFilter | null;
  _isWaitingApproval?: BooleanFilter | null;
  isDraft?: BooleanFilter | null;
  _isApprovalDocumentsRequired?: BooleanFilter | null;
  _requiredApprovalDocuments?: StringFilter | null;
  _isStagedApprovalRequest?: BooleanFilter | null;
  _isUserRequester?: BooleanFilter | null;
  _isUserApprover?: BooleanFilter | null;
  _isUserObserver?: BooleanFilter | null;
  _isUserRevoker?: BooleanFilter | null;
  _isUserApprovalTreeViewer?: BooleanFilter | null;
  _isUserPersonalApproversAllowed?: BooleanFilter | null;
  _isUserPersonalApproversRequired?: BooleanFilter | null;
  _isValidated?: BooleanFilter | null;
  _isWaitingValidation?: BooleanFilter | null;
  _statusTypeId?: IntFilter | null;
  _isProtected?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isProtectedView?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _isEnvironmentalsExist?: BooleanFilter | null;
  _isApprovalHistoryExists?: BooleanFilter | null;
  _isRatingsExist?: BooleanFilter | null;
  _isNotesExist?: BooleanFilter | null;
  _isTagsExist?: BooleanFilter | null;
  _isDocumentsExist?: BooleanFilter | null;
  _isMessagesExist?: BooleanFilter | null;
  _pendingApprovers?: StringFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  _companyOrPoolingId?: UUIDFilter | null;
  _sortName?: StringFilter | null;
  _fullName?: StringFilter | null;
  userOccupationTitleId?: UUIDFilter | null;
  travelerDepartmentId?: UUIDFilter | null;
  vendorServiceTypeId?: IntFilter | null;
  vendorServiceRate?: BigFloatFilter | null;
  travelerCompanionTickets?: IntFilter | null;
  travelerCompanionAmount?: BigFloatFilter | null;
  isFirstClassPreferred?: BooleanFilter | null;
  isBusinessClassPreferred?: BooleanFilter | null;
  isEconomyClassPreferred?: BooleanFilter | null;
  travelerCompanionMasterId?: UUIDFilter | null;
  federalTaxReference?: StringFilter | null;
  vatNumber?: StringFilter | null;
  _urgencyLevel?: IntFilter | null;
  invoices?: VendorToManyInvoiceFilter | null;
  invoicesExist?: boolean | null;
  payments?: VendorToManyPaymentFilter | null;
  paymentsExist?: boolean | null;
  vendorAccessBusinessUnitsByVendorId?: VendorToManyVendorAccessBusinessUnitFilter | null;
  vendorAccessBusinessUnitsByVendorIdExist?: boolean | null;
  vendorAddressesByVendorId?: VendorToManyVendorAddressFilter | null;
  vendorAddressesByVendorIdExist?: boolean | null;
  vendorClassificationsByVendorId?: VendorToManyVendorClassificationFilter | null;
  vendorClassificationsByVendorIdExist?: boolean | null;
  vendorContactsByVendorId?: VendorToManyVendorContactFilter | null;
  vendorContactsByVendorIdExist?: boolean | null;
  vendorDefaultAccountsByVendorId?: VendorToManyVendorDefaultAccountFilter | null;
  vendorDefaultAccountsByVendorIdExist?: boolean | null;
  vendorIntegrationsByVendorId?: VendorToManyVendorIntegrationFilter | null;
  vendorIntegrationsByVendorIdExist?: boolean | null;
  vendorTravelDietaryRequirementsByVendorId?: VendorToManyVendorTravelDietaryRequirementFilter | null;
  vendorTravelDietaryRequirementsByVendorIdExist?: boolean | null;
  vendorTravelPolicyAllowancesByVendorId?: VendorToManyVendorTravelPolicyAllowanceFilter | null;
  vendorTravelPolicyAllowancesByVendorIdExist?: boolean | null;
  approvalHistoriesByEntityId?: VendorToManyApprovalHistoryFilter | null;
  approvalHistoriesByEntityIdExist?: boolean | null;
  approvalHistorySignaturesByEntityId?: VendorToManyApprovalHistorySignatureFilter | null;
  approvalHistorySignaturesByEntityIdExist?: boolean | null;
  approvalSignaturesByEntityId?: VendorToManyApprovalSignatureFilter | null;
  approvalSignaturesByEntityIdExist?: boolean | null;
  userApprovalsByEntityId?: VendorToManyUserApprovalFilter | null;
  userApprovalsByEntityIdExist?: boolean | null;
  entityTagsByEntityId?: VendorToManyEntityTagFilter | null;
  entityTagsByEntityIdExist?: boolean | null;
  mobileUserMessagesByEntityId?: VendorToManyMobileUserMessageFilter | null;
  mobileUserMessagesByEntityIdExist?: boolean | null;
  notesByEntityId?: VendorToManyNoteFilter | null;
  notesByEntityIdExist?: boolean | null;
  ratingsByEntityId?: VendorToManyRatingFilter | null;
  ratingsByEntityIdExist?: boolean | null;
  userMessagesByEntityId?: VendorToManyUserMessageFilter | null;
  userMessagesByEntityIdExist?: boolean | null;
  userNotificationsByEntityId?: VendorToManyUserNotificationFilter | null;
  userNotificationsByEntityIdExist?: boolean | null;
  entityDocumentsByEntityId?: VendorToManyEntityDocumentFilter | null;
  entityDocumentsByEntityIdExist?: boolean | null;
  vendorHistoriesByEntityId?: VendorToManyVendorHistoryFilter | null;
  vendorHistoriesByEntityIdExist?: boolean | null;
  statusType?: StatusTypeFilter | null;
  statusTypeExists?: boolean | null;
  secureRowLevel?: SecureRowLevelFilter | null;
  secureRowLevelExists?: boolean | null;
  vendorRatingResults?: RatingEntityResultFilter | null;
  vendorRatingResultsExists?: boolean | null;
  paymentTermType?: PaymentTermTypeFilter | null;
  paymentTermTypeExists?: boolean | null;
  environmentMetric?: EnvironmentalMetricFilter | null;
  environmentMetricExists?: boolean | null;
  travelPolicy?: TravelPolicyFilter | null;
  travelPolicyExists?: boolean | null;
  userOccupation?: UserOccupationTitleFilter | null;
  userOccupationExists?: boolean | null;
  department?: CompanyDepartmentFilter | null;
  departmentExists?: boolean | null;
  vendorServiceType?: VendorServiceTypeFilter | null;
  vendorServiceTypeExists?: boolean | null;
  travelerHost?: TravelerCompanionMasterFilter | null;
  travelerHostExists?: boolean | null;
  and?: VendorFilter[] | null;
  or?: VendorFilter[] | null;
  not?: VendorFilter | null;
}

/**
 * A filter to be used against `VendorHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  and?: VendorHistoryFilter[] | null;
  or?: VendorHistoryFilter[] | null;
  not?: VendorHistoryFilter | null;
}

/**
 * A filter to be used against `VendorIntegration` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorIntegrationFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  tradingPartnerId?: UUIDFilter | null;
  vendorKey?: StringFilter | null;
  vendorNumber?: StringFilter | null;
  _searchDocument?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  and?: VendorIntegrationFilter[] | null;
  or?: VendorIntegrationFilter[] | null;
  not?: VendorIntegrationFilter | null;
}

/**
 * A filter to be used against `VendorServiceType` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorServiceTypeFilter {
  id?: IntFilter | null;
  vendorType?: StringFilter | null;
  description?: StringFilter | null;
  sortOrder?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  isServiceRateAllowed?: BooleanFilter | null;
  isAirfareType?: BooleanFilter | null;
  isTravelAgentType?: BooleanFilter | null;
  isTrainType?: BooleanFilter | null;
  isVehicleRentalType?: BooleanFilter | null;
  isGroundTransportationType?: BooleanFilter | null;
  isHotelType?: BooleanFilter | null;
  isRateRequired?: BooleanFilter | null;
  expenditureTypes?: VendorServiceTypeToManyExpenditureTypeFilter | null;
  expenditureTypesExist?: boolean | null;
  vendors?: VendorServiceTypeToManyVendorFilter | null;
  vendorsExist?: boolean | null;
  suppliers?: VendorServiceTypeToManySupplierFilter | null;
  suppliersExist?: boolean | null;
  and?: VendorServiceTypeFilter[] | null;
  or?: VendorServiceTypeFilter[] | null;
  not?: VendorServiceTypeFilter | null;
}

/**
 * A filter to be used against many `ExpenditureType` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorServiceTypeToManyExpenditureTypeFilter {
  every?: ExpenditureTypeFilter | null;
  some?: ExpenditureTypeFilter | null;
  none?: ExpenditureTypeFilter | null;
}

/**
 * A filter to be used against many `Supplier` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorServiceTypeToManySupplierFilter {
  every?: SupplierFilter | null;
  some?: SupplierFilter | null;
  none?: SupplierFilter | null;
}

/**
 * A filter to be used against many `Vendor` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorServiceTypeToManyVendorFilter {
  every?: VendorFilter | null;
  some?: VendorFilter | null;
  none?: VendorFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyApprovalHistoryFilter {
  every?: ApprovalHistoryFilter | null;
  some?: ApprovalHistoryFilter | null;
  none?: ApprovalHistoryFilter | null;
}

/**
 * A filter to be used against many `ApprovalHistorySignature` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyApprovalHistorySignatureFilter {
  every?: ApprovalHistorySignatureFilter | null;
  some?: ApprovalHistorySignatureFilter | null;
  none?: ApprovalHistorySignatureFilter | null;
}

/**
 * A filter to be used against many `ApprovalSignature` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyApprovalSignatureFilter {
  every?: ApprovalSignatureFilter | null;
  some?: ApprovalSignatureFilter | null;
  none?: ApprovalSignatureFilter | null;
}

/**
 * A filter to be used against many `EntityDocument` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyEntityDocumentFilter {
  every?: EntityDocumentFilter | null;
  some?: EntityDocumentFilter | null;
  none?: EntityDocumentFilter | null;
}

/**
 * A filter to be used against many `EntityTag` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyEntityTagFilter {
  every?: EntityTagFilter | null;
  some?: EntityTagFilter | null;
  none?: EntityTagFilter | null;
}

/**
 * A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyInvoiceFilter {
  every?: InvoiceFilter | null;
  some?: InvoiceFilter | null;
  none?: InvoiceFilter | null;
}

/**
 * A filter to be used against many `MobileUserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyMobileUserMessageFilter {
  every?: MobileUserMessageFilter | null;
  some?: MobileUserMessageFilter | null;
  none?: MobileUserMessageFilter | null;
}

/**
 * A filter to be used against many `Note` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyNoteFilter {
  every?: NoteFilter | null;
  some?: NoteFilter | null;
  none?: NoteFilter | null;
}

/**
 * A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyPaymentFilter {
  every?: PaymentFilter | null;
  some?: PaymentFilter | null;
  none?: PaymentFilter | null;
}

/**
 * A filter to be used against many `Rating` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyRatingFilter {
  every?: RatingFilter | null;
  some?: RatingFilter | null;
  none?: RatingFilter | null;
}

/**
 * A filter to be used against many `UserApproval` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyUserApprovalFilter {
  every?: UserApprovalFilter | null;
  some?: UserApprovalFilter | null;
  none?: UserApprovalFilter | null;
}

/**
 * A filter to be used against many `UserMessage` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyUserMessageFilter {
  every?: UserMessageFilter | null;
  some?: UserMessageFilter | null;
  none?: UserMessageFilter | null;
}

/**
 * A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyUserNotificationFilter {
  every?: UserNotificationFilter | null;
  some?: UserNotificationFilter | null;
  none?: UserNotificationFilter | null;
}

/**
 * A filter to be used against many `VendorAccessBusinessUnit` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorAccessBusinessUnitFilter {
  every?: VendorAccessBusinessUnitFilter | null;
  some?: VendorAccessBusinessUnitFilter | null;
  none?: VendorAccessBusinessUnitFilter | null;
}

/**
 * A filter to be used against many `VendorAddress` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorAddressFilter {
  every?: VendorAddressFilter | null;
  some?: VendorAddressFilter | null;
  none?: VendorAddressFilter | null;
}

/**
 * A filter to be used against many `VendorClassification` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorClassificationFilter {
  every?: VendorClassificationFilter | null;
  some?: VendorClassificationFilter | null;
  none?: VendorClassificationFilter | null;
}

/**
 * A filter to be used against many `VendorContact` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorContactFilter {
  every?: VendorContactFilter | null;
  some?: VendorContactFilter | null;
  none?: VendorContactFilter | null;
}

/**
 * A filter to be used against many `VendorDefaultAccount` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorDefaultAccountFilter {
  every?: VendorDefaultAccountFilter | null;
  some?: VendorDefaultAccountFilter | null;
  none?: VendorDefaultAccountFilter | null;
}

/**
 * A filter to be used against many `VendorHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorHistoryFilter {
  every?: VendorHistoryFilter | null;
  some?: VendorHistoryFilter | null;
  none?: VendorHistoryFilter | null;
}

/**
 * A filter to be used against many `VendorIntegration` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorIntegrationFilter {
  every?: VendorIntegrationFilter | null;
  some?: VendorIntegrationFilter | null;
  none?: VendorIntegrationFilter | null;
}

/**
 * A filter to be used against many `VendorTravelDietaryRequirement` object types.
 * All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorTravelDietaryRequirementFilter {
  every?: VendorTravelDietaryRequirementFilter | null;
  some?: VendorTravelDietaryRequirementFilter | null;
  none?: VendorTravelDietaryRequirementFilter | null;
}

/**
 * A filter to be used against many `VendorTravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorToManyVendorTravelPolicyAllowanceFilter {
  every?: VendorTravelPolicyAllowanceFilter | null;
  some?: VendorTravelPolicyAllowanceFilter | null;
  none?: VendorTravelPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against `VendorTravelDietaryRequirement` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorTravelDietaryRequirementFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  dietaryRequirementId?: IntFilter | null;
  description?: StringFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendorTravelDietaryRequirementHistoriesByEntityId?: VendorTravelDietaryRequirementToManyVendorTravelDietaryRequirementHistoryFilter | null;
  vendorTravelDietaryRequirementHistoriesByEntityIdExist?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  dietaryRequirement?: DietaryRequirementFilter | null;
  dietaryRequirementExists?: boolean | null;
  and?: VendorTravelDietaryRequirementFilter[] | null;
  or?: VendorTravelDietaryRequirementFilter[] | null;
  not?: VendorTravelDietaryRequirementFilter | null;
}

/**
 * A filter to be used against `VendorTravelDietaryRequirementHistory` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface VendorTravelDietaryRequirementHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  vendorTravelDietaryRequirement?: VendorTravelDietaryRequirementFilter | null;
  vendorTravelDietaryRequirementExists?: boolean | null;
  and?: VendorTravelDietaryRequirementHistoryFilter[] | null;
  or?: VendorTravelDietaryRequirementHistoryFilter[] | null;
  not?: VendorTravelDietaryRequirementHistoryFilter | null;
}

/**
 * A filter to be used against many `VendorTravelDietaryRequirementHistory` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface VendorTravelDietaryRequirementToManyVendorTravelDietaryRequirementHistoryFilter {
  every?: VendorTravelDietaryRequirementHistoryFilter | null;
  some?: VendorTravelDietaryRequirementHistoryFilter | null;
  none?: VendorTravelDietaryRequirementHistoryFilter | null;
}

/**
 * A filter to be used against `VendorTravelPolicyAllowance` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorTravelPolicyAllowanceFilter {
  id?: UUIDFilter | null;
  _vendorId?: UUIDFilter | null;
  travelPolicyAllowanceId?: UUIDFilter | null;
  allowanceOverrideAmount?: BigFloatFilter | null;
  _isActive?: BooleanFilter | null;
  _isModified?: BooleanFilter | null;
  _isDeprecated?: BooleanFilter | null;
  _isUpdatable?: BooleanFilter | null;
  _isDeletable?: BooleanFilter | null;
  _createdDate?: DatetimeFilter | null;
  _rowTimestamp?: DatetimeFilter | null;
  vendorTravelPolicyAllowanceHistoriesByEntityId?: VendorTravelPolicyAllowanceToManyVendorTravelPolicyAllowanceHistoryFilter | null;
  vendorTravelPolicyAllowanceHistoriesByEntityIdExist?: boolean | null;
  vendor?: VendorFilter | null;
  vendorExists?: boolean | null;
  policyAllowanceOverride?: TravelPolicyAllowanceFilter | null;
  policyAllowanceOverrideExists?: boolean | null;
  and?: VendorTravelPolicyAllowanceFilter[] | null;
  or?: VendorTravelPolicyAllowanceFilter[] | null;
  not?: VendorTravelPolicyAllowanceFilter | null;
}

/**
 * A filter to be used against `VendorTravelPolicyAllowanceHistory` object types. All fields are combined with a logical ‘and.’
 */
export interface VendorTravelPolicyAllowanceHistoryFilter {
  historyId?: StringFilter | null;
  entityId?: UUIDFilter | null;
  entityTypeId?: IntFilter | null;
  parentEntityId?: UUIDFilter | null;
  parentEntityTypeId?: IntFilter | null;
  pointInTimeDate?: DatetimeFilter | null;
  pointInTimeDateFormat?: StringFilter | null;
  tenantUserId?: UUIDFilter | null;
  tenantUserName?: StringFilter | null;
  modificationInformation?: StringFilter | null;
  isPointInTimeModification?: BooleanFilter | null;
  isRemoved?: BooleanFilter | null;
  crudOperation?: CrudOperationsFilter | null;
  beforeChangeColumnKeyValues?: StringListFilter | null;
  pointInTimeColumnKeyValues?: StringListFilter | null;
  modifiedColumns?: StringListFilter | null;
  availableRollbackColumns?: StringListFilter | null;
  modifiedJsonColumnValues?: JSONFilter | null;
  originalJsonRowValues?: JSONFilter | null;
  currentJsonRowValues?: JSONFilter | null;
  vendorTravelPolicyAllowance?: VendorTravelPolicyAllowanceFilter | null;
  vendorTravelPolicyAllowanceExists?: boolean | null;
  and?: VendorTravelPolicyAllowanceHistoryFilter[] | null;
  or?: VendorTravelPolicyAllowanceHistoryFilter[] | null;
  not?: VendorTravelPolicyAllowanceHistoryFilter | null;
}

/**
 * A filter to be used against many `VendorTravelPolicyAllowanceHistory` object
 * types. All fields are combined with a logical ‘and.’
 */
export interface VendorTravelPolicyAllowanceToManyVendorTravelPolicyAllowanceHistoryFilter {
  every?: VendorTravelPolicyAllowanceHistoryFilter | null;
  some?: VendorTravelPolicyAllowanceHistoryFilter | null;
  none?: VendorTravelPolicyAllowanceHistoryFilter | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
